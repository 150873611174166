import React, { createRef } from "react";
import { toast } from "react-toastify";
import { Offcanvas, Dropdown } from "react-bootstrap";
import { loadUserPayments, getUsers } from "store/users";
import moment from "moment/moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

//  Images
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";
import loadingIcon from "include/images/loader-grey.svg";
import closeIcon from "include/images/dark-close-icon.svg";

//  Components
import Loader from "common/loader";
import NextButton from "common/form/submitButton";
import Form from "common/form/form";
import { Link } from "react-router-dom";
import { truncateAddress } from "utils/misc";

class ListingFlyer extends Form {
  ref = createRef(null);
  scrollRef = createRef();
  state = {
    loading: false,
    loadingWinner: false,
    loadingMore: false,
    winner: "",
    page: 1,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({ loading: true });
      this.props.loadUserPayments(this.props.user?.id, true, (res) => {
        this.setState({ loading: false });
      });
    }
    // if (this.props.getUsers.previousAmoeAwards?.length !== prevProps.getUsers.previousAmoeAwards?.length) {
    //   document.addEventListener("scroll", this.trackScrolling, true);
    // }
    if (!this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.complete();

    if (this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.continuousStart();
  };

  trackScrolling = () => {
    if (this.scrollRef.current?.getBoundingClientRect().bottom - 1000 <= window.innerHeight) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.setState({ loadingMore: true });
        this.props.loadUserPayments(this.props.user?.id, false, () => this.setState({ loadingMore: false }));
        document.removeEventListener("scroll", this.trackScrolling, true);
      });
    }
  };

  render() {
    const { userPayments, filteredCount } = this.props.getUsers;
    const { user } = this.props;
    return (
      <>
        <Loader loaderRef={this.ref} />
        <Offcanvas
          className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2 "}
          show={this.props.showModal}
          onHide={this.props.toggleModal}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header className="offCanvas-header-2">
            <div>
              <h5>Users Payments</h5>{" "}
              <div className="td-img-group d-flex align-items-start m-top-2">
                <div className="round-img m-right-2">
                  {user.image ? (
                    <img src={process.env.REACT_APP_CDN + user.image.small} alt="" />
                  ) : (
                    <img src={userIcon} alt="" />
                  )}
                </div>
                <span style={{ marginTop: -8 }}>
                  {user.userName}
                  <br />
                  {user.email}
                </span>
              </div>
            </div>
            <div
              className="btn_close pointer"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={this.props.toggleModal}
            >
              <img src={closeIcon} alt="" />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="" ref={this.scrollRef}>
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {userPayments.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th width={150}>Payment Type</th>
                            <th width={150}>Payment Status</th>
                            <th width={150}>Amount</th>
                            <th width={150}>Coins</th>
                            <th width={200}>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userPayments?.map((payment, i) => (
                            <tr>
                              <td>{payment?.paymentType === 1 ? "ETH" : "USD"}</td>
                              <td className="ust-tag">
                                <span
                                  className={`w-100 text-center d-block ${
                                    ["inactive", "active", "suspended"][payment?.status]
                                  }`}
                                >
                                  {["PENDING", "COMPLETED", "FAILED"][payment?.status]}
                                </span>
                              </td>

                              <td>${payment?.package?.price?.toLocaleString("en-us") ?? 0}</td>
                              <td className="ust-tag">
                                <Dropdown className=" custom-dropdown mb-2">
                                  <Dropdown.Toggle
                                    id="tdDropdownOne"
                                    className="custom-dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    disabled
                                  >
                                    <span className={`w-100 text-center d-block arcade`}>
                                      Arcade Coins: {payment?.package?.arcadeCoins?.toLocaleString() ?? 0}
                                    </span>
                                  </Dropdown.Toggle>
                                </Dropdown>
                                <Dropdown className=" custom-dropdown my-2">
                                  <Dropdown.Toggle
                                    id="tdDropdownOne"
                                    className="custom-dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    disabled
                                  >
                                    <span className={`w-100 text-center d-block loot`}>
                                      Loot Bucks: {payment?.package?.lootBonusCoins?.toLocaleString("en-us") ?? 0}
                                    </span>
                                  </Dropdown.Toggle>
                                </Dropdown>
                              </td>
                              <td>
                                <div class="small-text">
                                  {moment(payment.createdAt).format("MM/DD/YYYY")}
                                  <br /> <small> {moment(payment.createdAt).format("hh:mm a")}</small>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
            {this.state.loadingMore && (
              <div className="w-100 d-flex justify-content-center load-more">
                <img src={loadingIcon} className="fa-spin" alt="" />
              </div>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadUserPayments: (id, fresh, callback) => dispatch(loadUserPayments(id, fresh, callback)),
});
const mapStateToProps = (state) => ({
  getUsers: getUsers(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListingFlyer));
