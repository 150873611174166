import React, { createRef, Component, Fragment } from "react";
import { toast } from "react-toastify";
import { Offcanvas, Form, Dropdown } from "react-bootstrap";
import {
  getGame,
  markAsIllegitimate,
  refundGame,
  refundIndividualGame,
  markIndividualGameAsRefundedReceived,
  markAsIllegitimateReceived,
  markAsRefundedReceived,
  loadContestGameDetails,
  loadGameMetaData,
} from "store/games";

import moment from "moment/moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _, { get } from "lodash";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { getNumberWithOrdinal } from "utils/misc";
import { Popover, OverlayTrigger } from "react-bootstrap";

//  Images
import userIcon from "include/images/user.png";
import carrentIcon from "include/images/carrent-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import loader from "include/images/loading-icon-grey.svg";
import closeIcon from "include/images/dark-close-icon.svg";
import arrowIcon from "include/images/arrow-down-icon.svg";
import arrowIcon1 from "include/images/arrow-down-icon-1.svg";
import arrowIcon2 from "include/images/arrow-down-icon-2.svg";
import copyIcon from "include/images/copy.svg";
import loadingIcon from "include/images/loader-grey.svg";

//  Components
import Loader from "common/loader";
import NextButton from "common/form/submitButton";
import Select from "common/form/selectSearch";
import AlertModal from "common/alert/alertModal";
import { Joi } from "joi-browser";
import { setFilters } from "utils/localStorageServices";
import { convertDate, formatDate } from "utils/dateConversion";
import AlertSuccess from "common/alert/alertSuccess";
import ReasonFlyer from "./reasonFlyer";
import HistoryFlyer from "common/historyFlyer";
import AlertError from "common/alert/alertError";

class ListingFlyer extends Component {
  ref = createRef(null);
  state = {
    loading: false,
    loadingReason: false,
    showRevertModal: false,
    showReasonFlyer: false,
    reasonType: "",
    gameId: "",
    showHistory: false,
    objectId: "",
    object: null,
    refundIndex: 0,
    game: null,
    metaType: "",
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.props.loadContestGameDetails(this.props.contest?.id, this.props.allGame?.userId, {
        amountType: this.props.amountType,
      });
    }
  };
  revertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showRevertModal: !this.state.showRevertModal });
  };
  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showReasonFlyer: !this.state.showReasonFlyer });
  };
  toggleHistoryFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showHistory: !this.state.showHistory });
  };
  handleMarkAsIllegitimate = (e, reason, callback) => {
    if (e) e.preventDefault();
    this.setState({ loadingReason: true });
    this.props.markAsIllegitimate(
      this.state.gameId.gameId,
      {
        reason: this.state.gameId.isIllegitimate
          ? this.state.gameId.illegitimateDetails
            ? this.state.gameId.illegitimateDetails.reason
            : "invalid game"
          : reason,
        markAsIllegitimate: !this.state.gameId.isIllegitimate,
      },
      (gameRes) => {
        if (gameRes.status === 200) {
          toast(<AlertSuccess message="Information Saved!" />, {
            containerId: 1,
          });
          this.props.markAsIllegitimateReceived({
            gameId: this.state.gameId.gameId,
            id: this.props.id,
            isIllegitimate: this.state.gameId.isIllegitimate,
            reason: reason,
          });
          callback();
          this.setState({ loadingReason: false });
        } else {
          toast(<AlertError message={gameRes.data && gameRes.data.message} />, {
            containerId: 1,
          });
          callback();
          this.setState({ loadingReason: false });
        }
      },
    );
  };
  handleRefund3 = (e, reason, callback) => {
    if (e) e.preventDefault();
    this.setState({ loadingReason: true });
    this.props.refundGame(this.state.gameId?.contest?.id, this.state.gameId.userId, { reason }, (gameRes) => {
      if (gameRes.status === 200) {
        toast(<AlertSuccess message="Information Saved!" />, {
          containerId: 1,
        });
        this.setState({ loadingReason: false });
        this.props.markAsRefundedReceived({
          selectedGameIndex: this.state.selectedGameIndex,
          gameId: this.state.gameId.gameId,
          isRefunded: this.state.gameId.isRefunded,
          reason: reason,
        });
        callback();
      } else {
        toast(<AlertError message={gameRes.data && gameRes.data.message} />, {
          containerId: 1,
        });
        callback();
        this.setState({ loadingReason: false });
      }
    });
  };
  handleRefund = (e, reason, callback) => {
    if (e) e.preventDefault();
    this.setState({ loadingReason: true });
    this.props.refundIndividualGame(this.state.gameId, { reason }, (gameRes) => {
      if (gameRes.status === 200) {
        toast(<AlertSuccess message="Information Saved!" />, {
          containerId: 1,
        });
        this.setState({ loadingReason: false });
        this.props.markIndividualGameAsRefundedReceived({
          selectedGameIndex: this.props.selectedGameIndex,
          gameId: this.state.gameId,
          isRefunded: this.state.gameId.isRefunded,
          reason: reason,
        });
        callback();
      } else {
        toast(<AlertError message={gameRes.data && gameRes.data.message} />, {
          containerId: 1,
        });
        callback();
        this.setState({ loadingReason: false });
      }
    });
  };

  //  Get Game Meta data method
  getMetaData = () => {
    this.props.loadGameMetaData(this.state.game?.gameId, (res) => {
      const data =
        this.state.metaType === "finalMetadata"
          ? res.data?.data?.[this.state.metaType]?.data
          : res.data?.data?.[this.state.metaType];
      navigator.clipboard.writeText(JSON.stringify(data));
      toast(
        <AlertSuccess
          message={`${
            this.state.metaType === "gameData"
              ? "Initial Data"
              : this.state.metaType === "intermediateMetadata"
              ? "Intermediate Metadata"
              : "Final Metadata"
          } copied!`}
        />,
        {
          containerId: 1,
        },
      );
      this.setState({ game: null, metaType: "" });
    });
  };
  render() {
    const { getGame, contest, player, selectedGameIndex } = this.props;
    const rounds = getGame?.contestGameDetails?.games;
    const showRefundButton = rounds?.filter((round, index) => !round.isRefunded).length;
    const allGame = getGame?.contestGameDetails;
    return (
      <>
        <Loader loaderRef={this.ref} />
        <Offcanvas
          className={`offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel3 `}
          show={this.props.showModal}
          onHide={(e) => {
            this.clearState(e);
            this.props.toggleModal(e);
          }}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header className="offCanvas-header-3">
            <div>
              {player && (
                <div className="td-img-group d-flex align-items-start m-top-2">
                  <div className="round-img m-right-2">
                    {player.image ? (
                      <img src={process.env.REACT_APP_CDN + player.image.small} alt="" />
                    ) : (
                      <img src={userIcon} alt="" />
                    )}
                  </div>
                  {player.userName}
                  <br />
                  {player.email}
                </div>
              )}
              {contest && (
                <div className="td-img-group d-flex align-items-start m-top-2">
                  <div className="round-img m-right-2">
                    {contest.image ? (
                      <img src={process.env.REACT_APP_CDN + contest.image.small} alt="" />
                    ) : (
                      <img src={userIcon} alt="" />
                    )}
                  </div>
                  {contest.title}
                  {contest.title && contest.rewardTitle && <br />}
                  {contest.rewardTitle}
                </div>
              )}
            </div>
            <div className="d-flex flex-column justify-content-between align-items-end h-100">
              <div
                className="btn_close pointer ml-40"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={(e) => {
                  this.props.toggleModal(e);
                }}
              >
                <img src={closeIcon} alt="" />
              </div>
              {(contest?.bgGame?.scroringType === 1 || !contest?.bgGame?.scoringType) && (
                <div className="td-img-group d-flex align-items-start m-top-2">
                  Score Calculated on {moment(allGame?.scoreUpdatedAt).format("MMM DD, YYYY")} at{" "}
                  {moment(allGame?.scoreUpdatedAt).format("hh:mm a")}
                </div>
              )}
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="">
              <div className="fwc-body">
                {!this.props.getGame.loading && (
                  <div className="table-responsive">
                    {rounds?.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            {contest?.bgGame?.scoringType === 2 && <th style={{ width: "15%" }}>Refund</th>}
                            {contest?.bgGame?.scoringType === 2 ? <></> : <th>Round</th>}
                            <th>Score </th>
                            <th>Played On </th>
                            {contest?.bgGame?.scoringType === 2 && <th>Duration (in seconds)</th>}
                            <th>AI Engine Scores</th>
                            <th>Metadata</th>
                            <th
                              style={
                                contest?.bgGame?.scoringType
                                  ? contest.bgGame.scoringType === 1
                                    ? {}
                                    : { width: 15 }
                                  : {}
                              }
                            >
                              Status
                            </th>
                            <th>{contest?.bgGame?.scoringType === 2 ? <></> : "Completed At"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rounds?.map((game, index) => {
                            const isNA =
                              !game.isCompleted ||
                              game.isRefunded ||
                              game.refund ||
                              game.isIllegitimate ||
                              game.isCancelled ||
                              game?.contest?.ticketsAwarded;
                            const reason = game?.reasonForInvalidation ? JSON.parse(game?.reasonForInvalidation) : null;
                            const aiEngine = game?.AIEngineResponse ? JSON.parse(game?.AIEngineResponse) : null;
                            return (
                              <tr>
                                {contest?.bgGame?.scoringType === 2 &&
                                  (game.isCancelled ? (
                                    <td>
                                      <div class="td-custom-btn ">
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Popover className="custom-tooltip " data-bs-title="Illegitimate">
                                              <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                                <h4>This contest has been canceled.</h4>
                                              </Popover.Body>
                                            </Popover>
                                          }
                                        >
                                          <div>
                                            <a href="#!" class={`btn btn-outline disabled btn-outline-dark w-100 `}>
                                              Contest Canceled
                                            </a>
                                          </div>
                                        </OverlayTrigger>
                                      </div>
                                    </td>
                                  ) : (
                                    <td>
                                      <div class="td-custom-btn ">
                                        {!isNA ? (
                                          <a
                                            href="#!"
                                            class={`btn btn-outline w-100 `}
                                            onClick={(e) => {
                                              this.setState(
                                                {
                                                  gameId: game.gameId,
                                                  selectedGameIndex: index,
                                                  reasonType: "refund",
                                                },
                                                () => {
                                                  this.alertModalToggle(e);
                                                },
                                              );
                                            }}
                                          >
                                            Refund
                                          </a>
                                        ) : (
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Popover className="custom-tooltip " data-bs-title="Illegitimate">
                                                <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                                  {reason ? (
                                                    <Fragment>
                                                      <h4>{reason}</h4>
                                                    </Fragment>
                                                  ) : game?.isCancelled ? (
                                                    <Fragment>
                                                      <h4>Contest Cancelled</h4>
                                                    </Fragment>
                                                  ) : game?.isRefunded || game?.refund ? (
                                                    <Fragment>
                                                      <h4>Game Refunded</h4>
                                                    </Fragment>
                                                  ) : game.isCompleted == 0 ? (
                                                    <Fragment>
                                                      <h4>Game Incomplete</h4>
                                                    </Fragment>
                                                  ) : (
                                                    <Fragment>
                                                      <h4>Game Illegitimate</h4>
                                                    </Fragment>
                                                  )}
                                                </Popover.Body>
                                              </Popover>
                                            }
                                          >
                                            <div>
                                              <a href="#!" class={`btn btn-outline disabled w-100`}>
                                                {game?.isRefunded || game?.refund ? "Refunded" : "Refund"}
                                              </a>
                                            </div>
                                          </OverlayTrigger>
                                        )}
                                      </div>
                                    </td>
                                  ))}
                                {contest?.bgGame?.scoringType === 2 ? (
                                  <></>
                                ) : (
                                  <td className={`${!game?.round && "opacity-5"}`}>
                                    {game?.round ? (
                                      <Link
                                        to={{
                                          pathname: `/game-details/${game.gameId}`,
                                          state: { game },
                                        }}
                                        className="orange-text"
                                        target="_blank"
                                      >
                                        {game.round}
                                      </Link>
                                    ) : (
                                      "NA"
                                    )}
                                  </td>
                                )}
                                <td>
                                  <div className={`td-text-box `}>
                                    {game.totalScore.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </div>
                                </td>
                                <td>
                                  <div class="small-text">
                                    {moment(game.createdAt).format("MM/DD/YYYY")}
                                    <br /> <small> {moment(game.createdAt).format("hh:mm a")}</small>
                                  </div>
                                </td>
                                {contest?.bgGame?.scoringType === 2 && (
                                  <td className={game?.timeSpan ? "" : "opacity-5"}>
                                    {game?.timeSpan ? game?.timeSpan / 1000 : "NA"}
                                  </td>
                                )}
                                <td className={!aiEngine && "opacity-5"}>
                                  {aiEngine ? (
                                    <div className="d-flex flex-column">
                                      <span>Anomaly Score: {aiEngine?.anomalyScore}</span>
                                      <span>Prediction Score: {aiEngine?.prediction}</span>
                                    </div>
                                  ) : (
                                    "NA"
                                  )}
                                </td>
                                <td>
                                  <div className="d-flex flex-column">
                                    <span
                                      className={`orange-text ${getGame?.loadingMetaData ? "pointer-none" : "pointer"}`}
                                      onClick={() => {
                                        if (getGame?.loadingMetaData) return;
                                        this.setState({ game: game, metaType: "gameData" }, this.getMetaData);
                                      }}
                                    >
                                      {this.state.game?.gameId === game?.gameId &&
                                      getGame?.loadingMetaData &&
                                      this.state.metaType === "gameData" ? (
                                        <img
                                          src={loadingIcon}
                                          className="fa-spin"
                                          style={{ width: 20 }}
                                          alt="loading"
                                        />
                                      ) : (
                                        <>
                                          Initial Data <img src={copyIcon} alt="copy" />
                                        </>
                                      )}
                                    </span>
                                    <span
                                      className={`orange-text ${getGame?.loadingMetaData ? "pointer-none" : "pointer"}`}
                                      onClick={() => {
                                        if (getGame?.loadingMetaData) return;
                                        this.setState(
                                          { game: game, metaType: "intermediateMetadata" },
                                          this.getMetaData,
                                        );
                                      }}
                                    >
                                      {this.state.game?.gameId === game?.gameId &&
                                      getGame?.loadingMetaData &&
                                      this.state.metaType === "intermediateMetadata" ? (
                                        <img
                                          src={loadingIcon}
                                          className="fa-spin"
                                          style={{ width: 20 }}
                                          alt="loading"
                                        />
                                      ) : (
                                        <>
                                          Intermediate Metadata <img src={copyIcon} alt="copy" />
                                        </>
                                      )}
                                    </span>
                                    <span
                                      className={`orange-text ${getGame?.loadingMetaData ? "pointer-none" : "pointer"}`}
                                      onClick={() => {
                                        if (getGame?.loadingMetaData) return;
                                        this.setState({ game: game, metaType: "finalMetadata" }, this.getMetaData);
                                      }}
                                    >
                                      {this.state.game?.gameId === game?.gameId &&
                                      getGame?.loadingMetaData &&
                                      this.state.metaType === "finalMetadata" ? (
                                        <img
                                          src={loadingIcon}
                                          className="fa-spin"
                                          style={{ width: 20 }}
                                          alt="loading"
                                        />
                                      ) : (
                                        <>
                                          Final Metadata <img src={copyIcon} alt="copy" />
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  {!isNA ? (
                                    "Valid"
                                  ) : (
                                    <OverlayTrigger
                                      overlay={
                                        <Popover id="popoverPannel" className="custom-tooltip ">
                                          <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                            <h4>
                                              {reason
                                                ? reason
                                                : game.isCancelled
                                                ? "Contest Cancelled"
                                                : game.isRefunded || game?.refund
                                                ? "Game Refunded"
                                                : game.isIllegitimate
                                                ? "Game Illegitimate"
                                                : game.isCompleted === 0
                                                ? "Game Incomplete"
                                                : "Invalid"}
                                            </h4>
                                          </Popover.Body>
                                        </Popover>
                                      }
                                    >
                                      <div
                                        className="pointer"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-custom-class="custom-tooltip "
                                      >
                                        Invalid
                                      </div>
                                    </OverlayTrigger>
                                  )}
                                </td>
                                <td>
                                  {contest?.bgGame?.scoringType === 2 ? (
                                    <></>
                                  ) : (
                                    <div class="small-text">
                                      {moment(game.completedAt).format("MM/DD/YYYY")}
                                      <br /> <small> {moment(game.completedAt).format("hh:mm a")}</small>
                                    </div>
                                  )}
                                  {game.isIllegitimate ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Popover
                                          id="popoverPannel"
                                          className="custom-tooltip "
                                          data-bs-title="Illegitimate"
                                        >
                                          <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                            <h4>Reason for marking illegitimate</h4>
                                            <p>{game?.illegitimateDetails?.reason}</p>
                                            <h4>Date</h4>
                                            <p>
                                              {moment(game?.illegitimateDetails?.date).format("MM/DD/YYYY hh:mm a")}
                                            </p>
                                          </Popover.Body>
                                        </Popover>
                                      }
                                    >
                                      <div
                                        class="td-shape"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-custom-class="custom-tooltip"
                                        data-bs-title="Illegitimate"
                                      ></div>
                                    </OverlayTrigger>
                                  ) : (
                                    (game.isRefunded || game?.refund) && (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Popover
                                            id="popoverPannel"
                                            className="custom-tooltip "
                                            data-bs-title="Illegitimate"
                                          >
                                            <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                              <h4>Reason for refund</h4>
                                              <p>{game?.refund?.reason}</p>
                                              <h4>Date</h4>
                                              <p>{moment(game?.refund?.date).format("MM/DD/YYYY hh:mm a")}</p>
                                            </Popover.Body>
                                          </Popover>
                                        }
                                      >
                                        <div
                                          class="td-shape yellow-bg"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          data-bs-custom-class="custom-tooltip"
                                          data-bs-title="Illegitimate"
                                        ></div>
                                      </OverlayTrigger>
                                    )
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        <AlertModal
          alertModalToggle={this.toggleModal}
          alertModalShow={this.state.showAlert}
          title="Alert!"
          classData="z-index-high"
          description="Are you sure you want to mark this user as winner?"
          alertType="Yes"
          onPress={this.markWinner}
          loading={this.state.loadingWinner}
        />{" "}
        <HistoryFlyer
          showModal={this.state.showHistory}
          toggleModal={this.toggleHistoryFlyer}
          moduleName={"game"}
          objectId={this.state.objectId}
          title={contest?.title}
        />
        <ReasonFlyer
          showModal={this.state.showReasonFlyer}
          toggleModal={this.alertModalToggle}
          handleSubmit={this.state.reasonType === "refund" ? this.handleRefund : this.handleMarkAsIllegitimate}
          description={
            this.state.reasonType === "refund"
              ? "Are you sure you want to refund the entry fee?"
              : "Are you sure you want to mark this game as Illegitimate?"
          }
          title={this.state.reasonType === "refund" ? "Refund Entry Fee" : "Mark Game as Illegitimate"}
          game={this.state.gameId}
        />{" "}
        <AlertModal
          alertModalToggle={this.revertModalToggle}
          alertModalShow={this.state.showRevertModal}
          title="Alert"
          classData="z-index-high"
          description={"Are you sure you want to mark this game as legitimate again?"}
          onPress={(e) => {
            this.handleMarkAsIllegitimate(e, "", () => {
              this.revertModalToggle();
            });
          }}
          loading={this.state.loadingReason}
          alertType="Yes"
        />{" "}
        {(this.state.showReasonFlyer || this.state.showHistory) && (
          <div
            className="modal-backdrop z-index-high show fade"
            onClick={this.state.showHistory ? this.toggleHistoryFlyer : this.alertModalToggle}
          ></div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  markAsIllegitimate: (id, data, callback) => dispatch(markAsIllegitimate(id, data, callback)),
  refundGame: (contestId, userId, data, callback) => dispatch(refundGame(contestId, userId, data, callback)),
  refundIndividualGame: (gameId, data, callback) => dispatch(refundIndividualGame(gameId, data, callback)),
  markAsIllegitimateReceived: (payLoad, callback) => dispatch(markAsIllegitimateReceived(payLoad, callback)),
  markAsRefundedReceived: (payLoad, callback) => dispatch(markAsRefundedReceived(payLoad, callback)),
  markIndividualGameAsRefundedReceived: (payLoad, callback) =>
    dispatch(markIndividualGameAsRefundedReceived(payLoad, callback)),
  loadContestGameDetails: (contestId, gameId, params, callback) =>
    dispatch(loadContestGameDetails(contestId, gameId, params, callback)),
  loadGameMetaData: (id, callback) => dispatch(loadGameMetaData(id, callback)),
});
const mapStateToProps = (state) => ({
  getGame: getGame(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListingFlyer));
