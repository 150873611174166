import React, { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getGame, loadGameDetails, markAsIllegitimate, refundGame } from "store/games";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import Timer from "react-compound-timer";
import ReactTooltip from "react-tooltip";
import { Dropdown } from "react-bootstrap";
import { ethToUSD } from "utils/externalApiCaller";
import { convertDate, formatDate } from "utils/dateConversion";

//  Images
import moreIcon from "include/images/more-horizontal.svg";
import backIcon from "include/images/chevron-left.svg";
import userIcon from "include/images/user.png";
import circleIcon from "include/images/help-circle.svg";
import clockIcon from "include/images/clock.svg";
import creditIcon from "include/images/burnghost-credit.svg";
import ethIcon from "include/images/eth-icon-1.svg";
import loader from "include/images/loading-icon.svg";
import starIcon from "include/images/1-star.svg";
import starIcon1 from "include/images/star-fill.svg";
import checkSquare from "include/images/check-square.svg";
import gridImg from "include/images/grid-img-2.jpg";
import playerImg from "include/images/new-player-1.jpg";
import error404 from "include/images/404.svg";

//  Components
import Form from "common/form/form";
import Header from "common/header";
import SideBar from "../../common/sidebar";
import Loader from "common/loader";
import AlertModal from "common/alert/alertModal";
import AlertSuccess from "common/alert/alertSuccess";
import ReasonFlyer from "./reasonFlyer";
import AlertError from "common/alert/alertError";

class gameDetails extends Form {
  videoRef = createRef(null);
  ref = createRef(null);
  state = {
    showCancelModal: false,
    showDeleteModal: false,
    showInformation: false,
    ethVPrice: "",
    loadingDelete: false,
    loading: false,
    loadingCancel: false,
    markAsFeaturedLoading: false,
    markAsFeaturedLoading: false,
    showReasonFlyer: false,
    loadingReason: false,
    gameId: "",
    reasonType: "",
  };
  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showReasonFlyer: !this.state.showReasonFlyer });
  };
  revertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showRevertModal: !this.state.showRevertModal });
  };
  handleMarkAsIllegitimate = (e, reason, callback) => {
    if (e) e.preventDefault();
    this.setState({ loadingReason: true });
    this.props.markAsIllegitimate(
      this.state.gameId.gameId,
      {
        reason: this.state.gameId.isIllegitimate ? this.state.gameId.illegitimateDetails.reason : reason,
        markAsIllegitimate: !this.state.gameId.isIllegitimate,
      },
      (gameRes) => {
        if (gameRes.status === 200) {
          toast(<AlertSuccess message="Information Saved!" />, {
            containerId: 1,
          });
          setTimeout(() => {
            this.setState({ loadingReason: false });
            const params = this.props.match.params;
            this.props.loadGameDetails(params.id, () => {
              this.setState({ loading: false, loadingReason: false });
              callback();
            });
          }, 800);
        } else {
          toast(<AlertError message={gameRes.data && gameRes.data.message} />, {
            containerId: 1,
          });
          callback();
          this.setState({ loadingReason: false });
        }
      },
    );
  };
  handleRefund = (e, reason, callback) => {
    if (e) e.preventDefault();
    this.setState({ loadingReason: true });
    this.props.refundGame(this.state.gameId.gameId, { reason }, (gameRes) => {
      if (gameRes.status === 200) {
        toast(<AlertSuccess message="Information Saved!" />, {
          containerId: 1,
        });
        setTimeout(() => {
          this.setState({ loadingReason: false });
          const params = this.props.match.params;
          this.props.loadGameDetails(params.id, () => {
            this.setState({ loading: false, loadingReason: false });
            callback();
          });
        }, 800);
      } else {
        toast(<AlertError message={gameRes.data && gameRes.data.message} />, {
          containerId: 1,
        });
        callback();
        this.setState({ loadingReason: false });
      }
    });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getGame.loading && this.props.getGame.loading !== prevProps.getGame.loading)
      this.ref && this.ref.current && this.ref.current.complete();

    if (this.props.getGame.loading && this.props.getGame.loading !== prevProps.getGame.loading)
      this.ref && this.ref.current && this.ref.current.continuousStart();
  };
  componentDidMount = () => {
    this.setState({ loading: true });
    const params = this.props.match.params;
    this.props.loadGameDetails(params.id, () => {
      this.setState({ loading: false });
    });
  };
  render() {
    const game = this.props.getGame.gameDetails;
    const contest = game && game.contest;

    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="games" />
          {game && (
            <>
              {" "}
              {!this.state.loading && (
                <div className="main-content-wrapper position-relative">
                  {contest && (
                    <div className="mcw-header d-flex align-items-center">
                      <h1>
                        <span className="round-img ">
                          {game.user && game.user.image ? (
                            <img
                              src={process.env.REACT_APP_CDN + game.user.image.small}
                              className="round-img m-right-2"
                              alt=""
                            />
                          ) : (
                            <img src={userIcon} alt="" className="round-img m-right-2" />
                          )}
                        </span>
                        {game.user ? (game.user.userName ? game.user.userName : game.user.email) : game.userId}
                      </h1>
                    </div>
                  )}
                  <div className="fwc-wrapper">
                    <a
                      href="/"
                      className="back-link-btn d-flex align-items-center"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.goBack();
                      }}
                    >
                      <img src={backIcon} alt="" /> Back
                    </a>
                    {contest && (
                      <div className="grid-card-details-row d-flex flex-wrap">
                        {" "}
                        <div className="gcdr-img-box">
                          <div className="gcdr-img-outer text-white">
                            <div className="gcdr-image">
                              {contest.rewardAnimationURL ? (
                                !contest.rewardAnimationURL.includes(".mp4") &&
                                !contest.rewardAnimationURL.includes(".mov") ? (
                                  <>
                                    <iframe src={contest.rewardAnimationURL} alt="" />
                                  </>
                                ) : (
                                  <video
                                    className=""
                                    src={contest.rewardAnimationURL}
                                    autoPlay
                                    controls
                                    ref={this.videoRef}
                                    loop
                                    onCanPlay={(e) => {
                                      this.videoRef.play();
                                    }}
                                  ></video>
                                )
                              ) : (
                                <img
                                  src={
                                    contest.image.medium.includes("https://")
                                      ? contest.image.medium
                                      : process.env.REACT_APP_CDN + contest.image.medium
                                  }
                                  alt=""
                                />
                              )}
                            </div>

                            <div className="gcdr-group d-flex flex-wrap">
                              <div className="gcdr-item flex-grow-1 w-auto">
                                <h6 className="yellow-text d-flex align-items-baseline">
                                  {" "}
                                  <img src={creditIcon} className="me-1 credit-icon" alt="" />
                                  {contest.entryFee}
                                </h6>
                                <p className="opacity-50 yellow-text">
                                  <strong>ENTRY FEE</strong>
                                </p>
                              </div>
                              <div className="gcdr-item flex-grow-1 w-auto">
                                <ReactTooltip
                                  place="top"
                                  effect="solid"
                                  className="custom-tooltip"
                                  type="dark"
                                  transparent={false}
                                />
                                <h6>
                                  $
                                  {contest.rewardValue.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </h6>
                                <p>
                                  <strong>Value</strong>
                                </p>
                              </div>
                              {contest.maxEntries && (
                                <div className="gcdr-item flex-grow-1 w-auto">
                                  <h6>
                                    {contest.maxEntries - contest.entries}/{contest.maxEntries}
                                  </h6>
                                  <p>
                                    <strong>Entries Left</strong>
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>{" "}
                          {contest.endDate && (
                            <div className="clockdiv d-flex justify-content-center" id="clockdiv">
                              <Timer
                                initialTime={convertDate(contest.endDate).diff(moment().tz("America/New_York"))}
                                direction="backward"
                                startImmediately={true}
                              >
                                {(control) => {
                                  this.ref = control;

                                  return (
                                    <>
                                      <div className="colckdiv-item">
                                        <span>
                                          {" "}
                                          <Timer.Days className="days"></Timer.Days>
                                        </span>
                                        <div className="smalltext">Days</div>
                                      </div>
                                      <div className="colckdiv-item">
                                        <span>
                                          {" "}
                                          <Timer.Hours className="hours"></Timer.Hours>
                                        </span>
                                        <div className="smalltext">Hours</div>
                                      </div>
                                      <div className="colckdiv-item">
                                        <span>
                                          {" "}
                                          <Timer.Minutes className="minutes"></Timer.Minutes>
                                        </span>
                                        <div className="smalltext">Minutes</div>
                                      </div>
                                      <div className="colckdiv-item">
                                        <span>
                                          <Timer.Seconds className="seconds"></Timer.Seconds>
                                        </span>
                                        <div className="smalltext">Seconds</div>
                                      </div>
                                    </>
                                  );
                                }}
                              </Timer>
                            </div>
                          )}
                        </div>
                        <div className="gcdr-text-box position-relative">
                          <div>
                            <div className="gcdr-heading">
                              <h2>
                                {game.user ? (game.user.userName ? game.user.userName : game.user.email) : game.userId}
                              </h2>
                            </div>{" "}
                            <div className="gcdr-widget-wrapper">
                              <div className="gcdr-widget-box">
                                <div className="gcdr-details-box">
                                  <h4>
                                    Contest: <span className="game-details-value">{contest.title}</span>
                                  </h4>
                                  <h4>
                                    Total Score:{" "}
                                    <span className="game-details-value">
                                      {game.totalScore.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </span>
                                  </h4>
                                  {game.speedBonusTotalScore && (
                                    <h4>
                                      Speed Bonus:{" "}
                                      <span className="game-details-value">
                                        {game.speedBonusTotalScore.toLocaleString()}
                                      </span>
                                    </h4>
                                  )}
                                  {game.streakBonusTotalScore && (
                                    <h4>
                                      Streak Bonus:{" "}
                                      <span className="game-details-value">
                                        {game.streakBonusTotalScore.toLocaleString()}
                                      </span>
                                    </h4>
                                  )}
                                  <h4>
                                    Power-Ups: <span className="game-details-value">{game.pointBoost ? 1 : 0} / 1</span>
                                  </h4>{" "}
                                  <h4>
                                    Round: <span className="game-details-value">{game.round}</span>
                                  </h4>{" "}
                                  <h4>
                                    Played On:{" "}
                                    <span className="game-details-value">
                                      {moment(game.createdAt).format("MM/DD/YYYY hh:mm a")}
                                    </span>
                                  </h4>{" "}
                                  <h4>
                                    Status:{" "}
                                    <span className="game-details-value">
                                      {game?.isCompleted ? "Completed" : "Incomplete"}
                                    </span>
                                  </h4>{" "}
                                  {game.user && (
                                    <>
                                      <h4>
                                        Player Email: <span className="game-details-value">{game.user.email}</span>
                                      </h4>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="gcdr-widget-wrapper">
                      {game.gameQuestions.map((question, index) => (
                        <div className="gcdr-widget-box">
                          <h4>
                            {index + 1}. {question.questionText}
                          </h4>
                          <div className="new-player-wrapper  m-0">
                            <ul className="new-player-list d-flex flex-wrap">
                              {question.options &&
                                question.options.map((opt, index) => (
                                  <li className="new-player-item">
                                    <div
                                      className={`new-player-box position-relative w-100 h-100 disable-hover ${
                                        opt.id === question.answer ? "selected-player" : "disable-player"
                                      }   card1`}
                                    >
                                      {opt.name ? (
                                        <>
                                          <div
                                            class={`new-player-button d-flex flex-column ${
                                              (opt.value || opt.value === 0) && "vbl-low"
                                            } ${opt.id === question.answer ? "correct-btn" : "disable-answer-btn"}`}
                                          >
                                            <div class="new-player-btn btn-sm text-center">{opt.name}</div>
                                          </div>
                                        </>
                                      ) : (
                                        <div className="npi-icon-outer">
                                          <div className="npi-icon-box">
                                            <i
                                              className={`fas ${opt.id === question.answer ? "fa-check" : "fa-ban"}`}
                                            ></i>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div className="gcdr-details-box mt-5">
                            <h4>
                              Score:{" "}
                              <span className="game-details-value">
                                {" "}
                                {!question.score || question.score === -1
                                  ? 0
                                  : question.score.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                              </span>
                            </h4>{" "}
                            <h4>
                              Points:{" "}
                              <span className="game-details-value">
                                {!question.points || question.points === -1 ? 0 : question.points}
                              </span>
                            </h4>
                            <h4>
                              Time Taken:{" "}
                              <span className="game-details-value">
                                {question.answer === "TIMEOUT"
                                  ? "Time Out"
                                  : !question.timeTaken
                                  ? 0
                                  : question.timeTaken.toFixed(2)}{" "}
                                {question.answer !== "TIMEOUT" && "Seconds"}
                              </span>
                            </h4>
                            {(question.removeOne || question.pointBoost) && (
                              <h4>
                                Power Up:{" "}
                                <span className="game-details-value">
                                  {question.removeOne ? "Remove One" : "Point Boost"}
                                </span>
                              </h4>
                            )}
                            {question.speedBonus && (
                              <h4>
                                Speed Bonus: <span className="game-details-value">{question.speedBonusScore}</span>
                              </h4>
                            )}{" "}
                            {question.streakBonus && (
                              <h4>
                                Streak Bonus: <span className="game-details-value">{question.streakBonusScore}</span>
                              </h4>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {this.state.showReasonFlyer && <div className="modal-backdrop show fade" onClick={this.alertModalToggle}></div>}
        <ReasonFlyer
          showModal={this.state.showReasonFlyer}
          toggleModal={this.alertModalToggle}
          handleSubmit={this.state.reasonType === "refund" ? this.handleRefund : this.handleMarkAsIllegitimate}
          description={
            this.state.reasonType === "refund"
              ? "Are you sure you want to refund the entry fee?"
              : "Are you sure you want to mark this game as Illegitimate?"
          }
          title={this.state.reasonType === "refund" ? "Refund Game" : "Mark Game as Illegitimate"}
          game={this.state.gameId}
        />{" "}
        <AlertModal
          alertModalToggle={this.revertModalToggle}
          alertModalShow={this.state.showRevertModal}
          title="Alert"
          description={"Are you sure you want to mark this game as legitimate again?"}
          onPress={(e) => {
            this.handleMarkAsIllegitimate(e, "", () => {
              this.revertModalToggle();
            });
          }}
          loading={this.state.loadingReason}
          alertType="Yes"
        />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadGameDetails: (id, callback) => dispatch(loadGameDetails(id, callback)),
  markAsIllegitimate: (id, data, callback) => dispatch(markAsIllegitimate(id, data, callback)),
  refundGame: (id, data, callback) => dispatch(refundGame(id, data, callback)),
});
const mapStateToProps = (state) => ({
  getGame: getGame(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(gameDetails));
