import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Joi from "joi-browser";
import { getUser, signIn } from "store/user";
import { toast } from "react-toastify";
import { setToken, setProfile } from "utils/localStorageServices";

//  Images
import loginBg from "include/images/login-bg.png";
import logo from "include/images/burnghostHorizontal.png";

//  Component
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import AlertError from "common/alert/alertError";
import { Link } from "react-router-dom";

class SignIn extends Form {
  state = {
    data: { email: "", password: "" },
    errors: {},
    loading: false,
  };
  schema = {
    email: Joi.string()
      .email()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          if (err.type === "string.email") {
            if (err.context.value === "") {
              err.message = "Email is required";
            } else {
              err.message = "Email is invalid.";
            }
          }
          switch (err.type) {
            case "any.empty":
              err.message = "Email is required.";
              break;
            case "string.max":
              err.message = "Email is invalid.";
              break;
          }
        });
        return errors;
      }),
    password: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          if (err.type === "string.regex.name") {
            if (err.context.value === "") {
              err.message = "Password is required.";
            } else {
              err.message = "The password does not meet the password policy requirements.";
            }
          }
          switch (err.type) {
            case "any.empty":
              err.message = "Password is required.";
              break;
            case "string.min":
              err.message = "The password does not meet the password policy requirements.";
              break;
          }
        });
        return errors;
      }),
  };
  doSubmit = () => {
    this.setState({ loading: true });
    const data = { ...this.state.data };
    this.props.signIn(data, this.callBack);
  };
  callBack = (signInRes) => {
    this.setState({ loading: false, disabled: false });
    if (signInRes && signInRes.status === 200) {
      const data = signInRes.data;
      const { accessToken, refreshToken } = data;
      setToken(refreshToken, accessToken);
      setProfile(data.profile);
      this.props.history.push("/games?scoringType=2");
    } else {
      toast(<AlertError message={signInRes.data && signInRes.data.message} />, {
        containerId: 1,
      });
    }
  };
  render() {
    return (
      <div className="member-container w-100 vh-100 d-flex flex-wrap">
        <div className="member-left text-white">
          <Link to="/" className="member-logo-area">
            <img src={logo} alt="" />
          </Link>
          <div className="member-intro-desc">
            <h5>PLAY TO WIN</h5>
            <p>NFT COMPETITIONS </p>
          </div>

          <img src={loginBg} alt="" className="member-image" />
        </div>
        <div className="member-right">
          <div className="mr-inner">
            <div className="mr-inner-content">
              <h1>LOGIN</h1>
              {this.renderInput("email", "Email")}
              {this.renderPasswordInput("password", "Password")}
              <div className="form-group form-btn text-end">
                <NextButton
                  handleSubmit={this.handleSubmit}
                  label="Login"
                  loading={this.state.loading}
                  classData={"btn btn-default "}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  signIn: (data, callback) => dispatch(signIn(data, callback)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
