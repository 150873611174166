import Joi from "joi-browser";
import { createRef } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//  Images
import dotsIcon from "include/images/dots.svg";
import emptyIcon from "include/images/nrf.svg";
import plusIcon from "include/images/plus.svg";
import sortIcon from "include/images/sort-icon.svg";

//  Components
import AlertError from "common/alert/alertError";
import DeleteModal from "common/alert/alertModal";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import { toast } from "react-toastify";
import { deleteLootbox, getLootbox, loadLootboxes, publishLootbox } from "store/lootbox";

class LootboxListing extends Form {
  ref = createRef(null);
  state = {
    sorting: { sort: "createdAt", order: "desc" },
    loading: false,
    showDeleteModal: false,
    showPublishModal: false,
    loadingDelete: false,
    loadingFilters: false,
    loadingPublish: false,
    lootboxPublished: false,
    showFilters: false,
    deleteId: "",
    loadingReset: false,
    contest: "",
    publishId: "",
    page: 1,
    appliedFilters: {},
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getLootbox.loading && this.props.getLootbox.loading !== prevProps.getLootbox.loading)
      this.ref.current.complete();

    if (this.props.getLootbox.loading && this.props.getLootbox.loading !== prevProps.getLootbox.loading)
      this.ref.current.continuousStart();
  };

  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  alertPublishModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showPublishModal: !this.state.showPublishModal });
  };
  componentDidMount = () => {
    this.setState({ loading: true });
    const { sort, order } = this.state.sorting;
    const params = {
      sort,
      order,
    };
    params.page = this.state.page;
    this.props.loadLootboxes(params, () => {
      this.setState({ loading: false });
    });
  };

  //  Filters
  schema = {
    keyword: Joi.string().allow(""),
    valueMin: Joi.string().allow(""),
    valueMax: Joi.string().allow(""),
    category: Joi.object().allow({}),
    subcategory: Joi.object().allow({}),
    status: Joi.object().allow({}),
    showCount: Joi.string().allow(""),
    startDate: Joi.date().allow("", null),
    endDate: Joi.date().allow("", null),
    maxEntriesMax: Joi.string().allow(""),
    maxEntriesMin: Joi.string().allow(""),
    entryFeeMax: Joi.string().allow(""),
    entryFeeMin: Joi.string().allow(""),
  };
  handleDelete = (e) => {
    if (e) e.preventDefault();
    this.setState({ loadingDelete: true });
    this.props.deleteLootbox(this.state.deleteId, (lootboxResponse) => {
      if (lootboxResponse.status === 200) {
        toast(<AlertSuccess message="Deleted!" />, {
          containerId: 1,
        });
        this.searchFilters();
      } else {
        toast(<AlertError message={lootboxResponse.data && lootboxResponse.data.message} />, {
          containerId: 1,
        });
        this.setState({ loadingDelete: false });
        this.alertModalToggle();
      }
    });
  };
  handleTogglePublish = (e) => {
    if (e) e.preventDefault();
    this.setState({ loadingPublish: true });
    this.props.publishLootbox(this.state.publishId, (lootboxResponse) => {
      if (lootboxResponse.status === 200) {
        toast(<AlertSuccess message={this.state.lootboxPublished ? "Unpublished!" : "Published!"} />, {
          containerId: 1,
        });
        this.searchFilters();
      } else {
        toast(<AlertError message={lootboxResponse.data && lootboxResponse.data.message} />, {
          containerId: 1,
        });
        this.setState({ loadingPublish: false });
        this.alertPublishModalToggle();
      }
    });
  };
  searchFilters = (e) => {
    if (e) e.preventDefault();
    this.setState({ loadingFilters: true });
    const { sort, order } = this.state.sorting;
    const params = {
      sort,
      order,
    };
    params.page = this.state.page;
    this.props.loadLootboxes(params, () => {
      this.setState({
        loadingDelete: false,
        showDeleteModal: false,
        loadingPublish: false,
        showPublishModal: false,
      });
    });
  };
  sortTypes = [
    { name: "Create Date - Asc", sorting: { sort: "createdAt", order: "asc" } },
    { name: "Create Date - Desc", sorting: { sort: "createdAt", order: "desc" } },
    { name: "Update Date - Asc", sorting: { sort: "updatedAt", order: "asc" } },
    { name: "Update Date - Desc", sorting: { sort: "updatedAt", order: "desc" } },
    { name: "Number Of Distributions - Asc", sorting: { sort: "numberOfDistribution", order: "asc" } },
    { name: "Number Of Distributions - Desc", sorting: { sort: "numberOfDistribution", order: "desc" } },
    { name: "Price Per Spin - Asc", sorting: { sort: "pricePerSpin", order: "asc" } },
    { name: "Price Per Spin - Desc", sorting: { sort: "pricePerSpin", order: "desc" } },
  ];
  sort = (sorting) => {
    this.setState({ sorting: sorting, page: 1 }, () => {
      this.searchFilters();
    });
  };
  render() {
    const { lootboxes, totalCount, filteredCount } = this.props.getLootbox;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="lootbox" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1 className="camelCase">Loot Boxes {totalCount > 0 && `(${totalCount})`}</h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                {" "}
                <div className="fmb-box"></div>
                <div className="fmb-box">
                  <Dropdown className="filter-link-box custom-dropdown dropdown-lg position-relative">
                    <Dropdown.Toggle
                      className="dropdown-toggle custom-dropdown-toggle"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={sortIcon} alt="sort icon" />
                      <span>Sort</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                      {this.sortTypes?.map(({ name, sorting }, index) => (
                        <>
                          <Dropdown.Item
                            key={index}
                            className={`dropdown-item ${
                              this.state.sorting.order === sorting?.order &&
                              this.state.sorting.sort === sorting?.sort &&
                              "orange-text"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              this.sort(sorting);
                            }}
                          >
                            {name}
                          </Dropdown.Item>
                          {index % 2 === 1 && (
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                          )}
                        </>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {/* <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        showFilters: !this.state.showFilters,
                      });
                    }}
                  >
                    <img src={filterIcon} /> <span>Filters</span>
                  </a>
                </div> */}
                <div className="fmb-box">
                  <Link to="/add-lootbox" className="btn btn-default btn-sm">
                    <img src={plusIcon} alt="" /> Add a Loot Box
                  </Link>
                </div>
              </div>
            </div>
            <div className="fwc-wrapper">
              {/* {this.state.showFilters && (
                <div className="fwc-head ">
                  <div className="fwc-inner">
                    <ul className="filter-mode-list d-flex flex-wrap align-items-center">
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("keyword", "Keyword")}</div>
                      </li>

                      <li className="flex-fill">
                        <div className="fml-box">
                          <div className="fml-input-group d-flex align-items-center">
                            <div className="fml-input-group-item flex-grow-1">
                              {" "}
                              {this.renderDateInput("startDate", "Start Date & Time ( In EST )", null)}
                            </div>
                            <div className="fml-input-group-item flex-grow-1">
                              {" "}
                              {this.renderDateInput("endDate", "End Date & Time ( In EST )", this.getEndDate())}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="fwc-btn d-flex align-items-center justify-content-end">
                      <NextButton
                        handleSubmit={this.resetFilters}
                        classData="btn-text pointer"
                        label="Reset"
                        loading={this.state.loadingReset}
                      />
                      <NextButton
                        handleSubmit={() => this.setState({ page: 1 }, this.searchFilters)}
                        classData="btn btn-primary btn-sm"
                        label="Search"
                        loading={this.state.loadingFilters}
                      />
                    </div>
                  </div>{" "}
                  {!_.isEmpty(this.state.appliedFilters) && (
                    <div className="filter-tag-container d-flex align-items-center ">
                      <div className="fwc-left">
                        <div className="filter-tag-list">
                          <p>filter applied :</p>
                          {Object.keys(this.state.appliedFilters).map((key) => (
                            <div className="filter-tag-item">
                              <span className="camelCase">
                                {key === "status"
                                  ? "Status"
                                  : key === "startDate"
                                  ? "Start Date & Time ( In EST )"
                                  : key === "endDate"
                                  ? "End Date & Time ( In EST )"
                                  : key}
                              </span>{" "}
                              :{" "}
                              {key === "status"
                                ? this.state.appliedFilters[key].value
                                : key === "startDate" || key === "endDate"
                                ? formatDate(this.state.appliedFilters[key], "MM/DD/YYYY hh:mm A")
                                : this.state.appliedFilters[key]}
                              <a
                                href="#!"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.removeFilter(key);
                                }}
                              >
                                <img src={closeIcon} alt="" />
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )} */}
              {this.state.loading ? (
                <></>
              ) : lootboxes?.length === 0 ? (
                <>
                  {" "}
                  <div className="fwc-body">
                    <div className="no-record-found-container">
                      <div className="nfr-box">
                        <img src={emptyIcon} alt="No Record Found" />
                        <div className="nrf-text">
                          <h5>No Records Found!</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <ul className="grid-card-list d-flex flex-wrap">
                    {lootboxes?.map((lootbox, i) => (
                      <li className="grid-card-item" key={lootbox?.id}>
                        <div className="grid-card-box position-relative text-white">
                          <div className="gc-header d-flex flex-wrap">
                            <div className="gc-header-left width50">
                              <h5
                                className="pointer two-line-ellipsis width150"
                                onClick={(e) => {
                                  this.props.history.push({
                                    pathname: `/lootbox/${lootbox?.slug}`,
                                    state: { lootbox: lootbox },
                                  });
                                }}
                              >
                                {lootbox.title}
                              </h5>
                            </div>
                            <div className="gc-header-right ms-auto d-flex flex-column align-items-end justify-content-end">
                              {" "}
                              {((lootbox?.processingStatus === "COMPLETED" && lootbox?.locked) || !lootbox?.locked) && (
                                <Dropdown className="grid-dropdown custom-dropdown">
                                  <Dropdown.Toggle
                                    className="dropdown-toggle custom-dropdown-toggle"
                                    id="dropdownMenuLink"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img src={dotsIcon} alt="" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                                    {lootbox.locked ? (
                                      <Dropdown.Item
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          this.setState(
                                            { publishId: lootbox?._id, lootboxPublished: lootbox?.published },
                                            () => this.alertPublishModalToggle(e),
                                          );
                                        }}
                                      >
                                        {lootbox?.published ? "Unpublish" : "Publish"}
                                      </Dropdown.Item>
                                    ) : (
                                      <Dropdown.Item
                                        className="dropdown-item delete"
                                        onClick={(e) => {
                                          this.setState({ deleteId: lootbox?._id }, () => this.alertModalToggle(e));
                                        }}
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                            </div>
                          </div>
                          <div className="gc-img-box">
                            <div
                              className="pc-image pointer"
                              onClick={(e) => {
                                this.props.history.push({
                                  pathname: `/lootbox/${lootbox?.slug}`,
                                  state: { lootbox: lootbox },
                                });
                              }}
                            >
                              <img src={process.env.REACT_APP_CDN + lootbox?.image?.medium} alt="" />
                            </div>

                            {lootbox?.published && <div class="badge orange-bg">Published</div>}
                            {lootbox?.locked && (
                              <div class="badge cancelled" style={{ left: lootbox?.published ? 110 : 25 }}>
                                Locked
                              </div>
                            )}
                          </div>
                          <div className={`gc-footer gc-intro-group d-flex justify-content-between w-100`}>
                            <div className={`gc-intro-item text-left`}>
                              <h6>{lootbox?.pricePerSpin?.toLocaleString() ?? 0} Loot Bucks</h6>
                              <p style={{ maxWidth: 100 }}>
                                <strong>Price per spin</strong>
                              </p>
                            </div>
                            <div className={`gc-intro-item text-right`}>
                              <h6>{lootbox?.totalValue?.toLocaleString() ?? 0}</h6>
                              <p style={{ maxWidth: 130 }}>
                                <strong>Total Value (In USD)</strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}{" "}
                  </ul>
                </>
              )}
              {!this.state.loading && !(this.state.page === 1 && lootboxes?.length === 0) && (
                <div className="page-pagination">
                  <ul className="pagination  justify-content-end">
                    <li className="page-item">
                      <a
                        class={`page-link ${this.state.page === 1 && "disabled"}`}
                        href="!#"
                        aria-label="Previous"
                        onClick={(e) => {
                          e.preventDefault();
                          if (this.state.page > 1) {
                            this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                          }
                        }}
                      >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                    </li>
                    <li className="page-item active">
                      <a
                        className="page-link"
                        href="!#"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        {this.state.page}
                      </a>
                    </li>
                    {lootboxes?.length >= 50 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="!#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                          }}
                        >
                          {this.state.page + 1}
                        </a>
                      </li>
                    )}
                    <li className="page-item">
                      <a
                        class={`page-link ${lootboxes?.length < 50 && "disabled"}`}
                        href="!#"
                        aria-label="Next"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                        }}
                      >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>{" "}
        <DeleteModal
          alertModalToggle={this.alertPublishModalToggle}
          alertModalShow={this.state.showPublishModal}
          title="Alert"
          description={`Are you sure you want to ${
            this.state.lootboxPublished ? "unpublish" : "publish"
          } this loot box?`}
          alertType="Yes"
          onPress={this.handleTogglePublish}
          loading={this.state.loadingPublish}
        />
        <DeleteModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showDeleteModal}
          title="Alert"
          description="Are you sure you want to delete this loot box?"
          onPress={this.handleDelete}
          loading={this.state.loadingDelete}
        />{" "}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadLootboxes: (params, callback) => dispatch(loadLootboxes(params, callback)),
  deleteLootbox: (id, callback) => dispatch(deleteLootbox(id, callback)),
  publishLootbox: (id, callback) => dispatch(publishLootbox(id, callback)),
});
const mapStateToProps = (state) => ({
  getLootbox: getLootbox(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LootboxListing));
