import React, { createRef } from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import { loadAMOEContests, getUsers } from "store/users";
import moment from "moment/moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

//  Images
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";
import loadingIcon from "include/images/loader-grey.svg";
import closeIcon from "include/images/dark-close-icon.svg";

//  Components
import Loader from "common/loader";
import NextButton from "common/form/submitButton";
import Form from "common/form/form";
import { Link } from "react-router-dom";
import { truncateAddress } from "utils/misc";

class ListingFlyer extends Form {
  ref = createRef(null);
  scrollRef = createRef();
  state = {
    loading: false,
    loadingWinner: false,
    loadingMore: false,
    winner: "",
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({ loading: true });
      const params = { sort: "lastPlayedAt", userId: this.props.user?.id, order: "desc", amountType: 3 };
      this.props.loadAMOEContests(params, true, (res) => {
        this.setState({ loading: false });
      });
    }
    if (
      this.props.getUsers.ticketsWonLastKey !== prevProps.getUsers.ticketsWonLastKey &&
      this.props.getUsers.ticketsWonLastKey !== null
    ) {
      document.addEventListener("scroll", this.trackScrolling, true);
    }
    if (!this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.complete();

    if (this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.continuousStart();
  };

  trackScrolling = () => {
    if (this.scrollRef.current?.getBoundingClientRect().bottom - 1000 <= window.innerHeight) {
      this.setState({ loadingMore: true });
      const params = {
        sort: "lastPlayedAt",
        user: this.props.user?.id,
        order: "desc",
        amountType: 3,
        lastKey: this.props.getUsers.ticketsWonLastKey,
      };
      this.props.loadAMOEContests(params, false, () => this.setState({ loadingMore: false }));
      document.removeEventListener("scroll", this.trackScrolling, true);
    }
  };

  render() {
    const { amoeContests, totalCount } = this.props.getUsers;
    const { user } = this.props;
    return (
      <>
        <Loader loaderRef={this.ref} />
        <Offcanvas
          className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2"}
          show={this.props.showModal}
          onHide={this.props.toggleModal}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header className="offCanvas-header-2">
            <div>
              <h5>Tickets Won {totalCount > 0 && `(${totalCount})`}</h5>{" "}
              <div className="td-img-group d-flex align-items-start m-top-2">
                <div className="round-img m-right-2">
                  {user.image ? (
                    <img src={process.env.REACT_APP_CDN + user.image.small} alt="" />
                  ) : (
                    <img src={userIcon} alt="" />
                  )}
                </div>
                <span style={{ marginTop: -8 }}>
                  {user.userName}
                  <br />
                  {user.email}
                </span>
              </div>
            </div>
            <div
              className="btn_close pointer"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={this.props.toggleModal}
            >
              <img src={closeIcon} alt="" />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="" ref={this.scrollRef}>
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {amoeContests.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Contest</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Tickets Awarded Date</th>
                            <th>Tickets Awarded</th>
                          </tr>
                        </thead>
                        <tbody>
                          {amoeContests?.map((contest, i) => (
                            <tr>
                              <td className="camelCase">{contest?.title}</td>
                              <td>
                                <div class="small-text">
                                  {moment(contest?.startDate).format("MM/DD/YYYY")}
                                  <br /> <small> {moment(contest?.startDate).format("hh:mm a")}</small>
                                </div>
                              </td>
                              <td>
                                <div class="small-text">
                                  {moment(contest?.endDate).format("MM/DD/YYYY")}
                                  <br /> <small> {moment(contest?.endDate).format("hh:mm a")}</small>
                                </div>
                              </td>
                              <td>
                                <div class="small-text">
                                  {moment(contest?.ticketsAwardedAt).format("MM/DD/YYYY")}
                                  <br /> <small> {moment(contest?.ticketsAwardedAt).format("hh:mm a")}</small>
                                </div>
                              </td>
                              <td>{contest?.ticketsAwarded?.toLocaleString()}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>{" "}
            {this.state.loadingMore && (
              <div className="w-100 d-flex justify-content-center load-more">
                <img src={loadingIcon} className="fa-spin" alt="" />
              </div>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadAMOEContests: (params, fresh, callback) => dispatch(loadAMOEContests(params, fresh, callback)),
});
const mapStateToProps = (state) => ({
  getUsers: getUsers(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListingFlyer));
