import { createSlice, current } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "lootbox",
  initialState: {
    lootboxes: [],
    lootboxDetails: {},
    prizes: [],
    lootboxWinners: [],
    loading: false,
    totalCount: null,
    filteredCount: null,
    totalWinners: null,
    lastFetch: null,
  },
  reducers: {
    lootboxesRequested: (misc, action) => {
      misc.loading = true;
    },
    lootboxesReceived: (misc, action) => {
      misc.lootboxes = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    lootboxesRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    lootboxDetailsRequested: (misc, action) => {
      misc.loading = true;
    },
    lootboxDetailsReceived: (misc, action) => {
      misc.lootboxDetails = action.payload.data;
      misc.prizes = action.payload.data?.prizes ?? misc.prizes;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    lootboxDetailsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    prizesRequested: (misc, action) => {
      misc.loading = true;
    },
    prizesReceived: (misc, action) => {
      misc.prizes = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    prizesRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    prizesReset: (misc, action) => {
      misc.lootboxDetails = {};
      misc.prizes = [];
      misc.lastFetch = Date.now();
    },
    lootboxWinnersRequested: (misc, action) => {
      misc.loading = true;
    },
    lootboxWinnersReceived: (misc, action) => {
      misc.lootboxWinners = action.payload.data;
      misc.totalWinners = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    moreLootboxWinnersReceived: (misc, action) => {
      misc.lootboxWinners = [...misc.lootboxWinners, ...action.payload.data];
      misc.totalWinners = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    lootboxWinnersRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    updateTransferLootboxWinners: (misc, action) => {
      misc.lootboxWinners = misc.lootboxWinners?.map((winner) =>
        winner?._id === action.payload ? { ...winner, transactionStatus: 2 } : winner,
      );
    },
  },
});

export const {
  lootboxesRequested,
  lootboxesReceived,
  lootboxesRequestFailed,
  lootboxDetailsRequested,
  lootboxDetailsReceived,
  lootboxDetailsRequestFailed,
  prizesRequested,
  prizesReceived,
  prizesRequestFailed,
  prizesReset,
  lootboxWinnersRequested,
  lootboxWinnersReceived,
  lootboxWinnersRequestFailed,
  moreLootboxWinnersReceived,
  updateTransferLootboxWinners,
} = slice.actions;
export default slice.reducer;

// Action Creators
const lootboxUrl = "lootBox/";

export const loadLootboxes = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: lootboxUrl,
      params,
      onStart: lootboxesRequested.type,
      onSuccess: lootboxesReceived.type,
      onError: lootboxesRequestFailed.type,
      callback,
    }),
  );
};
export const loadLootboxDetails = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: lootboxUrl + id,
      onStart: lootboxDetailsRequested.type,
      onSuccess: lootboxDetailsReceived.type,
      onError: lootboxDetailsRequestFailed.type,
      callback,
    }),
  );
};
export const addLootbox = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: lootboxUrl,
      method: "POST",
      data,
      onSuccess: lootboxDetailsReceived.type,
      callback,
    }),
  );
};
export const updateLootbox = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: lootboxUrl + id,
      method: "PUT",
      data,
      onSuccess: lootboxDetailsReceived.type,
      callback,
    }),
  );
};
export const publishLootbox = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: lootboxUrl + "changeLootBoxStatus/" + id,
      method: "PUT",
      callback,
    }),
  );
};
export const deleteLootbox = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: lootboxUrl + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const loadPrizes = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: lootboxUrl + "prize/" + id,
      onStart: prizesRequested.type,
      onSuccess: prizesReceived.type,
      onError: prizesRequestFailed.type,
      callback,
    }),
  );
};
export const resetPrizes = () => (dispatch) => {
  dispatch(prizesReset());
};
export const addPrize = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: lootboxUrl + "prize/" + id,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadLootboxWinners =
  (id, params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: lootboxUrl + "winner/" + id,
        params,
        onStart: lootboxWinnersRequested.type,
        onSuccess: fresh ? lootboxWinnersReceived.type : moreLootboxWinnersReceived.type,
        onError: lootboxWinnersRequestFailed.type,
        callback,
      }),
    );
  };
export const transferLootPrize = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: lootboxUrl + "transferPrize/" + id,
      callback,
    }),
  );
};
export const getLootbox = createSelector(
  (state) => state.entities.lootbox,
  (contest) => contest,
);
