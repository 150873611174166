import { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//  Images
import emptyIcon from "include/images/nrf.svg";
import closeIcon from "include/images/close-icon.svg";

//  Components
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
// import AddCard from "./updateSpinnerRewards";
import filterIcon from "include/images/filter-icon.svg";
import userIcon from "include/images/user.png";
import {
  getTelegram,
  loadTelegramUsdtRequests,
  telegramUsdtStatusReceived,
  updateTelegramUsdtStatus,
} from "store/telegram";
import SelectSearch from "common/form/selectSearch";
import NextButton from "common/form/submitButton";
import { isEmpty, pickBy } from "lodash";
import Form from "common/form/form";
import { Dropdown } from "react-bootstrap";
import DeleteModal from "common/alert/alertModal";
import { toast } from "react-toastify";
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import moment from "moment";

class UsdtWithdrawalListing extends Form {
  ref = createRef(null);
  state = {
    data: { firstName: "", lastName: "", status: {}, externalWalletAddress: "" },
    loadingMain: false,
    showAlertModal: false,
    loadingStatus: false,

    errors: {},
    page: 1,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getTelegram.loading && this.props.getTelegram.loading !== prevProps.getTelegram.loading)
      this.ref.current.complete();

    if (this.props.getTelegram.loading && this.props.getTelegram.loading !== prevProps.getTelegram.loading)
      this.ref.current.continuousStart();
  };
  componentDidMount = () => {
    this.setState({ loadingMain: true });
    this.props.loadTelegramUsdtRequests({}, (res) => {
      this.setState({
        loadingMain: false,
      });
    });
  };

  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAlertModal: !this.state.showAlertModal });
  };

  searchFilters = () => {
    this.setState({ loading: true, loadingFilters: true });
    const data = { ...this.state.data };
    const payLoad = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      externalWalletAddress: data?.externalWalletAddress,
    };

    if (!isEmpty(data.status)) {
      payLoad.status = data?.status?.value;
    }

    var params = pickBy(payLoad, function (value, key) {
      return value !== "";
    });
    params.page = this.state.page;

    this.props.loadTelegramUsdtRequests(params, (res) => {
      if (res?.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).forEach((key) => {
          if (!isEmpty(data[key])) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({
          appliedFilters,
          loading: false,
          loadingFilters: false,
        });
      } else this.setState({ loading: false, loadingFilters: false });
    });
  };
  resetFilters = (e) => {
    e.preventDefault();

    this.setState(
      {
        data: {
          firstName: "",
          lastName: "",
          status: {},
          externalWalletAddress: "",
        },
        page: 1,
      },
      () => this.searchFilters(),
    );
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };

  handleStatusUpdate = (e) => {
    if (e) e.preventDefault();
    this.setState({ loadingStatus: true });
    this.props.updateTelegramUsdtStatus(this.state.requestId, (res) => {
      if (res.status === 200) {
        toast(<AlertSuccess message="USDT transfer request status updated!" />, {
          containerId: 1,
        });
        this.props.telegramUsdtStatusReceived({
          id: this.state.requestId,
        });
      } else {
        toast(<AlertError message={res.data && res.data.message} />, {
          containerId: 1,
        });
      }
      this.alertModalToggle();
      this.setState({ loadingStatus: false, requestId: null });
    });
  };

  render() {
    const { telegramUsdtWithdrawals, totalCount } = this.props.getTelegram;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="telegram-mini-app-usdt" />

          {!this.state.loadingMain && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1>USDT Requests {totalCount > 0 && `(${totalCount})`}</h1>
                <div className="filter-menu-box d-flex align-items-center ms-auto">
                  <div className="fmb-box">
                    <a
                      href="#!"
                      className="filter-link-box"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          showFilters: !this.state.showFilters,
                        });
                      }}
                    >
                      <img src={filterIcon} /> <span>Filters</span>
                    </a>
                  </div>
                </div>{" "}
              </div>
              <div className="fwc-wrapper">
                {this.state.showFilters && (
                  <div className="fwc-head ">
                    <div className="fwc-inner">
                      <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("firstName", "First Name")}</div>
                        </li>{" "}
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("lastName", "Last Name")}</div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">
                            {this.renderInput("externalWalletAddress", "External Wallet Address")}
                          </div>
                        </li>
                        <li className="flex-fill">
                          <div className="fml-box">
                            <label className="form-label">Status</label>
                            <SelectSearch
                              name="status"
                              options={[
                                {
                                  name: "Pending",
                                  value: 1,
                                },
                                {
                                  name: "Complete",
                                  value: 2,
                                },
                              ]}
                              label=""
                              value={this.state.data.status}
                              error={this.state.errors.status}
                              onChange={this.onChange}
                            />
                          </div>
                        </li>
                      </ul>
                      <div className="fwc-btn d-flex align-items-center justify-content-end">
                        <NextButton
                          handleSubmit={this.resetFilters}
                          classData="btn-text pointer"
                          label="Reset"
                          loading={this.state.loadingReset}
                        />
                        <NextButton
                          handleSubmit={() => {
                            this.setState(
                              {
                                page: 1,
                              },
                              () => this.searchFilters(),
                            );
                          }}
                          classData="btn btn-primary btn-sm"
                          label="Search"
                          loading={this.state.loadingFilters}
                        />
                      </div>
                    </div>{" "}
                    {!isEmpty(this.state.appliedFilters) && (
                      <div className="filter-tag-container d-flex align-items-center ">
                        <div className="fwc-left">
                          <div className="filter-tag-list">
                            <p>filter applied :</p>
                            {Object.keys(this.state.appliedFilters).map((key) => (
                              <div className="filter-tag-item">
                                <span className="camelCase">
                                  {key === "firstName"
                                    ? "First Name"
                                    : key === "lastName"
                                    ? "Last Name"
                                    : key === "redeemableUsdt"
                                    ? "Redeemable USDT"
                                    : key === "externalWalletAddress"
                                    ? "External Wallet Address"
                                    : key}
                                </span>{" "}
                                :{" "}
                                {key === "status"
                                  ? this.state.appliedFilters[key].name
                                  : this.state.appliedFilters[key]}
                                <a
                                  href="#!"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState(
                                      {
                                        data: { ...this.state.data, [key]: "" },
                                        page: 1,
                                      },
                                      this.searchFilters,
                                    );
                                  }}
                                >
                                  <img src={closeIcon} alt="" />
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}{" "}
                <div className="fwc-body">
                  {telegramUsdtWithdrawals?.length === 0 ? (
                    <div className="fwc-body">
                      <div className="no-record-found-container">
                        <div className="nfr-box">
                          <img src={emptyIcon} alt="No Record Found" />
                          <div className="nrf-text">
                            <h5>No Records Found!</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Users</th>
                            <th>Withdrawable USDT</th>
                            <th>External Wallet Address</th>
                            <th>Status</th>
                            <th>Request Date</th>
                            <th>Completion Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {telegramUsdtWithdrawals?.map((withdrawalRequest, index) => (
                            <tr key={index}>
                              <td>
                                <span>
                                  <div className="td-img-group d-flex align-items-start ">
                                    <div className="td-img round-img m-right-2 pointer">
                                      {withdrawalRequest?.image ? (
                                        <img src={process.env.REACT_APP_CDN + withdrawalRequest?.image?.small} alt="" />
                                      ) : (
                                        <img src={userIcon} alt="" />
                                      )}
                                    </div>

                                    <div>
                                      <a
                                        href="#!"
                                        onClick={(e) => {
                                          e.preventDefault();
                                        }}
                                      >
                                        {withdrawalRequest?.firstName} {withdrawalRequest?.lastName}
                                      </a>
                                      <br />
                                      {withdrawalRequest?.userName}
                                    </div>
                                  </div>
                                </span>
                              </td>
                              <td>{withdrawalRequest?.withdrawableUsdt}</td>
                              <td>{withdrawalRequest?.externalWalletAddress}</td>
                              <td className="ust-tag">
                                <Dropdown className="custom-dropdown">
                                  <Dropdown.Toggle
                                    id="tdDropdownOne"
                                    className="custom-dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    disabled={withdrawalRequest?.status === 2}
                                  >
                                    <span
                                      className={`w-100 text-center d-block ${
                                        withdrawalRequest?.status === 1
                                          ? "inactive"
                                          : withdrawalRequest?.status === 2
                                          ? "active"
                                          : "suspended"
                                      }`}
                                    >
                                      {!isNaN(withdrawalRequest?.status) &&
                                        ["PENDING", "COMPLETED"][withdrawalRequest?.status - 1]}
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                    <Dropdown.Item
                                      className="dropdown-item "
                                      onClick={(e) =>
                                        this.setState({ requestId: withdrawalRequest?._id }, () =>
                                          this.alertModalToggle(e),
                                        )
                                      }
                                    >
                                      COMPLETED
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>{moment(withdrawalRequest?.requestedAt).format("MM/DD/YYYY hh:mm a")}</td>
                              <td>
                                {withdrawalRequest?.status === 2 &&
                                  moment(withdrawalRequest?.completedAt).format("MM/DD/YYYY hh:mm a")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {!this.state.loading && !(this.state.page === 1 && telegramUsdtWithdrawals?.length === 0) && (
                    <div className="page-pagination">
                      <ul className="pagination  justify-content-end">
                        <li className="page-item">
                          <a
                            class={`page-link ${this.state.page === 1 && "disabled"}`}
                            href="#!"
                            aria-label="Previous"
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.state.page > 1) {
                                this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                              }
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                        <li className="page-item active">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {this.state.page}
                          </a>
                        </li>
                        {telegramUsdtWithdrawals?.length >= 50 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                              }}
                            >
                              {this.state.page + 1}
                            </a>
                          </li>
                        )}
                        <li className="page-item">
                          <a
                            class={`page-link ${telegramUsdtWithdrawals?.length < 50 && "disabled"}`}
                            href="#!"
                            aria-label="Next"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <DeleteModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showAlertModal}
          title="Alert"
          description="Are you sure you want to mark this request as completed? Are you sure you have transferred the USDT manually? This action is irreversible."
          alertType="Yes"
          onPress={this.handleStatusUpdate}
          loading={this.state.loadingStatus}
        />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadTelegramUsdtRequests: (params, callback) => dispatch(loadTelegramUsdtRequests(params, callback)),
  updateTelegramUsdtStatus: (id, callback) => dispatch(updateTelegramUsdtStatus(id, callback)),
  telegramUsdtStatusReceived: (payLoad) => dispatch(telegramUsdtStatusReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsdtWithdrawalListing));
