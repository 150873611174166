import axios from "axios";
import { clearAllToken, setToken } from "./../../utils/localStorageServices";
import * as actions from "../api";
const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
      const apiUrl = error.request.responseURL.split("/");
      if (
        apiUrl[apiUrl.length - 1] !== "refreshToken" &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const headers = {};
        headers["Authorization"] = localStorage.getItem("x-auth-token");

        try {
          if (localStorage.getItem("refresh-called") !== "true") {
            localStorage.setItem("refresh-called", "true");
            const response = await axios.request({
              baseURL: process.env.REACT_APP_API_BASE,
              headers,
              url: "auth/admin/refreshToken",
              method: "POST",
              data: { refreshToken: localStorage.getItem("x-refresh-token") },
            });
            setToken(
              response.data.data.refreshToken,
              response.data.data.accessToken
            );
            localStorage.setItem("refresh-called", "false");
            axios.defaults.headers.common["Authorization"] =
              response.data.data.accessToken;
            originalRequest.headers["Authorization"] =
              response.data.data.accessToken;
            return axios(originalRequest);
          } else {
            return;
          }
        } catch (error) {
          clearAllToken();
          window.location.href = "/signin";
        }
      }
    }
    return Promise.reject(error);
  }
);

const api =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== actions.apiCallBegan.type) return next(action);
    const {
      url,
      method,
      data,
      params,
      onStart,
      onSuccess,
      onError,
      callback = false,
    } = action.payload;
    if (onStart) dispatch({ type: onStart });

    next(action);

    const headers = {
      "X-API-KEY": process.env.REACT_APP_API_KEY,
    };

    if (localStorage.getItem("x-auth-token")) {
      headers["Authorization"] = localStorage.getItem("x-auth-token");
    }

    try {
      const response = await axios.request({
        baseURL: process.env.REACT_APP_API_BASE,
        headers,
        url,
        method,
        params,
        data,
      });
      // General
      dispatch(actions.apiCallSuccess(response.data));
      // Specific
      if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
      if (callback) callback(response);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        // window.location.href = "/500"; TODO: uncomment
      } else if (error.response && error.response.status === 404) {
        // window.location.href = "/404"; TODO: uncomment
      }
      // General
      dispatch(actions.apiCallFailed(error.message));
      // Specific
      if (onError) dispatch({ type: onError, payload: error });
      if (callback) callback(error.response);
    }
  };

export default api;
