import Amplify, { Auth, Storage } from "aws-amplify";
import Joi from "joi-browser";
import _ from "lodash";
import moment from "moment-timezone";
import { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Form as BootstrapForm } from "react-bootstrap";

//  Images
import deleteIcon from "include/images/delete-icon.svg";
import addIcon from "include/images/plus.svg";
import plusIcon from "include/images/plus-1.svg";

//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import SelectSearch from "common/form/selectSearch";
import NextButton from "common/form/submitButton";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "../../common/sidebar";
import { addLootbox, getLootbox, loadLootboxDetails, prizesReset, updateLootbox } from "store/lootbox";
import { Link } from "react-router-dom";
import AddPrizes from "./addPrizes";
import DeleteModal from "common/alert/alertModal";
import { getSettings, loadHowItWorksTemplates } from "store/settings";
import WinnersListingFlyer from "./winnersListingFlyer";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});

class AddLootbox extends Form {
  ref = createRef(null);
  state = {
    data: {
      title: "",
      pricePerSpin: 0,
      numberOfDistribution: null,
      howItWorksTemplate: null,
      totalValue: 0,
      image: null,
      displayOrder: null,
    },
    id: "",
    edit: false,
    errors: {},
    loading: false,
    loadingLootbox: true,
    showAddModal: false,
    showDeleteModal: false,
    showWinnersListing: false,
  };
  componentDidMount = () => {
    this.props.prizesReset();
    if (this.props.match.params?.name) {
      const lootbox = this.props?.location?.state?.lootbox;
      if (!lootbox) return this.props.history.replace("/lootbox");

      this.props.loadLootboxDetails(lootbox?._id, (res) => {
        if (res.status === 200) {
          this.props.loadHowItWorksTemplates((howItWorksRes) => {
            if (howItWorksRes.status === 200) {
              const data = { ...this.state.data };

              const temp = howItWorksRes?.data?.data?.find((item) => item?.id === lootbox?.howItWorksTemplateId);
              data.howItWorksTemplate = {
                name: temp.title,
                value: temp.id,
                data: temp,
              };
              this.setState({ data }, () => this.handleEditData(res?.data?.data));
            }
          });
        } else {
          toast(<AlertError message={res.data && res.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      this.props.loadHowItWorksTemplates((res) => {
        if (res.status === 200) {
          this.setState({ loadingLootbox: false });
        }
      });
    }
  };
  handleEditData = (lootbox) => {
    const data = { ...this.state.data };

    data.title = lootbox?.title;
    data.pricePerSpin = lootbox?.pricePerSpin;
    data.numberOfDistribution = {
      name: lootbox?.numberOfDistribution?.toLocaleString("en-us"),
      value: parseInt(lootbox?.numberOfDistribution),
    };
    data.totalValue = lootbox?.totalValue;
    data.image = lootbox?.image;
    if (lootbox?.displayOrder) data.displayOrder = { name: `${lootbox?.displayOrder}`, value: lootbox?.displayOrder };
    this.setState({ data, edit: true, id: lootbox?._id, loadingLootbox: false });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      (!this.state.loadingLootbox && this.state.loadingLootbox !== prevState.loadingLootbox) ||
      (!this.state.loading && this.state.loading !== prevState.loading)
    )
      this.ref.current.complete();

    if (
      (this.props.getLootbox.loading && this.props.getLootbox.loading !== prevProps.getLootbox.loading) ||
      (this.state.loading && this.state.loading !== prevState.loading)
    )
      this.ref.current.continuousStart();
  };

  handleImageChange = (e, type) => {
    const errors = { ...this.state.errors };
    this.setState({ loadingImages: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ loadingImages: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ loadingImages: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.setState({
          errors,
          loadingImages: false,
          [type === "image" ? "imageChange" : "imageChangeLoot"]: true,
          data: {
            ...this.state.data,
            [type]: { medium: "public/" + result.key },
          },
        });
      });
    }
    e.target.value = null;
  };

  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];

    this.setState({ data, errors });
  };
  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  doSubmit = (e) => {
    this.setState({ loading: true });
    const data = { ...this.state.data };

    const payLoad = {
      title: data.title,
      howItWorksTemplateId: data.howItWorksTemplate?.value,
      displayOrder: data.displayOrder?.value,
    };

    payLoad.image = !_.isEmpty(data.image)
      ? this.state.edit && !this.state.imageChange
        ? data.image
        : data.image.medium
      : null;

    if (!this.props.getLootbox?.lootboxDetails?.locked) {
      payLoad.pricePerSpin = data.pricePerSpin;
      payLoad.totalValue = data.totalValue;
      payLoad.numberOfDistribution = data.numberOfDistribution?.value;
    }
    if (this.state.edit) {
      this.props.updateLootbox(this.state.id, payLoad, (lootboxRes) => {
        this.setState({ loading: false });
        if (lootboxRes.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.history.goBack();
        } else {
          toast(<AlertError message={lootboxRes.data && lootboxRes.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      this.props.addLootbox(payLoad, (lootboxRes) => {
        this.setState({
          loading: false,
        });
        if (lootboxRes.status === 200) {
          toast(<AlertSuccess message="Information Saved" />);
          this.handleEditData(lootboxRes?.data?.data);
          this.props.history.replace({
            pathname: `/lootbox/${lootboxRes?.data?.data?.slug}`,
            state: { lootbox: lootboxRes?.data?.data },
          });
        } else {
          toast(<AlertError message={lootboxRes.data && lootboxRes.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };

  schema = {
    title: Joi.string().max(500).required(),
    pricePerSpin: Joi.number().required(),
    image: Joi.object().required(),
    totalValue: Joi.number().required(),
    numberOfDistribution: Joi.object().keys({
      data: Joi.string(),
      name: Joi.string().required(),
      value: Joi.number().required(),
    }),
    howItWorksTemplate: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.string().allow(""),
    }),
    displayOrder: Joi.object().allow({}),
  };
  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };
  toggleWinnersListing = (e) => {
    if (e) e.preventDefault();
    this.setState({ showWinnersListing: !this.state.showWinnersListing });
  };
  lockLootbox = () => {
    this.ref.current.continuousStart();

    this.props.updateLootbox(this.state.id, { locked: true }, (lootboxRes) => {
      this.setState({ loading: false });
      if (lootboxRes.status === 200) {
        toast(
          <AlertSuccess message="Prize distribution is being created. This may take few minutes. Loot box can be published only after this process." />,
          {
            containerId: 1,
          },
        );
        this.ref.current.complete();
      } else {
        toast(<AlertError message={lootboxRes.data && lootboxRes.data.message} />, {
          containerId: 1,
        });
        this.ref.current.complete();
      }
    });
  };
  render() {
    const locked = this.state.edit && this.props.getLootbox?.lootboxDetails?.locked;
    const { howItWorksTemplates } = this.props.getSettings;

    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="lootbox" />

          {!this.state.loadingLootbox && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1>{this.props.location.state ? "Edit" : "Add a"} Loot Box</h1>
                {this.state.edit && (
                  <div className="filter-menu-box d-flex align-items-center ms-auto">
                    <div className="fmb-box">
                      <Link
                        to="#"
                        className="btn btn-default btn-sm"
                        onClick={locked ? this.toggleWinnersListing : this.toggleAddModal}
                      >
                        {!locked && <img src={addIcon} alt="" />}{" "}
                        {locked ? "Winners" : this.props.getLootbox?.prizes?.length === 0 ? "Add" : "Update"}{" "}
                        {!locked && "Prizes"}
                      </Link>
                    </div>
                  </div>
                )}
              </div>
              <div className="fwc-wrapper">
                <fieldset className="row fieldset">
                  <div className="col-6">
                    <div className="fml-box">{this.renderInput("title", "Title", "", "text")}</div>
                  </div>
                  <div className="col-6">
                    <div className="fml-box">
                      {this.renderInput("pricePerSpin", "Price Per Spin", "", "number", locked)}
                    </div>
                  </div>
                </fieldset>
                <fieldset className="row fieldset">
                  <div className="col-6">
                    <label className="form-label">Number Of Distributions</label>
                    <SelectSearch
                      name="numberOfDistribution"
                      options={[
                        {
                          name: "1,000",
                          value: 1000,
                        },
                        {
                          name: "10,000",
                          value: 10000,
                        },
                        {
                          name: "100,000",
                          value: 100000,
                        },
                        {
                          name: "500,000",
                          value: 500000,
                        },
                      ]}
                      label="Select Number Of Distributions"
                      value={this.state.data.numberOfDistribution}
                      error={this.state.errors.numberOfDistribution}
                      isDisabled={locked}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="col-6">
                    <div className="fml-box">
                      {this.renderInput("totalValue", "Total Value (In USD)", "", "number", locked)}
                    </div>
                  </div>
                </fieldset>
                <fieldset className="row fieldset">
                  <div className="col-6">
                    <label className="form-label">How It Works Template</label>
                    <SelectSearch
                      name="howItWorksTemplate"
                      options={howItWorksTemplates?.map((template) => ({
                        name: template.title,
                        value: template.id,
                        data: template,
                      }))}
                      label="Select How It Works Template"
                      value={this.state.data.howItWorksTemplate}
                      error={this.state.errors.howItWorksTemplate}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label">Display Order</label>
                    <SelectSearch
                      name="displayOrder"
                      options={[
                        { name: "1", value: 1 },
                        { name: "2", value: 2 },
                        { name: "3", value: 3 },
                        { name: "4", value: 4 },
                        { name: "5", value: 5 },
                      ]}
                      label="Select Display Order"
                      value={this.state.data.displayOrder}
                      error={this.state.errors.displayOrder}
                      onChange={this.onChange}
                    />
                  </div>
                </fieldset>
                <fieldset className="row fieldset">
                  <div className="col-6">
                    <label htmlFor="" className="form-label">
                      Loot Box Image
                    </label>
                    <div className="kt-upload-row d-flex flex-wrap align-items-start">
                      <div
                        className={`kt-upload-outer ${
                          !this.state.data.image ? "placeholder-uploaded-img" : " position-relative"
                        } ${this.state.errors.image && "border-error"}`}
                      >
                        <div className="kt-upload-img">
                          <img
                            src={
                              this.state.data.image
                                ? this.state.data.image.medium
                                  ? this.state.data.image.medium.includes("https://")
                                    ? this.state.data.image.medium
                                    : process.env.REACT_APP_CDN + this.state.data.image.medium
                                  : process.env.REACT_APP_CDN + this.state.data.image
                                : plusIcon
                            }
                            alt=""
                          />
                        </div>{" "}
                        {this.state.data.image && (
                          <div
                            className="delete-icon d-flex align-items-center justify-content-center"
                            onClick={(e) => {
                              const data = this.state.data;
                              data.image = null;
                              this.setState({ data });
                            }}
                          >
                            <img src={deleteIcon} alt="delete icon" />
                          </div>
                        )}
                      </div>
                      <div className="kt-file-input">
                        <label htmlFor="formFile" className="kt-label">
                          {this.state.data.image ? " Change Image" : "+ Upload an Image"}
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          id="formFile"
                          onChange={(e) => {
                            this.handleImageChange(e, "image");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {this.state.edit && (
                    <div className="col-3">
                      <div className="form-check-outer">
                        <label className="form-label">Locked</label>
                        <BootstrapForm.Switch
                          type="switch"
                          name="locked"
                          id="locked-switch"
                          className="review-switch"
                          checked={this.props.getLootbox?.lootboxDetails?.locked}
                          disabled={locked}
                          onChange={(e) => {
                            const sum = this.props.getLootbox?.prizes?.reduce(
                              (s, prize) => s + (parseFloat(prize?.percentDistribution) ?? 0),
                              0,
                            );
                            if (sum !== 100)
                              return toast(
                                <AlertError message="Loot Box can not be locked if the distribution is not 100%" />,
                                {
                                  containerId: 1,
                                },
                              );
                            this.alertModalToggle();
                          }}
                        />
                      </div>
                    </div>
                  )}
                </fieldset>

                <div className="new-form-group new-form-btn  text-end">
                  <NextButton
                    handleSubmit={this.handleSubmit}
                    label="Save"
                    loading={this.state.loading || this.state.loadingImages}
                    classData={"btn btn-default"}
                  />
                </div>

                {this.props.getLootbox?.prizes?.length > 0 && (
                  <div className="fwc-inner pb-0">
                    <h1 className="ict-header">Prizes</h1>
                    <div className="new-form-group">
                      <table className="table large-table flyer-table mb-0">
                        <thead>
                          <tr>
                            <th style={{ width: 100 }}>Fulfillment</th>
                            <th style={{ width: 142 }}>Title</th>
                            <th style={{ width: "4%" }}>Image</th>
                            <th style={{ width: 100 }}>Amount Type</th>
                            <th style={{ width: 20 }}>Value</th>
                            <th style={{ width: 100 }}>Value Text</th>
                            <th style={{ width: 20 }}>Usd Value</th>
                            <th style={{ width: "4%" }}>Icon</th>
                            <th style={{ width: "10%" }}>% Distribution</th>
                            <th style={{ width: "8%" }}>Limit</th>
                            <th style={{ width: "4%" }}>Remaining Limit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.getLootbox?.prizes?.map((prize, index) => (
                            <tr className="flyer-table" key={index}>
                              <td>{prize?.fulfillment === 1 ? "Automatic" : "Manual"}</td>
                              <td className="align-middle">{prize?.title}</td>
                              <td>
                                {prize?.image && (
                                  <img
                                    src={
                                      prize?.image?.medium?.includes("https://")
                                        ? prize?.image.medium
                                        : process.env.REACT_APP_CDN + prize?.image?.medium
                                    }
                                    alt=""
                                  />
                                )}
                              </td>
                              <td>{["ARCADE", "TICKETS", "LOOT", "USDT", "CUSTOM"][prize?.amountType - 1]}</td>
                              <td>{prize?.value}</td>
                              <td>{prize?.customValueText ? prize?.customValueText : "N/A"}</td>
                              <td>{prize?.usdValue ? prize?.usdValue : "N/A"}</td>
                              <td>
                                {prize?.valueIcon ? (
                                  <img
                                    src={
                                      prize?.valueIcon?.medium?.includes("https://")
                                        ? prize?.valueIcon.medium
                                        : process.env.REACT_APP_CDN + prize?.valueIcon?.medium
                                    }
                                    alt=""
                                  />
                                ) : (
                                  "N/A"
                                )}
                              </td>
                              <td>{prize?.percentDistribution} %</td>
                              <td>{prize?.limit ? prize?.limit : "Unlimited"}</td>
                              <td>{prize?.limit ? prize?.limit - prize?.awardedCount : "N/A"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>{" "}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <AddPrizes
          shouldLoad={false}
          lootboxId={this.state.id}
          showModal={this.state.showAddModal}
          toggleModal={this.toggleAddModal}
        />
        <WinnersListingFlyer showModal={this.state.showWinnersListing} toggleModal={this.toggleWinnersListing} />
        <DeleteModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showDeleteModal}
          title="Alert"
          alertType="Yes"
          description="Are you sure you want to lock this loot box? Once locked, information cannot be edited."
          onPress={(e) => {
            this.alertModalToggle(e);
            this.lockLootbox();
          }}
        />
        {(this.state.showAddModal || this.state.showWinnersListing) && (
          <div
            className="modal-backdrop show fade"
            onClick={this.state.showAddModal ? this.toggleAddModal : this.toggleWinnersListing}
          ></div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadLootboxDetails: (id, callback) => dispatch(loadLootboxDetails(id, callback)),
  addLootbox: (data, callback) => dispatch(addLootbox(data, callback)),
  updateLootbox: (id, data, callback) => dispatch(updateLootbox(id, data, callback)),
  loadHowItWorksTemplates: (callback) => dispatch(loadHowItWorksTemplates(callback)),
  prizesReset: () => dispatch(prizesReset()),
});
const mapStateToProps = (state) => ({
  getLootbox: getLootbox(state),
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddLootbox));
