import moment from "moment-timezone";
import { createRef } from "react";
import { Dropdown } from "react-bootstrap";
import Timer from "react-compound-timer";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  cancelContest,
  cancelContestDetailsReceived,
  deleteContest,
  getContest,
  loadContestInfo,
  markAsFeaturedContest,
  markAsFeaturedDetailsReceived,
} from "store/contest";
import { convertDate, formatDate } from "utils/dateConversion";
import { ethToUSD } from "utils/externalApiCaller";

//  Images
import starIcon from "include/images/1-star.svg";
import backIcon from "include/images/chevron-left.svg";
import clockIcon from "include/images/clock.svg";
import loader from "include/images/loading-icon.svg";
import moreIcon from "include/images/more-horizontal.svg";
import starIcon1 from "include/images/star-fill.svg";
import copyIcon from "include/images/copy.svg";

//  Components
import AlertError from "common/alert/alertError";
import DeleteModal from "common/alert/alertModal";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "../../common/sidebar";

class contestDetails extends Form {
  videoRef = createRef(null);
  ref = createRef(null);
  state = {
    showCancelModal: false,
    showDeleteModal: false,
    showInformation: false,
    ethVPrice: "",
    loadingDelete: false,
    loading: false,
    loadingCancel: false,
    markAsFeaturedLoading: false,
    markAsFeaturedLoading: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getContest.loading && this.props.getContest.loading !== prevProps.getContest.loading)
      this.ref && this.ref.current && this.ref.current.complete();

    if (this.props.getContest.loading && this.props.getContest.loading !== prevProps.getContest.loading)
      this.ref && this.ref.current && this.ref.current.continuousStart();
  };

  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  alertCancelModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showCancelModal: !this.state.showCancelModal });
  };
  handleDelete = (e) => {
    if (e) e.preventDefault();
    this.setState({ loadingDelete: true });
    this.props.deleteContest(this.props.location.state.contest.id, (contestResponse) => {
      if (contestResponse.status == 200) {
        toast(<AlertSuccess message="Deleted!" />, {
          containerId: 1,
        });
        this.setState({ loadingDelete: false });
        this.props.history.push("/contests");
      } else {
        toast(<AlertError message={contestResponse.data && contestResponse.data.message} />, {
          containerId: 1,
        });
        this.setState({ loadingDelete: false });
        this.alertModalToggle();
      }
    });
  };
  handleCancel = (e) => {
    if (e) e.preventDefault();
    this.setState({ loadingCancel: true });
    this.props.cancelContest(this.props.location.state.contest.id, (contestResponse) => {
      if (contestResponse.status == 200) {
        toast(<AlertSuccess message="Canceled!" />, {
          containerId: 1,
        });
        this.props.cancelContestDetailsReceived({
          id: this.props.location.state.contest.id,
        });
        this.alertCancelModalToggle();
      } else {
        toast(<AlertError message={contestResponse.data && contestResponse.data.message} />, {
          containerId: 1,
        });
        this.setState({ loadingCancel: false });
        this.alertCancelModalToggle();
      }
    });
  };
  componentDidMount = () => {
    this.setState({ loading: true });
    ethToUSD((ethRes) => {
      this.setState({ ethVPrice: ethRes.rates.USD });
    });
    const { contest, status } = this.props.location.state;
    const params = {};
    if (status === "cancelled") {
      params.isCancelled = true;
    }
    this.props.loadContestInfo(contest.id, params, () => {
      this.setState({ loading: false });
    });
  };
  render() {
    if (!this.props.location.state) {
      this.props.history.push("/contests");
    }
    const contest = this.props.getContest.contestInfo;
    const arcadeDescription = contest?.arcadeDescription;
    const lootDescription = contest?.lootDescription;
    const { status } = this.props.location.state;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="contests" />
          {contest && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1>{contest.title}</h1>
              </div>
              <div className="fwc-wrapper">
                <a
                  href="/"
                  className="back-link-btn d-flex align-items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.goBack();
                  }}
                >
                  <img src={backIcon} alt="" /> Back
                </a>

                {!this.state.loading && (
                  <div className="grid-card-details-row d-flex flex-wrap">
                    <div className="gcdr-img-box">
                      <div className="gcdr-img-outer text-white">
                        <div className="gcdr-image">
                          {contest.rewardAnimationURL ? (
                            !contest.rewardAnimationURL.includes(".mp4") &&
                            !contest.rewardAnimationURL.includes(".mov") ? (
                              <>
                                <iframe src={contest.rewardAnimationURL} alt="" />
                              </>
                            ) : (
                              <video
                                className=""
                                src={contest.rewardAnimationURL}
                                autoPlay
                                controls
                                ref={this.videoRef}
                                loop
                                onCanPlay={(e) => {
                                  this.videoRef.play();
                                }}
                              ></video>
                            )
                          ) : (
                            <img src={process.env.REACT_APP_CDN + contest?.bgGame?.thumbnailImage} alt="" />
                          )}

                          {(contest.status === "Upcoming" || contest.status === "Running") && (
                            <div
                              className="pointer add-grid-bookmark"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.setState({
                                  markAsFeaturedLoading: true,
                                });

                                this.props.markAsFeaturedContest(contest.id, !contest.isFeatured, () => {
                                  this.props.markAsFeaturedDetailsReceived({
                                    id: contest.id,
                                    isFeatured: !contest.isFeatured,
                                  });
                                  this.setState({
                                    markAsFeaturedLoading: false,
                                  });
                                });
                              }}
                            >
                              <img
                                className={this.state.markAsFeaturedLoading && "fa-spin"}
                                src={
                                  this.state.markAsFeaturedLoading ? loader : contest.isFeatured ? starIcon1 : starIcon
                                }
                                alt=""
                              />
                            </div>
                          )}

                          <div
                            class={`badge ${
                              contest.status === "Running"
                                ? "running"
                                : contest.status === "Upcoming"
                                ? "upcoming"
                                : contest.status === "Cancelled" || contest.status === "Invalid"
                                ? "cancelled"
                                : "past"
                            }`}
                          >
                            {contest.status === "Running"
                              ? "Running"
                              : contest.status === "Upcoming"
                              ? "Upcoming"
                              : contest.status === "Cancelled"
                              ? "Canceled"
                              : contest.status === "Invalid"
                              ? "Invalid"
                              : "Past"}
                          </div>
                        </div>

                        <div className="gcdr-group d-flex justify-content-center">
                          {contest?.contestType !== 3 && (
                            <>
                              {contest.arcadeCoinToBeAwarded ? (
                                <div className="gcdr-item text-center ">
                                  <h6>{contest.arcadeCoinToBeAwarded?.toLocaleString() ?? 0}</h6>
                                  <p>
                                    <strong>Arcade Coins to be awarded</strong>
                                  </p>
                                </div>
                              ) : (
                                <div className="gcdr-item text-center ">
                                  <h6>{contest.xPToBeAwarded?.toLocaleString() ?? 0}</h6>
                                  <p>
                                    <strong>XP to be awarded</strong>
                                  </p>
                                </div>
                              )}
                            </>
                          )}
                          {contest?.contestType !== 2 && (
                            <div className="gcdr-item text-center ">
                              <h6>{contest.ticketsToBeAwarded?.toLocaleString() ?? 0}</h6>
                              <p>
                                <strong>Tickets to be awarded</strong>
                              </p>
                            </div>
                          )}
                        </div>
                      </div>{" "}
                      {contest.endDate && status !== "Cancelled" && (
                        <div className="clockdiv d-flex justify-content-center" id="clockdiv">
                          <Timer
                            initialTime={convertDate(contest.endDate).diff(moment().tz("America/New_York"))}
                            direction="backward"
                            startImmediately={true}
                          >
                            {(control) => {
                              this.ref = control;

                              return (
                                <>
                                  <div className="colckdiv-item">
                                    <span>
                                      {" "}
                                      <Timer.Days className="days"></Timer.Days>
                                    </span>
                                    <div className="smalltext">Days</div>
                                  </div>
                                  <div className="colckdiv-item">
                                    <span>
                                      {" "}
                                      <Timer.Hours className="hours"></Timer.Hours>
                                    </span>
                                    <div className="smalltext">Hours</div>
                                  </div>
                                  <div className="colckdiv-item">
                                    <span>
                                      {" "}
                                      <Timer.Minutes className="minutes"></Timer.Minutes>
                                    </span>
                                    <div className="smalltext">Minutes</div>
                                  </div>
                                  <div className="colckdiv-item">
                                    <span>
                                      <Timer.Seconds className="seconds"></Timer.Seconds>
                                    </span>
                                    <div className="smalltext">Seconds</div>
                                  </div>
                                </>
                              );
                            }}
                          </Timer>
                        </div>
                      )}
                    </div>
                    <div className="gcdr-text-box">
                      <div className="gcdr-heading-filter d-flex align-items-center justify-content-between">
                        <div className="gcdr-heading-date d-flex align-items-center">
                          {contest.startDate && (
                            <>
                              <div className="gcdr-heading-text">
                                {" "}
                                <img src={clockIcon} alt="" />
                                <span>Starts on :</span>
                                {formatDate(contest.startDate, "MM/DD/YYYY hh:mm A")} EST
                              </div>
                            </>
                          )}
                          {contest.startDate && contest.endDate && <div className="dash-line">-</div>}
                          {contest.endDate && (
                            <div className="gcdr-heading-text">
                              <span>Ends on :</span> {formatDate(contest.endDate, "MM/DD/YYYY hh:mm A")} EST
                            </div>
                          )}
                        </div>{" "}
                        {contest && (contest.status === "Upcoming" || contest.status === "Running") && (
                          <Dropdown className="dropdown custom-dropdown">
                            <Dropdown.Toggle
                              className="dropdown-toggle custom-dropdown-toggle"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src={moreIcon} alt="" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                              {contest.status === "Upcoming" && (
                                <Dropdown.Item
                                  className="dropdown-item"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.history.push({
                                      pathname: "/add-contest",
                                      state: { contest },
                                    });
                                  }}
                                >
                                  Edit
                                </Dropdown.Item>
                              )}
                              {contest.status === "Running" && (
                                <Dropdown.Item
                                  className="dropdown-item delete"
                                  onClick={(e) => {
                                    this.alertCancelModalToggle(e);
                                  }}
                                >
                                  Cancel
                                </Dropdown.Item>
                              )}
                              {contest.status === "Upcoming" && (
                                <Dropdown.Item
                                  className="dropdown-item delete"
                                  onClick={(e) => {
                                    this.alertModalToggle(e);
                                  }}
                                >
                                  Delete
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </div>
                      <div className="gcdr-heading d-flex justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <h2>{contest.title}</h2>
                          {contest?.isPrivate && <span class="badge cancelled position-static">Private</span>}
                        </div>
                        <span
                          className={`orange-text pointer`}
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            navigator.clipboard.writeText(
                              `${process.env.REACT_APP_SITE_URL}games/${contest?.bgGameSlug}/${contest?.slug}`,
                            );
                            toast(<AlertSuccess message="Copied to clipboard" />, { containerId: 1 });
                          }}
                        >
                          Copy Link <img src={copyIcon} alt="copy" />
                        </span>
                      </div>
                      <div className="gcdr-widget-wrapper">
                        {contest.bgGame && (
                          <div className="gcdr-widget-box">
                            <h4>BG Game</h4>
                            <div className="gcdr-details-box">
                              <h4>{contest.bgGame?.name}</h4>
                              <p className="m-0">
                                <strong>{contest.bgGame?.category}</strong>
                              </p>
                              {contest.bgGame.url && (
                                <a href={contest.bgGame.url} className="orange-text" target={"_blank"} rel="noreferrer">
                                  {contest.bgGame.url}
                                </a>
                              )}
                              <p className="para-line">{contest.bgGame?.description}</p>
                            </div>
                          </div>
                        )}

                        {contest?.contestType !== 3 && (
                          <div className="gcdr-widget-box">
                            <h4 className="camelCase">Arcade Contest</h4>
                            <div className="gcdr-details-box">
                              <h4 className="camelCase">{contest?.arcadeTitle}</h4>
                              <p>
                                <strong>Entry Fee:</strong> <br />
                                {contest?.entryFeeArcadeCoins?.toLocaleString("en-us")} Arcade Coins
                              </p>
                              <p className="para-line">
                                <strong>Description:</strong>
                                <br />
                                {arcadeDescription}
                              </p>
                            </div>
                          </div>
                        )}
                        {contest?.contestType !== 2 && (
                          <div className="gcdr-widget-box">
                            <h4 className="camelCase">Loot Contest</h4>
                            <div className="gcdr-details-box">
                              <h4 className="camelCase">{contest?.lootTitle}</h4>
                              <p>
                                <strong>Entry Fee:</strong>
                                <br />
                                {contest?.entryFeeLootBonusCoins?.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 4,
                                }) ?? 0}{" "}
                                Loot Bucks
                              </p>
                              <p className="para-line">
                                <strong>Description:</strong> <br /> {lootDescription}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <DeleteModal
          alertModalToggle={this.alertCancelModalToggle}
          alertModalShow={this.state.showCancelModal}
          title="Cancel contest"
          description="Are you sure you want to cancel this contest?"
          alertType="Yes"
          onPress={this.handleCancel}
          loading={this.state.loadingCancel}
        />
        <DeleteModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showDeleteModal}
          title="Delete contest"
          description="Are you sure you want to delete this contest?"
          onPress={this.handleDelete}
          loading={this.state.loadingDelete}
        />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadContestInfo: (id, params, callback) => dispatch(loadContestInfo(id, params, callback)),
  deleteContest: (id, callback) => dispatch(deleteContest(id, callback)),
  markAsFeaturedContest: (id, featured, callback) => dispatch(markAsFeaturedContest(id, featured, callback)),
  cancelContest: (id, callback) => dispatch(cancelContest(id, callback)),
  markAsFeaturedDetailsReceived: (payLoad) => dispatch(markAsFeaturedDetailsReceived(payLoad)),
  cancelContestDetailsReceived: (payLoad) => dispatch(cancelContestDetailsReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getContest: getContest(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(contestDetails));
