import React from "react";
import closeBtn from "include/images/cross.svg";
import { Modal } from "react-bootstrap";
import NextButton from "common/form/submitButton";

const AlertModal = (props) => {
  return (
    <Modal
      className={`modal fade alert-modal z-index-highest ${props.classData}`}
      dialogClassName="modal-dialog modal-dialog-centered"
      show={props.alertModalShow}
      onHide={props.alertModalToggle}
      id="deleteModal"
      aria-labelledby="deleteModalLabel"
      centered
    >
      <div className="modal-alert-box position-relative">
        <div className="alert-close-icon" data-bs-dismiss="modal" aria-label="Close" onClick={props.alertModalToggle}>
          <img src={closeBtn} alt="" />
        </div>
        <h4>{props.title}</h4>
        <p>{props.description}</p>
        <div className="modal-action-btn">
          <NextButton
            handleSubmit={props.onPress}
            label={props.alertType ? props.alertType : "Delete"}
            loading={props.loading}
            classData={"btn btn-delete btn-md"}
          />
          <a href="#!" className="btn btn-cancel" onClick={props.alertModalToggle}>
            Cancel
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default AlertModal;
