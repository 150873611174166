import React, { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Amplify, { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import ReactTooltip from "react-tooltip";
import { fetchNFT, ethToUSD } from "utils/externalApiCaller";
import Lottie from "react-lottie-player";
import _ from "lodash";
import { addSlider, getSettings, updateSlider } from "store/settings";
import { getContest, loadContests } from "store/contest";

//  Images
import plusIcon from "include/images/plus-1.svg";
import deleteIcon from "include/images/delete-icon.svg";
import ethIcon from "include/images/eth-icon.svg";
import placeholderJson from "include/json/placeholder-img.json";

//  Components
import SelectSearch from "common/form/selectSearch";
import Header from "common/header";
import SideBar from "common/sidebar";
import NextButton from "common/form/submitButton";
import Form from "common/form/form";
import AlertSuccess from "common/alert/alertSuccess";
import Loader from "common/loader";
import AlertError from "common/alert/alertError";
import { formatDate } from "utils/dateConversion";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});

class AddSlider extends Form {
  ref = createRef(null);
  toolRef = createRef(null);
  state = {
    data: {
      title1: "",
      title2: "",
      subTitle: "",
      buttonText: "",
      buttonLink: "",
      startDate: "",
      endDate: "",
      type: "",
      displayOrder: { name: "1", value: 1 },
      campaign: "",
      image: null,
      mobileBanner: "",
      contest: {},
    },
    id: "",
    edit: false,
    errors: {},
    loadingImages: false,
    loadingImages2: false,
    loadingSlide: false,
    showTooltip: false,
    sliderLoading: false,
    loadingUpdate: false,
    updateParams: null,
    imageChanged: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getContest.loading && this.props.getContest.loading !== prevProps.getContest.loading) {
      this.ref.current.complete();
    }

    if (this.props.getContest.loading && this.props.getContest.loading !== prevProps.getContest.loading)
      this.ref.current.continuousStart();
  };
  componentDidMount = () => {
    this.setState({ sliderLoading: true });
    this.props.loadContests({}, true, (res) => {
      this.setState({ sliderLoading: false });
      if (!_.isEmpty(this.props.location.state.slider)) {
        const data = { ...this.state.data };
        const slide = this.props.location.state.slider;
        data.title1 = slide.titleLine1;
        data.title2 = slide.titleLine2;
        data.buttonText = slide.buttonText;
        data.buttonLink = slide.buttonLink;
        data.subTitle = slide.subTitle;
        data.type = slide.type;
        data.endDate = slide.endDate;
        data.startDate = slide.startDate;
        data.image = slide.image;
        data.mobileBanner = slide.mobileBanner;
        res.data.data.forEach((contest) => {
          if (contest.id === slide.contestId) {
            data.contest = {
              name: contest.title,
              value: contest.id,
              data: contest,
            };
          }
        });
        data.displayOrder = {
          name: `${slide.displayOrder}`,
          value: slide.displayOrder,
        };
        data.campaign = slide?.campaign ?? "";
        this.setState({ data, edit: true, id: slide.id });
      }
    });
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    if (e.name === "contest") {
      if (data.endDate) {
        data.endDate = new Date(e.currentTarget.data.endDate).toLocaleString("en-US", {
          timeZone: "America/New_York",
        });
      }

      data.title1 = e.currentTarget.data.title;
      data.subTitle = e.currentTarget.data.rewardTitle;
      data.image = e.currentTarget.data.image;
      data.buttonText = "View Contest";
      data.buttonLink = "";
    }

    this.setState({ data, errors });
  };
  doSubmit = (e) => {
    this.setState({ loadingSlide: true });
    const data = { ...this.state.data };
    const payLoad = {
      titleLine1: data.title1,
      titleLine2: data.title2,
      buttonText: data.buttonText,
      buttonLink: data.buttonLink,
      subTitle: data.subTitle,
      displayOrder: data.displayOrder.value,
      campaign: data.campaign,
      image: data.image.original,
      type: data.type,
      mobileBanner: data.mobileBanner,
    };

    if (data.type === "CONTEST") {
      payLoad.contestId = data.contest.value;
      if (data.endDate) {
        payLoad.endDate = parseInt(formatDate(data.endDate, "x"));
      }
    } else {
      if (data.startDate) {
        payLoad.startDate = parseInt(formatDate(data.startDate, "x"));
      }
    }

    // var params = _.pickBy(payLoad, function (value, key) {
    //   return !(
    //     value === undefined ||
    //     value === "" ||
    //     value === null ||
    //     value === {}
    //   );
    // });
    if (this.state.edit) {
      this.props.updateSlider(this.state.id, payLoad, (sliderRes) => {
        if (sliderRes.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.setState({ loadingUpdate: false });
          this.props.history.push("/custom-sliders");
        } else {
          this.setState({ loadingUpdate: false });
          toast(<AlertError message={sliderRes.data && sliderRes.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      this.props.addSlider(payLoad, (sliderResponse) => {
        this.setState({
          loadingSlide: false,
        });
        if (sliderResponse.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.history.push("/custom-sliders");
        } else {
          toast(<AlertError message={sliderResponse.data && sliderResponse.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };

  onTypeChange = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    data.type = e.target.name;
    delete errors.type;
    data.contest = {};
    data.endDate = "";
    data.startDate = "";
    this.setState({ data, errors });
  };
  handleImageChange = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    this.setState({ loadingImages: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ loadingImages: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ loadingImages: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.setState({
          errors,
          data,
          loadingImages: false,
          data: {
            ...this.state.data,
            image: { original: "public/" + result.key },
          },
          imageChanged: this.state.edit ? true : false,
        });
      });
    }
    this.setState({ data });
    // e.target.value = null;
  };
  handleMobileImageChange = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    this.setState({ loadingImages2: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ loadingImages2: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ loadingImages2: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.setState({
          errors,
          data,
          loadingImages2: false,
          data: {
            ...this.state.data,
            mobileBanner: "public/" + result.key,
          },
        });
      });
    }
    this.setState({ data });
  };
  schema = {
    title1: Joi.string()
      .max(500)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Title 1 is required.";
              break;
            case "string.max":
              err.message = "Title 1 cannot be longer than 500 characters.";
              break;
          }
        });
        return errors;
      }),
    mobileBanner: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Mobile Banner Image is required.";
              break;
          }
        });
        return errors;
      }),
    startDate: Joi.date().allow("", null),
    endDate: Joi.date().allow("", null),
    title2: Joi.string().allow(""),
    subTitle: Joi.string().allow(""),
    buttonText: Joi.string().allow(""),
    buttonLink: Joi.string().allow(""),
    displayOrder: Joi.object().allow({}),
    campaign: Joi.string().allow(""),
    contest: Joi.when("type", {
      is: "CONTEST",
      then: Joi.object().keys({
        data: Joi.object().allow(null),
        name: Joi.string().allow(""),
        value: Joi.string()
          .required()
          .error((errors) => {
            errors.forEach((err) => {
              switch (err.type) {
                case "any.required":
                  err.message = "Contest is required";
                  break;
                case "any.empty":
                  err.message = "Contest is required";
                  break;
                default:
              }
            });
            return errors;
          }),
      }),
      otherwise: Joi.object().allow({}),
    }),
    type: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Slider Type is required.";
              break;
          }
        });
        return errors;
      }),
    image: Joi.object()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Image is required.";
              break;
          }
        });
        return errors;
      }),
  };

  render() {
    const { contests } = this.props.getContest;
    return (
      <>
        <Header history={this.props.history} />
        <ReactTooltip place="top" effect="solid" className="custom-tooltip" type="dark" transparent={false} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="customSliders" />

          {!this.state.sliderLoading && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center justify-content-between">
                <h1>{this.props.location.state ? "Edit" : "Add a"} Slide</h1>
              </div>
              <div className="fwc-wrapper">
                <div className="new-form-group form-check-outer">
                  <label className="form-label">Type</label>
                  <div className="form-check-group full-width-row d-flex align-items-center flex-wrap">
                    <>
                      {" "}
                      <div className="form-check-item">
                        <div className="form-check has-circle-radio">
                          <input
                            className={`form-check-input ${this.state.errors.type && "border-error"}`}
                            type="radio"
                            name="EDUCATIONAL"
                            id="radioCheckOne"
                            checked={this.state.data.type === "EDUCATIONAL"}
                            onChange={this.onTypeChange}
                            disabled={this.state.loadingSlide}
                          />
                          <label className="form-check-label" htmlFor="radioCheckOne">
                            Educational
                          </label>
                        </div>
                      </div>
                      <div className="form-check-item">
                        <div className="form-check has-circle-radio">
                          <input
                            className={`form-check-input ${this.state.errors.type && "border-error"}`}
                            type="radio"
                            name="CONTEST"
                            id="radioCheckTwo"
                            checked={this.state.data.type === "CONTEST"}
                            onChange={this.onTypeChange}
                            disabled={this.state.loadingSlide}
                          />
                          <label className="form-check-label" htmlFor="radioCheckTwo">
                            Contest
                          </label>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
                <>
                  {this.state.data.type === "CONTEST" && (
                    <div className="new-form-group">
                      <div className="d-flex align-items-center justify-content-between">
                        <label className="form-label">Contests</label>
                      </div>
                      <SelectSearch
                        name="contest"
                        options={contests.map((contest) => ({
                          name: contest.title,
                          value: contest.id,
                          data: contest,
                        }))}
                        label="Select Contest"
                        value={this.state.data.contest}
                        error={this.state.errors.contest}
                        onChange={this.onChange}
                      />
                    </div>
                  )}
                  <div className="new-form-group">
                    <div className="row">
                      <div className="col-6">{this.renderInput("title1", "Title 1", "", "")}</div>
                      <div className="col-6">{this.renderInput("subTitle", "Subtitle")}</div>
                    </div>
                  </div>
                  <div className="new-form-group">
                    <div className="row">
                      <div className="col-12">{this.renderTextarea("title2", "Title 2", "", "")}</div>
                    </div>
                  </div>
                  <div className="new-form-group">
                    <div className="row">
                      <div className="col-3">
                        <label className="form-label">Display Order</label>
                        <SelectSearch
                          name="displayOrder"
                          options={[
                            { name: "1", value: 1 },
                            { name: "2", value: 2 },
                            { name: "3", value: 3 },
                            { name: "4", value: 4 },
                            { name: "5", value: 5 },
                          ]}
                          label="Display Order"
                          value={this.state.data.displayOrder}
                          error={this.state.errors.displayOrder}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="col-3">{this.renderInput("campaign", "Campaign Name", "", "")}</div>
                      <div className="col-3">
                        {this.renderInput(
                          "buttonText",
                          "Button Text",
                          "",
                          "",
                          this.state.data.type === "CONTEST" ? true : false,
                        )}
                      </div>
                      <div className="col-3">
                        {this.renderInput(
                          "buttonLink",
                          "Button Link",
                          "",
                          "",
                          this.state.data.type === "CONTEST" ? true : false,
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="new-form-group">
                    <div className="row"></div>
                  </div>
                  <div className="new-form-group">
                    <div className="row">
                      {this.state.data.type === "EDUCATIONAL" && (
                        <div className="col-12">
                          <div className="fml-box">{this.renderDateInput("startDate", "Start Date & Time")}</div>
                        </div>
                      )}
                      {this.state.data.type === "CONTEST" && (
                        <div className="col-12">
                          <div className="fml-box">{this.renderDateInput("endDate", "End Date & Time", "")}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="new-form-group">
                    <div className="kt-upload-row d-flex flex-wrap align-items-start">
                      {!this.state.loadingImages ? (
                        <div
                          className={`kt-upload-outer kt-upload-outer-banner ${
                            !this.state.data.image ? "placeholder-uploaded-img" : " position-relative"
                          } ${this.state.errors.image && "border-error"}`}
                        >
                          <div className="kt-upload-img" htmlFor="uploadImg">
                            <img
                              src={
                                this.state.data.image
                                  ? this.state.data.image.original
                                    ? this.state.data.image.original.includes("https://")
                                      ? this.state.data.image.original
                                      : process.env.REACT_APP_CDN + this.state.data.image.original
                                    : process.env.REACT_APP_CDN + this.state.data.image
                                  : plusIcon
                              }
                              alt=""
                            />
                          </div>
                          <div
                            className="delete-icon d-flex align-items-center justify-content-center"
                            onClick={(e) => {
                              const data = this.state.data;
                              data.image = null;
                              this.setState({ data });
                            }}
                          >
                            <img src={deleteIcon} />
                          </div>
                        </div>
                      ) : (
                        <div className="kt-upload-outer  kt-upload-outer-banner position-relative loading">
                          <div className="lottie-player-box">
                            <Lottie
                              background="transparent"
                              speed="1"
                              loop
                              autoplay
                              animationData={placeholderJson}
                              play
                            />
                          </div>
                        </div>
                      )}
                      <div className="kt-file-input">
                        <label htmlFor="formFile" className="kt-label" id="uploadImg">
                          {this.state.data.image ? " Change Desktop Image" : "+ Upload Desktop Image"}
                        </label>{" "}
                        ( Recommended size: 1266 x 475 )
                        <input className="form-control" type="file" id="formFile" onChange={this.handleImageChange} />
                      </div>
                    </div>
                  </div>{" "}
                  <div className="new-form-group">
                    <div className="kt-upload-row d-flex flex-wrap align-items-start">
                      {!this.state.loadingImages2 ? (
                        <div
                          className={`kt-upload-outer kt-upload-outer-banner-2 ${
                            !this.state.data.mobileBanner
                              ? "placeholder-uploaded-img"
                              : "kt-upload-outer-banner-2-h-100  position-relative"
                          } ${this.state.errors.mobileBanner && "border-error"}`}
                        >
                          <div className="kt-upload-img" htmlFor="uploadImg">
                            <img
                              src={
                                this.state.data.mobileBanner
                                  ? process.env.REACT_APP_CDN + this.state.data.mobileBanner
                                  : plusIcon
                              }
                              alt=""
                            />
                          </div>
                          <div
                            className="delete-icon d-flex align-items-center justify-content-center"
                            onClick={(e) => {
                              const data = this.state.data;
                              data.mobileBanner = "";
                              this.setState({ data });
                            }}
                          >
                            <img src={deleteIcon} />
                          </div>
                        </div>
                      ) : (
                        <div className="kt-upload-outer  kt-upload-outer-banner-2 position-relative loading">
                          <div className="lottie-player-box">
                            <Lottie
                              background="transparent"
                              speed="1"
                              loop
                              autoplay
                              animationData={placeholderJson}
                              play
                            />
                          </div>
                        </div>
                      )}
                      <div className="kt-file-input">
                        <label htmlFor="formFile2" className="kt-label" id="uploadImg">
                          {this.state.data.mobileBanner ? " Change Mobile Image" : "+ Upload Mobile Image"}
                        </label>{" "}
                        ( Recommended size: 390 x 650 )
                        <input
                          className="form-control"
                          type="file"
                          id="formFile2"
                          onChange={this.handleMobileImageChange}
                        />
                      </div>
                    </div>
                  </div>
                </>
                <div className="new-form-group new-form-btn text-end">
                  {" "}
                  <NextButton
                    handleSubmit={this.handleSubmit}
                    label="Save"
                    loading={this.state.loadingSlide || this.state.loadingImages}
                    classData={"btn btn-default"}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  addSlider: (data, callback) => dispatch(addSlider(data, callback)),
  updateSlider: (data, id, callback) => dispatch(updateSlider(data, id, callback)),
  loadContests: (params, fresh, callback) => dispatch(loadContests(params, fresh, callback)),
});
const mapStateToProps = (state) => ({
  getContest: getContest(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddSlider));
