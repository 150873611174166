import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "dailyTrivia",
  initialState: {
    questions: [],
    questionInfo: null,
    totalCount: null,
    totalCount: 0,
  },
  reducers: {
    dailyTriviaRequested: (misc, action) => {
      misc.loading = true;
    },
    questionsReceived: (misc, action) => {
      misc.questions = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    deleteQuestionReceived: (misc, action) => {
      const ind = misc.questions.findIndex((quest) => quest.id === action.payload.id);
      const newQuestions = misc.questions;
      newQuestions.splice(ind, 1);
      misc.totalCount = misc.totalCount - 1;
      misc.questions = [...newQuestions];
    },
    questionDetailsReceived: (misc, action) => {
      misc.questionDetails = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    dailyTriviaRequestFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  dailyTriviaRequested,
  questionsReceived,
  dailyTriviaRequestFailed,
  questionDetailsReceived,
  deleteQuestionReceived,
} = slice.actions;
export default slice.reducer;

// Action Creators
const dailyTriviaUrl = "dailyTriviaQuestion/";

export const loadQuestions = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: dailyTriviaUrl,
      params,
      onStart: dailyTriviaRequested.type,
      onSuccess: questionsReceived.type,
      onError: dailyTriviaRequestFailed.type,
      callback,
    }),
  );
};
export const loadQuestionInfo = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: dailyTriviaUrl + "detail/" + id,
      onStart: dailyTriviaRequested.type,
      onSuccess: questionDetailsReceived.type,
      onError: dailyTriviaRequestFailed.type,
      callback,
    }),
  );
};
export const addQuestion = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: dailyTriviaUrl,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateQuestion = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: dailyTriviaUrl + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteQuestion = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: dailyTriviaUrl + "delete/" + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const getDailyTrivia = createSelector(
  (state) => state.entities.dailyTrivia,
  (dailyTrivia) => dailyTrivia,
);
