import React, { createRef, Component } from "react";
import { toast } from "react-toastify";
import { Offcanvas, Form, Dropdown } from "react-bootstrap";
import { getGame, markAsWinner, removeWinner } from "store/games";
import {
  loadContests,
  getContest,
  loadContestGames,
  loadContestInfo,
  awardTickets,
  awardTicketsStatusChangeReceived,
  awardArcadeCoins,
  awardArcadeStatusChangeReceived,
  clearContestInfo,
  awardXpPoints,
  awardXpStatusChangeReceived,
} from "store/contest";

import moment from "moment/moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { getNumberWithOrdinal } from "utils/misc";

//  Images
import userIcon from "include/images/user.png";
import carrentIcon from "include/images/carrent-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import loader from "include/images/loading-icon-grey.svg";
import closeIcon from "include/images/dark-close-icon.svg";
import arrowIcon from "include/images/arrow-down-icon.svg";
import arrowIcon1 from "include/images/arrow-down-icon-1.svg";
import arrowIcon2 from "include/images/arrow-down-icon-2.svg";

//  Components
import Loader from "common/loader";
import NextButton from "common/form/submitButton";
import Select from "common/form/selectSearch";
import AlertModal from "common/alert/alertModal";
import { Joi } from "joi-browser";
import { setFilters } from "utils/localStorageServices";
import { formatDate } from "utils/dateConversion";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";
import { Skeleton } from "@mui/material";

class ListingFlyer extends Component {
  ref = createRef(null);
  state = {
    loading: false,
    data: {
      qualifiers: {},
    },
    loadingTicketsTransfer: false,
    showAlert: false,
    showAlertAwardTickets: false,
    winner: "",
    isWinnerDeclared: false,
    sorting: {
      sort: "totalScore",
      order: "desc",
    },
    ticketsAwarded: false,
    loadingTransfer: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({ loading: true });
      this.props.loadContestInfo(this.props.contest.id, {}, (res) => {});
      this.props.loadContestGames(this.props.contest.id, { amountType: this.props.amountType }, (res) => {
        const qualifiers = {};
        res.data.data.forEach((game, index) => {
          if (
            this.props.contest &&
            this.props.contest.qualifierRewards &&
            this.props.contest.qualifierRewards.length > 0
          ) {
            if (index > 0 && index <= this.props.contest.qualifierRewards.length) {
              qualifiers[game.userId] = this.props.contest.qualifierRewards[index - 1].position;
            }
          }
        });
        this.setState({
          data: { ...this.state.data, qualifiers },
          loading: false,
        });
      });
    }
    if (!this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.clearState();
    }
    if (!this.props.getGame.loading && this.props.getGame.loading !== prevProps.getGame.loading)
      this.ref.current.complete();

    if (this.props.getGame.loading && this.props.getGame.loading !== prevProps.getGame.loading)
      this.ref.current.continuousStart();
  };

  sort = (e, type) => {
    const data = { ...this.state.sorting };
    if (type === 1) {
      if (data.sort === "totalScore" && data.order === "desc") {
        data.sort = "totalScore";
        data.order = "asc";
      } else {
        data.sort = "totalScore";
        data.order = "desc";
      }
    } else if (type === 2) {
      if (data.sort === "createdAt" && data.order === "desc") {
        data.sort = "createdAt";
        data.order = "asc";
      } else {
        data.sort = "createdAt";
        data.order = "desc";
      }
    }
    this.setState({ sorting: data }, () => {
      this.loadData();
    });
  };
  loadData = () => {
    this.props.loadContestGames(this.props.contest.id, { amountType: this.props.amountType }, () => {
      this.setState({
        loading: false,
      });
    });
  };
  toggleAwardTickets = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAlertAwardTickets: !this.state.showAlertAwardTickets });
  };
  transferReward = (e) => {
    if (e) e.preventDefault();
    this.setState({ loadingTransfer: true });
    const contest = this.props.getContest.contestInfo;

    if (this.props.amountType === 1) {
      if (contest?.xPToBeAwarded >= 0) {
        this.props.awardXpPoints(this.props.contest.id, (res) => {
          if (res?.status === 200) {
            toast(<AlertSuccess message={res?.data?.data} />, {
              containerId: 1,
            });
            this.props.awardXpStatusChangeReceived({
              id: this.props.contest.id,
            });
          } else {
            toast(<AlertError message={res?.data?.message} />, {
              containerId: 1,
            });
          }
        });
      } else {
        this.props.awardArcadeCoins(this.props.contest.id, (res) => {
          if (res?.status === 200) {
            toast(<AlertSuccess message={res?.data?.data} />, {
              containerId: 1,
            });
            this.props.awardArcadeStatusChangeReceived({
              id: this.props.contest.id,
            });
          } else {
            toast(<AlertError message={res?.data?.message} />, {
              containerId: 1,
            });
          }
        });
      }
      this.toggleAwardTickets();
      this.setState({
        loadingTransfer: false,
        ticketsAwarded: !this.state.ticketsAwarded,
      });
    } else {
      this.props.awardTickets(this.props.contest.id, (res) => {
        if (res?.status === 200) {
          toast(<AlertSuccess message={res?.data?.data} />, {
            containerId: 1,
          });
          this.props.awardTicketsStatusChangeReceived({
            id: this.props.contest.id,
          });
        } else {
          toast(<AlertError message={res?.data?.message} />, {
            containerId: 1,
          });
        }
        this.toggleAwardTickets();
        this.setState({
          loadingTransfer: false,
          ticketsAwarded: !this.state.ticketsAwarded,
        });
      });
    }
  };
  clearState = (e) => {
    console.log("clear");
    if (e) e.preventDefault();
    this.setState({
      loading: false,
      data: {
        qualifiers: {},
      },
      loadingTicketsTransfer: false,
      showAlert: false,
      showAlertAwardTickets: false,
      winner: "",
      isWinnerDeclared: false,
      sorting: {
        sort: "totalScore",
        order: "desc",
      },
      ticketsAwarded: false,
      loadingTransfer: false,
    });
    this.props.clearContestInfo();
  };

  render() {
    const { contestGames } = this.props.getContest;
    const contest = this.props.getContest.contestInfo;
    return (
      <>
        <Loader loaderRef={this.ref} />
        <Offcanvas
          className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2"}
          show={this.props.showModal}
          onHide={(e) => {
            this.props.toggleModal(e);
          }}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header className="offCanvas-header-2">
            <div>
              {this.state.loading ? (
                <>
                  <Skeleton width={200} height={30} />
                </>
              ) : (
                contest && (
                  <div className="td-img-group d-flex align-items-start m-top-2">
                    <div className="round-img m-right-2">
                      {contest.image ? (
                        <img src={process.env.REACT_APP_CDN + contest.image.small} alt="" />
                      ) : (
                        <img src={userIcon} alt="" />
                      )}
                    </div>
                    {contest.title}
                  </div>
                )
              )}
            </div>
            <div className="d-flex align-items-center justify-content-center ">
              {this.props.amountType === 1
                ? contest &&
                  contestGames?.length > 0 &&
                  !(contest.arcadeCoinsAwarded || contest.xPAwarded) && (
                    <NextButton
                      handleSubmit={this.toggleAwardTickets}
                      label={`Award ${
                        this.props.amountType === 1
                          ? contest?.xPToBeAwarded >= 0
                            ? "XP points"
                            : "Arcade Coins"
                          : "Tickets"
                      }`}
                      loading={this.state.loadingTicketsTransfer}
                      classData={"btn btn-default"}
                      disabled={
                        contest.arcadeCoinsAwarded ||
                        contest.xPAwarded ||
                        this.state.ticketsAwarded ||
                        contest?.isArcadeCoinAwardingStarted ||
                        contest?.isXPAwardingStarted ||
                        contest?.isArcadeCoinDistributionCalculated ||
                        contest?.isXPDistributionCalculated
                      }
                    />
                  )
                : contest &&
                  contestGames?.length > 0 &&
                  !contest.ticketsAwarded && (
                    <NextButton
                      handleSubmit={this.toggleAwardTickets}
                      label={`Award ${this.props.amountType === 1 ? "Arcade Coins" : "Tickets"}`}
                      loading={this.state.loadingTicketsTransfer}
                      classData={"btn btn-default"}
                      disabled={
                        contest.ticketsAwarded ||
                        this.state.ticketsAwarded ||
                        contest?.isTicketAwardingStarted ||
                        contest?.isTicketDistributionCalculated
                      }
                    />
                  )}

              <div
                className="btn_close pointer ml-40"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={(e) => {
                  this.props.toggleModal(e);
                }}
              >
                <img src={closeIcon} alt="" />
              </div>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {" "}
            <div className="">
              <div className="fwc-body">
                {this.state.loading ? (
                  <>
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} /> <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} /> <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                  </>
                ) : (
                  <div className="table-responsive">
                    {contestGames.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Rank</th>
                            <th>Player</th>
                            {contest?.bgGame?.scoringType === 2 ? <></> : <th>Rounds</th>}
                            <th>Highest Score</th>
                            <th>Played On </th> {contest?.bgGame?.scoringType === 2 && <th>Duration (in seconds)</th>}
                            <th>
                              {this.props.amountType === 1
                                ? contest?.xPToBeAwarded >= 0
                                  ? "XP points"
                                  : "Arcade Coins"
                                : "Tickets"}{" "}
                              to be Awarded
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {contestGames &&
                            contestGames.length > 0 &&
                            contestGames.map((game, index) => (
                              <tr>
                                <td>{game?.rank}</td>
                                <td>
                                  <span>
                                    {game.user && (
                                      <div className="td-img-group d-flex align-items-start ">
                                        <div className="td-img round-img m-right-2">
                                          {game.user.image ? (
                                            <img src={process.env.REACT_APP_CDN + game.user.image.small} alt="" />
                                          ) : (
                                            <img src={userIcon} alt="" />
                                          )}
                                        </div>
                                        {game.user.userName}
                                        <br />
                                        {game.user.email}
                                      </div>
                                    )}
                                  </span>
                                </td>
                                {contest?.bgGame?.scoringType === 2 ? (
                                  <></>
                                ) : (
                                  <td className={`${!game.rounds && "opacity-5"}`}>
                                    {game?.rounds ? (
                                      <Link
                                        to={`/games`}
                                        className="td-img-group d-flex align-items-start pointer"
                                        target={"_blank"}
                                        onClick={(e) => {
                                          setFilters(
                                            `paid-games`,
                                            JSON.stringify({
                                              data: {
                                                keyword: "",
                                                user: game.userId,
                                                minScore: null,
                                                maxScore: null,
                                                usedHint: false,
                                                usedRemoveOne: false,
                                                usedPointBoost: false,
                                                amountType: this.props.amountType,
                                                contest: {
                                                  name: contest.title,
                                                  value: contest.id,
                                                  data: contest,
                                                },
                                              },
                                              sorting: {
                                                sort: "totalScore",
                                                order: "desc",
                                              },
                                            }),
                                          );
                                        }}
                                      >
                                        <div className="td-text-box orange-text">{game.rounds}</div>
                                      </Link>
                                    ) : (
                                      "NA"
                                    )}
                                  </td>
                                )}
                                <td>
                                  <Link
                                    to={`/games`}
                                    className="td-img-group d-flex align-items-start pointer"
                                    onClick={(e) => {
                                      setFilters(
                                        `paid-games`,
                                        JSON.stringify({
                                          data: {
                                            keyword: "",
                                            user: game.userId,
                                            minScore: null,
                                            maxScore: null,
                                            usedHint: false,
                                            usedRemoveOne: false,
                                            amountType: this.props.amountType,
                                            usedPointBoost: false,
                                            contest: {
                                              name: contest.title,
                                              value: contest.id,
                                              data: contest,
                                            },
                                          },
                                          sorting: {
                                            sort: "totalScore",
                                            order: "desc",
                                          },
                                        }),
                                      );
                                    }}
                                    target={"_blank"}
                                  >
                                    <div className="td-text-box orange-text">
                                      {game.totalScore.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  <div className="td-text-box small-text">
                                    {moment(game.lastPlayedAt).format("MM/DD/YYYY")} <br />
                                    <small> {moment(game.lastPlayedAt).format("hh:mm a")}</small>
                                  </div>
                                </td>
                                {contest?.bgGame?.scoringType === 2 && (
                                  <td className={game?.timeSpan ? "" : "opacity-5"}>
                                    {game?.timeSpan ? game?.timeSpan / 1000 : "NA"}
                                  </td>
                                )}

                                <td>
                                  {this.props.amountType === 1
                                    ? game?.xPAwarded
                                      ? game?.xPAwarded?.toLocaleString()
                                      : game?.arcadeCoinsAwarded?.toLocaleString() ?? 0
                                    : game?.ticketsAwarded?.toLocaleString() ?? 0}
                                </td>
                                {/* <td>
                                  {index === 0 ? (
                                    <div className="green-text">
                                      <div className="font-16">Winner</div>
                                    </div>
                                  ) : game.qualifier ? (
                                    <div>
                                      <div className="font-16">
                                        {game.qualifier.position}
                                        {getNumberWithOrdinal(game.qualifier.position)}
                                      </div>
                                    </div>
                                  ) : (
                                    contest &&
                                    contest.qualifierRewards &&
                                    contest.qualifierRewards.length > 0 &&
                                    index <= contest.qualifierRewards.length && (
                                      <div>
                                        <div className="font-16">
                                          {index + 1}
                                          {getNumberWithOrdinal(index + 1)}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </td> */}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        <AlertModal
          alertModalToggle={this.toggleAwardTickets}
          alertModalShow={this.state.showAlertAwardTickets}
          title="Alert!"
          classData="z-index-high"
          description={`Are you sure you want to award the ${
            this.props.amountType === 1 ? (contest?.xPToBeAwarded ? "XP Points" : "Arcade Coins") : "Tickets"
          } to all the participants? This action cannot be reversed.`}
          alertType="Yes"
          onPress={this.transferReward}
          loading={this.state.loadingTransfer}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  awardTicketsStatusChangeReceived: (payLoad) => dispatch(awardTicketsStatusChangeReceived(payLoad)),
  awardArcadeStatusChangeReceived: (payLoad) => dispatch(awardArcadeStatusChangeReceived(payLoad)),
  awardXpStatusChangeReceived: (payLoad) => dispatch(awardXpStatusChangeReceived(payLoad)),
  loadContestGames: (id, params, callback) => dispatch(loadContestGames(id, params, callback)),
  markAsWinner: (id, callback) => dispatch(markAsWinner(id, callback)),
  removeWinner: (id, callback) => dispatch(removeWinner(id, callback)),
  awardTickets: (id, callback) => dispatch(awardTickets(id, callback)),
  awardArcadeCoins: (id, callback) => dispatch(awardArcadeCoins(id, callback)),
  awardXpPoints: (id, callback) => dispatch(awardXpPoints(id, callback)),
  loadContests: (status, params, callback) => dispatch(loadContests(status, params, callback)),
  loadContestInfo: (id, params, callback) => dispatch(loadContestInfo(id, params, callback)),
  clearContestInfo: () => dispatch(clearContestInfo()),
});
const mapStateToProps = (state) => ({
  getGame: getGame(state),
  getContest: getContest(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListingFlyer));
