import React from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import {
  getSettings,
  loadCreditPrices,
  addCreditPrice,
  updateCreditPrices,
  addCreditPriceReceived,
  updateCreditPriceReceived,
} from "store/settings";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Joi, { errors } from "joi-browser";
import _ from "lodash";
import Amplify, { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import plusIcon from "include/images/plus-1.svg";
import deleteIcon from "include/images/delete-icon.svg";

//  Components
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});
class AddFlyer extends Form {
  fileInputRef = React.createRef(null);
  state = {
    loading: false,
    data: {
      description: "",
      price: "",
      arcadeCoins: "",
      lootBonusCoins: "",
    },
    errors: {},
    loadingPackage: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal) {
        if (this.props.edit) {
          const data = { ...this.state.data };
          data.description = this.props.edit.description;
          data.price = this.props.edit.price;
          data.arcadeCoins = this.props.edit.arcadeCoins;
          data.lootBonusCoins = this.props.edit.lootBonusCoins;
          this.setState({ data });
        }
      }
    }
  };
  doSubmit = (e) => {
    this.setState({ loadingPackage: true });
    const data = { ...this.state.data };
    const payLoad = {
      description: data.description,
      price: parseFloat(data.price),
      arcadeCoins: parseInt(data.arcadeCoins),
      lootBonusCoins: parseFloat(data.lootBonusCoins),
    };
    if (this.props.edit) {
      this.props.updateCreditPrices(this.props.edit.id, payLoad, (packagesResponse) => {
        this.setState({
          loadingPackage: false,
        });
        if (packagesResponse.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.addCreditPriceReceived(packagesResponse.data.data);
          this.clearData();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={packagesResponse.data && packagesResponse.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      this.props.addCreditPrice(payLoad, (packagesResponse) => {
        this.setState({
          loadingPackage: false,
        });
        if (packagesResponse.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.addCreditPriceReceived(packagesResponse.data.data);
          this.clearData();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={packagesResponse.data && packagesResponse.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };
  schema = {
    description: Joi.string().required(),
    price: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
    arcadeCoins: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
    lootBonusCoins: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        description: "",
        price: "",
        arcadeCoins: "",
        lootBonusCoins: "",
      },
      errors: {},
      loadingPackage: false,
    });
  };
  render() {
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>
            {this.props.edit ? (this.props.type === "contest" ? "" : "Edit ") : "Add "}
            Package
          </h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <div className="row">
                <div className="col-12 new-form-group">{this.renderInput("description", "Title", "", "")}</div>
              </div>{" "}
            </div>
            <div className="new-form-group">
              <div className="row">
                <div className="col-12 new-form-group">{this.renderInput("price", "Price (USD)", "", "number")}</div>
              </div>
            </div>{" "}
            <div className="new-form-group">
              <div className="row">
                <div className="col-6 new-form-group">
                  {this.renderInput("arcadeCoins", "Arcade Coins", "", "number")}
                </div>
                <div className="col-6 new-form-group">
                  {this.renderInput("lootBonusCoins", "Loot Bucks", "", "number")}
                </div>
              </div>
            </div>{" "}
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loadingPackage}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addCreditPrice: (data, callback) => dispatch(addCreditPrice(data, callback)),
  updateCreditPrices: (id, data, callback) => dispatch(updateCreditPrices(id, data, callback)),
  loadCreditPrices: (params, callback) => dispatch(loadCreditPrices(params, callback)),
  addCreditPriceReceived: (payLoad) => dispatch(addCreditPriceReceived(payLoad)),
  updateCreditPriceReceived: (payLoad) => dispatch(updateCreditPriceReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFlyer));
