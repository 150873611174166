import Form from "common/form/form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import NextButton from "common/form/submitButton";
import _ from "lodash";
import { dateAsUtc, formatDate } from "utils/dateConversion";
import closeIcon from "include/images/close-icon.svg";
import filterIcon from "include/images/filter-icon.svg";
import { createRef } from "react";
import { getSettings, loadWeeklyLeaderboard } from "store/settings";
import Joi from "joi-browser";
import moment from "moment/moment";
import userIcon from "include/images/user.png";
import emptyIcon from "include/images/nrf.svg";
import ticketIcon from "include/images/ticketIcon.svg";

class WeeklyLeaderboard extends Form {
  ref = createRef(null);
  state = {
    data: {
      startDate: moment().startOf("isoWeek").toDate(),
    },
    loadingMain: false,
    loadingFilters: false,
    errors: {},
    appliedFilters: {},
  };
  schema = {
    startDate: Joi.date().allow("", null),
  };
  componentDidMount = () => {
    this.setState({ loadingMain: true });

    this.props.loadWeeklyLeaderboard(parseInt(dateAsUtc(this.state.data.startDate, "x")), () => {
      this.setState({
        loadingMain: false,
      });
    });
  };
  searchFilters = (e) => {
    if (e) e.preventDefault();
    this.setState({ loadingFilters: true });
    this.props.loadWeeklyLeaderboard(parseInt(dateAsUtc(this.state.data?.startDate, "x")), () => {
      this.setState({
        loadingFilters: false,
      });
    });
  };
  resetFilters = (e) => {
    e.preventDefault();
    this.setState(
      {
        data: {
          startDate: moment().startOf("isoWeek").toDate(),
        },
      },
      () => this.searchFilters(),
    );
  };
  render() {
    const tickets = [250, 175, 125, 100, 50];

    const { weeklyLeaderboard } = this.props.getSettings;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="customSliders" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1 className="camelCase">Weekly Leaderboard Results</h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                {" "}
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        showFilters: !this.state.showFilters,
                      });
                    }}
                  >
                    <img src={filterIcon} /> <span>Filters</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="fwc-wrapper">
              {this.state.showFilters && (
                <div className="fwc-head ">
                  <div className="fwc-inner">
                    <ul className="filter-mode-list d-flex flex-wrap align-items-center">
                      <li className="flex-fill">
                        <div className="fml-box">
                          <div className="fml-input-group d-flex align-items-center">
                            <div className="fml-input-group-item flex-grow-1">
                              {" "}
                              {this.renderDateOnlyInput(
                                "startDate",
                                "Start Date of the Week",
                                moment("2024-4-1").toDate(),
                                moment().toDate(),
                                (day) => day?.getDay() !== 1,
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="fwc-btn d-flex align-items-center justify-content-end">
                      <NextButton handleSubmit={this.resetFilters} classData="btn-text pointer" label="Reset" />
                      <NextButton
                        handleSubmit={this.searchFilters}
                        classData="btn btn-primary btn-sm"
                        label="Search"
                        loading={this.state.loadingFilters}
                      />
                    </div>
                  </div>{" "}
                </div>
              )}
              <div className="fwc-body">
                {!this.state.loadingMain && (
                  <div className="table-responsive">
                    {weeklyLeaderboard?.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex flex-wrap">
                        {weeklyLeaderboard?.map((leaderboard, i) => (
                          <div className="px-2 col-4">
                            <div className="fwc-inner pb-0">
                              <h5>{leaderboard?.name}</h5>

                              <table className="table large-table mb-0" key={i}>
                                <thead>
                                  <tr>
                                    <th style={{ width: "20%" }}>Username</th>
                                    <th style={{ width: "20%" }}>Total Score</th>
                                    <th style={{ width: "20%" }}>Tickets</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {leaderboard?.topPlayers?.map((player, index) => (
                                    <tr key={index}>
                                      <td className="d-flex">
                                        <div className="td-img round-img m-right-2">
                                          <img
                                            src={
                                              player?.user?.image
                                                ? process.env.REACT_APP_CDN + player?.user?.image?.small
                                                : userIcon
                                            }
                                            alt=""
                                          />
                                        </div>
                                        {player?.user?.userName}
                                      </td>
                                      <td>{player?.totalScore?.toLocaleString("en-us") ?? 0}</td>
                                      <td className="d-flex">
                                        <div className="m-right-2">
                                          <img src={ticketIcon} alt="" />
                                        </div>
                                        {tickets[index]}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              {leaderboard?.topPlayers?.length === 0 && (
                                <div className="nfr-box py-3">No Records Found!</div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>{" "}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadWeeklyLeaderboard: (date, callback) => dispatch(loadWeeklyLeaderboard(date, callback)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WeeklyLeaderboard));
