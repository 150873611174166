import _ from "lodash";
import moment from "moment";
import { Component, createRef } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  cancelInstance,
  deleteInstanceReceived,
  getSweepstake,
  loadInstances,
  loadSweepstakeWinners,
  updateInstanceReceived,
  updateInstances,
} from "store/sweepstakes";
import { SWEEP_INSTANCES_STATUS } from "utils/constants";
import Joi from "joi-browser";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";
import exportIcon from "include/images/export.svg";
import loadingIcon from "include/images/loader-grey.svg";
import emptyIcon from "include/images/nrf.svg";
import plusIcon from "include/images/plus.svg";
import filterIcon from "include/images/filter-icon.svg";
import closeIcon from "include/images/close-icon.svg";

//  Components
import AlertError from "common/alert/alertError";
import DeleteModal from "common/alert/alertModal";
import AlertSuccess from "common/alert/alertSuccess";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import LeaderboardListing from "../leaderboard/leaderboardListing";
import AddContract from "./addInstance";
import { CSVLink } from "react-csv";
import NextButton from "common/form/submitButton";
import Form from "common/form/form";

class ContractListing extends Form {
  ref = createRef(null);
  refCsv = createRef(null);
  state = {
    data: {
      title: "",
      winnerUserName: "",
    },
    id: "",
    loadingOptions: false,
    loadingMain: false,
    loadingDelete: false,
    loading: false,
    showDeleteModal: false,
    showAddModal: false,
    cancelId: "",
    editData: null,
    showHistory: false,
    objectId: "",
    object: null,
    page: 1,
    statusInd: "",
    loadingStatus: false,
    showLeaderboard: false,
    instance: null,
    winnersCSV: [],
    loadingCsv: false,
    errors: {},
  };
  schema = {
    title: Joi.string().allow(""),
    winnerUserName: Joi.string().allow(""),
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getSweepstake.loading && this.props.getSweepstake.loading !== prevProps.getSweepstake.loading)
      this.ref.current.complete();

    if (this.props.getSweepstake.loading && this.props.getSweepstake.loading !== prevProps.getSweepstake.loading)
      this.ref.current.continuousStart();
    if (this.state.showAddModal !== prevState.showAddModal && !this.state.showAddModal) {
      setTimeout(() => {
        this.setState({ editData: null });
      }, 500);
    }
    const params = new URLSearchParams(this.props.location.search);
    const status = params.get("status");
    const prevParams = new URLSearchParams(prevProps.location.search);
    const prevStatus = prevParams.get("status");
    if (status !== prevStatus) this.getInstances();
  };
  componentDidMount = () => {
    this.getInstances();
  };
  getInstances = () => {
    this.setState({ loadingMain: true });
    this.props.loadInstances({}, (res) => {
      this.setState({
        loadingMain: false,
      });
    });
  };
  toggleHistoryFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showHistory: !this.state.showHistory });
  };
  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };
  toggleLeaderboardModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showLeaderboard: !this.state.showLeaderboard });
  };
  onChange = (e) => {
    const data = { ...this.state.data };
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };
  handleCancel = (e) => {
    this.setState({ loadingDelete: true });
    this.props.cancelInstance(this.state.cancelId, (instanceRes) => {
      if (instanceRes.status === 200) {
        toast(<AlertSuccess message="Canceled!" />, {
          containerId: 1,
        });
        this.props.updateInstanceReceived(instanceRes?.data?.data);
        this.cancelModalToggle();
        this.setState({ loadingDelete: false });
      } else {
        toast(<AlertError message={instanceRes.data && instanceRes.data.message} />, {
          containerId: 1,
        });
        this.cancelModalToggle();
        this.setState({ loadingDelete: false });
      }
    });
  };
  changeStatus = (e) => {
    this.setState({ loadingStatus: true });
    const payLoad = { status: this.state.statusInd };
    this.props.updateInstances(this.state.statusChangeId, payLoad, (contractRes) => {
      this.setState({
        loadingStatus: false,
      });
      if (contractRes.status === 200) {
        this.props.updateInstanceReceived(contractRes.data.data);
      } else {
        toast(<AlertError message={contractRes.data && contractRes.data.message} />, {
          containerId: 1,
        });
      }
    });
  };
  cancelModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  searchFilters = (e) => {
    if (e) e.preventDefault();

    this.setState({ loadingFilters: true });
    var params = _.pickBy(this.state.data, function (value, key) {
      return !_.isEmpty(value);
    });

    this.props.loadInstances(params, (instanceRes) => {
      this.setState({
        loadingFilters: false,
      });
      if (instanceRes?.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).forEach((key) => {
          if (!_.isEmpty(data[key])) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({ appliedFilters });
      }
    });
  };
  resetFilters = (e) => {
    e.preventDefault();
    this.setState(
      {
        data: {
          title: "",
          winnerUserName: "",
        },
      },
      () => this.searchFilters(),
    );
  };
  removeFilter = (type) => {
    const data = this.state.data;
    data[type] = "";
    this.setState({ data }, this.searchFilters);
  };
  loadWinnersCSV = (e) => {
    e.preventDefault();
    this.setState({ loadingCsv: true });
    let winnersCSV = [];
    const params = {};
    this.ref.current.continuousStart();
    const fn = () => {
      this.props.loadSweepstakeWinners(params, (res) => {
        winnersCSV = [
          ...winnersCSV,
          ...res?.data?.data?.winners?.map((winner, i) => ({
            Username: winner?.winnerUsername,
            "Email Address": winner?.winnerEmail,
            "Sweepstakes Title": winner?.title,
            "Reward Value in USD": winner?.rewardValueUSD,
            "Reward Title": winner?.rewardTitle,
            "Sweepstakes Start Date": moment(winner?.startDate).format("MM/DD/YYYY"),
            "Sweepstakes End Date": moment(winner?.endDate).format("MM/DD/YYYY"),
            "Sweepstakes Won Date": moment(winner?.winnerDate).format("MM/DD/YYYY"),
          })),
        ];
        if (res?.data?.data?.lastKey === null) {
          this.setState({ loadingCsv: false, winnersCSV: winnersCSV }, () => this.refCsv.current.link.click());
          this.ref.current.complete();
          return;
        }
        params.lastKey = res?.data?.data?.lastKey;
        fn();
      });
    };
    fn();
  };
  render() {
    const params = new URLSearchParams(this.props.location.search);
    const status = params.get("status");
    const instances = this.props.getSweepstake?.instances?.filter((instance, i) => {
      if (
        (status == 1 && (instance?.status === 1 || instance?.status === 2)) ||
        (status == 2 &&
          (instance?.status === 4 ||
            instance?.status === 5 ||
            instance?.status === 6 ||
            instance?.status === 7 ||
            instance?.status === 9)) ||
        (status == 3 && instance?.status === 3) ||
        (status == 4 && instance?.status === 8)
      ) {
        return true;
      } else return false;
    });

    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="sweepstakes" />
          {!this.state.loadingMain && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1>
                  {status == 1
                    ? "Active/Paused"
                    : status == 2
                    ? "Action Needed"
                    : status == 3
                    ? "Completed"
                    : "Canceled"}{" "}
                  Sweeps Instances
                </h1>
                <div className="filter-menu-box d-flex align-items-center ms-auto">
                  <div className="fmb-box">
                    <>
                      <NextButton
                        handleSubmit={this.loadWinnersCSV}
                        label={
                          <>
                            <img src={exportIcon} alt="export csv" />
                            <span>Export Winners to CSV</span>
                          </>
                        }
                        loading={this.state.loadingCsv}
                        classData={"filter-link-box pointer"}
                      />
                      <CSVLink
                        filename={"Sweepstakes-winners.csv"}
                        className="d-none"
                        data={this.state.winnersCSV}
                        ref={this.refCsv}
                      ></CSVLink>
                    </>
                  </div>{" "}
                  <div className="fmb-box">
                    <a
                      href="#!"
                      className="filter-link-box"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          showFilters: !this.state.showFilters,
                        });
                      }}
                    >
                      <img src={filterIcon} /> <span>Filters</span>
                    </a>
                  </div>
                  <div className="fmb-box">
                    <Link to="#" onClick={this.toggleAddModal} className="btn btn-default btn-sm">
                      <img src={plusIcon} alt="" /> Add a Sweeps Instance
                    </Link>
                  </div>
                </div>
              </div>
              <div className="fwc-wrapper">
                {this.state.showFilters && (
                  <div className="fwc-head ">
                    <div className="fwc-inner">
                      <ul className="filter-mode-list d-flex flex-wrap align-items-center">
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("title", "Title")}</div>
                        </li>{" "}
                        <li className="flex-fill">
                          <div className="fml-box">{this.renderInput("winnerUserName", "Username")}</div>
                        </li>
                      </ul>
                      <div className="fwc-btn d-flex align-items-center justify-content-end">
                        <NextButton
                          handleSubmit={this.resetFilters}
                          classData="btn-text pointer"
                          label="Reset"
                          loading={this.state.loadingReset}
                        />
                        <NextButton
                          handleSubmit={this.searchFilters}
                          classData="btn btn-primary btn-sm"
                          label="Search"
                          loading={this.state.loadingFilters}
                        />
                      </div>
                    </div>{" "}
                    {!_.isEmpty(this.state.appliedFilters) && (
                      <div className="filter-tag-container d-flex align-items-center ">
                        <div className="fwc-left">
                          <div className="filter-tag-list">
                            <p>filter applied :</p>
                            {Object.keys(this.state.appliedFilters).map((key) => (
                              <div className="filter-tag-item">
                                <span className="camelCase">{key === "title" ? "Title" : "Username"}</span> :{" "}
                                {this.state.appliedFilters[key]}
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.removeFilter(key);
                                  }}
                                >
                                  <img src={closeIcon} alt="" />
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="fwc-body">
                  {!this.state.loading && (
                    <div className="table-responsive">
                      {instances.length === 0 ? (
                        <div className="fwc-body">
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <table className="table large-table">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center", width: 40 }}>Actions</th>
                              <th style={{ width: "12%" }}>Title</th>
                              <th style={{ width: "8%" }}>Winner</th>
                              <th>Sweeps Image</th>
                              <th>Expiry Date & Time</th>
                              <th>Max Entries Per User </th>
                              <th>Sweeps Contract</th>
                              <th>Sweeps Prize</th>
                              <th>Status</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {instances &&
                              instances.length > 0 &&
                              instances.map((instance, ind) => {
                                return (
                                  <tr>
                                    <td>
                                      <Dropdown className=" custom-dropdown">
                                        <Dropdown.Toggle
                                          id="tdDropdownOne"
                                          className={`custom-dropdown-toggle`}
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          disabled={instance?.status === 8}
                                        >
                                          <img src={carrentIcon} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                          className={`dropdown-menu-start `}
                                          aria-labelledby="tdDropdownOne"
                                        >
                                          <Dropdown.Item
                                            className="dropdown-item"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.setState({ editData: instance }, () => {
                                                this.toggleAddModal();
                                              });
                                            }}
                                          >
                                            Edit
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            className="dropdown-item"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.setState({ instance }, () => {
                                                this.toggleLeaderboardModal();
                                              });
                                            }}
                                          >
                                            View Leaderboard
                                          </Dropdown.Item>
                                          {instance?.status !== 8 && (
                                            <Dropdown.Item
                                              className="dropdown-item delete"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ cancelId: instance?.id }, () => {
                                                  this.cancelModalToggle();
                                                });
                                              }}
                                            >
                                              Cancel
                                            </Dropdown.Item>
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                    <td>
                                      <Link to={`/sweepstakes/instances/${instance?.slug}`}>
                                        <div class="small-text">
                                          {instance?.title}
                                          {instance?.displayOrder > 0 && (
                                            <>
                                              <br /> <small>Display Order: {instance?.displayOrder}</small>
                                            </>
                                          )}
                                        </div>
                                      </Link>
                                    </td>
                                    <td>{instance?.winnerUserName}</td>
                                    <td>
                                      <div className="td-img margin-right-2 ">
                                        <img src={process.env.REACT_APP_CDN + instance?.image?.small} alt="" />
                                      </div>
                                    </td>
                                    <td>
                                      <div class="small-text">
                                        {moment(instance?.timeExpired).format("MM/DD/YYYY")}
                                        <br /> <small> {moment(instance?.timeExpired).format("hh:mm a")}</small>
                                      </div>
                                    </td>
                                    <td>{instance?.maxTicketsPerUser.toLocaleString()}</td>
                                    <td>{instance?.contract?.title}</td>
                                    <td>{instance?.prizeTitle}</td>
                                    <td className="ust-tag">
                                      <Dropdown className=" custom-dropdown">
                                        <Dropdown.Toggle
                                          id="tdDropdownOne"
                                          className="custom-dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          disabled={instance?.status !== 1 && instance?.status !== 2}
                                        >
                                          <span
                                            className={`w-100 text-center d-block ${
                                              instance.status >= 8
                                                ? "suspended"
                                                : instance.status === 1 || instance.status === 3
                                                ? "active"
                                                : "inactive"
                                            } ${this.state.loadingStatus && "pointer-none"}`}
                                          >
                                            {this.state.loadingStatus && this.state.statusChangeId === instance.id ? (
                                              <img
                                                src={loadingIcon}
                                                alt="loading..."
                                                style={{ width: 20 }}
                                                className="fa-spin"
                                              />
                                            ) : (
                                              SWEEP_INSTANCES_STATUS[instance.status]
                                            )}
                                          </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                          className={`dropdown-menu-start `}
                                          aria-labelledby="tdDropdownOne"
                                        >
                                          <>
                                            <Dropdown.Item
                                              className="dropdown-item "
                                              onClick={(e) => {
                                                this.setState(
                                                  {
                                                    statusChangeId: instance.id,
                                                    statusInd: instance.status === 1 ? 2 : 1,
                                                    loadingStatus: true,
                                                  },
                                                  this.changeStatus,
                                                );
                                              }}
                                            >
                                              {instance?.status === 1 ? "Pause" : "Active"}
                                            </Dropdown.Item>
                                          </>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>{" "}
                                    <td>
                                      <div class="small-text">
                                        {moment(instance?.createdAt).format("MM/DD/YYYY")}
                                        <br /> <small> {moment(instance?.timeExpired).format("hh:mm a")}</small>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <AddContract
          showModal={this.state.showAddModal}
          toggleModal={this.toggleAddModal}
          loadData={this.props.loadInstances}
          edit={this.state.editData}
          status={status}
        />
        <DeleteModal
          alertModalToggle={this.cancelModalToggle}
          alertModalShow={this.state.showDeleteModal}
          alertType="Yes"
          title="Alert"
          description="Are you sure you want to cancel this instance?"
          onPress={this.handleCancel}
          loading={this.state.loadingDelete}
        />
        <LeaderboardListing
          showModal={this.state.showLeaderboard}
          toggleModal={this.toggleLeaderboardModal}
          instance={this.state.instance}
        />
        {(this.state.showAddModal || this.state.showLeaderboard) && (
          <div
            className="modal-backdrop show fade"
            onClick={this.state.showLeaderboard ? this.toggleLeaderboardModal : this.toggleAddModal}
          ></div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadInstances: (params, callback) => dispatch(loadInstances(params, callback)),
  cancelInstance: (id, callback) => dispatch(cancelInstance(id, callback)),
  deleteInstanceReceived: (payLoad) => dispatch(deleteInstanceReceived(payLoad)),
  updateInstances: (id, data, callback) => dispatch(updateInstances(id, data, callback)),
  updateInstanceReceived: (payLoad) => dispatch(updateInstanceReceived(payLoad)),
  loadSweepstakeWinners: (params, callback) => dispatch(loadSweepstakeWinners(params, callback)),
});
const mapStateToProps = (state) => ({
  getSweepstake: getSweepstake(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractListing));
