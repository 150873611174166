import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  getSettings,
  loadHowItWorksTemplates,
  deleteHowItWorksTemplate,
  deleteHowItWorksTemplateReceived,
} from "store/settings";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import _ from "lodash";
import moment from "moment";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import historyIcon from "include/images/history-icon.svg";
import plusIcon from "include/images/plus.svg";

//  Components
import Header from "common/header";
import SideBar from "../../../common/sidebar";
import NextButton from "common/form/submitButton";
import Loader from "common/loader";
import AlertSuccess from "common/alert/alertSuccess";
import SelectSearch from "common/form/selectSearch";
import AlertError from "common/alert/alertError";
import DeleteModal from "common/alert/alertModal";
import AddHowItWorks from "./addHowItWorks";
import HistoryFlyer from "common/historyFlyer";

class MessageListing extends Component {
  ref = createRef(null);
  state = {
    data: {
      messages: [],
    },
    id: "",
    loadingOptions: false,
    loadingMain: false,
    loadingDelete: false,
    loading: false,
    showDeleteModal: false,
    showAddModal: false,
    deleteId: "",
    editData: null,
    showHistory: false,
    objectId: "",
    object: null,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getSettings.loading && this.props.getSettings.loading !== prevProps.getSettings.loading)
      this.ref.current.complete();

    if (this.props.getSettings.loading && this.props.getSettings.loading !== prevProps.getSettings.loading)
      this.ref.current.continuousStart();
    if (this.state.showAddModal !== prevState.showAddModal && !this.state.showAddModal) {
      setTimeout(() => {
        this.setState({ editData: null });
      }, 500);
    }
  };
  componentDidMount = () => {
    this.setState({ loadingMain: true });
    this.props.loadHowItWorksTemplates((res) => {
      this.setState({
        loadingMain: false,
      });
    });
  };
  toggleHistoryFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showHistory: !this.state.showHistory });
  };
  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };
  onChange = (e, index) => {
    const data = { ...this.state.data };
    var messages = [...data.messages];
    var message = { ...messages[index] };
    message[e.target.name] = e.target.value;
    messages[index] = { ...message };
    data.messages = messages;
    this.setState({ data });
  };
  handleDelete = (e) => {
    this.setState({ loadingDelete: true });
    this.props.deleteHowItWorksTemplate(this.state.deleteId, (templateRes) => {
      if (templateRes.status == 200) {
        toast(<AlertSuccess message="Deleted!" />, {
          containerId: 1,
        });
        this.props.deleteHowItWorksTemplateReceived({ id: this.state.deleteId });
        this.alertModalToggle();
        this.setState({ loadingDelete: false });
      } else {
        toast(<AlertError message={templateRes.data && templateRes.data.message} />, {
          containerId: 1,
        });
        this.alertModalToggle();
        this.setState({ loadingDelete: false });
      }
    });
  };
  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  render() {
    const { howItWorksTemplates } = this.props.getSettings;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="customText" />

          {!this.state.loadingMain && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1>How It Works Template</h1>
                <div className="filter-menu-box d-flex align-items-center ms-auto">
                  <div className="fmb-box">
                    <Link to="#" onClick={this.toggleAddModal} className="btn btn-default btn-sm">
                      <img src={plusIcon} alt="" /> Add a How It Works Template
                    </Link>
                  </div>
                </div>
              </div>
              {howItWorksTemplates?.length > 0 && (
                <div className="fwc-wrapper">
                  <div className="fwc-body">
                    <div className="table-responsive">
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th style={{ width: 75, textAlign: "center" }}>Actions</th>
                            <th style={{ width: "5%" }}>Image</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Created On</th>
                          </tr>
                        </thead>
                        <tbody>
                          {_.sortBy(howItWorksTemplates, ["displayOrder"]).map((template, index) => (
                            <tr>
                              <td>
                                <Dropdown className=" custom-dropdown">
                                  <Dropdown.Toggle
                                    id="tdDropdownOne"
                                    className="custom-dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img src={carrentIcon} alt="" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ editData: template }, () => {
                                          this.toggleAddModal();
                                        });
                                      }}
                                    >
                                      Edit
                                    </Dropdown.Item>{" "}
                                    <Dropdown.Item
                                      className="dropdown-item delete"
                                      onClick={(e) => {
                                        this.setState({ deleteId: template.id }, () => {
                                          this.alertModalToggle(e);
                                        });
                                      }}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                <div className="td-img margin-right-2 ">
                                  {template.image && (
                                    <img src={process.env.REACT_APP_CDN + template.image.small} alt="" />
                                  )}
                                </div>
                              </td>
                              <td>
                                <Link
                                  className="two-line-ellipsis"
                                  to={"#"}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ editData: template }, () => {
                                      this.toggleAddModal();
                                    });
                                  }}
                                >
                                  {template.title}
                                </Link>
                              </td>{" "}
                              <td>{template.description}</td>
                              <td>{moment(template.createdAt).format("MM/DD/YYYY hh:mm A")}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <AddHowItWorks
          showModal={this.state.showAddModal}
          toggleModal={this.toggleAddModal}
          loadData={this.props.loadHowItWorksTemplates}
          edit={this.state.editData}
        />
        <DeleteModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showDeleteModal}
          title="Alert"
          description="Are you sure you want to delete this item?"
          onPress={this.handleDelete}
          loading={this.state.loadingDelete}
        />
        <HistoryFlyer
          showModal={this.state.showHistory}
          toggleModal={this.toggleHistoryFlyer}
          moduleName={"winnerTemplate"}
          objectId={this.state.objectId}
          title={this.state.object && this.state.object.title}
        />
        {(this.state.showAddModal || this.state.showHistory) && (
          <div
            className="modal-backdrop show fade"
            onClick={this.state.showHistory ? this.toggleHistoryFlyer : this.toggleAddModal}
          ></div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadHowItWorksTemplates: (callback) => dispatch(loadHowItWorksTemplates(callback)),
  deleteHowItWorksTemplate: (id, callback) => dispatch(deleteHowItWorksTemplate(id, callback)),
  deleteHowItWorksTemplateReceived: (payLoad) => dispatch(deleteHowItWorksTemplateReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessageListing));
