import React, { createRef } from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import { loadIPHistory, getUsers } from "store/users";
import moment from "moment/moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

//  Images
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";
import closeIcon from "include/images/dark-close-icon.svg";

//  Components
import Loader from "common/loader";
import NextButton from "common/form/submitButton";
import Form from "common/form/form";
import { Link } from "react-router-dom";
import { truncateAddress } from "utils/misc";

class ListingFlyer extends Form {
  ref = createRef(null);
  state = {
    loading: false,
    loadingWinner: false,
    winner: "",
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({ loading: true });
      this.props.loadIPHistory(this.props.user.id, (walletRes) => {
        this.setState({ loading: false });
      });
    }
    if (!this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.complete();

    if (this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.continuousStart();
  };
  render() {
    const { ipHistory, filteredCount } = this.props.getUsers;
    const { user } = this.props;
    console.log("ip:", ipHistory);
    return (
      <>
        <Loader loaderRef={this.ref} />
        <Offcanvas
          className={"offcanvas offcanvas-end custom-offcanvas-pannel"}
          show={this.props.showModal}
          onHide={this.props.toggleModal}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header className="offCanvas-header-2">
            <div>
              <h5>User IP History</h5>{" "}
              <div className="td-img-group d-flex align-items-start m-top-2">
                {user.userName}
                <br />
                {user.email}
              </div>
            </div>
            <div
              className="btn_close pointer"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={this.props.toggleModal}
            >
              <img src={closeIcon} alt="" />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="">
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {ipHistory?.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>IP Address</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ipHistory?.map((ip, i) => (
                            <tr>
                              <td>{ip?.ip}</td>
                              <td>
                                <div class="small-text">
                                  {moment(ip?.createdAt).format("MM/DD/YYYY")}
                                  <br /> <small> {moment(ip?.createdAt).format("hh:mm a")}</small>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadIPHistory: (id, callback) => dispatch(loadIPHistory(id, callback)),
});
const mapStateToProps = (state) => ({
  getUsers: getUsers(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListingFlyer));
