import React, { Component } from "react";
import { Editor } from "@tinymce/tinymce-react";

class Textarea extends Component {
  editorRef = React.createRef(null);
  state = {
    formclass: "form-group custom-input",
  };

  handleFocus = () => {
    this.setState({ formclass: "form-group custom-input show on" });
  };

  handleBlur = (e) => {
    const classname = e.target.value
      ? "form-group custom-input show on"
      : "form-group custom-input";
    this.setState({ formclass: classname });
  };

  componentDidMount() {
    const classname = this.props.value
      ? "form-group custom-input show on"
      : "form-group custom-input";
    this.setState({ formclass: classname });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      const classname = this.props.value
        ? "form-group custom-input show on"
        : "form-group custom-input";
      this.setState({ formclass: classname });
    }
  }
  render() {
    const { name, label, error, placeholder, value, ...rest } = this.props;
    return (
      <div>
        {label && <label htmlFor={name}>{label}</label>}

        <Editor
          onInit={(evt, editor) => (this.editorRef.current = editor)}
          className={error ? "form-control error" : "form-control"}
          initialValue={value}
          init={{
            height: 500,
            menubar: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
      </div>
    );
  }
}

export default Textarea;
