import React from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import { getUsers, updateProfilePicture, updateUser } from "store/users";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Joi from "joi-browser";
import { fetchNFT, ethToUSD } from "utils/externalApiCaller";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import Amplify, { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import plusIcon from "include/images/plus-1.svg";
import ethIcon from "include/images/eth-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";
import userIcon from "include/images/user.png";

//  Components
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import AlertSuccess from "common/alert/alertSuccess";
import AlertModal from "common/alert/alertModal";
import AlertError from "common/alert/alertError";

class EditUser extends Form {
  state = {
    data: {
      reason: "",
    },
    errors: {},
    showSuspendUser: false,
    loading: false,
    loadingSuspend: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
    }
  };
  doSubmit = (e) => {
    this.suspendModalToggle(e);
  };

  schema = {
    reason: Joi.string()
      .max(500)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Reason is required.";
              break;
          }
        });
        return errors;
      }),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        reason: "",
      },
      errors: {},
      showSuspendUser: false,
      loadingSuspend: false,
    });
  };
  suspendModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showSuspendUser: !this.state.showSuspendUser });
  };
  render() {
    const contest = this.props.game && this.props.game.contest;
    const user = this.props.game && this.props.game.user;
    return (
      <>
        <Offcanvas
          className={`offcanvas offcanvas-end custom-offcanvas-pannel z-index-highest ${
            this.state.showSuspendUser && "invisible"
          }`}
          show={this.props.showModal}
          onHide={this.props.toggleModal}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header>
            <h5>{this.props.title}</h5>
            <div
              className="btn_close pointer"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={this.props.toggleModal}
            >
              <img src={closeIcon} alt="" />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {user && (
              <div className="td-img-group d-flex align-items-start m-top-2">
                <div className="round-img m-right-2 mis-flyer-img-outer">
                  {user.image ? (
                    <img src={process.env.REACT_APP_CDN + user.image.small} alt="" className="mis-flyer-img" />
                  ) : (
                    <img src={userIcon} alt="" />
                  )}
                </div>
                {user.userName}
                <br />
                {user.email}
                {contest.title && <br />}
              </div>
            )}
            {user && (
              <div className="td-img-group d-flex align-items-start m-top-2">
                <div className="round-img m-right-2 mis-flyer-img-outer">
                  {contest.image && (
                    <img src={process.env.REACT_APP_CDN + contest.image.small} alt="" className="mis-flyer-img" />
                  )}
                </div>
                {contest.title}
              </div>
            )}
            <div className="new-form-group mt-4">{this.renderTextarea("reason", "Reason", "Enter a reason")}</div>{" "}
          </Offcanvas.Body>
          <div className="offcanvas-footer">
            <NextButton
              classData="btn btn-default btn-block h-100"
              label="Save"
              loading={this.state.loading}
              handleSubmit={this.handleSubmit}
            />
          </div>
        </Offcanvas>{" "}
        <AlertModal
          alertModalToggle={this.suspendModalToggle}
          alertModalShow={this.state.showSuspendUser}
          title="Alert"
          description={this.props.description}
          onPress={(e) => {
            this.setState({ loading: true });
            this.props.handleSubmit(e, this.state.data?.reason, () => {
              this.suspendModalToggle();
              this.props.toggleModal();
              this.setState({ loading: false });
            });
          }}
          loading={this.state.loading}
          alertType="Yes"
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data, id, callback) => dispatch(updateUser(data, id, callback)),
  updateProfilePicture: (data, id, callback) => dispatch(updateProfilePicture(data, id, callback)),
});
const mapStateToProps = (state) => ({
  getUsers: getUsers(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditUser));
