import React from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import { addSettings } from "store/settings";
import { getGame, loadBGGames } from "store/games";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Joi from "joi-browser";
import _, { isEmpty } from "lodash";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";

//  Components
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";
import SelectSearch from "common/form/selectSearch";

class AddDailyGameChallenge extends Form {
  fileInputRef = React.createRef(null);
  initialState = {
    data: {
      dailyGameChallenge: [
        {
          day: "MONDAY",
          bgGame: {},
          highestScore: 0,
        },
        {
          day: "TUESDAY",
          bgGame: {},
          highestScore: 0,
        },
        {
          day: "WEDNESDAY",
          bgGame: {},
          highestScore: 0,
        },
        {
          day: "THURSDAY",
          bgGame: {},
          highestScore: 0,
        },
        {
          day: "FRIDAY",
          bgGame: {},
          highestScore: 0,
        },
        {
          day: "SATURDAY",
          bgGame: {},
          highestScore: 0,
        },
        {
          day: "SUNDAY",
          bgGame: {},
          highestScore: 0,
        },
      ],
    },

    errors: { dailyGameChallenge: {} },
    loading: false,
    bgGamesLoading: false,
  };
  state = this.initialState;

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal) {
        this.setState({ bgGamesLoading: true });
        this.props.loadBGGames({ isActive: true, isComingSoon: false }, (res) => {
          if (this.props.edit) {
            const data = { ...this.state.data };
            const editData = this.props.edit?.dailyGameChallenge?.reduce(
              (obj, challenge) => (challenge?.day ? { ...obj, [challenge.day]: challenge } : obj),
              {},
            );
            if (!isEmpty(editData)) {
              data.dailyGameChallenge = data?.dailyGameChallenge?.map((challenge) => {
                const bgGame = res?.data?.data?.find((item) => item.id === editData[challenge?.day]?.gameId);

                return {
                  day: challenge?.day,
                  bgGame: {
                    name: bgGame?.name,
                    value: bgGame?.id,
                    data: bgGame,
                  },
                  highestScore: editData[challenge?.day]?.highestScore,
                };
              });
            }

            this.setState({
              data,
              bgGamesLoading: false,
            });
          } else {
            this.setState({ bgGamesLoading: false });
          }
        });
      }
    }
  };
  doSubmit = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    errors.dailyGameChallenge = data?.dailyGameChallenge?.map((challenge) => ({
      day: this.validateProperty({
        name: "day",
        value: challenge?.day,
      }),
      bgGame: this.validateProperty({
        name: "bgGame",
        value: challenge?.bgGame,
      }),
      highestScore: this.validateProperty({
        name: "highestScore",
        value: challenge?.highestScore,
      }),
    }));

    if (errors?.dailyGameChallenge?.find((err) => Object.values(err)?.some((v) => v !== null))) {
      this.setState({ errors });
      return;
    }

    this.setState({ loading: true });

    const payLoad = {
      dailyGameChallenge: data?.dailyGameChallenge?.map((challenge) => ({
        day: challenge?.day,
        gameId: challenge?.bgGame?.value,
        highestScore: challenge?.highestScore ? parseInt(challenge?.highestScore) : null,
      })),
    };

    this.props.addSettings(payLoad, (settingsResponse) => {
      if (settingsResponse?.status === 200) {
        toast(<AlertSuccess message="Information Saved" />, {
          containerId: 1,
        });
        this.props.loadSettings(() => {
          this.props.toggleModal();
          this.clearData();
          this.setState({
            loading: false,
          });
        });
      } else {
        this.setState({
          loading: false,
        });
        toast(<AlertError message={settingsResponse?.data && settingsResponse.data?.message} />, {
          containerId: 1,
        });
      }
    });
  };

  schema = {
    day: Joi.string().required(),
    bgGame: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
    }),
    highestScore: Joi.number().min(0).required(),
  };

  onChangeSelect = (e, index) => {
    const data = this.state.data;
    const errors = structuredClone(this.state.errors);
    data.dailyGameChallenge[index][e.name] = e.currentTarget;
    const error = errors?.dailyGameChallenge && errors.dailyGameChallenge[index];
    if (error && error[e.name]) {
      delete error[e.name];
    }
    this.setState({ data, errors });
  };
  clearData = () => {
    this.setState({
      ...this.initialState,
    });
  };
  onChange = (name, value, index) => {
    const data = structuredClone(this.state.data);
    const errors = structuredClone(this.state.errors);
    const error = errors?.dailyGameChallenge && errors.dailyGameChallenge[index];
    if (error && error[name]) {
      delete error[name];
    }
    const challenge = data?.dailyGameChallenge[index];
    challenge[name] = value;
    data.dailyGameChallenge[index] = challenge;
    this.setState({ data, errors });
  };
  render() {
    const { bgGames } = this.props.getGame;
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel almost-full-modal"}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>Daily Game Challenge</h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!this.state.bgGamesLoading && (
            <div className="form-check-outer">
              <div className="new-form-group">
                <table className="table large-table flyer-table">
                  <thead>
                    <tr>
                      <th style={{ width: "30%" }}>Week Days</th>
                      <th style={{ width: "40%" }}>BG Game</th>
                      <th style={{ width: "30%" }}>Challenging Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data?.dailyGameChallenge?.map((challenge, index) => (
                      <tr className="flyer-table" key={index}>
                        <td>{challenge?.day}</td>
                        <td>
                          <SelectSearch
                            name="bgGame"
                            options={_.sortBy(bgGames, ["name"]).map((game) => ({
                              name: game?.name,
                              value: game?.id,
                              data: game,
                            }))}
                            label="Select BG Game"
                            value={challenge.bgGame}
                            error={
                              this.state.errors?.dailyGameChallenge[index] &&
                              this.state.errors?.dailyGameChallenge[index].bgGame
                            }
                            onChange={(e) => this.onChangeSelect(e, index)}
                            isLoading={this.state.bgGamesLoading}
                          />
                        </td>
                        <td>
                          <input
                            name="highestScore"
                            value={challenge?.highestScore}
                            type="number"
                            className={`form-control ${
                              this.state.errors?.dailyGameChallenge &&
                              this.state.errors?.dailyGameChallenge[index] &&
                              this.state.errors.dailyGameChallenge[index].highestScore &&
                              "error"
                            }`}
                            onChange={(e) => {
                              this.onChange(e.target.name, e.target.value, index);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>{" "}
              </div>
            </div>
          )}
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loading}
            handleSubmit={this.doSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadBGGames: (params, callback) => dispatch(loadBGGames(params, callback)),
  addSettings: (data, callback) => dispatch(addSettings(data, callback)),
});
const mapStateToProps = (state) => ({
  getGame: getGame(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddDailyGameChallenge));
