import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import backIcon from "include/images/chevron-left.svg";
import racerGameImg from "include/images/robot-racer.png";
import loadingIcon from "include/images/loader-grey.svg";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  getDashboard,
  loadDogRunDashboard,
  loadDogRunOnlineUsers,
  loadDogRunTopLosers,
  loadDogRunTopUsersByTimeSpent,
  loadDogRunTopWinners,
} from "store/dashboard";
import { msToHMS } from "utils/misc";
import AllOnlineUserFlyer from "../flyers/allOnlineUsersFlyer";
import AllUserEngagementFlyer from "../flyers/allUserEngagementFlyer";
import ViewAllFlyer from "../flyers/viewAllFlyer";

const DogRunStats = (props) => {
  const ref = useRef();
  const [type, setType] = useState("winner");
  const [showViewAll, setShowViewAll] = useState(false);
  const [showAllUserEngagement, setShowAllUserEngagement] = useState(false);
  const [loadingMain, setLoadingMain] = useState(true);
  useEffect(() => {
    props.loadDogRunDashboard(() => setLoadingMain(false));
    props.loadDogRunTopWinners({ page: 1 });
    props.loadDogRunTopLosers({ page: 1 });
    props.loadDogRunTopUsersByTimeSpent({ page: 1 });
    props.loadDogRunOnlineUsers({ page: 1 });
  }, []);
  const {
    dogRunDashboard,
    dogRunTopWinners,
    dogRunTopWinnersLoading,
    dogRunTopWinnersPagination,
    dogRunTopLosers,
    dogRunTopLosersLoading,
    dogRunTopLosersPagination,
    dogRunTopUsersByTimeSpent,
    dogRunTopUsersByTimeSpentPagination,
    dogRunTopUsersByTimeSpentLoading,
    dogRunOnlineUsers,
    dogRunOnlineUsersPagination,
  } = props.getDashboard;

  useEffect(() => {
    if (!loadingMain) ref?.current?.complete();
    else ref?.current?.continuousStart();
  }, [loadingMain]);

  const toggleViewAll = (e, type) => {
    e.preventDefault();
    if (type) setType(type);
    setShowViewAll(!showViewAll);
  };
  const toggleViewUserEngagement = (e) => {
    e.preventDefault();
    setShowAllUserEngagement(!showAllUserEngagement);
  };
  return (
    <>
      <Header />
      <Loader loaderRef={ref} />
      <div className="page-wrapper add-dev-server-wrapper">
        <SideBar page="dashboard" />
        {!loadingMain && (
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Robot Racer Game</h1>
            </div>
            <div className="fwc-wrapper">
              <Link
                to="#"
                className="back-link-btn"
                onClick={(e) => {
                  e.preventDefault();
                  props.history.goBack();
                }}
              >
                <img src={backIcon} alt="" /> Back
              </Link>
              <div className="grid-card-details-row d-flex flex-wrap">
                <div className="gcdr-img-box">
                  <div className="gcdr-img-outer text-white">
                    <div className="gcdr-image">
                      <img src={racerGameImg} alt="" />
                    </div>
                    <div className="gcdr-group d-flex flex-wrap">
                      <div className="gcdr-item flex-grow-1 w-auto">
                        <h6>{msToHMS(parseInt(dogRunDashboard?.totalTimeSpend))}</h6>
                        <p>
                          <strong>Time Spend</strong>
                        </p>
                      </div>
                      <div className="gcdr-item flex-grow-1 w-auto">
                        <h6>{dogRunDashboard?.onlineUsers?.toLocaleString("en-us") ?? 0}</h6>
                        <p>
                          <strong>ONLINE USERS</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="gcdr-widget-wrapper">
                    <div className="gcdr-widget-box">
                      <h4>Details</h4>
                      <div className="gcdr-details-box">
                        <div className="gcdr-details-item d-flex align-items-center justify-content-between">
                          <div>Total Games Played</div>
                          <div>{dogRunDashboard?.completedRound?.toLocaleString("en-us") ?? 0}</div>
                        </div>
                        <div className="gcdr-details-item d-flex align-items-center justify-content-between">
                          <div>Unique Players</div>
                          <div>{dogRunDashboard?.totalPlayer?.toLocaleString("en-us") ?? 0}</div>
                        </div>
                        <div className="gcdr-details-item d-flex align-items-center justify-content-between">
                          <div>Games per Player</div>
                          <div>
                            {dogRunDashboard?.gamePerPlayerRatio?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) ?? 0}
                          </div>
                        </div>
                        <div className="gcdr-details-item d-flex align-items-center justify-content-between">
                          <div>Bets per Player</div>
                          <div>
                            {dogRunDashboard?.betPerPlayerRatio?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) ?? 0}
                          </div>
                        </div>
                        <div className="gcdr-details-item d-flex align-items-center justify-content-between">
                          <div>Empty Rounds with No Players</div>
                          <div>{dogRunDashboard?.emptyRound?.toLocaleString("en-us") ?? 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gcdr-text-box">
                  <div className="gcdr-heading d-flex align-items-center justify-content-between">
                    <h2>Robot Racer Game</h2>
                  </div>

                  <div className="gcdr-widget-wrapper mt-2">
                    <div className="gcdr-widget-box">
                      <ul className="gw-list d-flex flex-wrap">
                        <li>
                          <div className="gw-box">
                            <em>Total Rounds</em>
                            <h6>{dogRunDashboard?.totalRound?.toLocaleString("en-us") ?? 0}</h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Available Rounds</em>
                            <h6>{dogRunDashboard?.availableRounds?.toLocaleString("en-us") ?? 0}</h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Completed Rounds</em>
                            <h6>{dogRunDashboard?.completedRound?.toLocaleString("en-us") ?? 0}</h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Total Bet Amount</em>
                            <h6>
                              {dogRunDashboard?.totalBetAmount?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) ?? 0}
                            </h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Total Amount Won</em>
                            <h6>
                              {dogRunDashboard?.totalWinningAmount?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) ?? 0}
                            </h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>BG Profit</em>
                            <h6>
                              {dogRunDashboard?.BGProfit?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) ?? 0}
                            </h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="gcdr-widget-box">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>Top 10 Winners</h4>{" "}
                        <Link to="#" className="back-link-btn m-0" onClick={(e) => toggleViewAll(e, "winner")}>
                          View all
                        </Link>
                      </div>
                      <div className="table-responsive">
                        <table className="table custom-table-striped">
                          <thead>
                            <tr>
                              <th>Username</th>
                              <th>No of Times Bets Placed</th>
                              <th>Loot Bucks Spent</th>
                              <th>Loot Bucks Won</th>
                              <th>Profit/Loss</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dogRunTopWinners?.length > 0 &&
                              dogRunTopWinners?.map((winner, index) => (
                                <tr key={winner?._id}>
                                  <td>{winner?.user?.userName}</td>
                                  <td>{winner?.totalBetsPlaced}</td>
                                  <td>
                                    {winner?.totalBetAmount?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                  <td>
                                    {winner?.totalWinningAmount?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                  <td>
                                    {winner?.profitDifference?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {dogRunTopWinners?.length === 0 &&
                          (dogRunTopWinnersLoading ? (
                            <div className="w-100 d-flex justify-content-center load-more">
                              <img src={loadingIcon} className="fa-spin" alt="" />
                            </div>
                          ) : (
                            <div className="nfr-box">No Records Found!</div>
                          ))}
                      </div>
                    </div>
                    <div className="gcdr-widget-box">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>Top 10 Losers</h4>
                        <Link to="#" className="back-link-btn m-0" onClick={(e) => toggleViewAll(e, "loser")}>
                          View all
                        </Link>
                      </div>
                      <div className="table-responsive">
                        <table className="table custom-table-striped">
                          <thead>
                            <tr>
                              <th>Username</th>
                              <th>No of Times Bets Placed</th>
                              <th>Loot Bucks Spent</th>
                              <th>Loot Bucks Won</th>
                              <th>Profit/Loss</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dogRunTopLosers?.length > 0 &&
                              dogRunTopLosers?.slice(0, 10)?.map((loser, index) => (
                                <tr key={loser?._id}>
                                  <td>{loser?.user?.userName}</td>
                                  <td>{loser?.totalBetsPlaced}</td>
                                  <td>
                                    {loser?.totalBetAmount?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                  <td>
                                    {loser?.totalWinningAmount?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                  <td>
                                    {loser?.profitDifference?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {dogRunTopLosers?.length === 0 &&
                          (dogRunTopLosersLoading ? (
                            <div className="w-100 d-flex justify-content-center load-more">
                              <img src={loadingIcon} className="fa-spin" alt="" />
                            </div>
                          ) : (
                            <div className="nfr-box">No Records Found!</div>
                          ))}
                      </div>
                    </div>
                    <div className="gcdr-widget-box">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>User Engagement</h4>
                        <Link to="#" className="back-link-btn m-0" onClick={toggleViewUserEngagement}>
                          View all
                        </Link>
                      </div>
                      <div className="table-responsive">
                        <table className="table custom-table-striped">
                          <thead>
                            <tr>
                              <th>Username</th>
                              <th>Total Time Spent</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dogRunTopUsersByTimeSpent?.length > 0 &&
                              dogRunTopUsersByTimeSpent?.slice(0, 10)?.map((user, index) => (
                                <tr key={user?._id}>
                                  <td>{user?.user?.userName}</td>
                                  <td>{msToHMS(user?.totalSpendTime)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {dogRunTopUsersByTimeSpent?.length === 0 &&
                          (dogRunTopUsersByTimeSpentLoading ? (
                            <div className="w-100 d-flex justify-content-center load-more">
                              <img src={loadingIcon} className="fa-spin" alt="" />
                            </div>
                          ) : (
                            <div className="nfr-box">No Records Found!</div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ViewAllFlyer
        type={type}
        players={type === "winner" ? dogRunTopWinners : dogRunTopLosers}
        loadMore={type === "winner" ? props.loadDogRunTopWinners : props.loadDogRunTopLosers}
        pagination={type === "winner" ? dogRunTopWinnersPagination : dogRunTopLosersPagination}
        showModal={showViewAll}
        toggleModal={toggleViewAll}
      />
      <AllUserEngagementFlyer
        topUsersByTimeSpent={dogRunTopUsersByTimeSpent}
        loadMore={props.loadDogRunTopUsersByTimeSpent}
        pagination={dogRunTopUsersByTimeSpentPagination}
        showModal={showAllUserEngagement}
        toggleModal={toggleViewUserEngagement}
      />
      {(showViewAll || showAllUserEngagement) && (
        <div
          className="modal-backdrop show fade"
          onClick={showViewAll ? toggleViewAll : toggleViewUserEngagement}
        ></div>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  loadDogRunDashboard: (callback) => dispatch(loadDogRunDashboard(callback)),
  loadDogRunTopWinners: (pramas, fresh, callback) => dispatch(loadDogRunTopWinners(pramas, fresh, callback)),
  loadDogRunTopLosers: (pramas, fresh, callback) => dispatch(loadDogRunTopLosers(pramas, fresh, callback)),
  loadDogRunTopUsersByTimeSpent: (params, fresh, callback) =>
    dispatch(loadDogRunTopUsersByTimeSpent(params, fresh, callback)),
  loadDogRunOnlineUsers: (params, fresh, callback) => dispatch(loadDogRunOnlineUsers(params, fresh, callback)),
});
const mapStateToProps = (state) => ({
  getDashboard: getDashboard(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DogRunStats));
