import React from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import { getUsers, updateProfilePicture, updateUser, updateUserReceived, updateUserImageReceived } from "store/users";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Joi from "joi-browser";
import { fetchNFT, ethToUSD } from "utils/externalApiCaller";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import Amplify, { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import plusIcon from "include/images/plus-1.svg";
import ethIcon from "include/images/eth-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";

//  Components
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";

class EditUser extends Form {
  state = {
    loading: false,
    data: {
      userName: "",
      description: "",
      twitter: "",
      website: "",
      instagram: "",
      email: "",
      image: null,
    },
    errors: {},
    imageChange: false,
    loadingImages: false,
    loadingReward: false,
    showTooltip: false,
    showFields: false,
    showNFTFields: false,
    loadingNFT: false,
    ethVPrice: "",
    loadingEth: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
    }
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      const user = this.props.user;
      this.setState({
        data: {
          ...this.state.data,
          email: user.email,
          description: user.description,
          image: user.image,
          instagram: user.instagram,
          twitter: user.twitter,
          userName: user.userName,
          website: user.website,
        },
      });
    }
  };

  schema = {
    description: Joi.string().allow(""),
    twitter: Joi.string().allow(""),
    email: Joi.string().allow(""),
    instagram: Joi.string().allow(""),
    website: Joi.string().allow(""),
    userName: Joi.string().allow(""),

    image: Joi.object().allow(null),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        userName: "",
        description: "",
        twitter: "",
        website: "",
        instagram: "",
        email: "",
        image: null,
      },
      errors: {},
      loadingImages: false,
      loadingReward: false,
      showTooltip: false,
      showFields: false,
      showNFTFields: false,
      loadingNFT: false,
      ethVPrice: "",
    });
  };
  render() {
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel"}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>User Details</h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={`new-form-group ${this.state.errors.userName && "has-error"}`}>
            <label className="form-label">Username</label>
            <input
              name="userName"
              id="userName"
              maxLength={15}
              className={this.state.errors.userName ? "form-control error" : "form-control"}
              onChange={this.onChange}
              value={this.state.data.userName}
              disabled
            />
            {this.state.errors.userName && (
              <div className="invalid-feedback">
                Username must be of minimum 4 characters and maximum 15 characters.
              </div>
            )}
          </div>{" "}
          <div className="new-form-group">{this.renderInput("email", "Email", "", "", true)}</div>
          {this.state.data.description && (
            <div className="new-form-group">
              {" "}
              <div class="d-flex align-items-center justify-content-between">
                <label>Description</label>{" "}
                <div class="label-count" id="the-count">
                  <span id="current">
                    {this.state.data && this.state.data.description ? this.state.data.description.length : 0}
                  </span>
                  <span id="maximum">/ 160</span>
                </div>
              </div>
              <textarea
                name="description"
                id="description"
                maxLength={160}
                className={this.state.errors.description ? "form-control error" : "form-control"}
                multiline
                value={this.state.data.description}
                onChange={this.onChange}
                disabled={true}
              />
            </div>
          )}
          {this.state.data.twitter && (
            <div className="new-form-group">{this.renderInput("twitter", "Twitter", "", "", true)}</div>
          )}
          {this.state.data.instagram && (
            <div className="new-form-group">{this.renderInput("instagram", "Instagram", "", "", true)}</div>
          )}
          {this.state.data.website && (
            <div className="new-form-group">{this.renderInput("website", "Discord", "", "", true)}</div>
          )}
          <div className="kt-upload-row d-flex flex-wrap align-items-start">
            <div
              className={`kt-upload-outer ${
                !this.state.data.image ? "placeholder-uploaded-img" : " position-relative"
              } ${this.state.errors.image && "border-error"}`}
            >
              <div className="kt-upload-img">
                <img
                  src={this.state.data.image ? process.env.REACT_APP_CDN + this.state.data.image.medium : plusIcon}
                  alt=""
                />
              </div>{" "}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data, id, callback) => dispatch(updateUser(data, id, callback)),
  updateProfilePicture: (data, id, callback) => dispatch(updateProfilePicture(data, id, callback)),
  updateUserReceived: (payLoad) => dispatch(updateUserReceived(payLoad)),
  updateUserImageReceived: (payLoad) => dispatch(updateUserImageReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getUsers: getUsers(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditUser));
