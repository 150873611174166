import moment from "moment/moment";
import { createRef } from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getQuestion, loadAllHistory } from "store/question";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import loadingIcon from "include/images/loader-grey.svg";

//  Components
import Form from "common/form/form";
import Loader from "common/loader";

class ListingFlyer extends Form {
  ref = createRef(null);
  scrollRef = createRef(null);
  state = {
    loading: false,
    loadingWinner: false,
    winner: "",
    loadingFilters: false,
    showFilters: false,
    appliedFilters: {},
    errors: {},
    currKey: "",
    loadingReset: false,
  };
  handleLoadMore = (entries) => {
    const target = entries[0];
    if (target?.isIntersecting) {
      const { allHistory, totalHistoryCount } = this.props.getQuestion;
      if (!this.state.loadingMore && allHistory?.length < totalHistoryCount) {
        this.setState({ loadingMore: true });
        this.searchFilters();
        this.observer.disconnect();
      }
    }
  };
  observer = new IntersectionObserver(this.handleLoadMore, {
    threshold: 0.2,
  });
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({ loading: true, currKey: "" }, this.searchFilters);
    }
    if (!this.props.getQuestion.loading && this.props.getQuestion.loading !== prevProps.getQuestion.loading)
      this.ref.current.complete();

    if (this.props.getQuestion.loading && this.props.getQuestion.loading !== prevProps.getQuestion.loading)
      this.ref.current.continuousStart();
    if (this.state.currKey !== prevState.currKey && this.state.currKey !== null) {
      if (this.observer && this.scrollRef?.current) {
        this.observer.observe(this.scrollRef.current);
      }
    }
  };
  searchFilters = () => {
    const params = {};
    if (this.props.objectId) {
      params.objectId = this.props.objectId;
    }
    if (this.state.currKey) params.lastKey = this.state.currKey;
    this.props.loadAllHistory(params, this.props.moduleName, this.state.currKey?.length === 0, (res) => {
      this.setState({ loading: false, loadingMore: false, currKey: res?.data?.lastKey });
    });
  };
  onRefChange = (node, i) => {
    const { allHistory, totalHistoryCount } = this.props.getQuestion;

    if (i === allHistory?.length - 1 && allHistory?.length < totalHistoryCount) {
      this.scrollRef.current = node;
    }
  };
  render() {
    const { allHistory, totalHistoryCount } = this.props.getQuestion;
    return (
      <>
        <Loader loaderRef={this.ref} />
        <Offcanvas
          className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2 z-index-highest"}
          show={this.props.showModal}
          onHide={this.props.toggleModal}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header>
            <h5>History {totalHistoryCount > 0 && `(${totalHistoryCount})`}</h5>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div
                className="btn_close pointer"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={this.props.toggleModal}
              >
                <img src={closeIcon} alt="" />
              </div>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {this.props.objectId && (
              <div className="offcanvas-header-bottom-2 td-img-group d-flex align-items-start m-top-2 lh-base">
                <h5 className="camelCase">
                  {this.props.moduleName === "paidQuestion" ||
                  this.props.moduleName === "freeQuestion" ||
                  this.props.moduleName === "dailyTriviaQuestion"
                    ? "Question "
                    : this.props.moduleName === "contestTemplate" || this.props.moduleName === "winnerTemplate"
                    ? "Template "
                    : this.props.moduleName === "customSlide"
                    ? "Slide "
                    : this.props.moduleName === "customMessage"
                    ? "Message "
                    : this.props.moduleName === "newsletterSubscriber"
                    ? "Subscriber "
                    : this.props.moduleName === "importQuestion"
                    ? "Filename "
                    : this.props.moduleName === "waitList"
                    ? "User "
                    : this.props.moduleName}
                </h5>
                : {this.props.title}
              </div>
            )}
            <div className="">
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {allHistory.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Action</th>
                            {!this.props.objectId && (
                              <th className="camelCase">
                                {this.props.moduleName === "paidQuestion" ||
                                this.props.moduleName === "freeQuestion" ||
                                this.props.moduleName === "dailyTriviaQuestion"
                                  ? "Question "
                                  : this.props.moduleName === "contestTemplate" ||
                                    this.props.moduleName === "winnerTemplate"
                                  ? "Template "
                                  : this.props.moduleName === "customSlide"
                                  ? "Slide "
                                  : this.props.moduleName === "customMessage"
                                  ? "Message "
                                  : this.props.moduleName === "newsletterSubscriber"
                                  ? "Subscriber "
                                  : this.props.moduleName === "importQuestion"
                                  ? "Filename "
                                  : this.props.moduleName === "waitList"
                                  ? "User "
                                  : this.props.moduleName === "imageUploader"
                                  ? "Image "
                                  : this.props.moduleName}{" "}
                              </th>
                            )}
                            <th>Date</th>
                            <th>Action By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allHistory &&
                            allHistory.length > 0 &&
                            allHistory.map((item, i) => {
                              const beforeData = item.beforeData && JSON.parse(item.beforeData);
                              const afterData = item.afterData && JSON.parse(item.afterData);
                              const data = beforeData ? beforeData : afterData;
                              return (
                                <tr key={item?.id} ref={(node) => this.onRefChange(node, i)}>
                                  <td className="camelCase">
                                    {item && item.action && item.action.replace(/([a-z0-9])([A-Z])/g, "$1 $2")}
                                  </td>
                                  {!this.props.objectId && (
                                    <td>
                                      {item.module.toLowerCase().includes("question") &&
                                        (item.objectDetails && item.objectDetails.questionText
                                          ? item.objectDetails.questionText
                                          : data.questionText
                                          ? data.questionText
                                          : item.action.toLowerCase().includes("option")
                                          ? data.name
                                          : item.action.toLowerCase().includes("comment")
                                          ? data.comment
                                          : data.questionText)}{" "}
                                      {(item.module === "reward" ||
                                        item.module === "contest" ||
                                        item.module === "winner" ||
                                        item.module === "winnerTemplate") &&
                                        (item.objectDetails ? item.objectDetails.title : data ? data.title : "")}{" "}
                                      {item.module === "game" &&
                                        (item.objectDetails
                                          ? item.objectDetails.contest && item.objectDetails.contest.title
                                          : data
                                          ? data.contest && data.contest.title
                                          : "")}
                                      {item.module === "contestTemplate" && item.objectDetails
                                        ? item.objectDetails.name
                                        : data
                                        ? data.name
                                        : ""}{" "}
                                      {item.module === "customSlide" &&
                                        (item.objectDetails ? item.objectDetails.titleLine1 : data && data.titleLine1)}
                                      {item.module === "customMessage" &&
                                        data &&
                                        `${data.correctAnswerLowerLimit} - ${data.correctAnswerUpperLimit}`}
                                      {(item.module === "newsletterSubscriber" || item.module === "waitList") &&
                                        data &&
                                        data.email}
                                      {item.module === "importQuestion" &&
                                        item.objectDetails &&
                                        item.objectDetails.fileName}{" "}
                                      {item.module === "imageUploader" &&
                                        data &&
                                        ("filename" in data ? data.filename : data.key)}
                                      {item.module === "user" && data?.userName}
                                    </td>
                                  )}
                                  <td>{moment(item.createdAt).format("MM/DD/YYYY hh:mm A")}</td>
                                  <td>
                                    {item.createdBy && item.createdBy.firstName}{" "}
                                    {item.createdBy && item.createdBy.lastName}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>{" "}
            {this.state.loadingMore && (
              <div className="w-100 d-flex justify-content-center load-more">
                <img src={loadingIcon} className="fa-spin" alt="" />
              </div>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadAllHistory: (params, moduleName, fresh, callback) =>
    dispatch(loadAllHistory(params, moduleName, fresh, callback)),
});
const mapStateToProps = (state) => ({
  getQuestion: getQuestion(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListingFlyer));
