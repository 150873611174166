import Amplify, { Auth, Storage } from "aws-amplify";
import Joi from "joi-browser";
import _ from "lodash";
import moment from "moment-timezone";
import { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { addContest, getContest, updateContest } from "store/contest";
import { getGame, loadBGGames } from "store/games";
import { getSettings, loadContestTemplate, loadSettings } from "store/settings";
import { v4 as uuidv4 } from "uuid";

//  Images
import deleteIcon from "include/images/delete-icon.svg";
import plusIcon from "include/images/plus-1.svg";

//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import SelectSearch from "common/form/selectSearch";
import NextButton from "common/form/submitButton";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "../../common/sidebar";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});

class AddContest extends Form {
  ref = createRef(null);
  state = {
    nftNetwork: [
      { name: "ETH MAINNET", value: "ETH_MAINNET" },
      { name: "POLYGON MAINNET", value: "MATIC_MAINNET" },
    ],
    data: {
      nftContractAddress: "",
      nftNetwork: {},
      rounds: { data: null, name: 5, value: 5 },
      title: "",
      startDate: "",
      endDate: "",
      bgGame: {},
      contestTemplate: {},
      image: null,
      contestType: 1,
      isPrivate: false,
      // Arcade
      arcadeDescription: "",
      arcadeTitle: "",
      xPToBeAwarded: "",
      entryFeeArcadeCoins: 0,

      // Loot
      lootTitle: "",
      ticketsToBeAwarded: "",
      lootDescription: "",
      entryFeeLootBonusCoins: 0,
    },
    id: "",
    edit: false,
    errors: {},
    loadingImages: false,
    loadingContest: false,
    bgGamesLoading: false,
    imageChange: false,
    imageChangeLoot: false,
    loadingTemplate: false,
    loading: false,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      (!this.props.getContest.loading && this.props.getContest.loading !== prevProps.getContest.loading) ||
      (!this.state.loading && this.state.loading !== prevState.loading)
    )
      this.ref.current.complete();

    if (
      (this.props.getContest.loading && this.props.getContest.loading !== prevProps.getContest.loading) ||
      (this.state.loading && this.state.loading !== prevState.loading)
    )
      this.ref.current.continuousStart();
  };
  componentDidMount = async () => {
    this.setState({ loading: true, bgGamesLoading: true, loadingTemplate: true });
    await this.props.loadBGGames({ isActive: true, isComingSoon: false }, () => {
      this.setState({ bgGamesLoading: false });
    });
    await this.props.loadContestTemplate({}, (res) => {
      this.setState({ loadingTemplate: false, loading: false });
    });

    if (this.props.location.state) {
      const data = { ...this.state.data };
      const contest = this.props.location.state.contest;
      if (!contest) this.props.history.goBack();
      data.title = contest.title;
      data.contestType = contest?.contestType;
      data.isPrivate = contest?.isPrivate;
      data.image = contest?.image;
      data.startDate = new Date(contest && contest.startDate);
      data.endDate = contest.endDate ? new Date(contest && contest.endDate) : "";
      data.bgGame = {
        name: contest.bgGame?.name,
        value: contest.bgGame?.id,
        data: contest.bgGame,
      };

      //  Arcade Coins
      data.entryFeeArcadeCoins = contest.entryFeeArcadeCoins;
      data.xPToBeAwarded = contest?.xPToBeAwarded ? contest.xPToBeAwarded.toString() : "";
      data.arcadeTitle = contest?.arcadeTitle ?? "";
      data.arcadeDescription = contest?.arcadeDescription ?? "";

      //  Loot Bucks
      data.entryFeeLootBonusCoins = contest.entryFeeLootBonusCoins;
      data.ticketsToBeAwarded = contest.ticketsToBeAwarded ? contest.ticketsToBeAwarded.toString() : "";
      data.lootTitle = contest?.lootTitle ?? "";
      data.lootDescription = contest?.lootDescription ?? "";

      data.nftContractAddress = contest?.nftContractAddress;
      data.nftNetwork = contest?.nftNetwork ? this.state.nftNetwork.find((v) => v.value == contest.nftNetwork) : {};

      this.setState({ data, edit: true, id: contest.id });
    }
  };
  handleImageChange = (e, type) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    this.setState({ loadingImages: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ loadingImages: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ loadingImages: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.setState({
          errors,
          loadingImages: false,
          [type === "image" ? "imageChange" : "imageChangeLoot"]: true,
          data: {
            ...this.state.data,
            [type]: { medium: "public/" + result.key },
          },
        });
      });
    }
    this.setState({ data });
    // e.target.value = null;
  };

  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];

    this.setState({ data, errors });
  };

  onChangeSelect = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    console.log("data[e.name]", e.name, data[e.name]);
    if (e.name === "contestTemplate") {
      const fieldData = e.currentTarget?.data;
      const bgGameData = this.props.getGame?.bgGames?.find((val) => val.id === fieldData?.bgGameId);
      data.title = fieldData?.contestTitle;
      data.image = fieldData?.image;
      data.bgGame = {
        name: bgGameData?.name,
        value: bgGameData?.id,
        data: bgGameData,
      };
      data.contestType = fieldData?.contestTemplateType;

      // Arcade
      data.arcadeTitle = fieldData?.arcadeContestTitle ?? "";
      data.arcadeDescription = fieldData?.arcadeContestDescription ?? "";
      data.entryFeeArcadeCoins = fieldData?.entryFeeArcadeCoins ?? 0;
      data.xPToBeAwarded = fieldData?.xPToBeAwarded ?? 0;

      //  Loot
      data.lootTitle = fieldData?.lootContestTitle ?? "";
      data.lootDescription = fieldData?.lootContestDescription ?? "";
      data.entryFeeLootBonusCoins = fieldData?.entryFeeLootBonusCoins ?? 0;
      data.ticketsToBeAwarded = fieldData?.ticketsToBeAwarded ?? 0;
    }
    delete errors[e.name];
    this.setState({ data, errors });
  };

  doSubmit = (e) => {
    this.setState({ loadingContest: true });
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };

    if (_.isEmpty(data.mode)) errors.mode = "Mode is required";
    const payLoad = {
      title: data.title,
      nftContractAddress: data.nftContractAddress,
      nftNetwork: data.nftNetwork.value,
      startDate: parseInt(moment(data.startDate).format("x")),
      endDate: data.endDate ? parseInt(moment(data.endDate).format("x")) : null,
      maxEntries: parseInt(""),
      reserve: false,
      bgGameId: data.bgGame?.value,
      contestType: data.contestType,
      isPrivate: data.isPrivate,
      closeOnEndDate: true,
      closeOnTotalEntries: false,
      minimumEntries: null,
      category: "",
      subcategory: "",
    };

    //  Arcade
    if (this.state.data.contestType === 1 || this.state.data.contestType === 2) {
      payLoad.entryFeeArcadeCoins = parseInt(data.entryFeeArcadeCoins);
      payLoad.xPToBeAwarded = data.xPToBeAwarded ? parseInt(data.xPToBeAwarded) : null;
      payLoad.arcadeDescription = data.arcadeDescription.trim();
      payLoad.arcadeTitle = data.arcadeTitle;
    }

    //  Loot
    if (this.state.data.contestType === 1 || this.state.data.contestType === 3) {
      payLoad.entryFeeLootBonusCoins = parseFloat(data.entryFeeLootBonusCoins);
      payLoad.ticketsToBeAwarded = data.ticketsToBeAwarded ? parseInt(data.ticketsToBeAwarded) : null;
      payLoad.lootDescription = data.lootDescription.trim();
      payLoad.lootTitle = data.lootTitle;
    }

    if (data?.bgGame?.data?.scoringType === 1) {
      payLoad.rounds = data.rounds?.value;
    }
    payLoad.image = !_.isEmpty(data.image)
      ? this.state.edit && !this.state.imageChange
        ? data.image
        : data.image.medium
      : null;

    if (this.state.edit) {
      this.props.updateContest(payLoad, this.state.id, (contestResponse) => {
        this.setState({ loadingContest: false });
        if (contestResponse.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.history.goBack();
        } else {
          toast(<AlertError message={contestResponse.data && contestResponse.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      this.props.addContest(payLoad, (contestResponse) => {
        this.setState({
          loadingContest: false,
        });
        if (contestResponse.status === 200) {
          toast(<AlertSuccess message="Information Saved" />);
          this.props.history.goBack();
        } else {
          toast(<AlertError message={contestResponse.data && contestResponse.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };

  onTypeChange = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    data.contestType = Number(e.target.name);
    this.setState({ data, errors });
  };

  schema = {
    title: Joi.string().max(500).required(),
    nftContractAddress: Joi.string().max(500).allow(""),
    nftNetwork: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.string().allow(""),
    }),
    image: Joi.object().required(),
    bgGame: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
    }),
    contestTemplate: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.alternatives().try(Joi.string(), Joi.number()).allow([""]),
    }),
    rounds: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.number(),
      value: Joi.number(),
    }),
    startDate: Joi.date().required(),
    endDate: Joi.date().required(),
    contestType: Joi.number().required(),
    isPrivate: Joi.boolean().allow(false),
    //  Arcade
    arcadeDescription: Joi.when("contestType", {
      is: Joi.valid(1, 2),
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    arcadeTitle: Joi.when("contestType", {
      is: Joi.valid(1, 2),
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    entryFeeArcadeCoins: Joi.when("contestType", {
      is: Joi.valid(1, 2),
      then: Joi.number().min(0).required(),
      otherwise: Joi.number().min(0).allow(0),
    }),
    xPToBeAwarded: Joi.when("contestType", {
      is: Joi.valid(1, 2),
      then: Joi.number().min(0).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),

    //  Loot
    lootTitle: Joi.when("contestType", {
      is: Joi.valid(1, 3),
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    entryFeeLootBonusCoins: Joi.when("contestType", {
      is: Joi.valid(1, 3),
      then: Joi.number().min(0).required(),
      otherwise: Joi.number().min(0).allow(0),
    }),
    lootDescription: Joi.when("contestType", {
      is: Joi.valid(1, 3),
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    ticketsToBeAwarded: Joi.when("contestType", {
      is: Joi.valid(1, 3),
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
  };

  getEndDate = () => {
    var startDate = new Date(this.state.data.startDate);
    var endDate = "";
    if (startDate) endDate = new Date(startDate.getTime());
    else endDate = new Date();
    return endDate;
  };
  togglePrivateContest = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    data[e.target.name] = !data[e.target.name];
    delete errors[e.target.name];
    this.setState({ data, errors });
  };
  render() {
    const { bgGames } = this.props.getGame;
    const { contestTemplate } = this.props.getSettings;
    const contestState = this.props.location.state && this.props.location.state.contest;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="contests" />

          {!this.state.loading && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1>{this.props.location.state ? "Edit" : "Add a"} Contest</h1>
              </div>
              <div className="fwc-wrapper">
                <fieldset className="row fieldset">
                  <div className="col-6">
                    <div className="d-flex align-items-center justify-content-between">
                      <label className="form-label">Contest Template</label>
                    </div>
                    <SelectSearch
                      name="contestTemplate"
                      options={contestTemplate?.map((template) => ({
                        name: template.name,
                        value: template.id,
                        data: template,
                      }))}
                      label="Select Contest Template"
                      value={this.state.data.contestTemplate}
                      error={this.state.errors.contestTemplate}
                      onChange={this.onChangeSelect}
                      isLoading={this.state.loadingTemplate}
                      isDisabled={this.state.edit || contestState}
                    />
                  </div>
                  <div className="col-6">
                    <div className="fml-box">{this.renderInput("title", "Title")}</div>
                  </div>
                </fieldset>
                <div className="new-form-group form-check-outer">
                  <div className="row">
                    <div className="col-4">
                      <label className="form-label">Contest Type</label>
                      <div className="form-check-group full-width-row d-flex align-items-center flex-wrap">
                        <div className="form-check-item">
                          <div className="form-check has-circle-radio">
                            <input
                              className={`form-check-input ${this.state.errors.type && "border-error"}`}
                              type="radio"
                              name={1}
                              id="radioCheckOne"
                              checked={this.state.data.contestType === 1}
                              onChange={this.onTypeChange}
                              disabled={this.state.loadingSlide || this.state.edit}
                            />
                            <label className="form-check-label" htmlFor="radioCheckOne">
                              Both
                            </label>
                          </div>
                        </div>
                        <div className="form-check-item ms-0">
                          <div className="form-check has-circle-radio">
                            <input
                              className={`form-check-input ${this.state.errors.type && "border-error"}`}
                              type="radio"
                              name={2}
                              id="radioCheckTwo"
                              checked={this.state.data.contestType === 2}
                              onChange={this.onTypeChange}
                              disabled={this.state.loadingSlide || this.state.edit}
                            />
                            <label className="form-check-label" htmlFor="radioCheckTwo">
                              Arcade
                            </label>
                          </div>
                        </div>
                        <div className="form-check-item ms-0">
                          <div className="form-check has-circle-radio">
                            <input
                              className={`form-check-input ${this.state.errors.type && "border-error"}`}
                              type="radio"
                              name={3}
                              id="radioCheckThree"
                              checked={this.state.data.contestType === 3}
                              onChange={this.onTypeChange}
                              disabled={this.state.loadingSlide || this.state.edit}
                            />
                            <label className="form-check-label" htmlFor="radioCheckThree">
                              Loot
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-2">
                      <label className="form-label">Contest Mode</label>
                      <div className="form-check-group full-width-row d-flex align-items-center flex-wrap">
                        <div className="form-check-item">
                          <div className="form-check has-circle-radio">
                            <input
                              className={`form-check-input ${this.state.errors.isPrivate && "border-error"}`}
                              type="checkbox"
                              name="isPrivate"
                              id="radioCheckOneFlyer"
                              checked={this.state.data.isPrivate}
                              onChange={this.togglePrivateContest}
                              disabled={this.state.loadingSlide || this.props.edit}
                            />
                            <label className="form-check-label" htmlFor="radioCheckOneFlyer">
                              Private
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <fieldset className="row fieldset mb-5">
                  <div className="fml-box col-3">
                    {this.renderDateInput(
                      "startDate",
                      "Start Date & Time",
                      new Date(),
                      contestState && contestState.status !== "Upcoming",
                      true,
                    )}
                  </div>
                  <div className="fml-box col-3">
                    {this.renderDateInput(
                      "endDate",
                      "End Date & Time",
                      this.getEndDate(),
                      contestState && contestState.status !== "Upcoming",
                      true,
                    )}
                  </div>
                  <div className={`${this.state.data.bgGame?.data?.scoringType === 1 ? "col-3" : "col-6"}`}>
                    <div className="d-flex align-items-center justify-content-between">
                      <label className="form-label">BG Game</label>
                    </div>
                    <SelectSearch
                      name="bgGame"
                      options={_.sortBy(bgGames, ["name"]).map((game) => ({
                        name: game.name,
                        value: game.id,
                        data: game,
                      }))}
                      label="Select BG Game"
                      value={this.state.data.bgGame}
                      error={this.state.errors.bgGame}
                      onChange={this.onChangeSelect}
                      isLoading={this.state.bgGamesLoading}
                      isDisabled={contestState ? contestState?.status !== "Upcoming" : false}
                    />
                  </div>
                  {this.state.data.bgGame?.data?.scoringType === 1 && (
                    <div className="col-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <label className="form-label">Rounds</label>
                      </div>
                      <SelectSearch
                        name="rounds"
                        options={[1, 2, 3, 4, 5].map((rounds) => ({
                          name: rounds,
                          value: rounds,
                          data: null,
                        }))}
                        isDisabled={contestState ? contestState?.status !== "Upcoming" : false}
                        label="Select Rounds"
                        value={this.state.data.rounds}
                        error={this.state.errors.rounds}
                        onChange={this.onChangeSelect}
                      />
                    </div>
                  )}
                </fieldset>

                <fieldset className="row fieldset">
                  <div className="col-6">
                    <div className="fml-box">
                      {this.renderInput("nftContractAddress", "NFT Contract Address", "0x...")}
                    </div>
                  </div>
                  <div className={`${this.state.data.bgGame?.data?.scoringType === 1 ? "col-3" : "col-6"}`}>
                    <div className="d-flex align-items-center justify-content-between">
                      <label className="form-label">NFT Contract Network</label>
                    </div>
                    <SelectSearch
                      name="nftNetwork"
                      options={[
                        { name: "ETH MAINNET", value: "ETH_MAINNET" },
                        { name: "POLYGON MAINNET", value: "MATIC_MAINNET" },
                      ]}
                      label="NFT Contract Network"
                      value={this.state.data.nftContractAddress ? this.state.data.nftNetwork : {}}
                      error={this.state.errors.nftNetwork}
                      onChange={this.onChangeSelect}
                      isLoading={this.state.bgGamesLoading}
                      isDisabled={!this.state.data.nftContractAddress}
                    />
                  </div>
                </fieldset>

                {/* Arcade */}
                {this.state.data.contestType !== 3 && (
                  <fieldset>
                    <div className="fwc-inner">
                      <h1 className="ict-header">Arcade Contest</h1>
                      <fieldset className="row fieldset">
                        <div className="col-6">
                          <div className="fml-box">{this.renderInput("arcadeTitle", "Arcade Title")}</div>
                        </div>
                        <div className="col-3">
                          <div className="fml-box">
                            {this.renderInput(
                              "entryFeeArcadeCoins",
                              "Entry Fee (Arcade Coins)",
                              "",
                              "number",
                              contestState && contestState.status !== "Upcoming",
                            )}
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="fml-box">
                            {this.renderInput("xPToBeAwarded", "XP to be awarded", "", "number")}
                          </div>
                        </div>
                      </fieldset>

                      <fieldset className="row fieldset">
                        <div className="col-12">
                          <div className="fml-box">
                            {this.renderTextarea("arcadeDescription", "Arcade Description", "", "")}
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </fieldset>
                )}

                {/* Loot */}
                {this.state.data.contestType !== 2 && (
                  <fieldset>
                    <div className="fwc-inner">
                      <h1 className="ict-header">Loot Contest</h1>
                      <fieldset className="row fieldset">
                        <div className="col-6">
                          <div className="fml-box">{this.renderInput("lootTitle", "Loot Title")}</div>
                        </div>
                        <div className="col-3">
                          <div className="fml-box">
                            {this.renderInput(
                              "entryFeeLootBonusCoins",
                              "Entry Fee (Loot Bucks)",
                              "",
                              "number",
                              contestState && contestState.status !== "Upcoming",
                            )}
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="fml-box">
                            {this.renderInput("ticketsToBeAwarded", "Tickets to be awarded", "", "number")}
                          </div>
                        </div>
                      </fieldset>
                      <fieldset className="row fieldset">
                        <div className="col-12">
                          <div className="fml-box">
                            {this.renderTextarea("lootDescription", "Loot Description", "", "")}
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </fieldset>
                )}

                <label htmlFor="" className="form-label">
                  Contest Image
                </label>
                <div className="kt-upload-row d-flex flex-wrap align-items-start">
                  <div
                    className={`kt-upload-outer ${
                      !this.state.data.image ? "placeholder-uploaded-img" : " position-relative"
                    } ${this.state.errors.image && "border-error"}`}
                  >
                    <div className="kt-upload-img">
                      <img
                        src={
                          this.state.data.image
                            ? this.state.data.image.medium
                              ? this.state.data.image.medium.includes("https://")
                                ? this.state.data.image.medium
                                : process.env.REACT_APP_CDN + this.state.data.image.medium
                              : process.env.REACT_APP_CDN + this.state.data.image
                            : plusIcon
                        }
                        alt=""
                      />
                    </div>{" "}
                    {this.state.data.image && (
                      <div
                        className="delete-icon d-flex align-items-center justify-content-center"
                        onClick={(e) => {
                          const data = this.state.data;
                          data.image = null;
                          this.setState({ data });
                        }}
                      >
                        <img src={deleteIcon} />
                      </div>
                    )}
                  </div>
                  <div className="kt-file-input">
                    <label htmlFor="formFile" className="kt-label">
                      {this.state.data.image ? " Change Image" : "+ Upload an Image"}
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      id="formFile"
                      onChange={(e) => {
                        this.handleImageChange(e, "image");
                      }}
                    />
                  </div>
                </div>

                <div className="new-form-group new-form-btn  text-end">
                  <NextButton
                    handleSubmit={this.handleSubmit}
                    label="Save"
                    loading={this.state.loadingContest || this.state.loadingImages}
                    classData={"btn btn-default"}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  addContest: (data, callback) => dispatch(addContest(data, callback)),
  loadSettings: (callback) => dispatch(loadSettings(callback)),
  loadContestTemplate: (params, callback) => dispatch(loadContestTemplate(params, callback)),
  loadBGGames: (params, callback) => dispatch(loadBGGames(params, callback)),
  updateContest: (data, id, callback) => dispatch(updateContest(data, id, callback)),
});
const mapStateToProps = (state) => ({
  getContest: getContest(state),
  getSettings: getSettings(state),
  getGame: getGame(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddContest));
