import moment from "moment";
import { Component, createRef } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import plusIcon from "include/images/plus.svg";

//  Components
import AlertError from "common/alert/alertError";
import DeleteModal from "common/alert/alertModal";
import AlertSuccess from "common/alert/alertSuccess";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import { deleteBoardGameTournament, getBoardGame, loadBoardGameTournament } from "store/boardGame";
import AddCard from "./addTournament";

class TournamentListing extends Component {
  ref = createRef(null);
  state = {
    loadingMain: false,
    loadingDelete: false,
    showDeleteModal: false,
    showAddModal: false,
    deleteId: "",
    editData: null,
    page: 1,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getBoardGame.loading && this.props.getBoardGame.loading !== prevProps.getBoardGame.loading)
      this.ref.current.complete();

    if (this.props.getBoardGame.loading && this.props.getBoardGame.loading !== prevProps.getBoardGame.loading)
      this.ref.current.continuousStart();
    if (this.state.showAddModal !== prevState.showAddModal && !this.state.showAddModal) {
      setTimeout(() => {
        this.setState({ editData: null });
      }, 500);
    }
    const params = new URLSearchParams(this.props.location.search);
    const prevParams = new URLSearchParams(prevProps.location.search);
    const status = params.get("status");
    const prevStatus = prevParams.get("status");
    if (status !== prevStatus) {
      this.setState(
        {
          loadingMain: true,
          loadingDelete: false,
          showDeleteModal: false,
          showAddModal: false,
          deleteId: "",
          editData: null,
          page: 1,
        },
        () => {
          this.searchFilters();
        },
      );
    }
  };
  componentDidMount = () => {
    this.setState({ loadingMain: true });
    const searchParams = new URLSearchParams(this.props.location.search);
    const status = searchParams.get("status");
    this.props.loadBoardGameTournament({ status }, (res) => {
      this.setState({
        loadingMain: false,
      });
    });
  };

  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };

  handleDelete = (e) => {
    this.setState({ loadingDelete: true });
    this.props.deleteBoardGameTournament(this.state.deleteId, (res) => {
      if (res?.status === 200) {
        toast(<AlertSuccess message="Deleted!" />, {
          containerId: 1,
        });
        this.searchFilters();
      } else {
        toast(<AlertError message={res.data && res.data.message} />, {
          containerId: 1,
        });
      }
      this.alertModalToggle();
      this.setState({ loadingDelete: false });
    });
  };
  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  searchFilters = () => {
    this.setState({ loadingMain: true });
    const searchParams = new URLSearchParams(this.props.location.search);
    const status = searchParams.get("status");
    this.props.loadBoardGameTournament({ page: this.state.page, status }, () => this.setState({ loadingMain: false }));
  };
  render() {
    const { boardGameTournament, totalCount } = this.props.getBoardGame;
    const searchParams = new URLSearchParams(this.props.location.search);
    const status = searchParams.get("status");

    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="boardGameTournaments" />

          {!this.state.loadingMain && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1>Tournaments {totalCount > 0 && `(${totalCount})`}</h1>
                <div className="filter-menu-box d-flex align-items-center ms-auto">
                  <div className="fmb-box">
                    <Link to="#" onClick={this.toggleAddModal} className="btn btn-default btn-sm">
                      <img src={plusIcon} alt="" /> Add a Tournament
                    </Link>
                  </div>
                </div>
              </div>
              <div className="fwc-wrapper">
                <div className="fwc-body">
                  {boardGameTournament?.length === 0 ? (
                    <div className="fwc-body">
                      <div className="no-record-found-container">
                        <div className="nfr-box">
                          <img src={emptyIcon} alt="No Record Found" />
                          <div className="nrf-text">
                            <h5>No Records Found!</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table large-table">
                        <thead>
                          <tr>
                            {status === "pending" && <th style={{ width: 75, textAlign: "center" }}>Actions</th>}
                            <th>Name</th>
                            <th>Bs</th>
                            <th>Free Spin</th>
                            <th>Board Configuration Image</th>
                            <th>Icon Map Image</th>
                            <th>Status</th>
                            <th style={{ width: "15%" }}>Start Date</th>
                            <th style={{ width: "15%" }}>End Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {boardGameTournament?.map((tournament, index) => (
                            <tr key={index}>
                              {status === "pending" && (
                                <td>
                                  <Dropdown className=" custom-dropdown">
                                    <Dropdown.Toggle
                                      id="tdDropdownOne"
                                      className="custom-dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <img src={carrentIcon} alt="" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                      <Dropdown.Item
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState({ editData: tournament }, () => {
                                            this.toggleAddModal();
                                          });
                                        }}
                                      >
                                        Edit
                                      </Dropdown.Item>{" "}
                                      <Dropdown.Item
                                        className="dropdown-item delete"
                                        onClick={(e) => {
                                          this.setState({ deleteId: tournament?._id }, () => {
                                            this.alertModalToggle(e);
                                          });
                                        }}
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              )}

                              <td>
                                <Link
                                  className="two-line-ellipsis"
                                  to={"#"}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ editData: tournament }, () => {
                                      this.toggleAddModal();
                                    });
                                  }}
                                >
                                  {tournament?.name}
                                </Link>
                              </td>
                              <td>{tournament?.bs}</td>
                              <td>{tournament?.freeSpin}</td>

                              <td>
                                <div style={{ width: 80 }}>
                                  {tournament?.image && (
                                    <img src={process.env.REACT_APP_CDN + tournament?.image} alt="" />
                                  )}
                                </div>
                              </td>
                              <td>
                                <div style={{ width: 80 }}>
                                  {tournament?.iconMapImage && (
                                    <img src={process.env.REACT_APP_CDN + tournament?.iconMapImage} alt="" />
                                  )}
                                </div>
                              </td>
                              <td className="text-capitalize">{tournament?.status}</td>
                              <td>{moment(tournament?.startedAt).format("MM/DD/YYYY")}</td>
                              <td>{moment(tournament?.endedAt).format("MM/DD/YYYY")}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {!this.state.loadingMain && !(this.state.page === 1 && boardGameTournament?.length === 0) && (
                    <div className="page-pagination">
                      <ul className="pagination  justify-content-end">
                        <li className="page-item">
                          <a
                            class={`page-link ${this.state.page === 1 && "disabled"}`}
                            href="#!"
                            aria-label="Previous"
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.state.page > 1) {
                                this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                              }
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                        <li className="page-item active">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {this.state.page}
                          </a>
                        </li>
                        {boardGameTournament?.length >= 50 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                              }}
                            >
                              {this.state.page + 1}
                            </a>
                          </li>
                        )}
                        <li className="page-item">
                          <a
                            class={`page-link ${boardGameTournament?.length < 50 && "disabled"}`}
                            href="#!"
                            aria-label="Next"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <AddCard
          showModal={this.state.showAddModal}
          toggleModal={this.toggleAddModal}
          loadData={() => {
            const searchParams = new URLSearchParams(this.props.location.search);
            const status = searchParams.get("status");
            this.props.loadBoardGameTournament({ status });
          }}
          edit={this.state.editData}
        />
        <DeleteModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showDeleteModal}
          title="Alert"
          description="Are you sure you want to delete this tournament?"
          onPress={this.handleDelete}
          loading={this.state.loadingDelete}
        />

        {this.state.showAddModal && <div className="modal-backdrop show fade" onClick={this.toggleAddModal}></div>}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadBoardGameTournament: (params, callback) => dispatch(loadBoardGameTournament(params, callback)),
  deleteBoardGameTournament: (id, callback) => dispatch(deleteBoardGameTournament(id, callback)),
});
const mapStateToProps = (state) => ({
  getBoardGame: getBoardGame(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TournamentListing));
