import React from "react";
import { Offcanvas } from "react-bootstrap";
import closeIcon from "include/images/dark-close-icon.svg";
import emptyIcon from "include/images/nrf.svg";

const LinkedAccounts = ({ showModal, toggleModal, user }) => {
  return (
    <Offcanvas
      className={"offcanvas offcanvas-end custom-offcanvas-pannel"}
      show={showModal}
      onHide={toggleModal}
      id="rewardPannel"
      placement="right"
      name="rewardPannel"
      backdrop={true}
    >
      <Offcanvas.Header className="offCanvas-header-2">
        <div>
          <h5>User's Multiple Accounts</h5>{" "}
          <div className="td-img-group d-flex align-items-start m-top-2">
            {user?.accountId}
            <br />
            {user?.email}
          </div>
        </div>
        <div className="btn_close pointer" data-bs-dismiss="offcanvas" aria-label="Close" onClick={toggleModal}>
          <img src={closeIcon} alt="" />
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="">
          <div className="fwc-body">
            <div className="table-responsive">
              {user?.linkedAccounts?.length === 0 ? (
                <div className="fwc-body">
                  <div className="no-record-found-container">
                    <div className="nfr-box">
                      <img src={emptyIcon} alt="No Record Found" />
                      <div className="nrf-text">
                        <h5>No Records Found!</h5>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <table className="table large-table">
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Match Type</th>
                      <th>Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user?.linkedAccounts?.map((account, i) => (
                      <tr>
                        <td>{account?.accountId}</td>
                        <td>{account?.matchType?.join(", ")}</td>
                        <td>{account?.score}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default LinkedAccounts;
