import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "emails",
  initialState: {
    emailLogs: [],
    totalCount: null,
    filteredCount: null,
    loading: false,
  },
  reducers: {
    emailsRequested: (misc, action) => {
      misc.loading = true;
    },
    emailsReceived: (misc, action) => {
      misc.emailLogs = action.payload.data;
      misc.filteredCount = action.payload.filteredCount;
      misc.totalCount = action.payload.totalCount;
      misc.loading = false;
    },
    emailsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const { emailsRequested, emailsReceived, emailsRequestFailed } = slice.actions;
export default slice.reducer;

// Action Creators
export const loadEmailLogs = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "emailLog",
      params,
      onStart: emailsRequested.type,
      onSuccess: emailsReceived.type,
      onError: emailsRequestFailed.type,
      callback,
    }),
  );
};
export const getEmails = createSelector(
  (state) => state.entities.emails,
  (emails) => emails,
);
