import NextButton from "common/form/submitButton";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import filterIcon from "include/images/filter-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getDashboard, loadVerisoulMultipleAccountsDashboard } from "store/dashboard";
import LinkedAccounts from "../flyers/linkedAccounts";
const VerisoulLinkedAccounts = (props) => {
  const ref = useRef();

  const [loadingMain, setLoadingMain] = useState(true);
  const [page, setPage] = useState(1);
  const [user, setUser] = useState(null);

  const [showMultipleAccounts, setShowMultipleAccounts] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [keyword, setKeyword] = useState("");
  useEffect(() => {
    searchFilters();
  }, []);
  const { verisoulMultipleAccounts, filteredCount, totalCount } = props.getDashboard;

  useEffect(() => {
    if (!loadingMain) ref?.current?.complete();
    else ref?.current?.continuousStart();
  }, [loadingMain]);

  const toggleShowMultipleAccounts = (e, user = null) => {
    if (e) e.preventDefault();
    setUser(user);
    setShowMultipleAccounts(!showMultipleAccounts);
  };
  const searchFilters = (e, searchKeyword = "", searchPage = 1) => {
    if (e) e.preventDefault();
    setLoadingFilters(true);
    if (searchKeyword !== keyword) setKeyword(searchKeyword);
    if (searchPage !== page) setPage(searchPage);
    props.loadVerisoulMultipleAccountsDashboard({ keyword: searchKeyword, page: searchPage }, () => {
      setLoadingFilters(false);
      setLoadingMain(false);
    });
  };

  return (
    <>
      <Header />
      <Loader loaderRef={ref} />
      <div className="page-wrapper add-dev-server-wrapper">
        <SideBar page="dashboard" />
        <div className="main-content-wrapper position-relative">
          <div className="mcw-header d-flex align-items-center">
            <h1>Users {totalCount > 0 && `(${totalCount})`}</h1>
            <div className="filter-menu-box d-flex align-items-center ms-auto">
              <div className="fmb-box">
                <a
                  href="#!"
                  className="filter-link-box"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowFilters(!showFilters);
                  }}
                >
                  <img src={filterIcon} /> <span>Filters</span>
                </a>
              </div>
            </div>
          </div>
          <div className="fwc-wrapper">
            {showFilters && (
              <div className="fwc-head ">
                <div className="fwc-inner">
                  <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                    <li className="flex-fill width-100">
                      <div className="fml-box">
                        <div className={"form-group"}>
                          <label htmlFor={"keyword"} className="form-label">
                            Keyword
                          </label>

                          <input
                            name={"keyword"}
                            id={"keyword"}
                            className={"form-control"}
                            placeholder="Search via keywords"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                        </div>
                      </div>
                    </li>{" "}
                  </ul>

                  <div className="fwc-btn d-flex align-items-center justify-content-end">
                    <NextButton handleSubmit={searchFilters} classData="btn-text pointer" label="Reset" />
                    <NextButton
                      handleSubmit={(e) => searchFilters(e, keyword, 1)}
                      classData="btn btn-primary btn-sm"
                      label="Search"
                      loading={loadingFilters}
                    />
                  </div>
                </div>{" "}
              </div>
            )}
            <div className="fwc-body">
              {!loadingMain && (
                <div className="table-responsive">
                  {filteredCount === 0 ? (
                    <div className="fwc-body">
                      <div className="no-record-found-container">
                        <div className="nfr-box">
                          <img src={emptyIcon} alt="No Record Found" />
                          <div className="nrf-text">
                            <h5>No Records Found!</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <table className="table large-table">
                      <thead>
                        <tr>
                          <th>Username</th>
                          <th>Email</th>
                          <th width={300}>Linked Accounts</th>
                        </tr>
                      </thead>
                      <tbody>
                        {verisoulMultipleAccounts?.map((user, index) => (
                          <tr key={index}>
                            <td>{user?.accountId}</td>
                            <td>{user?.email}</td>
                            <td className="ust-tag">
                              <Dropdown className=" custom-dropdown my-2">
                                <Dropdown.Toggle
                                  id="tdDropdownOne"
                                  className={`custom-dropdown-toggle `}
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  disabled={user?.linkedAccounts?.length === 0}
                                  onClick={(e) => {
                                    if (user?.linkedAccounts?.length > 0) {
                                      toggleShowMultipleAccounts(e, user);
                                    }
                                  }}
                                >
                                  <span
                                    className={`w-100 text-center d-block tickets ${
                                      user?.linkedAccounts?.length > 0 && "suspended"
                                    } `}
                                  >
                                    {user?.linkedAccounts?.length > 0 ? "Yes" : "No"}
                                  </span>
                                </Dropdown.Toggle>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
              {!(page === 1 && filteredCount === 0) && (
                <div className="page-pagination">
                  <ul className="pagination  justify-content-end">
                    <li className="page-item">
                      <a
                        class={`page-link ${page === 1 && "disabled"}`}
                        href="#!"
                        aria-label="Previous"
                        onClick={(e) => {
                          e.preventDefault();
                          if (page > 1) {
                            searchFilters(e, keyword, page - 1);
                          }
                        }}
                      >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                    </li>
                    <li className="page-item active">
                      <a
                        className="page-link"
                        href="#!"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        {page}
                      </a>
                    </li>
                    {filteredCount >= 50 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                            searchFilters(e, keyword, page + 1);
                          }}
                        >
                          {page + 1}
                        </a>
                      </li>
                    )}
                    <li className="page-item">
                      <a
                        class={`page-link ${filteredCount < 50 && "disabled"}`}
                        href="#!"
                        aria-label="Next"
                        onClick={(e) => {
                          e.preventDefault();
                          searchFilters(e, keyword, page + 1);
                        }}
                      >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <LinkedAccounts showModal={showMultipleAccounts} toggleModal={toggleShowMultipleAccounts} user={user} />
      {showMultipleAccounts && <div className="modal-backdrop show fade" onClick={toggleShowMultipleAccounts}></div>}
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  loadVerisoulMultipleAccountsDashboard: (params, callback) =>
    dispatch(loadVerisoulMultipleAccountsDashboard(params, callback)),
});
const mapStateToProps = (state) => ({
  getDashboard: getDashboard(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerisoulLinkedAccounts));
