import { createSlice, current } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "contest",
  initialState: {
    contests: [],
    invalidContests: [],
    contestTemplate: [],
    totalCount: null,
    filteredCount: null,
    contestInfo: null,
    contestGames: [],
    assignedQuestions: [],
  },
  reducers: {
    contestsRequested: (misc, action) => {
      misc.loading = true;
    },
    contestsReceived: (misc, action) => {
      misc.contests = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    moreContestsReceived: (misc, action) => {
      misc.contests = [...misc.contests, ...action.payload.data];
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    assignedQuestionsReceived: (misc, action) => {
      misc.assignedQuestions = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },

    invalidContestsReceived: (misc, action) => {
      misc.invalidContests = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    deleteContestsReceived: (misc, action) => {
      const ind = misc.contests.findIndex((quest) => quest.id === action.payload.id);
      const newContests = misc.contests;
      newContests.splice(ind, 1);
      misc.totalCount = misc.totalCount - 1;
      misc.contests = [...newContests];
    },
    deleteContestTemplateReceived: (misc, action) => {
      const ind = misc.contestTemplate.findIndex((quest) => quest.id === action.payload.id);
      const newContests = misc.contestTemplate;
      newContests.splice(ind, 1);
      misc.totalCount = misc.totalCount - 1;
      misc.contestTemplate = [...newContests];
    },
    markWinnerAsFeaturedReceived: (misc, action) => {
      const payLoad = action.payload;
      misc.contests = misc.contests.map((contest, index) => {
        const newContest = { ...contest };
        if (payLoad.id === newContest.id) {
          newContest.winner = {
            ...newContest.winner,
            isFeatured: payLoad.isFeatured,
          };
        }
        return newContest;
      });
    },
    clearContestInfo: (misc, action) => {
      misc.contestInfo = null;
    },
    markAsFeaturedReceived: (misc, action) => {
      const payLoad = action.payload;
      misc.contests = misc.contests.map((contest, index) => {
        const newContest = { ...contest };
        if (payLoad.id === newContest.id) {
          newContest.isFeatured = payLoad.isFeatured;
        }
        return newContest;
      });
    },
    markAsFeaturedPendingContestsReceived: (misc, action) => {
      const payLoad = action.payload;
      misc.contests = misc.contests.map((contest, index) => {
        const newContest = { ...contest };
        if (payLoad.id === newContest.id) {
          newContest.featuredUnderWinnerAndPendingContests = payLoad.isFeatured;
        }
        return newContest;
      });
    },
    markAsFeaturedDetailsReceived: (misc, action) => {
      const payLoad = action.payload;
      const newContest = { ...misc.contestInfo };
      if (payLoad.id === newContest.id) {
        newContest.isFeatured = payLoad.isFeatured;
      }
      misc.contestInfo = newContest;
    },
    cancelContestReceived: (misc, action) => {
      const payLoad = action.payload;

      misc.contests = misc.contests.map((contest, index) => {
        const newContest = { ...contest };
        if (payLoad.id === newContest.id) {
          newContest.status = "Cancelled";
        }
        return newContest;
      });
    },
    cancelContestDetailsReceived: (misc, action) => {
      const payLoad = action.payload;
      const newContest = { ...misc.contestInfo };
      if (payLoad.id === newContest.id) {
        newContest.status = "Cancelled";
      }
      misc.contestInfo = newContest;
    },
    contestGamesReceived: (misc, action) => {
      misc.contestGames = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    awardTicketsStatusChangeReceived: (misc, action) => {
      const payLoad = action.payload;
      const newContests = misc.contests;
      for (let i = 0; i < newContests.length; i++) {
        const newContest = { ...newContests[i] };
        if (newContest.id === payLoad.id) {
          newContest.isTicketAwardingStarted = true;
          newContests[i] = newContest;
        }
      }
      misc.contests = newContests;
    },
    awardArcadeStatusChangeReceived: (misc, action) => {
      const payLoad = action.payload;
      const newContests = misc.contests;
      for (let i = 0; i < newContests.length; i++) {
        const newContest = { ...newContests[i] };
        if (newContest.id === payLoad.id) {
          newContest.isArcadeCoinAwardingStarted = true;
          newContests[i] = newContest;
        }
      }
      misc.contests = newContests;
    },
    awardXpStatusChangeReceived: (misc, action) => {
      const payLoad = action.payload;
      const newContests = misc.contests;
      for (let i = 0; i < newContests.length; i++) {
        const newContest = { ...newContests[i] };
        if (newContest.id === payLoad.id) {
          newContest.isXPAwardingStarted = true;
          newContests[i] = newContest;
        }
      }
      misc.contests = newContests;
    },
    contestsRequestFailed: (misc, action) => {
      misc.loading = false;
    },

    contestInfoRequested: (misc, action) => {
      misc.loading = true;
    },
    contestInfoReceived: (misc, action) => {
      misc.contestInfo = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    contestInfoRequestFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  contestsRequested,
  contestsReceived,
  moreContestsReceived,
  contestsRequestFailed,

  contestInfoRequested,
  contestInfoReceived,
  contestInfoRequestFailed,
  contestGamesReceived,
  deleteContestsReceived,
  markAsFeaturedReceived,
  markAsFeaturedDetailsReceived,
  cancelContestReceived,
  cancelContestDetailsReceived,
  markWinnerAsFeaturedReceived,
  deleteContestTemplateReceived,
  invalidContestsReceived,
  assignedQuestionsReceived,
  markAsFeaturedPendingContestsReceived,
  awardTicketsStatusChangeReceived,
  awardArcadeStatusChangeReceived,
  awardXpStatusChangeReceived,
  clearContestInfo,
} = slice.actions;
export default slice.reducer;

// Action Creators
const contestUrl = "contest/";

export const loadContests =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: contestUrl,
        params,
        onStart: contestsRequested.type,
        onSuccess: fresh ? contestsReceived.type : moreContestsReceived.type,
        onError: contestsRequestFailed.type,
        callback,
      }),
    );
  };
export const loadInvalidContests = (status, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + "invalid",
      params,
      onStart: contestsRequested.type,
      onSuccess: invalidContestsReceived.type,
      onError: contestsRequestFailed.type,
      callback,
    }),
  );
};
export const loadContestGames = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + "games/" + id,
      onStart: contestsRequested.type,
      onSuccess: contestGamesReceived.type,
      onError: contestsRequestFailed.type,
      params,
      callback,
    }),
  );
};
export const loadContestWinners =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: contestUrl + "winners",
        params,
        onStart: contestsRequested.type,
        onSuccess: fresh ? contestsReceived.type : moreContestsReceived.type,
        onError: contestsRequestFailed.type,
        callback,
      }),
    );
  };
export const awardTickets = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + "awardTickets/" + id,
      method: "POST",
      callback,
    }),
  );
};
export const awardArcadeCoins = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + "awardArcadeCoins/" + id,
      method: "POST",
      callback,
    }),
  );
};
export const awardXpPoints = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + "awardXP/" + id,
      method: "POST",
      callback,
    }),
  );
};
export const loadContestInfo = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + id,
      params,
      onStart: contestInfoRequested.type,
      onSuccess: contestInfoReceived.type,
      onError: contestInfoRequestFailed.type,
      callback,
    }),
  );
};
export const loadAssignedQuestions = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + id + "/assignedQuestions",
      onStart: contestInfoRequested.type,
      onSuccess: assignedQuestionsReceived.type,
      onError: contestInfoRequestFailed.type,
      callback,
    }),
  );
};
export const addContest = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const fixAssignedQuestion = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + id + "/fixAssignedQuestions",
      method: "POST",
      onSuccess: assignedQuestionsReceived.type,
      callback,
    }),
  );
};
export const updateContest = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const markAsFeaturedContest = (id, featured, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + "featured/" + id + "/" + featured,
      method: "PUT",
      callback,
    }),
  );
};
export const markAsFeaturedPendingContest = (id, featured, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + "featuredPendingContest/" + id + "/" + featured,
      method: "PUT",
      callback,
    }),
  );
};
export const uploadImage = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + "uploadImage/",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const deleteContest = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const cancelContest = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + "cancel/" + id,
      method: "PUT",
      callback,
    }),
  );
};
export const scheduleContests = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + "SaveBulkContests",
      callback,
    }),
  );
};
export const getContest = createSelector(
  (state) => state.entities.contest,
  (contest) => contest,
);
