import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Password from "./password";
import DateInput from "./dateInput";
import DateOnlyInput from "./dateOnlyInput";
import DateInputEST from "./dateInputEST";
import DateRangeInput from "./dateRangeInput";
import Textarea from "./textarea";
import TextareaEditor from "./textareaEditor";
import { formatDate } from "utils/dateConversion";
import moment from "moment";

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateCustomData = (data) => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema, { abortEarly: false });
    return error ? error.details[0].message : null;
  };

  setErrors = (input) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) {
      errors[input.name] = errorMessage;
    } else {
      delete errors[input.name];
    }
    if (input.name === "confirmPassword") {
      if (this.state.data.password === input.value) {
        delete errors[input.name];
      }
    }
    this.setState({ errors });
  };

  setData = (input) => {
    if (input.name === "startDate") {
      const { data } = this.state;
      data.endDate = null;
      this.setState({ data });
    }
    if (input.name === "eth") {
      var t = input.value;
      input.value = t.indexOf(".") >= 0 ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 8) : t;
    }
    if (input.type === "number" && input.value < 0) return;
    if (input.name) {
      const data = { ...this.state.data };
      data[input.name] = input.value;
      this.setState({ data });
    }
  };

  handleSubmit = (e) => {
    if (e) e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };

  handleChangeSelect = ({ currentTarget: input }) => {
    this.setErrors(input);
    this.setData(input);
  };

  handleChange = ({ currentTarget: input }) => {
    this.setData(input);
  };

  handleBlur = ({ currentTarget: input }) => {
    this.setErrors(input);
  };

  renderInput(name, label, placeholder, type = "text", disabled, readOnly = false, toolData, maxLength, minLength) {
    const { data, errors } = this.state;
    return (
      <Input
        type={type ? type : "text"}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
        readOnly={readOnly}
        placeholder={placeholder}
        toolData={toolData}
        maxLength={maxLength}
        minLength={minLength}
        disabled={disabled}
      />
    );
  }
  renderTextarea(name, label, type = "text", disabled, placeholder, maxLength, minLength) {
    const { data, errors } = this.state;

    return (
      <Textarea
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
        placeholder={placeholder}
        maxLength={maxLength}
        minLength={minLength}
        disabled={disabled}
      />
    );
  }
  renderTextareaEditor(name, label, type = "text", disabled, placeholder) {
    const { data, errors } = this.state;

    return (
      <TextareaEditor
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
        placeholder={placeholder}
        disabled={disabled}
      />
    );
  }

  renderPasswordInput(name, label, type = "password") {
    const { data, errors } = this.state;
    return (
      <Password
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
      />
    );
  }

  renderDateInput(name, label, minDate, disabled, subText) {
    const { data, errors } = this.state;
    return (
      <DateInput
        name={name}
        minDate={minDate}
        disabled={disabled}
        onChange={(date) => {
          this.handleChangeSelect({
            currentTarget: { value: date, name },
          });
        }}
        error={errors[name]}
        value={data[name]}
        label={label}
        subText={subText && data[name] ? formatDate(data[name], "MM/DD/YYYY hh:mm A") : null}
      />
    );
  }
  renderDateOnlyInput(name, label, minDate, maxDate, shouldDisableDate, disabled, subText) {
    const { data, errors } = this.state;
    return (
      <DateOnlyInput
        name={name}
        minDate={minDate}
        maxDate={maxDate}
        shouldDisableDate={shouldDisableDate}
        disabled={disabled}
        onChange={(date) => {
          this.handleChangeSelect({
            currentTarget: { value: date, name },
          });
        }}
        error={errors[name]}
        value={data[name]}
        label={label}
        subText={subText && data[name] ? formatDate(data[name], "MM/DD/YYYY") : null}
      />
    );
  }
  renderDateESTInput(name, label, minDate, disabled, subText) {
    const { data, errors } = this.state;
    return (
      <DateInputEST
        name={name}
        minDate={minDate}
        disabled={disabled}
        onChange={(date) => {
          this.handleChangeSelect({
            currentTarget: { value: date, name },
          });
        }}
        error={errors[name]}
        value={data[name]}
        label={label}
        subText={subText && data[name] ? formatDate(data[name], "MM/DD/YYYY") : null}
      />
    );
  }

  renderDateRangeInput(name, label, minDate, disabled, options, defaultValue) {
    const { data, errors } = this.state;
    return (
      <DateRangeInput
        name={name}
        minDate={minDate}
        disabled={disabled}
        onChange={(date) => {
          this.handleChangeSelect({
            currentTarget: { value: date, name },
          });
        }}
        error={errors[name]}
        value={data[name]}
        label={label}
      />
    );
  }
}

export default Form;
