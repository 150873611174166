import { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

//  Images
import emptyIcon from "include/images/nrf.svg";

//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import SelectSearch from "common/form/selectSearch";
import NextButton from "common/form/submitButton";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import Joi from "joi-browser";
import { addBoardGameConfig, getBoardGame, loadBoardGameConfig } from "store/boardGame";

class ColorMapListing extends Form {
  ref = createRef(null);
  state = {
    data: [],
    loadingMain: false,
    loadingDelete: false,
    showDeleteModal: false,
    showAddModal: false,
    deleteId: "",
    errors: [{}],

    page: 1,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getBoardGame.loading && this.props.getBoardGame.loading !== prevProps.getBoardGame.loading)
      this.ref.current.complete();

    if (this.props.getBoardGame.loading && this.props.getBoardGame.loading !== prevProps.getBoardGame.loading)
      this.ref.current.continuousStart();
    if (this.state.showAddModal !== prevState.showAddModal && !this.state.showAddModal) {
      this.setState({ editData: null });
    }
  };
  componentDidMount = () => {
    this.setState({ loadingMain: true });
    this.props.loadBoardGameConfig({}, (res) => {
      this.setState({
        loadingMain: false,
      });
      if (res.status === 200) {
        const data = res?.data?.data?.map((v) => ({
          xx: v?.xx,
          zz: v?.zz,
          bgGame: res?.data?.bgGame
            ?.filter((game) => game?.id === v?.bgGameId)
            ?.map((game) => ({ name: game?.name, value: game?.id }))[0],
        }));
        this.setState({ data });
      }
    });
  };

  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };

  schema = {
    xx: Joi.number().allow(""),
    zz: Joi.number().allow(""),
    bgGame: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
    }),
  };
  changeOne = (name, value, index) => {
    const data = structuredClone(this.state.data);
    const errors = structuredClone(this.state.errors);
    if (!errors[index]) errors[index] = {};
    const err = errors[index];
    if (err[name]) {
      delete err[name];
    }
    const newData = data[index];
    newData[name] = value;
    data[index] = newData;
    this.setState({ data, errors });
  };
  doSubmit = (e) => {
    const data = [...this.state.data];
    const errors = data?.map((prize, index) => this.validateCustomData(prize));
    this.setState({ errors });

    if (errors?.find((err) => err)) {
      return;
    } else {
      this.setState({ loading: true });
    }
    const payLoad = {
      data: data?.map((v) => ({
        xx: v.xx ? parseInt(v.xx) : 0,
        zz: v.zz ? parseInt(v.zz) : 0,
        bgGame_id: v?.bgGame?.value,
      })),
    };

    this.props.addBoardGameConfig(payLoad, (res) => {
      this.setState({
        loading: false,
      });
      if (res.status === 200) {
        this.props.loadBoardGameConfig();
        toast(<AlertSuccess message="Information Saved" />);
      } else {
        toast(<AlertError message={res.data && res.data.message} />, {
          containerId: 1,
        });
      }
    });
  };
  render() {
    const { boardGameConfig, boardGameBGgames } = this.props.getBoardGame;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="boardGameConfig" />

          {!this.state.loadingMain && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1>Board Game Config </h1>
              </div>
              <div className="fwc-wrapper">
                <div className="fwc-body">
                  {boardGameConfig?.length === 0 ? (
                    <div className="fwc-body">
                      <div className="no-record-found-container">
                        <div className="nfr-box">
                          <img src={emptyIcon} alt="No Record Found" />
                          <div className="nrf-text">
                            <h5>No Records Found!</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>XX</th>
                            <th>ZZ</th>
                            <th>My Type</th>
                            <th>BG Game</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data?.map((config, index) => (
                            <tr>
                              <td>{config?.xx}</td>
                              <td>{config?.zz}</td>
                              <td>{boardGameConfig[index]?.myType}</td>
                              <td>
                                <SelectSearch
                                  name="bgGame"
                                  options={boardGameBGgames?.map((game) => ({ name: game?.name, value: game?.id }))}
                                  label="Select BG game"
                                  value={this.state.data[index]?.bgGame}
                                  error={this.state.errors[index]?.bgGame}
                                  onChange={(e) => {
                                    this.changeOne(e.name, e.currentTarget, index);
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <div className="new-form-group new-form-btn  text-end">
                        <NextButton
                          handleSubmit={this.doSubmit}
                          label="Save"
                          loading={this.state.loading}
                          classData={"btn btn-default"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadBoardGameConfig: (params, callback) => dispatch(loadBoardGameConfig(params, callback)),
  addBoardGameConfig: (data, callback) => dispatch(addBoardGameConfig(data, callback)),
});
const mapStateToProps = (state) => ({
  getBoardGame: getBoardGame(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ColorMapListing));
