import React from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import {
  getSettings,
  addSettings,
  loadWinnerTemplates,
  addWinnerTemplate,
  updateWinnerTemplate,
  addWinnerTemplateReceived,
  updateWinnerTemplateReceived,
} from "store/settings";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Joi from "joi-browser";
import { fetchNFT, ethToUSD } from "utils/externalApiCaller";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import Amplify, { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import plusIcon from "include/images/plus-1.svg";
import ethIcon from "include/images/eth-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";

//  Components
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";

class AddFlyer extends Form {
  state = {
    loading: false,
    data: {
      rewards: [{ credits: 0 }],
    },
    errors: {},
    loadingCredits: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal) {
        if (
          this.props.modalTitle?.value !== "streakReward" &&
          this.props.modalTitle?.value !== "freeSignupCredits" &&
          this.props.modalTitle?.value !== "dailyCreditPurchaseLimit"
        ) {
          if (this.props.edit) {
            const data = { ...this.state.data };
            data.rewards = this.props.edit.map((reward) => {
              const rew = { credits: reward.credits };
              return rew;
            });
            this.setState({ data });
          }
        } else {
          const data = structuredClone(this.state.data);
          const rew = { credits: parseInt(this.props.edit) };
          data.rewards = [rew];
          this.setState({ data });
        }
      }
    }
  };
  doSubmit = (e) => {
    this.setState({ loadingCredits: true });
    const data = structuredClone(this.state.data);
    const errors = structuredClone(this.state.errors);
    let err = false;
    data.rewards.forEach((reward, index) => {
      if (
        (this.props.modalTitle?.value === "streakReward" && isNaN(reward.credits)) ||
        (this.props.modalTitle?.value === "dailyCreditPurchaseLimit" && isNaN(reward.credits)) ||
        (this.props.modalTitle?.value === "freeSignupCredits" && isNaN(reward.credits)) ||
        (this.props.modalTitle?.value !== "streakReward" &&
          this.props.modalTitle?.value !== "freeSignupCredits" &&
          this.props.modalTitle?.value !== "dailyCreditPurchaseLimit" &&
          !reward.credits)
      ) {
        err = true;
        errors.rewards = { ...errors.rewards };
        errors.rewards[index] = "Credits cannot be empty";
      } else {
        if (errors.rewards && errors.rewards[index]) {
          delete errors.rewards[index];
        }
      }
    });
    if (
      this.props.modalTitle?.value !== "streakReward" &&
      this.props.modalTitle?.value !== "freeSignupCredits" &&
      this.props.modalTitle?.value !== "dailyCreditPurchaseLimit"
    ) {
      data.rewards.map((reward, index) => {
        reward.position = index + 1;
        return;
      });
    }

    if (err || errors.name) {
      return this.setState({ loadingCredits: false, errors });
    }
    const payLoad = {
      [this.props.modalTitle?.value]:
        this.props.modalTitle?.value === "streakReward" ||
        this.props.modalTitle?.value === "freeSignupCredits" ||
        this.props.modalTitle?.value === "dailyCreditPurchaseLimit"
          ? parseInt(data.rewards[0]?.credits)
          : data.rewards,
    };
    this.props.addSettings(payLoad, (settingsResponse) => {
      this.setState({
        loadingCredits: false,
      });
      if (settingsResponse.status === 200) {
        toast(<AlertSuccess message="Information Saved" />, {
          containerId: 1,
        });
        const settings = settingsResponse.data.data;
        this.props.setResponse(settings[this.props.modalTitle?.value]);
        this.props.toggleModal();
        this.clearData();
      } else {
        toast(<AlertError message={settingsResponse.data && settingsResponse.data.message} />, {
          containerId: 1,
        });
      }
    });
  };
  schema = {
    rewards: Joi.array().allow([]),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        rewards: [{ credits: 0 }],
      },
      errors: {},
      loadingCredits: false,
    });
  };

  changeOne = (value, index) => {
    const data = structuredClone(this.state.data);
    const errors = structuredClone(this.state.errors);
    if (errors.rewards && errors.rewards[index]) {
      delete errors.rewards[index];
    }
    data.rewards[index].credits = parseInt(value);
    this.setState({ data });
  };
  render() {
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel half-width-modal"}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>{this.props.modalTitle?.title}</h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              {this.props.modalTitle?.value !== "streakReward" &&
              this.props.modalTitle?.value !== "freeSignupCredits" &&
              this.props.modalTitle?.value !== "dailyCreditPurchaseLimit" ? (
                <table className="table large-table flyer-table">
                  <thead>
                    <tr>
                      <th style={{ width: 10 }}>Position</th>
                      <th style={{ width: 370 }}>Game Credits</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.rewards?.map((reward, index) => (
                      <tr className="flyer-table">
                        <td className="vertical-middle">{index + 1}</td>
                        <td>
                          <input
                            name="credit"
                            type={"number"}
                            value={reward.credits}
                            className={`form-control ${
                              this.state.errors.rewards && this.state.errors.rewards[index] && "error"
                            }`}
                            onChange={(e) => {
                              this.changeOne(e.target.value, index);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <table className="table large-table flyer-table">
                  <thead>
                    <tr>
                      <th>{this.props.modalTitle?.value === "dailyCreditPurchaseLimit" ? "Limit" : "Game Credits"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.rewards?.map((reward, index) => (
                      <tr className="flyer-table">
                        <td>
                          <input
                            name="credit"
                            type={"number"}
                            value={reward.credits}
                            className={`form-control ${
                              this.state.errors.rewards && this.state.errors.rewards[index] && "error"
                            }`}
                            onChange={(e) => {
                              this.changeOne(e.target.value, index);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}{" "}
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loadingCredits}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addSettings: (data, callback) => dispatch(addSettings(data, callback)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFlyer));
