import { createSlice } from "@reduxjs/toolkit";
import moment from "moment/moment";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "users",
  initialState: {
    users: [],
    userNotification: [],
    totalCount: null,
    filteredCount: null,
    userActivities: [],
    userWallets: [],
    purchaseHistory: [],
    userTransactions: [],
    userWithdrawals: [],
    filteredCount: 0,
    userTicketBalance: null,
    loadingTicketBalance: false,
    ipHistory: [],
    multipleAccounts: [],
    amoeUsers: [],
    amoeContests: [],
    previousAmoeAwards: [],
    amoeEligibility: null,
    payments: [],
    userPayments: [],
    loadingPayments: false,
    paymentLastKey: null,
    amoeAwardsLastKey: null,
    ticketsWonLastKey: null,
  },
  reducers: {
    usersRequested: (misc, action) => {
      misc.loading = true;
    },
    updateUserReceived: (misc, action) => {
      const payLoad = action.payload;
      const data = payLoad.data;
      misc.users = misc.users.map((user, index) => {
        const newUser = { ...user };
        if (payLoad.id === newUser.id) {
          newUser.email = data.email;
          newUser.description = data.description;
          newUser.instagram = data.instagram;
          newUser.twitter = data.twitter;
          newUser.userName = data.userName;
          newUser.website = data.website;
        }
        return newUser;
      });
    },
    updateUserCreditsReceived: (misc, action) => {
      const payLoad = action.payload;

      const data = payLoad.data;
      const type = payLoad.rewardType === 1 ? "tickets" : payLoad.rewardType === 2 ? "arcadeCoins" : "lootBonusCoins";
      misc.users = misc.users.map((user, index) => {
        const newUser = { ...user };
        if (payLoad.id === newUser.id) {
          newUser[type] = payLoad.type === "add" ? newUser[type] + data : newUser[type] - data;
        }
        return newUser;
      });
    },
    updateUserImageReceived: (misc, action) => {
      const payLoad = action.payload;
      const image = payLoad.image;
      misc.users = misc.users.map((user, index) => {
        const newUser = { ...user };
        if (payLoad.id === newUser.id) {
          newUser.image = image;
        }
        return newUser;
      });
    },
    updateUserStatusReceived: (misc, action) => {
      const payLoad = action.payload;
      misc.users = misc.users.map((user, index) => {
        const newUser = { ...user };
        if (payLoad.id === newUser.id) {
          newUser.status = payLoad.status;
          if (payLoad.status === -1) {
            newUser.suspensionDetails = {
              date: moment.utc().valueOf(),
              reason: payLoad.reason,
            };
          } else if (payLoad.status === 0) {
            newUser.inactiveDetails = {
              date: moment.utc().valueOf(),
              reason: "",
            };
          } else {
            newUser.inactiveDetails = {};
          }
        }
        return newUser;
      });
    },
    updateKycStatusReceived: (misc, action) => {
      const payLoad = action.payload.data;
      misc.users = misc.users.map((user, index) => {
        const newUser = { ...user };
        if (payLoad.id === newUser.id) {
          newUser.isKycVerified = payLoad?.isKycVerified;
          newUser.kycVerifiedAt = payLoad?.kycVerifiedAt;
        }
        return newUser;
      });
    },
    usersReceived: (misc, action) => {
      misc.users = action.payload.data;
      misc.filteredCount = action.payload.filteredCount;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    moreUsersReceived: (misc, action) => {
      misc.users = [...misc.users, ...action.payload.data];
      misc.filteredCount = action.payload.filteredCount;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    userNotificationReceived: (misc, action) => {
      misc.userNotification = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    userWalletsReceived: (misc, action) => {
      misc.userWallets = action.payload.data;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    userPurchaseHistoryReceived: (misc, action) => {
      misc.purchaseHistory = action.payload.data;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    userTransactionsReceived: (misc, action) => {
      misc.userTransactions = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    moreUserTransactionsReceived: (misc, action) => {
      misc.userTransactions = [...misc.userTransactions, ...action.payload.data];
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    userActivitiesReceived: (misc, action) => {
      misc.userActivities = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    userWithdrawalsReceived: (misc, action) => {
      misc.userWithdrawals = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    userIPHistoryReceived: (misc, action) => {
      misc.ipHistory = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    userMultipleAccountsReceived: (misc, action) => {
      misc.multipleAccounts = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },

    //  Get Ticket Balance
    usersTicketBalanceRequested: (misc, action) => {
      misc.loadingTicketBalance = true;
    },
    usersTicketBalanceReceived: (misc, action) => {
      misc.userTicketBalance = action.payload.data;
      misc.users = misc.users.map((user) => {
        if (user.id == action.payload.data.userId) {
          user.ticketBalance = action.payload.data.availableTickets;
          user.allTicketAwarded = action.payload.data.ticketAwarded;
          user.ticketUsed = action.payload.data.ticketUsed;
        }
        return user;
      });
      misc.loadingTicketBalance = false;
    },
    usersTicketBalanceRequestFailed: (misc, action) => {
      misc.loadingTicketBalance = false;
    },

    //  AMOE Users Reducer
    amoeUsersReceived: (misc, action) => {
      misc.amoeUsers = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    amoeContestsReceived: (misc, action) => {
      misc.amoeContests = action.payload.data;
      misc.filteredCount = action.payload.filteredCount;
      misc.totalCount = action.payload.totalCount;
      misc.ticketsWonLastKey = action.payload.lastKey;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    moreAmoeContestsReceived: (misc, action) => {
      misc.amoeContests = [...misc.amoeContests, ...action.payload.data];
      misc.filteredCount = action.payload.filteredCount;
      misc.totalCount = action.payload.totalCount;
      misc.ticketsWonLastKey = action.payload.lastKey;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    amoeAwardsReceived: (misc, action) => {
      misc.previousAmoeAwards = action.payload.data;
      misc.filteredCount = action.payload.filteredCount;
      misc.totalCount = action.payload.totalCount;
      misc.amoeAwardsLastKey = action.payload.lastKey;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    moreAmoeAwardsReceived: (misc, action) => {
      misc.previousAmoeAwards = [...misc.previousAmoeAwards, ...action.payload.data];
      misc.filteredCount = action.payload.filteredCount;
      misc.totalCount = action.payload.totalCount;
      misc.amoeAwardsLastKey = action.payload.lastKey;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    amoeEligibilityReceived: (misc, action) => {
      misc.amoeEligibility = action.payload;
    },
    amoeEligibilityRequestFailed: (misc, action) => {
      misc.amoeEligibility = null;
    },
    amoeUsersRequestFailed: (misc, action) => {
      misc.amoeUsers = [];
      misc.amoeEligibility = null;
      misc.loading = false;
    },
    usersRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    paymentsReceived: (misc, action) => {
      misc.payments = action.payload.data;
      misc.filteredCount = action.payload.filteredCount;
      misc.totalCount = action.payload.totalCount;
      misc.paymentLastKey = action.payload.lastKey;
      misc.lastFetch = Date.now();
      misc.loadingPayments = false;
    },
    morePaymentsReceived: (misc, action) => {
      misc.payments = [...misc.payments, ...action.payload.data];
      misc.filteredCount = action.payload.filteredCount;
      misc.paymentLastKey = action.payload.lastKey;
      misc.lastFetch = Date.now();
      misc.loadingPayments = false;
    },
    userPaymentsReceived: (misc, action) => {
      misc.userPayments = action.payload.data;
      misc.filteredCount = action.payload.filteredCount;
      misc.paymentLastKey = action.payload.lastKey;
      misc.lastFetch = Date.now();
      misc.loadingPayments = false;
    },
    userMorePaymentsReceived: (misc, action) => {
      misc.userPayments = [...misc.userPayments, ...action.payload.data];
      misc.filteredCount = action.payload.filteredCount;
      misc.paymentLastKey = action.payload.lastKey;
      misc.lastFetch = Date.now();
      misc.loadingPayments = false;
    },
    paymentsRequestFailed: (misc, action) => {
      misc.loadingPayments = false;
    },
    paymentsRequested: (misc, action) => {
      misc.loadingPayments = true;
    },
  },
});

export const {
  usersRequested,
  usersReceived,
  moreUsersReceived,
  usersRequestFailed,
  userNotificationReceived,
  userActivitiesReceived,
  updateUserStatusReceived,
  updateKycStatusReceived,
  updateUserReceived,
  updateUserImageReceived,
  userWalletsReceived,
  userPurchaseHistoryReceived,
  userTransactionsReceived,
  moreUserTransactionsReceived,
  updateUserCreditsReceived,
  usersTicketBalanceRequested,
  usersTicketBalanceReceived,
  usersTicketBalanceRequestFailed,
  userIPHistoryReceived,
  userMultipleAccountsReceived,
  amoeUsersReceived,
  amoeContestsReceived,
  moreAmoeContestsReceived,
  amoeAwardsReceived,
  moreAmoeAwardsReceived,
  amoeEligibilityReceived,
  amoeEligibilityRequestFailed,
  amoeUsersRequestFailed,
  paymentsReceived,
  paymentsRequestFailed,
  paymentsRequested,
  morePaymentsReceived,
  userPaymentsReceived,
  userMorePaymentsReceived,
  userWithdrawalsReceived,
} = slice.actions;
export default slice.reducer;

// Action Creators
const usersUrl = "userProfile/";
const amoeUrl = "amoe/";

export const loadUsers =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: usersUrl,
        params,
        onStart: usersRequested.type,
        onSuccess: fresh ? usersReceived.type : moreUsersReceived.type,
        onError: usersRequestFailed.type,
        callback,
      }),
    );
  };
export const loadUsersToExport = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: usersUrl,
      params,
      callback,
    }),
  );
};
export const updateUser = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: usersUrl + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const updateStatus = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: usersUrl + "updateStatus/" + id,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateKycStatus = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: usersUrl + "verifyUserKyc/" + id,
      onSuccess: updateKycStatusReceived.type,
      callback,
    }),
  );
};
export const freeTickets = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "transactions/mintTickets",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const freeCredits = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "transactions/freeCredit",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const deductTickets = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "transactions/burnticket",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const deductCredits = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "transactions/deductCredit",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateProfilePicture = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: usersUrl + "profilePicture/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const loadUserNotification = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: usersUrl + "notifications/" + id,
      onStart: usersRequested.type,
      onSuccess: userNotificationReceived.type,
      onError: usersRequestFailed.type,
      callback,
    }),
  );
};
export const loadUserActivities = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "transactions/" + id,
      onStart: usersRequested.type,
      onSuccess: userActivitiesReceived.type,
      onError: usersRequestFailed.type,
      callback,
    }),
  );
};
export const loadUserWallets = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "walletConnections/" + id,
      params,
      onStart: usersRequested.type,
      onSuccess: userWalletsReceived.type,
      onError: usersRequestFailed.type,
      callback,
    }),
  );
};
export const changeUserWallet = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: usersUrl + "connectWallet/" + id,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadPurchaseHistory = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "purchaseHistory/" + id,
      params,
      onStart: usersRequested.type,
      onSuccess: userPurchaseHistoryReceived.type,
      onError: usersRequestFailed.type,
      callback,
    }),
  );
};
export const loadUserTransactions = (id, params, fresh, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "transactions/" + id,
      params,
      onStart: usersRequested.type,
      onSuccess: fresh ? userTransactionsReceived.type : moreUserTransactionsReceived.type,
      onError: usersRequestFailed.type,
      callback,
    }),
  );
};
export const loadUserWithdrawals = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "assetsWithdrawalInfo/" + id,
      onStart: usersRequested.type,
      onSuccess: userWithdrawalsReceived.type,
      onError: usersRequestFailed.type,
      callback,
    }),
  );
};

//  User Ticket Balance
export const loadUserTicketBalance = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: usersUrl + "getTicketBalance",
      params,
      onStart: usersTicketBalanceRequested.type,
      onSuccess: usersTicketBalanceReceived.type,
      onError: usersTicketBalanceRequestFailed.type,
      callback,
    }),
  );
};

export const loadIPHistory = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: usersUrl + "ipHistory/" + id,
      onStart: usersRequested.type,
      onSuccess: userIPHistoryReceived.type,
      onError: usersRequestFailed.type,
      callback,
    }),
  );
};
export const loadMultipleAccounts = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: usersUrl + "multipleAccounts/" + id,
      onStart: usersRequested.type,
      onSuccess: userMultipleAccountsReceived.type,
      onError: usersRequestFailed.type,
      callback,
    }),
  );
};

//  AMOE Actions
export const loadAMOEUsers = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: amoeUrl + "userProfile",
      params,
      onStart: usersRequested.type,
      onSuccess: amoeUsersReceived.type,
      onError: amoeUsersRequestFailed.type,
      callback,
    }),
  );
};
export const loadAMOEContests = (params, fresh, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: amoeUrl + "contests",
      params,
      onStart: usersRequested.type,
      onSuccess: fresh ? amoeContestsReceived.type : moreAmoeContestsReceived.type,
      onError: usersRequestFailed.type,
      callback,
    }),
  );
};
export const loadAMOEAwards = (params, fresh, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: amoeUrl + "awards",
      params,
      onStart: usersRequested.type,
      onSuccess: fresh ? amoeAwardsReceived.type : moreAmoeAwardsReceived.type,
      onError: usersRequestFailed.type,
      callback,
    }),
  );
};
export const awardAMOETickets = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: amoeUrl + "awardTickets/",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const isEligibleForAMOE = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: amoeUrl + "isEligible/",
      params,
      callback,
      onSuccess: amoeEligibilityReceived.type,
    }),
  );
};
export const loadPayments = (params, fresh, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "orders/",
      params,
      onStart: paymentsRequested.type,
      onSuccess: fresh ? paymentsReceived.type : morePaymentsReceived.type,
      onError: paymentsRequestFailed.type,
      callback,
    }),
  );
};
export const loadUserPayments = (id, fresh, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "orders/" + id,
      onStart: paymentsRequested.type,
      onSuccess: fresh ? userPaymentsReceived.type : userMorePaymentsReceived.type,
      onError: paymentsRequestFailed.type,
      callback,
    }),
  );
};

export const getUsers = createSelector(
  (state) => state.entities.users,
  (users) => users,
);
