import moment from "moment/moment";
import React, { createRef } from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getUsers, loadUserWithdrawals } from "store/users";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import loadingIcon from "include/images/loader-grey.svg";
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";

//  Components
import Form from "common/form/form";
import Loader from "common/loader";
import { truncateAddress } from "utils/misc";
import AlertSuccess from "common/alert/alertSuccess";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

class ListingFlyer extends Form {
  scrollRef = React.createRef();
  ref = createRef(null);
  state = {
    loading: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({ loading: true });

      this.props.loadUserWithdrawals(this.props.user?.id, (res) => {
        this.setState({ loading: false });
      });
    }
    if (!this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({
        loading: false,
      });
    }
    if (!this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.complete();

    if (this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.continuousStart();
  };

  render() {
    const { userWithdrawals } = this.props.getUsers;
    const { user } = this.props;
    return (
      <>
        <Loader loaderRef={this.ref} />
        <Offcanvas
          className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2"}
          show={this.props.showModal}
          onHide={this.props.toggleModal}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header className="offCanvas-header-2">
            <div>
              <h5>User Withdrawals</h5>{" "}
              <div className="td-img-group d-flex align-items-start m-top-2">
                <div className="round-img m-right-2">
                  {user.image ? (
                    <img src={process.env.REACT_APP_CDN + user.image.small} alt="" />
                  ) : (
                    <img src={userIcon} alt="" />
                  )}
                </div>
                <span style={{ marginTop: -8 }}>
                  {user.userName}
                  <br />
                  {user.email}
                </span>
              </div>
            </div>
            <div
              className="btn_close pointer"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={this.props.toggleModal}
            >
              <img src={closeIcon} alt="" />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="" ref={this.scrollRef}>
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {userWithdrawals.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Amount</th>
                            <th>Withdrawn to</th>
                            <th>Date</th>
                            <th>Transaction Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userWithdrawals?.map((withdrawal, i) => (
                            <tr>
                              <td>
                                <div className="td-text-box upperCase">
                                  {withdrawal?.amount?.toLocaleString("en-us")}
                                </div>
                              </td>
                              <td>
                                <Link
                                  to="!#"
                                  className="td-text-box upperCase"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(withdrawal?.to);
                                    toast(<AlertSuccess message="Copied to clipboard" />, { containerId: 1 });
                                  }}
                                >
                                  {truncateAddress(withdrawal?.to)}
                                </Link>
                              </td>
                              <td>
                                <div className="td-text-box">
                                  {moment(withdrawal?.createdAt).format("MM/DD/YYYY hh:mm a")}
                                </div>
                              </td>
                              <td>
                                <div className="td-text-box">
                                  <a
                                    href={
                                      process.env.REACT_APP_ADMIN_SITE_URL === "https://www.admin-play.burnghost.com/"
                                        ? `https://polygonscan.com/tx/${withdrawal?.transactionHash}`
                                        : `https://mumbai.polygonscan.com/tx/${withdrawal?.transactionHash}`
                                    }
                                    className="orange-text"
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    Details
                                  </a>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
            {this.state.loadingMore && (
              <div className="w-100 d-flex justify-content-center load-more">
                <img src={loadingIcon} className="fa-spin" alt="" />
              </div>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadUserWithdrawals: (id, callback) => dispatch(loadUserWithdrawals(id, callback)),
});
const mapStateToProps = (state) => ({
  getUsers: getUsers(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListingFlyer));
