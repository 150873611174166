import { createSlice, current } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "dashboard",
  initialState: {
    zedRunDashboard: {},
    topWinners: [],
    topWinnersPagination: {},
    topLosers: [],
    topLosersPagination: {},
    topUsersByTimeSpent: [],
    lootboxDashboard: {},
    topUsersByTimeSpentPagination: {},
    onlineUsers: [],
    onlineUsersPagination: {},
    lootboxTopWinners: [],
    lootboxTopWinnersPagination: {},
    lootboxCustomPrizes: [],
    lootboxCustomPrizesTotalCount: null,
    lootboxCustomPrizesLoading: false,
    loading: false,
    topWinnersLoading: false,
    topLosersLoading: false,
    topUsersByTimeSpentLoading: false,
    onlineUsersLoading: false,
    dogRunDashboard: {},
    dogRunDashboardLoading: false,
    dogRunTopWinners: [],
    dogRunTopWinnersPagination: {},
    dogRunTopWinnersLoading: false,
    dogRunTopLosers: [],
    dogRunTopLosersPagination: {},
    dogRunTopLosersLoading: false,
    dogRunTopUsersByTimeSpent: [],
    dogRunTopUsersByTimeSpentPagination: {},
    dogRunTopUsersByTimeSpentLoading: false,
    dogRunOnlineUsers: [],
    dogRunOnlineUsersPagination: {},
    dogRunOnlineUsersLoading: false,
    cowitzerDashboardloading: false,
    cowitzerDashboard: {},
    cowitzerTopWinners: [],
    cowitzerTopWinnersPagination: {},
    cowitzerTopWinnersLoading: false,
    cowitzerTopLosers: [],
    cowitzerTopLosersLoading: false,
    cowitzerTopLosersPagination: {},
    lootboxDashboardLoading: false,
    lootboxTopWinnersLoading: false,
    verisoulAuthResults: [],
    verisoulAuthResultsLoading: false,
    totalCount: 0,
    filteredCount: 0,
    verisoulMultipleAccounts: {},
    verisoulMultipleAccountsLoading: false,
    allReferrals: [],
    allReferralsFilteredCount: 0,
    allReferralsTotalCount: 0,
    allReferralsLoading: false,
    deposits: [],
    depositsFilteredCount: 0,
    depositsTotalCount: 0,
    depositsLoading: false,
  },
  reducers: {
    zedRunDashboardRequested: (misc, action) => {
      misc.loading = true;
    },
    zedRunDashboardReceived: (misc, action) => {
      misc.zedRunDashboard = action.payload;
      misc.loading = false;
    },
    zedRunDashboardRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    topWinnersRequested: (misc, action) => {
      misc.topWinnersLoading = true;
    },
    topWinnersReceived: (misc, action) => {
      misc.topWinners = action.payload.data;
      misc.topWinnersPagination = action.payload.pagination;
      misc.topWinnersLoading = false;
    },
    moreTopWinnersReceived: (misc, action) => {
      misc.topWinners = [...misc.topWinners, ...action.payload.data];
      misc.topWinnersPagination = action.payload.pagination;
      misc.topWinnersLoading = false;
    },
    topWinnersRequestFailed: (misc, action) => {
      misc.topWinnersLoading = false;
    },
    topLosersRequested: (misc, action) => {
      misc.topLosersLoading = true;
    },
    topLosersReceived: (misc, action) => {
      misc.topLosers = action.payload.data;
      misc.topLosersPagination = action.payload.pagination;
      misc.topLosersLoading = false;
    },
    moreTopLosersReceived: (misc, action) => {
      misc.topLosers = [...misc.topLosers, ...action.payload.data];
      misc.topLosersPagination = action.payload.pagination;
      misc.topLosersLoading = false;
    },
    topLosersRequestFailed: (misc, action) => {
      misc.topLosersLoading = false;
    },
    topUsersByTimeSpentRequested: (misc, action) => {
      misc.topUsersByTimeSpentLoading = true;
    },
    topUsersByTimeSpentReceived: (misc, action) => {
      misc.topUsersByTimeSpent = action.payload.data;
      misc.topUsersByTimeSpentPagination = action.payload.pagination;
      misc.topUsersByTimeSpentLoading = false;
    },
    moreTopUsersByTimeSpentReceived: (misc, action) => {
      misc.topUsersByTimeSpent = [...misc.topUsersByTimeSpent, ...action.payload.data];
      misc.topUsersByTimeSpentPagination = action.payload.pagination;
      misc.topUsersByTimeSpentLoading = false;
    },
    topUsersByTimeSpentRequestFailed: (misc, action) => {
      misc.topUsersByTimeSpentLoading = false;
    },
    onlineUsersRequested: (misc, action) => {
      misc.onlineUsersLoading = true;
    },
    onlineUsersReceived: (misc, action) => {
      misc.onlineUsers = action.payload.data;
      misc.onlineUsersPagination = action.payload.pagination;
      misc.onlineUsersLoading = false;
    },
    moreOnlineUsersReceived: (misc, action) => {
      misc.onlineUsers = [...misc.onlineUsers, ...action.payload.data];
      misc.onlineUsersPagination = action.payload.pagination;
      misc.onlineUsersLoading = false;
    },
    onlineUsersRequestFailed: (misc, action) => {
      misc.onlineUsersLoading = false;
    },
    userBetsRequested: (misc, action) => {
      misc.userBetsLoading = true;
    },
    userBetsReceived: (misc, action) => {
      misc.userBets = action.payload.data;
      misc.userBetsPagination = action.payload.pagination;
      misc.userBetsLoading = false;
    },
    userBetsRequestFailed: (misc, action) => {
      misc.userBetsLoading = false;
    },
    dogRunDashboardRequested: (misc, action) => {
      misc.dogRunDashboardloading = true;
    },
    dogRunDashboardReceived: (misc, action) => {
      misc.dogRunDashboard = action.payload;
      misc.dogRunDashboardloading = false;
    },
    dogRunDashboardRequestFailed: (misc, action) => {
      misc.dogRunDashboardloading = false;
    },
    dogRunTopWinnersRequested: (misc, action) => {
      misc.dogRunTopWinnersLoading = true;
    },
    dogRunTopWinnersReceived: (misc, action) => {
      misc.dogRunTopWinners = action.payload.data;
      misc.dogRunTopWinnersPagination = action.payload.pagination;
      misc.dogRunTopWinnersLoading = false;
    },
    moreDogRunTopWinnersReceived: (misc, action) => {
      misc.dogRunTopWinners = [...misc.dogRunTopWinners, ...action.payload.data];
      misc.dogRunTopWinnersPagination = action.payload.pagination;
      misc.dogRunTopWinnersLoading = false;
    },
    dogRunTopWinnersRequestFailed: (misc, action) => {
      misc.dogRunTopWinnersLoading = false;
    },
    dogRunTopLosersRequested: (misc, action) => {
      misc.dogRunTopLosersLoading = true;
    },
    dogRunTopLosersReceived: (misc, action) => {
      misc.dogRunTopLosers = action.payload.data;
      misc.dogRunTopLosersPagination = action.payload.pagination;
      misc.dogRunTopLosersLoading = false;
    },
    moreDogRunTopLosersReceived: (misc, action) => {
      misc.dogRunTopLosers = [...misc.dogRunTopLosers, ...action.payload.data];
      misc.dogRunTopLosersPagination = action.payload.pagination;
      misc.dogRunTopLosersLoading = false;
    },
    dogRunTopLosersRequestFailed: (misc, action) => {
      misc.dogRunTopLosersLoading = false;
    },
    dogRunTopUsersByTimeSpentRequested: (misc, action) => {
      misc.dogRunTopUsersByTimeSpentLoading = true;
    },
    dogRunTopUsersByTimeSpentReceived: (misc, action) => {
      misc.dogRunTopUsersByTimeSpent = action.payload.data;
      misc.dogRunTopUsersByTimeSpentPagination = action.payload.pagination;
      misc.dogRunTopUsersByTimeSpentLoading = false;
    },
    moreDogRunTopUsersByTimeSpentReceived: (misc, action) => {
      misc.dogRunTopUsersByTimeSpent = [...misc.dogRunTopUsersByTimeSpent, ...action.payload.data];
      misc.dogRunTopUsersByTimeSpentPagination = action.payload.pagination;
      misc.dogRunTopUsersByTimeSpentLoading = false;
    },
    dogRunTopUsersByTimeSpentRequestFailed: (misc, action) => {
      misc.dogRunTopUsersByTimeSpentLoading = false;
    },
    dogRunOnlineUsersRequested: (misc, action) => {
      misc.dogRunOnlineUsersLoading = true;
    },
    dogRunOnlineUsersReceived: (misc, action) => {
      misc.dogRunOnlineUsers = action.payload.data;
      misc.dogRunOnlineUsersPagination = action.payload.pagination;
      misc.dogRunOnlineUsersLoading = false;
    },
    moreDogRunOnlineUsersReceived: (misc, action) => {
      misc.dogRunOnlineUsers = [...misc.dogRunOnlineUsers, ...action.payload.data];
      misc.dogRunOnlineUsersPagination = action.payload.pagination;
      misc.dogRunOnlineUsersLoading = false;
    },
    dogRunOnlineUsersRequestFailed: (misc, action) => {
      misc.dogRunOnlineUsersLoading = false;
    },
    cowitzerDashboardRequested: (misc, action) => {
      misc.cowitzerDashboardloading = true;
    },
    cowitzerDashboardReceived: (misc, action) => {
      misc.cowitzerDashboard = action.payload;
      misc.cowitzerDashboardloading = false;
    },
    cowitzerDashboardRequestFailed: (misc, action) => {
      misc.cowitzerDashboardloading = false;
    },
    cowitzerTopWinnersRequested: (misc, action) => {
      misc.cowitzerTopWinnersLoading = true;
    },
    cowitzerTopWinnersReceived: (misc, action) => {
      misc.cowitzerTopWinners = action.payload.data;
      misc.cowitzerTopWinnersPagination = action.payload.pagination;
      misc.cowitzerTopWinnersLoading = false;
    },
    moreCowitzerTopWinnersReceived: (misc, action) => {
      misc.cowitzerTopWinners = [...misc.cowitzerTopWinners, ...action.payload.data];
      misc.cowitzerTopWinnersPagination = action.payload.pagination;
      misc.cowitzerTopWinnersLoading = false;
    },
    cowitzerTopWinnersRequestFailed: (misc, action) => {
      misc.cowitzerTopWinnersLoading = false;
    },
    cowitzerTopLosersRequested: (misc, action) => {
      misc.cowitzerTopLosersLoading = true;
    },
    cowitzerTopLosersReceived: (misc, action) => {
      misc.cowitzerTopLosers = action.payload.data;
      misc.cowitzerTopLosersPagination = action.payload.pagination;
      misc.cowitzerTopLosersLoading = false;
    },
    moreCowitzerTopLosersReceived: (misc, action) => {
      misc.cowitzerTopLosers = [...misc.cowitzerTopLosers, ...action.payload.data];
      misc.cowitzerTopLosersPagination = action.payload.pagination;
      misc.cowitzerTopLosersLoading = false;
    },
    cowitzerTopLosersRequestFailed: (misc, action) => {
      misc.cowitzerTopLosersLoading = false;
    },
    lootboxDashboardRequested: (misc, action) => {
      misc.lootboxDashboardLoading = true;
    },
    lootboxDashboardReceived: (misc, action) => {
      misc.lootboxDashboard = action.payload;
      misc.lootboxDashboardLoading = false;
    },
    lootboxDashboardRequestFailed: (misc, action) => {
      misc.lootboxDashboardLoading = false;
    },
    lootboxTopWinnersRequested: (misc, action) => {
      misc.lootboxTopWinnersLoading = true;
    },
    lootboxTopWinnersReceived: (misc, action) => {
      misc.lootboxTopWinners = action.payload.data;
      misc.lootboxTopWinnersPagination = action.payload.pagination;
      misc.lootboxTopWinnersLoading = false;
    },
    moreLootboxTopWinnersReceived: (misc, action) => {
      misc.lootboxTopWinners = [...misc.lootboxTopWinners, ...action.payload.data];
      misc.lootboxTopWinnersPagination = action.payload.pagination;
      misc.lootboxTopWinnersLoading = false;
    },
    lootboxTopWinnersRequestFailed: (misc, action) => {
      misc.lootboxTopWinnersLoading = false;
    },
    lootboxCustomPrizesRequested: (misc, action) => {
      misc.lootboxCustomPrizesLoading = true;
    },
    lootboxCustomPrizesReceived: (misc, action) => {
      misc.lootboxCustomPrizes = action.payload.data;
      misc.lootboxCustomPrizesTotalCount = action.payload.totalCount;
      misc.lootboxCustomPrizesLoading = false;
    },
    moreLootboxCustomPrizesReceived: (misc, action) => {
      misc.lootboxCustomPrizes = [...misc.lootboxCustomPrizes, ...action.payload.data];
      misc.lootboxCustomPrizesTotalCount = action.payload.totalCount;
      misc.lootboxCustomPrizesLoading = false;
    },
    lootboxCustomPrizesRequestFailed: (misc, action) => {
      misc.lootboxCustomPrizesLoading = false;
    },
    updateTransferCustomPrize: (misc, action) => {
      misc.lootboxCustomPrizes = misc.lootboxCustomPrizes?.map((winner) =>
        winner?._id === action.payload ? { ...winner, transactionStatus: 2 } : winner,
      );
    },
    verisoulAuthResultsRequested: (misc, action) => {
      misc.verisoulAuthResultsLoading = true;
    },
    verisoulAuthResultsReceived: (misc, action) => {
      misc.verisoulAuthResults = action.payload.data;
      misc.filteredCount = action.payload.filteredCount;
      misc.totalCount = action.payload.totalCount;
      misc.verisoulAuthResultsLoading = false;
    },
    verisoulAuthResultsRequestFailed: (misc, action) => {
      misc.verisoulAuthResultsLoading = false;
    },
    verisoulMultipleAccountsRequested: (misc, action) => {
      misc.verisoulMultipleAccountsLoading = true;
    },
    verisoulMultipleAccountsReceived: (misc, action) => {
      misc.verisoulMultipleAccounts = action.payload.data;
      misc.filteredCount = action.payload.filteredCount;
      misc.totalCount = action.payload.totalCount;
      misc.verisoulMultipleAccountsLoading = false;
    },
    verisoulMultipleAccountsRequestFailed: (misc, action) => {
      misc.verisoulMultipleAccountsLoading = false;
    },
    allReferralsRequested: (misc, action) => {
      misc.allReferralsLoading = true;
    },
    allReferralsReceived: (misc, action) => {
      misc.allReferrals = action.payload.data;
      misc.allReferralsFilteredCount = action.payload.filteredCount;
      misc.allReferralsTotalCount = action.payload.totalCount;
      misc.allReferralsLoading = false;
    },
    allReferralsRequestFailed: (misc, action) => {
      misc.allReferralsLoading = false;
    },
    depositsRequested: (misc, action) => {
      misc.depositsLoading = true;
    },
    depositsReceived: (misc, action) => {
      misc.deposits = action.payload.data;
      misc.depositsFilteredCount = action.payload.filteredCount;
      misc.depositsTotalCount = action.payload.totalCount;
      misc.depositsLoading = false;
    },
    moreDepositsReceived: (misc, action) => {
      misc.deposits = [...misc.deposits, ...action.payload.data];
      misc.depositsFilteredCount = action.payload.filteredCount;
      misc.depositsTotalCount = action.payload.totalCount;
      misc.depositsLoading = false;
    },
    depositsRequestFailed: (misc, action) => {
      misc.depositsLoading = false;
    },
    referralStatsRequested: (misc, action) => {
      misc.referralStatsLoading = true;
    },
    referralStatsReceived: (misc, action) => {
      misc.referralStats = action.payload;
      misc.referralStatsLoading = false;
    },
    referralStatsRequestFailed: (misc, action) => {
      misc.referralStatsLoading = false;
    },
  },
});

export const {
  zedRunDashboardRequested,
  zedRunDashboardReceived,
  zedRunDashboardRequestFailed,
  topWinnersRequested,
  topWinnersReceived,
  moreTopWinnersReceived,
  topWinnersRequestFailed,
  topLosersRequested,
  topLosersReceived,
  moreTopLosersReceived,
  topLosersRequestFailed,
  topUsersByTimeSpentRequested,
  topUsersByTimeSpentReceived,
  moreTopUsersByTimeSpentReceived,
  topUsersByTimeSpentRequestFailed,
  onlineUsersRequested,
  onlineUsersReceived,
  moreOnlineUsersReceived,
  onlineUsersRequestFailed,
  userBetsRequested,
  userBetsReceived,
  userBetsRequestFailed,
  dogRunDashboardRequested,
  dogRunDashboardReceived,
  dogRunDashboardRequestFailed,
  cowitzerDashboardRequested,
  cowitzerDashboardReceived,
  cowitzerDashboardRequestFailed,
  lootboxDashboardRequested,
  lootboxDashboardReceived,
  lootboxDashboardRequestFailed,
  lootboxTopWinnersRequested,
  lootboxTopWinnersReceived,
  moreLootboxTopWinnersReceived,
  lootboxTopWinnersRequestFailed,
  lootboxCustomPrizesRequested,
  lootboxCustomPrizesReceived,
  updateTransferCustomPrize,
  moreLootboxCustomPrizesReceived,
  lootboxCustomPrizesRequestFailed,
  dogRunTopWinnersRequested,
  dogRunTopWinnersReceived,
  moreDogRunTopWinnersReceived,
  dogRunTopWinnersRequestFailed,
  dogRunTopLosersRequested,
  dogRunTopLosersReceived,
  moreDogRunTopLosersReceived,
  dogRunTopLosersRequestFailed,
  dogRunTopUsersByTimeSpentRequested,
  dogRunTopUsersByTimeSpentReceived,
  moreDogRunTopUsersByTimeSpentReceived,
  dogRunTopUsersByTimeSpentRequestFailed,
  dogRunOnlineUsersRequested,
  dogRunOnlineUsersReceived,
  moreDogRunOnlineUsersReceived,
  dogRunOnlineUsersRequestFailed,
  cowitzerTopWinnersRequested,
  cowitzerTopWinnersReceived,
  moreCowitzerTopWinnersReceived,
  cowitzerTopWinnersRequestFailed,
  cowitzerTopLosersRequested,
  cowitzerTopLosersReceived,
  moreCowitzerTopLosersReceived,
  cowitzerTopLosersRequestFailed,
  verisoulAuthResultsRequested,
  verisoulAuthResultsReceived,
  verisoulAuthResultsRequestFailed,
  verisoulMultipleAccountsRequested,
  verisoulMultipleAccountsReceived,
  verisoulMultipleAccountsRequestFailed,
  allReferralsRequested,
  allReferralsReceived,
  allReferralsRequestFailed,
  depositsRequested,
  depositsReceived,
  moreDepositsReceived,
  depositsRequestFailed,
  referralStatsRequested,
  referralStatsReceived,
  referralStatsRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const zedRunAnalyticsUrl = "crashgame/zedrun/";
const dogRunAnalyticsUrl = "crashgame/dogracing/";
const cowitzerAnalyticsUrl = "game/cowitzer/";
const lootboxAnalyticsUrl = "lootBox/";
const verisoulAuthResultsUrl = "userProfile/verisoulAuthResults";
const verisoulMultipleAccountsUrl = "userProfile/verisoulMultipleAccounts";

export const loadZedRunDashboard = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: zedRunAnalyticsUrl + "dashboard",
      onStart: zedRunDashboardRequested.type,
      onSuccess: zedRunDashboardReceived.type,
      onError: zedRunDashboardRequestFailed.type,
      callback,
    }),
  );
};
export const loadZedRunTopWinners =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: zedRunAnalyticsUrl + "topWinner",
        params,
        onStart: topWinnersRequested.type,
        onSuccess: fresh ? topWinnersReceived.type : moreTopWinnersReceived.type,
        onError: topWinnersRequestFailed.type,
        callback,
      }),
    );
  };
export const loadZedRunTopLosers =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: zedRunAnalyticsUrl + "topLoser",
        params,
        onStart: topLosersRequested.type,
        onSuccess: fresh ? topLosersReceived.type : moreTopLosersReceived.type,
        onError: topLosersRequestFailed.type,
        callback,
      }),
    );
  };
export const loadZedRunTopUsersByTimeSpent =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: zedRunAnalyticsUrl + "timeSpentByUser",
        params,
        onStart: topUsersByTimeSpentRequested.type,
        onSuccess: fresh ? topUsersByTimeSpentReceived.type : moreTopUsersByTimeSpentReceived.type,
        onError: topUsersByTimeSpentRequestFailed.type,
        callback,
      }),
    );
  };
export const loadZedRunOnlineUsers =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: zedRunAnalyticsUrl + "onlineUser",
        params,
        onStart: onlineUsersRequested.type,
        onSuccess: fresh ? onlineUsersReceived.type : moreOnlineUsersReceived.type,
        onError: onlineUsersRequestFailed.type,
        callback,
      }),
    );
  };
export const loadUserBets = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: zedRunAnalyticsUrl + "userBet",
      params,
      onStart: userBetsRequested.type,
      onSuccess: userBetsReceived.type,
      onError: userBetsRequestFailed.type,
      callback,
    }),
  );
};
export const loadLootboxDashboard = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: lootboxAnalyticsUrl + "dashboard",
      onStart: lootboxDashboardRequested.type,
      onSuccess: lootboxDashboardReceived.type,
      onError: lootboxDashboardRequestFailed.type,
      callback,
    }),
  );
};
export const loadLootboxTopWinners =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: lootboxAnalyticsUrl + "topWinners",
        params,
        onStart: lootboxTopWinnersRequested.type,
        onSuccess: fresh ? lootboxTopWinnersReceived.type : moreLootboxTopWinnersReceived.type,
        onError: lootboxTopWinnersRequestFailed.type,
        callback,
      }),
    );
  };
export const loadLootboxCustomPrizes =
  (userId, params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: lootboxAnalyticsUrl + "customPrize/" + userId,
        params,
        onStart: lootboxCustomPrizesRequested.type,
        onSuccess: fresh ? lootboxCustomPrizesReceived.type : moreLootboxCustomPrizesReceived.type,
        onError: lootboxCustomPrizesRequestFailed.type,
        callback,
      }),
    );
  };
export const loadDogRunDashboard = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: dogRunAnalyticsUrl + "dashboard",
      onStart: dogRunDashboardRequested.type,
      onSuccess: dogRunDashboardReceived.type,
      onError: dogRunDashboardRequestFailed.type,
      callback,
    }),
  );
};
export const loadDogRunTopWinners =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: dogRunAnalyticsUrl + "topWinner",
        params,
        onStart: dogRunTopWinnersRequested.type,
        onSuccess: fresh ? dogRunTopWinnersReceived.type : moreDogRunTopWinnersReceived.type,
        onError: dogRunTopWinnersRequestFailed.type,
        callback,
      }),
    );
  };
export const loadDogRunTopLosers =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: dogRunAnalyticsUrl + "topLoser",
        params,
        onStart: dogRunTopLosersRequested.type,
        onSuccess: fresh ? dogRunTopLosersReceived.type : moreDogRunTopLosersReceived.type,
        onError: dogRunTopLosersRequestFailed.type,
        callback,
      }),
    );
  };
export const loadDogRunTopUsersByTimeSpent =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: dogRunAnalyticsUrl + "timeSpentByUser",
        params,
        onStart: dogRunTopUsersByTimeSpentRequested.type,
        onSuccess: fresh ? dogRunTopUsersByTimeSpentReceived.type : moreDogRunTopUsersByTimeSpentReceived.type,
        onError: dogRunTopUsersByTimeSpentRequestFailed.type,
        callback,
      }),
    );
  };
export const loadDogRunOnlineUsers =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: dogRunAnalyticsUrl + "onlineUser",
        params,
        onStart: dogRunOnlineUsersRequested.type,
        onSuccess: fresh ? dogRunOnlineUsersReceived.type : moreDogRunOnlineUsersReceived.type,
        onError: dogRunOnlineUsersRequestFailed.type,
        callback,
      }),
    );
  };

export const loadCowitzerDashboard = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: cowitzerAnalyticsUrl + "dashboard",
      onStart: cowitzerDashboardRequested.type,
      onSuccess: cowitzerDashboardReceived.type,
      onError: cowitzerDashboardRequestFailed.type,
      callback,
    }),
  );
};
export const loadCowitzerTopWinners =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: cowitzerAnalyticsUrl + "topWinner",
        params,
        onStart: cowitzerTopWinnersRequested.type,
        onSuccess: fresh ? cowitzerTopWinnersReceived.type : moreCowitzerTopWinnersReceived.type,
        onError: cowitzerTopWinnersRequestFailed.type,
        callback,
      }),
    );
  };
export const loadCowitzerTopLosers =
  (params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: cowitzerAnalyticsUrl + "topLoser",
        params,
        onStart: cowitzerTopLosersRequested.type,
        onSuccess: fresh ? cowitzerTopLosersReceived.type : moreCowitzerTopLosersReceived.type,
        onError: cowitzerTopLosersRequestFailed.type,
        callback,
      }),
    );
  };
export const loadVerisoulAuthResultsDashboard = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      params,
      url: verisoulAuthResultsUrl,
      onStart: verisoulAuthResultsRequested.type,
      onSuccess: verisoulAuthResultsReceived.type,
      onError: verisoulAuthResultsRequestFailed.type,
      callback,
    }),
  );
};
export const loadVerisoulMultipleAccountsDashboard = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      params,
      url: verisoulMultipleAccountsUrl,
      onStart: verisoulMultipleAccountsRequested.type,
      onSuccess: verisoulMultipleAccountsReceived.type,
      onError: verisoulMultipleAccountsRequestFailed.type,
      callback,
    }),
  );
};

export const loadAllReferrals = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      params,
      url: "referrals",
      onStart: allReferralsRequested.type,
      onSuccess: allReferralsReceived.type,
      onError: allReferralsRequestFailed.type,
      callback,
    }),
  );
};
export const loadDeposits =
  (id, params, fresh = true, callback) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        params,
        url: "referrals/deposits/" + id,
        onStart: depositsRequested.type,
        onSuccess: fresh ? depositsReceived.type : moreDepositsReceived.type,
        onError: depositsRequestFailed.type,
        callback,
      }),
    );
  };
export const loadReferralStats = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "referrals/dashboard",
      onStart: referralStatsRequested.type,
      onSuccess: referralStatsReceived.type,
      onError: referralStatsRequestFailed.type,
      callback,
    }),
  );
};
export const getDashboard = createSelector(
  (state) => state.entities.dashboard,
  (dashboard) => dashboard,
);
