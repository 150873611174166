import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "boardGame",
  initialState: {
    boardGameCard: [],
    boardGameColorMap: [],
    boardGameTournament: [],
    boardGameSpinReward: [],
    boardGameSlotReward: [],
    boardGameConfig: [],
    coinRewardConfig: [],
    diceRewardConfig: [],
    boardGameBGgames: [],
    loading: false,
    totalCount: null,
    filteredCount: null,
    lastFetch: null,
  },
  reducers: {
    boardGameCardRequested: (misc, action) => {
      misc.loading = true;
    },
    boardGameCardReceived: (misc, action) => {
      misc.boardGameCard = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    boardGameCardRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    boardGameColorMapRequested: (misc, action) => {
      misc.loading = true;
    },
    boardGameColorMapReceived: (misc, action) => {
      misc.boardGameColorMap = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    boardGameColorMapRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    boardGameTournamentRequested: (misc, action) => {
      misc.loading = true;
    },
    boardGameTournamentReceived: (misc, action) => {
      misc.boardGameTournament = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    boardGameTournamentRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    boardGameSpinRewardRequested: (misc, action) => {
      misc.loading = true;
    },
    boardGameSpinRewardReceived: (misc, action) => {
      misc.boardGameSpinReward = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    boardGameSpinRewardRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    boardGameConfigRequested: (misc, action) => {
      misc.loading = true;
    },
    boardGameConfigReceived: (misc, action) => {
      misc.boardGameConfig = action.payload.data;
      misc.boardGameBGgames = action.payload.bgGame;

      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    boardGameConfigRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    coinRewardConfigRequested: (misc, action) => {
      misc.loading = true;
    },
    coinRewardConfigReceived: (misc, action) => {
      misc.coinRewardConfig = action.payload.data;

      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    coinRewardConfigRequestFailed: (misc, action) => {
      misc.loading = false;
    },

    diceRewardConfigRequested: (misc, action) => {
      misc.loading = true;
    },
    diceRewardConfigReceived: (misc, action) => {
      misc.diceRewardConfig = action.payload.data;

      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    diceRewardConfigRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    boardGameSlotRewardRequested: (misc, action) => {
      misc.loading = true;
    },
    boardGameSlotRewardReceived: (misc, action) => {
      misc.boardGameSlotReward = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    boardGameSlotRewardRequestFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  boardGameCardRequested,
  boardGameCardReceived,
  boardGameCardRequestFailed,
  boardGameColorMapRequested,
  boardGameColorMapReceived,
  boardGameColorMapRequestFailed,
  boardGameTournamentRequested,
  boardGameTournamentReceived,
  boardGameTournamentRequestFailed,
  boardGameSpinRewardRequested,
  boardGameSpinRewardReceived,
  boardGameSpinRewardRequestFailed,
  boardGameConfigRequested,
  boardGameConfigReceived,
  boardGameConfigRequestFailed,
  coinRewardConfigRequested,
  coinRewardConfigReceived,
  coinRewardConfigRequestFailed,
  diceRewardConfigRequested,
  diceRewardConfigReceived,
  diceRewardConfigRequestFailed,
  boardGameSlotRewardRequested,
  boardGameSlotRewardReceived,
  boardGameSlotRewardRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadBoardGameCard = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameCard",
      params,
      onStart: boardGameCardRequested.type,
      onSuccess: boardGameCardReceived.type,
      onError: boardGameCardRequestFailed.type,
      callback,
    }),
  );
};
export const addBoardGameCard = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameCard",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateBoardGameCard = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameCard/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteBoardGameCard = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameCard/" + id,
      method: "DELETE",
      callback,
    }),
  );
};

// Color Map CRUD
export const loadBoardGameColorMap = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameColorMap",
      params,
      onStart: boardGameColorMapRequested.type,
      onSuccess: boardGameColorMapReceived.type,
      onError: boardGameColorMapRequestFailed.type,
      callback,
    }),
  );
};
export const addBoardGameColorMap = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameColorMap",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateBoardGameColorMap = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameColorMap/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteBoardGameColorMap = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameColorMap/" + id,
      method: "DELETE",
      callback,
    }),
  );
};
// Tournament CRUD
export const loadBoardGameTournament = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameTournament",
      params,
      onStart: boardGameTournamentRequested.type,
      onSuccess: boardGameTournamentReceived.type,
      onError: boardGameTournamentRequestFailed.type,
      callback,
    }),
  );
};
export const addBoardGameTournament = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameTournament",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateBoardGameTournament = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameTournament/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteBoardGameTournament = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameTournament/" + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const loadBoardGameSpinReward = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameSpinReward",
      params,
      onStart: boardGameSpinRewardRequested.type,
      onSuccess: boardGameSpinRewardReceived.type,
      onError: boardGameSpinRewardRequestFailed.type,
      callback,
    }),
  );
};
export const updateBoardGameSpinReward = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameSpinReward/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};

export const loadBoardGameSlotReward = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameSlotReward",
      params,
      onStart: boardGameSlotRewardRequested.type,
      onSuccess: boardGameSlotRewardReceived.type,
      onError: boardGameSlotRewardRequestFailed.type,
      callback,
    }),
  );
};
export const updateBoardGameSlotReward = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameSlotReward/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const addBoardGameSlotReward = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameSlotReward/",
      method: "POST",
      data,
      callback,
    }),
  );
};

// Config CRUD
export const loadBoardGameConfig = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameColorMap/getGameConfig",
      params,
      onStart: boardGameConfigRequested.type,
      onSuccess: boardGameConfigReceived.type,
      onError: boardGameConfigRequestFailed.type,
      callback,
    }),
  );
};
export const addBoardGameConfig = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameColorMap/saveGameConfig",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadCoinRewardConfig = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameColorMap/getCoinConfig",
      onStart: coinRewardConfigRequested.type,
      onSuccess: coinRewardConfigReceived.type,
      onError: coinRewardConfigRequestFailed.type,
      callback,
    }),
  );
};
export const addCoinRewardConfig = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameColorMap/saveCoinConfig",
      method: "POST",
      data,
      callback,
    }),
  );
};

export const loadDiceRewardConfig = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameColorMap/getDiceConfig",
      onStart: diceRewardConfigRequested.type,
      onSuccess: diceRewardConfigReceived.type,
      onError: diceRewardConfigRequestFailed.type,
      callback,
    }),
  );
};
export const addDiceRewardConfig = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "boardGameColorMap/saveDiceConfig",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const getBoardGame = createSelector(
  (state) => state.entities.boardGame,
  (boardGame) => boardGame,
);
