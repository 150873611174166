import React from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import { getUsers, updateProfilePicture, updateUser, updateUserReceived, updateUserImageReceived } from "store/users";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Joi from "joi-browser";
import { fetchNFT, ethToUSD } from "utils/externalApiCaller";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import Amplify, { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import plusIcon from "include/images/plus-1.svg";
import ethIcon from "include/images/eth-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";

//  Components
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});
class EditUser extends Form {
  state = {
    loading: false,
    data: {
      userName: "",
      description: "",
      twitter: "",
      website: "",
      instagram: "",
      email: "",
      image: null,
    },
    errors: {},
    imageChange: false,
    loadingImages: false,
    loadingReward: false,
    showTooltip: false,
    showFields: false,
    showNFTFields: false,
    loadingNFT: false,
    ethVPrice: "",
    loadingEth: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
    }
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      const user = this.props.user;
      this.setState({
        data: {
          ...this.state.data,
          email: user.email,
          description: user.description,
          image: user.image,
          instagram: user.instagram,
          twitter: user.twitter,
          userName: user.userName,
          website: user.website,
        },
      });
    }
  };
  doSubmit = (e) => {
    this.setState({ loadingReward: true });
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    if (data.userName && data.userName.length < 4) {
      errors.userName = "Username cannot be shorter than 4 characters.";
      return this.setState({ errors, loadingReward: false });
    }
    const payLoad = {
      email: data.email,
      description: data.description,
      instagram: data.instagram,
      twitter: data.twitter,
      userName: data.userName,
      website: data.website,
    };

    var params = _.pickBy(payLoad, function (value, key) {
      return !(value === undefined || value === "" || value === null || value === {});
    });
    this.props.updateUser(params, this.props.user.id, (rewardResponse) => {
      this.setState({
        loadingReward: false,
      });
      if (rewardResponse.status === 200) {
        toast(<AlertSuccess message="Information Saved" />, {
          containerId: 1,
        });
        this.props.updateUserReceived({ id: this.props.user.id, data: { ...params } });
        // this.props.loadData();
        this.clearData();
        this.props.toggleModal();
      } else {
        toast(<AlertError message={rewardResponse.data && rewardResponse.data.message} />, {
          containerId: 1,
        });
      }
    });
  };
  handleImageChange = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    this.setState({ loadingImages: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ loadingImages: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ loadingImages: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.props.updateProfilePicture({ image: "public/" + result.key }, this.props.user.id, (resImage) => {
          if (resImage.status === 200) {
            this.props.updateUserImageReceived({ id: this.props.user.id, image: resImage.data.data.image });
          }
          data.image = { medium: "public/" + result.key };
          this.setState({
            errors,
            data,
            loadingImages: false,
          });
        });
      });
    }
    this.setState({ data });
    // e.target.value = null;
  };
  handleAvatarChange = (e, image) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    this.setState({ loadingImages: true });
    this.props.updateProfilePicture({ image }, this.props.user.id, (resImage) => {
      if (resImage.status === 200) {
        this.props.updateUserImageReceived({ id: this.props.user.id, image: resImage.data.data.image });
      }
      data.image = { medium: image };
      this.setState({
        errors,
        data,
        loadingImages: false,
      });
    });
    this.setState({ data });
  };
  onChange = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    data[e.target.name] = e.target.value;
    if (e.target.name === "userName") {
      if (e.target.value.length > 0 && e.target.value.length < 4) {
        errors.userName = "Username must be of  minimum 4 characters and maximum 15 characters.";
      } else {
        delete errors.userName;
      }
    }

    this.setState({ data, errors });
  };
  schema = {
    description: Joi.string().allow(""),
    twitter: Joi.string().allow(""),
    email: Joi.string().allow(""),
    instagram: Joi.string().allow(""),
    website: Joi.string().allow(""),
    userName: Joi.string().allow(""),

    image: Joi.object().allow(null),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        userName: "",
        description: "",
        twitter: "",
        website: "",
        instagram: "",
        email: "",
        image: null,
      },
      errors: {},
      loadingImages: false,
      loadingReward: false,
      showTooltip: false,
      showFields: false,
      showNFTFields: false,
      loadingNFT: false,
      ethVPrice: "",
    });
  };
  render() {
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel"}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>Edit User</h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={`new-form-group ${this.state.errors.userName && "has-error"}`}>
            <label className="form-label">Username</label>
            <input
              name="userName"
              id="userName"
              maxLength={15}
              className={this.state.errors.userName ? "form-control error" : "form-control"}
              onChange={this.onChange}
              value={this.state.data.userName}
            />
            {this.state.errors.userName && (
              <div className="invalid-feedback">
                Username must be of minimum 4 characters and maximum 15 characters.
              </div>
            )}
          </div>{" "}
          <div className="new-form-group">{this.renderInput("email", "Email")}</div>
          <div className="new-form-group">
            {" "}
            <div class="d-flex align-items-center justify-content-between">
              <label>Description</label>{" "}
              <div class="label-count" id="the-count">
                <span id="current">
                  {this.state.data && this.state.data.description ? this.state.data.description.length : 0}
                </span>
                <span id="maximum">/ 160</span>
              </div>
            </div>
            <textarea
              name="description"
              id="description"
              maxLength={160}
              className={this.state.errors.description ? "form-control error" : "form-control"}
              multiline
              value={this.state.data.description}
              onChange={this.onChange}
            />
          </div>
          <div className="new-form-group">{this.renderInput("twitter", "Twitter")}</div>
          <div className="new-form-group">{this.renderInput("instagram", "Instagram")}</div>
          <div className="new-form-group">{this.renderInput("website", "Discord")}</div>
          <div className="kt-upload-row d-flex flex-wrap align-items-start">
            <div
              className={`kt-upload-outer ${
                !this.state.data.image ? "placeholder-uploaded-img" : " position-relative"
              } ${this.state.errors.image && "border-error"}`}
            >
              <div className="kt-upload-img">
                <img
                  src={this.state.data.image ? process.env.REACT_APP_CDN + this.state.data.image.medium : plusIcon}
                  alt=""
                />
              </div>{" "}
              {this.state.data.image && (
                <div
                  className="delete-icon d-flex align-items-center justify-content-center"
                  onClick={(e) => {
                    const data = this.state.data;
                    data.image = null;
                    this.setState({ data });
                  }}
                >
                  <img src={deleteIcon} />
                </div>
              )}
            </div>
            <div className="kt-file-input">
              <label htmlFor="formFile" className="kt-label">
                {this.state.data.image ? " Change Image" : "+ Upload an Image"}
              </label>
              <input className="form-control" type="file" id="formFile" onChange={this.handleImageChange} />
            </div>
          </div>
          <label className="mt-3">Choose an Avatar</label>
          <div className="kt-upload-row mt-1 d-flex flex-wrap align-items-start">
            {Array(140)
              .fill(0)
              .map((a, i) => (
                <div
                  className={`kt-upload-outer-1 position-relative pointer
              `}
                  onClick={(e) => {
                    this.handleAvatarChange(e, `Avatars/${i + 1}.png`);
                  }}
                >
                  <div className="kt-upload-img-1">
                    <img src={process.env.REACT_APP_CDN + `Avatars/${i + 1}.png`} alt="" />
                  </div>
                </div>
              ))}
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loadingReward || this.state.loadingImages}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data, id, callback) => dispatch(updateUser(data, id, callback)),
  updateProfilePicture: (data, id, callback) => dispatch(updateProfilePicture(data, id, callback)),
  updateUserReceived: (payLoad) => dispatch(updateUserReceived(payLoad)),
  updateUserImageReceived: (payLoad) => dispatch(updateUserImageReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getUsers: getUsers(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditUser));
