import React, { createRef } from "react";
import { toast } from "react-toastify";
import { Offcanvas, Form } from "react-bootstrap";
import { getSettings, loadCountryDetails, updateStateStatus, updateStateStatusReceived } from "store/settings";
import moment from "moment/moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _, { before } from "lodash";
import { Popover, OverlayTrigger } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import Joi from "joi-browser";
import { convertDate, formatDate } from "utils/dateConversion";

//  Images
import emptyIcon from "include/images/nrf.svg";
import closeIcon from "include/images/dark-close-icon.svg";

//  Components
import Loader from "common/loader";
import Form2 from "common/form/form";

class ListingFlyer extends Form2 {
  ref = createRef(null);
  state = {
    data: { keyword: "" },
    loading: false,
    loadingWinner: false,
    winner: "",
    loadingFilters: false,
    showFilters: false,
    appliedFilters: {},
    errors: {},
    loadingStatus: false,
    loadingReset: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.searchFilters();
    }
    if (!this.props.getSettings.loading && this.props.getSettings.loading !== prevProps.getSettings.loading)
      this.ref.current.complete();

    if (this.props.getSettings.loading && this.props.getSettings.loading !== prevProps.getSettings.loading)
      this.ref.current.continuousStart();
  };
  searchFilters = () => {
    this.setState({ loading: true });
    const { country } = this.props;
    this.props.loadCountryDetails(country?.countryCode, (res) => {
      this.setState({ loading: false });
    });
  };
  updateStatus = (e, state) => {
    if (e) e.preventDefault();
    const { country } = this.props;
    this.setState({ loadingStatus: true });
    this.props.updateStateStatus(
      { countryCode: country.countryCode, state: state.name, isAllowed: state.isAllowed ? 0 : 1 },
      (res) => {
        this.props.updateStateStatusReceived({ name: state.name });
        this.setState({ loadingStatus: false });
      },
    );
  };
  render() {
    const { countryDetails } = this.props.getSettings;
    const { country } = this.props;
    return (
      <>
        <Loader loaderRef={this.ref} />
        <Offcanvas
          className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2"}
          show={this.props.showModal}
          onHide={this.props.toggleModal}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header>
            <h5>{country?.name}</h5>
            <div className="d-flex flex-row align-items-center justify-content-between ">
              <div
                className="btn_close pointer"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={this.props.toggleModal}
              >
                <img src={closeIcon} alt="" />
              </div>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="">
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {countryDetails?.states?.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>State</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {countryDetails?.states?.map((state, i) => {
                            return (
                              <tr>
                                <td className="camelCase">{state.name}</td>
                                <td>
                                  <Form.Switch
                                    type="switch"
                                    name="allow-switch"
                                    id={`allow-switch-${i}`}
                                    className={`pointer review-switch review-switch2 ${
                                      this.state.loadingStatus && "pointer-none"
                                    } ${state.isAllowed === 1 ? "toggle-is-on" : "toggle-is-off"}`}
                                    checked={state.isAllowed === 1 ? true : false}
                                    label={state.isAllowed ? "Allowed" : "Blocked"}
                                    onClick={(e) => {
                                      this.updateStatus(e, state);
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadCountryDetails: (code, callback) => dispatch(loadCountryDetails(code, callback)),
  updateStateStatus: (data, callback) => dispatch(updateStateStatus(data, callback)),
  updateStateStatusReceived: (payLoad) => dispatch(updateStateStatusReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListingFlyer));
