import Joi from "joi-browser";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";
import plusIcon from "include/images/plus-1.svg";
//  Components
import Amplify, { Auth, Storage } from "aws-amplify";
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import SelectSearch from "common/form/selectSearch";
import NextButton from "common/form/submitButton";
import { updateBoardGameSlotReward, addBoardGameSlotReward } from "store/boardGame";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});
class AddFlyer extends Form {
  fileInputRef = React.createRef(null);
  initialState = {
    data: {
      value: 1,
      rewardType: {},
      reels: [
        { text: "", image: null },
        { text: "", image: null },
        { text: "", image: null },
      ],
      percentage: 0,
    },
    id: "",
    edit: false,
    loading: false,
    imageChange: false,
    loadingImage: false,
    errors: {},
  };
  state = { ...this.initialState };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal && this.props.edit) {
        const data = { ...this.state.data };
        data.value = this.props.edit?.rewardValue;
        data.rewardType = this.rewardOptions.find((reward) => reward?.value === this.props.edit?.rewardType);
        data.percentage = this.props.edit?.percentage;
        data.reels = this.props.edit.reels.map((step, index) => {
          const newStep = {
            text: step,
            image: this.props.edit.reelsImage?.[index],
          };
          return newStep;
        });
        this.setState({ data, edit: true, id: this.props.edit?._id });
      }
    }
  };
  doSubmit = (e) => {
    this.setState({ loading: true });
    const data = { ...this.state.data };
    const payLoad = {
      rewardValue: parseInt(data.value),
      rewardType: data.rewardType?.value,
      percentage: data.percentage,
      reels: data.reels?.map((reel) => reel.text),
      reelsImage: data.reels?.map((reel) => reel.image),
    };

    // if (this.state.imageChange) payLoad.image = data.image; //TODO: image change condition

    if (this.props.edit) {
      this.props.updateBoardGameSlotReward(payLoad, this.state.id, (res) => {
        this.setState({ loading: false });
        if (res.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.loadData();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={res.data && res.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      this.props.addBoardGameSlotReward(payLoad, (res) => {
        this.setState({ loading: false });
        if (res.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.loadData();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={res.data && res.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };
  schema = {
    value: Joi.number().required(),
    rewardType: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.string().required(),
    }),
    percentage: Joi.number().required(),
    reels: Joi.array().items(
      Joi.object().keys({
        text: Joi.string().required(),
        image: Joi.string().required(),
      }),
    ),
    // image: Joi.string().allow(""),
  };

  handleImageChange = (e, index) => {
    this.setState({ loadingImage: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ loadingImage: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ loadingImage: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        const data = structuredClone(this.state.data);
        const newStep = data.reels[index];
        newStep.image = "public/" + result.key;
        data.reels[index] = newStep;
        this.setState({
          data,
          [e.target.name + "Change"]: true,
          loadingImage: false,
        });
      });
    }
    e.target.value = null;
  };
  clearData = () => {
    this.setState({
      ...this.initialState,
    });
  };
  changeOne = (name, value, index) => {
    const data = structuredClone(this.state.data);
    const errors = structuredClone(this.state.errors);
    const newStep = data.reels[index];
    newStep[name] = value;
    data.reels[index] = newStep;
    this.setState({ data, errors });
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  positionOptions = [
    { name: "Top Center", value: "top-center" },
    { name: "Top Left", value: "top-left" },
    { name: "Top Right", value: "top-right" },
    { name: "Bottom Center", value: "bottom-center" },
    { name: "Bottom Left", value: "bottom-left" },
    { name: "Bottom Right", value: "bottom-right" },
  ];
  spinnerLocationOptions = [
    { name: "1", value: 1 },
    { name: "2", value: 2 },
  ];
  angleOptions = [
    { name: "0", value: 0 },
    { name: "60", value: 60 },
    { name: "120", value: 120 },
    { name: "180", value: 180 },
    { name: "240", value: 240 },
    { name: "300", value: 300 },
  ];
  rewardOptions = [{ name: "Dice", value: "dice" }];
  render() {
    console.log("log:", this.state.data, this.state.errors);
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>{this.props.edit ? "Edit" : "Add a"} Slot Reward</h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <table className="table large-table flyer-table hide-date-label">
                <tbody>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Value</td>
                    <td>{this.renderInput("value", "", "", "number")}</td>
                    <td></td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Reward Type</td>
                    <td>
                      <SelectSearch
                        name="rewardType"
                        options={this.rewardOptions}
                        label="Reward Type"
                        value={this.state.data.rewardType}
                        error={this.state.errors.rewardType}
                        onChange={this.onChange}
                      />
                    </td>
                    <td></td>
                  </tr>{" "}
                  <tr className="flyer-table">
                    <td className="vertical-middle">Percentage</td>
                    <td>{this.renderInput("percentage", "", "", "number")}</td>
                    <td></td>
                  </tr>{" "}
                  <tr className="">
                    <td>
                      <strong className="px-0">Reels</strong>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  {this.state.data.reels?.map((reel, index) => (
                    <tr className="flyer-table">
                      <td className="vertical-middle">{index + 1}</td>
                      <td>
                        <input
                          name="text"
                          value={reel.text}
                          className={`form-control`}
                          onChange={(e) => {
                            this.changeOne(e.target.name, e.target.value, index);
                          }}
                        />
                      </td>{" "}
                      <td className="d-flex flex-wrap align-items-start">
                        <div>
                          <div
                            className={`kt-upload-outer-small ${this.state.imageLoading ? "pointer-none" : "pointer"} ${
                              !reel.image ? "placeholder-uploaded-img" : " position-relative"
                            } ${this.state.errors?.image && "border-error"}`}
                          >
                            <div className="kt-upload-img">
                              <img src={reel.image ? process.env.REACT_APP_CDN + reel.image : plusIcon} alt="" />
                              <input
                                className="file-d-input"
                                type="file"
                                name="image"
                                accept="image/*"
                                onChange={(e) => {
                                  this.handleImageChange(e, index);
                                }}
                              />
                            </div>
                            {reel?.image && (
                              <div
                                className="delete-icon d-flex align-items-center justify-content-center"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const data = { ...this.state.data };
                                  let newReel = data.reels?.[index];
                                  newReel.image = null;
                                  data.reels[index] = newReel;
                                  this.setState({ data });
                                }}
                              >
                                <img src={deleteIcon} alt="delete" />
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loading || this.state.loadingImage}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateBoardGameSlotReward: (data, id, callback) => dispatch(updateBoardGameSlotReward(data, id, callback)),
  addBoardGameSlotReward: (data, callback) => dispatch(addBoardGameSlotReward(data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(AddFlyer));
