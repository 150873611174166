import { useCallback, useEffect, useRef, useState } from "react";
import { Dropdown, Offcanvas } from "react-bootstrap";

import moment from "moment/moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import loadingIcon from "include/images/loader-grey.svg";
import emptyIcon from "include/images/nrf.svg";
import sortIcon from "include/images/sort-icon.svg";
import userIcon from "include/images/user.png";
//  Components
import { Skeleton } from "@mui/material";
import { getLootbox, loadLootboxWinners, transferLootPrize, updateTransferLootboxWinners } from "store/lootbox";
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Loader from "common/loader";

const WinnersListingFlyer = ({
  getLootbox,
  showModal,
  toggleModal,
  loadLootboxWinners,
  transferLootPrize,
  updateTransferLootboxWinners,
  location,
}) => {
  const ref = useRef();

  const sortTypes = [
    { name: "Date - Asc", sorting: { sort: "createdAt", order: "asc" } },
    { name: "Date - Desc", sorting: { sort: "createdAt", order: "desc" } },
    { name: "Transaction Status - Pending, Completed, Failed", sorting: { sort: "transactionStatus", order: "asc" } },
    { name: "Transaction Status - Failed, Completed, Pending", sorting: { sort: "transactionStatus", order: "desc" } },
  ];
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [scrollRef, setScrollRef] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingTransferPrize, setLoadingTransferPrize] = useState(false);
  const [selectedSorting, setSelectedSorting] = useState({ sort: "transactionStatus", order: "asc" });
  const { lootboxWinners, totalWinners } = getLootbox;

  //load data with the provided page, sort and order
  const loadData = useCallback(
    (page, sort, order) => {
      if (page === 1) setLoading(true);
      else setLoadingMore(true);
      setPage(page);
      const params = { page, sort, order };
      loadLootboxWinners(location?.state?.lootbox?._id, params, page === 1, () =>
        page === 1 ? setLoading(false) : setLoadingMore(false),
      );
    },
    [loadLootboxWinners, location?.state?.lootbox?._id],
  );

  const handleLoadMore = useCallback(
    (entries) => {
      const target = entries[0];
      if (target?.isIntersecting) {
        if (!loadingMore && lootboxWinners?.length < totalWinners) {
          loadData(page + 1, selectedSorting?.sort, selectedSorting?.order);
        }
      }
    },
    [loadingMore, page, lootboxWinners?.length, totalWinners, loadData, selectedSorting],
  );
  //Intersection observer for infinite scroll
  useEffect(() => {
    const options = {
      threshold: 0.2,
    };
    // Observer API
    const observer = new IntersectionObserver(handleLoadMore, options);

    if (observer && scrollRef) {
      observer.observe(scrollRef);
    }
    return () => {
      observer.disconnect();
    };
  }, [handleLoadMore, scrollRef]);

  useEffect(() => {
    if (showModal) {
      loadData(1, "transactionStatus", "asc");
    }
  }, [showModal, loadData]);

  const lootbox = location?.state?.lootbox;
  const toggleTransfer = (e, id) => {
    e.preventDefault();
    ref?.current?.continuousStart();
    setLoadingTransferPrize(true);
    transferLootPrize(id, (res) => {
      if (res.status === 200) {
        updateTransferLootboxWinners(id);
        toast(<AlertSuccess message="Transfer status updated" />, {
          containerId: 1,
        });
      } else {
        toast(<AlertError message={res.data && res.data.message} />, {
          containerId: 1,
        });
      }
      ref?.current?.complete();
      setLoadingTransferPrize(false);
    });
  };
  return (
    <>
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2"}
        show={showModal}
        onHide={toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Loader loaderRef={ref} />
        <Offcanvas.Header className="offCanvas-header-2">
          <div>
            {loading ? (
              <>
                <Skeleton width={200} height={30} />
              </>
            ) : (
              lootbox && (
                <div className="td-img-group d-flex align-items-start m-top-2">
                  <div className="round-img m-right-2">
                    {lootbox.image ? (
                      <img src={process.env.REACT_APP_CDN + lootbox?.image?.small} alt="" />
                    ) : (
                      <img src={userIcon} alt="" />
                    )}
                  </div>
                  {lootbox?.title} Winners
                </div>
              )
            )}
          </div>
          <div className="filter-menu-box d-flex align-items-center ms-auto">
            <div className="fmb-box">
              <Dropdown className="filter-link-box custom-dropdown dropdown-lg position-relative">
                <Dropdown.Toggle
                  className="dropdown-toggle custom-dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={sortIcon} alt="sort icon" />
                  <span>Sort</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                  {sortTypes?.map(({ name, sorting }, index) => (
                    <>
                      <Dropdown.Item
                        key={index}
                        className={`dropdown-item ${
                          selectedSorting?.order === sorting?.order &&
                          selectedSorting?.sort === sorting?.sort &&
                          "orange-text"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedSorting(sorting);
                          loadData(1, sorting?.sort, sorting?.order);
                        }}
                      >
                        {name}
                      </Dropdown.Item>
                      {index === 1 && (
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                      )}
                    </>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center ">
            <div
              className="btn_close pointer ml-40"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={toggleModal}
            >
              <img src={closeIcon} alt="" />
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {" "}
          <div className="">
            <div className="fwc-body">
              {loading ? (
                <>
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                  <Skeleton height={50} /> <Skeleton height={50} />
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                  <Skeleton height={50} /> <Skeleton height={50} />
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                  <Skeleton height={50} />
                </>
              ) : (
                <div className="table-responsive">
                  {lootboxWinners.length === 0 ? (
                    <div className="fwc-body">
                      <div className="no-record-found-container">
                        <div className="nfr-box">
                          <img src={emptyIcon} alt="No Record Found" />
                          <div className="nrf-text">
                            <h5>No Records Found!</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <table className="table large-table">
                      <thead>
                        <tr>
                          <th>Player</th>
                          <th>Prize </th>
                          <th>Value </th>
                          <th>Fulfillment </th>
                          <th>Transaction Status</th>
                          <th>Played On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lootboxWinners?.length > 0 &&
                          lootboxWinners.map((winner, index) => (
                            <tr
                              key={winner?._id}
                              ref={(ref) => {
                                index === lootboxWinners?.length - 1 &&
                                  lootboxWinners?.length < totalWinners &&
                                  setScrollRef(ref);
                              }}
                            >
                              <td>
                                <span>
                                  <div className="td-img-group d-flex align-items-start ">
                                    <div className="td-img round-img m-right-2">
                                      {winner?.userImage ? (
                                        <img src={process.env.REACT_APP_CDN + winner?.userImage?.small} alt="" />
                                      ) : (
                                        <img src={userIcon} alt="" />
                                      )}
                                    </div>
                                    {winner?.userName}
                                    <br />
                                    {winner?.email}
                                  </div>
                                </span>
                              </td>

                              <td>{winner?.lootBoxPrizeDistribution?.title}</td>
                              <td>
                                {winner?.lootBoxPrizeDistribution?.value}{" "}
                                {
                                  ["ARCADE", "TICKETS", "LOOT", "USDT"][
                                    winner?.lootBoxPrizeDistribution?.amountType - 1
                                  ]
                                }
                              </td>
                              <td>{winner?.lootBoxPrizeDistribution?.fulfillment === 1 ? "Automatic" : "Manual"}</td>
                              <td className="ust-tag">
                                <Dropdown className="custom-dropdown">
                                  <Dropdown.Toggle
                                    id="tdDropdownOne"
                                    className="custom-dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    disabled={winner?.transactionStatus === 2 || loadingTransferPrize}
                                  >
                                    <span
                                      className={`w-100 text-center d-block ${
                                        winner?.transactionStatus === 1
                                          ? "inactive"
                                          : winner?.transactionStatus === 2
                                          ? "active"
                                          : "suspended"
                                      }`}
                                    >
                                      {!isNaN(winner?.transactionStatus)
                                        ? ["PENDING", "COMPLETED", "CANCELLED", "FAILED"][winner?.transactionStatus - 1]
                                        : winner?.transactionStatus}
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                    <Dropdown.Item
                                      className="dropdown-item "
                                      onClick={(e) => toggleTransfer(e, winner?._id)}
                                    >
                                      COMPLETED
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                <div className="td-text-box small-text">
                                  {moment(winner?.createdAt).format("MM/DD/YYYY")} <br />
                                  <small> {moment(winner?.createdAt).format("hh:mm a")}</small>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
            </div>
          </div>
          {loadingMore && (
            <div className="w-100 d-flex justify-content-center load-more">
              <img src={loadingIcon} className="fa-spin" alt="" />
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadLootboxWinners: (id, params, fresh, callback) => dispatch(loadLootboxWinners(id, params, fresh, callback)),
  transferLootPrize: (id, callback) => dispatch(transferLootPrize(id, callback)),
  updateTransferLootboxWinners: (id) => dispatch(updateTransferLootboxWinners(id)),
});
const mapStateToProps = (state) => ({
  getLootbox: getLootbox(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WinnersListingFlyer));
