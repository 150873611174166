import React from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import { getUsers, updateProfilePicture, updateUser, updateUserReceived, updateUserImageReceived } from "store/users";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Joi from "joi-browser";
import { fetchNFT, ethToUSD } from "utils/externalApiCaller";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import Amplify, { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import plusIcon from "include/images/plus-1.svg";
import ethIcon from "include/images/eth-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";

//  Components
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";
import { loadBGGames, updateBGGame } from "store/games";
class UpdateGameData extends Form {
  state = {
    loading: false,
    data: {
      gameData: "",
    },
    errors: {},
    imageChange: false,
    loadingImages: false,
    loadingReward: false,
    showTooltip: false,
    showFields: false,
    showNFTFields: false,
    loadingNFT: false,
    ethVPrice: "",
    loadingEth: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
    }
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      const game = this.props.game;
      this.setState({
        data: {
          ...this.state.data,
          gameData: game.gameData,
        },
      });
    }
  };
  doSubmit = (e) => {
    this.setState({ loadingReward: true });
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };

    const payLoad = {
      gameData: data.gameData,
    };
    const game = this.props.game;
    this.props.updateBGGame(payLoad, game.id, (gameRes) => {
      this.setState({ loadingBGGame: false });
      if (gameRes.status === 200) {
        this.props.loadBGGames({}, null);
        toast(<AlertSuccess message="Information Saved" />, {
          containerId: 1,
        });
        setTimeout(() => {
          this.props.toggleModal();
        }, 1000);
      } else {
        toast(<AlertError message={gameRes.data && gameRes.data.message} />, {
          containerId: 1,
        });
      }
    });
  };

  onChange = (e) => {
    console.log(e);
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    data[e.target.name] = e.target.value;

    this.setState({ data, errors });
  };
  schema = {
    gameData: Joi.string().allow(""),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        gameData: "",
      },
      errors: {},
      loadingImages: false,
      loadingReward: false,
      showTooltip: false,
      showFields: false,
      showNFTFields: false,
      loadingNFT: false,
      ethVPrice: "",
    });
  };
  render() {
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel"}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>Update Game Data Function</h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="new-form-group">
            <textarea
              style={{ height: "660px" }}
              rows="40"
              name="gameData"
              id="gameData"
              className={this.state.errors.gameData ? "form-control error" : "form-control"}
              multiline
              value={this.state.data.gameData}
              onChange={this.onChange}
            />
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loadingReward || this.state.loadingImages}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadBGGames: (params, callback) => dispatch(loadBGGames(params, callback)),
  updateBGGame: (data, id, callback) => dispatch(updateBGGame(data, id, callback)),
});
const mapStateToProps = (state) => ({
  getUsers: getUsers(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateGameData));
