import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  getSweepstake,
  loadContract,
  deleteContract,
  deleteContractReceived,
  updateContract,
  updateContractReceived,
  checkContractStatus,
  cancelContract,
} from "store/sweepstakes";
import { toast } from "react-toastify";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { Dropdown, Form } from "react-bootstrap";
import _, { isBoolean } from "lodash";
import moment from "moment";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import plusIcon from "include/images/plus.svg";
import loadingIcon from "include/images/loader-grey.svg";

//  Components
import Header from "common/header";
import SideBar from "common/sidebar";
import Loader from "common/loader";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";
import DeleteModal from "common/alert/alertModal";
import AddContract from "./addContract";
import { truncateAddress } from "utils/misc";
import ValidateFlyer from "./validateFlyer";

class ContractListing extends Component {
  ref = createRef(null);
  state = {
    data: {
      messages: [],
    },
    id: "",
    // Loading states
    loadingOptions: false,
    loadingMain: false,
    loadingDelete: false,
    loading: false,
    loadingStatus: false,

    showDeleteModal: false,
    showAddModal: false,
    deleteId: "",
    statusChangeId: "",
    editData: null,
    showHistory: false,
    status: "",
    objectId: "",
    object: null,
    page: 1,
    statusInd: "",
    contractId: "",
    loadingContractStatus: [],
    allContractHasIssue: [],
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getSweepstake.loading && this.props.getSweepstake.loading !== prevProps.getSweepstake.loading)
      this.ref.current.complete();

    if (this.props.getSweepstake.loading && this.props.getSweepstake.loading !== prevProps.getSweepstake.loading)
      this.ref.current.continuousStart();
    if (this.state.showAddModal !== prevState.showAddModal && !this.state.showAddModal) {
      setTimeout(() => {
        this.setState({ editData: null });
      }, 500);
    }
  };
  componentDidMount = () => {
    this.setState({ loadingMain: true });
    this.props.loadContract({}, (res) => {
      this.setState({
        loadingMain: false,
      });
    });
  };
  toggleHistoryFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showHistory: !this.state.showHistory });
  };
  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };
  handleDelete = (e) => {
    this.setState({ loadingDelete: true });
    this.props.cancelContract(this.state.deleteId, (contractRes) => {
      if (contractRes.status === 200) {
        toast(<AlertSuccess message="Canceled!" />, {
          containerId: 1,
        });
        const index = this.props.getSweepstake.contracts?.findIndex((contract) => contract?.id === this.state.deleteId);
        this.props.loadContract({});
        const status = [...this.state.allContractHasIssue];
        status[index] = null;

        this.setState({ allContractHasIssue: status });
        this.alertModalToggle();
        this.setState({ loadingDelete: false });
      } else {
        toast(<AlertError message={contractRes.data && contractRes.data.message} />, {
          containerId: 1,
        });
        this.alertModalToggle();
        this.setState({ loadingDelete: false });
      }
    });
  };
  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  changeStatus = (e) => {
    this.setState({ loadingStatus: true });
    const payLoad = { status: this.state.status };
    this.props.updateContract(this.state.statusChangeId, payLoad, (contractRes) => {
      this.setState({
        loadingStatus: false,
      });
      if (contractRes.status === 200) {
        this.props.updateContractReceived(contractRes.data.data);
      } else {
        toast(<AlertError message={contractRes.data && contractRes.data.message} />, {
          containerId: 1,
        });
      }
    });
  };
  validate = (e, id, index) => {
    e.preventDefault();
    const loadingContractStatus = [...this.state.loadingContractStatus];
    loadingContractStatus[index] = true;
    this.setState({ loadingContractStatus });

    this.props.checkContractStatus(id, (res) => {
      if (res.status === 200) {
        const status = [...this.state.allContractHasIssue];
        status[index] = res.data?.data?.hasIssue;
        this.setState({ allContractHasIssue: status });
      } else {
        toast(<AlertError message={res.data && res.data.message} />, {
          containerId: 1,
        });
      }
      const loadingContractStatus = [...this.state.loadingContractStatus];
      loadingContractStatus[index] = false;
      this.setState({ loadingContractStatus });
    });
  };

  render() {
    const { contracts, filteredCount, contractStatus, contractStatusLoading } = this.props.getSweepstake;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="sweepstakes" />
          {!this.state.loadingMain && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1>Sweeps Contracts {filteredCount > 0 && `(${filteredCount})`}</h1>
                <div className="filter-menu-box d-flex align-items-center ms-auto">
                  <div className="fmb-box">
                    <Link to="#" onClick={this.toggleAddModal} className="btn btn-default btn-sm">
                      <img src={plusIcon} alt="" /> Add a Sweeps Contract
                    </Link>
                  </div>
                </div>
              </div>
              <div className="fwc-wrapper">
                <div className="fwc-body">
                  {!this.state.loading && (
                    <div className="table-responsive">
                      {contracts.length === 0 ? (
                        <div className="fwc-body">
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <table className="table large-table">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center", width: 40 }}>Actions</th>
                              <th style={{ width: "20%" }}>Title</th>
                              <th>Contract Address</th>
                              <th>Validate From Blockchain</th>
                              <th>Availability IN DB</th>
                              <th>Status</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {contracts &&
                              contracts.length > 0 &&
                              contracts.map((contract, i) => (
                                <tr>
                                  <td>
                                    <Dropdown className=" custom-dropdown">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <img src={carrentIcon} alt="" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ editData: contract }, () => {
                                              this.toggleAddModal();
                                            });
                                          }}
                                        >
                                          Edit
                                        </Dropdown.Item>{" "}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      placement="auto"
                                      overlay={
                                        <Popover className="custom-tooltip " id={contract.id + "desc"}>
                                          <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                            <h4>Description</h4>
                                            <p>{contract?.description}</p>
                                          </Popover.Body>
                                        </Popover>
                                      }
                                    >
                                      <Link
                                        to="!#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState({ editData: contract }, () => {
                                            this.toggleAddModal();
                                          });
                                        }}
                                      >
                                        {contract?.title}
                                      </Link>
                                    </OverlayTrigger>
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      placement="top"
                                      trigger={["hover"]}
                                      overlay={
                                        <Popover className="custom-tooltip " id={contract.id + "add"}>
                                          <Popover.Body className="tooltip-inner tooltip-inner-ca tooltip-inner-reason ">
                                            <h4>{contract?.contractAddress}</h4>
                                          </Popover.Body>
                                        </Popover>
                                      }
                                    >
                                      <Link
                                        to="!#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          navigator.clipboard.writeText(contract?.contractAddress);
                                          toast(<AlertSuccess message="Copied to clipboard" />, { containerId: 1 });
                                        }}
                                      >
                                        <>{truncateAddress(contract?.contractAddress)}</>
                                      </Link>
                                    </OverlayTrigger>
                                  </td>
                                  <td>
                                    <a
                                      href="#!"
                                      className="orange-text"
                                      onClick={(e) => this.validate(e, contract?.id, i)}
                                    >
                                      Validate
                                    </a>
                                    {"  "}
                                    {this.state.loadingContractStatus[i] ? (
                                      <span className=" load-more">
                                        <img src={loadingIcon} className="fa-spin" alt="" />
                                      </span>
                                    ) : (
                                      isBoolean(this.state.allContractHasIssue[i]) && (
                                        <>
                                          {this.state.allContractHasIssue[i] ? (
                                            <>
                                              <i class="fas fa-xmark red"></i> <br />
                                              <a
                                                href="#!"
                                                className="orange-text"
                                                onClick={(e) =>
                                                  this.setState({ deleteId: contract?.id }, () =>
                                                    this.alertModalToggle(e),
                                                  )
                                                }
                                              >
                                                Cancel from blockchain
                                              </a>
                                            </>
                                          ) : (
                                            <i className={`fas fa-check green`}></i>
                                          )}{" "}
                                        </>
                                      )
                                    )}
                                  </td>
                                  <td className="ust-tag">
                                    <Dropdown className=" custom-dropdown">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className={`custom-dropdown-toggle  pointer-none`}
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <span
                                          className={`w-100 text-center d-block ${
                                            contract.isAvailable ? "active" : "inactive"
                                          } ${this.state.loadingStatus && "pointer-none"}`}
                                        >
                                          {this.state.loadingStatus && this.state.statusInd === i ? (
                                            <img
                                              src={loadingIcon}
                                              alt="loading..."
                                              style={{ width: 20 }}
                                              className="fa-spin"
                                            />
                                          ) : contract?.isAvailable ? (
                                            "Available"
                                          ) : (
                                            "In use"
                                          )}
                                        </span>
                                      </Dropdown.Toggle>
                                    </Dropdown>
                                  </td>
                                  <td className="ust-tag">
                                    <Dropdown className=" custom-dropdown">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className={`custom-dropdown-toggle  ${
                                          this.state.loadingStatus && "pointer-none"
                                        }`}
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <span
                                          className={`w-100 text-center d-block ${
                                            contract.status === 3
                                              ? "suspended"
                                              : contract.status === 2
                                              ? "inactive"
                                              : "active"
                                          } ${this.state.loadingStatus && "pointer-none"}`}
                                        >
                                          {this.state.loadingStatus && this.state.statusInd === i ? (
                                            <img
                                              src={loadingIcon}
                                              alt="loading..."
                                              style={{ width: 20 }}
                                              className="fa-spin"
                                            />
                                          ) : contract.status === 2 ? (
                                            "Paused"
                                          ) : (
                                            "Active"
                                          )}
                                        </span>
                                      </Dropdown.Toggle>
                                      {/* {contract.status !== -1 && ( */}
                                      <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                        <>
                                          <Dropdown.Item
                                            className="dropdown-item "
                                            onClick={(e) => {
                                              this.setState(
                                                {
                                                  statusChangeId: contract.id,
                                                  status: contract.status === 1 ? 2 : 1,
                                                  statusInd: i,
                                                },
                                                this.changeStatus,
                                              );
                                            }}
                                          >
                                            {contract.status === 1 ? "Pause" : "Active"}
                                          </Dropdown.Item>
                                        </>
                                      </Dropdown.Menu>
                                      {/* )} */}
                                    </Dropdown>
                                  </td>
                                  <td>
                                    <div class="small-text">
                                      {moment(contract?.createdAt).format("MM/DD/YYYY")}
                                      <br /> <small> {moment(contract?.timeExpired).format("hh:mm a")}</small>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <AddContract
          showModal={this.state.showAddModal}
          toggleModal={this.toggleAddModal}
          loadData={this.props.loadContract}
          edit={this.state.editData}
        />{" "}
        <DeleteModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showDeleteModal}
          title="Alert"
          description="Are you sure you want to cancel this contract?"
          onPress={this.handleDelete}
          loading={this.state.loadingDelete}
          alertType={"Cancel Contract"}
        />
        {this.state.showAddModal && <div className="modal-backdrop show fade" onClick={this.toggleAddModal}></div>}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadContract: (params, callback) => dispatch(loadContract(params, callback)),
  cancelContract: (id, callback) => dispatch(cancelContract(id, callback)),
  deleteContractReceived: (payLoad) => dispatch(deleteContractReceived(payLoad)),
  updateContract: (id, data, callback) => dispatch(updateContract(id, data, callback)),
  updateContractReceived: (payLoad) => dispatch(updateContractReceived(payLoad)),
  checkContractStatus: (id, callback) => dispatch(checkContractStatus(id, callback)),
});
const mapStateToProps = (state) => ({
  getSweepstake: getSweepstake(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractListing));
