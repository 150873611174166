import Joi from "joi-browser";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";
import plusIcon from "include/images/plus-1.svg";
//  Components
import Amplify, { Auth, Storage } from "aws-amplify";
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import SelectSearch from "common/form/selectSearch";
import NextButton from "common/form/submitButton";
import { updateTelegramSpinReward } from "store/telegram";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});
class AddFlyer extends Form {
  fileInputRef = React.createRef(null);
  initialState = {
    data: {
      value: 0,
      rewardType: {},
      position: {},
      angle: {},
      spinnerLocation: {},
      percentage: 0,
      color: "",
      image: null,
    },
    id: "",
    edit: false,
    loading: false,
    imageChange: false,
    errors: {},
  };
  state = { ...this.initialState };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal) {
        const data = { ...this.state.data };
        data.value = this.props.edit?.value;
        data.percentage = this.props.edit?.percentage;
        data.rewardType = this.rewardOptions.find((reward) => reward?.value === this.props.edit?.rewardType);
        data.position = this.positionOptions.find((position) => position?.value === this.props.edit?.position);
        data.angle = this.angleOptions.find((angle) => angle?.value === this.props.edit?.angle);
        data.spinnerLocation = this.spinnerLocationOptions.find(
          (location) => location?.value === this.props.edit?.spinnerLocation,
        );
        data.color = this.props.edit?.color;
        data.image = this.props.edit?.image;
        this.setState({ data, edit: true, id: this.props.edit?._id });
      }
    }
  };
  doSubmit = (e) => {
    this.setState({ loading: true });
    const data = { ...this.state.data };
    const payLoad = {
      value: data.value,
      percentage: data.percentage,
      rewardType: data.rewardType?.value,
      position: data.position?.value,
      angle: data.angle?.value,
      spinnerLocation: data.spinnerLocation?.value,
      color: data.color,
    };

    if (this.state.imageChange) payLoad.image = data.image;

    this.props.updateTelegramSpinReward(payLoad, this.state.id, (res) => {
      this.setState({ loading: false });
      if (res.status === 200) {
        toast(<AlertSuccess message="Information Saved" />, {
          containerId: 1,
        });
        this.props.loadData();
        this.props.toggleModal();
      } else {
        toast(<AlertError message={res.data && res.data.message} />, {
          containerId: 1,
        });
      }
    });
  };
  schema = {
    value: Joi.number().required(),
    percentage: Joi.number().required(),
    rewardType: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.string().required(),
    }),
    position: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.string().required(),
    }),
    angle: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.number().required(),
    }),
    spinnerLocation: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.number().required(),
    }),
    color: Joi.string().required(),
    image: Joi.string().allow(""),
  };

  handleImageChange = (e) => {
    this.setState({ [e.target.name + "Loading"]: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ [e.target.name + "Loading"]: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ [e.target.name + "Loading"]: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.setState({
          data: {
            ...this.state.data,
            [e.target.name]: "public/" + result.key,
          },
          [e.target.name + "Change"]: true,
          [e.target.name + "Loading"]: false,
        });
      });
    }
    e.target.value = null;
  };
  clearData = () => {
    this.setState({
      ...this.initialState,
    });
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  positionOptions = [
    { name: "Top Center", value: "top-center" },
    { name: "Top Left", value: "top-left" },
    { name: "Top Right", value: "top-right" },
    { name: "Bottom Center", value: "bottom-center" },
    { name: "Bottom Left", value: "bottom-left" },
    { name: "Bottom Right", value: "bottom-right" },
  ];
  angleOptions = [
    { name: "0", value: 0 },
    { name: "60", value: 60 },
    { name: "120", value: 120 },
    { name: "180", value: 180 },
    { name: "240", value: 240 },
    { name: "300", value: 300 },
  ];
  spinnerLocationOptions = [
    { name: "1", value: 1 },
    { name: "2", value: 2 },
  ];
  rewardOptions = [
    { name: "USDT", value: "usdt" },
    { name: "Point", value: "point" },
  ];
  render() {
    console.log(this.state.errors);

    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>Edit Spinner Reward</h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <table className="table large-table flyer-table hide-date-label">
                <tbody>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Value</td>
                    <td>{this.renderInput("value", "", "", "number")}</td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Reward Type</td>
                    <td>
                      <SelectSearch
                        name="rewardType"
                        options={this.rewardOptions}
                        label="Reward Type"
                        value={this.state.data.rewardType}
                        error={this.state.errors.rewardType}
                        onChange={this.onChange}
                      />
                    </td>
                  </tr>{" "}
                  <tr className="flyer-table">
                    <td className="vertical-middle">Percentage</td>
                    <td>{this.renderInput("percentage", "", "", "number")}</td>
                  </tr>{" "}
                  <tr className="flyer-table">
                    <td className="vertical-middle">Position</td>
                    <td>
                      <SelectSearch
                        name="position"
                        options={this.positionOptions}
                        label="Position"
                        value={this.state.data.position}
                        error={this.state.errors.position}
                        onChange={this.onChange}
                      />
                    </td>
                  </tr>{" "}
                  <tr className="flyer-table">
                    <td className="vertical-middle">Angle</td>
                    <td>
                      <SelectSearch
                        name="angle"
                        options={this.angleOptions}
                        label="Angle"
                        value={this.state.data.angle}
                        error={this.state.errors.angle}
                        onChange={this.onChange}
                      />
                    </td>
                  </tr>{" "}
                  <tr className="flyer-table">
                    <td className="vertical-middle">Spinner Location</td>
                    <td>
                      <SelectSearch
                        name="spinnerLocation"
                        options={this.spinnerLocationOptions}
                        label="Spinner Location"
                        value={this.state.data.spinnerLocation}
                        error={this.state.errors.spinnerLocation}
                        onChange={this.onChange}
                      />
                    </td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Color</td>
                    <td>{this.renderInput("color", "")}</td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Image </td>
                    <td className="d-flex flex-wrap align-items-start">
                      <div>
                        <div
                          className={`kt-upload-outer-small ${this.state.imageLoading ? "pointer-none" : "pointer"} ${
                            !this.state.data.image ? "placeholder-uploaded-img" : " position-relative"
                          } ${this.state.errors?.image && "border-error"}`}
                        >
                          <div className="kt-upload-img">
                            <img
                              src={
                                this.state.data.image ? process.env.REACT_APP_CDN + this.state.data?.image : plusIcon
                              }
                              alt=""
                            />
                            <input
                              className="file-d-input"
                              type="file"
                              name="image"
                              accept="image/*"
                              onChange={this.handleImageChange}
                            />
                          </div>
                          {this.state.data?.image && (
                            <div
                              className="delete-icon d-flex align-items-center justify-content-center"
                              onClick={(e) => {
                                e.stopPropagation();
                                const data = { ...this.state.data };

                                data.image = null;
                                this.setState({ data });
                              }}
                            >
                              <img src={deleteIcon} alt="delete" />
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loading}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateTelegramSpinReward: (id, data, callback) => dispatch(updateTelegramSpinReward(id, data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(AddFlyer));
