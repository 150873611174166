import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { getSettings, loadSettings, addSettings } from "store/settings";
import _ from "lodash";
//  Components
import Header from "common/header";
import SideBar from "../../../common/sidebar";
import Loader from "common/loader";
import AlertModal from "common/alert/alertModal";

class maintenanceMode extends Component {
  ref = createRef(null);
  state = { showAddModal: false, loadingMain: true };

  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };

  handleChangeSubmit = (e) => {
    if (e) e.preventDefault();
    this.setState({ loadingMain: true });
    const payload = {
      maintenanceMode: !this.props.getSettings.settings[0].maintenanceMode,
    };
    this.props.addSettings(payload, (res) => {
      this.props.loadSettings(null);
      console.log(res);
      this.setState({ loadingMain: false });
      this.toggleAddModal(e);
    });
  };

  componentDidMount = () => {
    this.props.loadSettings((res) => {
      console.log(res);
      this.setState({ loadingMain: false });
    });
  };

  render() {
    const { settings } = this.props.getSettings;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="customText" />

          {settings && settings.length > 0 && (
            <>
              <div className="main-content-wrapper position-relative">
                <div className="mcw-header d-flex align-items-center">
                  <h1>Maintenance Mode</h1>
                </div>
                <div className="fwc-wrapper">
                  <div className="fwc-body">
                    <div className="table-responsive">
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {" "}
                              <Link
                                className="links"
                                to={"#"}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.toggleAddModal(e);
                                }}
                              >
                                {settings[0].maintenanceMode ? "On" : "Off"}
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <AlertModal
                alertModalToggle={this.toggleAddModal}
                alertModalShow={this.state.showAddModal}
                title="Alert"
                description={
                  !settings[0].maintenanceMode
                    ? "This action is very critical! Customer site will stop working. Are you sure you want to put the site under maintenance?"
                    : "Are you sure you want to activate the customer site?"
                }
                onPress={this.handleChangeSubmit}
                alertType={"Ok"}
                loading={this.state.loadingMain}
              />
            </>
          )}
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadSettings: (callback) => dispatch(loadSettings(callback)),
  addSettings: (data, callback) => dispatch(addSettings(data, callback)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(maintenanceMode));
