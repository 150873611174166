import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import backIcon from "include/images/chevron-left.svg";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getDashboard, loadLootboxCustomPrizes, loadLootboxDashboard, loadLootboxTopWinners } from "store/dashboard";
// import ViewAllFlyer from "./viewAllFlyer";
import userIcon from "include/images/user.png";
import loadingIcon from "include/images/loader-grey.svg";
import ViewAllFlyer from "./viewAllFlyer";
import CustomPrizesFlyer from "./customPrizesFlyer";

const LootboxStats = (props) => {
  const ref = useRef();

  const [showViewAll, setShowViewAll] = useState(false);
  const [showCustomPrizes, setShowCustomPrizes] = useState(false);
  const [loadingMain, setLoadingMain] = useState(true);
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState({});
  useEffect(() => {
    props.loadLootboxDashboard(() => setLoadingMain(false));
    props.loadLootboxTopWinners({ page: 1 });
  }, []);
  const {
    lootboxDashboard,
    lootboxTopWinners,
    lootboxTopWinnersLoading,
    lootboxTopWinnersPagination,
    lootboxCustomPrizes,
    lootboxCustomPrizesTotalCount,
  } = props.getDashboard;

  useEffect(() => {
    if (!loadingMain) ref?.current?.complete();
    else ref?.current?.continuousStart();
  }, [loadingMain]);

  const toggleViewAll = (e, type) => {
    e.preventDefault();
    setShowViewAll(!showViewAll);
  };
  const toggleCustomPrizesModal = (e, userObj = {}, id = null) => {
    if (e) e.preventDefault();
    setShowCustomPrizes(!showCustomPrizes);
    setUser(userObj);
    setUserId(id);
  };
  return (
    <>
      <Header />
      <Loader loaderRef={ref} />
      <div className="page-wrapper add-dev-server-wrapper">
        <SideBar page="dashboard" />
        {!loadingMain && (
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Loot Box Stats</h1>
            </div>
            <div className="fwc-wrapper">
              <div className="grid-card-details-row d-flex flex-wrap">
                <div className="gcdr-text-box w-100">
                  <div className="gcdr-heading d-flex align-items-center justify-content-between">
                    <h2>Loot Box Stats</h2>
                  </div>

                  <div className="gcdr-widget-wrapper mt-2">
                    <div className="gcdr-widget-box">
                      <ul className="gw-list d-flex flex-wrap">
                        <li>
                          <div className="gw-box">
                            <em>Total Spins</em>
                            <h6>{lootboxDashboard?.totalSpin?.toLocaleString("en-us") ?? 0}</h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Total Test Spins</em>
                            <h6>{lootboxDashboard?.totalTestSpin?.toLocaleString("en-us") ?? 0}</h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Total Loot Bucks Spent</em>
                            <h6>
                              {lootboxDashboard?.totalLootBucksSpent?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) ?? 0}
                            </h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Total Loot Bucks Won</em>
                            <h6>
                              {lootboxDashboard?.totalLootWon?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) ?? 0}
                            </h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Total Tickets Won</em>
                            <h6>{lootboxDashboard?.totalTicketWon?.toLocaleString("en-us") ?? 0}</h6>
                          </div>
                        </li>{" "}
                        <li>
                          <div className="gw-box">
                            <em>Total Custom Prizes Won in USD</em>
                            <h6>{lootboxDashboard?.totalCustomWon?.toLocaleString("en-us") ?? 0}</h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="gcdr-widget-box">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>Winners</h4>
                        <a href="/" className="back-link-btn m-0" onClick={toggleViewAll}>
                          View all
                        </a>
                      </div>
                      <div className="table-responsive">
                        <table className="table custom-table-striped">
                          <thead>
                            <tr>
                              <th>Username</th>
                              <th>Total Spins</th>
                              <th>Loot Bucks Spent</th>
                              <th>Arcade Coins Won</th>
                              <th>Loot Bucks Won</th>
                              <th>Tickets Won</th>
                              <th>Custom Prizes Won in USD</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!(lootboxTopWinnersLoading && !lootboxTopWinners?.length) &&
                              lootboxTopWinners?.slice(0, 10)?.map((winner, index) => (
                                <tr key={winner?._id}>
                                  <td className="d-flex">
                                    <div className="td-img round-img m-right-2">
                                      <img
                                        src={
                                          winner?.userImage
                                            ? process.env.REACT_APP_CDN + winner?.userImage?.small
                                            : userIcon
                                        }
                                        alt=""
                                      />
                                    </div>
                                    {winner?.userName}
                                  </td>
                                  <td>{winner?.totalSpins?.toLocaleString("en-us")}</td>
                                  <td>
                                    {winner?.totalLootBuckSpend?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                  <td>{winner?.totalArcadeWon?.toLocaleString("en-us") ?? 0}</td>
                                  <td>
                                    {winner?.totalLootWon?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                  <td>{winner?.totalTicketsWon?.toLocaleString("en-us") ?? 0}</td>
                                  <td>
                                    <a
                                      href="#!"
                                      onClick={(e) =>
                                        toggleCustomPrizesModal(
                                          e,
                                          {
                                            userName: winner?.userName,
                                            image: winner?.userImage,
                                            email: winner?.email,
                                          },
                                          winner?.userId,
                                        )
                                      }
                                    >
                                      {winner?.totalCustomWon?.toLocaleString("en-us") ?? 0}
                                    </a>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {lootboxTopWinnersLoading && !lootboxTopWinners?.length && (
                          <div className="w-100 d-flex justify-content-center load-more">
                            <img src={loadingIcon} className="fa-spin" alt="" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ViewAllFlyer
        players={lootboxTopWinners}
        loadMore={props.loadLootboxTopWinners}
        pagination={lootboxTopWinnersPagination}
        showModal={showViewAll}
        toggleModal={toggleViewAll}
        toggleCustomPrizesModal={toggleCustomPrizesModal}
      />
      <CustomPrizesFlyer
        userId={userId}
        user={user}
        lootboxCustomPrizes={lootboxCustomPrizes}
        loadMore={props.loadLootboxCustomPrizes}
        totalCount={lootboxCustomPrizesTotalCount}
        showModal={showCustomPrizes}
        toggleModal={toggleCustomPrizesModal}
      />
      {(showViewAll || showCustomPrizes) && (
        <div
          className="modal-backdrop show fade"
          onClick={showCustomPrizes ? toggleCustomPrizesModal : toggleViewAll}
        ></div>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  loadLootboxDashboard: (callback) => dispatch(loadLootboxDashboard(callback)),
  loadLootboxTopWinners: (pramas, fresh, callback) => dispatch(loadLootboxTopWinners(pramas, fresh, callback)),
  loadLootboxCustomPrizes: (id, params, fresh, callback) =>
    dispatch(loadLootboxCustomPrizes(id, params, fresh, callback)),
});
const mapStateToProps = (state) => ({
  getDashboard: getDashboard(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LootboxStats));
