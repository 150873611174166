import React from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import {
  getSettings,
  addSettings,
  loadWinnerTemplates,
  addWinnerTemplate,
  updateWinnerTemplate,
  addWinnerTemplateReceived,
  updateWinnerTemplateReceived,
} from "store/settings";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Joi from "joi-browser";
import { fetchNFT, ethToUSD } from "utils/externalApiCaller";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import Amplify, { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import plusIcon from "include/images/plus-1.svg";
import ethIcon from "include/images/eth-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";

//  Components
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";

class AddFlyer extends Form {
  state = {
    loading: false,
    data: {
      blockChainNetworks: [],
    },
    errors: {},
    loadingCredits: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal) {
        if (this.props.edit) {
          const data = { ...this.state.data };
          data.blockChainNetworks = this.props.edit;
          this.setState({ data });
        }
      }
    }
  };
  doSubmit = (e) => {
    this.setState({ loadingCredits: true });
    const data = structuredClone(this.state.data);
    const errors = structuredClone(this.state.errors);
    let err = false;
    data.blockChainNetworks.forEach((reward, index) => {
      if (!reward) {
        err = true;
        errors.blockChainNetworks = { ...errors.blockChainNetworks };
        errors.blockChainNetworks[index] = "Credits cannot be empty";
      } else {
        if (errors.blockChainNetworks && errors.blockChainNetworks[index]) {
          delete errors.blockChainNetworks[index];
        }
      }
    });

    if (err || errors.name) {
      return this.setState({ loadingCredits: false, errors });
    }
    const payLoad = {
      [this.props.modalTitle?.value]: data.blockChainNetworks,
    };
    this.props.addSettings(payLoad, (settingsResponse) => {
      this.setState({
        loadingCredits: false,
      });
      if (settingsResponse.status === 200) {
        toast(<AlertSuccess message="Information Saved" />, {
          containerId: 1,
        });
        const settings = settingsResponse.data.data;
        this.props.setResponse(settings[this.props.modalTitle?.value]);
        this.props.toggleModal();
        this.clearData();
      } else {
        toast(<AlertError message={settingsResponse.data && settingsResponse.data.message} />, {
          containerId: 1,
        });
      }
    });
  };
  schema = {
    blockChainNetworks: Joi.array().allow([]),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        blockChainNetworks: [],
      },
      errors: {},
      loadingCredits: false,
    });
  };
  addOne = (e) => {
    if (e) e.preventDefault();
    const data = { ...this.state.data };
    data.blockChainNetworks = [...data.blockChainNetworks, ""];
    this.setState({ data });
  };
  removeOne = (index) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    if (errors.blockChainNetworks && errors.blockChainNetworks[index]) {
      delete errors.blockChainNetworks[index];
    }
    data.blockChainNetworks.splice(index, 1);
    this.setState({ data });
  };
  changeOne = (value, index) => {
    const data = structuredClone(this.state.data);
    const errors = structuredClone(this.state.errors);
    if (errors.blockChainNetworks && errors.blockChainNetworks[index]) {
      delete errors.blockChainNetworks[index];
    }
    data.blockChainNetworks[index] = value;
    this.setState({ data });
  };
  render() {
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel half-width-modal"}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>{this.props.modalTitle?.title}</h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <table className="table large-table flyer-table">
                <thead>
                  <tr>
                    <th style={{ width: 10 }}>#</th>
                    <th>Blockchain Networks</th>
                    <th style={{ width: 10 }}> Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.blockChainNetworks?.map((reward, index) => (
                    <tr className="flyer-table">
                      <td>{index + 1}</td>
                      <td>
                        <input
                          name="blockChainNetworks"
                          type={"text"}
                          value={reward}
                          className={`form-control ${
                            this.state.errors.blockChainNetworks &&
                            this.state.errors.blockChainNetworks[index] &&
                            "error"
                          }`}
                          onChange={(e) => {
                            this.changeOne(e.target.value, index);
                          }}
                        />
                      </td>
                      <td>
                        <img
                          src={closeIcon}
                          alt=""
                          className="pointer"
                          onClick={(e) => {
                            this.removeOne(index);
                          }}
                        />{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>{" "}
              <div className="new-form-group new-form-btn">
                <NextButton
                  handleSubmit={this.addOne}
                  label="+ Add more"
                  loading={this.state.loadingCredits}
                  classData={"btn btn-primary btn-sm orange-hover"}
                />
              </div>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loadingCredits}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addSettings: (data, callback) => dispatch(addSettings(data, callback)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFlyer));
