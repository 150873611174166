import React from "react";
import statusIcon from "include/images/green-tick-icon.svg";
const AlertSuccess = (props) => {
  return (
    <>
      <div className="alert-icon">
        <img src={statusIcon} alt="" />
      </div>
      <div>{props.message}</div>
    </>
  );
};

export default AlertSuccess;
