import React, { Component } from "react";

//  Images
import errorIcon from "include/images/404.svg";

//  Components
import Header from "common/header";
import SideBar from "common/sidebar";

class Error404 extends Component {
  state = {};
  render() {
    return (
      <>
        <Header history={this.props.history} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="" />
          <div className="main-content-wrapper position-relative">
            <div className="error-page-wrapper d-flex align-items-center justify-content-center">
              <div className="epw-content-box text-center">
                <div className="epw-img-box">
                  <img src={errorIcon} alt="" />
                </div>
                <h4>ERROR</h4>
                <p>Page Not Found!</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Error404;
