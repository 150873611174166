import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getDashboard, loadVerisoulAuthResultsDashboard } from "store/dashboard";
import NextButton from "common/form/submitButton";
import filterIcon from "include/images/filter-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import arrowIcon from "include/images/arrow-down-icon.svg";
import arrowIcon1 from "include/images/arrow-down-icon-1.svg";
import arrowIcon2 from "include/images/arrow-down-icon-2.svg";
const VerisoulStats = (props) => {
  const ref = useRef();

  const [showViewAll, setShowViewAll] = useState(false);
  const [loadingMain, setLoadingMain] = useState(true);
  const [page, setPage] = useState(1);
  const [sorting, setSorting] = useState({});

  const [showFilters, setShowFilters] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [keyword, setKeyword] = useState("");
  useEffect(() => {
    searchFilters();
  }, []);
  const { verisoulAuthResults, filteredCount, totalCount } = props.getDashboard;

  useEffect(() => {
    if (!loadingMain) ref?.current?.complete();
    else ref?.current?.continuousStart();
  }, [loadingMain]);

  const toggleViewAll = (e, type) => {
    e.preventDefault();
    setShowViewAll(!showViewAll);
  };
  const searchFilters = (e, searchKeyword = "", searchPage = 1, sorting) => {
    if (e) e.preventDefault();
    setLoadingFilters(true);
    if (searchKeyword !== keyword) setKeyword(searchKeyword);
    if (searchPage !== page) setPage(searchPage);
    props.loadVerisoulAuthResultsDashboard(
      { keyword: searchKeyword, page: searchPage, sort: sorting?.sort, order: sorting?.order },
      () => {
        setLoadingFilters(false);
        setLoadingMain(false);
      },
    );
  };
  const sort = (e, type) => {
    const data = {};
    if (type === 1) {
      if (sorting?.sort === "accountScore" && sorting?.order === "desc") {
        data.sort = "accountScore";
        data.order = "asc";
      } else {
        data.sort = "accountScore";
        data.order = "desc";
      }
    } else if (type === 2) {
      if (sorting?.sort === "bot" && sorting?.order === "desc") {
        data.sort = "bot";
        data.order = "asc";
      } else {
        data.sort = "bot";
        data.order = "desc";
      }
    } else if (type === 3) {
      if (sorting?.sort === "riskSignals" && sorting?.order === "desc") {
        data.sort = "riskSignals";
        data.order = "asc";
      } else {
        data.sort = "riskSignals";
        data.order = "desc";
      }
    } else if (type === 4) {
      if (sorting?.sort === "multipleAccounts" && sorting?.order === "desc") {
        data.sort = "multipleAccounts";
        data.order = "asc";
      } else {
        data.sort = "multipleAccounts";
        data.order = "desc";
      }
    }
    setSorting(data);

    searchFilters(e, keyword, page, data);
  };
  return (
    <>
      <Header />
      <Loader loaderRef={ref} />
      <div className="page-wrapper add-dev-server-wrapper">
        <SideBar page="dashboard" />
        <div className="main-content-wrapper position-relative">
          <div className="mcw-header d-flex align-items-center">
            <h1>Users {totalCount > 0 && `(${totalCount})`}</h1>
            <div className="filter-menu-box d-flex align-items-center ms-auto">
              <div className="fmb-box">
                <a
                  href="#!"
                  className="filter-link-box"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowFilters(!showFilters);
                  }}
                >
                  <img src={filterIcon} /> <span>Filters</span>
                </a>
              </div>
            </div>
          </div>
          <div className="fwc-wrapper">
            {showFilters && (
              <div className="fwc-head ">
                <div className="fwc-inner">
                  <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                    <li className="flex-fill width-100">
                      <div className="fml-box">
                        <div className={"form-group"}>
                          <label htmlFor={"keyword"} className="form-label">
                            Keyword
                          </label>

                          <input
                            name={"keyword"}
                            id={"keyword"}
                            className={"form-control"}
                            placeholder="Search via keywords"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                        </div>
                      </div>
                    </li>{" "}
                  </ul>

                  <div className="fwc-btn d-flex align-items-center justify-content-end">
                    <NextButton
                      handleSubmit={(e) => searchFilters(e, "", 1, sorting)}
                      classData="btn-text pointer"
                      label="Reset"
                    />
                    <NextButton
                      handleSubmit={(e) => searchFilters(e, keyword, 1, sorting)}
                      classData="btn btn-primary btn-sm"
                      label="Search"
                      loading={loadingFilters}
                    />
                  </div>
                </div>{" "}
              </div>
            )}
            <div className="fwc-body">
              {!loadingMain && (
                <div className="table-responsive">
                  {filteredCount === 0 ? (
                    <div className="fwc-body">
                      <div className="no-record-found-container">
                        <div className="nfr-box">
                          <img src={emptyIcon} alt="No Record Found" />
                          <div className="nrf-text">
                            <h5>No Records Found!</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <table className="table large-table">
                      <thead>
                        <tr>
                          <th>Username</th>
                          <th>Email</th>
                          <th>
                            Account Score{" "}
                            <a href="#!" onClick={(e) => sort(e, 1)}>
                              <img
                                src={
                                  sorting?.sort === "accountScore" && sorting?.order === "desc"
                                    ? arrowIcon1
                                    : sorting?.sort === "accountScore" && sorting?.order === "asc"
                                    ? arrowIcon2
                                    : arrowIcon
                                }
                                alt=""
                              />
                            </a>
                          </th>
                          <th>
                            Bot{" "}
                            <a href="#!" onClick={(e) => sort(e, 2)}>
                              <img
                                src={
                                  sorting?.sort === "bot" && sorting?.order === "desc"
                                    ? arrowIcon1
                                    : sorting?.sort === "bot" && sorting?.order === "asc"
                                    ? arrowIcon2
                                    : arrowIcon
                                }
                                alt=""
                              />
                            </a>
                          </th>
                          <th>Decision</th>
                          <th>
                            Risk Signals{" "}
                            <a href="#!" onClick={(e) => sort(e, 3)}>
                              <img
                                src={
                                  sorting?.sort === "riskSignals" && sorting?.order === "desc"
                                    ? arrowIcon1
                                    : sorting?.sort === "riskSignals" && sorting?.order === "asc"
                                    ? arrowIcon2
                                    : arrowIcon
                                }
                                alt=""
                              />
                            </a>
                          </th>
                          <th width={300}>
                            Multiple Accounts{" "}
                            <a href="#!" onClick={(e) => sort(e, 4)}>
                              <img
                                src={
                                  sorting?.sort === "multipleAccounts" && sorting?.order === "desc"
                                    ? arrowIcon1
                                    : sorting?.sort === "multipleAccounts" && sorting?.order === "asc"
                                    ? arrowIcon2
                                    : arrowIcon
                                }
                                alt=""
                              />
                            </a>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {verisoulAuthResults?.map((user, index) => (
                          <tr key={index}>
                            <td>{user?.accountId}</td>
                            <td>{user?.email}</td>
                            <td>{user?.accountScore}</td>
                            <td>{user?.bot}</td>
                            <td className="ust-tag">
                              <span
                                className={`text-center d-block tickets pointer ${
                                  user?.decision === "Fake" && "suspended"
                                } `}
                              >
                                {user?.decision}
                              </span>
                            </td>
                            <td>{user?.riskSignals}</td>
                            <td>{user?.multipleAccounts}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
              {!(page === 1 && filteredCount === 0) && (
                <div className="page-pagination">
                  <ul className="pagination  justify-content-end">
                    <li className="page-item">
                      <a
                        class={`page-link ${page === 1 && "disabled"}`}
                        href="#!"
                        aria-label="Previous"
                        onClick={(e) => {
                          e.preventDefault();
                          if (page > 1) {
                            searchFilters(e, keyword, page - 1, sorting);
                          }
                        }}
                      >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                    </li>
                    <li className="page-item active">
                      <a
                        className="page-link"
                        href="#!"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        {page}
                      </a>
                    </li>
                    {filteredCount >= 50 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                            searchFilters(e, keyword, page + 1, sorting);
                          }}
                        >
                          {page + 1}
                        </a>
                      </li>
                    )}
                    <li className="page-item">
                      <a
                        class={`page-link ${verisoulAuthResults?.length < 50 && "disabled"}`}
                        href="#!"
                        aria-label="Next"
                        onClick={(e) => {
                          e.preventDefault();
                          searchFilters(e, keyword, page + 1, sorting);
                        }}
                      >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  loadVerisoulAuthResultsDashboard: (params, callback) => dispatch(loadVerisoulAuthResultsDashboard(params, callback)),
});
const mapStateToProps = (state) => ({
  getDashboard: getDashboard(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerisoulStats));
