import React, { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loadCountries, getSettings, updateCountryStatus, updateStatusReceived } from "store/settings";
import moment from "moment";
import { Dropdown, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import _ from "lodash";
import { saveAs } from "file-saver";

//  Images
import filterIcon from "include/images/filter-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import closeIcon from "include/images/close-icon.svg";

//  Components
import Form2 from "common/form/form";
import Header from "common/header";
import SideBar from "common/sidebar";
import Loader from "common/loader";
import StatesFlyer from "./statesFlyer";
import NextButton from "common/form/submitButton";

class Listing extends Form2 {
  ref = createRef(null);
  state = {
    data: { keyword: "" },
    loading: false,
    errors: {},
    country: null,
    showStates: false,
    loadingStatus: false,
    loadingReset: false,
    showFilters: false,
    appliedFilters: {},
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getSettings.loading && this.props.getSettings.loading !== prevProps.getSettings.loading)
      this.ref.current.complete();

    if (this.props.getSettings.loading && this.props.getSettings.loading !== prevProps.getSettings.loading)
      this.ref.current.continuousStart();
  };

  componentDidMount = () => {
    this.searchFilters();
  };
  updateStatus = (e, country) => {
    if (e) e.preventDefault();
    this.setState({ loadingStatus: true });
    this.props.updateCountryStatus(
      { countryCode: country.countryCode, isAllowed: country.isAllowed ? 0 : 1 },
      (res) => {
        this.props.updateStatusReceived({ countryCode: country.countryCode });
        this.setState({ loadingStatus: false });
      },
    );
  };
  toggleStatesFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showStates: !this.state.showStates });
  };
  searchFilters = () => {
    this.setState({ loading: true, loadingFilters: true });
    const data = { ...this.state.data };
    const payLoad = {
      keyword: data.keyword,
    };
    var params = _.pickBy(payLoad, function (value, key) {
      return value !== "";
    });
    this.props.loadCountries(params, (response) => {
      if (response.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).map((key) => {
          if (!_.isEmpty(data[key])) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({ appliedFilters });
      }
      this.setState({ loading: false, loadingFilters: false });
    });
  };

  resetFilters = (e) => {
    e.preventDefault();
    this.setState(
      {
        data: { keyword: "" },
      },
      () => this.searchFilters(),
    );
  };
  removeFilter = (e, type) => {
    const data = this.state.data;
    data[type] = "";
    this.setState({ data }, () => this.searchFilters());
  };
  schema = {
    keyword: Joi.string().allow(""),
  };
  render() {
    const { countries, totalCount } = this.props.getSettings;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="customSliders" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Geolocation {totalCount > 0 && `(${totalCount})`}</h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showFilters: !this.state.showFilters });
                    }}
                  >
                    <img src={filterIcon} /> <span>Filters</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="fwc-wrapper">
              {this.state.showFilters && (
                <div className="fwc-head ">
                  <div className="fwc-inner">
                    <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("keyword", "Keyword")}</div>
                      </li>{" "}
                    </ul>
                    <div className="fwc-btn d-flex align-items-center justify-content-end">
                      <NextButton
                        handleSubmit={this.resetFilters}
                        classData="btn-text pointer"
                        label="Reset"
                        loading={this.state.loadingReset}
                      />
                      <NextButton
                        handleSubmit={this.searchFilters}
                        classData="btn btn-primary btn-sm"
                        label="Search"
                        loading={this.state.loadingFilters}
                      />
                    </div>
                  </div>{" "}
                  {!_.isEmpty(this.state.appliedFilters) && (
                    <div className="filter-tag-container d-flex align-items-center ">
                      <div className="fwc-left">
                        <div className="filter-tag-list">
                          <p>filter applied :</p>
                          {Object.keys(this.state.appliedFilters).map((key) => (
                            <div className="filter-tag-item">
                              <span className="camelCase">
                                {key === "keyword"
                                  ? "Keyword"
                                  : key === "creditsMin"
                                  ? "Credits Min"
                                  : key === "creditsMax"
                                  ? "Credits Max"
                                  : key}
                              </span>{" "}
                              :{" "}
                              {key === "status" ? this.state.appliedFilters[key].value : this.state.appliedFilters[key]}
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState(
                                    {
                                      data: { ...this.state.data, [key]: "" },
                                    },
                                    this.searchFilters,
                                  );
                                }}
                              >
                                <img src={closeIcon} alt="" />
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {countries?.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Country</th>
                            <th>Code</th>
                            <th>Actions</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {countries?.map((country, i) => (
                            <tr>
                              <td>
                                <Link
                                  to="#"
                                  className={` ${country.isAllowed === 0 ? "pointer-none" : "orange-text"}`}
                                  onClick={(e) => {
                                    this.setState({ country }, () => {
                                      this.toggleStatesFlyer(e);
                                    });
                                  }}
                                >
                                  {country.name}
                                </Link>
                              </td>
                              <td>{country.countryCode}</td>
                              <td>
                                <Form.Switch
                                  type="switch"
                                  name="allow-switch"
                                  id={`allow-switch-${country.countryCode}`}
                                  className={`pointer review-switch review-switch2 ${
                                    this.state.loadingStatus && "pointer-none"
                                  } ${country.isAllowed === 1 ? "toggle-is-on" : "toggle-is-off"}`}
                                  checked={country.isAllowed === 1 ? true : false}
                                  label={country.isAllowed ? "Allowed" : "Blocked"}
                                  onClick={(e) => {
                                    this.updateStatus(e, country);
                                  }}
                                />
                              </td>
                              <td>
                                {country.updatedAt ? moment(country.updatedAt).format("MM/DD/YYYY hh:mm a") : "N/A"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>{" "}
        <StatesFlyer
          showModal={this.state.showStates}
          toggleModal={this.toggleStatesFlyer}
          country={this.state.country}
        />
        {this.state.showStates && <div className="modal-backdrop show fade" onClick={this.toggleStatesFlyer}></div>}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadCountries: (params, callback) => dispatch(loadCountries(params, callback)),
  updateCountryStatus: (data, callback) => dispatch(updateCountryStatus(data, callback)),
  updateStatusReceived: (payLoad) => dispatch(updateStatusReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Listing));
