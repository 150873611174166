import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { getSettings, loadSettings, addSettings } from "store/settings";

import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import _ from "lodash";
import moment from "moment";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import historyIcon from "include/images/history-icon.svg";
import plusIcon from "include/images/plus.svg";

//  Components
import Header from "common/header";
import SideBar from "../../../common/sidebar";
import NextButton from "common/form/submitButton";
import Loader from "common/loader";
import AlertSuccess from "common/alert/alertSuccess";
import SelectSearch from "common/form/selectSearch";
import AlertError from "common/alert/alertError";
import DeleteModal from "common/alert/alertModal";
import AddCategory from "./addRewards";
import AddMiscSettings from "./addMiscSettings";
import HistoryFlyer from "common/historyFlyer";
import AddDailyGameChallenge from "./addDailyGameChallenge";

class Listing extends Component {
  ref = createRef(null);
  state = {
    mainSettings: [
      // { title: "Daily Streak Bonus", value: "streakReward" },
      // {
      //   title: "Daily Credits Purchase Limit Per Account",
      //   value: "dailyCreditPurchaseLimit",
      // },
    ],

    miscSettingData: [
      {
        title: "Email Settings",
        value: "email",
        data: [
          {
            key: "duplicateIPsNotificationEmails",
            type: "array",
          },
          {
            key: "sqsErrorNotificationEmails",
            type: "array",
          },
          {
            key: "lowBalanceNotificationEmails",
            type: "array",
          },
          {
            key: "contestSweepstakesEndTimeReminderEmails",
            type: "array",
          },
          {
            key: "lootBoxPrizeEmails",
            type: "array",
          },
          {
            key: "kycVerificationEmails",
            type: "array",
          },
          {
            key: "fraudDetectionEmails",
            type: "array",
          },
          {
            key: "contestSchedularEmails",
            type: "array",
          },
        ],
      },
      {
        title: "Chain Link Settings",
        value: "chainLink",
        data: [
          {
            key: "chainlinkVRFCoodinatorAddress",
            type: "string",
          },
          {
            key: "chainlinkSubscriptionId",
            type: "number",
          },
          {
            key: "chainlinkVRFCoodinatorABI",
            type: "string",
          },
        ],
      },
      {
        title: "Minimum Balance",
        value: "walletBalance",
        data: [
          {
            key: "minBGWalletBalance",
            type: "number",
          },
          {
            key: "minChainLinkBalance",
            type: "number",
          },
        ],
      },
      {
        title: "Free Sign Up Credits",
        value: "freeSignUpCredits",
        data: [
          {
            key: "freeSignupArcadeCoinCredits",
            type: "number",
          },
          {
            key: "freeSignupLootBonusCoinCredits",
            type: "number",
          },
        ],
      },
      {
        title: "Daily Rewards",
        value: "dailyRewards",
        data: [
          {
            key: "dailyArcadeCoinReward",
            type: "number",
          },
          {
            key: "dailyLootBonusCoinReward",
            type: "number",
          },
        ],
      },
      {
        title: "Three Day Streak Rewards",
        value: "threeDayStreakRewards",
        data: [
          {
            key: "threeDayStreakArcadeCoinReward",
            type: "number",
          },
          {
            key: "threeDayStreakLootBonusCoinReward",
            type: "number",
          },
        ],
      },
      {
        title: "Weekly Streak Rewards",
        value: "weeklyStreakRewards",
        data: [
          {
            key: "weeklyStreakArcadeCoinReward",
            type: "number",
          },
          {
            key: "weeklyStreakLootBonusCoinReward",
            type: "number",
          },
        ],
      },
      {
        title: "Package Bonus %",
        value: "creditPriceBonusPercent",
        data: [
          {
            key: "creditPriceBonusPercent",
            type: "number",
          },
        ],
      },
      {
        title: "Referral Bonus %",
        value: "referralBonusPercent",
        data: [
          {
            key: "referralBonusPercent",
            type: "number",
          },
        ],
      },
      {
        title: "Daily Game Challenge",
        value: "dailyGameChallenge",
        data: [
          {
            key: "dailyGameChallenge",
            type: "array",
          },
        ],
      },
      {
        title: "Daily Game Challenge Rewards",
        value: "dailyGameChallengeRewards",
        data: [
          {
            key: "dailyGameChallengeArcadeCoinReward",
            type: "number",
          },
          {
            key: "dailyGameChallengeLootBonusCoinReward",
            type: "number",
          },
        ],
      },
      {
        title: "Resume Auto Suspension Days",
        value: "resumeAutoSuspensionDays",
        data: [
          {
            key: "resumeAutoSuspensionDays",
            type: "number",
          },
        ],
      },
    ],

    monthlyLeaderBoardCredits: [],
    dailyLeaderBoardCredits: [],
    weeklyLeaderBoardCredits: [],
    modalTitle: "",
    streakReward: 10,
    freeSignupCredits: 10,
    dailyCreditPurchaseLimit: 10,
    loadingMain: false,
    showAddModal: false,
    deleteId: "",
    editData: null,
    editId: null,
    showMiscSettingsFlyer: false,
    showDailyGameChallengeSettingsFlyer: false,

    //  Misc Settings
    duplicateIPsNotificationEmails: [],
    sqsErrorNotificationEmails: [],
    lowBalanceNotificationEmails: [],
    contestSweepstakesEndTimeReminderEmails: [],
    lootBoxPrizeEmails: [],
    kycVerificationEmails: [],
    fraudDetectionEmails: [],
    contestSchedularEmails: [],
    chainlinkVRFCoodinatorAddress: "",
    chainlinkSubscriptionId: null,
    chainlinkVRFCoodinatorABI: "",
    minBGWalletBalance: null,
    minChainLinkBalance: null,

    //  Free Sign up credits
    freeSignupArcadeCoinCredits: null,
    freeSignupLootBonusCoinCredits: null,

    //  Free Daily reward Settings
    dailyArcadeCoinReward: null,
    dailyLootBonusCoinReward: null,

    //  Three Day Streak reward Settings
    threeDayStreakArcadeCoinReward: null,
    threeDayStreakLootBonusCoinReward: null,

    //  Weekly Streak reward Settings
    weeklyStreakArcadeCoinReward: null,
    weeklyStreakLootBonusCoinReward: null,

    creditPriceBonusPercent: 0,
    referralBonusPercent: 0,

    //daily game challenge
    dailyGameChallenge: [],

    //daily game challenge reward
    dailyGameChallengeArcadeCoinReward: null,
    dailyGameChallengeLootBonusCoinReward: null,
    //resumeAutoSuspensionDays
    resumeAutoSuspensionDays: 0,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getSettings.loading && this.props.getSettings.loading !== prevProps.getSettings.loading)
      this.ref.current.complete();

    if (this.props.getSettings.loading && this.props.getSettings.loading !== prevProps.getSettings.loading)
      this.ref.current.continuousStart();
    if (this.state.showAddModal !== prevState.showAddModal && !this.state.showAddModal) {
      setTimeout(() => {
        this.setState({ editData: null, editId: null });
      }, 500);
    }
  };
  componentDidMount = () => {
    this.setState({ loadingMain: true });
    this.loadData();
  };
  loadData = (callback) => {
    this.props.loadSettings((res) => {
      const settings = res.data.data;
      const dailyLeaderBoardCredits = settings?.length > 0 ? settings[0]?.dailyLeaderBoardCredits : [];
      const weeklyLeaderBoardCredits = settings?.length > 0 ? settings[0]?.weeklyLeaderBoardCredits : [];
      const monthlyLeaderBoardCredits = settings?.length > 0 ? settings[0]?.monthlyLeaderBoardCredits : [];
      const streakReward = settings?.length > 0 ? settings[0]?.streakReward : [];
      const freeSignupCredits = settings?.length > 0 ? settings[0]?.freeSignupCredits : [];
      const dailyCreditPurchaseLimit = settings?.length > 0 ? settings[0]?.dailyCreditPurchaseLimit : [];

      //  Email Settings
      const duplicateIPsNotificationEmails = settings?.length > 0 ? settings[0]?.duplicateIPsNotificationEmails : [];
      const sqsErrorNotificationEmails = settings?.length > 0 ? settings[0]?.sqsErrorNotificationEmails : [];
      const lowBalanceNotificationEmails = settings?.length > 0 ? settings[0]?.lowBalanceNotificationEmails : [];
      const contestSweepstakesEndTimeReminderEmails =
        settings?.length > 0 ? settings[0]?.contestSweepstakesEndTimeReminderEmails : [];
      const lootBoxPrizeEmails = settings?.length > 0 ? settings[0]?.lootBoxPrizeEmails : [];
      const kycVerificationEmails = settings?.length > 0 ? settings[0]?.kycVerificationEmails : [];
      const fraudDetectionEmails = settings?.length > 0 ? settings[0]?.fraudDetectionEmails : [];
      const contestSchedularEmails = settings?.length > 0 ? settings[0]?.contestSchedularEmails : [];
      //  Chain Link Settings
      const chainlinkVRFCoodinatorAddress = settings?.length > 0 ? settings[0]?.chainlinkVRFCoodinatorAddress : "";
      const chainlinkSubscriptionId = settings?.length > 0 ? settings[0]?.chainlinkSubscriptionId : null;
      const chainlinkVRFCoodinatorABI = settings?.length > 0 ? settings[0]?.chainlinkVRFCoodinatorABI : "";

      //  Minimum Balance Settings
      const minBGWalletBalance = settings?.length > 0 ? settings[0]?.minBGWalletBalance : null;
      const minChainLinkBalance = settings?.length > 0 ? settings[0]?.minChainLinkBalance : null;

      //  Free Sign up credits Settings
      const freeSignupArcadeCoinCredits = settings?.length > 0 ? settings[0]?.freeSignupArcadeCoinCredits : null;
      const freeSignupLootBonusCoinCredits = settings?.length > 0 ? settings[0]?.freeSignupLootBonusCoinCredits : null;

      //  Free Daily reward Settings
      const dailyArcadeCoinReward = settings?.length > 0 ? settings[0]?.dailyArcadeCoinReward : null;
      const dailyLootBonusCoinReward = settings?.length > 0 ? settings[0]?.dailyLootBonusCoinReward : null;

      //  Three Day Streak reward Settings
      const threeDayStreakArcadeCoinReward = settings?.length > 0 ? settings[0]?.threeDayStreakArcadeCoinReward : null;
      const threeDayStreakLootBonusCoinReward =
        settings?.length > 0 ? settings[0]?.threeDayStreakLootBonusCoinReward : null;

      //  Weekly Streak reward Settings
      const weeklyStreakArcadeCoinReward = settings?.length > 0 ? settings[0]?.weeklyStreakArcadeCoinReward : null;
      const weeklyStreakLootBonusCoinReward =
        settings?.length > 0 ? settings[0]?.weeklyStreakLootBonusCoinReward : null;

      const creditPriceBonusPercent = settings?.length > 0 ? settings[0]?.creditPriceBonusPercent : 0;
      const referralBonusPercent = settings?.length > 0 ? settings[0]?.referralBonusPercent : 0;

      //Daily game challenge
      const dailyGameChallenge = settings?.length > 0 ? settings[0]?.dailyGameChallenge : [];

      //  Free Daily game challenge reward Settings
      const dailyGameChallengeArcadeCoinReward =
        settings?.length > 0 ? settings[0]?.dailyGameChallengeArcadeCoinReward : null;
      const dailyGameChallengeLootBonusCoinReward =
        settings?.length > 0 ? settings[0]?.dailyGameChallengeLootBonusCoinReward : null;
      //resumeAutoSuspensionDays
      const resumeAutoSuspensionDays = settings?.length > 0 ? settings[0]?.resumeAutoSuspensionDays : null;
      this.setState({
        loadingMain: false,
        dailyLeaderBoardCredits,
        weeklyLeaderBoardCredits,
        monthlyLeaderBoardCredits,
        streakReward,
        freeSignupCredits,
        dailyCreditPurchaseLimit,

        //  Misc Settings
        duplicateIPsNotificationEmails,
        sqsErrorNotificationEmails,
        lowBalanceNotificationEmails,
        chainlinkVRFCoodinatorAddress,
        contestSweepstakesEndTimeReminderEmails,
        lootBoxPrizeEmails,
        kycVerificationEmails,
        fraudDetectionEmails,
        contestSchedularEmails,
        chainlinkSubscriptionId,
        chainlinkVRFCoodinatorABI,
        minBGWalletBalance,
        minChainLinkBalance,
        freeSignupArcadeCoinCredits,
        freeSignupLootBonusCoinCredits,
        dailyArcadeCoinReward,
        dailyLootBonusCoinReward,
        threeDayStreakArcadeCoinReward,
        threeDayStreakLootBonusCoinReward,
        weeklyStreakArcadeCoinReward,
        weeklyStreakLootBonusCoinReward,
        creditPriceBonusPercent,
        referralBonusPercent,
        dailyGameChallenge,
        dailyGameChallengeArcadeCoinReward,
        dailyGameChallengeLootBonusCoinReward,
        resumeAutoSuspensionDays,
      });
      if (callback) callback();
    });
  };
  //  Toggle modals
  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };
  toggleAddMiscSettings = (e) => {
    if (e) e.preventDefault();
    this.setState({ showMiscSettingsFlyer: !this.state.showMiscSettingsFlyer });
  };
  toggleAddDailyGameChallengeSettings = (e) => {
    if (e) e.preventDefault();
    this.setState({
      showDailyGameChallengeSettingsFlyer: !this.state.showDailyGameChallengeSettingsFlyer,
    });
  };
  render() {
    const { mainSettings, modalTitle, miscSettingData } = this.state ?? [];
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="customText" />

          {!this.state.loadingMain && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1>Misc Settings</h1>
              </div>
              <div className="fwc-wrapper">
                <div className="fwc-body">
                  <div className="table-responsive">
                    <table className="table large-table">
                      <thead>
                        <tr>
                          <th style={{ width: 75, textAlign: "center" }}>Actions</th>
                          <th>Title</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mainSettings.map((interval, index) => (
                          <tr>
                            <td>
                              <Dropdown className=" custom-dropdown">
                                <Dropdown.Toggle
                                  id="tdDropdownOne"
                                  className="custom-dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img src={carrentIcon} alt="" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState(
                                        {
                                          editData: this.state[interval.value],
                                          modalTitle: interval,
                                          editId: index,
                                        },
                                        () => {
                                          this.toggleAddModal();
                                        },
                                      );
                                    }}
                                  >
                                    Edit
                                  </Dropdown.Item>{" "}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td>
                              <Link
                                className="two-line-ellipsis"
                                to={"#"}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState(
                                    {
                                      editData: this.state[interval.value],
                                      modalTitle: interval,
                                      editId: index,
                                    },
                                    () => {
                                      this.toggleAddModal();
                                    },
                                  );
                                }}
                              >
                                {interval.title}
                              </Link>
                            </td>
                          </tr>
                        ))}
                        {miscSettingData.map((interval, index) => (
                          <tr>
                            <td>
                              <Dropdown className=" custom-dropdown">
                                <Dropdown.Toggle
                                  id="tdDropdownOne"
                                  className="custom-dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img src={carrentIcon} alt="" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      //editData object contains interval data keys as keys and state values as values
                                      const editData = interval.data?.reduce(
                                        (obj, { key }) => ({
                                          ...obj,
                                          [key]: this.state[key],
                                        }),
                                        {},
                                      );
                                      this.setState(
                                        {
                                          editData,
                                          modalTitle: interval,
                                          editId: index,
                                        },
                                        () => {
                                          interval.value === "dailyGameChallenge"
                                            ? this.toggleAddDailyGameChallengeSettings()
                                            : this.toggleAddMiscSettings();
                                        },
                                      );
                                    }}
                                  >
                                    Edit
                                  </Dropdown.Item>{" "}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td>
                              <Link
                                className="two-line-ellipsis"
                                to={"#"}
                                onClick={(e) => {
                                  e.preventDefault();
                                  //editData object contains interval data keys as keys and state values as values
                                  const editData = interval?.data?.reduce(
                                    (obj, { key }) => ({
                                      ...obj,
                                      [key]: this.state[key],
                                    }),
                                    {},
                                  );
                                  this.setState(
                                    {
                                      editData,
                                      modalTitle: interval,
                                      editId: index,
                                    },
                                    () => {
                                      interval.value === "dailyGameChallenge"
                                        ? this.toggleAddDailyGameChallengeSettings()
                                        : this.toggleAddMiscSettings();
                                    },
                                  );
                                }}
                              >
                                {interval.title}
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <AddCategory
          showModal={this.state.showAddModal}
          toggleModal={this.toggleAddModal}
          edit={this.state.editData}
          editId={this.state.editId}
          modalTitle={modalTitle}
          setResponse={(val) => {
            this.setState({ [modalTitle?.value]: val });
          }}
        />
        <AddMiscSettings
          showModal={this.state.showMiscSettingsFlyer}
          toggleModal={this.toggleAddMiscSettings}
          edit={this.state.editData}
          editId={this.state.editId}
          modalTitle={modalTitle}
          setResponse={(val) => {
            this.setState({ [modalTitle?.value]: val });
          }}
          loadSettings={this.loadData}
        />
        <AddDailyGameChallenge
          showModal={this.state.showDailyGameChallengeSettingsFlyer}
          toggleModal={this.toggleAddDailyGameChallengeSettings}
          loadSettings={this.loadData}
          edit={this.state.editData}
        />
        <HistoryFlyer
          showModal={this.state.showHistory}
          toggleModal={this.toggleHistoryFlyer}
          moduleName={"winnerTemplate"}
          objectId={this.state.objectId}
          title={this.state.object && this.state.object.title}
        />
        {(this.state.showAddModal ||
          this.state.showHistory ||
          this.state.showMiscSettingsFlyer ||
          this.state.showDailyGameChallengeSettingsFlyer) && (
          <div
            className="modal-backdrop show fade"
            onClick={
              this.state.showHistory
                ? this.toggleHistoryFlyer
                : this.state.showMiscSettingsFlyer
                ? this.toggleAddMiscSettings
                : this.state.showDailyGameChallengeSettingsFlyer
                ? this.toggleAddDailyGameChallengeSettings
                : this.toggleAddModal
            }
          ></div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadSettings: (callback) => dispatch(loadSettings(callback)),
  addSettings: (data, callback) => dispatch(addSettings(data, callback)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Listing));
