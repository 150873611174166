import React, { Component } from "react";

//  Images
import errorIcon from "include/images/comingsoon.png";

//  Components
import Header from "common/header";
import SideBar from "common/sidebar";

class ComingSoon extends Component {
  state = {};
  render() {
    return (
      <>
        <Header history={this.props.history} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="" />
          <div className="single-page-wrapper d-flex align-items-center justify-content-center">
            <div className="single-page-content-box text-center">
              <div className="single-page-img-box">
                <img src={errorIcon} alt="" />
              </div>
              <h4>
                Coming <span>Soon!</span>
              </h4>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ComingSoon;
