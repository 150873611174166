import closeIcon from "include/images/dark-close-icon.svg";
import loadingIcon from "include/images/loader-grey.svg";
import emptyIcon from "include/images/nrf.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import userIcon from "include/images/user.png";
import moment from "moment/moment";
import { Dropdown } from "react-bootstrap";
import Loader from "common/loader";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import AlertSuccess from "common/alert/alertSuccess";
import { transferLootPrize } from "store/lootbox";
import AlertError from "common/alert/alertError";
import { toast } from "react-toastify";
import { updateTransferCustomPrize } from "store/dashboard";

import arrowIcon1 from "include/images/arrow-down-icon-1.svg";
import arrowIcon2 from "include/images/arrow-down-icon-2.svg";
import arrowIcon from "include/images/arrow-down-icon.svg";

const CustomPrizesFlyer = ({
  userId,
  user,
  lootboxCustomPrizes,
  loadMore,
  totalCount,
  showModal,
  toggleModal,
  transferLootPrize,
  updateTransferCustomPrize,
}) => {
  const ref = useRef();
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [scrollRef, setScrollRef] = useState(null);
  const [page, setPage] = useState(1);
  const [loadingTransferPrize, setLoadingTransferPrize] = useState(false);
  const [sorting, setSorting] = useState({ sort: "createdAt", order: "desc" });

  const searchFilters = useCallback(
    (searchPage = 1, sorting) => {
      const params = { page: searchPage, ...sorting, limit: 10 };
      if (searchPage === 1) setLoading(true);
      else setLoadingMore(true);
      loadMore(userId, params, searchPage === 1, () => (searchPage === 1 ? setLoading(false) : setLoadingMore(false)));
    },
    [userId, loadMore],
  );
  useEffect(() => {
    if (userId) {
      setPage(1);
      searchFilters(1, { sort: "createdAt", order: "desc" });
    }
  }, [userId, searchFilters]);
  const handleLoadMore = useCallback(
    (entries) => {
      const target = entries[0];
      if (target?.isIntersecting) {
        if (!loadingMore && lootboxCustomPrizes?.length < totalCount) {
          searchFilters(page + 1, sorting);
          setPage(page + 1);
        }
      }
    },
    [loadingMore, lootboxCustomPrizes?.length, totalCount, page, searchFilters, sorting],
  );
  useEffect(() => {
    const options = {
      threshold: 0.2,
    };
    // Observer API
    const observer = new IntersectionObserver(handleLoadMore, options);

    if (observer && scrollRef) {
      observer.observe(scrollRef);
    }
    return () => {
      observer.disconnect();
    };
  }, [handleLoadMore, scrollRef]);
  const toggleTransfer = (e, id) => {
    e.preventDefault();
    ref?.current?.continuousStart();
    setLoadingTransferPrize(true);
    transferLootPrize(id, (res) => {
      if (res.status === 200) {
        updateTransferCustomPrize(id);
        toast(<AlertSuccess message="Transfer status updated" />, {
          containerId: 1,
        });
      } else {
        toast(<AlertError message={res.data && res.data.message} />, {
          containerId: 1,
        });
      }
      ref?.current?.complete();
      setLoadingTransferPrize(false);
    });
  };
  const sort = (e, type) => {
    if (e) e.preventDefault();
    const data = {};
    if (type === 1) {
      if (sorting?.sort === "transactionStatus" && sorting?.order === "desc") {
        data.sort = "transactionStatus";
        data.order = "asc";
      } else {
        data.sort = "transactionStatus";
        data.order = "desc";
      }
    } else if (type === 2) {
      if (sorting?.sort === "createdAt" && sorting?.order === "desc") {
        data.sort = "createdAt";
        data.order = "asc";
      } else {
        data.sort = "createdAt";
        data.order = "desc";
      }
    }
    setSorting(data);
    setPage(1);
    searchFilters(1, data, true);
  };

  return (
    <Offcanvas
      className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2"}
      show={showModal}
      onHide={toggleModal}
      id="rewardPannel"
      placement="right"
      name="rewardPannel"
      backdrop={true}
    >
      <Loader loaderRef={ref} />
      <Offcanvas.Header className="offCanvas-header-2">
        <div>
          <h5>Custom Prizes</h5>
          <div className="td-img-group d-flex align-items-start m-top-2">
            <div className="round-img m-right-2">
              {user?.image ? (
                <img src={process.env.REACT_APP_CDN + user?.image?.small} alt="" />
              ) : (
                <img src={userIcon} alt="" />
              )}
            </div>
            <span>
              {user?.userName}
              <br />
              {user?.email}
            </span>
          </div>
        </div>
        <div className="btn_close pointer" data-bs-dismiss="offcanvas" aria-label="Close" onClick={toggleModal}>
          <img src={closeIcon} alt="" />
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="fwc-body">
          {!loading && (
            <div className="table-responsive">
              {lootboxCustomPrizes?.length === 0 ? (
                <div className="fwc-body">
                  <div className="no-record-found-container">
                    <div className="nfr-box">
                      <img src={emptyIcon} alt="No Record Found" />
                      <div className="nrf-text">
                        <h5>No Records Found!</h5>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <table className="table large-table">
                  <thead>
                    <tr>
                      <th>Lootbox</th>
                      <th>Custom Prize</th>
                      <th>Value in USD</th>
                      <th>
                        Transaction Status
                        <a href="#!" onClick={(e) => sort(e, 1)}>
                          <img
                            src={
                              sorting?.sort === "transactionStatus" && sorting?.order === "desc"
                                ? arrowIcon1
                                : sorting?.sort === "transactionStatus" && sorting?.order === "asc"
                                ? arrowIcon2
                                : arrowIcon
                            }
                            alt=""
                          />
                        </a>
                      </th>
                      <th>
                        Date
                        <a href="#!" onClick={(e) => sort(e, 2)}>
                          <img
                            src={
                              sorting?.sort === "createdAt" && sorting?.order === "desc"
                                ? arrowIcon1
                                : sorting?.sort === "createdAt" && sorting?.order === "asc"
                                ? arrowIcon2
                                : arrowIcon
                            }
                            alt=""
                          />
                        </a>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {lootboxCustomPrizes?.map((prize, index) => (
                      <tr
                        ref={(ref) => {
                          index === lootboxCustomPrizes.length - 1 &&
                            lootboxCustomPrizes.length < totalCount &&
                            setScrollRef(ref);
                        }}
                      >
                        <td>
                          <div className="d-flex">
                            <div className="td-img m-right-2">
                              <img
                                src={
                                  prize?.lootBox?.image
                                    ? process.env.REACT_APP_CDN + prize?.lootBox?.image?.small
                                    : userIcon
                                }
                                alt=""
                              />
                            </div>
                            {prize?.lootBox?.title}
                          </div>
                        </td>

                        <td>
                          <div className="d-flex">
                            <div className="td-img m-right-2">
                              <img
                                src={
                                  prize?.lootBoxPrize?.image
                                    ? process.env.REACT_APP_CDN + prize?.lootBoxPrize?.image?.small
                                    : userIcon
                                }
                                alt=""
                              />
                            </div>
                            {prize?.lootBoxPrize?.title}
                          </div>
                        </td>
                        <td>
                          {prize?.lootBoxPrize?.usdValue?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) ?? 0}
                        </td>
                        <td className="ust-tag">
                          <Dropdown className="custom-dropdown">
                            <Dropdown.Toggle
                              id="tdDropdownOne"
                              className="custom-dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              disabled={prize?.transactionStatus === 2 || loadingTransferPrize}
                            >
                              <span
                                className={`w-100 text-center d-block ${
                                  prize?.transactionStatus === 1
                                    ? "inactive"
                                    : prize?.transactionStatus === 2
                                    ? "active"
                                    : "suspended"
                                }`}
                              >
                                {!isNaN(prize?.transactionStatus)
                                  ? ["PENDING", "COMPLETED", "CANCELLED", "FAILED"][prize?.transactionStatus - 1]
                                  : prize?.transactionStatus}
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                              <Dropdown.Item className="dropdown-item " onClick={(e) => toggleTransfer(e, prize?._id)}>
                                COMPLETED
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                        <td>
                          <div class="small-text">
                            {moment(prize?.createdAt).format("MM/DD/YYYY")}
                            <br /> <small> {moment(prize?.createdAt).format("hh:mm a")}</small>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>
        {loadingMore && (
          <div className="w-100 d-flex justify-content-center load-more">
            <img src={loadingIcon} className="fa-spin" alt="" />
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const mapDispatchToProps = (dispatch) => ({
  transferLootPrize: (id, callback) => dispatch(transferLootPrize(id, callback)),
  updateTransferCustomPrize: (id) => dispatch(updateTransferCustomPrize(id)),
});

export default withRouter(connect(null, mapDispatchToProps)(CustomPrizesFlyer));
