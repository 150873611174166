import React, { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loadSubscribers, getSettings, deleteSubscriber, deleteSubscriberReceived } from "store/settings";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import _ from "lodash";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";
import historyIcon from "include/images/history-icon.svg";
import emptyIcon from "include/images/nrf.svg";

//  Components
import Form from "common/form/form";
import Header from "common/header";
import SideBar from "common/sidebar";
import Loader from "common/loader";
import DeleteModal from "common/alert/alertModal";
import AlertSuccess from "common/alert/alertSuccess";
import HistoryFlyer from "common/historyFlyer";
import AlertError from "common/alert/alertError";

class Listing extends Form {
  ref = createRef(null);
  state = {
    loading: false,
    errors: {},
    showDeleteModal: false,
    loadingDelete: false,
    deleteId: "",
    id: "",
    showHistory: false,
    objectId: "",
    object: null,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getSettings.loading && this.props.getSettings.loading !== prevProps.getSettings.loading)
      this.ref.current.complete();

    if (this.props.getSettings.loading && this.props.getSettings.loading !== prevProps.getSettings.loading)
      this.ref.current.continuousStart();
  };

  componentDidMount = () => {
    this.setState({ loading: true });
    this.props.loadSubscribers(() => {
      this.setState({ loading: false });
    });
  };
  toggleHistoryFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showHistory: !this.state.showHistory });
  };
  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  handleDelete = (e) => {
    if (e) e.preventDefault();
    this.setState({ loadingDelete: true });
    this.props.deleteSubscriberReceived({ id: this.state.id });
    this.props.deleteSubscriber(this.state.deleteId, (subscriberRes) => {
      if (subscriberRes.status == 200) {
        toast(<AlertSuccess message="Deleted!" />, {
          containerId: 1,
        });

        this.alertModalToggle();
        this.setState({ loadingDelete: false });
      } else {
        toast(<AlertError message={subscriberRes.data && subscriberRes.data.message} />, {
          containerId: 1,
        });
        this.alertModalToggle();
        this.setState({ loadingDelete: false });
      }
    });
  };

  render() {
    const { newsletterSubscribers, totalCount } = this.props.getSettings;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="customSliders" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Newsletter Subscribers {totalCount > 0 && `(${totalCount})`}</h1>{" "}
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                {" "}
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState(
                        {
                          objectId: "",
                          object: null,
                        },
                        () => {
                          this.toggleHistoryFlyer();
                        },
                      );
                    }}
                  >
                    <img src={historyIcon} /> <span>History</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="fwc-wrapper">
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {newsletterSubscribers.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th style={{ width: 75, textAlign: "center" }}>Actions</th>
                            <th>Email </th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {newsletterSubscribers.map((subscriber, i) => (
                            <tr>
                              <td>
                                <Dropdown className=" custom-dropdown">
                                  <Dropdown.Toggle
                                    id="tdDropdownOne"
                                    className="custom-dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img src={carrentIcon} alt="" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                    <Dropdown.Item
                                      className="dropdown-item delete"
                                      onClick={(e) => {
                                        this.setState({ deleteId: subscriber.email, id: subscriber.id }, () =>
                                          this.alertModalToggle(e),
                                        );
                                      }}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>{subscriber.email}</td>{" "}
                              <td>{moment(subscriber.updatedAt).format("MM/DD/YYYY hh:mm A")}</td>{" "}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <DeleteModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showDeleteModal}
          title="Alert"
          description="Are you sure you want to delete this item?"
          onPress={this.handleDelete}
          loading={this.state.loadingDelete}
        />
        <HistoryFlyer
          showModal={this.state.showHistory}
          toggleModal={this.toggleHistoryFlyer}
          moduleName={"newsletterSubscriber"}
          objectId={this.state.objectId}
          title={this.state.object && this.state.object.email}
        />
        {this.state.showHistory && <div className="modal-backdrop show fade" onClick={this.toggleHistoryFlyer}></div>}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadSubscribers: (callback) => dispatch(loadSubscribers(callback)),
  deleteSubscriber: (email, callback) => dispatch(deleteSubscriber(email, callback)),
  deleteSubscriberReceived: (payLoad) => dispatch(deleteSubscriberReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Listing));
