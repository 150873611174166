import Amplify, { Auth, Storage } from "aws-amplify";
import Joi from "joi-browser";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";
import plusIcon from "include/images/plus-1.svg";

//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import SelectSearch from "common/form/selectSearch";
import NextButton from "common/form/submitButton";
import { addPrize, getLootbox, loadPrizes, prizesReceived } from "store/lootbox";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});

class AddPrizes extends Form {
  initialState = {
    loading: false,
    loadingPrizes: true,
    data: [
      {
        fulfillment: {
          name: "Automatic",
          value: 1,
        },
        amountType: null,
        title: "",
        image: null,
        value: 0,
        icon: null,
        percentDistribution: 0,
        limit: "",
        usdValue: "",
        customValueText: "",
      },
    ],
    errors: [{}],
    imageLoading: false,
    imageChange: [],
    iconsLoading: false,
    iconChange: [],
  };
  state = this.initialState;
  titleOptions = [
    {
      name: "Arcade Coins",
      value: "Arcade Coins",
    },
    {
      name: "Tickets",
      value: "Tickets",
    },
    {
      name: "Loot Bucks",
      value: "Loot Bucks",
    },
  ];
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal && this.props.lootboxId) {
        if (this.props.shouldLoad) {
          this.props.loadPrizes(this.props.lootboxId, (res) => {
            if (res.status === 200) {
              const prizes = res.data.data;
              this.stateFromEditData(prizes);
            } else {
              toast(<AlertError message={res.data && res.data.message} />, {
                containerId: 1,
              });
            }
          });
        } else this.stateFromEditData(this.props.getLootbox?.prizes);
      }
    }
  };
  stateFromEditData = (prizes) => {
    if (prizes?.length === 0) return this.setState({ loadingPrizes: false });

    const data = prizes?.map((prize) => ({
      _id: prize?._id,
      fulfillment:
        prize?.fulfillment === 1
          ? {
              name: "Automatic",
              value: 1,
            }
          : {
              name: "Manual",
              value: 2,
            },
      title: prize?.title,
      image: prize?.image,
      amountType: this.amountTypeOptions?.find((type) => type?.value === prize?.amountType),

      value: parseFloat(prize?.value),
      icon: prize?.valueIcon,
      percentDistribution: parseFloat(prize?.percentDistribution),
      limit: prize?.limit ? parseInt(prize?.limit) : "",
      usdValue: prize?.usdValue && prize?.fulfillment !== 1 ? parseFloat(prize?.usdValue) : "",
      customValueText: prize?.customValueText && prize?.fulfillment !== 1 ? prize?.customValueText : "",
    }));
    this.setState({ data, loadingPrizes: false });
  };
  doSubmit = (e) => {
    const data = structuredClone(this.state.data);
    const errors = data?.map((prize, index) => this.validateCustomData(prize));
    this.setState({ errors });

    if (errors?.find((err) => err)) {
      return;
    } else {
      this.setState({ loading: true });
    }
    const payLoad = data?.map((prize, index) => {
      const prizePayload = {
        fulfillment: parseInt(prize?.fulfillment?.value),
        title: prize?.title,
        image: prize?._id && !this.state.imageChange[index] ? prize?.image : prize?.image?.medium,
        amountType: parseInt(prize?.amountType?.value),
        value: parseFloat(prize?.value),
        percentDistribution: parseFloat(prize?.percentDistribution),
        limit: prize?.limit ? parseInt(prize?.limit) : null,
      };
      if (prize?._id) prizePayload._id = prize?._id;
      if (prize?.usdValue) prizePayload.usdValue = parseFloat(prize?.usdValue);
      if (prize?.customValueText) prizePayload.customValueText = prize?.customValueText;
      if (prize?.icon)
        prizePayload.valueIcon = prize?._id && !this.state.iconChange[index] ? prize?.icon : prize?.icon?.medium;

      return prizePayload;
    });

    this.props.addPrize(this.props.lootboxId, payLoad, (prizeResponse) => {
      this.setState({
        loading: false,
      });
      if (prizeResponse.status === 200) {
        toast(<AlertSuccess message="Information Saved" />, {
          containerId: 1,
        });
        this.props.prizesReceived(prizeResponse.data);
        this.clearData();
        this.props.toggleModal();
      } else {
        toast(<AlertError message={prizeResponse.data && prizeResponse.data.message} />, {
          containerId: 1,
        });
      }
    });
  };
  schema = {
    _id: Joi.string(),
    fulfillment: Joi.object().keys({
      data: Joi.string(),
      name: Joi.string().required(),
      value: Joi.number().required(),
    }),
    title: Joi.string().required(),
    image: Joi.object().required(),
    amountType: Joi.object().keys({
      data: Joi.string(),
      name: Joi.string().required(),
      value: Joi.number().required(),
    }),
    value: Joi.number().required(),
    icon: Joi.object().allow(null),
    percentDistribution: Joi.number().min(0.01).max(100).required(),
    limit: Joi.number().min(1).allow(["", null]),
    usdValue: Joi.when("fulfillment.value", {
      is: 2,
      then: Joi.number().min(1).required(),
      otherwise: Joi.number().allow(""),
    }),
    customValueText: Joi.string().allow(""),
  };
  amountTypeOptions = [
    {
      name: "ARCADE",
      value: 1,
    },
    {
      name: "TICKETS",
      value: 2,
    },
    {
      name: "LOOT",
      value: 3,
    },
    {
      name: "USDT",
      value: 4,
    },
    {
      name: "CUSTOM",
      value: 5,
    },
  ];
  clearData = () => {
    this.setState(this.initialState);
  };
  addOne = (e) => {
    if (e) e.preventDefault();
    const data = [
      ...this.state.data,
      {
        fulfillment: {
          name: "Automatic",
          value: 1,
        },
        title: "",
        image: null,
        amountType: null,
        value: 0,
        icon: null,
        percentDistribution: 0,
        limit: "",
        usdValue: "",
        customValueText: "",
      },
    ];
    const errors = [...this.state.errors, {}];
    this.setState({ data, errors });
  };
  removeOne = (index) => {
    const data = structuredClone(this.state.data);
    const errors = structuredClone(this.state.errors);

    data?.splice(index, 1);
    errors?.splice(index, 1);
    this.setState({ data, errors });
  };
  changeOne = (name, value, index) => {
    const data = structuredClone(this.state.data);
    const errors = structuredClone(this.state.errors);
    if (!errors[index]) errors[index] = {};
    const err = errors[index];
    if (err[name]) {
      delete err[name];
    }
    const newData = data[index];
    if (name === "fulfillment" && newData[name]?.value !== value?.value) {
      newData["title"] = "";
      newData["usdValue"] = "";
      newData["customValueText"] = "";
      newData["amountType"] = value?.value === 2 ? this.amountTypeOptions[4] : null;
    }
    newData[name] = value;
    if (data[index]?.fulfillment?.value === 1 && name === "title") {
      newData["amountType"] = this.amountTypeOptions[this.titleOptions?.findIndex((option) => value === option?.value)];
      delete err["amountType"];
    }
    data[index] = newData;
    this.setState({ data, errors });
  };

  handleImageChange = (e, index) => {
    this.setState({ [e.target.name + "Loading"]: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ [e.target.name + "Loading"]: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ [e.target.name + "Loading"]: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.changeOne(e.target.name, { medium: "public/" + result.key }, index);
        const change = [...this.state[e.target.name + "Change"]];
        change[index] = true;
        this.setState({
          [e.target.name + "Change"]: change,
          [e.target.name + "Loading"]: false,
        });
      });
    }
    e.target.value = null;
  };
  render() {
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel almost-full-modal"}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>
            {this.props.edit ? "Edit " : "Add "}
            Prizes
          </h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            {!this.state.loadingPrizes && (
              <div className="new-form-group">
                <table className="table large-table flyer-table">
                  <thead>
                    <tr>
                      <th style={{ width: 100 }}>Fulfillment</th>
                      <th style={{ width: 142 }}>Title</th>
                      <th style={{ width: "4%" }}>Image</th>
                      <th style={{ width: 100 }}>Amount Type</th>
                      <th style={{ width: 20 }}>Value</th>
                      <th style={{ width: 100 }}>Value Text</th>
                      <th style={{ width: 20 }}>Usd Value</th>
                      <th style={{ width: "4%" }}>Icon</th>
                      <th style={{ width: "10%" }}>% Distribution</th>
                      <th style={{ width: "8%" }}>Limit</th>
                      <th style={{ width: "4%" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.map((prize, index) => (
                      <tr className="flyer-table" key={index}>
                        <td>
                          <SelectSearch
                            name="fulfillment"
                            options={[
                              {
                                name: "Automatic",
                                value: 1,
                              },
                              {
                                name: "Manual",
                                value: 2,
                              },
                            ]}
                            label="Select Fulfillments"
                            value={this.state.data[index]?.fulfillment}
                            error={this.state.errors[index]?.fulfillment}
                            onChange={(e) => {
                              this.changeOne(e.name, e.currentTarget, index);
                            }}
                          />
                        </td>
                        <td>
                          {prize.fulfillment?.value === 1 ? (
                            <SelectSearch
                              name="title"
                              options={this.titleOptions}
                              label="Select Title"
                              value={this.titleOptions?.find(({ value }) => value === this.state.data[index]?.title)}
                              error={this.state.errors[index]?.title}
                              onChange={(e) => {
                                this.changeOne(e.name, e.currentTarget.value, index);
                              }}
                            />
                          ) : (
                            <input
                              name="title"
                              value={prize.title}
                              className={`form-control px-2 ${this.state.errors[index]?.title && "error"}`}
                              onChange={(e) => {
                                this.changeOne(e.target.name, e.target.value, index);
                              }}
                            />
                          )}
                        </td>
                        <td className="d-flex flex-wrap align-items-start">
                          <div>
                            <div
                              className={`kt-upload-outer-small ${
                                this.state.imageLoading ? "pointer-none" : "pointer"
                              } ${!prize.image ? "placeholder-uploaded-img" : " position-relative"} ${
                                this.state.errors[index]?.image && "border-error"
                              }`}
                            >
                              <div className="kt-upload-img">
                                <img
                                  src={
                                    prize?.image
                                      ? prize?.image?.medium?.includes("https://")
                                        ? prize?.image.medium
                                        : process.env.REACT_APP_CDN + prize?.image?.medium
                                      : plusIcon
                                  }
                                  alt=""
                                />
                                <input
                                  className="file-d-input"
                                  type="file"
                                  name="image"
                                  accept="image/*"
                                  onChange={(e) => {
                                    this.handleImageChange(e, index);
                                  }}
                                />
                              </div>
                              {prize?.image && (
                                <div
                                  className="delete-icon d-flex align-items-center justify-content-center"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const data = [...this.state.data];

                                    data[index].image = null;
                                    this.setState({ data });
                                  }}
                                >
                                  <img src={deleteIcon} alt="delete" />
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <SelectSearch
                            name="amountType"
                            options={this.amountTypeOptions}
                            value={this.state.data[index]?.amountType}
                            error={this.state.errors[index]?.amountType}
                            onChange={(e) => {
                              this.changeOne(e.name, e.currentTarget, index);
                            }}
                            isDisabled={true}
                          />
                        </td>
                        <td>
                          <input
                            name="value"
                            value={prize?.value}
                            type="number"
                            className={`form-control px-2 ${this.state?.errors[index]?.value && "error"}`}
                            onChange={(e) => {
                              this.changeOne(e.target.name, e.target.value, index);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            name="customValueText"
                            value={prize?.customValueText}
                            type="text"
                            className={`form-control px-2 ${this.state?.errors[index]?.customValueText && "error"}`}
                            disabled={prize?.fulfillment?.value === 1}
                            onChange={(e) => {
                              this.changeOne(e.target.name, e.target.value, index);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            name="usdValue"
                            value={prize?.usdValue}
                            type="number"
                            className={`form-control px-2 ${this.state?.errors[index]?.usdValue && "error"}`}
                            disabled={prize?.fulfillment?.value === 1}
                            onChange={(e) => {
                              this.changeOne(e.target.name, e.target.value, index);
                            }}
                          />
                        </td>
                        <td className="d-flex flex-wrap align-items-start">
                          <div>
                            <div
                              className={`kt-upload-outer-small ${
                                this.state.iconsLoading ? "pointer-none" : "pointer"
                              } ${!prize.icon ? "placeholder-uploaded-img" : " position-relative"} ${
                                this.state?.errors[index]?.icon && "border-error"
                              }`}
                            >
                              <div className="kt-upload-img">
                                <img
                                  src={
                                    prize?.icon
                                      ? prize?.icon?.medium?.includes("https://")
                                        ? prize?.icon.medium
                                        : process.env.REACT_APP_CDN + prize?.icon?.medium
                                      : plusIcon
                                  }
                                  alt=""
                                />
                                <input
                                  className="file-d-input"
                                  type="file"
                                  name="icon"
                                  accept="image/*"
                                  onChange={(e) => {
                                    this.handleImageChange(e, index);
                                  }}
                                />
                              </div>
                              {prize?.icon && (
                                <div
                                  className="delete-icon d-flex align-items-center justify-content-center"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const data = [...this.state.data];
                                    data[index].icon = null;
                                    this.setState({ data });
                                  }}
                                >
                                  <img src={deleteIcon} alt="delete" />
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <input
                            name="percentDistribution"
                            value={prize.percentDistribution}
                            type="number"
                            className={`form-control px-2 ${this.state?.errors[index]?.percentDistribution && "error"}`}
                            onChange={(e) => {
                              this.changeOne(e.target.name, e.target.value, index);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            name="limit"
                            value={prize?.limit}
                            type="number"
                            className={`form-control px-2 ${this.state?.errors[index]?.limit && "error"}`}
                            onChange={(e) => {
                              this.changeOne(e.target.name, e.target.value, index);
                            }}
                          />
                        </td>
                        <td>
                          <img
                            src={closeIcon}
                            alt=""
                            className="pointer"
                            onClick={(e) => {
                              this.removeOne(index);
                            }}
                          />{" "}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>{" "}
                <div className="new-form-group new-form-btn">
                  <NextButton
                    handleSubmit={this.addOne}
                    label="+ Add more"
                    loading={this.state.loadingContest || this.state.imageLoading || this.state.iconsLoading}
                    classData={"btn btn-primary btn-sm orange-hover"}
                  />
                </div>
              </div>
            )}
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loading || this.state.imageLoading || this.state.iconsLoading}
            handleSubmit={this.doSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addPrize: (id, data, callback) => dispatch(addPrize(id, data, callback)),
  loadPrizes: (id, callback) => dispatch(loadPrizes(id, callback)),
  prizesReceived: (data) => dispatch(prizesReceived(data)),
});
const mapStateToProps = (state) => ({
  getLootbox: getLootbox(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddPrizes));
