import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import closeIcon from "include/images/dark-close-icon.svg";
import loadingIcon from "include/images/loader-grey.svg";
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";

const ViewAllFlyer = ({ players, loadMore, pagination, showModal, toggleModal, toggleCustomPrizesModal }) => {
  const [loadingMore, setLoadingMore] = useState(false);
  const [scrollRef, setScrollRef] = useState(null);

  const handleLoadMore = useCallback(
    (entries) => {
      const target = entries[0];
      if (target?.isIntersecting) {
        if (!loadingMore && pagination?.currentPage < pagination?.totalPages) {
          setLoadingMore(true);
          const params = { page: pagination?.currentPage + 1 };
          loadMore(params, false, () => setLoadingMore(false));
        }
      }
    },
    [loadingMore, pagination, loadMore],
  );
  useEffect(() => {
    const options = {
      threshold: 0.2,
    };
    // Observer API
    const observer = new IntersectionObserver(handleLoadMore, options);

    if (observer && scrollRef) {
      observer.observe(scrollRef);
    }
    return () => {
      observer.disconnect();
    };
  }, [handleLoadMore, scrollRef]);

  return (
    <Offcanvas
      className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2 "}
      show={showModal}
      onHide={toggleModal}
      id="rewardPannel"
      placement="right"
      name="rewardPannel"
      backdrop={true}
    >
      <Offcanvas.Header className="offCanvas-header-2">
        <div>
          <h5>Winners</h5>
        </div>
        <div className="btn_close pointer" data-bs-dismiss="offcanvas" aria-label="Close" onClick={toggleModal}>
          <img src={closeIcon} alt="" />
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="fwc-body">
          <div className="table-responsive">
            {players?.length === 0 ? (
              <div className="fwc-body">
                <div className="no-record-found-container">
                  <div className="nfr-box">
                    <img src={emptyIcon} alt="No Record Found" />
                    <div className="nrf-text">
                      <h5>No Records Found!</h5>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table custom-table-striped">
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Total Spins</th>
                      <th>Loot Bucks Spent</th>
                      <th>Arcade Coins Won</th>
                      <th>Loot Bucks Won</th>
                      <th>Tickets Won</th>
                      <th>Custom Prizes Won in USD</th>
                    </tr>
                  </thead>
                  <tbody>
                    {players?.map((winner, index) => (
                      <tr
                        key={winner?._id}
                        ref={(ref) => {
                          index === players.length - 1 &&
                            pagination?.currentPage < pagination?.totalPages &&
                            setScrollRef(ref);
                        }}
                      >
                        <td className="d-flex">
                          <div className="td-img round-img m-right-2">
                            <img
                              src={winner?.userImage ? process.env.REACT_APP_CDN + winner?.userImage?.small : userIcon}
                              alt=""
                            />
                          </div>
                          {winner?.userName}
                        </td>
                        <td>{winner?.totalSpins?.toLocaleString("en-us")}</td>
                        <td>
                          {winner?.totalLootBuckSpend?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) ?? 0}
                        </td>
                        <td>{winner?.totalArcadeWon?.toLocaleString("en-us") ?? 0}</td>
                        <td>
                          {winner?.totalLootWon?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) ?? 0}
                        </td>
                        <td>{winner?.totalTicketsWon?.toLocaleString("en-us") ?? 0}</td>
                        <td>
                          <a
                            href="#!"
                            onClick={(e) =>
                              toggleCustomPrizesModal(
                                e,
                                {
                                  userName: winner?.userName,
                                  image: winner?.userImage,
                                  email: winner?.email,
                                },
                                winner?.userId,
                              )
                            }
                          >
                            {winner?.totalCustomWon?.toLocaleString("en-us") ?? 0}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        {loadingMore && (
          <div className="w-100 d-flex justify-content-center load-more">
            <img src={loadingIcon} className="fa-spin" alt="" />
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ViewAllFlyer;
