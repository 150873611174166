import React, { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import contest, {
  loadContests,
  getContest,
  deleteContest,
  cancelContest,
  markAsFeaturedContest,
  deleteContestsReceived,
  markAsFeaturedReceived,
  cancelContestReceived,
  markAsFeaturedPendingContest,
  markAsFeaturedPendingContestsReceived,
} from "store/contest";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { ethToUSD } from "utils/externalApiCaller";
import Joi from "joi-browser";
import _ from "lodash";
import moment from "moment-timezone";
import { convertDate, formatDate } from "utils/dateConversion";

//  Images
import plusIcon from "include/images/plus.svg";
import dotsIcon from "include/images/dots.svg";
import emptyIcon from "include/images/nrf.svg";
import closeIcon from "include/images/close-icon.svg";
import filterIcon from "include/images/filter-icon.svg";
import historyIcon from "include/images/history-icon.svg";
import creditIcon from "include/images/burnghost-credit.svg";
import starIcon from "include/images/1-star.svg";
import loader from "include/images/loading-icon.svg";
import starIcon1 from "include/images/star-fill.svg";
import sortIcon from "include/images/sort-icon.svg";
import loadingIcon from "include/images/loader-grey.svg";

//  Components
import Form from "common/form/form";
import Header from "common/header";
import SideBar from "common/sidebar";
import Loader from "common/loader";
import DeleteModal from "common/alert/alertModal";
import AlertSuccess from "common/alert/alertSuccess";
import SelectSearch from "common/form/selectSearch";
import AlertError from "common/alert/alertError";
import ListingFlyer from "./gameListingFlyer";
import NextButton from "common/form/submitButton";
import HistoryFlyer from "common/historyFlyer";

class contestListing extends Form {
  ref = createRef(null);
  scrollRef = createRef(null);
  state = {
    data: {
      status: {
        name: "Running",
        value: "Running",
      },
      title: "",
      startDate: null,
      endDate: null,
    },
    object: null,

    loading: false,
    showDeleteModal: false,
    showCancelModal: false,
    loadingDelete: false,
    loadingFilters: false,
    loadingCancel: false,
    showFilters: false,
    deleteId: "",
    loadingReset: false,
    errors: {},
    showGameList: false,
    contest: "",
    cancelId: "",
    bookMarkInd: "",
    markAsFeaturedLoading: false,
    appliedFilters: {
      status: {
        name: "Running",
        value: "Running",
      },
    },
    objectId: "",
    showHistory: false,
    settingsLoading: false,
    amountType: 1,
    currKey: "",
    loadingMore: false,
  };
  handleLoadMore = (entries) => {
    const target = entries[0];
    if (target?.isIntersecting) {
      const { contests, totalCount, filteredCount } = this.props.getContest;

      if (!this.state.loadingMore && contests?.length < totalCount) {
        this.setState({ loadingMore: true });
        this.searchFilters();
        this.observer.disconnect();
      }
    }
  };
  observer = new IntersectionObserver(this.handleLoadMore, {
    threshold: 0.2,
  });
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getContest.loading && this.props.getContest.loading !== prevProps.getContest.loading)
      this.ref.current.complete();

    if (this.props.getContest.loading && this.props.getContest.loading !== prevProps.getContest.loading)
      this.ref.current.continuousStart();

    if (this.state.currKey !== prevState.currKey && this.state.currKey !== null) {
      if (this.observer && this.scrollRef?.current) {
        this.observer.observe(this.scrollRef.current);
      }
    }
  };
  toggleHistoryFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showHistory: !this.state.showHistory });
  };

  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  alertCancelModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showCancelModal: !this.state.showCancelModal });
  };
  componentDidMount = () => {
    const status = this.state.data.status;
    this.setState({ loading: true });

    this.props.loadContests({ status: status?.value }, true, (res) => {
      this.setState({
        loading: false,
        currKey: res?.data?.lastKey,
      });
    });
  };
  handleDelete = (e) => {
    if (e) e.preventDefault();
    this.setState({ loadingDelete: true });

    this.props.deleteContest(this.state.deleteId, (contestResponse) => {
      if (contestResponse.status === 200) {
        toast(<AlertSuccess message="Deleted!" />, {
          containerId: 1,
        });
        this.props.deleteContestsReceived({ id: this.state.deleteId });
        this.alertModalToggle();
        this.setState({ loadingDelete: false });
      } else {
        toast(<AlertError message={contestResponse.data && contestResponse.data.message} />, {
          containerId: 1,
        });
        this.setState({ loadingDelete: false });
        this.alertModalToggle();
      }
    });
  };
  handleCancel = (e) => {
    if (e) e.preventDefault();
    this.setState({ loadingCancel: true });
    this.props.cancelContest(this.state.cancelId, (contestResponse) => {
      if (contestResponse.status === 200) {
        toast(<AlertSuccess message="Canceled!" />, {
          containerId: 1,
        });

        this.props.cancelContestReceived({
          id: this.state.cancelId,
        });
        this.alertCancelModalToggle();
        this.setState({ loadingCancel: false });
      } else {
        toast(<AlertError message={contestResponse.data && contestResponse.data.message} />, {
          containerId: 1,
        });
        this.setState({ loadingCancel: false });
        this.alertCancelModalToggle();
      }
    });
  };

  //  Filters
  schema = {
    title: Joi.string().allow(""),
    status: Joi.object().allow({}),
    startDate: Joi.date().allow("", null),
    endDate: Joi.date().allow("", null),
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };

  searchFilters = (e) => {
    if (e) e.preventDefault();

    this.setState({ loadingFilters: true });
    const { title, status, startDate, endDate } = this.state.data;

    const payLoad = {
      title,
      status: status?.value,
    };
    var params = _.pickBy(payLoad, function (value, key) {
      return !_.isEmpty(value);
    });
    if (startDate) {
      params.startDate = parseInt(formatDate(startDate, "x"));
    }
    if (endDate) {
      params.endDate = parseInt(formatDate(endDate, "x"));
    }
    if (this.state.currKey) params.lastKey = this.state.currKey;
    this.props.loadContests(params, this.state.currKey?.length === 0, (contestRes) => {
      this.setState({
        loadingFilters: false,
        loadingDelete: false,
        showDeleteModal: false,
        showCancelModal: false,
        loadingMore: false,
      });
      if (contestRes.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).forEach((key) => {
          if (key === "startDate" || key === "endDate") {
            data[key] = parseInt(formatDate(data[key], "x"));
          }
          if (!_.isEmpty(data[key]) || ((key === "startDate" || key === "endDate") && data[key])) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({ appliedFilters, currKey: contestRes?.data?.lastKey });
      }
    });
  };

  resetFilters = (e) => {
    e.preventDefault();
    this.setState(
      {
        data: {
          status: {
            name: "Running",
            value: "Running",
          },
          title: "",
          startDate: null,
          endDate: null,
        },
        currKey: "",
      },
      () => this.searchFilters(),
    );
  };
  removeFilter = (type) => {
    const data = this.state.data;
    if (type === "status") {
      data[type] = {};
    } else if (type === "startDate" || type === "endDate") {
      data[type] = null;
    } else {
      data[type] = "";
    }
    this.setState({ data, currKey: "" }, this.searchFilters);
  };
  getEndDate = () => {
    if (this.state.data.startDate) {
      var startDate = new Date(this.state.data.startDate);
      var endDate = new Date(startDate.getTime());
      return endDate;
    } else {
      return null;
    }
  };
  toggleGameFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showGameList: !this.state.showGameList });
  };
  onRefChange = (node, contests, i, totalCount) => {
    if (i === contests.length - 1 && contests.length < totalCount) {
      this.scrollRef.current = node;
    }
  };
  render() {
    const status = this.state.appliedFilters?.status?.value;
    const { contests, totalCount, filteredCount } = this.props.getContest;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="contests" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1 className="camelCase">
                {status} {""}
                Contests {totalCount > 0 && `(${totalCount})`}
              </h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                {" "}
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState(
                        {
                          historyType: "all",
                          objectId: "",
                          object: null,
                        },
                        () => {
                          this.toggleHistoryFlyer();
                        },
                      );
                    }}
                  >
                    <img src={historyIcon} /> <span>History</span>
                  </a>
                </div>
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        showFilters: !this.state.showFilters,
                      });
                    }}
                  >
                    <img src={filterIcon} /> <span>Filters</span>
                  </a>
                </div>
                <div className="fmb-box">
                  <Link to="/add-contest" className="btn btn-default btn-sm">
                    <img src={plusIcon} alt="" /> Add a Contest
                  </Link>
                </div>
              </div>
            </div>
            <div className="fwc-wrapper">
              {this.state.showFilters && (
                <div className="fwc-head ">
                  <div className="fwc-inner">
                    <ul className="filter-mode-list d-flex flex-wrap align-items-center">
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("title", "Keyword")}</div>
                      </li>
                      <li className="flex-fill">
                        <div className="fml-box">
                          <label className="form-label">Status</label>
                          <SelectSearch
                            name="status"
                            options={[
                              {
                                name: "Running",
                                value: "Running",
                              },
                              {
                                name: "Upcoming",
                                value: "Upcoming",
                              },
                              {
                                name: "Past",
                                value: "Ended",
                              },
                              {
                                name: "Invalid",
                                value: "Invalid",
                              },
                              {
                                name: "To Be Cancelled",
                                value: "To Be Cancelled",
                              },
                              {
                                name: "Cancelled",
                                value: "Cancelled",
                              },
                            ]}
                            label=""
                            value={this.state.data.status}
                            error={this.state.errors.status}
                            onChange={this.onChange}
                          />
                        </div>
                      </li>
                      <li className="flex-fill">
                        <div className="fml-box">
                          <div className="fml-input-group d-flex align-items-center">
                            <div className="fml-input-group-item flex-grow-1">
                              {" "}
                              {this.renderDateInput("startDate", "Start Date & Time ( In EST )", null)}
                            </div>
                            <div className="fml-input-group-item flex-grow-1">
                              {" "}
                              {this.renderDateInput("endDate", "End Date & Time ( In EST )", this.getEndDate())}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="fwc-btn d-flex align-items-center justify-content-end">
                      <NextButton
                        handleSubmit={this.resetFilters}
                        classData="btn-text pointer"
                        label="Reset"
                        loading={this.state.loadingReset}
                      />
                      <NextButton
                        handleSubmit={() => this.setState({ currKey: "" }, this.searchFilters)}
                        classData="btn btn-primary btn-sm"
                        label="Search"
                        loading={this.state.loadingFilters}
                      />
                    </div>
                  </div>{" "}
                  {!_.isEmpty(this.state.appliedFilters) && (
                    <div className="filter-tag-container d-flex align-items-center ">
                      <div className="fwc-left">
                        <div className="filter-tag-list">
                          <p>filter applied :</p>
                          {Object.keys(this.state.appliedFilters).map((key) => (
                            <div className="filter-tag-item">
                              <span className="camelCase">
                                {key === "status"
                                  ? "Status"
                                  : key === "startDate"
                                  ? "Start Date & Time ( In EST )"
                                  : key === "endDate"
                                  ? "End Date & Time ( In EST )"
                                  : key}
                              </span>{" "}
                              :{" "}
                              {key === "status"
                                ? this.state.appliedFilters[key].value
                                : key === "startDate" || key === "endDate"
                                ? formatDate(this.state.appliedFilters[key], "MM/DD/YYYY hh:mm A")
                                : this.state.appliedFilters[key]}
                              {key !== "status" && (
                                <a
                                  href="#!"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.removeFilter(key);
                                  }}
                                >
                                  <img src={closeIcon} alt="" />
                                </a>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.loading ? (
                <></>
              ) : contests.length === 0 ? (
                <>
                  {" "}
                  <div className="fwc-body">
                    <div className="no-record-found-container">
                      <div className="nfr-box">
                        <img src={emptyIcon} alt="No Record Found" />
                        <div className="nrf-text">
                          <h5>No Records Found!</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <ul className="grid-card-list d-flex flex-wrap">
                    {contests.map((contest, i) => (
                      <li
                        className="grid-card-item"
                        key={contest?.id}
                        ref={(node) => this.onRefChange(node, contests, i, totalCount)}
                      >
                        <div className="grid-card-box position-relative text-white">
                          <div className="gc-header d-flex flex-wrap">
                            <div className="gc-header-left width50">
                              <h5
                                className="pointer two-line-ellipsis width150"
                                style={{ marginBottom: 12 }}
                                onClick={(e) => {
                                  this.props.history.push({
                                    pathname: `/contests/${contest.slug}`,
                                    state: { contest: contest, status },
                                  });
                                }}
                              >
                                {contest.title}
                              </h5>
                              <h5
                                className="pointer two-line-ellipsis width150"
                                onClick={(e) => {
                                  this.props.history.push({
                                    pathname: `/contests/${contest.slug}`,
                                    state: { contest: contest, status },
                                  });
                                }}
                              >
                                {contest?.bgGame?.name}
                              </h5>
                            </div>
                            <div className="gc-header-right ms-auto d-flex flex-column align-items-end justify-content-end">
                              {" "}
                              <Dropdown className="grid-dropdown custom-dropdown">
                                <Dropdown.Toggle
                                  className="dropdown-toggle custom-dropdown-toggle"
                                  id="dropdownMenuLink"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img src={dotsIcon} alt="" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      this.props.history.push({
                                        pathname: `/contests/${contest.slug}`,
                                        state: { contest: contest, status },
                                      });
                                    }}
                                  >
                                    View
                                  </Dropdown.Item>{" "}
                                  {contest.status !== "Upcoming" && (
                                    <>
                                      <Dropdown.Divider></Dropdown.Divider>
                                      <Dropdown.Item
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          this.setState({ contest, amountType: 1 }, () => {
                                            this.toggleGameFlyer(e);
                                          });
                                        }}
                                      >
                                        View Arcade Games
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          this.setState({ contest, amountType: 3 }, () => {
                                            this.toggleGameFlyer(e);
                                          });
                                        }}
                                      >
                                        View Loot Bucks Games
                                      </Dropdown.Item>
                                      <Dropdown.Divider></Dropdown.Divider>
                                    </>
                                  )}
                                  {(contest.status === "Upcoming" || contest.status === "Running") && (
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.push({
                                          pathname: "/add-contest",
                                          state: { contest: contest },
                                        });
                                      }}
                                    >
                                      Edit
                                    </Dropdown.Item>
                                  )}
                                  {(contest.status === "Upcoming" || contest.status === "Running") && (
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                          markAsFeaturedLoading: true,
                                        });

                                        this.props.markAsFeaturedContest(contest.id, !contest.isFeatured, () => {
                                          this.props.markAsFeaturedReceived({
                                            id: contest.id,
                                            isFeatured: !contest.isFeatured,
                                          });
                                          this.setState({
                                            markAsFeaturedLoading: false,
                                          });
                                        });
                                      }}
                                    >
                                      {contest.isFeatured ? "Remove as Featured" : "Mark as Featured"}
                                    </Dropdown.Item>
                                  )}{" "}
                                  {contest.status === "Ended" && (
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                          markAsFeaturedLoading: true,
                                        });

                                        this.props.markAsFeaturedPendingContest(
                                          contest.id,
                                          !contest.featuredUnderWinnerAndPendingContests,
                                          () => {
                                            this.props.markAsFeaturedPendingContestsReceived({
                                              id: contest.id,
                                              isFeatured: !contest.featuredUnderWinnerAndPendingContests,
                                            });
                                            this.setState({
                                              markAsFeaturedLoading: false,
                                            });
                                          },
                                        );
                                      }}
                                    >
                                      {contest.featuredUnderWinnerAndPendingContests
                                        ? "Remove as Featured Under Closed Contests"
                                        : "Mark as Featured Under Closed Contests"}
                                    </Dropdown.Item>
                                  )}
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      this.setState(
                                        {
                                          objectId: contest.id,
                                          object: contest,
                                        },
                                        () => {
                                          this.toggleHistoryFlyer(e);
                                        },
                                      );
                                    }}
                                  >
                                    History
                                  </Dropdown.Item>
                                  {contest.status === "Running" && (
                                    <Dropdown.Item
                                      className="dropdown-item delete"
                                      onClick={(e) => {
                                        this.setState({ cancelId: contest.id }, () => this.alertCancelModalToggle(e));
                                      }}
                                    >
                                      Cancel
                                    </Dropdown.Item>
                                  )}
                                  {contest.status === "Upcoming" && (
                                    <Dropdown.Item
                                      className="dropdown-item delete"
                                      onClick={(e) => {
                                        this.setState({ deleteId: contest.id }, () => this.alertModalToggle(e));
                                      }}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                              <strong>
                                <span>{contest.endDate ? "Ends" : "Starts"} :</span>{" "}
                                {contest.endDate
                                  ? formatDate(contest.endDate, "MM/DD/YYYY hh:mm A")
                                  : formatDate(contest.startDate, "MM/DD/YYYY hh:mm A")}{" "}
                                EST
                              </strong>
                            </div>
                          </div>
                          <div className="gc-img-box">
                            <div
                              className="pc-image pointer"
                              onClick={(e) => {
                                this.props.history.push({
                                  pathname: `/contests/${contest.slug}`,
                                  state: { contest: contest, status },
                                });
                              }}
                            >
                              <img src={process.env.REACT_APP_CDN + contest?.bgGame?.thumbnailImage} alt="" />
                            </div>
                            {contest?.isPrivate && (
                              <div class="badge cancelled" style={{ left: 150 }}>
                                Private
                              </div>
                            )}
                            {contest?.nftContractAddress?.length > 0 && contest?.nftNetwork?.length > 0 && (
                              <div class="badge orange-bg" style={{ top: contest?.isPrivate ? 50 : 20 }}>
                                NFT Gating
                              </div>
                            )}

                            {(contest.status === "Upcoming" || contest.status === "Running") && (
                              <div
                                className="pointer add-grid-bookmark"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  this.setState({
                                    markAsFeaturedLoading: true,
                                    bookMarkInd: i,
                                  });

                                  this.props.markAsFeaturedContest(contest.id, !contest.isFeatured, () => {
                                    this.props.markAsFeaturedReceived({
                                      id: contest.id,
                                      isFeatured: !contest.isFeatured,
                                    });
                                    this.setState({
                                      markAsFeaturedLoading: false,
                                    });
                                  });
                                }}
                              >
                                <img
                                  className={
                                    this.state.markAsFeaturedLoading && i === this.state.bookMarkInd && "fa-spin"
                                  }
                                  src={
                                    this.state.markAsFeaturedLoading && i === this.state.bookMarkInd
                                      ? loader
                                      : contest.isFeatured
                                      ? starIcon1
                                      : starIcon
                                  }
                                  alt=""
                                />
                              </div>
                            )}
                            {contest.status === "Ended" && (
                              <div
                                className="pointer add-grid-bookmark"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  this.setState({
                                    markAsFeaturedLoading: true,
                                    bookMarkInd: i,
                                  });

                                  this.props.markAsFeaturedPendingContest(
                                    contest.id,
                                    !contest.featuredUnderWinnerAndPendingContests,
                                    () => {
                                      this.props.markAsFeaturedPendingContestsReceived({
                                        id: contest.id,
                                        isFeatured: !contest.featuredUnderWinnerAndPendingContests,
                                      });
                                      this.setState({
                                        markAsFeaturedLoading: false,
                                      });
                                    },
                                  );
                                }}
                              >
                                <img
                                  className={
                                    this.state.markAsFeaturedLoading && i === this.state.bookMarkInd && "fa-spin"
                                  }
                                  src={
                                    this.state.markAsFeaturedLoading && i === this.state.bookMarkInd
                                      ? loader
                                      : contest.featuredUnderWinnerAndPendingContests
                                      ? starIcon1
                                      : starIcon
                                  }
                                  alt=""
                                />
                              </div>
                            )}
                            {status === "all" && (
                              <div
                                class={`badge ${
                                  contest.status === "Running"
                                    ? "running"
                                    : contest.status === "Upcoming"
                                    ? "upcoming"
                                    : contest.status === "Ended"
                                    ? "past"
                                    : "cancelled"
                                }`}
                              >
                                To Be Canceled
                              </div>
                            )}
                          </div>

                          <div
                            className={`gc-footer gc-intro-group d-flex ${
                              contest?.contestType === 1 ? "justify-content-between" : "justify-content-center"
                            } w-100`}
                          >
                            {contest?.contestType !== 3 && (
                              <div
                                className={`gc-intro-item ${contest?.contestType === 1 ? "text-left" : "text-center"}`}
                              >
                                <h6>
                                  {contest?.arcadeCoinToBeAwarded
                                    ? contest?.arcadeCoinToBeAwarded?.toLocaleString()
                                    : contest?.xPToBeAwarded?.toLocaleString() ?? 0}
                                </h6>
                                <p style={{ maxWidth: 100 }}>
                                  <strong>
                                    {contest?.arcadeCoinToBeAwarded ? "Arcade Coins" : "XP"} to be awarded
                                  </strong>
                                </p>
                              </div>
                            )}
                            {contest?.contestType !== 2 && (
                              <div
                                className={`gc-intro-item ${contest?.contestType === 1 ? "text-right" : "text-center"}`}
                              >
                                <h6>{contest?.ticketsToBeAwarded?.toLocaleString() ?? 0}</h6>
                                <p style={{ maxWidth: 100 }}>
                                  <strong>Tickets to be awarded</strong>
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}{" "}
                  </ul>
                </>
              )}
              {this.state.loadingMore && (
                <div className="w-100 d-flex justify-content-center load-more">
                  <img src={loadingIcon} className="fa-spin" alt="" />
                </div>
              )}
            </div>
          </div>
        </div>{" "}
        <DeleteModal
          alertModalToggle={this.alertCancelModalToggle}
          alertModalShow={this.state.showCancelModal}
          title="Alert"
          description="Are you sure you want to cancel this contest?"
          alertType="Yes"
          onPress={this.handleCancel}
          loading={this.state.loadingCancel}
        />
        <DeleteModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showDeleteModal}
          title="Alert"
          description="Are you sure you want to delete this contest?"
          onPress={this.handleDelete}
          loading={this.state.loadingDelete}
        />{" "}
        <ListingFlyer
          showModal={this.state.showGameList}
          toggleModal={this.toggleGameFlyer}
          contest={this.state.contest}
          amountType={this.state.amountType}
        />{" "}
        <HistoryFlyer
          showModal={this.state.showHistory}
          toggleModal={this.toggleHistoryFlyer}
          moduleName={"contest"}
          objectId={this.state.objectId}
          title={this.state.object && this.state.object.title}
        />
        {(this.state.showHistory || this.state.showGameList) && (
          <div
            className="modal-backdrop z-index-1000 show fade"
            onClick={
              this.state.showHistory
                ? this.toggleHistoryFlyer
                : this.state.showGameList
                ? this.toggleGameFlyer
                : this.toggleCommentsModal
            }
          ></div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadContests: (params, fresh, callback) => dispatch(loadContests(params, fresh, callback)),
  deleteContest: (id, callback) => dispatch(deleteContest(id, callback)),
  markAsFeaturedContest: (id, featured, callback) => dispatch(markAsFeaturedContest(id, featured, callback)),
  markAsFeaturedPendingContest: (id, featured, callback) =>
    dispatch(markAsFeaturedPendingContest(id, featured, callback)),
  cancelContest: (id, callback) => dispatch(cancelContest(id, callback)),
  deleteContestsReceived: (payLoad) => dispatch(deleteContestsReceived(payLoad)),
  markAsFeaturedReceived: (payLoad) => dispatch(markAsFeaturedReceived(payLoad)),
  markAsFeaturedPendingContestsReceived: (payLoad) => dispatch(markAsFeaturedPendingContestsReceived(payLoad)),
  cancelContestReceived: (payLoad) => dispatch(cancelContestReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getContest: getContest(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(contestListing));
