import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns, MuiPickersUtilsProvider } from "@mui/x-date-pickers/AdapterDateFns";

//  Dayjs
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//  Momentjs
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import { formatDate } from "../../utils/dateConversion";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import calendarIcon from "include/images/calendar.svg";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/New_York");
const date = moment.utc("2022-04-17T15:30");

class DateInput extends Component {
  ref = React.createRef(null);
  modalRef = React.createRef(null);
  state = {
    formclass: "form-group custom-input show on",
    open: false,
  };

  handleFocus = () => {
    this.setState({ formclass: "form-group custom-input show on" });
  };

  handleBlur = (e) => {
    const classname = e.target.value ? "form-group custom-input show on" : "form-group custom-input show on";
    this.setState({ formclass: classname });
  };

  componentDidMount() {
    const classname = "form-group custom-input show on";
    this.setState({ formclass: classname });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      const classname = this.props.value ? "form-group custom-input show on" : "form-group custom-input show on";
      this.setState({ formclass: classname });
    }
  }
  theme = createTheme({
    palette: {
      primary: {
        main: "#1c1d23",
        color: "white",
      },
    },
    typography: {
      color: "#1b1b1b",
    },
  });
  render() {
    const { name, label, error, minDate, disabled, minTime, subText, ...rest } = this.props;
    if (this.ref && this.ref.current) {
      this.ref.current.placeholder = "mm/dd/yyyy";
    }
    return (
      <>
        <div className="form-group custom-date-form d-flex flex-column">
          <label className="form-label" htmlFor={label}>
            {label}
          </label>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={this.theme}>
              <DatePicker
                inputRef={this.ref}
                renderInput={(props) => (
                  <TextField className={error ? "datepicker" : "datepicker"} {...props} autoComplete="new-password" />
                )}
                disabled={disabled}
                minDateTime={minDate}
                id={name}
                timezone="America/New_York"
                onFocus={this.handleFocus}
                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                onBlur={this.handleBlur}
                className={error ? "form-control datepicker" : "form-control datepicker"}
                components={{
                  OpenPickerIcon: () => <img src={calendarIcon} />,
                  SwitchViewIcon: KeyboardArrowDownIcon,
                }}
                ref={this.modalRef}
                {...rest}
              />
              {subText && (
                <label className="form-label custom-label" htmlFor={label}>
                  <span>{label} (EST)</span> : {subText} EST
                </label>
              )}
            </ThemeProvider>
          </LocalizationProvider>
        </div>
      </>
    );
  }
}

export default DateInput;
