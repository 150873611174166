import React, { Component, createRef } from "react";
import AsyncSelect from "react-select";
import _ from "lodash";

class SelectSearchAsync extends Component {
  state = {
    objValue: null,
    inputValue: "",
    loading: false,
  };
  selectRef = createRef(null);
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.props.value !== "") {
      this.setState({
        objValue: {
          name: this.props.value,
          value: this.props.value,
        },
      });
    }
  }
  customStyles = {
    indicatorSeparator: (styles) => ({ display: "none" }),
    loadingIndicator: (styles) => ({ display: "none" }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#F5F5F5",
        color: "#000",
        cursor: "pointer",
      },
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#1C1D23" : "white",
      maxHeight: "84px",
      height: 40,
      borderRadius: 4,
      marginTop: 8,
      marginBottom: 8,
      padding: 10,
    }),
    menu: (base, state) => ({
      ...base,
      paddingLeft: 8,
      paddingRight: 8,
      border: 0,
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15);",
    }),
    control: (base, state) => ({
      ...base,
      height: 40,
      minHeight: 40,
      paddingLeft: 8,
      color: "#333230",
      borderColor: state.isFocused
        ? this.props.error
          ? "red"
          : "#1C1D23"
        : this.props.error
        ? "red"
        : "#F5F5F5",
      boxShadow: "0 !important",
      backgroundColor: "#F5F5F5",
      borderRadius: 6,
      "&:hover": {
        borderColor: "#1C1D23",
        cursor: "pointer",
      },
      "&:focus": {
        borderColor: "#1C1D23",
      },
    }),
  };
  handleInputChange = (newValue, action) => {
    const inputValue = newValue;
    if (action.action === "menu-close") {
      this.setState({ inputValue: "" });
      this.props.loadOptions("");
    }
    if (action.action === "input-change") {
      this.setState({ inputValue });
      this.props.loadOptions(inputValue);
    }
    return inputValue;
  };
  render() {
    const {
      name,
      options,
      label,
      error,
      value,
      defaultValue,
      disabled,
      isLoading,
      ...rest
    } = this.props;
    var newVal = "";
    if (!_.isEmpty(value)) {
      newVal = value;
    }
    return (
      <div className=" custom-input">
        <AsyncSelect
          {...rest}
          onInputChange={this.handleInputChange}
          onChange={(a) =>
            this.props.onChange({
              name: name,
              currentTarget: { value: a.value, name: a.name, data: a.data },
            })
          }
          value={newVal}
          className="basic-single"
          classNamePrefix="select-search"
          ref={this.selectRef}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          defaultOptions
          cacheOptions
          name={name}
          options={isLoading ? [] : options}
          placeholder={label}
          isDisabled={disabled}
          styles={this.customStyles}
          isLoading={isLoading}
        />
      </div>
    );
  }
}

export default SelectSearchAsync;
