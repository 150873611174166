import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import React, { createRef } from "react";
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  getSettings,
  loadTicketRedemptionRequests,
  ticketRedemptionStatusReceived,
  updateTicketRedemptionStatus,
} from "store/settings";
import { truncateAddress } from "utils/misc";
import AlertSuccess from "common/alert/alertSuccess";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import Form from "common/form/form";
import Joi from "joi-browser";
import filterIcon from "include/images/filter-icon.svg";
import NextButton from "common/form/submitButton";
import closeIcon from "include/images/close-icon.svg";
import { isEmpty, pickBy } from "lodash";
import SelectSearch from "common/form/selectSearch";
import DeleteModal from "common/alert/alertModal";
import AlertError from "common/alert/alertError";

class TicketRedemptionRequest extends Form {
  ref = createRef(null);
  state = {
    loading: false,
    loadingFilters: false,
    data: { userName: "", status: {}, walletAddress: "" },
    errors: {},
    page: 1,
    showAlertModal: false,
    requestId: null,
    loadingStatus: false,
  };

  schema = {
    userName: Joi.string().allow(""),
    walletAddress: Joi.string().allow(""),
    status: Joi.object().allow({}),
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getSettings.loading && this.props.getSettings.loading !== prevProps.getSettings.loading)
      this.ref.current.complete();

    if (this.props.getSettings.loading && this.props.getSettings.loading !== prevProps.getSettings.loading)
      this.ref.current.continuousStart();
  };
  componentDidMount = () => {
    this.setState({ loading: true });
    this.props.loadTicketRedemptionRequests({}, (res) => {
      this.setState({ loading: false });
    });
  };
  searchFilters = () => {
    this.setState({ loading: true, loadingFilters: true });
    const data = { ...this.state.data };
    const payLoad = {
      userName: data?.userName,
      walletAddress: data?.walletAddress,
    };

    if (!isEmpty(data.status)) {
      payLoad.status = data?.status?.value;
    }

    var params = pickBy(payLoad, function (value, key) {
      return value !== "";
    });
    params.page = this.state.page;

    this.props.loadTicketRedemptionRequests(params, (res) => {
      if (res?.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).forEach((key) => {
          if (!isEmpty(data[key])) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({
          appliedFilters,
          loading: false,
          loadingFilters: false,
        });
      } else this.setState({ loading: false, loadingFilters: false });
    });
  };
  resetFilters = (e) => {
    e.preventDefault();

    this.setState(
      {
        data: {
          userName: "",
          status: {},
          walletAddress: "",
        },
        page: 1,
      },
      () => this.searchFilters(),
    );
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAlertModal: !this.state.showAlertModal });
  };
  handleStatusUpdate = (e) => {
    if (e) e.preventDefault();
    this.setState({ loadingStatus: true });
    this.props.updateTicketRedemptionStatus(this.state.requestId, (res) => {
      if (res.status === 200) {
        toast(<AlertSuccess message="Redemption request status updated!" />, {
          containerId: 1,
        });
        this.props.ticketRedemptionStatusReceived({
          id: this.state.requestId,
        });
      } else {
        toast(<AlertError message={res.data && res.data.message} />, {
          containerId: 1,
        });
      }
      this.alertModalToggle();
      this.setState({ loadingStatus: false, requestId: null });
    });
  };
  render() {
    const { ticketRedemptionRequests, totalCount } = this.props.getSettings;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="customText" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Ticket Redemption Requests {totalCount > 0 && `(${totalCount})`}</h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        showFilters: !this.state.showFilters,
                      });
                    }}
                  >
                    <img src={filterIcon} /> <span>Filters</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="fwc-wrapper">
              {this.state.showFilters && (
                <div className="fwc-head ">
                  <div className="fwc-inner">
                    <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("userName", "Username")}</div>
                      </li>{" "}
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("walletAddress", "Wallet Address")}</div>
                      </li>
                      <li className="flex-fill">
                        <div className="fml-box">
                          <label className="form-label">Status</label>
                          <SelectSearch
                            name="status"
                            options={[
                              {
                                name: "Pending",
                                value: 1,
                              },
                              {
                                name: "Complete",
                                value: 2,
                              },
                            ]}
                            label=""
                            value={this.state.data.status}
                            error={this.state.errors.status}
                            onChange={this.onChange}
                          />
                        </div>
                      </li>
                    </ul>
                    <div className="fwc-btn d-flex align-items-center justify-content-end">
                      <NextButton
                        handleSubmit={this.resetFilters}
                        classData="btn-text pointer"
                        label="Reset"
                        loading={this.state.loadingReset}
                      />
                      <NextButton
                        handleSubmit={() => {
                          this.setState(
                            {
                              page: 1,
                            },
                            () => this.searchFilters(),
                          );
                        }}
                        classData="btn btn-primary btn-sm"
                        label="Search"
                        loading={this.state.loadingFilters}
                      />
                    </div>
                  </div>{" "}
                  {!isEmpty(this.state.appliedFilters) && (
                    <div className="filter-tag-container d-flex align-items-center ">
                      <div className="fwc-left">
                        <div className="filter-tag-list">
                          <p>filter applied :</p>
                          {Object.keys(this.state.appliedFilters).map((key) => (
                            <div className="filter-tag-item">
                              <span className="camelCase">
                                {key === "userName"
                                  ? "Username"
                                  : key === "redeemableUsdt"
                                  ? "Redeemable USDT"
                                  : key === "walletAddress"
                                  ? "Wallet Address"
                                  : key}
                              </span>{" "}
                              :{" "}
                              {key === "status" ? this.state.appliedFilters[key].name : this.state.appliedFilters[key]}
                              <a
                                href="#!"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState(
                                    {
                                      data: { ...this.state.data, [key]: "" },
                                      page: 1,
                                    },
                                    this.searchFilters,
                                  );
                                }}
                              >
                                <img src={closeIcon} alt="" />
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {ticketRedemptionRequests?.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>User</th>
                            <th width={150}>Tickets</th>
                            <th width={150}>Redeemable USDT</th>
                            <th width={200}>Wallet Address</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ticketRedemptionRequests?.map((user, i) => (
                            <tr>
                              <td>
                                <span>
                                  <div className="td-img-group d-flex align-items-start ">
                                    <div className="td-img round-img m-right-2 pointer">
                                      {user?.userImage ? (
                                        <img src={process.env.REACT_APP_CDN + user?.userImage?.small} alt="" />
                                      ) : (
                                        <img src={userIcon} alt="" />
                                      )}
                                    </div>

                                    <div>
                                      <a href="#!">{user.userName}</a>
                                      <br />
                                      {user.email}
                                    </div>
                                  </div>
                                </span>
                              </td>

                              <td>{user?.tickets}</td>
                              <td>
                                {user?.redeemableUsdt?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td>
                                <Link
                                  to="!#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(user?.walletAddress);
                                    toast(<AlertSuccess message="Copied to clipboard" />, { containerId: 1 });
                                  }}
                                >
                                  {user?.walletAddress}
                                </Link>
                              </td>
                              <td className="ust-tag">
                                <Dropdown className="custom-dropdown">
                                  <Dropdown.Toggle
                                    id="tdDropdownOne"
                                    className="custom-dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    disabled={user?.status === 2}
                                  >
                                    <span
                                      className={`w-100 text-center d-block ${
                                        user?.status === 1 ? "inactive" : user?.status === 2 ? "active" : "suspended"
                                      }`}
                                    >
                                      {!isNaN(user?.status) && ["PENDING", "COMPLETED"][user?.status - 1]}
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                    <Dropdown.Item
                                      className="dropdown-item "
                                      onClick={(e) =>
                                        this.setState({ requestId: user?._id }, () => this.alertModalToggle(e))
                                      }
                                    >
                                      COMPLETED
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
                {!this.state.loading && !(this.state.page === 1 && ticketRedemptionRequests?.length === 0) && (
                  <div className="page-pagination">
                    <ul className="pagination  justify-content-end">
                      <li className="page-item">
                        <a
                          class={`page-link ${this.state.page === 1 && "disabled"}`}
                          href="#!"
                          aria-label="Previous"
                          onClick={(e) => {
                            e.preventDefault();
                            if (this.state.page > 1) {
                              this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                            }
                          }}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                      <li className="page-item active">
                        <a
                          className="page-link"
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {this.state.page}
                        </a>
                      </li>
                      {ticketRedemptionRequests?.length >= 50 && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            {this.state.page + 1}
                          </a>
                        </li>
                      )}
                      <li className="page-item">
                        <a
                          class={`page-link ${ticketRedemptionRequests?.length < 50 && "disabled"}`}
                          href="#!"
                          aria-label="Next"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                          }}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <DeleteModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showAlertModal}
          title="Alert"
          description="Are you sure you want to mark this request as completed? Are you sure you have transferred the USDT manually? Tickets will be burnt after this action. This action is irreversible."
          alertType="Yes"
          onPress={this.handleStatusUpdate}
          loading={this.state.loadingStatus}
        />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadTicketRedemptionRequests: (params, callback) => dispatch(loadTicketRedemptionRequests(params, callback)),
  updateTicketRedemptionStatus: (id, callback) => dispatch(updateTicketRedemptionStatus(id, callback)),
  ticketRedemptionStatusReceived: (payLoad) => dispatch(ticketRedemptionStatusReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TicketRedemptionRequest));
