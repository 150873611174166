import { Component, createRef } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//  Images
import emptyIcon from "include/images/nrf.svg";
import plusIcon from "include/images/plus.svg";

//  Components
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import { getBoardGame, loadBoardGameSlotReward } from "store/boardGame";
import AddCard from "./updateSlotRewards";

class SlotRewardsListing extends Component {
  ref = createRef(null);
  state = {
    loadingMain: false,
    loadingDelete: false,
    showDeleteModal: false,
    showAddModal: false,
    deleteId: "",
    editData: null,
    page: 1,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getBoardGame.loading && this.props.getBoardGame.loading !== prevProps.getBoardGame.loading)
      this.ref.current.complete();

    if (this.props.getBoardGame.loading && this.props.getBoardGame.loading !== prevProps.getBoardGame.loading)
      this.ref.current.continuousStart();
    if (this.state.showAddModal !== prevState.showAddModal && !this.state.showAddModal) {
      setTimeout(() => {
        this.setState({ editData: null });
      }, 500);
    }
  };
  componentDidMount = () => {
    this.setState({ loadingMain: true });
    this.props.loadBoardGameSlotReward({}, (res) => {
      this.setState({
        loadingMain: false,
      });
    });
  };

  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };

  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };

  render() {
    const { boardGameSlotReward } = this.props.getBoardGame;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="boardGameSlot" />

          {!this.state.loadingMain && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1>Slot Rewards</h1>
                <div className="filter-menu-box d-flex align-items-center ms-auto">
                  <div className="fmb-box">
                    <Link to="#" onClick={this.toggleAddModal} className="btn btn-default btn-sm">
                      <img src={plusIcon} alt="" /> Add a slot reward
                    </Link>
                  </div>
                </div>
              </div>
              <div className="fwc-wrapper">
                <div className="fwc-body">
                  {boardGameSlotReward?.length === 0 ? (
                    <div className="fwc-body">
                      <div className="no-record-found-container">
                        <div className="nfr-box">
                          <img src={emptyIcon} alt="No Record Found" />
                          <div className="nrf-text">
                            <h5>No Records Found!</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Value</th>
                            <th>Reward Type</th>
                            <th>Percentage</th>
                            <th>Reels</th>
                          </tr>
                        </thead>
                        <tbody>
                          {boardGameSlotReward?.map((reward, index) => (
                            <tr key={index}>
                              <td>
                                <Link
                                  className="two-line-ellipsis"
                                  to={"#"}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ editData: reward }, () => {
                                      this.toggleAddModal();
                                    });
                                  }}
                                >
                                  {reward?.rewardValue}
                                </Link>
                              </td>
                              <td className="text-capitalize">{reward?.rewardType}</td> <td>{reward?.percentage}%</td>
                              <td>
                                <div>
                                  {reward?.reels?.map((reel, index) => (
                                    <div className="mb-2 td-text-box d-flex align-items-center">
                                      <div className="td-img margin-right-2 pointer">
                                        <img src={process.env.REACT_APP_CDN + reward?.reelsImage[index]} alt="" />
                                      </div>
                                      {reel}
                                    </div>
                                  ))}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <AddCard
          showModal={this.state.showAddModal}
          toggleModal={this.toggleAddModal}
          loadData={this.props.loadBoardGameSlotReward}
          edit={this.state.editData}
        />

        {this.state.showAddModal && <div className="modal-backdrop show fade" onClick={this.toggleAddModal}></div>}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadBoardGameSlotReward: (params, callback) => dispatch(loadBoardGameSlotReward(params, callback)),
});
const mapStateToProps = (state) => ({
  getBoardGame: getBoardGame(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SlotRewardsListing));
