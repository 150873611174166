import Joi from "joi-browser";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";
import plusIcon from "include/images/plus-1.svg";
//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import { addBoardGameTournament, updateBoardGameTournament } from "store/boardGame";
import Amplify, { Auth, Storage } from "aws-amplify";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});
class AddFlyer extends Form {
  fileInputRef = React.createRef(null);
  initialState = {
    data: {
      name: "",
      bs: 0,
      freeSpin: 0,
      startedAt: "",
      endedAt: "",
      image: null,
      iconMapImage: null,
    },
    id: "",
    edit: false,
    loading: false,
    imageChange: false,
    iconMapImageChange: false,
    errors: {},
  };
  state = { ...this.initialState };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal) {
        if (this.props.edit) {
          const data = { ...this.state.data };
          data.name = this.props.edit?.name;
          data.bs = this.props.edit?.bs;
          data.freeSpin = this.props.edit?.freeSpin;
          data.startedAt = new Date(this.props.edit?.startedAt);
          data.endedAt = new Date(this.props.edit?.endedAt);
          data.image = this.props.edit?.image;
          data.iconMapImage = this.props.edit?.iconMapImage;
          this.setState({ data, edit: true, id: this.props.edit?._id });
        }
      }
    }
  };
  doSubmit = (e) => {
    this.setState({ loading: true });
    const data = { ...this.state.data };
    const payLoad = {
      name: data.name,
      bs: data.bs ? parseInt(data.bs) : 0,
      freeSpin: data.freeSpin ? parseInt(data.freeSpin) : 0,
      startedAt: parseInt(moment(data.startedAt).format("x")),
      endedAt: parseInt(moment(data.endedAt).format("x")),
    };
    if (this.props.edit) {
      if (this.state.imageChange) payLoad.image = data.image;
      if (this.state.iconMapImageChange) payLoad.iconMapImage = data.iconMapImage;
      this.props.updateBoardGameTournament(payLoad, this.state.id, (res) => {
        this.setState({ loading: false });
        if (res.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.loadData();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={res.data && res.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      payLoad.image = data.image;
      payLoad.iconMapImage = data.iconMapImage;
      this.props.addBoardGameTournament(payLoad, (res) => {
        this.setState({
          loading: false,
        });
        if (res.status === 200) {
          this.props.loadData();
          toast(<AlertSuccess message="Information Saved" />);
          this.props.toggleModal();
        } else {
          toast(<AlertError message={res.data && res.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };
  schema = {
    name: Joi.string().required(),
    bs: Joi.number().allow(""),
    freeSpin: Joi.number().allow(""),
    startedAt: Joi.date().required(),
    endedAt: Joi.date().required(),
    image: Joi.string().required(),
    iconMapImage: Joi.string().required(),
  };

  handleImageChange = (e) => {
    this.setState({ [e.target.name + "Loading"]: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ [e.target.name + "Loading"]: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ [e.target.name + "Loading"]: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.setState({
          data: {
            ...this.state.data,
            [e.target.name]: "public/" + result.key,
          },
          [e.target.name + "Change"]: true,
          [e.target.name + "Loading"]: false,
        });
      });
    }
    e.target.value = null;
  };
  clearData = () => {
    this.setState({
      ...this.initialState,
    });
  };
  getEndDate = () => {
    var startedAt = new Date(this.state.data?.startedAt);
    var endDate = "";
    if (startedAt) endDate = new Date(startedAt.getTime());
    else endDate = new Date();
    return endDate;
  };
  render() {
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>
            {this.props.edit ? "Edit " : "Add "}
            Tournament
          </h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <table className="table large-table flyer-table hide-date-label">
                <tbody>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Name</td>
                    <td>{this.renderInput("name", "")}</td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Bs</td>
                    <td>{this.renderInput("bs", "", "", "number")}</td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Free Spin</td>
                    <td>{this.renderInput("freeSpin", "", "", "number")}</td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Start Date</td>
                    <td style={{ paddingTop: 30, paddingBottom: 30 }}>
                      {this.renderDateOnlyInput("startedAt", "Start Date", new Date())}
                    </td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">End Date</td>
                    <td style={{ paddingTop: 30, paddingBottom: 30 }}>
                      {this.renderDateOnlyInput("endedAt", "End Date", this.getEndDate())}
                    </td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Board Configuration Image </td>
                    <td className="d-flex flex-wrap align-items-start">
                      <div>
                        <div
                          className={`kt-upload-outer-small ${this.state.imageLoading ? "pointer-none" : "pointer"} ${
                            !this.state.data.image ? "placeholder-uploaded-img" : " position-relative"
                          } ${this.state.errors?.image && "border-error"}`}
                        >
                          <div className="kt-upload-img">
                            <img
                              src={
                                this.state.data.image ? process.env.REACT_APP_CDN + this.state.data?.image : plusIcon
                              }
                              alt=""
                            />
                            <input
                              className="file-d-input"
                              type="file"
                              name="image"
                              accept="image/*"
                              onChange={this.handleImageChange}
                            />
                          </div>
                          {this.state.data?.image && (
                            <div
                              className="delete-icon d-flex align-items-center justify-content-center"
                              onClick={(e) => {
                                e.stopPropagation();
                                const data = { ...this.state.data };

                                data.image = null;
                                this.setState({ data });
                              }}
                            >
                              <img src={deleteIcon} alt="delete" />
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>{" "}
                  <tr className="flyer-table">
                    <td className="vertical-middle">Icon Map Image</td>
                    <td className="d-flex flex-wrap align-items-start">
                      <div>
                        <div
                          className={`kt-upload-outer-small ${
                            this.state.iconMapImageLoading ? "pointer-none" : "pointer"
                          } ${!this.state.data.iconMapImage ? "placeholder-uploaded-img" : " position-relative"} ${
                            this.state.errors?.iconMapImage && "border-error"
                          }`}
                        >
                          <div className="kt-upload-img">
                            <img
                              src={
                                this.state.data.iconMapImage
                                  ? process.env.REACT_APP_CDN + this.state.data?.iconMapImage
                                  : plusIcon
                              }
                              alt=""
                            />
                            <input
                              className="file-d-input"
                              type="file"
                              name="iconMapImage"
                              accept="image/*"
                              onChange={this.handleImageChange}
                            />
                          </div>
                          {this.state.data?.iconMapImage && (
                            <div
                              className="delete-icon d-flex align-items-center justify-content-center"
                              onClick={(e) => {
                                e.stopPropagation();
                                const data = { ...this.state.data };

                                data.iconMapImage = null;
                                this.setState({ data });
                              }}
                            >
                              <img src={deleteIcon} alt="delete" />
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loading}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addBoardGameTournament: (data, callback) => dispatch(addBoardGameTournament(data, callback)),
  updateBoardGameTournament: (id, data, callback) => dispatch(updateBoardGameTournament(id, data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(AddFlyer));
