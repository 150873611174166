import React, { createRef } from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import { loadUserTransactions, getUsers } from "store/users";
import moment from "moment/moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

//  Images
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";
import loadingIcon from "include/images/loader-grey.svg";
import closeIcon from "include/images/dark-close-icon.svg";

//  Components
import Loader from "common/loader";
import NextButton from "common/form/submitButton";
import Form from "common/form/form";
import { Link } from "react-router-dom";
import { truncateAddress } from "utils/misc";

class ListingFlyer extends Form {
  scrollRef = React.createRef();
  ref = createRef(null);
  state = {
    loading: false,
    loadingMore: false,
    loadingWinner: false,
    winner: "",
    currKey: "",
    amountType: 0,
  };
  handleLoadMore = (entries) => {
    const target = entries[0];
    if (target?.isIntersecting) {
      const { userTransactions, totalCount } = this.props.getUsers;

      if (!this.state.loadingMore && userTransactions?.length < totalCount) {
        this.setState({ loadingMore: true });
        const params = {};
        if (this.props.amountType) {
          params.amountType = this.props.amountType;
        }
        if (this.state.currKey) params.lastKey = this.state.currKey;
        this.props.loadUserTransactions(this.props.user?.id, params, false, (res) =>
          this.setState({ loadingMore: false, currKey: res?.data?.lastKey }),
        );
        this.observer.disconnect();
      }
    }
  };
  observer = new IntersectionObserver(this.handleLoadMore, {
    threshold: 0.2,
  });
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({ loading: true });
      const params = {};
      if (this.props.amountType) {
        params.amountType = this.props.amountType;
      }
      this.props.loadUserTransactions(this.props.user?.id, params, true, (res) => {
        this.setState({ loading: false, currKey: res?.data?.lastKey });
      });
    }
    if (!this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({
        loading: false,
        loadingMore: false,
        loadingWinner: false,
        winner: "",
        currKey: "",
      });
    }

    if (!this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.complete();

    if (this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.continuousStart();
    if (this.state.currKey !== prevState.currKey && this.state.currKey !== null) {
      if (this.observer && this.scrollRef?.current) {
        this.observer.observe(this.scrollRef.current);
      }
    }
  };
  onRefChange = (node, i) => {
    const { userTransactions, totalCount } = this.props.getUsers;

    if (i === userTransactions?.length - 1 && userTransactions?.length < totalCount) {
      this.scrollRef.current = node;
    }
  };
  render() {
    const { userTransactions, filteredCount } = this.props.getUsers;
    const { user, amountType } = this.props;

    return (
      <>
        <Loader loaderRef={this.ref} />
        <Offcanvas
          className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2"}
          show={this.props.showModal}
          onHide={this.props.toggleModal}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header className="offCanvas-header-2">
            <div>
              <h5>User Activities {amountType && `(${amountType === 1 ? "Arcade Coins" : "Loot Bucks"})`}</h5>{" "}
              <div className="td-img-group d-flex align-items-start m-top-2">
                {user?.userName}
                <br />
                {user?.email}
              </div>
            </div>
            <div
              className="btn_close pointer"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={this.props.toggleModal}
            >
              <img src={closeIcon} alt="" />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="">
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {userTransactions.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Contest</th>
                            <th>Credits</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userTransactions?.map((activity, i) => (
                            <tr key={activity?.id} ref={(node) => this.onRefChange(node, i)}>
                              <td>
                                <div className="td-text-box upperCase">{activity.title}</div>
                              </td>{" "}
                              <td>
                                <div className="td-text-box">
                                  {activity.transactionType == "DR" ? "- " : "+ "}
                                  {activity.amount}{" "}
                                  {activity.amountType == 1
                                    ? "ARCADE COINS"
                                    : activity.amountType == 3
                                    ? "LOOT BUCKS"
                                    : activity.amountType == 2
                                    ? "TICKETS"
                                    : ""}
                                </div>
                              </td>{" "}
                              <td>
                                <div className="td-text-box">
                                  {moment(activity.updatedAt).format("MM/DD/YYYY hh:mm a")}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
            {this.state.loadingMore && (
              <div className="w-100 d-flex justify-content-center load-more">
                <img src={loadingIcon} className="fa-spin" alt="" />
              </div>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadUserTransactions: (id, params, fresh, callback) => dispatch(loadUserTransactions(id, params, fresh, callback)),
});
const mapStateToProps = (state) => ({
  getUsers: getUsers(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListingFlyer));
