import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  loadBGGames,
  deleteBGGame,
  getGame,
  deleteBGGameReceived,
  updateBGGame,
  changeStatusBGGameReceived,
  changeComingSoonStatusBGGameReceived,
} from "store/games";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import _ from "lodash";
import moment from "moment";
import { Form } from "react-bootstrap";

//  Images
import plusIcon from "include/images/plus.svg";
import carrentIcon from "include/images/carrent-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import closeIcon from "include/images/close-icon.svg";
import historyIcon from "include/images/history-icon.svg";
import filterIcon from "include/images/filter-icon.svg";
import loadingIcon from "include/images/loader-grey.svg";

//  Components
import Header from "common/header";
import NextButton from "common/form/submitButton";
import SideBar from "common/sidebar";
import Loader from "common/loader";
import DeleteModal from "common/alert/alertModal";
import AlertSuccess from "common/alert/alertSuccess";
import HistoryFlyer from "common/historyFlyer";
import AlertError from "common/alert/alertError";
import UpdateGameData from "./updateGameData";
import UpdateValidateGameData from "./updateValidateGameData";

class BurnGhostGamesListing extends Component {
  ref = createRef(null);
  state = {
    data: {
      keyword: "",
    },
    selectedBgGames: [],
    selectAll: false,
    loading: false,
    loadingDelete: false,
    loadingFilters: false,
    showFilters: false,
    loadingReset: false,
    page: 1,
    showDeleteModal: false,
    errors: {},
    deleteId: "",
    showHistory: false,
    objectId: "",
    object: null,
    loadingInd: "",
    changeStatusLoading: false,
    loadingComingSoon: false,
    showGameDataModal: false,
    showGameValidateModal: false,
    game: {},
  };
  toggleGameDataFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showGameDataModal: !this.state.showGameDataModal });
  };
  toggleGameValidateFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showGameValidateModal: !this.state.showGameValidateModal });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getGame.loading && this.props.getGame.loading !== prevProps.getGame.loading)
      this.ref.current.complete();

    if (this.props.getGame.loading && this.props.getGame.loading !== prevProps.getGame.loading)
      this.ref.current.continuousStart();
  };
  componentDidMount = () => {
    this.setState({ loading: true });
    this.props.loadBGGames({}, () => {
      this.setState({ loading: false });
    });
  };
  toggleHistoryFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showHistory: !this.state.showHistory });
  };
  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };

  changeGameStatus = (id, currStatus) => {
    this.setState({ changeStatusLoading: true });
    this.props.updateBGGame({ isActive: !currStatus }, id, (gameRes) => {
      if (gameRes.status === 200) {
        this.props.changeStatusBGGameReceived({ id, currStatus });
      }
      this.setState({ changeStatusLoading: false, loadingInd: "" });
    });
  };
  changeComingSoonStatus = (id, currStatus) => {
    this.setState({ loadingComingSoon: true });
    this.props.updateBGGame({ isComingSoon: currStatus }, id, (gameRes) => {
      if (gameRes.status === 200) {
        this.props.changeComingSoonStatusBGGameReceived({ id, currStatus: !currStatus });
      }
      this.setState({ loadingComingSoon: false, loadingInd: "" });
    });
  };
  handleDelete = (e) => {
    if (e) e.preventDefault();
    this.setState({ loadingDelete: true });
    this.props.deleteBGGame(this.state.deleteId, (gameRes) => {
      if (gameRes.status === 200) {
        toast(<AlertSuccess message="Deleted!" />, {
          containerId: 1,
        });
        this.props.deleteBGGameReceived({ id: this.state.deleteId });
        this.alertModalToggle();
        this.setState({ loadingDelete: false });
      } else {
        toast(<AlertError message={gameRes.data && gameRes.data.message} />, {
          containerId: 1,
        });
        this.setState({ loadingDelete: false });
        this.alertModalToggle();
      }
    });
  };

  render() {
    const { bgGames, filteredCount } = this.props.getGame;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="bgGames" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>BG Games {filteredCount > 0 && `(${filteredCount})`}</h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                <div className="fmb-box">
                  <Link to="/add-burn-ghost-game" className="btn btn-default btn-sm">
                    <img src={plusIcon} alt="" /> Add a BG Game
                  </Link>
                </div>
              </div>
            </div>
            <div className="fwc-wrapper">
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {bgGames.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th style={{ width: "5%", textAlign: "center" }}>Actions</th>
                            <th style={{ width: "20%" }}>Name</th>
                            <th style={{ width: "15%" }}>Scoring Type</th>
                            <th>Image</th>
                            <th style={{ width: "8%" }}>Max Time Span (In Seconds)</th>
                            <th>Coming Soon</th>
                            <th>Status</th>
                            <th>Dispaly Order</th>
                            <th>Created At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bgGames &&
                            bgGames.length > 0 &&
                            bgGames.map((game, i) => (
                              <tr>
                                <td>
                                  <Dropdown className=" custom-dropdown">
                                    <Dropdown.Toggle
                                      id="tdDropdownOne"
                                      className="custom-dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <img src={carrentIcon} alt="" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                      <Dropdown.Item
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.props.history.push({
                                            pathname: "/add-burn-ghost-game",
                                            state: { game },
                                          });
                                        }}
                                      >
                                        Edit
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          this.setState({ game: game }, () => {
                                            this.toggleGameDataFlyer(e);
                                          });
                                        }}
                                      >
                                        Update Game Data Function
                                      </Dropdown.Item>{" "}
                                      <Dropdown.Item
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          this.setState({ game: game }, () => {
                                            this.toggleGameValidateFlyer(e);
                                          });
                                        }}
                                      >
                                        Update Validate Game Function
                                      </Dropdown.Item>{" "}
                                      <Dropdown.Item
                                        className="dropdown-item delete"
                                        onClick={(e) => {
                                          this.setState({ deleteId: game.id }, () => this.alertModalToggle(e));
                                        }}
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                                <td>
                                  <Link
                                    className="two-line-ellipsis"
                                    to={{
                                      pathname: `/add-burn-ghost-game`,
                                      state: { game },
                                    }}
                                  >
                                    {game.name}
                                  </Link>
                                  <span className="subTitle-line2">
                                    {game?.subtitle}
                                    {game?.subtitle && <br />}
                                    Wallet Restrictions: {game?.isWalletRestrictionEnabled ? "Yes" : "No"}
                                    <br />
                                    Access Pass Restrictions: {game?.isAccessPassRestrictionEnabled ? "Yes" : "No"}
                                  </span>
                                </td>
                                <td>
                                  {game.scoringType === 1 ? "Round Based Scoring Pattern" : "Normal Scoring Pattern"}
                                </td>
                                <td>
                                  <div className="td-img margin-right-2 ">
                                    {game.desktopImage && (
                                      <img src={process.env.REACT_APP_CDN + game.desktopImage.small} alt="" />
                                    )}
                                  </div>
                                </td>
                                <td className={!game.maxTimeSpan && "opacity-5"}>{game.maxTimeSpan ?? "NA"}</td>
                                <td>
                                  <Form.Switch
                                    type="switch"
                                    name="comingSoon"
                                    id={`review-switch-${game.id}`}
                                    className={`pointer review-switch review-switch2 ${
                                      this.state.loadingComingSoon && "pointer-none"
                                    } ${game.isComingSoon ? "toggle-is-on" : "toggle-is-off"}`}
                                    checked={game.isComingSoon ?? false}
                                    disabled={this.state.loadingComingSoon || !game.isActive}
                                    label={game.isComingSoon ? "Yes" : "No"}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({ loadingInd: game.id }, () => {
                                        this.changeComingSoonStatus(game.id, !game.isComingSoon);
                                      });
                                    }}
                                  />
                                </td>
                                <td className="ust-tag">
                                  <Dropdown className=" custom-dropdown">
                                    <Dropdown.Toggle
                                      id="tdDropdownOne"
                                      className={`custom-dropdown-toggle ${
                                        (this.state.changeStatusLoading || this.state.loadingComingSoon) &&
                                        "pointer-none"
                                      }`}
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <span
                                        className={`w-100 text-center d-block ${
                                          game.isActive ? "active" : "inactive"
                                        } ${
                                          (this.state.changeStatusLoading || this.state.loadingComingSoon) &&
                                          "pointer-none"
                                        }`}
                                      >
                                        {this.state.changeStatusLoading && this.state.loadingInd === i ? (
                                          <img
                                            src={loadingIcon}
                                            alt="loading..."
                                            style={{ width: 20 }}
                                            className="fa-spin"
                                          />
                                        ) : game.isActive ? (
                                          "Active"
                                        ) : (
                                          "Inactive"
                                        )}
                                      </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                      <>
                                        <Dropdown.Item
                                          className="dropdown-item "
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ loadingInd: i });
                                            this.changeGameStatus(game.id, game.isActive);
                                          }}
                                        >
                                          {game.isActive ? "Deactivate" : "Activate"} Game
                                        </Dropdown.Item>
                                      </>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                                <td>{game?.displayOrder}</td>
                                <td>
                                  <div class="small-text">
                                    {moment(game.createdAt).format("MM/DD/YYYY")}
                                    <br /> <small> {moment(game.createdAt).format("hh:mm a")}</small>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>{" "}
        {(this.state.showGameDataModal || this.state.showGameValidateModal) && (
          <div className="modal-backdrop show fade z-index-1000"></div>
        )}
        <UpdateGameData
          showModal={this.state.showGameDataModal}
          toggleModal={this.toggleGameDataFlyer}
          game={this.state.game}
        />{" "}
        <UpdateValidateGameData
          showModal={this.state.showGameValidateModal}
          toggleModal={this.toggleGameValidateFlyer}
          game={this.state.game}
        />{" "}
        <DeleteModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showDeleteModal}
          title="Alert"
          description="Are you sure you want to delete this item?"
          onPress={this.handleDelete}
          loading={this.state.loadingDelete}
        />
        <HistoryFlyer
          showModal={this.state.showHistory}
          toggleModal={this.toggleHistoryFlyer}
          moduleName={"bgGames"}
          objectId={this.state.objectId}
          title={this.state.object && this.state.object.contestTitle}
        />
        {this.state.showHistory && <div className="modal-backdrop show fade" onClick={this.toggleHistoryFlyer}></div>}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadBGGames: (params, callback) => dispatch(loadBGGames(params, callback)),
  deleteBGGame: (id, callback) => dispatch(deleteBGGame(id, callback)),
  deleteBGGameReceived: (payLoad) => dispatch(deleteBGGameReceived(payLoad)),
  changeStatusBGGameReceived: (payLoad) => dispatch(changeStatusBGGameReceived(payLoad)),
  changeComingSoonStatusBGGameReceived: (payLoad) => dispatch(changeComingSoonStatusBGGameReceived(payLoad)),
  updateBGGame: (data, id, callback) => dispatch(updateBGGame(data, id, callback)),
});
const mapStateToProps = (state) => ({
  getGame: getGame(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BurnGhostGamesListing));
