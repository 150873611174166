import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import eyeOff from "include/images/eye-off.svg";
import eye from "include/images/eye.svg";

class Password extends Component {
  state = {
    isPasswordShown: false,
  };

  constructor(props) {
    super(props);
    this.input = React.createRef();
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.error !== prevProps.error) {
      ReactTooltip.rebuild();
    }
  };

  toggleShowPassword = (e) => {
    this.setState({ isPasswordShown: !this.state.isPasswordShown });
    this.input.current.type = this.state.isPasswordShown ? "password" : "text";
  };

  render() {
    const { name, label, error, errorId, id, ...rest } = this.props;
    return (
      <>
        <div className={"form-group"}>
          <label className="form-label" htmlFor={name}>
            {label}
          </label>
          <div className="input-group">
            <input
              name={name}
              id="passwd"
              className={error ? "form-control error" : "form-control"}
              type="password"
              ref={this.input}
              {...rest}
            />
            <div className="eye-icon-box">
              {this.state.isPasswordShown ? (
                <img src={eye} alt="" onClick={this.toggleShowPassword} />
              ) : (
                <img src={eyeOff} alt="" onClick={this.toggleShowPassword} />
              )}
            </div>
          </div>
          <div className="forgot-psw text-end">
            <a href="#" onClick={(e) => e.preventDefault()}>
              Forgot password?
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default Password;
