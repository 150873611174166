import Joi from "joi-browser";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";
import plusIcon from "include/images/plus-1.svg";
//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import { addBoardGameCard, updateBoardGameCard } from "store/boardGame";
import Amplify, { Auth, Storage } from "aws-amplify";
import SelectSearch from "common/form/selectSearch";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});
class AddFlyer extends Form {
  fileInputRef = React.createRef(null);
  initialState = {
    data: {
      name: "",
      rarity: {},
      color: {},
      type: {},
      damage: 0,
      area: 0,
      push: 0,
      disc: "",
      powers: "",
      image: null,
    },
    id: "",
    edit: false,
    loading: false,
    imageChange: false,
    errors: {},
  };
  state = { ...this.initialState };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal) {
        if (this.props.edit) {
          const data = { ...this.state.data };
          data.name = this.props.edit?.name;
          data.rarity = this.rarityOptions?.find((rarity) => rarity?.value === this.props.edit?.rarity);
          data.type = this.typeOptions?.find((type) => type?.value === this.props.edit?.type);
          data.color = this.colorOptions?.find((color) => color?.value === this.props.edit?.color);
          data.damage = this.props.edit?.damage;
          data.area = this.props.edit?.area;
          data.push = this.props.edit?.push;
          data.disc = this.props.edit?.disc;
          data.powers = this.props.edit?.powers?.join(", ") ?? "";
          data.image = this.props.edit?.image;
          this.setState({ data, edit: true, id: this.props.edit._id });
        }
      }
    }
  };
  doSubmit = (e) => {
    this.setState({ loading: true });
    const data = { ...this.state.data };
    const payLoad = {
      name: data.name,
      rarity: data.rarity?.value,
      type: data.type?.value,
      color: data.color?.value,
      damage: data.damage ? parseInt(data.damage) : 0,
      area: data.area ? parseInt(data.area) : 0,
      push: data.push ? parseInt(data.push) : 0,
      disc: data.disc,
      powers: [
        data.powers
          ?.split(",")
          ?.map((el) => el.trim())
          ?.filter((el) => el !== ""),
      ],
    };
    if (this.props.edit) {
      if (this.state.imageChange) payLoad.image = data.image;
      this.props.updateBoardGameCard(payLoad, this.state.id, (res) => {
        this.setState({ loading: false });
        if (res.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.loadData();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={res.data && res.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      payLoad.image = data.image;
      this.props.addBoardGameCard(payLoad, (res) => {
        this.setState({
          loading: false,
        });
        if (res.status === 200) {
          this.props.loadData();
          toast(<AlertSuccess message="Information Saved" />);
          this.props.toggleModal();
        } else {
          toast(<AlertError message={res.data && res.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };
  schema = {
    name: Joi.string().required(),
    rarity: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string(),
      value: Joi.number(),
    }),
    color: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string(),
      value: Joi.string(),
    }),
    type: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string(),
      value: Joi.string(),
    }),
    damage: Joi.number().allow(""),
    area: Joi.number().allow(""),
    push: Joi.number().allow(""),
    disc: Joi.string().allow(""),
    powers: Joi.string().allow(""),
    image: Joi.string().allow(""),
  };

  handleImageChange = (e) => {
    this.setState({ [e.target.name + "Loading"]: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ [e.target.name + "Loading"]: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ [e.target.name + "Loading"]: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.setState({
          data: {
            ...this.state.data,
            image: "public/" + result.key,
          },
          [e.target.name + "Change"]: true,
          [e.target.name + "Loading"]: false,
        });
      });
    }
    e.target.value = null;
  };
  clearData = () => {
    this.setState({
      ...this.initialState,
    });
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  rarityOptions = [
    {
      name: "1",
      value: 1,
    },
    {
      name: "2",
      value: 2,
    },
    {
      name: "3",
      value: 3,
    },
    {
      name: "4",
      value: 4,
    },
    {
      name: "5",
      value: 5,
    },
  ];
  colorOptions = [
    {
      name: "Silver",
      value: "silver",
    },
    {
      name: "Green",
      value: "green",
    },
    {
      name: "Blue",
      value: "blue",
    },
    {
      name: "Red",
      value: "red",
    },
    {
      name: "Purple",
      value: "purple",
    },
    {
      name: "Gold",
      value: "gold",
    },
  ];

  typeOptions = [
    {
      name: "Missile",
      value: "missile",
    },
    {
      name: "Melee",
      value: "melee",
    },
    {
      name: "Armor",
      value: "armor",
    },
  ];
  render() {
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>
            {this.props.edit ? "Edit " : "Add "}
            Player Card
          </h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <table className="table large-table flyer-table">
                <tbody>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Name</td>
                    <td>{this.renderInput("name", "")}</td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Rarity</td>
                    <td>
                      <SelectSearch
                        name="rarity"
                        options={this.rarityOptions}
                        label=""
                        value={this.state.data.rarity}
                        error={this.state.errors.rarity}
                        onChange={this.onChange}
                      />
                    </td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Color</td>
                    <td>
                      <SelectSearch
                        name="color"
                        options={this.colorOptions}
                        label=""
                        value={this.state.data.color}
                        error={this.state.errors.color}
                        onChange={this.onChange}
                      />
                    </td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Type </td>
                    <td>
                      <SelectSearch
                        name="type"
                        options={this.typeOptions}
                        label=""
                        value={this.state.data.type}
                        error={this.state.errors.type}
                        onChange={this.onChange}
                      />
                    </td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Damage</td>
                    <td>{this.renderInput("damage", "", "", "number")}</td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Area</td>
                    <td>{this.renderInput("area", "", "", "number")}</td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Push </td>
                    <td>{this.renderInput("push", "", "", "number")}</td>
                  </tr>{" "}
                  <tr className="flyer-table">
                    <td className="vertical-middle">Disc </td>
                    <td>{this.renderInput("disc", "")}</td>
                  </tr>{" "}
                  <tr className="flyer-table">
                    <td className="vertical-middle">Powers </td>
                    <td>{this.renderInput("powers", "")}</td>
                  </tr>{" "}
                  <tr className="flyer-table">
                    <td className="vertical-middle">Image </td>
                    <td className="d-flex flex-wrap align-items-start">
                      <div>
                        <div
                          className={`kt-upload-outer-small ${this.state.imageLoading ? "pointer-none" : "pointer"} ${
                            !this.state.data.image ? "placeholder-uploaded-img" : " position-relative"
                          } ${this.state.errors?.image && "border-error"}`}
                        >
                          <div className="kt-upload-img">
                            <img
                              src={
                                this.state.data.image ? process.env.REACT_APP_CDN + this.state.data?.image : plusIcon
                              }
                              alt=""
                            />
                            <input
                              className="file-d-input"
                              type="file"
                              name="image"
                              accept="image/*"
                              onChange={this.handleImageChange}
                            />
                          </div>
                          {this.state.data?.image && (
                            <div
                              className="delete-icon d-flex align-items-center justify-content-center"
                              onClick={(e) => {
                                e.stopPropagation();
                                const data = { ...this.state.data };

                                data.image = null;
                                this.setState({ data });
                              }}
                            >
                              <img src={deleteIcon} alt="delete" />
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loading}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addBoardGameCard: (data, callback) => dispatch(addBoardGameCard(data, callback)),
  updateBoardGameCard: (id, data, callback) => dispatch(updateBoardGameCard(id, data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(AddFlyer));
