import React from "react";
import statusIcon from "include/images/info.svg";
const AlertError = (props) => {
  return (
    <>
      <div className="alert-icon">
        <img src={statusIcon} alt="" />
      </div>
      <div>
        {typeof props.message === "string"
          ? props.message
          : "Something went wrong"}
      </div>
    </>
  );
};

export default AlertError;
