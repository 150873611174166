import Joi from "joi-browser";
import _ from "lodash";
import { createRef } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";
import filterIcon from "include/images/filter-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";

//  Components
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import closeIcon from "include/images/close-icon.svg";
import moment from "moment";
import { getTelegram, loadTelegramChallenges } from "store/telegram";

class UserListing extends Form {
  ref = createRef(null);

  state = {
    data: {},
    errors: {},
    loading: false,
    showFilters: false,
    page: 1,
    appliedFilters: {},
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.getTelegram.loadingChallenges &&
      this.props.getTelegram.loadingChallenges !== prevProps.getTelegram.loadingChallenges
    )
      this.ref.current.complete();

    if (
      this.props.getTelegram.loadingChallenges &&
      this.props.getTelegram.loadingChallenges !== prevProps.getTelegram.loadingChallenges
    )
      this.ref.current.continuousStart();
  };

  componentDidMount = () => {
    this.setState({ loading: true });
    const params = {};
    this.props.loadTelegramChallenges(params, (res) => {
      if (res?.status === 200) {
        this.setState({
          loading: false,
        });
      } else this.setState({ loading: false });
    });
  };

  //  Filters
  searchFilters = () => {
    this.setState({ loadingFilters: true });
    const params = {};
    params.page = this.state.page;
    this.props.loadTelegramChallenges(params, (userRes) => {
      if (userRes?.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).forEach((key) => {
          if (!_.isEmpty(data[key])) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({
          appliedFilters,
          loading: false,
          loadingMore: false,
          loadingFilters: false,
        });
      } else this.setState({ loading: false, loadingFilters: false });
    });
  };
  resetFilters = (e) => {
    e.preventDefault();
    this.setState(
      {
        data: {
          userName: "",
          firstName: "",
          lastName: "",
        },
        page: 1,
      },
      () => this.searchFilters(),
    );
  };

  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };

  schema = {
    userName: Joi.string().allow(""),
    firstName: Joi.string().allow(""),
    lastName: Joi.string().allow(""),
  };

  render() {
    const { telegramChallenges, totalChallengesCount } = this.props.getTelegram;

    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="telegram-mini-app-challenges" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Challenges {totalChallengesCount > 0 && `(${totalChallengesCount})`}</h1>
            </div>
            <div className="fwc-wrapper">
              <div className="">
                <div className="fwc-body">
                  {!this.state.loading && (
                    <div className="table-responsive">
                      {telegramChallenges?.length === 0 ? (
                        <div className="fwc-body">
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <table className="table large-table">
                          <thead>
                            <tr>
                              <th>Game</th>
                              <th>First Player</th>
                              <th>First Player Points</th>
                              <th>Second Player</th>
                              <th>Second Player Points</th>
                              <th>Challenged Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {telegramChallenges?.map((challenge, i) => (
                              <tr>
                                <td>
                                  <div className="td-text-box d-flex align-items-center">
                                    <div className="td-img margin-right-2 pointer">
                                      {challenge?.bgGame?.telegramAppImage && (
                                        <img
                                          src={process.env.REACT_APP_CDN + challenge?.bgGame?.telegramAppImage}
                                          alt=""
                                        />
                                      )}
                                    </div>

                                    {challenge?.bgGame?.name}
                                  </div>
                                </td>
                                <td>
                                  <span>
                                    <div className="td-img-group d-flex align-items-start ">
                                      <div className="td-img round-img m-right-2 pointer">
                                        <img
                                          src={
                                            challenge?.playerOneUser?.image
                                              ? process.env.REACT_APP_CDN + challenge?.playerOneUser?.image?.small
                                              : userIcon
                                          }
                                          alt=""
                                        />
                                      </div>

                                      <div>
                                        {challenge?.playerOneUser?.firstName} {challenge?.playerOneUser?.lastName}
                                        <br />
                                        {challenge?.playerOneUser?.userName}
                                      </div>
                                    </div>
                                  </span>
                                </td>
                                <td>
                                  {challenge?.playerOneHighestScore &&
                                    challenge?.playerOneHighestScore?.toLocaleString("en-us")}
                                </td>

                                <td>
                                  {challenge?.playerTwoUser && (
                                    <span>
                                      <div className="td-img-group d-flex align-items-start ">
                                        <div className="td-img round-img m-right-2 pointer">
                                          <img
                                            src={
                                              challenge?.playerTwoUser?.image
                                                ? process.env.REACT_APP_CDN + challenge?.playerTwoUser?.image?.small
                                                : userIcon
                                            }
                                            alt=""
                                          />
                                        </div>

                                        <div>
                                          {challenge?.playerTwoUser?.firstName} {challenge?.playerTwoUser?.lastName}
                                          <br />
                                          {challenge?.playerTwoUser?.userName}
                                        </div>
                                      </div>
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {challenge?.playerTwoHighestScore &&
                                    challenge?.playerTwoHighestScore?.toLocaleString("en-us")}
                                </td>
                                <td>{moment(challenge?.createdAt).format("MM/DD/YYYY hh:mm a")}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                  {!this.state.loading && !(this.state.page === 1 && telegramChallenges?.length === 0) && (
                    <div className="page-pagination">
                      <ul className="pagination  justify-content-end">
                        <li className="page-item">
                          <a
                            class={`page-link ${this.state.page === 1 && "disabled"}`}
                            href="#!"
                            aria-label="Previous"
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.state.page > 1) {
                                this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                              }
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                        <li className="page-item active">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {this.state.page}
                          </a>
                        </li>
                        {telegramChallenges?.length >= 50 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                              }}
                            >
                              {this.state.page + 1}
                            </a>
                          </li>
                        )}
                        <li className="page-item">
                          <a
                            class={`page-link ${telegramChallenges?.length < 50 && "disabled"}`}
                            href="#!"
                            aria-label="Next"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadTelegramChallenges: (pramas, callback) => dispatch(loadTelegramChallenges(pramas, callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserListing));
