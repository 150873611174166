import React, { createRef } from "react";
import { connect } from "react-redux";
import { getSettings, loadReports } from "store/settings";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import _, { debounce } from "lodash";
import { toast } from "react-toastify";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";
import Joi from "joi-browser";
import { truncateAddress } from "utils/misc";
import moment from "moment";
import { withRouter, Link } from "react-router-dom";
import { convertDate, formatDate } from "utils/dateConversion";

//  Images
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";
import filterIcon from "include/images/filter-icon.svg";
import sortIcon from "include/images/sort-icon.svg";

//  Components
import Form from "common/form/form";
import Header from "common/header";
import SideBar from "common/sidebar";
import Loader from "common/loader";
import AlertModal from "common/alert/alertModal";
import NextButton from "common/form/submitButton";
import closeIcon from "include/images/close-icon.svg";
import SelectSearch from "common/form/selectSearch";
import ListingActivitiesFlyer from "features/users/listingActivitiesFlyer";

class PaymentsListings extends Form {
  ref = createRef(null);
  lastKeys = [];
  state = {
    data: {
      date: "",
      keyword: "",
    },
    sorting: {
      sort: "lootBonusCoins",
      order: "desc",
    },
    showReasonFlyer: false,
    showRoundsFlyer: false,
    showActivitiesList: false,
    loadingReason: false,
    gameId: "",
    showFilters: false,
    loading: false,
    loadingReset: false,
    appliedFilters: {},
    page: 1,
    errors: {},
    contestsLoading: false,
    reasonType: "",
    showHistory: false,
    objectId: "",
    object: null,
    showRevertModal: false,
    game: null,
    selectedGameIndex: "",
    user: null,
    amountType: 0,
    currKey: "",
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getSettings.loading && this.props.getSettings.loading !== prevProps.getSettings.loading)
      this.ref.current.complete();

    if (this.props.getSettings.loading && this.props.getSettings.loading !== prevProps.getSettings.loading)
      this.ref.current.continuousStart();
  };
  componentDidMount = () => {
    this.searchFilters();
  };
  toggleActivitiesFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showActivitiesList: !this.state.showActivitiesList });
  };
  sort = (e, type) => {
    const data = { ...this.state.sorting };
    if (type === 1) {
      data.sort = "lootBonusCoins";
      data.order = "asc";
    } else if (type === 2) {
      data.sort = "lootBonusCoins";
      data.order = "desc";
    } else if (type === 3) {
      data.sort = "arcadeCoins";
      data.order = "asc";
    } else if (type === 4) {
      data.sort = "arcadeCoins";
      data.order = "desc";
    } else if (type === 5) {
      data.sort = "correctArcadeCoins";
      data.order = "asc";
    } else if (type === 6) {
      data.sort = "correctArcadeCoins";
      data.order = "desc";
    } else if (type === 7) {
      data.sort = "correctLootBonusCoins";
      data.order = "asc";
    } else if (type === 8) {
      data.sort = "correctLootBonusCoins";
      data.order = "desc";
    }
    this.lastKeys = [];
    this.setState({ sorting: data, page: 1 }, () => {
      this.searchFilters();
    });
  };
  debounce = (callback, delay) => {
    let timer;

    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), delay);
    };
  };

  //  Filter Methods
  searchFilters = () => {
    this.setState({ loading: true, loadingFilters: true });
    const { keyword } = this.state.data;

    const payLoad = { ...this.state.sorting, keyword };
    if (this.state.page > 1) payLoad.lastKey = this.lastKeys[this.lastKeys.length - 1];

    //  Load the report with sorting and date filter
    const date = this.state.data.date.length > 0 ? this.state.data.date : moment().subtract(1, "days").format();

    this.props.loadReports(payLoad, moment(date).format("YYYY-MM-DD"), (userRes) => {
      if (userRes.status === 200) {
        if (userRes?.data?.data?.length > 0) {
          //  Check the eligibility to award the AMOE Tickets on "postmarkedAt"
          this.setState({
            currKey: userRes?.data?.lastKey,
            loading: false,
            loadingFilters: false,
            data: { ...this.state.data, date: date },
          });
        } else {
          const dayBeforeYesterday = moment().subtract(2, "days").format();

          this.props.loadReports(payLoad, moment(dayBeforeYesterday).format("YYYY-MM-DD"), (userRes2) => {
            if (userRes2.status === 200) {
              this.setState({
                currKey: userRes2?.data?.lastKey,
                loading: false,
                loadingFilters: false,
                data: { ...this.state.data, date: dayBeforeYesterday },
              });
            }
          });
        }
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data)
          ?.filter((key) => key !== "date")
          ?.forEach((key) => {
            if (!_.isEmpty(data[key])) {
              appliedFilters[key] = data[key];
            }
          });
        this.setState({ appliedFilters, ticketsAwarded: false });
      } else {
        this.setState({ loading: false, loadingFilters: false });
      }
    });
  };
  resetFilters = (e) => {
    e.preventDefault();
    const data = { ...this.state.data };
    data.keyword = "";
    this.setState(
      {
        data,
        page: 1,
      },
      () => this.searchFilters(),
    );
  };

  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  schema = {
    date: Joi.date().allow(""),
    keyword: Joi.string().allow(""),
  };

  //  Toggle Methods for flyers
  toggleWonTicketsFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showWonTickets: !this.state.showWonTickets });
  };
  togglePreviousAmoeAwards = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAmoeAwards: !this.state.showAmoeAwards });
  };
  toggleEditFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  render() {
    const { reports } = this.props.getSettings;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="reports" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>
                Coin Balance Report{" "}
                {this.state.data.date?.length > 0 && <>({moment(this.state.data.date).format("MM-DD-YYYY")})</>}
              </h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showFilters: !this.state.showFilters });
                    }}
                  >
                    <img src={filterIcon} /> <span>Filters</span>
                  </a>
                </div>
                <div className="fmb-box">
                  <Dropdown className="filter-link-box custom-dropdown dropdown-lg position-relative">
                    <Dropdown.Toggle
                      className="dropdown-toggle custom-dropdown-toggle"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={sortIcon} />
                      <span>Sort</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                      <Dropdown.Item
                        className={`dropdown-item ${
                          this.state.sorting.order === "asc" &&
                          this.state.sorting.sort === "lootBonusCoins" &&
                          "orange-text"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.sort(e, 1);
                        }}
                      >
                        Actual Loot Bonus Bucks - Asc
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`dropdown-item ${
                          this.state.sorting.order === "desc" &&
                          this.state.sorting.sort === "lootBonusCoins" &&
                          "orange-text"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.sort(e, 2);
                        }}
                      >
                        Actual Loot Bonus Bucks - Desc
                      </Dropdown.Item>{" "}
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <Dropdown.Item
                        className={`dropdown-item ${
                          this.state.sorting.order === "asc" &&
                          this.state.sorting.sort === "arcadeCoins" &&
                          "orange-text"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.sort(e, 3);
                        }}
                      >
                        Actual Arcade Coins - Asc
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`dropdown-item ${
                          this.state.sorting.order === "desc" &&
                          this.state.sorting.sort === "arcadeCoins" &&
                          "orange-text"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.sort(e, 4);
                        }}
                      >
                        Actual Arcade Coins - Desc
                      </Dropdown.Item>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <Dropdown.Item
                        className={`dropdown-item ${
                          this.state.sorting.order === "asc" &&
                          this.state.sorting.sort === "correctArcadeCoins" &&
                          "orange-text"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.sort(e, 5);
                        }}
                      >
                        Correct Arcade Coins - Asc
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`dropdown-item ${
                          this.state.sorting.order === "desc" &&
                          this.state.sorting.sort === "correctArcadeCoins" &&
                          "orange-text"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.sort(e, 6);
                        }}
                      >
                        Correct Arcade Coins - Desc
                      </Dropdown.Item>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <Dropdown.Item
                        className={`dropdown-item ${
                          this.state.sorting.order === "asc" &&
                          this.state.sorting.sort === "correctLootBonusCoins" &&
                          "orange-text"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.sort(e, 7);
                        }}
                      >
                        Correct Loot Bucks - Asc
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`dropdown-item ${
                          this.state.sorting.order === "desc" &&
                          this.state.sorting.sort === "correctLootBonusCoins" &&
                          "orange-text"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          this.sort(e, 8);
                        }}
                      >
                        Correct Loot Bucks - Desc
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="fwc-wrapper">
              {this.state.showFilters && (
                <div className="fwc-head ">
                  <div className="fwc-inner">
                    <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                      <li className="flex-fill width-100">
                        <div className="fml-box">{this.renderInput("keyword", "Keyword", "Search via keywords")}</div>
                      </li>{" "}
                    </ul>

                    <div className="fwc-btn d-flex align-items-center justify-content-end">
                      <NextButton
                        handleSubmit={this.resetFilters}
                        classData="btn-text pointer"
                        label="Reset"
                        loading={this.state.loadingReset}
                      />
                      <NextButton
                        handleSubmit={() => this.setState({ page: 1 }, () => this.searchFilters())}
                        classData="btn btn-primary btn-sm"
                        label="Search"
                        loading={this.state.loadingFilters}
                      />
                    </div>
                  </div>{" "}
                  {!_.isEmpty(this.state.appliedFilters) && (
                    <div className="filter-tag-container d-flex align-items-center ">
                      <div className="fwc-left">
                        <div className="filter-tag-list">
                          <p>filter applied :</p>

                          {Object.keys(this.state.appliedFilters)?.map((key) => (
                            <div className="filter-tag-item">
                              <span className="camelCase">Keyword</span>:{" "}
                              {key === "keyword" && this.state.appliedFilters[key]}
                              <a href="#!" onClick={this.resetFilters}>
                                <img src={closeIcon} alt="" />
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {reports.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Username / Email</th>
                            <th>Actual Balance</th>
                            <th>Entry Points</th>
                            <th>Exit Points</th>
                            <th>Ideal Balance</th>
                            <th>Final Result</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reports?.length > 0 &&
                            reports.map((report, i) => {
                              const entryArcadeCoins =
                                report.freeCreditsFromAdminArcadeCoins +
                                report.freeSignupCreditsArcadeCoins +
                                report.creditOrderCreditedArcadeCoins +
                                report.bountyBoxCreditedArcadeCoins +
                                report.refundsArcadeCoins +
                                report.rewardsArcadeCoins;
                              const entryLootCoins =
                                report.freeCreditsFromAdminLootBonusCoins +
                                report.freeSignupCreditsLootBonusCoins +
                                report.creditOrderCreditedLootBonusCoins +
                                report.bountyBoxCreditedLootBonusCoins +
                                report.refundsLootBonusCoins +
                                report.rewardsLootBonusCoins;
                              const exitArcadeCoins = report?.contestEntriesArcadeCoins + report?.expiredArcadeCoins;
                              const exitLootCoins =
                                report?.contestEntriesLootBonusCoins + report?.expiredLootBonusCoins;

                              return (
                                <tr>
                                  <td>
                                    {report?.userName}
                                    <br />
                                    {report?.email}
                                  </td>
                                  <td className="ust-tag">
                                    <Dropdown className=" custom-dropdown my-2 pointer-none">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <span className={`w-100 text-center d-block arcade`}>
                                          Arcade Coins: {report?.balanceInUserProfileArcadeCoins?.toLocaleString() ?? 0}
                                        </span>
                                      </Dropdown.Toggle>
                                    </Dropdown>
                                    <Dropdown className=" custom-dropdown my-2 pointer-none">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <span className={`w-100 text-center d-block loot`}>
                                          Loot Bucks:{" "}
                                          {report?.balanceInUserProfileLootBonusCoins.toLocaleString("en-us") ?? 0}
                                        </span>
                                      </Dropdown.Toggle>
                                    </Dropdown>
                                  </td>{" "}
                                  <td className="ust-tag">
                                    <Dropdown className=" custom-dropdown my-2">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className={`custom-dropdown-toggle `}
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <span className={`w-100 text-center d-block arcade `}>
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Popover
                                                id="popoverPannel"
                                                className="custom-tooltip "
                                                data-bs-title="Illegitimate"
                                              >
                                                <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                                  <h4>
                                                    Free Award From Admin Panel:{" "}
                                                    {report.freeCreditsFromAdminArcadeCoins}{" "}
                                                  </h4>
                                                  <h4>Free Signup Bonus: {report.freeSignupCreditsArcadeCoins} </h4>
                                                  <h4>Package Purchase : {report.creditOrderCreditedArcadeCoins} </h4>
                                                  <h4>Bounty Box Redeem: {report.bountyBoxCreditedArcadeCoins} </h4>
                                                  <h4>Refunds: {report.refundsArcadeCoins} </h4>
                                                  <h4>Rewards: {report.rewardsArcadeCoins} </h4>
                                                </Popover.Body>
                                              </Popover>
                                            }
                                          >
                                            <div
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-custom-class="custom-tooltip"
                                              data-bs-title="Illegitimate"
                                            >
                                              Arcade Coins: {entryArcadeCoins?.toLocaleString() ?? 0}
                                            </div>
                                          </OverlayTrigger>
                                        </span>
                                      </Dropdown.Toggle>
                                    </Dropdown>
                                    <Dropdown className=" custom-dropdown my-2">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className={`custom-dropdown-toggle `}
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <span className={`w-100 text-center d-block loot `}>
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Popover
                                                id="popoverPannel"
                                                className="custom-tooltip "
                                                data-bs-title="Illegitimate"
                                              >
                                                <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                                  <h4>
                                                    Free Award From Admin Panel:{" "}
                                                    {report.freeCreditsFromAdminLootBonusCoins}{" "}
                                                  </h4>
                                                  <h4>Free Signup Bonus: {report.freeSignupCreditsLootBonusCoins} </h4>
                                                  <h4>
                                                    Package Purchase : {report.creditOrderCreditedLootBonusCoins}{" "}
                                                  </h4>
                                                  <h4>Bounty Box Redeem: {report.bountyBoxCreditedLootBonusCoins} </h4>
                                                  <h4>Refunds: {report.refundsLootBonusCoins} </h4>
                                                  <h4>Rewards: {report.rewardsLootBonusCoins} </h4>
                                                </Popover.Body>
                                              </Popover>
                                            }
                                          >
                                            <div
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-custom-class="custom-tooltip"
                                              data-bs-title="Illegitimate"
                                            >
                                              Loot Bucks: {entryLootCoins.toLocaleString("en-us") ?? 0}
                                            </div>
                                          </OverlayTrigger>
                                        </span>
                                      </Dropdown.Toggle>
                                    </Dropdown>
                                  </td>{" "}
                                  <td className="ust-tag">
                                    <Dropdown className=" custom-dropdown my-2 ">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className={`custom-dropdown-toggle `}
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <span className={`w-100 text-center d-block arcade `}>
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Popover
                                                id="popoverPannel"
                                                className="custom-tooltip "
                                                data-bs-title="Illegitimate"
                                              >
                                                <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                                  <h4>Contest Entries: {report?.contestEntriesArcadeCoins} </h4>
                                                  <h4>Deducted Coins: {report?.expiredArcadeCoins} </h4>
                                                </Popover.Body>
                                              </Popover>
                                            }
                                          >
                                            <div
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-custom-class="custom-tooltip"
                                              data-bs-title="Illegitimate"
                                            >
                                              Arcade Coins: {exitArcadeCoins?.toLocaleString() ?? 0}
                                            </div>
                                          </OverlayTrigger>
                                        </span>
                                      </Dropdown.Toggle>
                                    </Dropdown>
                                    <Dropdown className=" custom-dropdown my-2 ">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className={`custom-dropdown-toggle `}
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <span className={`w-100 text-center d-block loot `}>
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Popover
                                                id="popoverPannel"
                                                className="custom-tooltip "
                                                data-bs-title="Illegitimate"
                                              >
                                                <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                                  <h4>Contest Entries: {report.contestEntriesLootBonusCoins} </h4>
                                                  <h4>Deducted Bucks: {report?.expiredLootBonusCoins} </h4>
                                                </Popover.Body>
                                              </Popover>
                                            }
                                          >
                                            <div
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-custom-class="custom-tooltip"
                                              data-bs-title="Illegitimate"
                                            >
                                              Loot Bucks: {exitLootCoins?.toLocaleString("en-us") ?? 0}
                                            </div>
                                          </OverlayTrigger>
                                        </span>
                                      </Dropdown.Toggle>
                                    </Dropdown>
                                  </td>{" "}
                                  <td className="ust-tag">
                                    <Dropdown className=" custom-dropdown my-2 pointer-none">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <span className={`w-100 text-center d-block arcade`}>
                                          Arcade Coins:{" "}
                                          {report?.expectedBalanceArcadeCoins?.toLocaleString("en-us") ?? 0}
                                        </span>
                                      </Dropdown.Toggle>
                                    </Dropdown>
                                    <Dropdown className=" custom-dropdown my-2 pointer-none">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <span className={`w-100 text-center d-block loot`}>
                                          Loot Bucks:{" "}
                                          {report?.expectedBalanceLootBonusCoins?.toLocaleString("en-us") ?? 0}
                                        </span>
                                      </Dropdown.Toggle>
                                    </Dropdown>
                                  </td>
                                  <td className="ust-tag">
                                    <Dropdown
                                      className={` custom-dropdown my-2 pointer-cursor`}
                                      onClick={(e) => {
                                        this.setState(
                                          {
                                            user: {
                                              id: report?.userId,
                                              userName: report?.userName,
                                              email: report?.email,
                                            },
                                            amountType: 1,
                                          },
                                          () => {
                                            this.toggleActivitiesFlyer(e);
                                          },
                                        );
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <span
                                          className={`w-100 text-center d-block ${
                                            report?.isBalanceCorrectArcadeCoins ? "tickets" : "suspended"
                                          }`}
                                        >
                                          Arcade Coins: {report?.isBalanceCorrectArcadeCoins ? "Yes" : "No"}
                                        </span>
                                      </Dropdown.Toggle>
                                    </Dropdown>
                                    <Dropdown
                                      className={` custom-dropdown my-2 pointer-cursor`}
                                      onClick={(e) => {
                                        this.setState(
                                          {
                                            user: {
                                              id: report?.userId,
                                              userName: report?.userName,
                                              email: report?.email,
                                            },
                                            amountType: 3,
                                          },
                                          () => {
                                            this.toggleActivitiesFlyer(e);
                                          },
                                        );
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <span
                                          className={`w-100 text-center d-block ${
                                            report?.isBalanceCorrectLootBonusCoins ? "tickets" : "suspended"
                                          }`}
                                        >
                                          Loot Bucks: {report?.isBalanceCorrectLootBonusCoins ? "Yes" : "No"}
                                        </span>
                                      </Dropdown.Toggle>
                                    </Dropdown>
                                  </td>{" "}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}

                {!this.state.loading && !(this.state.page === 1 && reports.length === 0) && (
                  <div className="page-pagination">
                    <ul className="pagination  justify-content-end">
                      <li className="page-item">
                        <a
                          class={`page-link ${this.state.page === 1 && "disabled"}`}
                          href="#!"
                          aria-label="Previous"
                          onClick={(e) => {
                            e.preventDefault();
                            if (this.state.page > 1) {
                              this.setState({ page: this.state.page - 1, currKey: this.lastKeys.pop() }, () =>
                                this.searchFilters(),
                              );
                            }
                          }}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                      <li className="page-item active">
                        <a
                          className="page-link"
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {this.state.page}
                        </a>
                      </li>
                      {reports.length >= 50 && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                              this.lastKeys.push(this.state.currKey);
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            {this.state.page + 1}
                          </a>
                        </li>
                      )}
                      <li className="page-item">
                        <a
                          class={`page-link ${reports.length < 50 && "disabled"}`}
                          href="#!"
                          aria-label="Next"
                          onClick={(e) => {
                            e.preventDefault();
                            this.lastKeys.push(this.state.currKey);
                            this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                          }}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>{" "}
        <ListingActivitiesFlyer
          showModal={this.state.showActivitiesList}
          toggleModal={this.toggleActivitiesFlyer}
          user={this.state.user}
          amountType={this.state.amountType}
        />
        {(this.state.showWonTickets ||
          this.state.showEditModal ||
          this.state.showAmoeAwards ||
          this.state.showActivitiesList) && (
          <div
            className="modal-backdrop show fade z-index-1000"
            onClick={
              this.state.showWonTickets
                ? this.toggleWonTicketsFlyer
                : this.state.showAmoeAwards
                ? this.togglePreviousAmoeAwards
                : this.state.showActivitiesList
                ? this.toggleActivitiesFlyer
                : this.toggleEditFlyer
            }
          ></div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadReports: (prams, date, callback) => dispatch(loadReports(prams, date, callback)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentsListings));
