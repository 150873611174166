import React from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import {
  getSettings,
  loadHowItWorksTemplates,
  addHowItWorksTemplate,
  updateHowItWorksTemplate,
  addHowItWorksTemplateReceived,
  updateHowItWorksTemplateReceived,
} from "store/settings";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Joi from "joi-browser";
import _ from "lodash";
import Amplify, { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import plusIcon from "include/images/plus-1.svg";
import deleteIcon from "include/images/delete-icon.svg";

//  Components
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});
class AddFlyer extends Form {
  fileInputRef = React.createRef(null);
  state = {
    loading: false,
    data: {
      description: "",
      title: "",
      image: null,
      steps: [{ title: "", description: "", displayOrder: 1, image: null }],
    },
    errors: {},
    loadingTemplate: false,
    loadingImages: false,
    loadingMainImages: false,
    imageChange: false,
    imgInd: "",
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal) {
        if (this.props.edit) {
          const data = { ...this.state.data };
          data.title = this.props.edit.title;
          data.description = this.props.edit.description;
          data.image = this.props.edit.image;
          data.steps = this.props.edit.steps.map((step) => {
            const newStep = {
              title: step?.title,
              description: step.description,
              displayOrder: step.displayOrder,
              image: step.image,
            };
            return newStep;
          });
          this.setState({ data });
        }
      }
    }
  };
  doSubmit = (e) => {
    this.setState({ loadingTemplate: true });
    const data = { ...this.state.data };
    const errors = structuredClone(this.state.errors);
    const steps = structuredClone(data.steps);
    errors.steps = structuredClone(steps);
    let err = false;
    if (data.title === "") {
      errors.title = "Title is required.";
      err = true;
    }
    if (data.description === "") {
      errors.description = "Description is required.";
      err = true;
    }
    if (!data.image) {
      errors.image = "Image is required.";
      err = true;
    }
    if (data.steps.length > 0) {
      for (let i = 0; i < data.steps.length; i++) {
        const isData =
          !_.isEmpty(data.steps[i].title) || !_.isEmpty(data.steps[i].description) || !_.isEmpty(data.steps[i].image);
        console.log(isData);
        if (!isData) {
          data.steps.splice(i, 1);
        } else {
          if (!data.steps[i].description) {
            errors.steps[i].descriptionErr = "Description is required";
            err = true;
          } else {
            delete errors.steps[i].descriptionErr;
          }
          if (!data.steps[i].image) {
            errors.steps[i].imageErr = "Image is required";
            err = true;
          } else {
            delete errors.steps[i].imageErr;
          }
        }
      }
    }
    if (err) {
      return this.setState({ loadingTemplate: false, errors });
    } else {
      this.setState({ errors: {} });
    }
    const payLoad = {
      title: data.title,
      description: data.description,
      image: data.image?.medium,
      steps: data.steps.map((step) => ({ ...step, image: step.image.medium })),
    };
    if (this.props.edit) {
      this.props.updateHowItWorksTemplate(this.props.edit.id, payLoad, (templateResponse) => {
        this.setState({
          loadingTemplate: false,
        });
        if (templateResponse.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.addHowItWorksTemplateReceived(templateResponse.data.data);
          this.clearData();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={templateResponse.data && templateResponse.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      this.props.addHowItWorksTemplate(payLoad, (templateResponse) => {
        this.setState({
          loadingTemplate: false,
        });
        if (templateResponse.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.addHowItWorksTemplateReceived(templateResponse.data.data);
          this.clearData();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={templateResponse.data && templateResponse.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };
  schema = {
    title: Joi.string().allow(""),
    description: Joi.string().allow(""),
    image: Joi.object().allow(null),
    steps: Joi.array().allow([]),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        description: "",
        title: "",
        image: null,
        steps: [{ title: "", description: "", displayOrder: 1, image: null }],
      },
      errors: {},
      loadingTemplate: false,
    });
  };
  addOne = (e) => {
    if (e) e.preventDefault();
    const data = { ...this.state.data };
    data.steps = [...data.steps, { title: "", description: "", displayOrder: 1, image: null }];
    this.setState({ data });
  };
  removeOne = (index) => {
    const data = structuredClone(this.state.data);
    const errors = structuredClone(this.state.errors);
    if (errors.steps && errors.steps[index]) {
      delete errors?.steps[index];
    }
    data.steps?.splice(index, 1);
    this.setState({ data });
  };
  changeOne = (name, value, index) => {
    const data = structuredClone(this.state.data);
    const errors = structuredClone(this.state.errors);
    const stepErr = errors?.steps && errors.steps[index];
    if (stepErr && stepErr[name + "Err"]) {
      delete stepErr[name + "Err"];
    }
    const newStep = data.steps[index];
    newStep[name] = value;
    data.steps[index] = newStep;
    this.setState({ data, errors });
  };

  handleMainImageChange = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    this.setState({ loadingMainImages: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ loadingMainImages: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ loadingMainImages: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.setState({
          errors,
          imageChange: true,
          loadingMainImages: false,
          data: {
            ...this.state.data,
            image: { medium: "public/" + result.key },
          },
        });
      });
    }
    this.setState({ data });
    e.target.value = null;
  };
  handleStepsImageChange = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    this.setState({ loadingImages: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ loadingImages: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ loadingImages: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.changeOne("image", { medium: "public/" + result.key }, this.state.imgInd);
        this.setState({
          imageChange: true,
          loadingImages: false,
        });
      });
    }
    this.setState({ data });
    e.target.value = null;
  };
  render() {
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel almost-full-modal"}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>
            {this.props.edit ? (this.props.type === "contest" ? "" : "Edit ") : "Add "}
            How It Works Template
          </h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <div className="row">
                <div className="col-12 new-form-group">{this.renderInput("title", "Title", "", "")}</div>
              </div>
            </div>
            <div className="new-form-group">
              <div className="row">
                <div className="col-12 new-form-group">{this.renderTextarea("description", "Description", "", "")}</div>
              </div>
            </div>
            <div className="new-form-group">
              <div className="kt-upload-row d-flex flex-wrap align-items-start">
                <div
                  className={`kt-upload-outer ${
                    !this.state.data.image ? "placeholder-uploaded-img" : " position-relative"
                  } ${this.state.errors.image && "border-error"}`}
                >
                  <div className="kt-upload-img">
                    <img
                      src={
                        this.state.data.image
                          ? this.state.data.image.medium
                            ? this.state.data.image.medium.includes("https://")
                              ? this.state.data.image.medium
                              : process.env.REACT_APP_CDN + this.state.data.image.medium
                            : process.env.REACT_APP_CDN + this.state.data.image
                          : plusIcon
                      }
                      alt=""
                    />
                  </div>{" "}
                  {this.state.data.image && (
                    <div
                      className="delete-icon d-flex align-items-center justify-content-center"
                      onClick={(e) => {
                        const data = this.state.data;
                        data.image = null;
                        this.setState({ data });
                      }}
                    >
                      <img src={deleteIcon} alt="delete" />
                    </div>
                  )}
                </div>
                <div className="kt-file-input">
                  <label htmlFor="formFile" className="kt-label">
                    {this.state.data.image ? " Change Image" : "+ Upload an Image"}
                  </label>
                  <input className="form-control" type="file" id="formFile" onChange={this.handleMainImageChange} />
                </div>
              </div>
            </div>
            <div className="new-form-group">
              <table className="table large-table flyer-table">
                <thead>
                  <tr>
                    <th style={{ width: "8%" }}>Display Order</th>
                    <th style={{ width: "20%" }}>Title</th>
                    <th>Description</th>
                    <th style={{ width: "6%" }}>Image</th>
                    <th style={{ width: 10 }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.steps &&
                    this.state.data.steps.map((step, index) => (
                      <tr className="flyer-table" key={index}>
                        <td className="vertical-middle">{index + 1}</td>
                        <td>
                          <input
                            name="title"
                            value={step.title}
                            className={`form-control `}
                            onChange={(e) => {
                              this.changeOne(e.target.name, e.target.value, index);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            name="description"
                            value={step.description}
                            className={`form-control ${
                              this.state.errors?.steps &&
                              this.state.errors?.steps[index] &&
                              this.state.errors.steps[index].descriptionErr &&
                              "error"
                            }`}
                            onChange={(e) => {
                              this.changeOne(e.target.name, e.target.value, index);
                            }}
                          />
                        </td>
                        <td className="d-flex flex-wrap align-items-start">
                          <div>
                            <div
                              className={`kt-upload-outer-small ${
                                this.state.loadingImages ? "pointer-none" : "pointer"
                              } ${!step.image ? "placeholder-uploaded-img" : " position-relative"} ${
                                this.state.errors?.steps &&
                                this.state.errors?.steps[index] &&
                                this.state.errors.steps[index].imageErr &&
                                "border-error"
                              }`}
                              htmlFor={`formFile-${index}`}
                              onClick={(e) => {
                                this.setState({ imgInd: index }, () => {
                                  this.fileInputRef?.current?.click();
                                });
                              }}
                            >
                              <div className="kt-upload-img">
                                <img
                                  src={
                                    step?.image
                                      ? step?.image?.medium?.includes("https://")
                                        ? step?.image.medium
                                        : process.env.REACT_APP_CDN + step?.image?.medium
                                      : plusIcon
                                  }
                                  alt=""
                                />
                              </div>
                              {step?.image && (
                                <div
                                  className="delete-icon d-flex align-items-center justify-content-center"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const data = this.state.data;
                                    const { steps } = data;
                                    steps[index].image = null;
                                    data.steps = steps;
                                    this.setState({ data });
                                  }}
                                >
                                  <img src={deleteIcon} alt="delete" />
                                </div>
                              )}
                            </div>
                            <div className="kt-file-input">
                              <input
                                className="form-control"
                                type="file"
                                style={{ display: "none" }}
                                id={`formFile-${index}`}
                                ref={this.fileInputRef}
                                onChange={(e) => {
                                  this.handleStepsImageChange(e);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <img
                            src={closeIcon}
                            alt=""
                            className="pointer"
                            onClick={(e) => {
                              this.removeOne(index);
                            }}
                          />{" "}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>{" "}
              <div className="new-form-group new-form-btn">
                <NextButton
                  handleSubmit={this.addOne}
                  label="+ Add more"
                  loading={this.state.loadingContest || this.state.loadingImages}
                  classData={"btn btn-primary btn-sm orange-hover"}
                />
              </div>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loadingTemplate || this.state.loadingMainImages || this.state.loadingImages}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addHowItWorksTemplate: (data, callback) => dispatch(addHowItWorksTemplate(data, callback)),
  updateHowItWorksTemplate: (id, data, callback) => dispatch(updateHowItWorksTemplate(id, data, callback)),
  loadHowItWorksTemplates: (params, callback) => dispatch(loadHowItWorksTemplates(params, callback)),
  addHowItWorksTemplateReceived: (payLoad) => dispatch(addHowItWorksTemplateReceived(payLoad)),
  updateHowItWorksTemplateReceived: (payLoad) => dispatch(updateHowItWorksTemplateReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFlyer));
