import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { getSweepstake, loadCurrencies, deleteCurrency, deleteCurrencyReceived } from "store/sweepstakes";
import { toast } from "react-toastify";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import _ from "lodash";
import moment from "moment";
import { truncateAddress } from "utils/misc";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import plusIcon from "include/images/plus.svg";

//  Components
import Header from "common/header";
import SideBar from "common/sidebar";
import Loader from "common/loader";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";
import DeleteModal from "common/alert/alertModal";
import AddContract from "./addCurrency";

class ContractListing extends Component {
  ref = createRef(null);
  state = {
    data: {
      messages: [],
    },
    id: "",
    loadingOptions: false,
    loadingMain: false,
    loadingDelete: false,
    loading: false,
    showDeleteModal: false,
    showAddModal: false,
    deleteId: "",
    editData: null,
    showHistory: false,
    objectId: "",
    object: null,
    page: 1,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getSweepstake.loading && this.props.getSweepstake.loading !== prevProps.getSweepstake.loading)
      this.ref.current.complete();

    if (this.props.getSweepstake.loading && this.props.getSweepstake.loading !== prevProps.getSweepstake.loading)
      this.ref.current.continuousStart();
    if (this.state.showAddModal !== prevState.showAddModal && !this.state.showAddModal) {
      setTimeout(() => {
        this.setState({ editData: null });
      }, 500);
    }
  };
  componentDidMount = () => {
    this.setState({ loadingMain: true });
    this.props.loadCurrencies((res) => {
      this.setState({
        loadingMain: false,
      });
    });
  };
  toggleHistoryFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showHistory: !this.state.showHistory });
  };
  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };
  handleDelete = (e) => {
    this.setState({ loadingDelete: true });
    this.props.deleteCurrency(this.state.deleteId, (currencyRes) => {
      if (currencyRes.status === 200) {
        toast(<AlertSuccess message="Deleted!" />, {
          containerId: 1,
        });
        this.props.deleteCurrencyReceived({ id: this.state.deleteId });
        this.alertModalToggle();
        this.setState({ loadingDelete: false });
      } else {
        toast(<AlertError message={currencyRes.data && currencyRes.data.message} />, {
          containerId: 1,
        });
        this.alertModalToggle();
        this.setState({ loadingDelete: false });
      }
    });
  };

  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  render() {
    const { currencies } = this.props.getSweepstake ?? [];
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="sweepstakes" />
          {!this.state.loadingMain && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1>Sweeps Currency</h1>
                <div className="filter-menu-box d-flex align-items-center ms-auto">
                  <div className="fmb-box">
                    <Link to="#" onClick={this.toggleAddModal} className="btn btn-default btn-sm">
                      <img src={plusIcon} alt="" /> Add a Sweeps Currency
                    </Link>
                  </div>
                </div>
              </div>
              <div className="fwc-wrapper">
                <div className="fwc-body">
                  {!this.state.loading && (
                    <div className="table-responsive">
                      {currencies.length === 0 ? (
                        <div className="fwc-body">
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <table className="table large-table">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center", width: 40 }}>Actions</th>
                              <th>Title</th>
                              <th>Contract Address</th>
                              <th>Blockchain Network</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currencies?.length > 0 &&
                              currencies.map((currency, i) => (
                                <tr>
                                  <td>
                                    <Dropdown className=" custom-dropdown">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <img src={carrentIcon} alt="" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ editData: currency }, () => {
                                              this.toggleAddModal();
                                            });
                                          }}
                                        >
                                          Edit
                                        </Dropdown.Item>{" "}
                                        <Dropdown.Item
                                          className="dropdown-item delete"
                                          onClick={(e) => {
                                            this.setState({ deleteId: currency.id }, () => {
                                              this.alertModalToggle(e);
                                            });
                                          }}
                                        >
                                          Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                  <td>
                                    <Link
                                      to="!#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ editData: currency }, () => {
                                          this.toggleAddModal();
                                        });
                                      }}
                                    >
                                      {currency.title}
                                    </Link>
                                  </td>{" "}
                                  <td>
                                    <OverlayTrigger
                                      placement="top"
                                      trigger={["hover"]}
                                      overlay={
                                        <Popover className="custom-tooltip " id={currency.id + "add"}>
                                          <Popover.Body className="tooltip-inner tooltip-inner-ca tooltip-inner-reason ">
                                            <h4>{currency?.contractAddress}</h4>
                                          </Popover.Body>
                                        </Popover>
                                      }
                                    >
                                      <Link
                                        to="!#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          navigator.clipboard.writeText(currency?.contractAddress);
                                          toast(<AlertSuccess message="Copied to clipboard" />, { containerId: 1 });
                                        }}
                                      >
                                        <>{truncateAddress(currency?.contractAddress)}</>
                                      </Link>
                                    </OverlayTrigger>
                                  </td>
                                  <td>{currency?.blockChainNetwork}</td>
                                  <td>
                                    <div class="small-text">
                                      {moment(currency.createdAt).format("MM/DD/YYYY")}
                                      <br /> <small> {moment(currency.createdAt).format("hh:mm a")}</small>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}

                  {!this.state.loading && !(this.state.page === 1 && currencies.length === 0) && (
                    <div className="page-pagination">
                      <ul className="pagination  justify-content-end">
                        <li className="page-item">
                          <a
                            class={`page-link ${this.state.page === 1 && "disabled"}`}
                            href="!#"
                            aria-label="Previous"
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.state.page > 1) {
                                this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                              }
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                        <li className="page-item active">
                          <a
                            className="page-link"
                            href="!#"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {this.state.page}
                          </a>
                        </li>
                        {currencies.length >= 50 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="!#"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                              }}
                            >
                              {this.state.page + 1}
                            </a>
                          </li>
                        )}
                        <li className="page-item">
                          <a
                            class={`page-link ${currencies.length < 50 && "disabled"}`}
                            href="!#"
                            aria-label="Next"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <AddContract
          showModal={this.state.showAddModal}
          toggleModal={this.toggleAddModal}
          loadData={this.props.loadCurrencies}
          edit={this.state.editData}
        />{" "}
        <DeleteModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showDeleteModal}
          title="Alert"
          description="Are you sure you want to delete this item?"
          onPress={this.handleDelete}
          loading={this.state.loadingDelete}
        />
        {this.state.showAddModal && <div className="modal-backdrop show fade" onClick={this.toggleAddModal}></div>}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadCurrencies: (callback) => dispatch(loadCurrencies(callback)),
  deleteCurrency: (id, callback) => dispatch(deleteCurrency(id, callback)),
  deleteCurrencyReceived: (payLoad) => dispatch(deleteCurrencyReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getSweepstake: getSweepstake(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractListing));
