import Joi from "joi-browser";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";

//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import { addBoardGameColorMap, updateBoardGameColorMap } from "store/boardGame";

class AddFlyer extends Form {
  fileInputRef = React.createRef(null);
  initialState = {
    data: {
      hexColor: "",
      myType: "",
      tt: "",
      canStandOn: false,
      hasArch: false,
    },
    id: "",
    edit: false,
    loading: false,
    errors: {},
  };
  state = { ...this.initialState };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal) {
        if (this.props.edit) {
          const data = { ...this.state.data };
          data.hexColor = this.props.edit?.hexColor;
          data.myType = this.props.edit?.myType;
          data.tt = this.props.edit?.tt;
          data.canStandOn = this.props.edit?.canStandOn;
          data.hasArch = this.props.edit?.hasArch;

          this.setState({ data, edit: true, id: this.props.edit?._id });
        }
      }
    }
  };
  doSubmit = (e) => {
    this.setState({ loading: true });
    const data = { ...this.state.data };
    const payLoad = {
      myType: data.myType,
      tt: data.tt,
      canStandOn: data.canStandOn,
      hasArch: data.hasArch,
    };
    if (this.props.edit) {
      this.props.updateBoardGameColorMap(payLoad, this.state.id, (res) => {
        this.setState({ loading: false });
        if (res.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.loadData();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={res.data && res.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      payLoad.hexColor = data?.hexColor;

      this.props.addBoardGameColorMap(payLoad, (res) => {
        this.setState({
          loading: false,
        });
        if (res.status === 200) {
          this.props.loadData();
          toast(<AlertSuccess message="Information Saved" />);
          this.props.toggleModal();
        } else {
          toast(<AlertError message={res.data && res.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };
  schema = {
    hexColor: Joi.string().required(),
    myType: Joi.string().allow(""),
    tt: Joi.string().allow(""),
    canStandOn: Joi.boolean().allow(false),
    hasArch: Joi.boolean().allow(false),
  };

  clearData = () => {
    this.setState({
      ...this.initialState,
    });
  };
  toggleCheckbox = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    data[e.target.name] = !data[e.target.name];
    delete errors[e.target.name];
    this.setState({ data, errors });
  };
  render() {
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>
            {this.props.edit ? "Edit " : "Add "}
            Color Map
          </h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <table className="table large-table flyer-table">
                <tbody>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Hex Color</td>
                    <td>{this.renderInput("hexColor", "", "", "text", this.props.edit)}</td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">My Type</td>
                    <td>{this.renderInput("myType", "")}</td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Tt</td>
                    <td>{this.renderInput("tt", "")}</td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Can Stand On</td>
                    <td>
                      <div className="has-circle-radio">
                        <input
                          className={`form-check-input position-static ${
                            this.state.errors.canStandOn && "border-error"
                          }`}
                          type="checkbox"
                          name="canStandOn"
                          id="radioCheckOneFlyer"
                          checked={this.state.data.canStandOn}
                          onChange={this.toggleCheckbox}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="flyer-table">
                    <td className="vertical-middle">Has Arch </td>
                    <td>
                      <div className="has-circle-radio">
                        <input
                          className={`form-check-input position-static ${this.state.errors.hasArch && "border-error"}`}
                          type="checkbox"
                          name="hasArch"
                          id="radioCheckOneFlyer"
                          checked={this.state.data.hasArch}
                          onChange={this.toggleCheckbox}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loading}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addBoardGameColorMap: (data, callback) => dispatch(addBoardGameColorMap(data, callback)),
  updateBoardGameColorMap: (id, data, callback) => dispatch(updateBoardGameColorMap(id, data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(AddFlyer));
