import { combineReducers } from "redux";

import questionReducer from "./question";
import userReducer from "./user";
import rewardReducer from "./reward";
import contestReducer from "./contest";
import settingsReducer from "./settings";
import gamesReducer from "./games";
import usersReducer from "./users";
import dailyTriviaReducer from "./dailyTrivia";
import emailsReducer from "./emails";
import sweepstake from "./sweepstakes";
import dashboard from "./dashboard";
import lootbox from "./lootbox";
import boardGame from "./boardGame";
import telegram from "./telegram";

export default combineReducers({
  question: questionReducer,
  user: userReducer,
  reward: rewardReducer,
  contest: contestReducer,
  settings: settingsReducer,
  users: usersReducer,
  game: gamesReducer,
  emails: emailsReducer,
  dailyTrivia: dailyTriviaReducer,
  sweepstake,
  dashboard,
  lootbox,
  boardGame,
  telegram,
});
