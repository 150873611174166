import Joi from "joi-browser";
import _ from "lodash";
import { createRef } from "react";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getContest, loadContestWinners, markWinnerAsFeaturedReceived } from "store/contest";
import { markAsFeatured } from "store/games";
import { loadUsers } from "store/users";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";
import closeIcon from "include/images/close-icon.svg";
import filterIcon from "include/images/filter-icon.svg";
import historyIcon from "include/images/history-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import loadingIcon from "include/images/loader-grey.svg";

//  Components
import Form2 from "common/form/form";
import NextButton from "common/form/submitButton";
import Header from "common/header";
import HistoryFlyer from "common/historyFlyer";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import moment from "moment";
import ListingFlyer from "./listingFlyer";

class UserListing extends Form2 {
  ref = createRef(null);
  scrollRef = createRef(null);
  state = {
    currKey: "",
    loadingReset: false,
    loading: false,
    showFilters: false,
    showGameList: false,
    contest: "",
    winner: "",
    objectId: "",
    object: null,
    loadingFeatured: false,
    amountType: 1,
    loadingMore: false,
  };
  handleLoadMore = (entries) => {
    const target = entries[0];
    if (target?.isIntersecting) {
      const { contests, totalCount } = this.props.getContest;
      if (!this.state.loadingMore && contests?.length < totalCount) {
        this.setState({ loadingMore: true });
        this.searchFilters();
        this.observer.disconnect();
      }
    }
  };
  observer = new IntersectionObserver(this.handleLoadMore, {
    threshold: 0.2,
  });
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getContest.loading && this.props.getContest.loading !== prevProps.getContest.loading)
      this.ref.current.complete();

    if (this.props.getContest.loading && this.props.getContest.loading !== prevProps.getContest.loading)
      this.ref.current.continuousStart();
    const params = new URLSearchParams(this.props.location.search);
    const prevParams = new URLSearchParams(prevProps.location.search);
    const status = params.get("status");
    const prevStatus = prevParams.get("status");
    if (status !== prevStatus) {
      this.setState(
        {
          currKey: "",
          loadingReset: false,
          loading: false,
          showFilters: false,
          showGameList: false,
          contest: "",
          winner: "",
          loadingFeatured: false,
        },
        () => {
          this.setState({ loading: true });
          this.searchFilters();
        },
      );
    }

    if (this.state.currKey !== prevState.currKey && this.state.currKey !== null) {
      if (this.observer && this.scrollRef?.current) {
        this.observer.observe(this.scrollRef.current);
      }
    }
  };

  componentDidMount = () => {
    this.setState({ loading: true });
    this.searchFilters();
  };

  toggleGameFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showGameList: !this.state.showGameList });
  };

  searchFilters = (e) => {
    if (e) e.preventDefault();
    const searchParams = new URLSearchParams(this.props.location.search);
    const status = searchParams.get("status");
    const payLoad = {};
    if (status === "open") {
      payLoad.actionNeeded = true;
    }
    if (status === "closed") {
      payLoad.actionNeeded = false;
    }
    var params = _.pickBy(payLoad, function (value, key) {
      return value !== "";
    });
    if (this.state.currKey) params.lastKey = this.state.currKey;

    this.props.loadContestWinners({ ...params }, this.state.currKey?.length === 0, (contestsRes) => {
      this.setState({
        loading: false,
        currKey: contestsRes?.data?.lastKey,
        loadingMore: false,
      });
    });
  };

  onRefChange = (node, contests, i, totalCount) => {
    if (i === contests.length - 1 && contests.length < totalCount) {
      this.scrollRef.current = node;
    }
  };
  render() {
    const { contests, totalCount, filteredCount } = this.props.getContest;
    const searchParams = new URLSearchParams(this.props.location.search);
    const status = searchParams.get("status");
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="winners" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>
                {status === "closed" ? "Selected" : "Select"} Winners {totalCount > 0 && `(${totalCount})`}
              </h1>{" "}
            </div>
            <div className="fwc-wrapper">
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {contests.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th style={{ textAlign: "center", width: 30 }}>Actions</th>
                            <th style={{ width: 400 }}>Contest</th>
                            <th align="center" className="text-center">
                              Arcade Coins Awarded Status
                            </th>{" "}
                            <th align="center" className="text-center">
                              XP Awarded Status
                            </th>
                            <th align="center" className="text-center">
                              Tickets Awarded Status
                            </th>
                            <th>Ended On </th>
                          </tr>
                        </thead>
                        <tbody>
                          {contests &&
                            contests.length > 0 &&
                            contests.map((cont, i) => {
                              return (
                                <tr key={cont?.id} ref={(node) => this.onRefChange(node, contests, i, totalCount)}>
                                  <td style={{ textAlign: "center" }}>
                                    <Dropdown className=" custom-dropdown">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <img src={carrentIcon} alt="" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ contest: cont, amountType: 1 }, () => {
                                              this.toggleGameFlyer(e);
                                            });
                                          }}
                                        >
                                          View Arcade Games
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ contest: cont, amountType: 3 }, () => {
                                              this.toggleGameFlyer(e);
                                            });
                                          }}
                                        >
                                          View Loot Bucks Games
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                  <td>
                                    <span>
                                      <div className="td-img-group d-flex align-items-start ">
                                        <div className="td-img round-img m-right-2">
                                          {cont.image && (
                                            <img src={process.env.REACT_APP_CDN + cont.image.small} alt="" />
                                          )}
                                        </div>
                                        <div>
                                          <div>{cont.title}</div>
                                          <span className="two-line-ellipsis subTitle-line2">{cont.bgGame?.name}</span>
                                        </div>
                                      </div>
                                    </span>
                                  </td>

                                  <td className="ust-tag" align="center">
                                    {cont?.entriesArcade > 0 && cont?.arcadeCoinToBeAwarded >= 0 ? (
                                      <div className="small-text">
                                        {cont?.arcadeCoinAwardFailed ? (
                                          <Dropdown className=" custom-dropdown" style={{ maxWidth: 160 }}>
                                            <Dropdown.Toggle
                                              id="tdDropdownOne"
                                              className={`custom-dropdown-toggle pointer-default`}
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                              onClick={() => {}}
                                            >
                                              <span className={`text-center d-block suspended`}>
                                                <OverlayTrigger
                                                  placement="top"
                                                  overlay={
                                                    <Popover
                                                      id="popoverPannel"
                                                      className="custom-tooltip "
                                                      data-bs-title="Illegitimate"
                                                    >
                                                      <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                                        <h4>{cont?.arcadeCoinAwardError}</h4>
                                                      </Popover.Body>
                                                    </Popover>
                                                  }
                                                >
                                                  <div
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    data-bs-custom-class="custom-tooltip"
                                                    data-bs-title="Illegitimate"
                                                  >
                                                    Error
                                                  </div>
                                                </OverlayTrigger>
                                              </span>
                                            </Dropdown.Toggle>
                                          </Dropdown>
                                        ) : (
                                          <Dropdown className=" custom-dropdown " style={{ maxWidth: 160 }}>
                                            <Dropdown.Toggle
                                              id="tdDropdownOne"
                                              className="custom-dropdown-toggle"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ contest: cont, amountType: 1 }, () => {
                                                  this.toggleGameFlyer(e);
                                                });
                                              }}
                                            >
                                              <span
                                                className={`w-100 text-center d-block pointer-none ${
                                                  cont?.arcadeCoinsAwarded
                                                    ? "active"
                                                    : cont?.arcadeCoinAwardFailed
                                                    ? "error"
                                                    : "inactive"
                                                } `}
                                              >
                                                {cont?.arcadeCoinsAwarded
                                                  ? "Awarded"
                                                  : cont?.arcadeCoinAwardFailed
                                                  ? "Error"
                                                  : cont?.isArcadeCoinAwardingStarted ||
                                                    cont?.isArcadeCoinDistributionCalculated
                                                  ? "Transfer In Progress"
                                                  : "Pending"}
                                              </span>
                                            </Dropdown.Toggle>
                                          </Dropdown>
                                        )}
                                        {cont.arcadeCoinsAwardedAt && (
                                          <small className="small-date">
                                            {moment(cont.arcadeCoinsAwardedAt).format("MM/DD/YYYY hh:mm a")}
                                          </small>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="small-text opacity-5 text-center">NA</div>
                                    )}
                                  </td>
                                  <td className="ust-tag" align="center">
                                    {cont?.entriesArcade > 0 && cont?.xPToBeAwarded >= 0 ? (
                                      <div className="small-text">
                                        {cont?.xPAwardFailed ? (
                                          <Dropdown className=" custom-dropdown" style={{ maxWidth: 160 }}>
                                            <Dropdown.Toggle
                                              id="tdDropdownOne"
                                              className={`custom-dropdown-toggle pointer-default`}
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                              onClick={() => {}}
                                            >
                                              <span className={`text-center d-block suspended`}>
                                                <OverlayTrigger
                                                  placement="top"
                                                  overlay={
                                                    <Popover
                                                      id="popoverPannel"
                                                      className="custom-tooltip "
                                                      data-bs-title="Illegitimate"
                                                    >
                                                      <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                                        <h4>{cont?.xPAwardError}</h4>
                                                      </Popover.Body>
                                                    </Popover>
                                                  }
                                                >
                                                  <div
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    data-bs-custom-class="custom-tooltip"
                                                    data-bs-title="Illegitimate"
                                                  >
                                                    Error
                                                  </div>
                                                </OverlayTrigger>
                                              </span>
                                            </Dropdown.Toggle>
                                          </Dropdown>
                                        ) : (
                                          <Dropdown className=" custom-dropdown " style={{ maxWidth: 160 }}>
                                            <Dropdown.Toggle
                                              id="tdDropdownOne"
                                              className="custom-dropdown-toggle"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ contest: cont, amountType: 1 }, () => {
                                                  this.toggleGameFlyer(e);
                                                });
                                              }}
                                            >
                                              <span
                                                className={`w-100 text-center d-block pointer-none ${
                                                  cont?.xPAwarded
                                                    ? "active"
                                                    : cont?.xPAwardFailed
                                                    ? "error"
                                                    : "inactive"
                                                } `}
                                              >
                                                {cont?.xPAwarded
                                                  ? "Awarded"
                                                  : cont?.xPAwardFailed
                                                  ? "Error"
                                                  : cont?.isXPAwardingStarted || cont?.isXPDistributionCalculated
                                                  ? "Transfer In Progress"
                                                  : "Pending"}
                                              </span>
                                            </Dropdown.Toggle>
                                          </Dropdown>
                                        )}
                                        {cont?.arcadeCoinsAwardedAt && (
                                          <small className="small-date">
                                            {moment(cont?.xPAwardedAt).format("MM/DD/YYYY hh:mm a")}
                                          </small>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="small-text opacity-5 text-center">NA</div>
                                    )}
                                  </td>
                                  <td className="ust-tag" align="center">
                                    {cont?.entries > 0 ? (
                                      <div className="small-text">
                                        {cont?.ticketAwardFailed ? (
                                          <Dropdown className=" custom-dropdown" style={{ maxWidth: 160 }}>
                                            <Dropdown.Toggle
                                              id="tdDropdownOne"
                                              className={`custom-dropdown-toggle pointer-default`}
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                              onClick={() => {}}
                                            >
                                              <span className={`text-center d-block suspended`}>
                                                <OverlayTrigger
                                                  placement="top"
                                                  overlay={
                                                    <Popover
                                                      id="popoverPannel"
                                                      className="custom-tooltip "
                                                      data-bs-title="Illegitimate"
                                                    >
                                                      <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                                        <h4>{cont?.ticketAwardError}</h4>
                                                      </Popover.Body>
                                                    </Popover>
                                                  }
                                                >
                                                  <div
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    data-bs-custom-class="custom-tooltip"
                                                    data-bs-title="Illegitimate"
                                                  >
                                                    Error
                                                  </div>
                                                </OverlayTrigger>
                                              </span>
                                            </Dropdown.Toggle>
                                          </Dropdown>
                                        ) : (
                                          <Dropdown className=" custom-dropdown" style={{ maxWidth: 160 }}>
                                            <Dropdown.Toggle
                                              id="tdDropdownOne"
                                              className="custom-dropdown-toggle"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ contest: cont, amountType: 3 }, () => {
                                                  this.toggleGameFlyer(e);
                                                });
                                              }}
                                            >
                                              <span
                                                className={`w-100 text-center d-block pointer-none ${
                                                  cont?.ticketsAwarded ? "active" : "inactive"
                                                } `}
                                              >
                                                {cont?.ticketsAwarded
                                                  ? "Awarded"
                                                  : cont?.isTicketAwardingStarted ||
                                                    cont?.isTicketDistributionCalculated
                                                  ? "Transfer In Progress"
                                                  : "Pending"}
                                              </span>
                                            </Dropdown.Toggle>
                                          </Dropdown>
                                        )}
                                        {cont.ticketsAwardedAt && (
                                          <small className="small-date">
                                            {moment(cont.ticketsAwardedAt).format("MM/DD/YYYY hh:mm a")}
                                          </small>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="small-text opacity-5 text-center">NA</div>
                                    )}
                                  </td>
                                  <td>
                                    <div class="small-text">
                                      {moment(cont.endDate).format("MM/DD/YYYY")}
                                      <br /> <small> {moment(cont.endDate).format("hh:mm a")}</small>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
                {this.state.loadingMore && (
                  <div className="w-100 d-flex justify-content-center load-more">
                    <img src={loadingIcon} className="fa-spin" alt="" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>{" "}
        <ListingFlyer
          showModal={this.state.showGameList}
          toggleModal={this.toggleGameFlyer}
          contest={this.state.contest}
          amountType={this.state.amountType}
        />{" "}
        {this.state.showGameList && <div className="modal-backdrop show fade" onClick={this.toggleGameFlyer}></div>}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadUsers: (pramas, callback) => dispatch(loadUsers(pramas, callback)),
  loadContestWinners: (params, fresh, callback) => dispatch(loadContestWinners(params, fresh, callback)),
  markAsFeatured: (id, data, callback) => dispatch(markAsFeatured(id, data, callback)),
  markWinnerAsFeaturedReceived: (payLoad) => dispatch(markWinnerAsFeaturedReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getContest: getContest(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserListing));
