import React, { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loadSliders, getSettings, deleteSlider, deleteSliderReceived } from "store/settings";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import _ from "lodash";

//  Images
import plusIcon from "include/images/plus.svg";
import carrentIcon from "include/images/carrent-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import historyIcon from "include/images/history-icon.svg";
import copyIcon from "include/images/copy.svg";

//  Components
import Form from "common/form/form";
import Header from "common/header";
import SideBar from "common/sidebar";
import Loader from "common/loader";
import DeleteModal from "common/alert/alertModal";
import AlertSuccess from "common/alert/alertSuccess";
import NextButton from "common/form/submitButton";
import SelectSearch from "common/form/selectSearch";
import AlertError from "common/alert/alertError";
import HistoryFlyer from "common/historyFlyer";

class QuestionListing extends Form {
  ref = createRef(null);
  state = {
    loading: false,
    errors: {},
    showDeleteModal: false,
    loadingDelete: false,
    deleteId: "",
    showHistory: false,
    objectId: "",
    object: null,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getSettings.loading && this.props.getSettings.loading !== prevProps.getSettings.loading)
      this.ref.current.complete();

    if (this.props.getSettings.loading && this.props.getSettings.loading !== prevProps.getSettings.loading)
      this.ref.current.continuousStart();
  };

  componentDidMount = () => {
    this.setState({ loading: true });
    this.props.loadSliders(() => {
      this.setState({ loading: false });
    });
  };
  toggleHistoryFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showHistory: !this.state.showHistory });
  };
  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  handleDelete = (e) => {
    if (e) e.preventDefault();
    this.setState({ loadingDelete: true });

    this.props.deleteSlider(this.state.deleteId, (sliderRes) => {
      if (sliderRes.status == 200) {
        toast(<AlertSuccess message="Deleted!" />, {
          containerId: 1,
        });
        this.props.deleteSliderReceived({ id: this.state.deleteId });
        this.alertModalToggle();
        this.setState({ loadingDelete: false });
      } else {
        toast(<AlertError message={sliderRes.data && sliderRes.data.message} />, {
          containerId: 1,
        });
        this.alertModalToggle();
        this.setState({ loadingDelete: false });
      }
    });
  };

  render() {
    const { sliders, totalCount } = this.props.getSettings;
    const domain = window.location.origin;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="customSliders" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Slides {totalCount > 0 && `(${totalCount})`}</h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                {" "}
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState(
                        {
                          objectId: "",
                          object: null,
                        },
                        () => {
                          this.toggleHistoryFlyer();
                        },
                      );
                    }}
                  >
                    <img src={historyIcon} /> <span>History</span>
                  </a>
                </div>
                <div className="fmb-box">
                  <Link to={`/add-slider`} className="btn btn-default btn-sm">
                    <img src={plusIcon} alt="" /> Add a Slide
                  </Link>
                </div>
              </div>
            </div>
            <div className="fwc-wrapper">
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {sliders.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th style={{ width: 75, textAlign: "center" }}>Actions</th>
                            <th>Image</th>
                            <th>Type </th>
                            <th style={{ width: 370 }}>Title</th>
                            <th>Subtitle</th>
                            <th>Contest</th>
                            <th>Display Order </th>
                            <th>Button Text </th>
                            <th>Campaign Link </th>
                          </tr>
                        </thead>
                        <tbody>
                          {sliders.map((slider, i) => (
                            <tr>
                              <td>
                                <Dropdown className=" custom-dropdown">
                                  <Dropdown.Toggle
                                    id="tdDropdownOne"
                                    className="custom-dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img src={carrentIcon} alt="" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.push({
                                          pathname: `/add-slider`,
                                          state: { slider },
                                        });
                                      }}
                                    >
                                      Edit
                                    </Dropdown.Item>{" "}
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        this.setState({ objectId: slider.id, object: slider }, () => {
                                          this.toggleHistoryFlyer(e);
                                        });
                                      }}
                                    >
                                      History
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="dropdown-item delete"
                                      onClick={(e) => {
                                        this.setState({ deleteId: slider.id }, () => this.alertModalToggle(e));
                                      }}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                <div className="td-img-group d-flex align-items-center">
                                  <div className="td-img">
                                    <img src={process.env.REACT_APP_CDN + slider.image.small} alt="" />
                                  </div>
                                </div>
                              </td>
                              <td className="camelCase">{slider.type}</td>
                              <td>
                                <Link
                                  className="two-line-ellipsis"
                                  to={{
                                    pathname: `/add-slider`,
                                    state: { slider },
                                  }}
                                >
                                  {slider.titleLine1}
                                  <br />
                                  <span className="subTitle-line2">{slider.titleLine2}</span>
                                </Link>
                              </td>
                              <td className="camelCase">{slider.subTitle}</td>
                              <td className="camelCase">{slider.contest ? slider.contest.title : "N/A"}</td>
                              <td className="camelCase">{slider.displayOrder}</td>{" "}
                              <td className="camelCase">
                                {slider.buttonText}
                                {(slider.buttonLink || slider.contest) && slider.buttonText && <br />}
                                {(slider.buttonLink || slider.contest) && (
                                  <a
                                    href={
                                      slider.buttonLink
                                        ? slider.buttonLink.includes("http") || slider.buttonLink.includes("https")
                                          ? slider.buttonLink
                                          : `${process.env.REACT_APP_SITE_URL}${slider.buttonLink}`
                                        : `${process.env.REACT_APP_SITE_URL}contest-details/${slider.contest.slug}`
                                    }
                                    className=" orange-text"
                                    target="_blank"
                                  >
                                    Visit Link
                                  </a>
                                )}
                              </td>{" "}
                              <td className="camelCase">
                                {slider?.campaign?.length > 0 ? (
                                  <span
                                    className={`orange-text pointer`}
                                    onClick={(e) => {
                                      if (e) e.preventDefault();
                                      navigator.clipboard.writeText(
                                        `${process.env.REACT_APP_SITE_URL}campaign/${encodeURIComponent(
                                          slider?.campaign,
                                        )}`,
                                      );
                                      toast(<AlertSuccess message="Copied to clipboard" />, { containerId: 1 });
                                    }}
                                  >
                                    Copy Link <img src={copyIcon} alt="copy" />
                                  </span>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>{" "}
        <DeleteModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showDeleteModal}
          title="Alert"
          description="Are you sure you want to delete this item?"
          onPress={this.handleDelete}
          loading={this.state.loadingDelete}
        />{" "}
        <HistoryFlyer
          showModal={this.state.showHistory}
          toggleModal={this.toggleHistoryFlyer}
          moduleName={"customSlide"}
          objectId={this.state.objectId}
          title={this.state.object && this.state.object.titleLine1}
        />
        {this.state.showHistory && <div className="modal-backdrop show fade" onClick={this.toggleHistoryFlyer}></div>}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadSliders: (callback) => dispatch(loadSliders(callback)),
  deleteSlider: (id, callback) => dispatch(deleteSlider(id, callback)),
  deleteSliderReceived: (payLoad) => dispatch(deleteSliderReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionListing));
