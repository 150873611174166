import Joi from "joi-browser";
import _ from "lodash";
import moment from "moment";
import { createRef } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUsers, loadPayments } from "store/users";

//  Images
import filterIcon from "include/images/filter-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";
import loadingIcon from "include/images/loader-grey.svg";

//  Components
import Form from "common/form/form";
import SelectSearch from "common/form/selectSearch";
import NextButton from "common/form/submitButton";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import closeIcon from "include/images/close-icon.svg";

class PaymentsListings extends Form {
  ref = createRef(null);
  lastKeys = [];
  state = {
    data: {
      userName: "",
      paymentType: {},
      status: {
        name: "COMPLETED",
        value: 1,
      },
    },
    sorting: {
      sort: "lastPlayedAt",
      order: "desc",
    },
    showReasonFlyer: false,
    showRoundsFlyer: false,
    gameId: "",
    showFilters: false,
    loading: false,
    loadingMore: false,
    loadingReset: false,
    appliedFilters: {},
    page: 1,
    errors: {},
    reasonType: "",
    showHistory: false,
    objectId: "",
    object: null,
    showRevertModal: false,
    game: null,
    selectedGameIndex: "",
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.complete();

    if (this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.continuousStart();
  };
  componentDidMount = () => {
    this.searchFilters();
  };

  //  Filter Methods
  searchFilters = (e, loadMore) => {
    if (!loadMore) this.setState({ loading: true, loadingFilters: true, page: 1 });

    const data = { ...this.state.data };

    const payLoad = {
      userName: data.userName,
      status: data.status?.value ?? 1,
    };
    if (!_.isEmpty(data.paymentType)) payLoad.paymentType = data.paymentType.value;
    if (this.state.page > 1 && loadMore) payLoad.lastKey = this.lastKeys[this.lastKeys.length - 1];
    var params = _.pickBy(payLoad, function (value, key) {
      return value !== "";
    });

    //  Load the user with username/walletAddress/email filter
    this.props.loadPayments(params, !loadMore, (userRes) => {
      if (userRes.status === 200) {
        this.lastKeys.push(userRes?.data?.lastKey);
        if (userRes?.data?.data?.length > 0) {
          //  Check the eligibility to award the AMOE Tickets on "postmarkedAt"
          this.setState({ loading: false, loadingFilters: false, loadingMore: false });
        } else {
          this.setState({ loading: false, loadingFilters: false, loadingMore: false });
        }

        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).forEach((key) => {
          if (!_.isEmpty(data[key])) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({ appliedFilters, ticketsAwarded: false });
      } else {
        this.setState({ loading: false, loadingFilters: false });
      }
    });
  };
  resetFilters = (e) => {
    e.preventDefault();
    this.setState(
      {
        data: {
          userName: "",
          paymentType: {},
          status: {
            name: "COMPLETED",
            value: 1,
          },
        },
        page: 1,
      },
      () => this.searchFilters(),
    );
  };

  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  schema = {
    userName: Joi.string().allow(""),
    paymentType: Joi.object().allow({}),
    status: Joi.object(),
  };

  render() {
    const { payments, totalCount } = this.props.getUsers;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="payments" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Payments {totalCount > 0 && `(${totalCount})`}</h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showFilters: !this.state.showFilters });
                    }}
                  >
                    <img src={filterIcon} alt="" /> <span>Filters</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="fwc-wrapper">
              {this.state.showFilters && (
                <div className="fwc-head ">
                  <div className="fwc-inner">
                    <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("userName", "Username")}</div>
                      </li>
                      <li className="flex-fill">
                        <div className="fml-box">
                          <label className="form-label">Payment Type</label>
                          <SelectSearch
                            name="paymentType"
                            options={[
                              {
                                name: "ETH",
                                value: 1,
                              },
                              {
                                name: "USD",
                                value: 2,
                              },
                            ]}
                            label=""
                            value={this.state.data.paymentType}
                            error={this.state.errors.paymentType}
                            onChange={this.onChange}
                          />
                        </div>
                      </li>
                      <li className="flex-fill">
                        <div className="fml-box">
                          <label className="form-label">Payment Status</label>
                          <SelectSearch
                            name="status"
                            options={[
                              {
                                name: "PENDING",
                                value: 0,
                              },
                              {
                                name: "COMPLETED",
                                value: 1,
                              },
                              {
                                name: "FAILED",
                                value: 2,
                              },
                            ]}
                            label=""
                            value={this.state.data.status}
                            error={this.state.errors.status}
                            onChange={this.onChange}
                          />
                        </div>
                      </li>
                    </ul>
                    <div className="fwc-btn d-flex align-items-center justify-content-end">
                      <NextButton
                        handleSubmit={this.resetFilters}
                        classData="btn-text pointer"
                        label="Reset"
                        loading={this.state.loadingReset}
                      />
                      <NextButton
                        handleSubmit={this.searchFilters}
                        classData="btn btn-primary btn-sm"
                        label="Search"
                        loading={this.state.loadingFilters}
                      />
                    </div>
                  </div>{" "}
                  {!_.isEmpty(this.state.appliedFilters) && (
                    <div className="filter-tag-container d-flex align-items-center ">
                      <div className="fwc-left">
                        <div className="filter-tag-list">
                          <p>filter applied :</p>

                          {Object.keys(this.state.appliedFilters).map((key) => (
                            <div className="filter-tag-item">
                              <span className="camelCase">{key == "paymentType" ? "Payment Type" : key}</span> :{" "}
                              {key === "userName" && this.state.appliedFilters[key]}
                              {key === "paymentType" && this.state.appliedFilters[key]?.name}
                              {key === "status" && this.state.appliedFilters[key]?.name}
                              {key !== "status" && (
                                <a
                                  href="!#"
                                  onClick={(e) => {
                                    e.preventDefault();

                                    this.setState(
                                      {
                                        data: {
                                          ...this.state.data,
                                          [key]: "",
                                        },
                                      },
                                      this.searchFilters,
                                    );
                                  }}
                                >
                                  <img src={closeIcon} alt="" />
                                </a>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {payments.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Username / Email</th>
                            <th>Payment Type</th>
                            <th>Amount</th>
                            <th width={150}>Coins</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payments?.length > 0 &&
                            payments.map((payment, i) => (
                              <tr>
                                <td>
                                  <span>
                                    <div className="td-img-group d-flex align-items-start ">
                                      <div className="td-img round-img m-right-2">
                                        {payment.image ? (
                                          <img src={process.env.REACT_APP_CDN + payment.image.small} alt="" />
                                        ) : (
                                          <img src={userIcon} alt="" />
                                        )}
                                      </div>

                                      <div>
                                        {payment.userName}
                                        <br />
                                        {payment.email}
                                      </div>
                                    </div>
                                  </span>
                                </td>
                                <td>{payment?.paymentType === 1 ? "ETH" : "USD"}</td>

                                <td>${payment?.package?.price?.toLocaleString("en-us") ?? 0}</td>
                                <td className="ust-tag">
                                  <Dropdown className=" custom-dropdown my-2">
                                    <Dropdown.Toggle
                                      id="tdDropdownOne"
                                      className="custom-dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      disabled
                                    >
                                      <span className={`w-100 text-center d-block arcade`}>
                                        Arcade Coins: {payment?.package?.arcadeCoins?.toLocaleString() ?? 0}
                                      </span>
                                    </Dropdown.Toggle>
                                  </Dropdown>
                                  <Dropdown className=" custom-dropdown my-2">
                                    <Dropdown.Toggle
                                      id="tdDropdownOne"
                                      className="custom-dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      disabled
                                    >
                                      <span className={`w-100 text-center d-block loot`}>
                                        Loot Bucks: {payment?.package?.lootBonusCoins?.toLocaleString("en-us") ?? 0}
                                      </span>
                                    </Dropdown.Toggle>
                                  </Dropdown>
                                </td>
                                <td>{moment(payment.createdAt).format("MM/DD/YYYY hh:mm a")}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
                {!this.state.loading && this.lastKeys?.length > 0 && this.lastKeys[this.lastKeys?.length - 1] && (
                  <div
                    className="text-center"
                    role="button"
                    onClick={(e) => {
                      if (this.state.loadingMore) return;
                      this.setState(
                        {
                          page: this.state.page + 1,
                          loadingMore: true,
                        },
                        () => this.searchFilters(e, true),
                      );
                    }}
                  >
                    {this.state.loadingMore ? (
                      <img src={loadingIcon} className="fa-spin" width={30} alt="" />
                    ) : (
                      "SHOW MORE"
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadPayments: (prams, fresh, callback) => dispatch(loadPayments(prams, fresh, callback)),
});
const mapStateToProps = (state) => ({
  getUsers: getUsers(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentsListings));
