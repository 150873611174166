import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns, MuiPickersUtilsProvider } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import calendarIcon from "include/images/calendar.svg";
import MomentUtils from "@date-io/moment";
import momentTimeZone from "moment-timezone";
import { formatDate } from "../../utils/dateConversion";

class DateInput extends Component {
  ref = React.createRef(null);
  modalRef = React.createRef(null);
  state = {
    formclass: "form-group custom-input show on",
    open: false,
  };
  popperStyle = {
    zIndex: 999999,
  };
  handleFocus = () => {
    this.setState({ formclass: "form-group custom-input show on" });
  };

  handleBlur = (e) => {
    const classname = e.target.value ? "form-group custom-input show on" : "form-group custom-input show on";
    this.setState({ formclass: classname });
  };

  componentDidMount() {
    const classname = "form-group custom-input show on";
    this.setState({ formclass: classname });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      const classname = this.props.value ? "form-group custom-input show on" : "form-group custom-input show on";
      this.setState({ formclass: classname });
    }
  }
  theme = createTheme({
    palette: {
      primary: {
        main: "#1c1d23",
        color: "white",
      },
    },
    zIndex: 999999,
    typography: {
      color: "#1b1b1b",
    },
  });
  render() {
    const { name, label, error, minDate, disabled, minTime, subText, ...rest } = this.props;
    if (this.ref && this.ref.current) {
      this.ref.current.placeholder = "mm/dd/yyyy hh:mm";
    }
    return (
      <>
        <div className="form-group custom-date-form d-flex flex-column">
          <label className="form-label" htmlFor={label}>
            {label}
          </label>
          <LocalizationProvider dateLibInstance={momentTimeZone} dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={this.theme}>
              <DateTimePicker
                inputRef={this.ref}
                renderInput={(props) => (
                  <TextField
                    className={error ? "datepicker error" : "datepicker"}
                    {...props}
                    autoComplete="new-password"
                  />
                )}
                disabled={disabled}
                minDateTime={minDate}
                id={name}
                onFocus={this.handleFocus}
                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                onBlur={this.handleBlur}
                className={error ? "form-control datepicker error" : "form-control datepicker"}
                components={{
                  OpenPickerIcon: () => <img src={calendarIcon} />,
                  SwitchViewIcon: KeyboardArrowDownIcon,
                }}
                PopperProps={{
                  style: this.popperStyle,
                }}
                ref={this.modalRef}
                {...rest}
              />
            </ThemeProvider>
          </LocalizationProvider>
          {subText && (
            <label className="form-label custom-label" htmlFor={label}>
              <span>{label}</span> : {subText} EST
            </label>
          )}
        </div>
      </>
    );
  }
}

export default DateInput;
