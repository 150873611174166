import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "settings",
  initialState: {
    customTexts: [],
    totalCount: null,
    filteredCount: null,
    sliders: [],
    nftCredits: [],
    uploadedCsv: [],
    newsletterSubscribers: [],
    waitList: [],
    logs: [],
    countries: [],
    countryDetails: null,
    winnerTemplates: [],
    loading: false,
    settings: [],
    creditPrices: [],
    howItWorksTemplates: [],
    contestTemplate: [],
    sweepstakeTemplate: [],
    reports: [],
    purgingCache: false,
    purgedAt: null,
    ticketRedemptionRequests: [],
  },
  reducers: {
    // Purge Cache
    purgeCacheRequested: (misc, action) => {
      misc.purgingCache = true;
    },
    purgeCacheReceived: (misc, action) => {
      misc.purgingCache = false;
      misc.purgedAt = Date.now();
    },

    // Failures
    purgeCacheRequestFailed: (misc, action) => {
      misc.purgingCache = false;
    },

    settingsRequested: (misc, action) => {
      misc.loading = true;
    },
    settingsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    addSettingsReceived: (misc, action) => {
      misc.settings = action.payload;
      misc.filteredCount = action.payload.filteredCount;
    },
    customTextsReceived: (misc, action) => {
      misc.customTexts = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    countriesReceived: (misc, action) => {
      misc.countries = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    settingsReceived: (misc, action) => {
      misc.settings = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    countryDetailsReceived: (misc, action) => {
      misc.countryDetails = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    updateStatusReceived: (misc, action) => {
      const payLoad = action.payload;
      misc.countries = misc.countries.map((country, index) => {
        const newCountry = { ...country };
        if (payLoad.countryCode === newCountry.countryCode) {
          newCountry.isAllowed = newCountry.isAllowed ? 0 : 1;
        }
        return newCountry;
      });
    },
    updateStateStatusReceived: (misc, action) => {
      const payLoad = action.payload;
      const country = { ...misc.countryDetails };

      country.states = country.states.map((state, index) => {
        const newState = { ...state };
        if (payLoad.name === newState.name) {
          newState.isAllowed = newState.isAllowed ? 0 : 1;
        }
        return newState;
      });
      misc.countryDetails = country;
    },
    waitListReceived: (misc, action) => {
      misc.waitList = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    logsReceived: (misc, action) => {
      misc.logs = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    waitListDownloadReceived: (misc, action) => {
      misc.waitListDownloadLink = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    deleteWaitListSubscriberReceived: (misc, action) => {
      const ind = misc.waitList.findIndex((subscriber) => subscriber.id === action.payload.id);
      const newWaitList = misc.waitList;
      newWaitList.splice(ind, 1);
      misc.totalCount = misc.totalCount - 1;
      misc.waitList = [...newWaitList];
    },
    slidersReceived: (misc, action) => {
      misc.sliders = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    deleteSliderReceived: (misc, action) => {
      const ind = misc.sliders.findIndex((slide) => slide.id === action.payload.id);
      const newSliders = misc.sliders;
      newSliders.splice(ind, 1);
      misc.totalCount = misc.totalCount - 1;
      misc.sliders = [...newSliders];
    },
    nftCreditsReceived: (misc, action) => {
      misc.nftCredits = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    winnerTemplatesReceived: (misc, action) => {
      misc.winnerTemplates = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    deleteWinnerTemplateReceived: (misc, action) => {
      const ind = misc.winnerTemplates.findIndex((winner) => winner.id === action.payload.id);
      const newWinnerTemplates = misc.winnerTemplates;
      newWinnerTemplates.splice(ind, 1);
      misc.totalCount = misc.totalCount - 1;
      misc.winnerTemplates = [...newWinnerTemplates];
    },
    creditPricesReceived: (misc, action) => {
      misc.creditPrices = action.payload.data?.sort(function (a, b) {
        return new Date(a.price) - new Date(b.price);
      });
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    addCreditPriceReceived: (misc, action) => {
      const newData = misc.creditPrices;
      const payLoad = action.payload;
      misc.creditPrices = payLoad;
    },
    updateCreditPriceReceived: (misc, action) => {
      const payLoad = action.payload;
      misc.creditPrices = payLoad.data;
    },
    deleteCreditPricesReceived: (misc, action) => {
      const ind = misc.creditPrices.findIndex((winner) => winner.id === action.payload.id);
      const newCreditPrices = misc.creditPrices;
      newCreditPrices.splice(ind, 1);
      misc.totalCount = misc.totalCount - 1;
      misc.creditPrices = [...newCreditPrices];
    },
    deleteSubscriberReceived: (misc, action) => {
      const ind = misc.newsletterSubscribers.findIndex((subscriber) => subscriber.id === action.payload.id);
      const newSubscribers = misc.newsletterSubscribers;
      newSubscribers.splice(ind, 1);
      misc.totalCount = misc.totalCount - 1;
      misc.newsletterSubscribers = [...newSubscribers];
    },
    deleteCSVReceived: (misc, action) => {
      const ind = misc.uploadedCsv.findIndex((csv) => csv.id === action.payload.id);
      const newCsv = misc.uploadedCsv;
      newCsv.splice(ind, 1);
      misc.totalCount = misc.totalCount - 1;
      misc.uploadedCsv = [...newCsv];
    },
    updateWinnerTemplateReceived: (misc, action) => {
      const payLoad = action.payload;
      const data = payLoad.data;
      misc.winnerTemplates = misc.winnerTemplates.map((winnerTemplate, index) => {
        const newWinnerTemplates = { ...winnerTemplate };
        if (payLoad.id === newWinnerTemplates.id) {
          newWinnerTemplates.title = data.title;
          newWinnerTemplates.rewards = data.rewards;
        }
        return newWinnerTemplates;
      });
    },
    addWinnerTemplateReceived: (misc, action) => {
      const newWinnerTemplates = misc.winnerTemplates;
      const payLoad = action.payload;
      misc.winnerTemplates = [...newWinnerTemplates, payLoad];
    },
    uploadedCsvRequested: (misc, action) => {
      misc.loading = true;
    },
    uploadedCsvReceived: (misc, action) => {
      misc.loading = false;
      misc.uploadedCsv = action.payload.data.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
    newsletterSubscribersReceived: (misc, action) => {
      misc.newsletterSubscribers = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },

    //How It works Templates
    howItWorksTemplatesReceived: (misc, action) => {
      misc.howItWorksTemplates = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    addHowItWorksTemplateReceived: (misc, action) => {
      misc.howItWorksTemplates = action.payload;
    },
    updateHowItWorksTemplateReceived: (misc, action) => {
      const payLoad = action.payload;
      const data = payLoad.data;
      misc.howItWorksTemplates = misc.howItWorksTemplates.map((howItWorksTemplate, index) => {
        const newHowItWorksTemplates = { ...howItWorksTemplate };
        if (payLoad.id === newHowItWorksTemplates.id) {
          newHowItWorksTemplates.title = data.title;
          newHowItWorksTemplates.rewards = data.rewards;
        }
        return newHowItWorksTemplates;
      });
    },
    deleteHowItWorksTemplateReceived: (misc, action) => {
      const ind = misc.howItWorksTemplates.findIndex((winner) => winner.id === action.payload.id);
      const newHowItWorksTemplates = misc.howItWorksTemplates;
      newHowItWorksTemplates.splice(ind, 1);
      misc.totalCount = misc.totalCount - 1;
      misc.howItWorksTemplates = [...newHowItWorksTemplates];
    },

    //  Contest Template
    deleteContestTemplateReceived: (misc, action) => {
      const ind = misc.contestTemplate.findIndex((quest) => quest.id === action.payload.id);
      const newContests = misc.contestTemplate;
      newContests.splice(ind, 1);
      misc.totalCount = misc.totalCount - 1;
      misc.contestTemplate = [...newContests];
    },
    addContestTemplateReceived: (misc, action) => {
      misc.contestTemplate = action.payload;
    },
    contestTemplateRequested: (misc, action) => {
      misc.loading = true;
    },
    contestTemplateReceived: (misc, action) => {
      misc.contestTemplate = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    contestTemplateRequestFailed: (misc, action) => {
      misc.loading = false;
    },

    //  Sweepstake Template
    deleteSweepstakeTemplateReceived: (misc, action) => {
      const ind = misc.sweepstakeTemplate.findIndex((quest) => quest.id === action.payload.id);
      const newContests = misc.sweepstakeTemplate;
      newContests.splice(ind, 1);
      misc.totalCount = misc.totalCount - 1;
      misc.sweepstakeTemplate = [...newContests];
    },
    addSweepstakeTemplateReceived: (misc, action) => {
      misc.sweepstakeTemplate = action.payload;
    },
    sweepstakeTemplateRequested: (misc, action) => {
      misc.loading = true;
    },
    sweepstakeTemplateReceived: (misc, action) => {
      misc.sweepstakeTemplate = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    sweepstakeTemplateRequestFailed: (misc, action) => {
      misc.loading = false;
    },

    //  Reports
    reportsRequested: (misc, action) => {
      misc.loading = true;
    },
    reportsReceived: (misc, action) => {
      misc.reports = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    reportsRequestFailed: (misc, action) => {
      misc.loading = false;
    },

    //  Reports
    weeklyLeaderboardRequested: (misc, action) => {
      misc.loading = true;
    },
    weeklyLeaderboardReceived: (misc, action) => {
      misc.weeklyLeaderboard = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    weeklyLeaderboardRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    ticketRedemptionRequestsRequested: (misc, action) => {
      misc.loading = true;
    },
    ticketRedemptionRequestsReceived: (misc, action) => {
      misc.ticketRedemptionRequests = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    ticketRedemptionRequestsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    ticketRedemptionStatusReceived: (misc, action) => {
      const payLoad = action.payload;
      misc.ticketRedemptionRequests = misc.ticketRedemptionRequests?.map((request, index) => {
        const newRequest = { ...request };
        if (payLoad?.id === newRequest?._id) {
          newRequest.status = 2;
        }
        return newRequest;
      });
    },
  },
});

export const {
  settingsRequested,
  customTextsReceived,
  settingsRequestFailed,
  slidersReceived,
  nftCreditsReceived,
  newsletterSubscribersReceived,
  uploadedCsvReceived,
  uploadedCsvRequested,
  winnerTemplatesReceived,
  deleteSliderReceived,
  deleteSubscriberReceived,
  deleteCSVReceived,
  deleteWinnerTemplateReceived,
  addWinnerTemplateReceived,
  updateWinnerTemplateReceived,
  waitListReceived,
  deleteWaitListSubscriberReceived,
  logsReceived,
  waitListDownloadReceived,
  countriesReceived,
  updateStatusReceived,
  countryDetailsReceived,
  updateStateStatusReceived,
  settingsReceived,
  addSettingsReceived,
  creditPricesReceived,
  deleteCreditPricesReceived,
  addCreditPriceReceived,
  updateCreditPriceReceived,
  howItWorksTemplatesReceived,
  addHowItWorksTemplateReceived,
  updateHowItWorksTemplateReceived,
  deleteHowItWorksTemplateReceived,
  contestTemplateRequested,
  contestTemplateReceived,
  contestTemplateRequestFailed,
  deleteContestTemplateReceived,
  addContestTemplateReceived,
  deleteSweepstakeTemplateReceived,
  addSweepstakeTemplateReceived,
  sweepstakeTemplateRequested,
  sweepstakeTemplateReceived,
  sweepstakeTemplateRequestFailed,
  reportsRequested,
  reportsReceived,
  reportsRequestFailed,
  weeklyLeaderboardRequested,
  weeklyLeaderboardReceived,
  weeklyLeaderboardRequestFailed,
  purgeCacheRequested,
  purgeCacheReceived,
  purgeCacheRequestFailed,
  ticketRedemptionRequestsRequested,
  ticketRedemptionRequestsReceived,
  ticketRedemptionRequestsRequestFailed,
  ticketRedemptionStatusReceived,
} = slice.actions;
export default slice.reducer;

const contestTemplateUrl = "contestTemplate/";
const sweepstakesTemplateUrl = "sweepstakesTemplate/";

// Purge Cache
export const purgeCache = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "flushStageCache",
      onStart: purgeCacheRequested.type,
      onSuccess: purgeCacheReceived.type,
      onError: purgeCacheRequestFailed.type,
      callback,
    }),
  );
};

// Action Creators
export const loadMessages = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "gameOverCustomText",
      onStart: settingsRequested.type,
      onSuccess: customTextsReceived.type,
      onError: settingsRequestFailed.type,
      callback,
    }),
  );
};
export const loadCountries = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "country",
      params,
      onStart: settingsRequested.type,
      onSuccess: countriesReceived.type,
      onError: settingsRequestFailed.type,
      callback,
    }),
  );
};
export const loadCountryDetails = (code, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "country/" + code,
      onStart: settingsRequested.type,
      onSuccess: countryDetailsReceived.type,
      onError: settingsRequestFailed.type,
      callback,
    }),
  );
};
export const loadLogs = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "adminActionLog",
      onStart: settingsRequested.type,
      onSuccess: logsReceived.type,
      onError: settingsRequestFailed.type,
      params,
      callback,
    }),
  );
};
export const loadSettings = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "setting",
      onStart: settingsRequested.type,
      onSuccess: settingsReceived.type,
      onError: settingsRequestFailed.type,
      callback,
    }),
  );
};
export const addSettings = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "setting",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadUploadedCsv = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "csvUpload",
      onSuccess: uploadedCsvReceived.type,
      onStart: uploadedCsvRequested.type,
    }),
  );
};

export const addCsv = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "csvUpload",
      onStart: uploadedCsvReceived.type,
      onSuccess: settingsRequestFailed.type,
      onError: settingsRequestFailed.type,
      method: "POST",
      data,
      callback,
    }),
  );
};

export const loadFreeUploadedCsv = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "csvUploadFree",
      onSuccess: uploadedCsvReceived.type,
      onStart: uploadedCsvRequested.type,
    }),
  );
};

export const addFreeCsv = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "csvUploadFree",
      onStart: uploadedCsvReceived.type,
      onSuccess: settingsRequestFailed.type,
      onError: settingsRequestFailed.type,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const deleteFreeCsv = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "csvUploadFree/" + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const updateCountryStatus = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "country/status",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateStateStatus = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "country/state/status",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const deleteCsv = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "csvUpload/" + id,
      // onStart: uploadedCsvReceived.type,
      // onSuccess: settingsRequestFailed.type,
      // onError: settingsRequestFailed.type,
      method: "DELETE",
      callback,
    }),
  );
};

export const loadSliders = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "slider",
      onStart: settingsRequested.type,
      onSuccess: slidersReceived.type,
      onError: settingsRequestFailed.type,
      callback,
    }),
  );
};
export const addSlider = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "slider/",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateSlider = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "slider/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};

export const deleteSlider = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "slider/" + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const updateMessage = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "gameOverCustomText/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const addNFTCredit = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "web3/mintNft/",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const uploadImageLog = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "imageUploaderLog",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadNFTCredits = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "web3/mintNft",
      params,
      onStart: settingsRequested.type,
      onSuccess: nftCreditsReceived.type,
      onError: settingsRequestFailed.type,
      callback,
    }),
  );
};

export const loadSubscribers = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "newsletterSubscribers",
      onStart: settingsRequested.type,
      onSuccess: newsletterSubscribersReceived.type,
      onError: settingsRequestFailed.type,
      callback,
    }),
  );
};

export const deleteSubscriber = (email, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "newsletterSubscribers/" + email,
      method: "DELETE",
      callback,
    }),
  );
};
export const loadWaitList = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "userWaitList",
      onStart: settingsRequested.type,
      onSuccess: waitListReceived.type,
      onError: settingsRequestFailed.type,
      callback,
    }),
  );
};
export const loadWaitListDownloadLink = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "userWaitList/download",
      onSuccess: waitListDownloadReceived.type,
      onStart: uploadedCsvRequested.type,
      callback,
    }),
  );
};
export const deleteWaitListSubscriber = (walletAddress, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "userWaitList/" + walletAddress,
      method: "DELETE",
      callback,
    }),
  );
};
export const loadWinnerTemplates = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "qualifierRewardsTemplate",
      onStart: settingsRequested.type,
      onSuccess: winnerTemplatesReceived.type,
      onError: settingsRequestFailed.type,
      callback,
    }),
  );
};
export const addWinnerTemplate = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "qualifierRewardsTemplate",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateWinnerTemplate = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "qualifierRewardsTemplate/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteWinnerTemplate = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "qualifierRewardsTemplate/" + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const loadCreditPrices = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "creditPrice",
      onStart: settingsRequested.type,
      onSuccess: creditPricesReceived.type,
      onError: settingsRequestFailed.type,
      callback,
    }),
  );
};
export const addCreditPrice = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "creditPrice",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateCreditPrices = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "creditPrice/" + id,
      method: "PUT",
      data,
      callback,
      onStart: settingsRequested.type,
      onSuccess: creditPricesReceived.type,
    }),
  );
};
export const deleteCreditPrices = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "creditPrice/" + id,
      method: "DELETE",
      callback,
    }),
  );
};

//  How It Works Templates
export const loadHowItWorksTemplates = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "howItWorksTemplate",
      onStart: settingsRequested.type,
      onSuccess: howItWorksTemplatesReceived.type,
      onError: settingsRequestFailed.type,
      callback,
    }),
  );
};
export const addHowItWorksTemplate = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "howItWorksTemplate",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateHowItWorksTemplate = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "howItWorksTemplate/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteHowItWorksTemplate = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "howItWorksTemplate/" + id,
      method: "DELETE",
      callback,
    }),
  );
};

//  Contest Template
export const loadContestTemplate = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestTemplateUrl,
      params,
      onStart: contestTemplateRequested.type,
      onSuccess: contestTemplateReceived.type,
      onError: contestTemplateRequestFailed.type,
      callback,
    }),
  );
};
export const addContestTemplate = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestTemplateUrl,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateContestTemplate = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestTemplateUrl + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteContestTemplate = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestTemplateUrl + id,
      method: "DELETE",
      callback,
    }),
  );
};

//  Sweepstake Template
export const loadSweepstakeTemplate = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: sweepstakesTemplateUrl,
      params,
      onStart: sweepstakeTemplateRequested.type,
      onSuccess: sweepstakeTemplateReceived.type,
      onError: sweepstakeTemplateRequestFailed.type,
      callback,
    }),
  );
};
export const addSweepstakeTemplate = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: sweepstakesTemplateUrl,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateSweepstakeTemplate = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: sweepstakesTemplateUrl + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteSweepstakeTemplate = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: sweepstakesTemplateUrl + id,
      method: "DELETE",
      callback,
    }),
  );
};

//  Reports
export const loadReports = (params, date, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "report/userTransactions/" + date,
      params,
      onStart: reportsRequested.type,
      onSuccess: reportsReceived.type,
      onError: reportsRequestFailed.type,
      callback,
    }),
  );
};
//  Weekly Leaderboard
export const loadWeeklyLeaderboard = (date, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "leaderboard/weekly/" + date,
      onStart: weeklyLeaderboardRequested.type,
      onSuccess: weeklyLeaderboardReceived.type,
      onError: weeklyLeaderboardRequestFailed.type,
      callback,
    }),
  );
};
export const loadTicketRedemptionRequests = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "userRedeemTickets",
      params,
      onStart: ticketRedemptionRequestsRequested.type,
      onSuccess: ticketRedemptionRequestsReceived.type,
      onError: ticketRedemptionRequestsRequestFailed.type,
      callback,
    }),
  );
};
export const updateTicketRedemptionStatus = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "userRedeemTickets/changeStatus/" + id,
      method: "PUT",
      callback,
    }),
  );
};
export const getSettings = createSelector(
  (state) => state.entities.settings,
  (settings) => settings,
);
