import React, { createRef } from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import { loadAMOEAwards, getUsers } from "store/users";
import moment from "moment/moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

//  Images
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";
import loadingIcon from "include/images/loader-grey.svg";
import closeIcon from "include/images/dark-close-icon.svg";

//  Components
import Loader from "common/loader";
import NextButton from "common/form/submitButton";
import Form from "common/form/form";
import { Link } from "react-router-dom";
import { truncateAddress } from "utils/misc";

class ListingFlyer extends Form {
  ref = createRef(null);
  scrollRef = createRef();
  state = {
    loading: false,
    loadingWinner: false,
    loadingMore: false,
    winner: "",
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({ loading: true });
      const params = { userId: this.props.user?.id };
      this.props.loadAMOEAwards(params, true, (res) => {
        this.setState({ loading: false });
      });
    }
    if (
      this.props.getUsers.amoeAwardsLastKey !== prevProps.getUsers.amoeAwardsLastKey &&
      this.props.getUsers.amoeAwardsLastKey !== null
    ) {
      document.addEventListener("scroll", this.trackScrolling, true);
    }
    if (!this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.complete();

    if (this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.continuousStart();
  };

  trackScrolling = () => {
    if (this.scrollRef.current?.getBoundingClientRect().bottom - 1000 <= window.innerHeight) {
      this.setState({ loadingMore: true });
      const params = {
        userId: this.props.user?.id,
        lastKey: this.props.getUsers.amoeAwardsLastKey,
      };
      this.props.loadAMOEAwards(params, false, () => this.setState({ loadingMore: false }));
      document.removeEventListener("scroll", this.trackScrolling, true);
    }
  };

  render() {
    const { previousAmoeAwards, totalCount } = this.props.getUsers;
    const { user } = this.props;
    return (
      <>
        <Loader loaderRef={this.ref} />
        <Offcanvas
          className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2"}
          show={this.props.showModal}
          onHide={this.props.toggleModal}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header className="offCanvas-header-2">
            <div>
              <h5>Previous AMOE Awards {totalCount > 0 && `(${totalCount})`}</h5>{" "}
              <div className="td-img-group d-flex align-items-start m-top-2">
                <div className="round-img m-right-2">
                  {user.image ? (
                    <img src={process.env.REACT_APP_CDN + user.image.small} alt="" />
                  ) : (
                    <img src={userIcon} alt="" />
                  )}
                </div>
                <span style={{ marginTop: -8 }}>
                  {user.userName}
                  <br />
                  {user.email}
                </span>
              </div>
            </div>
            <div
              className="btn_close pointer"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={this.props.toggleModal}
            >
              <img src={closeIcon} alt="" />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="" ref={this.scrollRef}>
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {previousAmoeAwards.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Postmarked Date</th>
                            <th>Awarded Date</th>
                            <th>Tickets</th>
                          </tr>
                        </thead>
                        <tbody>
                          {previousAmoeAwards?.map((amoe, i) => (
                            <tr>
                              <td className="camelCase">{amoe?.title}</td>
                              <td>
                                <div class="small-text">{moment(amoe?.postmarkedAt).format("MM/DD/YYYY")}</div>
                              </td>{" "}
                              <td>
                                <div class="small-text">
                                  {moment(amoe?.createdAt).format("MM/DD/YYYY")} <br />{" "}
                                  <small> {moment(amoe?.createdAt).format("hh:mm a")}</small>
                                </div>
                              </td>
                              <td>{amoe?.amount?.toLocaleString()}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
            {this.state.loadingMore && (
              <div className="w-100 d-flex justify-content-center load-more">
                <img src={loadingIcon} className="fa-spin" alt="" />
              </div>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadAMOEAwards: (params, fresh, callback) => dispatch(loadAMOEAwards(params, fresh, callback)),
});
const mapStateToProps = (state) => ({
  getUsers: getUsers(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListingFlyer));
