import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Router from "./routes";
import configureStore from "./store/configureStore";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "include/css/bootstrap.min.css";
import "include/css/style.css";
import "include/css/dev.css";
import "include/css/instance.css";

const container = document.getElementById("root");
const root = createRoot(container);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const store = configureStore();
root.render(
  <Provider store={store}>
    <Router />
  </Provider>,
);

reportWebVitals();
