import React from "react";
import closeBtn from "include/images/cross.svg";

const AlertClose = () => {
  return (
    <div className="alert-close" data-bs-dismiss="alert" aria-label="Close">
      <img src={closeBtn} alt="" />
    </div>
  );
};

export default AlertClose;
