import React, { Fragment, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getGame, loadFreeGames, selectedGame } from "store/games";
import { getContest, loadContests } from "store/contest";
import { Popover, OverlayTrigger, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import _ from "lodash";

//  Images
import emptyIcon from "include/images/nrf.svg";
import carrentIcon from "include/images/carrent-icon.svg";
import closeIcon from "include/images/close-icon.svg";
import filterIcon from "include/images/filter-icon.svg";
import userIcon from "include/images/user.png";
import arrowIcon from "include/images/arrow-down-icon.svg";
import arrowIcon1 from "include/images/arrow-down-icon-1.svg";
import historyIcon from "include/images/history-icon.svg";
import arrowIcon2 from "include/images/arrow-down-icon-2.svg";

//  Components
import Form from "common/form/form";
import Header from "common/header";
import NextButton from "common/form/submitButton";
import SideBar from "common/sidebar";
import Loader from "common/loader";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";
import SelectSearchAsync from "common/form/selectSearchAsync";
import contest from "store/contest";
import AlertModal from "common/alert/alertModal";
import ReasonFlyer from "./reasonFlyer";
import moment from "moment";
import { getFilters } from "utils/localStorageServices";
import HistoryFlyer from "common/historyFlyer";
import GameRoundsFlyer from "./roundsFlyer";

class PracticeGamesListing extends Form {
  ref = createRef(null);
  state = {
    data: {
      keyword: "",
      minScore: null,
      maxScore: null,
      usedHint: false,
      usedRemoveOne: false,
      usedPointBoost: false,
      contest: "",
      user: "",
      isIllegitimate: false,
      isRefunded: false,
    },
    sorting: {
      sort: "createdAt",
      order: "desc",
    },
    showReasonFlyer: false,
    showRoundsFlyer: false,
    loadingReason: false,
    gameId: "",
    showFilters: false,
    loading: false,
    loadingReset: false,
    appliedFilters: {},
    page: 1,
    errors: {},
    contestsLoading: false,
    reasonType: "",
    showHistory: false,
    objectId: "",
    object: null,
    showRevertModal: false,
    game: null,
    selectedGameIndex: "",
  };

  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showReasonFlyer: !this.state.showReasonFlyer });
  };
  roundsModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showRoundsFlyer: !this.state.showRoundsFlyer });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getGame.loading && this.props.getGame.loading !== prevProps.getGame.loading)
      this.ref.current.complete();

    if (this.props.getGame.loading && this.props.getGame.loading !== prevProps.getGame.loading)
      this.ref.current.continuousStart();
  };
  clearStates = (callback) => {
    this.setState(
      {
        data: {
          keyword: "",
          minScore: null,
          maxScore: null,
          usedHint: false,
          usedRemoveOne: false,
          usedPointBoost: false,
          contest: "",
          user: "",
          isIllegitimate: false,
          isRefunded: false,
        },
        sorting: {
          sort: "createdAt",
          order: "desc",
        },
        showReasonFlyer: false,
        showRoundsFlyer: false,
        loadingReason: false,
        gameId: "",
        showFilters: false,
        loading: false,
        loadingReset: false,
        appliedFilters: {},
        page: 1,
        errors: {},
        contestsLoading: false,
        reasonType: "",
        showHistory: false,
        objectId: "",
        object: null,
        showRevertModal: false,
        game: null,
        selectedGameIndex: "",
      },
      callback,
    );
  };
  sort = (e, type) => {
    const data = { ...this.state.sorting };
    if (type === 1) {
      if (data.sort === "totalScore" && data.order === "desc") {
        data.sort = "totalScore";
        data.order = "asc";
      } else {
        data.sort = "totalScore";
        data.order = "desc";
      }
    } else if (type === 2) {
      if (data.sort === "createdAt" && data.order === "desc") {
        data.sort = "createdAt";
        data.order = "asc";
      } else {
        data.sort = "createdAt";
        data.order = "desc";
      }
    } else if (type === 3) {
      if (data.sort === "updatedAt" && data.order === "desc") {
        data.sort = "updatedAt";
        data.order = "asc";
      } else {
        data.sort = "updatedAt";
        data.order = "desc";
      }
    }
    this.setState({ sorting: data }, () => {
      this.searchFilters();
    });
  };
  componentDidMount = () => {
    this.setState({ loading: true });
    const filters = getFilters(`free-games`);
    const { data, sorting } = filters || {};
    if (data && sorting) {
      this.setState({ data, sorting }, () => {
        this.searchFilters();
      });
    } else {
      this.searchFilters();
    }
    // this.loadContestsOptions();
  };
  // loadContestsOptions = (keyword) => {
  //   this.setState({ contestsLoading: true });
  //   this.props.loadContests("all", { keyword }, () => {
  //     this.setState({ contestsLoading: false });
  //   });
  // };
  schema = {
    keyword: Joi.string().allow(""),
    user: Joi.string().allow(""),
    minScore: Joi.number().allow(["", null]),
    maxScore: Joi.number().allow(["", null]),
    usedHint: Joi.boolean().allow(false),
    usedPointBoost: Joi.boolean().allow(false),
    usedRemoveOne: Joi.boolean().allow(false),
  };
  toggleHistoryFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showHistory: !this.state.showHistory });
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  searchFilters = (e) => {
    if (e) e.preventDefault();
    const locationParams = new URLSearchParams(this.props.location.search);
    const amountType = locationParams.get("amountType") ?? this.state.data.amountType;
    this.setState({ loadingFilters: true });
    const errors = { ...this.state.errors };
    const {
      keyword,
      maxScore,
      minScore,
      usedHint,
      usedPointBoost,
      usedRemoveOne,
      contest,
      isIllegitimate,
      isRefunded,
      user,
    } = this.state.data;

    if ((parseInt(maxScore) || parseInt(maxScore) === 0) && (parseInt(minScore) || parseInt(minScore) === 0)) {
      if (parseInt(maxScore) <= parseInt(minScore)) {
        errors.maxScore = "Max Score should be greater than Min Score";
        return this.setState({ errors, loadingFilters: false });
      }
    }
    const payLoad = {
      keyword,
      maxScore: parseInt(maxScore),
      minScore: parseInt(minScore),
      sort: this.state.sorting.sort,
      order: this.state.sorting.order,
      page: this.state.page,
      user,
    };
    var params = _.pickBy(payLoad, function (value, key) {
      return value != {} && value !== "" && value;
    });
    params.page = this.state.page;
    this.props.loadFreeGames(params, (gamesResponse) => {
      this.setState({
        loadingFilters: false,
        loading: false,
      });
      if (gamesResponse.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).map((key) => {
          if (!_.isEmpty(data[key]) || data[key]) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({ appliedFilters });
      }
    });
  };
  resetFilters = (e) => {
    e.preventDefault();
    this.setState(
      {
        data: {
          ...this.state.data,
          keyword: "",
          minScore: "",
          maxScore: "",
          usedHint: false,
          usedRemoveOne: false,
          usedPointBoost: false,
          isIllegitimate: false,
          isRefunded: false,
          contest: "",
        },
      },
      () => this.searchFilters(),
    );
  };
  removeFilter = (e, type) => {
    const data = this.state.data;
    if (type === "usedHint" || type === "usedRemoveOne" || type === "usedPointBoost") {
      data[type] = false;
    } else if (type === "keyword") {
      data[type] = "";
    } else {
      data[type] = null;
    }
    this.setState({ data }, () => this.searchFilters());
  };

  render() {
    const { freeGames, totalCount } = this.props.getGame;
    const locationParams = new URLSearchParams(this.props.location.search);
    const amountType = locationParams.get("amountType") ?? this.state.data.amountType;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="games" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Practice Games {totalCount > 0 && `(${totalCount})`}</h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                {" "}
                {/* <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState(
                        {
                          objectId: "",
                          object: null,
                        },
                        () => {
                          this.toggleHistoryFlyer();
                        },
                      );
                    }}
                  >
                    <img src={historyIcon} alt="" /> <span>History</span>
                  </a>
                </div> */}
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showFilters: !this.state.showFilters });
                    }}
                  >
                    <img src={filterIcon} alt="" /> <span>Filters</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="fwc-wrapper">
              {this.state.showFilters && (
                <div className="fwc-head ">
                  <div className="fwc-inner">
                    <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("keyword", "Keyword")}</div>
                      </li>{" "}
                      <li className="flex-fill">
                        <div className="fml-box">
                          <label className="form-label">Score</label>
                          <div className="fml-input-group d-flex align-items-center">
                            <div className="fml-input-group-item">
                              {this.renderInput("minScore", "", "Min", "number")}
                            </div>
                            <div className="fml-input-group-item">
                              {this.renderInput("maxScore", "", "Max", "number")}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="fwc-btn d-flex align-items-center justify-content-end">
                      <NextButton
                        handleSubmit={this.resetFilters}
                        classData="btn-text pointer"
                        label="Reset"
                        loading={this.state.loadingReset}
                      />
                      <NextButton
                        handleSubmit={this.searchFilters}
                        classData="btn btn-primary btn-sm"
                        label="Search"
                        loading={this.state.loadingFilters}
                      />
                    </div>
                  </div>{" "}
                  {!_.isEmpty(this.state.appliedFilters) && (
                    <div className="filter-tag-container d-flex align-items-center ">
                      <div className="fwc-left">
                        <div className="filter-tag-list">
                          <p>filter applied :</p>

                          {Object.keys(this.state.appliedFilters).map((key) => (
                            <div className="filter-tag-item">
                              <span className="camelCase">
                                {key === "minScore"
                                  ? "Min Score"
                                  : key === "maxScore"
                                  ? "Max Score"
                                  : key === "usedHint"
                                  ? "Hint"
                                  : key === "isIllegitimate"
                                  ? "Illegitimate"
                                  : key === "isRefunded"
                                  ? "Refunded"
                                  : key}
                              </span>{" "}
                              :{" "}
                              {(key === "maxScore" || key === "minScore" || key === "keyword") &&
                                this.state.appliedFilters[key]}
                              {key === "contest" && this.state.appliedFilters[key]?.name}
                              {(key === "isRefunded" || key === "isIllegitimate") &&
                                (this.state.appliedFilters[key] ? "Yes" : "No")}
                              <a
                                href="!#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (key === "maxScore" || key === "minScore") {
                                    this.setState(
                                      {
                                        data: {
                                          ...this.state.data,
                                          [key]: "",
                                        },
                                      },
                                      this.searchFilters,
                                    );
                                  } else if (key === "isIllegitimate" || key === "isRefunded") {
                                    this.setState(
                                      {
                                        data: {
                                          ...this.state.data,
                                          [key]: false,
                                        },
                                      },
                                      this.searchFilters,
                                    );
                                  } else {
                                    this.setState(
                                      {
                                        data: {
                                          ...this.state.data,
                                          [key]: "",
                                        },
                                      },
                                      this.searchFilters,
                                    );
                                  }
                                }}
                              >
                                <img src={closeIcon} alt="" />
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {freeGames.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Player</th>
                            <th>BG Game</th>
                            <th>
                              Score{" "}
                              <a
                                href="#!"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.sort(e, 1);
                                }}
                              >
                                <img
                                  src={
                                    this.state.sorting.sort === "totalScore" && this.state.sorting.order === "desc"
                                      ? arrowIcon1
                                      : this.state.sorting.sort === "totalScore" && this.state.sorting.order === "asc"
                                      ? arrowIcon2
                                      : arrowIcon
                                  }
                                  alt=""
                                />
                              </a>
                            </th>
                            <th>
                              Played On
                              <a
                                href="#!"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.sort(e, 2);
                                }}
                              >
                                <img
                                  src={
                                    this.state.sorting.sort === "createdAt" && this.state.sorting.order === "desc"
                                      ? arrowIcon1
                                      : this.state.sorting.sort === "createdAt" && this.state.sorting.order === "asc"
                                      ? arrowIcon2
                                      : arrowIcon
                                  }
                                  alt=""
                                />
                              </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {freeGames &&
                            freeGames.length > 0 &&
                            freeGames.map((game, index) => {
                              const allGames = game?.games;
                              const activeGames = allGames?.filter(
                                (round) => !round.isRefunded && !round.isIllegitimate,
                              );
                              const refundAmount = allGames?.filter((item) => item.refund);
                              return (
                                <tr>
                                  <td className="td-img-group d-flex align-items-start">
                                    <div className="td-img round-img m-right-2">
                                      {game.user && game.user.image ? (
                                        <img
                                          src={
                                            process.env.REACT_APP_CDN +
                                            (game.user.image.small || game.user.image.original)
                                          }
                                          alt=""
                                        />
                                      ) : (
                                        <img src={userIcon} alt="" />
                                      )}
                                    </div>
                                    {game.user ? (
                                      <div className="">
                                        {game.user.userName}
                                        <br />
                                        {game.user.email}
                                      </div>
                                    ) : (
                                      <Link
                                        to={{
                                          pathname: `/game-details/${game.gameId}`,
                                          state: { game },
                                        }}
                                        className="orange-text"
                                        target="_blank"
                                      >
                                        {game.userId}
                                      </Link>
                                    )}
                                  </td>
                                  <td>{game?.bgGameName}</td>
                                  <td>
                                    <div className={`td-text-box`}>
                                      {game.totalScore?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </div>
                                  </td>{" "}
                                  <td>
                                    <div class="small-text">
                                      {moment(game.createdAt).format("MM/DD/YYYY")}
                                      <br /> <small> {moment(game.createdAt).format("hh:mm a")}</small>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    )}{" "}
                    {!this.state.loading && !(this.state.page === 1 && freeGames.length === 0) && (
                      <div className="page-pagination">
                        <ul className="pagination  justify-content-end">
                          <li className="page-item">
                            <a
                              class={`page-link ${this.state.page === 1 && "disabled"}`}
                              href="!#"
                              aria-label="Previous"
                              onClick={(e) => {
                                e.preventDefault();
                                if (this.state.page > 1) {
                                  this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                                }
                              }}
                            >
                              <svg
                                width="8"
                                height="14"
                                viewBox="0 0 8 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </a>
                          </li>
                          <li className="page-item active">
                            <a
                              className="page-link"
                              href="!#"
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              {this.state.page}
                            </a>
                          </li>
                          {freeGames.length >= 50 && (
                            <li className="page-item">
                              <a
                                className="page-link"
                                href="!#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                                }}
                              >
                                {this.state.page + 1}
                              </a>
                            </li>
                          )}
                          <li className="page-item">
                            <a
                              class={`page-link ${freeGames.length < 50 && "disabled"}`}
                              href="!#"
                              aria-label="Next"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                              }}
                            >
                              <svg
                                width="8"
                                height="14"
                                viewBox="0 0 8 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>{" "}
        {this.state.showReasonFlyer && <div className="modal-backdrop show fade" onClick={this.alertModalToggle}></div>}
        <HistoryFlyer
          showModal={this.state.showHistory}
          toggleModal={this.toggleHistoryFlyer}
          moduleName={"game"}
          objectId={this.state.objectId}
          title={this.state.object && this.state.object.contest && this.state.object.contest.title}
        />
        <GameRoundsFlyer
          showModal={this.state.showRoundsFlyer}
          toggleModal={this.roundsModalToggle}
          contest={this.props.getGame?.selectedGame?.contest}
          player={this.props.getGame?.selectedGame?.user}
          rounds={this.props.getGame?.selectedGame?.freeGames}
          id={this.props.getGame?.selectedGame?.id}
          allGame={this.props.getGame?.selectedGame}
          selectedGameIndex={this.state.selectedGameIndex}
          amountType={amountType}
        />
        {(this.state.showHistory || this.state.showRoundsFlyer) && (
          <div
            className="modal-backdrop z-index-1000 show fade"
            onClick={this.state.showHistory ? this.toggleHistoryFlyer : this.roundsModalToggle}
          ></div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadFreeGames: (params, callback) => dispatch(loadFreeGames(params, callback)),
  loadContests: (status, params, callback) => dispatch(loadContests(status, params, callback)),
  selectedGame: (payLoad) => dispatch(selectedGame(payLoad)),
});
const mapStateToProps = (state) => ({
  getGame: getGame(state),
  getContest: getContest(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PracticeGamesListing));
