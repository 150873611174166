import React, { useState } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getSweepstake, requestRandomNumber, selectInstanceWinner, transferInstancePrize } from "store/sweepstakes";
import { connect } from "react-redux";
import loadingIcon from "include/images/loading-icon-grey.svg";
import { SWEEP_INSTANCES_TEXTS } from "utils/constants";
import { useEffect } from "react";
import { toast } from "react-toastify";
import AlertError from "common/alert/alertError";

const SelectWinnerButton = ({
  instance,
  requestRandomNumber,
  transferInstancePrize,
  selectInstanceWinner,
  getSweepstake,
  status,
  setStatusTag,
  loadInstanceInfo,
}) => {
  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  useEffect(() => {
    if (
      (instance?.randomNumberRequestId && status === 4) ||
      status === 5 ||
      (!("winningTicketNumber" in instance) && status === 6) ||
      (instance?.winnerDocumentsRequired && !instance?.winnerDocumentsReceived && status === 7) ||
      (instance?.winnerPrizeTransferredAt && status === 7)
    ) {
      setDisableBtn(true);
      setStatusTag(true);
    } else {
      setDisableBtn(false);
    }
  }, [status]);

  const handleRequestRandomNumber = (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    requestRandomNumber(instance?.id, (res) => {
      setLoading(false);
      setStatusTag(true);
      setDisableBtn(true);
    });
  };
  const handleSelectInstanceWinner = (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    selectInstanceWinner(instance?.id, (res) => {
      loadInstanceInfo(() => {
        setLoading(false);
      });
    });
  };
  const handleTransferInstancePrize = (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    transferInstancePrize(instance?.id, (res) => {
      if (res.status === 200) {
        setDisableBtn(true);
        setStatusTag(true);
      } else {
        toast(<AlertError message={res.data && res.data.message} />, {
          containerId: 1,
        });
      }
      setLoading(false);
    });
  };

  return (
    <>
      <OverlayTrigger
        placement="auto"
        overlay={
          !disableBtn ? (
            <></>
          ) : (
            <Popover id="popoverPannel" key="instance-btn" className="custom-tooltip " data-bs-title="requestbtn">
              <Popover.Body className="tooltip-inner tooltip-inner-reason">
                <h4>
                  {
                    SWEEP_INSTANCES_TEXTS[
                      instance?.winnerDocumentsRequired && !instance?.winnerDocumentsReceived && status === 7
                        ? 10
                        : status
                    ].tooltipHead
                  }
                </h4>
                <p>
                  {
                    SWEEP_INSTANCES_TEXTS[
                      instance?.winnerDocumentsRequired && !instance?.winnerDocumentsReceived && status === 7
                        ? 10
                        : status
                    ].tooltipText
                  }
                </p>
              </Popover.Body>
            </Popover>
          )
        }
      >
        <div>
          <Link
            to="#!"
            class={`btn btn-outline ${(disableBtn || loading) && "disabled"} `}
            onClick={
              status === 9
                ? handleRequestRandomNumber
                : status === 6
                ? handleSelectInstanceWinner
                : status === 7
                ? handleTransferInstancePrize
                : (e) => {
                    e.preventDefault();
                  }
            }
          >
            {loading ? (
              <img src={loadingIcon} className="fa-spin" alt="loading..." />
            ) : (
              SWEEP_INSTANCES_TEXTS[status].buttonText
            )}
          </Link>
        </div>
      </OverlayTrigger>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  requestRandomNumber: (id, callback) => dispatch(requestRandomNumber(id, callback)),
  selectInstanceWinner: (id, callback) => dispatch(selectInstanceWinner(id, callback)),
  transferInstancePrize: (id, callback) => dispatch(transferInstancePrize(id, callback)),
});
const mapStateToProps = (state) => ({
  getSweepstake: getSweepstake(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(SelectWinnerButton);
