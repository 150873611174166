import React, { createRef } from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import { getUsers, loadUserNotification } from "store/users";
import moment from "moment/moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

//  Images
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";
import closeIcon from "include/images/dark-close-icon.svg";

//  Components
import Loader from "common/loader";
import NextButton from "common/form/submitButton";
import Form from "common/form/form";
import { Link } from "react-router-dom";

class ListingFlyer extends Form {
  ref = createRef(null);
  state = {
    loading: false,
    loadingWinner: false,
    winner: "",
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({ loading: true });
      this.props.loadUserNotification(this.props.user.id, () => {
        this.setState({ loading: false });
      });
    }
    if (!this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.complete();

    if (this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.continuousStart();
  };
  render() {
    const { userNotification } = this.props.getUsers;
    const { user } = this.props;
    return (
      <>
        <Loader loaderRef={this.ref} />
        <Offcanvas
          className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2"}
          show={this.props.showModal}
          onHide={this.props.toggleModal}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header className="offCanvas-header-2">
            <div>
              <h5>User Notifications</h5>
              <div className="td-img-group d-flex align-items-start m-top-2">
                {user.userName}
                <br />
                {user.email}
              </div>
            </div>
            <div
              className="btn_close pointer"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={this.props.toggleModal}
            >
              <img src={closeIcon} alt="" />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {" "}
            <div className="">
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {userNotification.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Notification</th>
                            <th>Credits</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userNotification &&
                            userNotification.length > 0 &&
                            userNotification.map((notification, i) => (
                              <tr>
                                <td>
                                  <div class="small-text">
                                    {notification.title}
                                    <br /> <small> {notification.subtitle}</small>
                                  </div>
                                </td>{" "}
                                <td>
                                  <div className="td-text-box">
                                    {notification?.amount ?? notification?.additionalData?.coinQuantity ?? ""}{" "}
                                    {notification?.amountType == 1
                                      ? "ARCADE COINS"
                                      : notification?.amountType == 3
                                      ? "LOOT BUCKS"
                                      : notification?.amountType == 2
                                      ? "TICKETS"
                                      : notification?.additionalData?.currency ?? ""}
                                  </div>
                                </td>{" "}
                                <td>
                                  <div className="td-text-box">
                                    <div class="small-text">
                                      {moment(notification?.createdAt).format("MM/DD/YYYY")}
                                      <br /> <small> {moment(notification?.createdAt).format("hh:mm a")}</small>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadUserNotification: (id, callback) => dispatch(loadUserNotification(id, callback)),
});
const mapStateToProps = (state) => ({
  getUsers: getUsers(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListingFlyer));
