import closeIcon from "include/images/dark-close-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";
import { Offcanvas } from "react-bootstrap";

const UsersFlyer = ({ user, referredUsers, showModal, toggleModal }) => {
  return (
    <Offcanvas
      className={"offcanvas offcanvas-end custom-offcanvas-pannel"}
      show={showModal}
      onHide={toggleModal}
      id="rewardPannel"
      placement="right"
      name="rewardPannel"
      backdrop={true}
    >
      <Offcanvas.Header className="offCanvas-header-2">
        <div>
          <h5>Referred Users</h5>
          <div className="td-img-group d-flex align-items-start m-top-2">
            <div className="round-img m-right-2">
              {user?.image ? (
                <img src={process.env.REACT_APP_CDN + user?.image?.small} alt="" />
              ) : (
                <img src={userIcon} alt="" />
              )}
            </div>
            <span>
              {user?.userName}
              <br />
              {user?.email}
            </span>
          </div>
        </div>
        <div className="btn_close pointer" data-bs-dismiss="offcanvas" aria-label="Close" onClick={toggleModal}>
          <img src={closeIcon} alt="" />
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="fwc-body">
          <div className="table-responsive">
            {referredUsers?.length === 0 ? (
              <div className="fwc-body">
                <div className="no-record-found-container">
                  <div className="nfr-box">
                    <img src={emptyIcon} alt="No Record Found" width={202} height={146} />
                    <div className="nrf-text">
                      <h5>No Records Found!</h5>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <table className="table large-table">
                <thead>
                  <tr>
                    <th>Username</th>
                  </tr>
                </thead>
                <tbody>
                  {referredUsers?.map((user, index) => (
                    <tr key={user?._id}>
                      <td className="d-flex">
                        <div className="td-img round-img m-right-2">
                          <img src={user?.image ? process.env.REACT_APP_CDN + user?.image?.small : userIcon} alt="" />
                        </div>
                        {user?.userName}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default UsersFlyer;
