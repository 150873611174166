import closeIcon from "include/images/dark-close-icon.svg";
import loadingIcon from "include/images/loader-grey.svg";
import emptyIcon from "include/images/nrf.svg";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import userIcon from "include/images/user.png";
import moment from "moment/moment";

const DepositsFlyer = ({ user, referralId, deposits, loadMore, depositsTotalCount, showModal, toggleModal }) => {
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [scrollRef, setScrollRef] = useState(null);
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (referralId) {
      setPage(1);
      setLoading(true);
      loadMore(referralId, {}, true, () => setLoading(false));
    }
  }, [referralId, loadMore]);

  const handleLoadMore = useCallback(
    (entries) => {
      const target = entries[0];
      if (target?.isIntersecting) {
        if (!loadingMore && deposits?.length < depositsTotalCount) {
          setLoadingMore(true);
          const params = { page: page + 1 };
          setPage(page + 1);
          loadMore(referralId, params, false, () => setLoadingMore(false));
        }
      }
    },
    [referralId, loadingMore, deposits?.length, depositsTotalCount, page, loadMore],
  );
  useEffect(() => {
    const options = {
      threshold: 0.2,
    };
    // Observer API
    const observer = new IntersectionObserver(handleLoadMore, options);

    if (observer && scrollRef) {
      observer.observe(scrollRef);
    }
    return () => {
      observer.disconnect();
    };
  }, [handleLoadMore, scrollRef]);
  return (
    <Offcanvas
      className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2"}
      show={showModal}
      onHide={toggleModal}
      id="rewardPannel"
      placement="right"
      name="rewardPannel"
      backdrop={true}
    >
      <Offcanvas.Header className="offCanvas-header-2">
        <div>
          <h5>Deposits</h5>
          <div className="td-img-group d-flex align-items-start m-top-2">
            <div className="round-img m-right-2">
              {user?.image ? (
                <img src={process.env.REACT_APP_CDN + user?.image?.small} alt="" />
              ) : (
                <img src={userIcon} alt="" />
              )}
            </div>
            <span>
              {user?.userName}
              <br />
              {user?.email}
            </span>
          </div>
        </div>
        <div className="btn_close pointer" data-bs-dismiss="offcanvas" aria-label="Close" onClick={toggleModal}>
          <img src={closeIcon} alt="" />
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="fwc-body">
          {!loading && (
            <div className="table-responsive">
              {deposits?.length === 0 ? (
                <div className="fwc-body">
                  <div className="no-record-found-container">
                    <div className="nfr-box">
                      <img src={emptyIcon} alt="No Record Found" />
                      <div className="nrf-text">
                        <h5>No Records Found!</h5>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <table className="table large-table">
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Deposit Amount</th>
                      <th>Deposit Date</th>
                      <th>Reward Amount</th>
                      <th>Reward Claimed Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deposits?.map((deposit, index) => (
                      <tr
                        ref={(ref) => {
                          index === deposits.length - 1 && deposits.length < depositsTotalCount && setScrollRef(ref);
                        }}
                      >
                        <td className="d-flex">
                          <div className="td-img round-img m-right-2">
                            <img
                              src={
                                deposit?.referredUser?.image
                                  ? process.env.REACT_APP_CDN + deposit?.referredUser?.image?.small
                                  : userIcon
                              }
                              alt=""
                            />
                          </div>
                          {deposit?.referredUser?.userName}
                        </td>
                        <td>
                          {deposit?.depositAmount?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) ?? 0}
                        </td>
                        <td>
                          <div class="small-text">
                            {moment(deposit?.depositedAt).format("MM/DD/YYYY")}
                            <br /> <small> {moment(deposit?.depositedAt).format("hh:mm a")}</small>
                          </div>
                        </td>
                        <td>
                          {deposit?.rewardAmount?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) ?? 0}
                        </td>
                        <td>
                          <div class="small-text">
                            {deposit.rewardClaimedAt ? (
                              <>
                                {moment(deposit?.rewardClaimedAt).format("MM/DD/YYYY")}
                                <br /> <small> {moment(deposit.rewardClaimedAt).format("hh:mm a")}</small>
                              </>
                            ) : (
                              "Not claimed"
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>
        {loadingMore && (
          <div className="w-100 d-flex justify-content-center load-more">
            <img src={loadingIcon} className="fa-spin" alt="" />
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default DepositsFlyer;
