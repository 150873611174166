import axios from "axios";

export const fetchNFT = (tokenId, address, callback) => {
  axios({
    method: "get",
    url: `https://api.opensea.io/api/v1/asset/${address}/${tokenId}?include_orders=false`,
    headers: { "X-API-KEY": process.env.REACT_APP_OPEN_SEA_API_KEY },
  })
    .then((nftResponse) => {
      callback(nftResponse);
    })
    .catch((err) => {
      callback(err.response.data);
    });
};
export const fetchTestNFT = (chain, tokenId, address, callback) => {
  axios({
    method: "get",
    url: `https://testnets-api.opensea.io/v2/chain/${chain}/contract/${address}/nfts/${tokenId}`,
  })
    .then((nftResponse) => {
      console.log("response:1");
      callback(nftResponse);
    })
    .catch((err) => {
      console.log("response:2");
      callback(err.response.data);
    });
};
export const ethToUSD = (callback) => {
  axios({
    method: "get",
    url: `https://api.coinbase.com/v2/exchange-rates?currency=ETH`,
  }).then((ethRes) => {
    callback(ethRes.data.data);
  });
};
