import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "telegram",
  initialState: {
    telegramSpinReward: [],
    telegramUsdtWithdrawals: [],
    telegramUsers: [],
    telegramSetting: [],
    telegramUserTransactions: [],
    telegramChallenges: [],
    verisoulMultipleTelegramAccounts: [],
    verisoulTelegramAuthResults: [],
    loading: false,
    loadingChallenges: false,
    verisoulTelegramAuthResultsLoading: false,
    verisoulMultipleTelegramAccountsLoading: false,
    totalCount: 0,
    lastFetch: null,
    totalChallengesCount: 0,
    verisoulMultTelegramAccFilteredCount: 0,
    verisoulMultTelegramAccTotalCount: 0,
    telegramUserTransactionsTotalCount: 0,
    verisoulTelegramAuthTotalCount: 0,
    verisoulTelegramAuthFilteredCount: 0,
  },
  reducers: {
    telegramSpinRewardRequested: (misc, action) => {
      misc.loading = true;
    },
    telegramSpinRewardReceived: (misc, action) => {
      misc.telegramSpinReward = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    telegramSpinRewardRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    telegramUsdtWithdrawalsRequested: (misc, action) => {
      misc.loading = true;
    },
    telegramUsdtWithdrawalsReceived: (misc, action) => {
      misc.telegramUsdtWithdrawals = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    telegramUsdtWithdrawalsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    telegramUsdtStatusReceived: (misc, action) => {
      const payLoad = action.payload;
      misc.telegramUsdtWithdrawals = misc.telegramUsdtWithdrawals?.map((request, index) => {
        const newRequest = { ...request };
        if (payLoad?.id === newRequest?._id) {
          newRequest.status = 2;
        }
        return newRequest;
      });
    },
    telegramSettingRequested: (misc, action) => {
      misc.loading = true;
    },
    telegramSettingReceived: (misc, action) => {
      misc.telegramSetting = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    telegramSettingRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    telegramUsersRequested: (misc, action) => {
      misc.loading = true;
    },
    telegramUsersReceived: (misc, action) => {
      misc.telegramUsers = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    telegramUsersRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    telegramUserTransactionsRequested: (misc, action) => {
      misc.loading = true;
    },
    telegramUserTransactionsReceived: (misc, action) => {
      misc.telegramUserTransactions = action.payload.data;
      misc.telegramUserTransactionsTotalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    moreTelegramUserTransactionsReceived: (misc, action) => {
      misc.telegramUserTransactions = [...misc.telegramUserTransactions, ...action.payload.data];
      misc.telegramUserTransactionsTotalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    telegramUserTransactionsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    telegramChallengesRequested: (misc, action) => {
      misc.loadingChallenges = true;
    },
    telegramChallengesReceived: (misc, action) => {
      misc.telegramChallenges = action.payload.data;
      misc.totalChallengesCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loadingChallenges = false;
    },
    telegramChallengesRequestFailed: (misc, action) => {
      misc.loadingChallenges = false;
    },
    verisoulMultipleTelegramAccountsRequested: (misc, action) => {
      misc.verisoulMultipleTelegramAccountsLoading = true;
    },
    verisoulMultipleTelegramAccountsReceived: (misc, action) => {
      misc.verisoulMultipleTelegramAccounts = action.payload.data;
      misc.verisoulMultTelegramAccFilteredCount = action.payload.filteredCount;
      misc.verisoulMultTelegramAccTotalCount = action.payload.totalCount;
      misc.verisoulMultipleTelegramAccountsLoading = false;
    },
    verisoulMultipleTelegramAccountsRequestFailed: (misc, action) => {
      misc.verisoulMultipleTelegramAccountsLoading = false;
    },
    verisoulTelegramAuthResultsRequested: (misc, action) => {
      misc.verisoulTelegramAuthResultsLoading = true;
    },
    verisoulTelegramAuthResultsReceived: (misc, action) => {
      misc.verisoulTelegramAuthResults = action.payload.data;
      misc.verisoulTelegramAuthFilteredCount = action.payload.filteredCount;
      misc.verisoulTelegramAuthTotalCount = action.payload.totalCount;
      misc.verisoulTelegramAuthResultsLoading = false;
    },
    verisoulTelegramAuthResultsRequestFailed: (misc, action) => {
      misc.verisoulTelegramAuthResultsLoading = false;
    },
  },
});

export const {
  telegramSpinRewardRequested,
  telegramSpinRewardReceived,
  telegramSpinRewardRequestFailed,
  telegramUsdtWithdrawalsReceived,
  telegramUsdtWithdrawalsRequestFailed,
  telegramUsdtWithdrawalsRequested,
  telegramUsdtStatusReceived,
  telegramSettingRequested,
  telegramSettingReceived,
  telegramSettingRequestFailed,
  telegramUsersRequested,
  telegramUsersReceived,
  telegramUsersRequestFailed,
  telegramUserTransactionsRequested,
  telegramUserTransactionsReceived,
  moreTelegramUserTransactionsReceived,
  telegramUserTransactionsRequestFailed,
  telegramChallengesRequested,
  telegramChallengesReceived,
  telegramChallengesRequestFailed,
  verisoulTelegramAuthResultsRequested,
  verisoulTelegramAuthResultsReceived,
  verisoulTelegramAuthResultsRequestFailed,
  verisoulMultipleTelegramAccountsRequested,
  verisoulMultipleTelegramAccountsReceived,
  verisoulMultipleTelegramAccountsRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadTelegramSpinReward = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSpinReward",
      params,
      onStart: telegramSpinRewardRequested.type,
      onSuccess: telegramSpinRewardReceived.type,
      onError: telegramSpinRewardRequestFailed.type,
      callback,
    }),
  );
};
export const updateTelegramSpinReward = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSpinReward/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const loadTelegramUsdtRequests = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/usdtWithdrawal",
      params,
      onStart: telegramUsdtWithdrawalsRequested.type,
      onSuccess: telegramUsdtWithdrawalsReceived.type,
      onError: telegramUsdtWithdrawalsRequestFailed.type,
      callback,
    }),
  );
};
export const updateTelegramUsdtStatus = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/transferUsdt/" + id,
      callback,
    }),
  );
};
export const loadTelegramSettings = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSetting",
      onStart: telegramSettingRequested.type,
      onSuccess: telegramSettingReceived.type,
      onError: telegramSettingRequestFailed.type,
      callback,
    }),
  );
};
export const addTelegramSetting = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramSetting",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadTelegramUsers = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser",
      params,
      onStart: telegramUsersRequested.type,
      onSuccess: telegramUsersReceived.type,
      onError: telegramUsersRequestFailed.type,
      callback,
    }),
  );
};
export const loadTelegramUserTransactions = (id, params, fresh, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/transactions/" + id,
      params,
      onStart: telegramUserTransactionsRequested.type,
      onSuccess: fresh ? telegramUserTransactionsReceived.type : moreTelegramUserTransactionsReceived.type,
      onError: telegramUserTransactionsRequestFailed.type,
      callback,
    }),
  );
};
export const loadTelegramChallenges = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "telegramUser/challenges",
      params,
      onStart: telegramChallengesRequested.type,
      onSuccess: telegramChallengesReceived.type,
      onError: telegramChallengesRequestFailed.type,
      callback,
    }),
  );
};
export const loadVerisoulTelegramAuthResults = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      params,
      url: "telegramUser/verisoulAuthResults",

      onStart: verisoulTelegramAuthResultsRequested.type,
      onSuccess: verisoulTelegramAuthResultsReceived.type,
      onError: verisoulTelegramAuthResultsRequestFailed.type,
      callback,
    }),
  );
};
export const loadVerisoulMultipleTelegramAccounts = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      params,
      url: "telegramUser/verisoulMultipleAccounts",
      onStart: verisoulMultipleTelegramAccountsRequested.type,
      onSuccess: verisoulMultipleTelegramAccountsReceived.type,
      onError: verisoulMultipleTelegramAccountsRequestFailed.type,
      callback,
    }),
  );
};
export const getTelegram = createSelector(
  (state) => state.entities.telegram,
  (telegram) => telegram,
);
