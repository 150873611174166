import React, { createRef } from "react";
import { connect } from "react-redux";
import { getUsers, loadAMOEUsers, awardAMOETickets, isEligibleForAMOE } from "store/users";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import _, { debounce } from "lodash";
import { toast } from "react-toastify";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";
import Joi from "joi-browser";
import { truncateAddress } from "utils/misc";
import moment from "moment";
import { withRouter, Link } from "react-router-dom";
import { convertDate, formatDate } from "utils/dateConversion";

//  Images
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";

//  Components
import Form from "common/form/form";
import Header from "common/header";
import SideBar from "common/sidebar";
import Loader from "common/loader";
import AlertModal from "common/alert/alertModal";
import NextButton from "common/form/submitButton";
import closeIcon from "include/images/close-icon.svg";
import WonTicketsListing from "./wonTicketsListing";
import PreviousAmoeAwards from "./previousAmoeAwards";
import EditUserDetails from "./viewUserDetails";
import SubmitButton from "common/form/submitButton";

class AMOEListing extends Form {
  ref = createRef(null);
  state = {
    data: {
      keyword: "",
      postmarkedAt: "",
    },
    errors: {},
    loading: false,
    showFilters: false,
    user: "",
    // page: 1,
    showEditModal: false,
    showSuspendUser: false,
    loadingReset: false,
    appliedFilters: {},
    showWonTickets: false,
    showAmoeAwards: false,
    dataFetched: false,
    loadingAward: false,
    ticketsAwarded: false,
    loadingFilters: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.complete();

    if (this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.continuousStart();
  };

  debounce = (callback, delay) => {
    let timer;

    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), delay);
    };
  };
  debouncedLog = debounce((text) => {
    this.props.loadAMOEUsers({ keyword: text });
  }, 300);

  //  Filter Methods
  searchFilters = () => {
    this.setState({ loading: true, loadingFilters: true });
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    const payLoad = {
      keyword: data.keyword,
      // page: this.state.page,
    };
    var params = _.pickBy(payLoad, function (value, key) {
      return value !== "";
    });

    //  Load the user with username/walletAddress/email filter
    this.props.loadAMOEUsers(params, (userRes) => {
      if (userRes.status === 200) {
        if (userRes?.data?.data?.length > 0) {
          //  Check the eligibility to award the AMOE Tickets on "postmarkedAt"
          this.props.isEligibleForAMOE(
            {
              userId: userRes?.data?.data[0]?.id,
              postmarkedAt: formatDate(this.state.data.postmarkedAt, "x"),
            },
            (res) => {
              this.setState({ dataFetched: true, loading: false, loadingFilters: false });
            },
          );
        } else {
          this.setState({ loading: false, loadingFilters: false });
        }
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).map((key) => {
          if (!_.isEmpty(data[key])) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({ appliedFilters, ticketsAwarded: false });
      } else {
        this.setState({ loading: false, loadingFilters: false });
      }
    });
  };
  resetFilters = (e) => {
    e.preventDefault();
    this.setState(
      {
        data: { keyword: "", postmarkedAt: "" },
      },
      () => this.searchFilters(),
    );
  };
  removeFilter = (e, type) => {
    const data = this.state.data;
    if (type === "status") {
      data[type] = {};
    } else {
      data[type] = "";
    }
    this.setState({ data }, () => this.searchFilters());
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  schema = {
    keyword: Joi.string().allow(""),
    postmarkedAt: Joi.date().allow(""),
  };

  //  Toggle Methods for flyers
  toggleWonTicketsFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showWonTickets: !this.state.showWonTickets });
  };
  togglePreviousAmoeAwards = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAmoeAwards: !this.state.showAmoeAwards });
  };
  toggleEditFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  //  Award AMOE Tickets Method
  handleAwardTickets = () => {
    this.setState({ loadingAward: true });
    //  Call award API with user id
    this.props.awardAMOETickets(
      { userId: this.state.user?.id, postmarkedAt: parseInt(formatDate(this.state.data.postmarkedAt, "x")) },
      (userRes) => {
        this.setState({ loadingAward: false });
        if (userRes.status == 200) {
          toast(<AlertSuccess message="Information Saved!" />, {
            containerId: 1,
          });
          this.setState({ ticketsAwarded: true });
        } else {
          toast(<AlertError message={userRes.data && userRes.data.message} />, {
            containerId: 1,
          });
        }
      },
    );
  };

  render() {
    const { amoeUsers, amoeEligibility } = this.props.getUsers;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="amoe" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>AMOE</h1>
            </div>
            <div className="fwc-wrapper">
              <div className="fwc-head ">
                <div className="fwc-inner">
                  <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                    <li className="flex-fill">
                      <div className="fml-box">{this.renderInput("keyword", "Username / Email / Wallet Address")}</div>
                    </li>{" "}
                    <li className="flex-fill">
                      <div className="fml-box">
                        <div className="fml-input-group d-flex align-items-center">
                          <div className="fml-input-group-item flex-grow-1">
                            {this.renderDateESTInput("postmarkedAt", "Postmarked Date ", null, "", true)}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="fwc-btn d-flex align-items-center justify-content-end">
                    <NextButton
                      handleSubmit={this.searchFilters}
                      classData="btn btn-primary btn-sm"
                      label="Search"
                      loading={this.state.loadingFilters}
                      disabled={!this.state.data.keyword || !this.state.data.postmarkedAt}
                    />
                  </div>
                </div>{" "}
              </div>

              <div className="fwc-body">
                {!this.state.loadingFilters &&
                  amoeEligibility &&
                  !amoeEligibility?.isEligible &&
                  this.state.dataFetched && (
                    <div className="ust-tag" style={{ marginBottom: 30 }}>
                      <Dropdown className="custom-dropdown crm-wrapper">
                        <Dropdown.Toggle
                          id="tdDropdownOne"
                          className="custom-dropdown-toggle pointer-none"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className={`d-block suspended my-2`}>{amoeEligibility?.message}</span>
                        </Dropdown.Toggle>
                      </Dropdown>{" "}
                    </div>
                  )}
                {!this.state.loading && (
                  <div className="table-responsive">
                    {!this.state.dataFetched ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>Please search for a username, email or wallet address</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : amoeUsers.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Username / Email</th>
                            <th>Wallet Address</th>
                            <th>Tickets Won</th>
                            <th>Previous AMOE Awards</th>
                            <th>Account Creation Date</th>
                            <th>Award AMOE Tickets</th>
                          </tr>
                        </thead>
                        <tbody>
                          {amoeUsers?.length > 0 &&
                            amoeUsers.map((user, i) => (
                              <tr>
                                <td>
                                  <span>
                                    <div className="td-img-group d-flex align-items-start ">
                                      <div
                                        className="td-img round-img m-right-2 pointer"
                                        onClick={(e) => {
                                          this.setState({ user: user }, () => {
                                            this.toggleEditFlyer(e);
                                          });
                                        }}
                                      >
                                        {user.image ? (
                                          <img src={process.env.REACT_APP_CDN + user.image.small} alt="" />
                                        ) : (
                                          <img src={userIcon} alt="" />
                                        )}
                                      </div>

                                      <div>
                                        <a
                                          href="#"
                                          onClick={(e) => {
                                            this.setState({ user: user }, () => {
                                              this.toggleEditFlyer(e);
                                            });
                                          }}
                                        >
                                          {user.userName}
                                        </a>
                                        <br />
                                        {user.email}
                                      </div>
                                    </div>
                                  </span>
                                </td>
                                <td>
                                  {" "}
                                  <Link
                                    to="!#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      navigator.clipboard.writeText(user?.walletAddress);
                                      toast(<AlertSuccess message="Copied to clipboard" />, { containerId: 1 });
                                    }}
                                  >
                                    <>{truncateAddress(user?.walletAddress)}</>
                                  </Link>
                                </td>
                                <td className="ust-tag">
                                  <Link
                                    to="/"
                                    className="orange-text"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({ user }, () => this.toggleWonTicketsFlyer(e));
                                    }}
                                  >
                                    Show Tickets Won
                                  </Link>
                                </td>{" "}
                                <td className="ust-tag">
                                  <Link
                                    to="/"
                                    className="orange-text"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({ user }, () => this.togglePreviousAmoeAwards(e));
                                    }}
                                  >
                                    Show Previous AMOE Awards
                                  </Link>
                                </td>
                                <td>{moment(user.createdAt).format("MM/DD/YYYY hh:mm a")}</td>
                                <td>
                                  <SubmitButton
                                    loading={this.state.loadingAward}
                                    handleSubmit={(e) => {
                                      e.preventDefault();
                                      this.setState({ user }, this.handleAwardTickets);
                                    }}
                                    disabled={
                                      (amoeEligibility && !amoeEligibility?.isEligible) ||
                                      this.state.loading ||
                                      this.state.ticketsAwarded
                                    }
                                    label="Award AMOE Tickets"
                                    classData="btn btn-outline w-100"
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}

                {/* {!this.state.loading && !(this.state.page === 1 && amoeUsers.length === 0) && (
                  <div className="page-pagination">
                    <ul className="pagination  justify-content-end">
                      <li className="page-item">
                        <a
                          class={`page-link ${this.state.page === 1 && "disabled"}`}
                          href="#"
                          aria-label="Previous"
                          onClick={(e) => {
                            e.preventDefault();
                            if (this.state.page > 1) {
                              this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                            }
                          }}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                      <li className="page-item active">
                        <a
                          className="page-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {this.state.page}
                        </a>
                      </li>
                      {amoeUsers.length >= 50 && (
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            {this.state.page + 1}
                          </a>
                        </li>
                      )}
                      <li className="page-item">
                        <a
                          class={`page-link ${amoeUsers.length < 50 && "disabled"}`}
                          href="#"
                          aria-label="Next"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                          }}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>{" "}
        <EditUserDetails
          showModal={this.state.showEditModal}
          toggleModal={this.toggleEditFlyer}
          user={this.state.user}
        />{" "}
        <WonTicketsListing
          showModal={this.state.showWonTickets}
          toggleModal={this.toggleWonTicketsFlyer}
          user={this.state.user}
        />
        <PreviousAmoeAwards
          showModal={this.state.showAmoeAwards}
          toggleModal={this.togglePreviousAmoeAwards}
          user={this.state.user}
        />
        {(this.state.showWonTickets || this.state.showEditModal || this.state.showAmoeAwards) && (
          <div
            className="modal-backdrop show fade z-index-1000"
            onClick={
              this.state.showWonTickets
                ? this.toggleWonTicketsFlyer
                : this.state.showAmoeAwards
                ? this.togglePreviousAmoeAwards
                : this.toggleEditFlyer
            }
          ></div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadAMOEUsers: (prams, callback) => dispatch(loadAMOEUsers(prams, callback)),
  isEligibleForAMOE: (params, payLoad) => dispatch(isEligibleForAMOE(params, payLoad)),
  awardAMOETickets: (data, callback) => dispatch(awardAMOETickets(data, callback)),
});
const mapStateToProps = (state) => ({
  getUsers: getUsers(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AMOEListing));
