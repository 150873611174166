import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Joi from "joi-browser";
import _, { debounce, isEmpty } from "lodash";
import { createRef } from "react";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getUsers,
  loadUserTicketBalance,
  loadUsers,
  loadUsersToExport,
  updateKycStatus,
  updateStatus,
  updateUserStatusReceived,
} from "store/users";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";
import arrowIcom from "include/images/chevron-down1.svg";
import discordIcon from "include/images/discord-logo.svg";
import exportIcon from "include/images/export.svg";
import filterIcon from "include/images/filter-icon.svg";
import historyIcon from "include/images/history-icon.svg";
import instaIcon from "include/images/insta.svg";
import loadingIcon from "include/images/loader-grey.svg";
import emptyIcon from "include/images/nrf.svg";
import twitterIcon from "include/images/twitter.svg";
import userIcon from "include/images/user.png";

//  Components
import { Skeleton } from "@mui/material";
import AlertModal from "common/alert/alertModal";
import Form from "common/form/form";
import SelectSearch from "common/form/selectSearch";
import NextButton from "common/form/submitButton";
import Header from "common/header";
import HistoryFlyer from "common/historyFlyer";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import closeIcon from "include/images/close-icon.svg";
import moment from "moment";
import { CSVLink } from "react-csv";
import { getSettings, loadSettings } from "store/settings";
import AddCreditRewards from "./addCreditRewards";
import ChangeWallet from "./changeWallet";
import DeductCreditRewards from "./deductReward.jsx";
import EditUserDetails from "./editUserDetails";
import IpHistory from "./ipHistory";
import ListingActivitiesFlyer from "./listingActivitiesFlyer";
import ListingFlyer from "./listingFlyer";
import ListingNotificationFlyer from "./listingNotificationFlyer";
import ListingWithdrawalFlyer from "./listingWithdrawalFlyer";
import MultipleAccounts from "./multipleAccounts";
import PaymentsListingFlyer from "./paymentsListingFlyer";
import SuspendUserFlyer from "./suspendUserFlyer";
import WalletListingFlyer from "./walletListing";

class UserListing extends Form {
  ref = createRef(null);
  refCsv = createRef();
  scrollRef = createRef();
  lastKeys = [];
  state = {
    data: {
      userName: "",
      email: "",
      searchType: 1,
      creditsMin: "",
      creditsMax: "",
      status: {
        name: "Active",
        value: 1,
      },
    },
    errors: {},
    loading: false,
    loadingSettings: true,
    showGameList: false,
    showWallets: false,
    showPurchaseHistory: false,
    showTransactions: false,
    showNotificationList: false,
    showActivitiesList: false,
    showWithdrawalList: false,
    showFilters: false,
    user: "",
    page: 1,
    showEditModal: false,
    showDeactivateUser: false,
    showSuspendUser: false,
    loadingSuspend: false,
    loadingDeactivate: false,
    loadingReset: false,
    loadingCsv: false,
    appliedFilters: {
      status: {
        name: "Active",
        value: 1,
      },
    },
    showHistory: false,
    showIP: false,
    objectId: "",
    object: null,
    showAddCreditModal: false,
    showDeductCreditModal: false,
    showChangeWallet: false,
    amountType: 1,
    getTicketUser: "",
    showMultipleAccounts: false,
    rewardType: 0,
    showUserPayments: false,
    usersCSV: [],
    showKycModal: false,
    loadingKyc: false,
    currKey: "",
    currentCount: 0,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.complete();

    if (this.props.getUsers.loading && this.props.getUsers.loading !== prevProps.getUsers.loading)
      this.ref.current.continuousStart();
    if (this.state.currKey !== prevState.currKey && this.state.currKey !== null) {
      document.addEventListener("scroll", this.trackScrolling, true);
    }
  };
  trackScrolling = () => {
    const { users, totalCount } = this.props.getUsers;
    if (this.scrollRef.current?.getBoundingClientRect().bottom - 1000 <= window.innerHeight) {
      if (!this.state.loadingMore && users?.length < totalCount) {
        this.setState({ loadingMore: true });
        this.searchFilters();
      }
      document.removeEventListener("scroll", this.trackScrolling, true);
    }
  };
  componentDidMount = () => {
    this.setState({ loading: true });
    let count = 0;
    const fn = (params) =>
      this.props.loadUsers(params, true, (res) => {
        if (res?.status === 200) {
          count = count + res?.data?.data?.length;
          if (count < 50 && res?.data?.totalCount > 0 && count < res?.data?.totalCount && res?.data?.lastKey) {
            this.setState({ currKey: res?.data?.lastKey, loading: false });
            fn({ status: 1, lastKey: res?.data?.lastKey });
          } else {
            this.lastKeys.push(this.state.currKey);
            this.setState({
              loading: false,
              currKey: res?.data?.lastKey,
              currentCount: this.state.currentCount + res?.data?.data?.length,
            });
          }
        } else this.setState({ loading: false });
      });
    fn({ status: 1 });
    this.props.loadSettings(() => {
      this.setState({ loadingSettings: false });
    });
  };

  //  Toggle methods for flyers
  toggleHistoryFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showHistory: !this.state.showHistory });
  };
  toggleMultipleAccountsFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showMultipleAccounts: !this.state.showMultipleAccounts });
  };
  toggleUserPaymentsFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showUserPayments: !this.state.showUserPayments });
  };
  toggleAddCreditRewardFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddCreditModal: !this.state.showAddCreditModal });
  };
  toggleDeductCreditRewardFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeductCreditModal: !this.state.showDeductCreditModal });
  };
  toggleChangeWalletFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showChangeWallet: !this.state.showChangeWallet });
  };
  deactivateModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeactivateUser: !this.state.showDeactivateUser });
  };
  suspendModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showSuspendUser: !this.state.showSuspendUser });
  };

  //  Filters
  searchFilters = () => {
    this.setState({ loading: this.state.currKey?.length === 0, loadingFilters: true });
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    if (
      (parseInt(data.creditsMax) || parseInt(data.creditsMax) === 0) &&
      (parseInt(data.creditsMin) || parseInt(data.creditsMin) === 0)
    ) {
      if (parseInt(data.creditsMax) <= parseInt(data.creditsMin)) {
        errors.creditsMax = "Max game credits should be greater than min game credits";
      }
    }
    if (errors.creditsMax) {
      return this.setState({ errors, loadingFilters: false, loading: false });
    }
    const payLoad = {
      userName: data?.userName,
      email: data?.email,
      searchType: data?.searchType,
    };
    // if (this.state.page > 1) payLoad.lastKey = this.lastKeys[this.lastKeys.length - 1];

    if (!_.isEmpty(data.status)) {
      payLoad.status = data.status.value;
    }

    var params = _.pickBy(payLoad, function (value, key) {
      return value !== "";
    });
    let count = 0;
    const fn = (lastKey) => {
      if (lastKey?.length) params.lastKey = lastKey;
      this.props.loadUsers(params, lastKey?.length === 0, (userRes) => {
        if (userRes?.status === 200) {
          count = count + userRes?.data?.data?.length;

          if (
            count < 50 &&
            userRes?.data?.totalCount > 0 &&
            count < userRes?.data?.totalCount &&
            userRes?.data?.lastKey
          ) {
            this.setState({ currKey: userRes?.data?.lastKey, loading: false });
            fn(userRes?.data?.lastKey);
          } else {
            const data = { ...this.state.data };
            const appliedFilters = {};
            Object.keys(this.state.data).forEach((key) => {
              if (!_.isEmpty(data[key])) {
                appliedFilters[key] = data[key];
              }
            });
            this.lastKeys.push(this.state.currKey);
            this.setState({
              appliedFilters,
              currKey: userRes?.data?.lastKey,
              loading: false,
              loadingMore: false,
              loadingFilters: false,
              currentCount: this.state.currentCount + userRes?.data?.data?.length,
            });
          }
        } else this.setState({ loading: false, loadingFilters: false });
      });
    };
    fn(this.state.currKey);
  };
  resetFilters = (e) => {
    e.preventDefault();
    this.lastKeys = [];
    this.setState(
      {
        data: {
          userName: "",
          email: "",
          searchType: 1,
          creditsMin: "",
          creditsMax: "",
          status: {
            name: "Active",
            value: 1,
          },
        },
        page: 1,
        currKey: "",
        currentCount: 0,
      },
      () => this.searchFilters(),
    );
  };
  removeFilter = (e, type) => {
    const data = this.state.data;
    if (type === "status") {
      data[type] = {};
    } else {
      data[type] = "";
    }
    this.setState({ data }, () => this.searchFilters());
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  toggleGameFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showGameList: !this.state.showGameList });
  };
  toggleWalletsFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showWallets: !this.state.showWallets });
  };
  toggleIPFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showIP: !this.state.showIP });
  };

  toggleUserTransactionsFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showTransactions: !this.state.showTransactions });
  };
  toggleEditFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showEditModal: !this.state.showEditModal });
  };
  toggleNotificationFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showNotificationList: !this.state.showNotificationList });
  };
  toggleActivitiesFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showActivitiesList: !this.state.showActivitiesList });
  };
  toggleWithdrawalFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showWithdrawalList: !this.state.showWithdrawalList });
  };
  kycModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showKycModal: !this.state.showKycModal });
  };
  handleDeactivateUser = (e) => {
    if (e) e.preventDefault();
    this.setState({ loadingDeactivate: true });
    this.props.updateStatus(
      {
        status: this.state.user.status === 1 ? 0 : 1,
        reason: "",
      },
      this.state.user.id,
      (userRes) => {
        if (userRes.status == 200) {
          toast(<AlertSuccess message="Information Saved!" />, {
            containerId: 1,
          });
          this.props.updateUserStatusReceived({
            id: this.state.user.id,
            status: this.state.user.status === 1 ? 0 : 1,
          });
          this.deactivateModalToggle();
          this.setState({ loadingDeactivate: false });
        } else {
          toast(<AlertError message={userRes.data && userRes.data.message} />, {
            containerId: 1,
          });
          this.deactivateModalToggle();
          this.setState({ loadingDeactivate: false });
        }
      },
    );
  };
  handleSuspendUser = (e, reason, callback) => {
    if (e) e.preventDefault();
    this.setState({ loadingSuspend: true });
    this.props.updateStatus(
      {
        status: -1,
        reason: reason,
      },
      this.state.user.id,
      (userRes) => {
        if (userRes.status == 200) {
          toast(<AlertSuccess message="Information Saved!" />, {
            containerId: 1,
          });
          this.props.updateUserStatusReceived({
            id: this.state.user.id,
            status: -1,
            reason,
          });
          callback();
          this.setState({ loadingSuspend: false });
        } else {
          toast(<AlertError message={userRes.data && userRes.data.message} />, {
            containerId: 1,
          });
          callback();
          this.setState({ loadingSuspend: false });
        }
      },
    );
  };
  handleKycToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ loadingKyc: true });
    this.props.updateKycStatus(this.state.user.id, (userRes) => {
      if (userRes.status === 200) {
        toast(<AlertSuccess message="Information Saved!" />, {
          containerId: 1,
        });
      } else {
        toast(<AlertError message={userRes.data && userRes.data.message} />, {
          containerId: 1,
        });
      }
      this.kycModalToggle();
      this.setState({ loadingKyc: false });
    });
  };
  loadUserCSV = (e) => {
    e.preventDefault();
    this.setState({ loadingCsv: true });
    let lastKey = "";
    let usersCSV = [];
    this.ref.current.continuousStart();
    const fn = () => {
      const params = { status: 1 };
      if (lastKey) params.lastKey = lastKey;
      this.props.loadUsersToExport(params, (res) => {
        if (lastKey === null) {
          this.setState({ loadingCsv: false, usersCSV: usersCSV }, () => this.refCsv.current.link.click());
          this.ref.current.complete();
          return;
        }
        usersCSV = [
          ...usersCSV,
          ...res.data.data?.map((user, i) => ({
            "User Name": user?.userName,
            "Email Address": user?.email,
            Date: moment(user?.createdAt).format("MM/DD/YYYY"),
          })),
        ];
        lastKey = res?.data?.lastKey;
        fn();
      });
    };
    fn();
  };
  schema = {
    userName: Joi.string().allow(""),
    email: Joi.string().allow(""),
    searchType: Joi.number().allow(""),
    creditsMin: Joi.string().allow(""),
    status: Joi.object().allow({}),
    creditsMax: Joi.string().allow(""),
  };

  render() {
    const { users, totalCount, filteredCount } = this.props.getUsers;
    const { settings } = this.props.getSettings;
    const { userPurchaseHistoryReportUpdate, userCLVAndARPPUReportUpdate } = settings?.length > 0 && settings[0];
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="users" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>
                {this.state?.appliedFilters?.status?.name} Users {totalCount > 0 && `(${totalCount})`}
              </h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                {" "}
                <div className="fmb-box">
                  <Dropdown className="custom-dropdown">
                    <Dropdown.Toggle
                      id="tdDropdownOne"
                      className="custom-dropdown-toggle filter-link-box"
                      data-bs-toggle="dropdown"
                    >
                      <img src={exportIcon} alt="export csv" />
                      <span>Export</span>
                      <img src={arrowIcom} alt="down arrow" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={`dropdown-menu-middle `} aria-labelledby="tdDropdownOne">
                      <Dropdown.Item as="button" className="dropdown-item pointer" onClick={this.loadUserCSV}>
                        Users - {moment().format("MM/DD/YYYY")}
                      </Dropdown.Item>
                      {this.state.loadingSettings ? (
                        <>
                          <Skeleton variant="rectangular" width={150} height={30} />
                          <Skeleton variant="rectangular" width={150} height={30} />
                        </>
                      ) : (
                        <>
                          <Dropdown.Item
                            className="dropdown-item pointer"
                            href={process.env.REACT_APP_CDN + userPurchaseHistoryReportUpdate?.path}
                          >
                            Payments - {moment(userPurchaseHistoryReportUpdate?.lastUpdated).format("MM/DD/YYYY")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="dropdown-item pointer"
                            href={process.env.REACT_APP_CDN + userCLVAndARPPUReportUpdate?.path}
                          >
                            CLV - {moment(userCLVAndARPPUReportUpdate?.lastUpdated).format("MM/DD/YYYY")}
                          </Dropdown.Item>
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  <CSVLink
                    filename={"Users.csv"}
                    className="d-none"
                    data={this.state.usersCSV}
                    ref={this.refCsv}
                  ></CSVLink>
                </div>
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState(
                        {
                          objectId: "",
                          object: null,
                        },
                        () => {
                          this.toggleHistoryFlyer();
                        },
                      );
                    }}
                  >
                    <img src={historyIcon} /> <span>History</span>
                  </a>
                </div>
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showFilters: !this.state.showFilters });
                    }}
                  >
                    <img src={filterIcon} /> <span>Filters</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="fwc-wrapper">
              {this.state.showFilters && (
                <div className="fwc-head ">
                  <div className="fwc-inner">
                    <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                      <li className="flex-fill">
                        <div className="row">
                          <label className="form-label">Search Type</label>
                          <div className="form-check-group full-width-row d-flex align-items-center flex-wrap">
                            <div className="form-check-item">
                              <div className="form-check has-circle-radio">
                                <input
                                  className={`form-check-input`}
                                  type="radio"
                                  name="searchType"
                                  id="radioCheckOne"
                                  checked={this.state.data?.searchType === 1}
                                  onChange={() => this.onChange({ name: "searchType", currentTarget: 1 })}
                                />
                                <label className="form-check-label" htmlFor="radioCheckOne">
                                  Exact Search
                                </label>
                              </div>
                            </div>
                            <div className="form-check-item ms-0">
                              <div className="form-check has-circle-radio">
                                <input
                                  className={`form-check-input`}
                                  type="radio"
                                  name="searchType"
                                  id="radioCheckTwo"
                                  checked={this.state.data?.searchType === 2}
                                  onChange={() => this.onChange({ name: "searchType", currentTarget: 2 })}
                                />
                                <label className="form-check-label" htmlFor="radioCheckTwo">
                                  Wildcard Search
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("userName", "Username")}</div>
                      </li>{" "}
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("email", "Email")}</div>
                      </li>{" "}
                      <li className="flex-fill">
                        <div className="fml-box">
                          <label className="form-label">Status</label>
                          <SelectSearch
                            name="status"
                            options={[
                              {
                                name: "Active",
                                value: 1,
                              },
                              {
                                name: "Inactive",
                                value: 0,
                              },
                              {
                                name: "Suspended",
                                value: -1,
                              },
                            ]}
                            label=""
                            value={this.state.data.status}
                            error={this.state.errors.status}
                            onChange={this.onChange}
                          />
                        </div>
                      </li>{" "}
                      {/* <li className="flex-fill">
                        <div className="fml-box">
                          <label className="form-label">Provider</label>
                          <SelectSearch
                            name="provider"
                            options={[
                              {
                                name: "Google",
                                value: 1,
                              },
                              {
                                name: "Facebook",
                                value: 2,
                              },
                              {
                                name: "Email",
                                value: 3,
                              },
                              {
                                name: "Discord",
                                value: 4,
                              },
                            ]}
                            label=""
                            value={this.state.data.provider}
                            error={this.state.errors.provider}
                            onChange={this.onChange}
                          />
                        </div>
                      </li> */}
                      {/* <li className="flex-fill">
                        <div className="fml-box">
                          <label className="form-label">Multiple Accounts</label>
                          <SelectSearch
                            name="multipleAccounts"
                            options={[
                              {
                                name: "Yes",
                                value: 1,
                              },
                            ]}
                            label=""
                            value={this.state.data.multipleAccounts}
                            error={this.state.errors.multipleAccounts}
                            onChange={this.onChange}
                          />
                        </div>
                      </li> */}
                    </ul>
                    <div className="fwc-btn d-flex align-items-center justify-content-end">
                      <NextButton
                        handleSubmit={this.resetFilters}
                        classData="btn-text pointer"
                        label="Reset"
                        loading={this.state.loadingReset}
                      />
                      <NextButton
                        handleSubmit={() => {
                          this.lastKeys = [];
                          this.setState(
                            {
                              page: 1,
                              currKey: "",
                              currentCount: 0,
                            },
                            () => this.searchFilters(),
                          );
                        }}
                        classData="btn btn-primary btn-sm"
                        label="Search"
                        loading={this.state.loadingFilters}
                      />
                    </div>
                  </div>{" "}
                  {!_.isEmpty(this.state.appliedFilters) && (
                    <div className="filter-tag-container d-flex align-items-center ">
                      <div className="fwc-left">
                        <div className="filter-tag-list">
                          <p>filter applied :</p>
                          {Object.keys(this.state.appliedFilters).map((key) => (
                            <div className="filter-tag-item">
                              <span className="camelCase">{key === "userName" ? "Username" : key}</span> :{" "}
                              {key === "status" ? this.state.appliedFilters[key].name : this.state.appliedFilters[key]}
                              {key !== "status" && (
                                <a
                                  href="#!"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.lastKeys = [];
                                    this.setState(
                                      {
                                        data: { ...this.state.data, [key]: "" },
                                        page: 1,
                                        currKey: "",
                                        currentCount: 0,
                                      },
                                      this.searchFilters,
                                    );
                                  }}
                                >
                                  <img src={closeIcon} alt="" />
                                </a>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="" ref={this.scrollRef}>
                {" "}
                <div className="fwc-body">
                  {!this.state.loading && (
                    <div className="table-responsive">
                      {users.length === 0 ? (
                        <div className="fwc-body">
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <table className="table large-table">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }}>Actions</th>
                              <th>Wallet Address</th>
                              <th width={150}>Game Credits</th>
                              <th width={150}>Multiple Accounts</th>
                              <th width={200}>Links</th>
                              <th>Mode</th>
                              <th>Status</th>
                              <th>KYC Verified</th>
                              <th width={300}>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users &&
                              users.length > 0 &&
                              users.map((user, i) => (
                                <tr>
                                  <td>
                                    <Dropdown className=" custom-dropdown">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <img src={carrentIcon} alt="" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ user: user }, () => {
                                              this.toggleEditFlyer(e);
                                            });
                                          }}
                                        >
                                          Edit User
                                        </Dropdown.Item>{" "}
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ user: user }, () => {
                                              this.toggleChangeWalletFlyer(e);
                                            });
                                          }}
                                        >
                                          Add Wallet
                                        </Dropdown.Item>{" "}
                                        <Dropdown.Divider />
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ user: user, rewardType: 1 }, () => {
                                              this.toggleAddCreditRewardFlyer(e);
                                            });
                                          }}
                                        >
                                          Free XP
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ user: user, rewardType: 2 }, () => {
                                              this.toggleAddCreditRewardFlyer(e);
                                            });
                                          }}
                                        >
                                          Free Arcade Coins
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ user: user, rewardType: 3 }, () => {
                                              this.toggleAddCreditRewardFlyer(e);
                                            });
                                          }}
                                        >
                                          Free Loot Bucks
                                        </Dropdown.Item>
                                        <Dropdown.Divider></Dropdown.Divider>
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ user: user, rewardType: 1 }, () => {
                                              this.toggleDeductCreditRewardFlyer(e);
                                            });
                                          }}
                                        >
                                          Deduct XP
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ user: user, rewardType: 2 }, () => {
                                              this.toggleDeductCreditRewardFlyer(e);
                                            });
                                          }}
                                        >
                                          Deduct Arcade Coins
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ user: user, rewardType: 3 }, () => {
                                              this.toggleDeductCreditRewardFlyer(e);
                                            });
                                          }}
                                        >
                                          Deduct Loot Bucks
                                        </Dropdown.Item>
                                        <Dropdown.Divider></Dropdown.Divider>
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ user: user, amountType: 1 }, () => {
                                              this.toggleGameFlyer(e);
                                            });
                                          }}
                                        >
                                          View Arcade Games
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ user: user, amountType: 3 }, () => {
                                              this.toggleGameFlyer(e);
                                            });
                                          }}
                                        >
                                          View Loot Bucks Games
                                        </Dropdown.Item>
                                        <Dropdown.Divider></Dropdown.Divider>
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ user: user }, () => {
                                              this.toggleNotificationFlyer(e);
                                            });
                                          }}
                                        >
                                          View Notifications
                                        </Dropdown.Item>{" "}
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ user: user }, () => {
                                              this.toggleUserPaymentsFlyer(e);
                                            });
                                          }}
                                        >
                                          View Payments
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ user: user }, () => {
                                              this.toggleActivitiesFlyer(e);
                                            });
                                          }}
                                        >
                                          View Activities
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ user: user }, () => {
                                              this.toggleWithdrawalFlyer(e);
                                            });
                                          }}
                                        >
                                          View Withdrawals
                                        </Dropdown.Item>{" "}
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ user: user }, () => {
                                              this.toggleWalletsFlyer(e);
                                            });
                                          }}
                                        >
                                          View Wallet Connections
                                        </Dropdown.Item>{" "}
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ user: user }, () => {
                                              this.toggleIPFlyer(e);
                                            });
                                          }}
                                        >
                                          IP History
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ objectId: user.id, object: user }, () => {
                                              this.toggleHistoryFlyer(e);
                                            });
                                          }}
                                        >
                                          History
                                        </Dropdown.Item>{" "}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                  <td>
                                    <span>
                                      <div className="td-img-group d-flex align-items-start ">
                                        <div
                                          className="td-img round-img m-right-2 pointer"
                                          onClick={(e) => {
                                            this.setState({ user: user }, () => {
                                              this.toggleEditFlyer(e);
                                            });
                                          }}
                                        >
                                          {user.image ? (
                                            <img src={process.env.REACT_APP_CDN + user.image.small} alt="" />
                                          ) : (
                                            <img src={userIcon} alt="" />
                                          )}
                                        </div>

                                        <div>
                                          <a
                                            href="#"
                                            onClick={(e) => {
                                              this.setState({ user: user }, () => {
                                                this.toggleEditFlyer(e);
                                              });
                                            }}
                                          >
                                            {user.userName}
                                          </a>
                                          <br />
                                          {user.email}
                                        </div>
                                      </div>
                                    </span>
                                  </td>
                                  <td className="ust-tag">
                                    <Dropdown className=" custom-dropdown my-2">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        disabled
                                      >
                                        <span className={`w-100 text-center d-block arcade`}>
                                          Arcade Coins: {user?.arcadeCoins?.toLocaleString() ?? 0}
                                        </span>
                                      </Dropdown.Toggle>
                                    </Dropdown>
                                    <Dropdown className=" custom-dropdown my-2">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        disabled
                                      >
                                        <span className={`w-100 text-center d-block loot`}>
                                          Loot Bucks: {user?.lootBonusCoins?.toLocaleString("en-us") ?? 0}
                                        </span>
                                      </Dropdown.Toggle>
                                    </Dropdown>

                                    {"walletAddress" in user ? (
                                      <Dropdown className=" custom-dropdown my-2">
                                        <Dropdown.Toggle
                                          id="tdDropdownOne"
                                          className={`custom-dropdown-toggle ${
                                            ("ticketBalance" in user || this.props.getUsers?.loadingTicketBalance) &&
                                            "pointer-default"
                                          }`}
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          onClick={(e) => {
                                            if ("ticketBalance" in user && !this.props.getUsers?.loadingTicketBalance)
                                              return;

                                            this.setState({ getTicketUser: user?.id });
                                            this.props.loadUserTicketBalance({ userId: user?.id }, () => {
                                              this.setState({ getTicketUser: "" });
                                            });
                                          }}
                                        >
                                          <span className={`w-100 text-center d-block tickets `}>
                                            {this.props.getUsers?.loadingTicketBalance &&
                                            user?.id === this.state.getTicketUser ? (
                                              <img
                                                src={loadingIcon}
                                                alt="loading..."
                                                style={{ width: 20 }}
                                                className="fa-spin"
                                              />
                                            ) : "ticketBalance" in user ? (
                                              <>
                                                <OverlayTrigger
                                                  placement="top"
                                                  overlay={
                                                    <Popover
                                                      id="popoverPannel"
                                                      className="custom-tooltip "
                                                      data-bs-title="Illegitimate"
                                                    >
                                                      <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                                        <h4>Tickets Awarded: {user?.allTicketAwarded}</h4>
                                                        <h4>Tickets Used: {user?.ticketUsed}</h4>
                                                      </Popover.Body>
                                                    </Popover>
                                                  }
                                                >
                                                  <div
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    data-bs-custom-class="custom-tooltip"
                                                    data-bs-title="Illegitimate"
                                                  >
                                                    Tickets: {user.ticketBalance}
                                                  </div>
                                                </OverlayTrigger>
                                              </>
                                            ) : (
                                              "Show Tickets"
                                            )}
                                            {/* Tickets: {user?.availableTickets?.toLocaleString() ?? 0} */}
                                          </span>
                                        </Dropdown.Toggle>
                                      </Dropdown>
                                    ) : (
                                      <Dropdown className=" custom-dropdown my-2">
                                        <Dropdown.Toggle
                                          id="tdDropdownOne"
                                          className={`custom-dropdown-toggle pointer-none`}
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          disabled={true}
                                        >
                                          <span className={`w-100 text-center d-block tickets `}>NA</span>
                                        </Dropdown.Toggle>
                                      </Dropdown>
                                    )}
                                  </td>
                                  <td className="ust-tag">
                                    <Dropdown className=" custom-dropdown my-2">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className={`custom-dropdown-toggle `}
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        disabled={!user?.multipleAccounts}
                                        onClick={(e) => {
                                          if (user?.multipleAccounts) {
                                            this.setState({ user: user }, () => {
                                              this.toggleMultipleAccountsFlyer(e);
                                            });
                                          }
                                        }}
                                      >
                                        <span
                                          className={`w-100 text-center d-block tickets ${
                                            user?.multipleAccounts && "suspended"
                                          } `}
                                        >
                                          {user?.multipleAccounts ? "Yes" : "No"}
                                        </span>
                                      </Dropdown.Toggle>
                                    </Dropdown>
                                  </td>
                                  <td>
                                    {user.instagram && (
                                      <a
                                        href={`https://www.instagram.com/${user.instagram}`}
                                        className="orange-text"
                                        target="_blank"
                                      >
                                        <span className="m-right-2">
                                          <img src={instaIcon} alt="" />
                                        </span>
                                        Instagram
                                      </a>
                                    )}
                                    {(user.twitter || user.website) && user.instagram && (
                                      <>
                                        <br />
                                        <br />
                                      </>
                                    )}
                                    {user.twitter && (
                                      <a
                                        href={`https://twitter.com/${user.twitter}`}
                                        className="orange-text"
                                        target="_blank"
                                      >
                                        <span className="m-right-2">
                                          <img src={twitterIcon} alt="" />
                                        </span>
                                        Twitter
                                      </a>
                                    )}
                                    {(user.instagram || user.twitter) && user.website && (
                                      <>
                                        <br />
                                        <br />
                                      </>
                                    )}
                                    {user.website && (
                                      <a
                                        href="!#"
                                        className="orange-text"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          navigator.clipboard.writeText(user.website);
                                          toast(<AlertSuccess message="Copied to clipboard" />, { containerId: 1 });
                                        }}
                                      >
                                        <span className="m-right-2">
                                          <img src={discordIcon} alt="" style={{ width: 20 }} />
                                        </span>
                                        Discord
                                      </a>
                                    )}
                                  </td>
                                  <td>
                                    {user.provider == 1
                                      ? "Google"
                                      : user.provider == 2
                                      ? "Facebook"
                                      : user.provider == 3
                                      ? "Email"
                                      : user.provider == 4
                                      ? "discord"
                                      : user.provider == 5
                                      ? "Wallet"
                                      : ""}
                                  </td>
                                  <td className="ust-tag">
                                    <Dropdown className=" custom-dropdown">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <span
                                          className={`w-100 text-center d-block ${
                                            user.status === -1 ? "suspended" : user.status === 0 ? "inactive" : "active"
                                          }`}
                                        >
                                          {user.status === -1 ? "Suspended" : user.status === 0 ? "Inactive" : "Active"}
                                        </span>
                                      </Dropdown.Toggle>
                                      {user.status !== -1 && (
                                        <Dropdown.Menu
                                          className={`dropdown-menu-start `}
                                          aria-labelledby="tdDropdownOne"
                                        >
                                          <>
                                            <Dropdown.Item
                                              className="dropdown-item "
                                              onClick={(e) => {
                                                this.setState({ user: user }, () => this.deactivateModalToggle(e));
                                              }}
                                            >
                                              {user.status === 0 ? "Activate" : "Deactivate"} User
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              className="dropdown-item "
                                              onClick={(e) => {
                                                this.setState({ user: user }, () => this.suspendModalToggle(e));
                                              }}
                                            >
                                              Suspend User
                                            </Dropdown.Item>
                                          </>
                                        </Dropdown.Menu>
                                      )}
                                    </Dropdown>
                                  </td>
                                  <td className="ust-tag">
                                    <span
                                      className={`w-100 text-center d-block ${
                                        user?.isKycVerified ? "active pointer-default" : "suspended pointer"
                                      }`}
                                      onClick={(e) => {
                                        if (!user?.isKycVerified)
                                          this.setState({ user: user }, () => this.kycModalToggle(e));
                                      }}
                                    >
                                      {user?.isKycVerified ? "Yes" : "No"}
                                    </span>
                                  </td>
                                  <td>{moment(user.createdAt).format("MM/DD/YYYY hh:mm a")}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                  {this.state.loadingMore && (
                    <div className="w-100 d-flex justify-content-center load-more">
                      <img src={loadingIcon} className="fa-spin" alt="" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <WalletListingFlyer
          showModal={this.state.showWallets}
          toggleModal={this.toggleWalletsFlyer}
          user={this.state.user}
        />
        <IpHistory showModal={this.state.showIP} toggleModal={this.toggleIPFlyer} user={this.state.user} />{" "}
        <MultipleAccounts
          showModal={this.state.showMultipleAccounts}
          toggleModal={this.toggleMultipleAccountsFlyer}
          user={this.state.user}
        />
        <ListingFlyer
          showModal={this.state.showGameList}
          toggleModal={this.toggleGameFlyer}
          user={this.state.user}
          amountType={this.state.amountType}
        />{" "}
        <ListingNotificationFlyer
          showModal={this.state.showNotificationList}
          toggleModal={this.toggleNotificationFlyer}
          user={this.state.user}
        />{" "}
        <ListingActivitiesFlyer
          showModal={this.state.showActivitiesList}
          toggleModal={this.toggleActivitiesFlyer}
          user={this.state.user}
        />
        <ListingWithdrawalFlyer
          showModal={this.state.showWithdrawalList}
          toggleModal={this.toggleWithdrawalFlyer}
          user={this.state.user}
        />
        <PaymentsListingFlyer
          showModal={this.state.showUserPayments}
          toggleModal={this.toggleUserPaymentsFlyer}
          user={this.state.user}
        />
        <EditUserDetails
          showModal={this.state.showEditModal}
          toggleModal={this.toggleEditFlyer}
          user={this.state.user}
          // loadData={() => {
          //   this.props.loadUsers({}, () => {});
          // }}
        />{" "}
        <AddCreditRewards
          showModal={this.state.showAddCreditModal}
          toggleModal={this.toggleAddCreditRewardFlyer}
          rewardType={this.state.rewardType}
          user={this.state.user}
        />{" "}
        <DeductCreditRewards
          showModal={this.state.showDeductCreditModal}
          toggleModal={this.toggleDeductCreditRewardFlyer}
          rewardType={this.state.rewardType}
          user={this.state.user}
        />{" "}
        <ChangeWallet
          showModal={this.state.showChangeWallet}
          toggleModal={this.toggleChangeWalletFlyer}
          user={this.state.user}
        />
        <AlertModal
          alertModalToggle={this.deactivateModalToggle}
          alertModalShow={this.state.showDeactivateUser}
          title="Alert"
          description={
            this.state.user.status === 0
              ? "Are you sure you want to activate the user? User will be able to login after this action."
              : "Are you sure you want to deactivate the user? User will not be allowed to login after this action. However, user's profile data will still be available in the application and other users can view the same."
          }
          onPress={this.handleDeactivateUser}
          alertType={this.state.user.status === 0 ? "Activate" : "Deactivate"}
          loading={this.state.loadingDeactivate}
        />{" "}
        <AlertModal
          alertModalToggle={this.kycModalToggle}
          alertModalShow={this.state.showKycModal}
          title="Alert"
          description={
            this.state.user?.isKycVerified
              ? "Are you sure the user is not KYC verified?"
              : "Are you sure the user is KYC verified?"
          }
          onPress={this.handleKycToggle}
          alertType={"Yes"}
          loading={this.state.loadingKyc}
        />
        <HistoryFlyer
          showModal={this.state.showHistory}
          toggleModal={this.toggleHistoryFlyer}
          moduleName={"user"}
          objectId={this.state.objectId}
          title={this.state.object && this.state.object.userName}
        />
        {(this.state.showGameList ||
          this.state.showNotificationList ||
          this.state.showActivitiesList ||
          this.state.showEditModal ||
          this.state.showHistory ||
          this.state.showSuspendUser ||
          this.state.showWallets ||
          this.state.showPurchaseHistory ||
          this.state.showTransactions ||
          this.state.showWithdrawalList ||
          this.state.showAddCreditModal ||
          this.state.showChangeWallet ||
          this.state.showIP ||
          this.state.showDeductCreditModal ||
          this.state.showUserPayments ||
          this.state.showMultipleAccounts) && (
          <div
            className="modal-backdrop show fade z-index-1000"
            onClick={
              this.state.showGameList
                ? this.toggleGameFlyer
                : this.state.showActivitiesList
                ? this.toggleActivitiesFlyer
                : this.state.showWithdrawalList
                ? this.toggleWithdrawalFlyer
                : this.state.showWallets
                ? this.toggleWalletsFlyer
                : this.state.showNotificationList
                ? this.toggleNotificationFlyer
                : this.state.showSuspendUser
                ? this.suspendModalToggle
                : this.state.showHistory
                ? this.toggleHistoryFlyer
                : this.state.showTransactions
                ? this.toggleUserTransactionsFlyer
                : this.state.showAddCreditModal
                ? this.toggleAddCreditRewardFlyer
                : this.state.showChangeWallet
                ? this.toggleChangeWalletFlyer
                : this.state.showIP
                ? this.toggleIPFlyer
                : this.state.showMultipleAccounts
                ? this.toggleMultipleAccountsFlyer
                : this.state.showUserPayments
                ? this.toggleUserPaymentsFlyer
                : this.state.showDeductCreditModal
                ? this.toggleDeductCreditRewardFlyer
                : this.toggleEditFlyer
            }
          ></div>
        )}
        <SuspendUserFlyer
          showModal={this.state.showSuspendUser}
          toggleModal={this.suspendModalToggle}
          handleSuspendUser={this.handleSuspendUser}
        />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadUsers: (pramas, fresh, callback) => dispatch(loadUsers(pramas, fresh, callback)),
  loadUsersToExport: (pramas, callback) => dispatch(loadUsersToExport(pramas, callback)),
  updateStatus: (data, id, callback) => dispatch(updateStatus(data, id, callback)),
  updateUserStatusReceived: (payLoad) => dispatch(updateUserStatusReceived(payLoad)),
  updateKycStatus: (id, callback) => dispatch(updateKycStatus(id, callback)),
  loadUserTicketBalance: (params, callback) => dispatch(loadUserTicketBalance(params, callback)),
  loadSettings: (callback) => dispatch(loadSettings(callback)),
});
const mapStateToProps = (state) => ({
  getUsers: getUsers(state),
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserListing));
