import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import backIcon from "include/images/chevron-left.svg";
import cowitzerGameImg from "include/images/cowitzer.png";
import loadingIcon from "include/images/loader-grey.svg";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getDashboard, loadCowitzerDashboard, loadCowitzerTopLosers, loadCowitzerTopWinners } from "store/dashboard";
import ViewAllFlyer from "../flyers/viewAllFlyer";

const CowitzerStats = (props) => {
  const ref = useRef();
  const [type, setType] = useState("winner");
  const [showViewAll, setShowViewAll] = useState(false);
  const [loadingMain, setLoadingMain] = useState(true);
  useEffect(() => {
    props.loadCowitzerDashboard(() => setLoadingMain(false));
    props.loadCowitzerTopWinners({ page: 1 });
    props.loadCowitzerTopLosers({ page: 1 });
  }, []);
  const {
    cowitzerDashboard,
    cowitzerTopWinners,
    cowitzerTopWinnersLoading,
    cowitzerTopWinnersPagination,
    cowitzerTopLosers,
    cowitzerTopLosersLoading,
    cowitzerTopLosersPagination,
  } = props.getDashboard;

  useEffect(() => {
    if (!loadingMain) ref?.current?.complete();
    else ref?.current?.continuousStart();
  }, [loadingMain]);

  const toggleViewAll = (e, type) => {
    e.preventDefault();
    if (type) setType(type);
    setShowViewAll(!showViewAll);
  };

  return (
    <>
      <Header />
      <Loader loaderRef={ref} />
      <div className="page-wrapper add-dev-server-wrapper">
        <SideBar page="dashboard" />
        {!loadingMain && (
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Cowitzer Game</h1>
            </div>
            <div className="fwc-wrapper">
              <Link
                to="#"
                className="back-link-btn"
                onClick={(e) => {
                  e.preventDefault();
                  props.history.goBack();
                }}
              >
                <img src={backIcon} alt="" /> Back
              </Link>
              <div className="grid-card-details-row d-flex flex-wrap">
                <div className="gcdr-img-box">
                  <div className="gcdr-img-outer text-white">
                    <div className="gcdr-image">
                      <img src={cowitzerGameImg} alt="" />
                    </div>
                  </div>
                </div>
                <div className="gcdr-text-box">
                  <div className="gcdr-heading d-flex align-items-center justify-content-between">
                    <h2>Cowitzer Game</h2>
                  </div>

                  <div className="gcdr-widget-wrapper mt-2">
                    <div className="gcdr-widget-box">
                      <ul className="gw-list d-flex flex-wrap">
                        <li>
                          <div className="gw-box">
                            <em>Total Games</em>
                            <h6>{cowitzerDashboard?.totalGames?.toLocaleString("en-us") ?? 0}</h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Available Games</em>
                            <h6>{cowitzerDashboard?.availableGames?.toLocaleString("en-us") ?? 0}</h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Completed Games</em>
                            <h6>{cowitzerDashboard?.completedGames?.toLocaleString("en-us") ?? 0}</h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Total Bet Amount</em>
                            <h6>
                              {cowitzerDashboard?.totalBetAmount?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) ?? 0}
                            </h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>Total Amount Won</em>
                            <h6>
                              {cowitzerDashboard?.totalWinningAmount?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) ?? 0}
                            </h6>
                          </div>
                        </li>
                        <li>
                          <div className="gw-box">
                            <em>BG Profit</em>
                            <h6>
                              {cowitzerDashboard?.BGProfit?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) ?? 0}
                            </h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="gcdr-widget-box">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>Top 10 Winners</h4>{" "}
                        <Link to="#" className="back-link-btn m-0" onClick={(e) => toggleViewAll(e, "winner")}>
                          View all
                        </Link>
                      </div>
                      <div className="table-responsive">
                        <table className="table custom-table-striped">
                          <thead>
                            <tr>
                              <th>Username</th>
                              <th>No of Times Bets Placed</th>
                              <th>Loot Bucks Spent</th>
                              <th>Loot Bucks Won</th>
                              <th>Profit/Loss</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cowitzerTopWinners?.length > 0 &&
                              cowitzerTopWinners?.map((winner, index) => (
                                <tr key={winner?._id}>
                                  <td>{winner?.user?.userName}</td>
                                  <td>{winner?.totalBetsPlaced}</td>
                                  <td>
                                    {winner?.totalBetAmount?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                  <td>
                                    {winner?.totalWinningAmount?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                  <td>
                                    {winner?.profitDifference?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {cowitzerTopWinners?.length === 0 &&
                          (cowitzerTopWinnersLoading ? (
                            <div className="w-100 d-flex justify-content-center load-more">
                              <img src={loadingIcon} className="fa-spin" alt="" />
                            </div>
                          ) : (
                            <div className="nfr-box">No Records Found!</div>
                          ))}
                      </div>
                    </div>
                    <div className="gcdr-widget-box">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>Top 10 Losers</h4>
                        <Link to="#" className="back-link-btn m-0" onClick={(e) => toggleViewAll(e, "loser")}>
                          View all
                        </Link>
                      </div>
                      <div className="table-responsive">
                        <table className="table custom-table-striped">
                          <thead>
                            <tr>
                              <th>Username</th>
                              <th>No of Times Bets Placed</th>
                              <th>Loot Bucks Spent</th>
                              <th>Loot Bucks Won</th>
                              <th>Profit/Loss</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cowitzerTopLosers?.length > 0 &&
                              cowitzerTopLosers?.slice(0, 10)?.map((loser, index) => (
                                <tr key={loser?._id}>
                                  <td>{loser?.user?.userName}</td>
                                  <td>{loser?.totalBetsPlaced}</td>
                                  <td>
                                    {loser?.totalBetAmount?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                  <td>
                                    {loser?.totalWinningAmount?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                  <td>
                                    {loser?.profitDifference?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) ?? 0}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {cowitzerTopLosers?.length === 0 &&
                          (cowitzerTopLosersLoading ? (
                            <div className="w-100 d-flex justify-content-center load-more">
                              <img src={loadingIcon} className="fa-spin" alt="" />
                            </div>
                          ) : (
                            <div className="nfr-box">No Records Found!</div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ViewAllFlyer
        type={type}
        players={type === "winner" ? cowitzerTopWinners : cowitzerTopLosers}
        loadMore={type === "winner" ? props.loadCowitzerTopWinners : props.loadCowitzerTopLosers}
        pagination={type === "winner" ? cowitzerTopWinnersPagination : cowitzerTopLosersPagination}
        showModal={showViewAll}
        toggleModal={toggleViewAll}
      />

      {showViewAll && <div className="modal-backdrop show fade" onClick={toggleViewAll}></div>}
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  loadCowitzerDashboard: (callback) => dispatch(loadCowitzerDashboard(callback)),
  loadCowitzerTopWinners: (pramas, fresh, callback) => dispatch(loadCowitzerTopWinners(pramas, fresh, callback)),
  loadCowitzerTopLosers: (pramas, fresh, callback) => dispatch(loadCowitzerTopLosers(pramas, fresh, callback)),
});
const mapStateToProps = (state) => ({
  getDashboard: getDashboard(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CowitzerStats));
