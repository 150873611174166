import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "reward",
  initialState: {
    rewards: [],
    filteredCount: null,
    totalCount: null,
    rewardInfo: null,
    options: [],
  },
  reducers: {
    rewardsRequested: (misc, action) => {
      misc.loading = true;
    },
    rewardsReceived: (misc, action) => {
      misc.rewards = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    deleteRewardsReceived: (misc, action) => {
      const ind = misc.rewards.findIndex((quest) => quest.id === action.payload.id);
      const newRewards = misc.rewards;
      newRewards.splice(ind, 1);
      misc.totalCount = misc.totalCount - 1;
      misc.rewards = [...newRewards];
    },
    rewardsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    rewardInfoRequested: (misc, action) => {
      misc.loading = true;
    },
    rewardInfoReceived: (misc, action) => {
      misc.rewardInfo = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    rewardInfoRequestFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  rewardsRequested,
  rewardsReceived,
  rewardsRequestFailed,
  optionsRequested,
  optionsReceived,
  optionsRequestFailed,
  rewardInfoRequested,
  rewardInfoReceived,
  rewardInfoRequestFailed,
  deleteRewardsReceived,
} = slice.actions;
export default slice.reducer;

// Action Creators
const rewardUrl = "reward/";

export const loadRewards = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: rewardUrl,
      params,
      onStart: rewardsRequested.type,
      onSuccess: rewardsReceived.type,
      onError: rewardsRequestFailed.type,
      callback,
    }),
  );
};
export const loadRewardInfo = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: rewardUrl,
      onStart: rewardInfoRequested.type,
      onSuccess: rewardInfoReceived.type,
      onError: rewardInfoRequestFailed.type,
      callback,
    }),
  );
};
export const addReward = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: rewardUrl,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateReward = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: rewardUrl + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const uploadImage = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: rewardUrl + "uploadImage/",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const deleteReward = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: rewardUrl + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const getReward = createSelector(
  (state) => state.entities.reward,
  (reward) => reward,
);
