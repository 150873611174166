export const setToken = (refresh, auth) => {
  if (refresh) localStorage.setItem("x-refresh-token", refresh);
  localStorage.setItem("x-auth-token", auth);
};

export const getToken = (type) => {
  if (type === "refresh") return localStorage.getItem("x-refresh-token");
  if (type === "auth") return localStorage.getItem("x-auth-token");
  return false;
};

export const clearAllToken = () => {
  localStorage.clear();
};

export const setFilters = (key, data) => {
  localStorage.setItem(key, data);
};
export const clearFilters = (key) => {
  localStorage.removeItem(key);
};
export const getFilters = (key) => {
  return JSON.parse(localStorage.getItem(key));
};
export const setProfile = (data) => {
  localStorage.setItem("data", JSON.stringify(data));
};
export const getProfile = () => {
  return JSON.parse(localStorage.getItem("data"));
};
