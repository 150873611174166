import React from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import {
  getSettings,
  loadContestTemplate,
  addContestTemplate,
  updateContestTemplate,
  addContestTemplateReceived,
  // updateContestTemplateReceived,
} from "store/settings";
import { getGame, loadBGGames } from "store/games";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Joi from "joi-browser";
import _ from "lodash";
import Amplify, { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import plusIcon from "include/images/plus-1.svg";
import deleteIcon from "include/images/delete-icon.svg";

//  Components
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";
import SelectSearch from "common/form/selectSearch";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});
class AddFlyer extends Form {
  fileInputRef = React.createRef(null);
  state = {
    data: {
      name: "",
      contestTemplateType: 1,
      contestTitle: "",
      image: null,
      bgGame: {},

      // Arcade
      xPToBeAwarded: 1,
      entryFeeArcadeCoins: 0,
      arcadeContestTitle: "",
      arcadeContestDescription: "",

      // Loots
      ticketsToBeAwarded: 1,
      entryFeeLootBonusCoins: 0,
      lootContestTitle: "",
      lootContestDescription: "",
    },
    id: "",
    edit: false,
    errors: {},
    loadingImages: false,
    loadingContest: false,
    bgGamesLoading: false,
    imageChange: false,
    imageChangeLoot: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal) {
        this.setState({ bgGamesLoading: true });
        this.props.loadBGGames({ isActive: true, isComingSoon: false }, (res) => {
          if (this.props.edit) {
            const data = { ...this.state.data };
            data.name = this.props.edit.name;
            data.contestTitle = this.props.edit.contestTitle;
            data.image = this.props.edit.image;
            const bgGame = res?.data?.data?.find((item) => item.id === this.props.edit.bgGameId);
            data.bgGame = {
              name: bgGame.name,
              value: bgGame.id,
              data: bgGame,
            };
            data.contestTemplateType = this.props.edit?.contestTemplateType;

            //  Arcade Coins
            data.entryFeeArcadeCoins = this.props.edit.entryFeeArcadeCoins;
            data.xPToBeAwarded = this.props.edit.xPToBeAwarded ? this.props.edit.xPToBeAwarded.toString() : "";
            data.arcadeContestTitle = this.props.edit?.arcadeContestTitle ?? "";
            data.arcadeContestDescription = this.props.edit?.arcadeContestDescription ?? "";

            //  Loot Bucks
            data.entryFeeLootBonusCoins = this.props.edit.entryFeeLootBonusCoins;
            data.ticketsToBeAwarded = this.props.edit.ticketsToBeAwarded
              ? this.props.edit.ticketsToBeAwarded.toString()
              : "";
            data.lootContestTitle = this.props.edit?.lootContestTitle ?? "";
            data.lootContestDescription = this.props.edit?.lootContestDescription ?? "";

            this.setState({ data, bgGamesLoading: false, edit: true, id: this.props.edit.id });
          } else {
            this.setState({ bgGamesLoading: false });
          }
        });
      }
    }
  };
  doSubmit = (e) => {
    this.setState({ loadingTemplate: true });
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };

    if (_.isEmpty(data.mode)) errors.mode = "Mode is required";
    const payLoad = {
      name: data.name,
      contestTitle: data.contestTitle,
      contestTemplateType: data.contestTemplateType,
      bgGameId: data.bgGame?.value,
    };

    //  Arcade
    if (this.state.data.contestTemplateType === 1 || this.state.data.contestTemplateType === 2) {
      payLoad.entryFeeArcadeCoins = parseInt(data.entryFeeArcadeCoins);
      payLoad.xPToBeAwarded = data.xPToBeAwarded ? parseInt(data.xPToBeAwarded) : 0;
      payLoad.arcadeContestDescription = data.arcadeContestDescription?.trim();
      payLoad.arcadeContestTitle = data.arcadeContestTitle;
    }

    //  Loot
    if (this.state.data.contestTemplateType === 1 || this.state.data.contestTemplateType === 3) {
      payLoad.entryFeeLootBonusCoins = parseFloat(data.entryFeeLootBonusCoins);
      payLoad.ticketsToBeAwarded = data.ticketsToBeAwarded ? parseInt(data.ticketsToBeAwarded) : 0;
      payLoad.lootContestDescription = data.lootContestDescription?.trim();
      payLoad.lootContestTitle = data.lootContestTitle;
    }

    payLoad.image = !_.isEmpty(data.image)
      ? this.props.edit && !this.state.imageChange
        ? data.image
        : data.image.medium
      : null;

    if (this.props.edit) {
      this.props.updateContestTemplate(payLoad, this.state.id, (contestResponse) => {
        this.setState({ loadingTemplate: false });
        if (contestResponse.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.addContestTemplateReceived(contestResponse?.data?.data);
          this.props.toggleModal();
        } else {
          toast(<AlertError message={contestResponse.data && contestResponse.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      this.props.addContestTemplate(payLoad, (contestResponse) => {
        this.setState({
          loadingTemplate: false,
        });
        if (contestResponse.status === 200) {
          this.props.addContestTemplateReceived(contestResponse?.data?.data);
          toast(<AlertSuccess message="Information Saved" />);
          this.props.toggleModal();
        } else {
          toast(<AlertError message={contestResponse.data && contestResponse.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };
  schema = {
    name: Joi.string().max(500).required(),
    contestTitle: Joi.string().required(),
    image: Joi.object().required(),
    bgGame: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
    }),
    contestTemplateType: Joi.number().required(),

    //  Arcade
    arcadeContestDescription: Joi.when("contestTemplateType", {
      is: Joi.valid(1, 2),
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    arcadeContestTitle: Joi.when("contestTemplateType", {
      is: Joi.valid(1, 2),
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    entryFeeArcadeCoins: Joi.when("contestTemplateType", {
      is: Joi.valid(1, 2),
      then: Joi.number().min(0).required(),
      otherwise: Joi.number().min(0).allow(0),
    }),
    xPToBeAwarded: Joi.when("contestTemplateType", {
      is: Joi.valid(1, 2),
      then: Joi.number().min(1).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),

    //  Loot
    lootContestTitle: Joi.when("contestTemplateType", {
      is: Joi.valid(1, 3),
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    entryFeeLootBonusCoins: Joi.when("contestTemplateType", {
      is: Joi.valid(1, 3),
      then: Joi.number().min(0).required(),
      otherwise: Joi.number().min(0).allow(0),
    }),
    lootContestDescription: Joi.when("contestTemplateType", {
      is: Joi.valid(1, 3),
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    ticketsToBeAwarded: Joi.when("contestTemplateType", {
      is: Joi.valid(1, 3),
      then: Joi.alternatives().try(Joi.string(), Joi.number().min(1)).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];

    this.setState({ data, errors });
  };
  handleImageChange = (e, type) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    this.setState({ loadingImages: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ loadingImages: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ loadingImages: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.setState({
          errors,
          loadingImages: false,
          [type === "image" ? "imageChange" : "imageChangeLoot"]: true,
          data: {
            ...this.state.data,
            [type]: { medium: "public/" + result.key },
          },
        });
      });
    }
    this.setState({ data });
    // e.target.value = null;
  };
  onChangeSelect = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        name: "",
        contestTemplateType: 1,
        contestTitle: "",
        image: null,
        bgGame: {},

        // Arcade
        xPToBeAwarded: 1,
        entryFeeArcadeCoins: 0,
        arcadeContestTitle: "",
        arcadeContestDescription: "",

        // Loots
        ticketsToBeAwarded: 1,
        entryFeeLootBonusCoins: 0,
        lootContestTitle: "",
        lootContestDescription: "",
      },
      errors: {},
      loadingTemplate: false,
    });
  };
  onTypeChange = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    data.contestTemplateType = Number(e.target.name);
    this.setState({ data, errors });
  };
  render() {
    const { bgGames } = this.props.getGame;
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>
            {this.props.edit ? (this.props.type === "contest" ? "" : "Edit ") : "Add "}
            Contest Template
          </h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!this.state.bgGamesLoading && (
            <div className="form-check-outer">
              <div className="new-form-group mb-0">
                <div className="row">
                  <div className="col-12 new-form-group">{this.renderInput("name", "Title", "", "")}</div>
                  <div className="col-12 new-form-group">
                    {this.renderInput("contestTitle", "Contest Title", "", "")}
                  </div>
                  <div className="col-12 new-form-group">
                    <div className=" d-flex align-items-center justify-content-between">
                      <label className="form-label">BG Game</label>
                    </div>
                    <SelectSearch
                      name="bgGame"
                      options={_.sortBy(bgGames, ["name"]).map((game) => ({
                        name: game.name,
                        value: game.id,
                        data: game,
                      }))}
                      label="Select BG Game"
                      value={this.state.data.bgGame}
                      error={this.state.errors.bgGame}
                      onChange={this.onChangeSelect}
                      isLoading={this.state.bgGamesLoading}
                    />
                  </div>
                  <div className="new-form-group form-check-outer">
                    <label className="form-label">Contest Type</label>
                    <div className="form-check-group full-width-row d-flex align-items-center flex-wrap">
                      <>
                        <div className="form-check-item">
                          <div className="form-check has-circle-radio">
                            <input
                              className={`form-check-input ${this.state.errors.type && "border-error"}`}
                              type="radio"
                              name={1}
                              id="radioCheckOne"
                              checked={this.state.data.contestTemplateType === 1}
                              onChange={this.onTypeChange}
                              disabled={this.state.loadingSlide || this.props.edit}
                            />
                            <label className="form-check-label" htmlFor="radioCheckOne">
                              Both
                            </label>
                          </div>
                        </div>
                        <div className="form-check-item">
                          <div className="form-check has-circle-radio">
                            <input
                              className={`form-check-input ${this.state.errors.type && "border-error"}`}
                              type="radio"
                              name={2}
                              id="radioCheckTwo"
                              checked={this.state.data.contestTemplateType === 2}
                              onChange={this.onTypeChange}
                              disabled={this.state.loadingSlide || this.props.edit}
                            />
                            <label className="form-check-label" htmlFor="radioCheckTwo">
                              Arcade
                            </label>
                          </div>
                        </div>
                        <div className="form-check-item">
                          <div className="form-check has-circle-radio">
                            <input
                              className={`form-check-input ${this.state.errors.type && "border-error"}`}
                              type="radio"
                              name={3}
                              id="radioCheckThree"
                              checked={this.state.data.contestTemplateType === 3}
                              onChange={this.onTypeChange}
                              disabled={this.state.loadingSlide || this.props.edit}
                            />
                            <label className="form-check-label" htmlFor="radioCheckThree">
                              Loot
                            </label>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>

                  {/* Arcade  */}
                  {this.state.data.contestTemplateType !== 3 && (
                    <fieldset>
                      <div className="fwc-inner">
                        <h1 className="ict-header">Arcade Contest</h1>
                        <fieldset className="row fieldset">
                          <div className="col-12 my-2">
                            <div className="fml-box">{this.renderInput("arcadeContestTitle", "Arcade Title")}</div>
                          </div>
                          <div className="col-12 my-2">
                            <div className="fml-box">
                              {this.renderInput("entryFeeArcadeCoins", "Entry Fee (Arcade Coins)", "", "number")}
                            </div>
                          </div>
                          <div className="col-12 my-2">
                            <div className="fml-box">
                              {this.renderInput("xPToBeAwarded", "XP to be awarded", "", "number")}
                            </div>
                          </div>
                          <div className="col-12 my-2">
                            <div className="fml-box">
                              {this.renderTextarea("arcadeContestDescription", "Arcade Description", "", "")}
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </fieldset>
                  )}
                  {/* Loot */}
                  {this.state.data.contestTemplateType !== 2 && (
                    <fieldset>
                      <div className="fwc-inner">
                        <h1 className="ict-header">Loot Contest</h1>
                        <fieldset className="row fieldset">
                          <div className="col-12 my-2">
                            <div className="fml-box">{this.renderInput("lootContestTitle", "Loot Title")}</div>
                          </div>
                          <div className="col-12 my-2">
                            <div className="fml-box">
                              {this.renderInput("entryFeeLootBonusCoins", "Entry Fee (Loot Bucks)", "", "number")}
                            </div>
                          </div>
                          <div className="col-12 my-2">
                            <div className="fml-box">
                              {this.renderInput("ticketsToBeAwarded", "Tickets to be awarded", "", "number")}
                            </div>
                          </div>
                          <div className="col-12 my-2">
                            <div className="fml-box">
                              {this.renderTextarea("lootContestDescription", "Loot Description", "", "")}
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </fieldset>
                  )}
                </div>
              </div>
              <div className="new-form-group">
                <label htmlFor="" className="form-label">
                  Contest Image
                </label>
                <div className="kt-upload-row d-flex flex-wrap align-items-start">
                  <div
                    className={`kt-upload-outer ${
                      !this.state.data.image ? "placeholder-uploaded-img" : " position-relative"
                    } ${this.state.errors.image && "border-error"}`}
                  >
                    <div className="kt-upload-img">
                      <img
                        src={
                          this.state.data.image
                            ? this.state.data.image.medium
                              ? this.state.data.image.medium.includes("https://")
                                ? this.state.data.image.medium
                                : process.env.REACT_APP_CDN + this.state.data.image.medium
                              : process.env.REACT_APP_CDN + this.state.data.image
                            : plusIcon
                        }
                        alt=""
                      />
                    </div>{" "}
                    {this.state.data.image && (
                      <div
                        className="delete-icon d-flex align-items-center justify-content-center"
                        onClick={(e) => {
                          const data = this.state.data;
                          data.image = null;
                          this.setState({ data });
                        }}
                      >
                        <img src={deleteIcon} alt="delete" />
                      </div>
                    )}
                  </div>
                  <div className="kt-file-input">
                    <label htmlFor="formFile" className="kt-label">
                      {this.state.data.image ? " Change Image" : "+ Upload an Image"}
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      id="formFile"
                      onChange={(e) => {
                        this.handleImageChange(e, "image");
                      }}
                    />
                  </div>
                </div>
              </div>{" "}
            </div>
          )}
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loadingTemplate || this.state.loadingMainImages || this.state.loadingImages}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addContestTemplate: (data, callback) => dispatch(addContestTemplate(data, callback)),
  updateContestTemplate: (id, data, callback) => dispatch(updateContestTemplate(id, data, callback)),
  loadContestTemplate: (params, callback) => dispatch(loadContestTemplate(params, callback)),
  loadBGGames: (params, callback) => dispatch(loadBGGames(params, callback)),
  addContestTemplateReceived: (payLoad) => dispatch(addContestTemplateReceived(payLoad)),
  // updateContestTemplateReceived: (payLoad) => dispatch(updateContestTemplateReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getGame: getGame(state),
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFlyer));
