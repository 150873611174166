import React, { Fragment, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getGame,
  loadGames,
  markAsIllegitimate,
  refundGame,
  markAsIllegitimateReceived,
  markAsRefundedReceived,
  selectedGame,
} from "store/games";
import { getContest, loadContests } from "store/contest";
import { Popover, OverlayTrigger, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import _ from "lodash";

//  Images
import emptyIcon from "include/images/nrf.svg";
import carrentIcon from "include/images/carrent-icon.svg";
import closeIcon from "include/images/close-icon.svg";
import filterIcon from "include/images/filter-icon.svg";
import userIcon from "include/images/user.png";
import arrowIcon from "include/images/arrow-down-icon.svg";
import arrowIcon1 from "include/images/arrow-down-icon-1.svg";
import historyIcon from "include/images/history-icon.svg";
import arrowIcon2 from "include/images/arrow-down-icon-2.svg";
import loadingIcon from "include/images/loader-grey.svg";

//  Components
import Form from "common/form/form";
import Header from "common/header";
import NextButton from "common/form/submitButton";
import SideBar from "common/sidebar";
import Loader from "common/loader";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";
import SelectSearchAsync from "common/form/selectSearchAsync";
import contest from "store/contest";
import AlertModal from "common/alert/alertModal";
import ReasonFlyer from "./reasonFlyer";
import moment from "moment";
import { getFilters } from "utils/localStorageServices";
import HistoryFlyer from "common/historyFlyer";
import GameRoundsFlyer from "./roundsFlyer";
import { formatDate } from "utils/dateConversion";

class GamesListing extends Form {
  ref = createRef(null);
  scrollRef = createRef(null);
  state = {
    data: {
      userName: "",
      email: "",
      minScore: null,
      maxScore: null,
      usedHint: false,
      usedRemoveOne: false,
      usedPointBoost: false,
      contest: "",
      user: "",
      isIllegitimate: false,
      isRefunded: false,
      contestStatus: 1,
    },

    showReasonFlyer: false,
    showRoundsFlyer: false,
    loadingReason: false,
    gameId: "",
    showFilters: false,
    loading: false,
    loadingReset: false,
    appliedFilters: {},
    errors: {},
    contestsLoading: false,
    reasonType: "",
    showHistory: false,
    objectId: "",
    object: null,
    showRevertModal: false,
    game: null,
    selectedGameIndex: "",
    currKey: "",
    loadingMore: false,
  };

  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showReasonFlyer: !this.state.showReasonFlyer });
  };
  roundsModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showRoundsFlyer: !this.state.showRoundsFlyer });
  };
  revertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showRevertModal: !this.state.showRevertModal });
  };
  handleMarkAsIllegitimate = (e, reason, callback) => {
    if (e) e.preventDefault();
    this.setState({ loadingReason: true });
    const locationParams = new URLSearchParams(this.props.location.search);
    const amountType = locationParams.get("amountType") ?? 1;
    this.props.markAsIllegitimate(
      this.state.gameId?.contest?.id,
      this.state.gameId.userId,
      {
        reason,
        markAsIllegitimate: true,
        amountType: parseInt(amountType),
      },
      (gameRes) => {
        if (gameRes.status === 200) {
          toast(<AlertSuccess message="Information Saved!" />, {
            containerId: 1,
          });
          this.props.markAsIllegitimateReceived({
            selectedGameIndex: this.state.selectedGameIndex,
            gameId: this.state.gameId.gameId,
            isIllegitimate: this.state.gameId.isIllegitimate,
            reason: reason,
          });
          // this.searchFilters();
          callback();
          this.setState({ loadingReason: false });
        } else {
          toast(<AlertError message={gameRes.data && gameRes.data.message} />, {
            containerId: 1,
          });
          callback();
          this.setState({ loadingReason: false });
        }
      },
    );
  };
  handleRefund = (e, reason, callback) => {
    if (e) e.preventDefault();
    this.setState({ loadingReason: true });
    this.props.refundGame(this.state.gameId?.contest?.id, this.state.gameId.userId, { reason }, (gameRes) => {
      if (gameRes.status === 200) {
        toast(<AlertSuccess message="Information Saved!" />, {
          containerId: 1,
        });
        this.setState({ loadingReason: false });
        this.props.markAsRefundedReceived({
          selectedGameIndex: this.state.selectedGameIndex,
          gameId: this.state.gameId.gameId,
          isRefunded: this.state.gameId.isRefunded,
          reason: reason,
        });
        callback();
      } else {
        toast(<AlertError message={gameRes.data && gameRes.data.message} />, {
          containerId: 1,
        });
        callback();
        this.setState({ loadingReason: false });
      }
    });
  };
  handleLoadMore = (entries) => {
    const target = entries[0];
    if (target?.isIntersecting) {
      const { games, totalCount } = this.props.getGame;

      if (!this.state.loadingMore && games?.length < totalCount) {
        this.setState({ loadingMore: true });
        this.searchFilters();
        this.observer.disconnect();
      }
    }
  };
  observer = new IntersectionObserver(this.handleLoadMore, {
    threshold: 0.2,
  });
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getGame.loading && this.props.getGame.loading !== prevProps.getGame.loading)
      this.ref.current.complete();

    if (this.props.getGame.loading && this.props.getGame.loading !== prevProps.getGame.loading)
      this.ref.current.continuousStart();

    const params = new URLSearchParams(this.props.location.search);
    const prevParams = new URLSearchParams(prevProps.location.search);
    const amountType = params.get("amountType");
    const prevAmountType = prevParams.get("amountType");
    if (amountType !== prevAmountType) {
      this.clearStates(() => {
        this.setState({ loading: true });
        this.props.loadGames({ amountType }, true, () => {
          this.setState({ loading: false });
        });
      });
    }
    if (this.state.currKey !== prevState.currKey && this.state.currKey !== null) {
      if (this.observer && this.scrollRef?.current) {
        this.observer.observe(this.scrollRef.current);
      }
    }
  };
  onRefChange = (node, games, i, totalCount) => {
    if (i === games.length - 1 && games.length < totalCount) {
      this.scrollRef.current = node;
    }
  };
  clearStates = (callback) => {
    this.setState(
      {
        data: {
          userName: "",
          email: "",
          minScore: null,
          maxScore: null,
          usedHint: false,
          usedRemoveOne: false,
          usedPointBoost: false,
          contest: "",
          user: "",
          isIllegitimate: false,
          isRefunded: false,
          contestStatus: 1,
        },

        showReasonFlyer: false,
        showRoundsFlyer: false,
        loadingReason: false,
        gameId: "",
        showFilters: false,
        loading: false,
        loadingReset: false,
        appliedFilters: {},
        errors: {},
        contestsLoading: false,
        reasonType: "",
        showHistory: false,
        objectId: "",
        object: null,
        showRevertModal: false,
        game: null,
        selectedGameIndex: "",
        currKey: "",
        loadingMore: false,
      },
      callback,
    );
  };

  componentDidMount = () => {
    this.setState({ loading: true });
    const filters = getFilters(`paid-games`);
    const { data } = filters || {};
    if (data) {
      this.setState({ data }, () => {
        this.searchFilters();
      });
    } else {
      this.searchFilters();
    }
    this.loadContestsOptions();
  };
  loadContestsOptions = (keyword) => {
    this.setState({ contestsLoading: true });
    this.props.loadContests({ status: this.state.data.contestStatus === 1 ? "Running" : "Ended" }, true, () => {
      this.setState({ contestsLoading: false });
    });
  };
  schema = {
    userName: Joi.string().allow(""),
    email: Joi.string().allow(""),
    user: Joi.string().allow(""),
    contestStatus: Joi.number(),
    minScore: Joi.number().allow(["", null]),
    amountType: Joi.number().allow(["", null]),
    maxScore: Joi.number().allow(["", null]),
    usedHint: Joi.boolean().allow(false),
    usedPointBoost: Joi.boolean().allow(false),
    usedRemoveOne: Joi.boolean().allow(false),
  };
  toggleHistoryFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showHistory: !this.state.showHistory });
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    if (e.name === "contestStatus") data.contest = "";
    this.setState({ data, errors });
  };
  searchFilters = (e) => {
    if (e) e.preventDefault();
    const locationParams = new URLSearchParams(this.props.location.search);
    const amountType = locationParams.get("amountType") ?? 1;
    this.setState({ loadingFilters: true });
    const errors = { ...this.state.errors };
    const {
      userName,
      email,
      maxScore,
      minScore,

      contest,
    } = this.state.data;

    if ((parseInt(maxScore) || parseInt(maxScore) === 0) && (parseInt(minScore) || parseInt(minScore) === 0)) {
      if (parseInt(maxScore) <= parseInt(minScore)) {
        errors.maxScore = "Max Score should be greater than Min Score";
        return this.setState({ errors, loadingFilters: false });
      }
    }
    const payLoad = {
      userName,
      maxScore: parseInt(maxScore),
      minScore: parseInt(minScore),
      contest: contest?.value,
      email,
      amountType,
    };
    var params = _.pickBy(payLoad, function (value, key) {
      return value !== "" && value;
    });
    if (this.state.currKey) params.lastKey = this.state.currKey;
    this.props.loadGames(params, this.state.currKey?.length === 0, (gamesResponse) => {
      this.setState({
        loadingFilters: false,
        loading: false,
        loadingMore: false,
      });
      if (gamesResponse.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).forEach((key) => {
          if ((!_.isEmpty(data[key]) || data[key]) && key !== "contestStatus") {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({ appliedFilters, currKey: gamesResponse?.data?.lastKey });
      }
    });
  };
  resetFilters = (e) => {
    e.preventDefault();
    this.setState(
      {
        data: {
          ...this.state.data,
          userName: "",
          email: "",
          minScore: "",
          maxScore: "",
          usedHint: false,
          usedRemoveOne: false,
          usedPointBoost: false,
          isIllegitimate: false,
          isRefunded: false,
          contest: "",
          contestStatus: 1,
        },
        currKey: "",
      },
      () => this.searchFilters(),
    );
  };
  removeFilter = (e, type) => {
    const data = this.state.data;
    if (type === "usedHint" || type === "usedRemoveOne" || type === "usedPointBoost") {
      data[type] = false;
    } else if (type === "userName" || type === "email") {
      data[type] = "";
    } else {
      data[type] = null;
    }
    this.setState({ data, currKey: "" }, () => this.searchFilters());
  };

  render() {
    const { games, totalCount } = this.props.getGame;
    const locationParams = new URLSearchParams(this.props.location.search);
    const amountType = locationParams.get("amountType") ?? 1;

    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="games" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>{amountType == 1 ? "Arcade Games" : "Loot Bucks Games"}</h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                {" "}
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState(
                        {
                          objectId: "",
                          object: null,
                        },
                        () => {
                          this.toggleHistoryFlyer();
                        },
                      );
                    }}
                  >
                    <img src={historyIcon} alt="" /> <span>History</span>
                  </a>
                </div>
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showFilters: !this.state.showFilters });
                    }}
                  >
                    <img src={filterIcon} alt="" /> <span>Filters</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="fwc-wrapper">
              {this.state.showFilters && (
                <div className="fwc-head ">
                  <div className="fwc-inner">
                    <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("userName", "Username")}</div>
                      </li>
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("email", "Email")}</div>
                      </li>
                      <li className="flex-fill">
                        <div className="fml-box">
                          <label className="form-label">Score</label>
                          <div className="fml-input-group d-flex align-items-center">
                            <div className="fml-input-group-item">
                              {this.renderInput("minScore", "", "Min", "number")}
                            </div>
                            <div className="fml-input-group-item">
                              {this.renderInput("maxScore", "", "Max", "number")}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="flex-fill">
                        <div className="row">
                          <label className="form-label">Contest Status</label>
                          <div className="form-check-group full-width-row d-flex align-items-center flex-wrap">
                            <div className="form-check-item">
                              <div className="form-check has-circle-radio">
                                <input
                                  className={`form-check-input`}
                                  type="radio"
                                  name="contestStatus"
                                  id="radioCheckOne"
                                  checked={this.state.data?.contestStatus === 1}
                                  onChange={() => this.onChange({ name: "contestStatus", currentTarget: 1 })}
                                />
                                <label className="form-check-label" htmlFor="radioCheckOne">
                                  Running
                                </label>
                              </div>
                            </div>
                            <div className="form-check-item ms-0">
                              <div className="form-check has-circle-radio">
                                <input
                                  className={`form-check-input`}
                                  type="radio"
                                  name="contestStatus"
                                  id="radioCheckTwo"
                                  checked={this.state.data?.contestStatus === 2}
                                  onChange={() => this.onChange({ name: "contestStatus", currentTarget: 2 })}
                                />
                                <label className="form-check-label" htmlFor="radioCheckTwo">
                                  Past
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="new-form-group">
                          <label className="form-label">Contest</label>
                          <div onClick={this.loadContestsOptions}>
                            <SelectSearchAsync
                              name="contest"
                              options={this.props.getContest.contests.map((contest) => ({
                                name: `${contest.title} - ${formatDate(contest.startDate, "MM/DD/YYYY hh:mm A")} EST`,
                                value: contest.id,
                                data: contest,
                              }))}
                              label="Select Contest"
                              value={this.state.data.contest}
                              error={this.state.errors.contests}
                              onChange={this.onChange}
                              isLoading={this.state.contestsLoading}
                              loadOptions={() => {}}
                            />
                          </div>
                        </div>
                      </li>{" "}
                    </ul>
                    <div className="fwc-btn d-flex align-items-center justify-content-end">
                      <NextButton
                        handleSubmit={this.resetFilters}
                        classData="btn-text pointer"
                        label="Reset"
                        loading={this.state.loadingReset}
                      />
                      <NextButton
                        handleSubmit={() => this.setState({ currKey: "" }, this.searchFilters)}
                        classData="btn btn-primary btn-sm"
                        label="Search"
                        loading={this.state.loadingFilters}
                      />
                    </div>
                  </div>{" "}
                  {!_.isEmpty(this.state.appliedFilters) && (
                    <div className="filter-tag-container d-flex align-items-center ">
                      <div className="fwc-left">
                        <div className="filter-tag-list">
                          <p>filter applied :</p>

                          {Object.keys(this.state.appliedFilters).map((key) => (
                            <div className="filter-tag-item">
                              <span className="camelCase">
                                {key === "minScore"
                                  ? "Min Score"
                                  : key === "maxScore"
                                  ? "Max Score"
                                  : key === "usedHint"
                                  ? "Hint"
                                  : key === "isIllegitimate"
                                  ? "Illegitimate"
                                  : key === "isRefunded"
                                  ? "Refunded"
                                  : key === "userName"
                                  ? "Username"
                                  : key}
                              </span>{" "}
                              :{" "}
                              {(key === "maxScore" || key === "minScore" || key === "userName" || key === "email") &&
                                this.state.appliedFilters[key]}
                              {key === "contest" && this.state.appliedFilters[key]?.name}
                              {(key === "isRefunded" || key === "isIllegitimate") &&
                                (this.state.appliedFilters[key] ? "Yes" : "No")}
                              <a
                                href="!#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (key === "maxScore" || key === "minScore") {
                                    this.setState(
                                      {
                                        data: {
                                          ...this.state.data,
                                          [key]: "",
                                        },
                                      },
                                      this.searchFilters,
                                    );
                                  } else if (key === "isIllegitimate" || key === "isRefunded") {
                                    this.setState(
                                      {
                                        data: {
                                          ...this.state.data,
                                          [key]: false,
                                        },
                                      },
                                      this.searchFilters,
                                    );
                                  } else {
                                    this.setState(
                                      {
                                        data: {
                                          ...this.state.data,
                                          [key]: "",
                                        },
                                      },
                                      this.searchFilters,
                                    );
                                  }
                                }}
                              >
                                <img src={closeIcon} alt="" />
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {games.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th style={{ width: "15%" }}>Action</th>
                            <th>Player</th>
                            <th style={{ width: 400 }}>Contest</th>
                            <th>Highest Score </th>
                            <th>Last Played On</th>
                          </tr>
                        </thead>
                        <tbody>
                          {games &&
                            games.length > 0 &&
                            games.map((game, index) => {
                              const allGames = game?.games;
                              const activeGames = allGames?.filter(
                                (round) => !round.isRefunded && !round.isIllegitimate,
                              );
                              const refundAmount = allGames?.filter((item) => item.refund);
                              return (
                                <tr key={game?.id} ref={(node) => this.onRefChange(node, games, index, totalCount)}>
                                  {(game?.contest?.ticketsAwarded &&
                                    game?.contest?.arcadeCoinsAwarded &&
                                    game?.contest?.xPAwarded) ||
                                  game?.contest?.isCancelled ||
                                  _.isEmpty(activeGames) ? (
                                    <td className="opacity-5">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Popover className="custom-tooltip " data-bs-title="Illegitimate">
                                            <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                              {game?.contest?.ticketsAwarded ? (
                                                <Fragment>
                                                  <h4>Reward Transferred</h4>
                                                </Fragment>
                                              ) : game?.contest?.isCancelled ? (
                                                <Fragment>
                                                  <h4>Contest Cancelled</h4>
                                                </Fragment>
                                              ) : allGames[0]?.refund ? (
                                                <Fragment>
                                                  <h4>
                                                    Refunded on {moment(allGames[0]?.refund?.date).format("MM/DD/YYYY")}{" "}
                                                    at {moment(allGames[0]?.refund?.date).format("hh:mm a")}
                                                  </h4>
                                                  <h4>
                                                    Credits Refunded:{" "}
                                                    {(game?.contest?.entryFee * refundAmount?.length)?.toLocaleString()}
                                                  </h4>
                                                  <h4>
                                                    Reason:
                                                    {allGames[0]?.refund?.reason}
                                                  </h4>
                                                </Fragment>
                                              ) : (
                                                <Fragment>
                                                  <h4>
                                                    Marked as illegitimate on{" "}
                                                    {moment(allGames[0]?.illegitimateDetails?.date).format(
                                                      "MM/DD/YYYY",
                                                    )}{" "}
                                                    at{" "}
                                                    {moment(allGames[0]?.illegitimateDetails?.date).format("hh:mm a")}
                                                  </h4>
                                                  <h4>Reason: {allGames[0]?.illegitimateDetails?.reason}</h4>
                                                </Fragment>
                                              )}
                                            </Popover.Body>
                                          </Popover>
                                        }
                                      >
                                        <div>
                                          <a href="#!" class={`btn btn-outline disabled w-100`}>
                                            {allGames[0]?.isIllegitimate ? "Illegitimate" : "Mark as illegitimate"}
                                          </a>
                                        </div>
                                      </OverlayTrigger>
                                    </td>
                                  ) : (
                                    <td>
                                      <a
                                        href="#!"
                                        class={`btn btn-outline w-100 `}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState(
                                            {
                                              gameId: game,
                                              selectedGameIndex: index,
                                              reasonType: "illegitimate",
                                            },
                                            () => {
                                              if (game.isIllegitimate) {
                                                this.revertModalToggle();
                                              } else {
                                                this.alertModalToggle();
                                              }
                                            },
                                          );
                                        }}
                                      >
                                        Mark as illegitimate
                                      </a>
                                    </td>
                                  )}
                                  <td className="td-img-group d-flex align-items-start">
                                    <div className="td-img round-img m-right-2">
                                      {game.user && game.user.image ? (
                                        <img
                                          src={
                                            process.env.REACT_APP_CDN +
                                            (game.user.image.small || game.user.image.original)
                                          }
                                          alt=""
                                        />
                                      ) : (
                                        <img src={userIcon} alt="" />
                                      )}
                                    </div>
                                    {game.user ? (
                                      <div className="">
                                        <Link
                                          to={{
                                            pathname: `/game-details/${game.gameId}`,
                                            state: { game },
                                          }}
                                          className="orange-text"
                                          target="_blank"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.props.selectedGame(game);
                                            this.setState({
                                              selectedGameIndex: index,
                                            });
                                            this.roundsModalToggle(e);
                                          }}
                                        >
                                          {game.user.userName}
                                        </Link>
                                        <br />
                                        {game.user.email}
                                      </div>
                                    ) : (
                                      <Link
                                        to={{
                                          pathname: `/game-details/${game.gameId}`,
                                          state: { game },
                                        }}
                                        className="orange-text"
                                        target="_blank"
                                      >
                                        {game.userId}
                                      </Link>
                                    )}
                                  </td>
                                  {game.contest ? (
                                    <td>
                                      <Link
                                        className="td-text-box d-flex align-items-center"
                                        to={{
                                          pathname: `/contests/${game.contest && game.contest.slug}`,
                                          state: {
                                            contest: game.contest,
                                            status: "all",
                                          },
                                        }}
                                      >
                                        <div className="td-img margin-right-2 pointer">
                                          {game.contest && game.contest.image && (
                                            <img src={process.env.REACT_APP_CDN + game.contest.image.small} alt="" />
                                          )}
                                        </div>

                                        {game.contest.title}
                                      </Link>
                                    </td>
                                  ) : (
                                    <td>NA</td>
                                  )}
                                  <td>
                                    <div className={`td-text-box`}>
                                      {game.totalScore?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </div>
                                  </td>{" "}
                                  <td>
                                    {allGames[0]?.isIllegitimate ? (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Popover
                                            id="popoverPannel"
                                            className="custom-tooltip "
                                            data-bs-title="Illegitimate"
                                          >
                                            <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                              <h4>Reason for marking illegitimate</h4>
                                              <p>{allGames[0]?.illegitimateDetails?.reason}</p>
                                              <h4>Date</h4>
                                              <p>
                                                {moment(allGames[0]?.illegitimateDetails?.date).format(
                                                  "MM/DD/YYYY hh:mm a",
                                                )}
                                              </p>
                                            </Popover.Body>
                                          </Popover>
                                        }
                                      >
                                        <div
                                          class="td-shape"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          data-bs-custom-class="custom-tooltip"
                                          data-bs-title="Illegitimate"
                                        ></div>
                                      </OverlayTrigger>
                                    ) : allGames[0]?.isRefunded ? (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Popover
                                            id="popoverPannel"
                                            className="custom-tooltip "
                                            data-bs-title="Illegitimate"
                                          >
                                            <Popover.Body className="tooltip-inner tooltip-inner-reason ">
                                              <h4>Reason for refund</h4>
                                              <p>{allGames[0]?.refund?.reason}</p>
                                              <h4>Date</h4>
                                              <p>{moment(allGames[0]?.refund?.date).format("MM/DD/YYYY hh:mm a")}</p>
                                            </Popover.Body>
                                          </Popover>
                                        }
                                      >
                                        <div
                                          class="td-shape yellow-bg"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          data-bs-custom-class="custom-tooltip"
                                          data-bs-title="Illegitimate"
                                        ></div>
                                      </OverlayTrigger>
                                    ) : (
                                      <></>
                                    )}
                                    <div class="small-text">
                                      {moment(game.lastPlayedAt).format("MM/DD/YYYY")}
                                      <br /> <small> {moment(game.lastPlayedAt).format("hh:mm a")}</small>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    )}{" "}
                  </div>
                )}
                {this.state.loadingMore && (
                  <div className="w-100 d-flex justify-content-center load-more">
                    <img src={loadingIcon} className="fa-spin" alt="" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>{" "}
        {this.state.showReasonFlyer && <div className="modal-backdrop show fade" onClick={this.alertModalToggle}></div>}
        <AlertModal
          alertModalToggle={this.revertModalToggle}
          alertModalShow={this.state.showRevertModal}
          title="Alert"
          description={"Are you sure you want to mark this game as legitimate again?"}
          onPress={(e) => {
            this.handleMarkAsIllegitimate(e, "", () => {
              this.revertModalToggle();
            });
          }}
          loading={this.state.loadingReason}
          alertType="Yes"
        />{" "}
        <ReasonFlyer
          showModal={this.state.showReasonFlyer}
          toggleModal={this.alertModalToggle}
          handleSubmit={this.state.reasonType === "refund" ? this.handleRefund : this.handleMarkAsIllegitimate}
          description={
            this.state.reasonType === "refund"
              ? "Are you sure you want to refund the entry fee?"
              : "Are you sure you want to mark this game as Illegitimate?"
          }
          title={this.state.reasonType === "refund" ? "Refund Entry Fee" : "Mark Game as Illegitimate"}
          game={this.state.gameId}
        />{" "}
        <HistoryFlyer
          showModal={this.state.showHistory}
          toggleModal={this.toggleHistoryFlyer}
          moduleName={"game"}
          objectId={this.state.objectId}
          title={this.state.object && this.state.object.contest && this.state.object.contest.title}
        />
        <GameRoundsFlyer
          showModal={this.state.showRoundsFlyer}
          toggleModal={this.roundsModalToggle}
          contest={this.props.getGame?.selectedGame?.contest}
          player={this.props.getGame?.selectedGame?.user}
          rounds={this.props.getGame?.selectedGame?.games}
          id={this.props.getGame?.selectedGame?.id}
          allGame={this.props.getGame?.selectedGame}
          selectedGameIndex={this.state.selectedGameIndex}
          amountType={amountType}
        />
        {(this.state.showHistory || this.state.showRoundsFlyer) && (
          <div
            className="modal-backdrop z-index-1000 show fade"
            onClick={this.state.showHistory ? this.toggleHistoryFlyer : this.roundsModalToggle}
          ></div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadGames: (params, fresh, callback) => dispatch(loadGames(params, fresh, callback)),
  loadContests: (params, fresh, callback) => dispatch(loadContests(params, fresh, callback)),
  markAsIllegitimate: (contestId, userId, data, callback) =>
    dispatch(markAsIllegitimate(contestId, userId, data, callback)),
  refundGame: (contestId, userId, data, callback) => dispatch(refundGame(contestId, userId, data, callback)),
  markAsIllegitimateReceived: (payLoad, callback) => dispatch(markAsIllegitimateReceived(payLoad, callback)),
  markAsRefundedReceived: (payLoad, callback) => dispatch(markAsRefundedReceived(payLoad, callback)),
  selectedGame: (payLoad) => dispatch(selectedGame(payLoad)),
});
const mapStateToProps = (state) => ({
  getGame: getGame(state),
  getContest: getContest(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GamesListing));
