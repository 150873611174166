import React, { createRef, Component } from "react";
import { toast } from "react-toastify";
import { Offcanvas, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { getGame, loadGames, markAsWinner, removeWinner, rewardTransfer, selectedGame } from "store/games";
import { loadContests, getContest } from "store/contest";

import moment from "moment/moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

//  Images
import userIcon from "include/images/user.png";
import emptyIcon from "include/images/nrf.svg";
import loader from "include/images/loading-icon-grey.svg";
import closeIcon from "include/images/dark-close-icon.svg";
import arrowIcon from "include/images/arrow-down-icon.svg";
import arrowIcon1 from "include/images/arrow-down-icon-1.svg";
import arrowIcon2 from "include/images/arrow-down-icon-2.svg";

//  Components
import Loader from "common/loader";
import NextButton from "common/form/submitButton";
import AlertModal from "common/alert/alertModal";
import GameRoundsFlyer from "../games/roundsFlyer";

class ListingFlyer extends Component {
  ref = createRef(null);
  state = {
    loading: false,
    loadingWinner: false,
    showAlert: false,
    showAlertNFT: false,
    winner: "",
    isWinnerDeclared: false,

    nftTransferred: false,
    showRoundsFlyer: false,
    loadingTransfer: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({ loading: true });
      this.props.loadGames(
        {
          contest: this.props.contest.id,

          amountType: this.props.amountType,
        },
        true,
        () => {
          this.setState({ loading: false });
        },
      );
    }
    if (!this.props.getGame.loading && this.props.getGame.loading !== prevProps.getGame.loading)
      this.ref.current.complete();

    if (this.props.getGame.loading && this.props.getGame.loading !== prevProps.getGame.loading)
      this.ref.current.continuousStart();
  };

  loadData = () => {
    const payLoad = {
      contest: this.props.contest.id,

      amountType: this.props.amountType,
    };
    var params = _.pickBy(payLoad, function (value, key) {
      return value !== "";
    });
    this.props.loadGames(params, true, () => {
      this.setState({
        loading: false,
      });
    });
  };
  roundsModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showRoundsFlyer: !this.state.showRoundsFlyer });
  };
  render() {
    const { games, totalCount } = this.props.getGame;
    const { contest } = this.props;
    return (
      <>
        {" "}
        <Loader loaderRef={this.ref} />
        <Offcanvas
          className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2"}
          show={this.props.showModal}
          onHide={(e) => {
            this.props.toggleModal(e);
          }}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header className="offCanvas-header-2">
            <div>
              <div className="td-img-group d-flex align-items-start m-top-2">
                <div className="round-img m-right-2">
                  {contest.image ? (
                    <img src={process.env.REACT_APP_CDN + contest.image.small} alt="" />
                  ) : (
                    <img src={userIcon} alt="" />
                  )}
                </div>
                <div>
                  <div>
                    {contest.title} {totalCount > 0 && `(${totalCount})`}
                  </div>
                  <div className="one-line-ellipsis">{contest.rewardTitle}</div>
                </div>
              </div>
            </div>
            <div
              className="btn_close pointer"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={(e) => {
                this.props.toggleModal(e);
              }}
            >
              <img src={closeIcon} alt="" />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {" "}
            <div className="">
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {games.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Player</th>
                            <th>Total/Highest Score </th>
                            <th>Played On </th>
                          </tr>
                        </thead>
                        <tbody>
                          {games &&
                            games.length > 0 &&
                            games.map((game, i) => {
                              const allGames = game?.games;

                              return (
                                <tr>
                                  <td className="td-img-group d-flex align-items-start">
                                    <div className="td-img round-img m-right-2">
                                      {game.user && game.user.image ? (
                                        <img
                                          src={
                                            process.env.REACT_APP_CDN +
                                            (game.user.image.small || game.user.image.original)
                                          }
                                          alt=""
                                        />
                                      ) : (
                                        <img src={userIcon} alt="" />
                                      )}
                                    </div>
                                    {game.user ? (
                                      <div className="">
                                        <Link
                                          to={{
                                            pathname: `/game-details/${game.gameId}`,
                                            state: { game },
                                          }}
                                          className="orange-text"
                                          target="_blank"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.props.selectedGame(game);
                                            this.roundsModalToggle(e);
                                          }}
                                        >
                                          {game.user.userName}
                                        </Link>
                                        <br />
                                        {game.user.email}
                                      </div>
                                    ) : (
                                      <Link
                                        to={{
                                          pathname: `/game-details/${game.gameId}`,
                                          state: { game },
                                        }}
                                        className="orange-text"
                                        target="_blank"
                                      >
                                        {game.userId}
                                      </Link>
                                    )}
                                  </td>
                                  <td>
                                    <div className={`td-text-box ${!game?.isValid && "opacity-5"}`}>
                                      {game?.isValid
                                        ? game.totalScore?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
                                        : "NA"}
                                    </div>
                                  </td>{" "}
                                  <td>
                                    {allGames[0]?.isIllegitimate ? (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Popover
                                            id="popoverPannel"
                                            className="custom-tooltip "
                                            data-bs-title="Illegitimate"
                                          >
                                            <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                              <h4>Reason for marking illegitimate</h4>
                                              <p>{allGames[0]?.illegitimateDetails?.reason}</p>
                                              <h4>Date</h4>
                                              <p>
                                                {moment(allGames[0]?.illegitimateDetails?.date).format(
                                                  "MM/DD/YYYY hh:mm a",
                                                )}
                                              </p>
                                            </Popover.Body>
                                          </Popover>
                                        }
                                      >
                                        <div
                                          class="td-shape"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          data-bs-custom-class="custom-tooltip"
                                          data-bs-title="Illegitimate"
                                        ></div>
                                      </OverlayTrigger>
                                    ) : allGames[0]?.isRefunded ? (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Popover
                                            id="popoverPannel"
                                            className="custom-tooltip "
                                            data-bs-title="Illegitimate"
                                          >
                                            <Popover.Body className="tooltip-inner tooltip-inner-reason ">
                                              <h4>Reason for refund</h4>
                                              <p>{allGames[0]?.refund?.reason}</p>
                                              <h4>Date</h4>
                                              <p>{moment(allGames[0]?.refund?.date).format("MM/DD/YYYY hh:mm a")}</p>
                                            </Popover.Body>
                                          </Popover>
                                        }
                                      >
                                        <div
                                          class="td-shape yellow-bg"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          data-bs-custom-class="custom-tooltip"
                                          data-bs-title="Illegitimate"
                                        ></div>
                                      </OverlayTrigger>
                                    ) : (
                                      <></>
                                    )}
                                    <div class="small-text">
                                      {moment(game.lastPlayedAt).format("MM/DD/YYYY")}
                                      <br /> <small> {moment(game.lastPlayedAt).format("hh:mm a")}</small>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        <AlertModal
          alertModalToggle={this.toggleModal}
          alertModalShow={this.state.showAlert}
          title="Alert!"
          classData="z-index-high"
          description="Are you sure you want to mark this user as winner?"
          alertType="Yes"
          onPress={this.markWinner}
          loading={this.state.loadingWinner}
        />{" "}
        <AlertModal
          alertModalToggle={this.toggleModalNFT}
          alertModalShow={this.state.showAlertNFT}
          title="Alert!"
          classData="z-index-high"
          description="Are you sure you have transferred the NFT to the winner of this contest? Winner will receive an email notification. This action cannot be reverted. "
          alertType="Yes"
          onPress={this.transferReward}
          loading={this.state.loadingTransfer}
        />
        <GameRoundsFlyer
          showModal={this.state.showRoundsFlyer}
          toggleModal={this.roundsModalToggle}
          contest={this.props.getGame?.selectedGame?.contest}
          player={this.props.getGame?.selectedGame?.user}
          rounds={this.props.getGame?.selectedGame?.games}
          id={this.props.getGame?.selectedGame?.id}
          allGame={this.props.getGame?.selectedGame}
          amountType={this.props.amountType}
        />{" "}
        {this.state.showRoundsFlyer && (
          <div className="modal-backdrop show fade" onClick={this.roundsModalToggle}></div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadGames: (params, fresh, callback) => dispatch(loadGames(params, fresh, callback)),
  markAsWinner: (id, callback) => dispatch(markAsWinner(id, callback)),
  removeWinner: (id, callback) => dispatch(removeWinner(id, callback)),
  rewardTransfer: (id, data, callback) => dispatch(rewardTransfer(id, data, callback)),
  selectedGame: (payLoad) => dispatch(selectedGame(payLoad)),
  loadContests: (status, params, callback) => dispatch(loadContests(status, params, callback)),
});
const mapStateToProps = (state) => ({
  getGame: getGame(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListingFlyer));
