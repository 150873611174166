import React from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import {
  getSweepstake,
  addInstances,
  updateInstances,
  addInstanceReceived,
  updateInstanceReceived,
  loadContract,
  loadCurrencies,
  loadPrizes,
} from "store/sweepstakes";
import { getSettings, loadHowItWorksTemplates, loadSweepstakeTemplate } from "store/settings";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Joi from "joi-browser";
import _ from "lodash";
import Amplify, { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { SWEEP_INSTANCES_STATUS, NFT_CHAIN_LISTS, SERVER } from "utils/constants";
import moment from "moment";
import { fetchNFT, ethToUSD, fetchTestNFT } from "utils/externalApiCaller";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import plusIcon from "include/images/plus-1.svg";
import deleteIcon from "include/images/delete-icon.svg";

//  Components
import Form from "common/form/form";
import Select from "common/form/selectSearch";
import NextButton from "common/form/submitButton";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});

class AddFlyer extends Form {
  fileInputRef = React.createRef(null);
  state = {
    loading: false,
    data: {
      title: "",
      description: "",
      status: { name: "Paused", value: 2 },
      timeExpired: "",
      maxTicketsPerUser: "",
      sweepContract: {},
      howItWorksTemplate: {},
      sweepstakesTemplate: {},
      displayOrder: {},
      //  Prize
      prizeTitle: "",
      prizeType: "NFT",
      coinQuantity: "",
      prizeValueUSD: "",
      image: null,
      nftTokenNumber: "",
      nftContractAddress: "",
      nftChain: {},
      nft: {},
      sweepCurrency: {},
    },
    errors: {},
    loadingTemplate: false,
    loadingImages: false,
    loadingReward: false,
    loadingMainImages: false,
    imageChange: false,
    imgInd: "",
    showFields: false,
    showNFTFields: false,
    loadingNFT: false,
    ethVPrice: "",
    loadingEth: false,
    loadingCurrencies: false,
    edit: false,
    nftFetched: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal) {
        this.setState({ loadingCurrencies: true, loadingSweepstakeTemplate: true });
        this.props.loadContract({ isAvailable: true });
        this.props.loadSweepstakeTemplate({}, () => {
          this.setState({ loadingSweepstakeTemplate: false });
        });
        this.props.loadCurrencies((res) => {
          const data = { ...this.state.data };
          this.props.loadHowItWorksTemplates((settingsRes) => {
            if (this.props.edit) {
              data.title = this.props.edit.title;
              data.description = this.props.edit.description;
              data.timeExpired = new Date(this.props.edit.timeExpired);
              data.maxTicketsPerUser = `${this.props.edit.maxTicketsPerUser}`;
              data.status = {
                name: SWEEP_INSTANCES_STATUS[this.props.edit.status],
                value: this.props.edit.status,
              };
              data.sweepContract = {
                name: this.props.edit.contract?.title,
                value: this.props.edit.contract?.id,
                data: this.props.edit.contract,
              };
              const hiw = settingsRes.data.data.find((h, i) => h.id === this.props.edit.howItWorksTemplateId);
              data.howItWorksTemplate = {
                name: hiw?.title,
                value: hiw?.id,
                data: hiw,
              };
              data.displayOrder = {
                name: this.props.edit?.displayOrder,
                value: this.props.edit?.displayOrder,
                data: this.props.edit?.displayOrder,
              };
              //  Prize
              data.prizeTitle = this.props.edit.prizeTitle;
              data.prizeType = this.props.edit.prizeType === 1 ? "NFT" : "COIN";
              data.coinQuantity = `${this.props.edit.coinQuantity}` ?? "";
              data.prizeValueUSD = `${this.props.edit.prizeValueUSD}` ?? "";
              data.nftContractAddress = this.props.edit.prizeContractAddress ?? "";
              data.nftTokenNumber = this.props.edit.nftTokenNumber?.toString() ?? "";
              data.image = this.props.edit.image;

              if (this.props.edit.prizeType === 2) {
                const currency = res.data.data.find((c, i) => c.id === this.props.edit.sweepsCurrencyId);
                data.sweepCurrency = {
                  name: currency.title,
                  value: currency.id,
                  data: currency,
                };
              }
            }
            this.setState({
              data,
              loadingCurrencies: false,
              edit: this.props.edit,
            });
          });
        });
        ethToUSD((ethRes) => {
          this.setState({ ethVPrice: ethRes.rates.USD });
        });
      }
    }
  };

  doSubmit = (e) => {
    this.setState({ loadingTemplate: true });
    const data = { ...this.state.data };
    const payLoad = {
      title: data.title,
      description: data.description,
      howItWorksTemplateId: data.howItWorksTemplate?.value,
      displayOrder: data.displayOrder?.value,

      //  Prizes
      prizeTitle: data.prizeTitle,
    };

    if (this.state.imageChange) {
      payLoad.image = data.image.medium;
    } else if (data.prizeType === "NFT") {
      payLoad.imageExternalURL = data.image.medium;
    } else {
      payLoad.image = data.image;
    }
    if (data.prizeType === "NFT" && !this.props.edit) {
      payLoad.prizeContractAddress = data.nftContractAddress;
      payLoad.nftTokenNumber = data.nftTokenNumber;
      payLoad.imageOriginalURL = data.nft.image_original_url;
    } else if (!this.props.edit) {
      payLoad.sweepsCurrencyId = data.sweepCurrency?.value;
      payLoad.coinQuantity = parseInt(data.coinQuantity);
    }

    if (this.props.edit) {
      this.props.updateInstances(this.props.edit.id, payLoad, (templateResponse) => {
        this.setState({
          loadingTemplate: false,
        });
        if (templateResponse.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          const payLoadData = { ...templateResponse.data.data };
          payLoad.contract = data.sweepContract;
          this.props.updateInstanceReceived(payLoadData);
          this.clearData();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={templateResponse.data && templateResponse.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      payLoad.timeExpired = parseInt(moment(data.timeExpired).format("x"));
      payLoad.maxTicketsPerUser = parseInt(data.maxTicketsPerUser);
      payLoad.sweepsContractId = data.sweepContract?.value;
      payLoad.prizeType = data.prizeType === "NFT" ? 1 : 2;
      payLoad.prizeValueUSD = parseFloat(data.prizeValueUSD);
      this.props.addInstances(payLoad, (templateResponse) => {
        this.setState({
          loadingTemplate: false,
        });
        if (templateResponse.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          const payLoadData = { ...templateResponse.data.data };
          payLoadData.contract = data.sweepContract?.data;
          this.props.addInstanceReceived(payLoadData);
          this.clearData();
          this.props.toggleModal();
        } else {
          this.setState({ data });
          toast(<AlertError message={templateResponse.data && templateResponse.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    if (e.name === "sweepstakesTemplate") {
      const fieldData = e.currentTarget?.data;
      const howItWorksTemplate = this.props.getSettings?.howItWorksTemplates?.find(
        (val) => val.id === fieldData?.howItWorksTemplateId,
      );

      data.howItWorksTemplate = {
        name: howItWorksTemplate?.title,
        value: howItWorksTemplate?.id,
        data: howItWorksTemplate,
      };
      data.title = fieldData?.sweepstakesTitle;
      data.description = fieldData?.sweepstakesDescription;
      data.image = fieldData?.image;
      data.maxTicketsPerUser = fieldData?.maxTicketsPerUser?.toString();
      data.prizeType = fieldData?.prizeType === 1 ? "NFT" : "COIN";
      data.prizeTitle = fieldData?.prizeTitle;
      data.sweepContract = {
        name: fieldData?.contract?.title,
        value: fieldData?.contract?.id,
        data: fieldData?.contract,
      };
      data.prizeValueUSD = fieldData?.prizeValueUSD;
      if (fieldData?.prizeType === 1) {
        data.nftChain = fieldData?.nftChain;
        data.nftContractAddress = fieldData?.nftContractAddress;
        data.nftTokenNumber = fieldData?.nftTokenNumber;
      } else {
        data.coinQuantity = fieldData?.coinQuantity?.toString();
        const sweepsCurrency = this.props.getSweepstake?.currencies?.find(
          (val) => val.id === fieldData?.sweepsCurrencyId,
        );
        data.sweepCurrency = {
          name: sweepsCurrency?.title,
          value: sweepsCurrency?.id,
          data: sweepsCurrency,
        };
      }
    }
    delete errors[e.name];
    this.setState({ data, errors });
  };
  schema = {
    title: Joi.string().required(),
    description: Joi.string().allow(""),
    status: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.number().required(),
    }),
    timeExpired: Joi.date().required(),
    maxTicketsPerUser: Joi.string().required(),
    sweepContract: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.string().required(),
    }),
    howItWorksTemplate: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.string().required(),
    }),
    sweepstakesTemplate: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.string().allow(""),
    }),
    displayOrder: Joi.object().allow({}),
    //  Prize
    prizeTitle: Joi.string().required(),
    nft: Joi.object().allow({}),
    image: Joi.object().required(),
    sweepCurrency: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.when("prizeType", {
        is: "COIN",
        then: Joi.string().required(),
        otherwise: Joi.string().allow(""),
      }),
    }),
    coinQuantity: Joi.when("prizeType", {
      is: "COIN",
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    prizeValueUSD: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
    nftContractAddress: Joi.when("prizeType", {
      is: "NFT",
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    nftChain: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.string().allow(""),
    }),
    nftTokenNumber: Joi.when("prizeType", {
      is: "NFT",
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    prizeType: Joi.string().required(),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        title: "",
        description: "",
        status: { name: "Paused", value: 2 },
        timeExpired: "",
        maxTicketsPerUser: "",
        sweepContract: {},
        howItWorksTemplate: {},
        sweepstakesTemplate: {},
        displayOrder: {},
        //  Prize
        prizeTitle: "",
        prizeType: "NFT",
        coinQuantity: "",
        prizeValueUSD: "",
        image: null,
        nftTokenNumber: "",
        nftContractAddress: "",
        nft: {},
        sweepCurrency: {},
        nftChain: {},
      },
      errors: {},
      loadingTemplate: false,
      loadingImages: false,
      loadingReward: false,
      loadingMainImages: false,
      imageChange: false,
      imgInd: "",
      showFields: false,
      showNFTFields: false,
      loadingNFT: false,
      ethVPrice: "",
      loadingEth: false,
      loadingCurrencies: false,
      edit: false,
    });
  };

  /**
   * Prize
   */
  onTypeChange = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    data.prizeType = e.target.name;
    if (data.prizeType === "NFT") {
      this.setState({ showNFTFields: true, showFields: false });
    } else {
      data.prizeTitle = "";
      data.prizeValueUSD = "";
      data.coinQuantity = "";
      data.image = null;
      data.sweepCurrency = {};
      data.nftContractAddress = "";
      data.nftChain = {};
      data.nftTokenNumber = "";
      this.setState({ showFields: true, showNFTFields: false, data });
    }
    this.setState({ data, errors: {} });
  };
  loadNFT = () => {
    const errors = structuredClone(this.state.errors);
    const data = structuredClone(this.state.data);
    const server = SERVER();

    this.setState({ loadingNFT: true });
    if (!data.nftContractAddress) {
      errors.nftContractAddress = "Address is required.";
    }
    if (!data.nftTokenNumber) {
      errors.nftTokenNumber = "Token ID is required.";
    }
    if (server && _.isEmpty(data?.nftChain)) {
      errors.nftChain = "Required";
    }

    if (Object.keys(errors).length) {
      this.setState({ errors, loadingNFT: false });
      return;
    }
    // const url = new URL(process.env.REACT_APP_SITE_URL).hostname;
    // const parts = url.split(".");
    // const server = parts[0];
    if (server === "dev" || server === "staging") {
      fetchTestNFT(
        this.state.data.nftChain?.value,
        this.state.data.nftTokenNumber,
        this.state.data.nftContractAddress,
        this.fetchTestNFTCallback,
      );
    } else {
      fetchNFT(this.state.data.nftTokenNumber, this.state.data.nftContractAddress, this.fetchNFTCallback);
    }
  };
  fetchTestNFTCallback = (response) => {
    const errors = this.state.errors;
    const data = this.state.data;
    const res = response.data?.nft;
    if (response.status === 200) {
      if (res.name) data.prizeTitle = res.name;

      data.nft = res;
      data.image = {
        small: res.image_thumbnail_url,
        medium: res.image_url,
        large: res.image_preview_url,
        original: res.image_original_url,
      };
      this.setState({
        loadingNFT: false,
        showFields: true,
        data,
        nftFetched: true,
      });
    } else {
      this.setState({
        loadingNFT: false,
      });
      toast(<AlertError message="Invalid Address or Token ID" />, {
        containerId: 1,
      });
    }
  };
  fetchNFTCallback = (response) => {
    const errors = this.state.errors;
    const data = this.state.data;
    const res = response.data;
    if (response.status === 200) {
      data.prizeValueUSD = (this.state.ethVPrice * res.collection.stats.seven_day_average_price).toFixed(2);
      if (res.name) data.prizeTitle = res.name;

      data.nft = res;
      data.image = {
        small: res.image_thumbnail_url,
        medium: res.image_url,
        large: res.image_preview_url,
        original: res.image_original_url,
      };
      this.setState({
        loadingNFT: false,
        showFields: true,
        data,
        nftFetched: true,
      });
    } else {
      this.setState({
        loadingNFT: false,
      });
      toast(<AlertError message="Invalid Address or Token ID" />, {
        containerId: 1,
      });
    }
  };
  handleImageChange = (e) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    this.setState({ loadingImages: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 25) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />, {
        containerId: 1,
      });
      return this.setState({ loadingImages: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ loadingImages: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.setState({
          errors,
          data,
          imageChange: true,
          loadingImages: false,
          data: {
            ...this.state.data,
            image: { medium: "public/" + result.key },
          },
        });
      });
    }
    this.setState({ data });
    e.target.value = null;
  };

  render() {
    const data = this.state.data;
    const { contracts, prizes } = this.props.getSweepstake;
    const { currencies } = this.props.getSweepstake;
    const { howItWorksTemplates, sweepstakeTemplate } = this.props.getSettings;
    const server = SERVER();
    console.log("state:", this.state);
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>{this.props.edit ? "Edit " : "Add "}a Sweeps Instance</h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!this.state.loadingCurrencies && (
            <div className="form-check-outer">
              <div className="new-form-group">
                <label className="form-label">Sweepstakes Template</label>
                <Select
                  name="sweepstakesTemplate"
                  options={sweepstakeTemplate?.map((s, i) => ({
                    name: s.name,
                    value: s.id,
                    data: s,
                  }))}
                  label="Select Sweepstakes Template"
                  value={this.state.data.sweepstakesTemplate}
                  error={this.state.errors.sweepstakesTemplate}
                  onChange={this.onChange}
                  isLoading={false}
                  isDisabled={this.state.edit}
                />
              </div>
              <div className="new-form-group">
                <div className="row">
                  <div className="col-12 new-form-group">{this.renderInput("title", "Title", "", "")}</div>
                </div>
              </div>
              <div className="new-form-group">
                <div className="row">
                  <div className="col-12 new-form-group">
                    {this.renderTextarea("description", "Description", "", "")}
                  </div>
                </div>
              </div>
              <div className="new-form-group">
                <div className="row">
                  <div className="col-6 ">
                    <label className="form-label">How It Works Template</label>
                    <Select
                      name="howItWorksTemplate"
                      options={howItWorksTemplates?.map((h, i) => ({
                        name: h.title,
                        value: h.id,
                        data: h,
                      }))}
                      label="Select How It Works Template"
                      value={this.state.data.howItWorksTemplate}
                      error={this.state.errors.howItWorksTemplate}
                      onChange={this.onChange}
                      isLoading={false}
                      isDisabled={this.state.edit}
                    />
                  </div>

                  <div className="col-6 ">
                    <label className="form-label">Display Order</label>
                    <Select
                      name="displayOrder"
                      options={[
                        { name: "1", value: 1 },
                        { name: "2", value: 2 },
                        { name: "3", value: 3 },
                        { name: "4", value: 4 },
                        { name: "5", value: 5 },
                        { name: "6", value: 6 },
                        { name: "7", value: 7 },
                        { name: "8", value: 8 },
                      ]}
                      label="Display Order"
                      value={this.state.data.displayOrder}
                      error={this.state.errors.displayOrder}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="new-form-group">
                <div className="row">
                  {this.renderDateInput("timeExpired", "Expiry Date & Time", new Date(), this.state.edit, true)}
                </div>
              </div>
              <div className="new-form-group">
                <div className="row">
                  <div className="col-6 ">
                    {this.renderInput("maxTicketsPerUser", "Max Tickets Per User ", "", "number", this.state.edit)}
                  </div>
                  <div className="col-6">
                    <label className="form-label">Sweeps Contract</label>
                    <Select
                      name="sweepContract"
                      options={contracts
                        ?.filter((c, i) => c.status === 1)
                        ?.map((c, i) => ({
                          name: c.title,
                          value: c.id,
                          data: c,
                        }))}
                      label="Select Sweeps Contract"
                      value={this.state.data.sweepContract}
                      error={this.state.errors.sweepContract}
                      onChange={this.onChange}
                      isLoading={false}
                      isDisabled={this.state.edit}
                    />
                  </div>
                </div>
              </div>

              {/* Prize  */}
              <div className="form-check-outer">
                <label className="form-label">Prize Type</label>
                <div className="form-check-group full-width-row d-flex align-items-center flex-wrap">
                  <ReactTooltip place="top" effect="solid" className="custom-tooltip" type="dark" transparent={false} />
                  <div className="form-check-item">
                    <div className="form-check has-circle-radio">
                      <input
                        className={`form-check-input ${this.state.errors.prizeType && "border-error"}`}
                        type="radio"
                        name="NFT"
                        id="radioCheckOneFlyer"
                        checked={this.state.data.prizeType === "NFT"}
                        onChange={this.onTypeChange}
                        disabled={this.state.loadingReward || this.props.edit}
                      />
                      <label className="form-check-label" htmlFor="radioCheckOneFlyer">
                        NFT
                      </label>
                    </div>
                  </div>
                  <div className="form-check-item">
                    <div className="form-check has-circle-radio">
                      <input
                        className={`form-check-input ${this.state.errors.prizeType && "border-error"}`}
                        type="radio"
                        name="COIN"
                        id="radioCheckTwoFlyer"
                        checked={this.state.data.prizeType === "COIN"}
                        onChange={this.onTypeChange}
                        disabled={this.state.loadingReward || this.props.edit}
                      />
                      <label className="form-check-label" htmlFor="radioCheckTwoFlyer" tabindex="1">
                        Coin
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {data.prizeType === "NFT" && (
                <div className="new-form-group">
                  <div className="row">
                    {(server === "dev" || server === "staging") && (
                      <div className="col-12 new-form-group ">
                        <label className="form-label">NFT Chain</label>
                        <Select
                          name="nftChain"
                          options={NFT_CHAIN_LISTS?.map((c, i) => ({
                            name: c.name,
                            value: c.value,
                            data: null,
                          }))}
                          label="Select Chain"
                          value={this.state.data.nftChain}
                          error={this.state.errors.nftChain}
                          onChange={this.onChange}
                          isLoading={false}
                          isDisabled={this.state.edit}
                        />
                      </div>
                    )}
                    <div className="col-12 new-form-group">
                      {this.renderInput("nftContractAddress", "Contract Address", "", "text", this.props.edit)}
                    </div>
                    <div className="col-12 new-form-group ">
                      {this.renderInput("nftTokenNumber", "Token ID", "", "", this.props.edit)}
                    </div>{" "}
                    {!this.props.edit && (
                      <div className="col-12 d-flex align-items-end">
                        <NextButton
                          handleSubmit={this.loadNFT}
                          label="Fetch NFT Details"
                          loading={this.state.loadingNFT}
                          classData={"btn btn-default"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {(data.prizeType === "COIN" || this.state.showFields || this.props.edit) && (
                <>
                  <div className="new-form-group">{this.renderInput("prizeTitle", "Prize Title")}</div>

                  {data.prizeType === "COIN" && (
                    <div className="new-form-group">
                      <div className="row">
                        <div className="col-6">
                          {this.renderInput("coinQuantity", "Coin Quantity", "", "number", this.props.edit)}
                        </div>
                        <div className="col-6">
                          <label className="form-label">Sweeps Currency</label>
                          <Select
                            name="sweepCurrency"
                            options={currencies?.map((currency) => ({
                              name: currency.title,
                              value: currency.id,
                              data: currency,
                            }))}
                            label="Select Sweeps Currency"
                            value={this.state.data.sweepCurrency}
                            error={this.state.errors.sweepCurrency}
                            onChange={this.onChange}
                            isLoading={false}
                            isDisabled={this.props.edit}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="new-form-group">
                    <div className="row">
                      <div className={"col-12"}>
                        <label className="form-label">Prize Value in USD</label>
                        <div className="number-input position-relative">
                          <span>$</span>
                          {this.renderInput("prizeValueUSD", "", "", "number", this.props.edit)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="kt-upload-row d-flex flex-wrap align-items-start">
                    <div
                      className={`kt-upload-outer ${
                        !this.state.data.image ? "placeholder-uploaded-img" : " position-relative"
                      } ${this.state.errors.image && "border-error"}`}
                    >
                      <div className="kt-upload-img">
                        <img
                          src={
                            this.state.data.image
                              ? this.state.data.image.medium
                                ? this.state.data.image.medium.includes("https://")
                                  ? this.state.data.image.medium
                                  : process.env.REACT_APP_CDN + this.state.data.image.medium
                                : process.env.REACT_APP_CDN + this.state.data.image
                              : plusIcon
                          }
                          alt=""
                        />
                      </div>{" "}
                      {this.state.data.image && (
                        <div
                          className="delete-icon d-flex align-items-center justify-content-center"
                          onClick={(e) => {
                            const data = this.state.data;
                            data.image = null;
                            this.setState({ data });
                          }}
                        >
                          <img src={deleteIcon} />
                        </div>
                      )}
                    </div>
                    <div className="kt-file-input">
                      <label htmlFor="formFile" className="kt-label">
                        {this.state.data.image ? " Change Image" : "+ Upload an Image"}
                      </label>
                      <input className="form-control" type="file" id="formFile" onChange={this.handleImageChange} />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loadingTemplate || this.state.loadingMainImages || this.state.loadingImages}
            disabled={data.prizeType === "NFT" && !this.state.nftFetched}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addInstances: (data, callback) => dispatch(addInstances(data, callback)),
  loadCurrencies: (callback) => dispatch(loadCurrencies(callback)),
  updateInstances: (id, data, callback) => dispatch(updateInstances(id, data, callback)),
  loadContract: (params, callback) => dispatch(loadContract(params, callback)),
  loadPrizes: (callback) => dispatch(loadPrizes(callback)),
  addInstanceReceived: (payLoad) => dispatch(addInstanceReceived(payLoad)),
  updateInstanceReceived: (payLoad) => dispatch(updateInstanceReceived(payLoad)),
  loadHowItWorksTemplates: (callback) => dispatch(loadHowItWorksTemplates(callback)),
  loadSweepstakeTemplate: (params, callback) => dispatch(loadSweepstakeTemplate(params, callback)),
});
const mapStateToProps = (state) => ({
  getSweepstake: getSweepstake(state),
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFlyer));
