import { createSlice } from "@reduxjs/toolkit";
import moment from "moment/moment";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "game",
  initialState: {
    games: [],
    freeGames: [],
    freeGameDetails: null,
    dailyTrivia: [],
    gameDetails: null,
    dailyTriviaGameDetails: null,
    totalCount: null,
    filteredCount: null,
    selectedGame: null,
    contestGameDetails: null,
    gameMetaData: null,
    loadingMetaData: false,
    //  BGGames:
    bgGames: [],
  },
  reducers: {
    gamesRequested: (misc, action) => {
      misc.loading = true;
    },
    gamesReceived: (misc, action) => {
      misc.games = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    moreGamesReceived: (misc, action) => {
      misc.games = [...misc.games, ...action.payload.data];
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    contestGameDetailsReceived: (misc, action) => {
      misc.contestGameDetails = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    //  BG Game
    bgGamesReceived: (misc, action) => {
      misc.bgGames = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    deleteBGGameReceived: (misc, action) => {
      const ind = misc.bgGames.findIndex((game) => game.id === action.payload.id);
      const newBGGames = misc.bgGames;
      newBGGames.splice(ind, 1);
      misc.totalCount = misc.totalCount - 1;
      misc.contestTemplate = [...newBGGames];
    },
    changeStatusBGGameReceived: (misc, action) => {
      const ind = misc.bgGames.findIndex((game) => game.id === action.payload.id);
      const newBGGames = misc.bgGames;
      newBGGames[ind].isActive = !action.payload.currStatus;
      misc.contestTemplate = [...newBGGames];
    },
    changeComingSoonStatusBGGameReceived: (misc, action) => {
      const ind = misc.bgGames.findIndex((game) => game.id === action.payload.id);
      const newBGGames = misc.bgGames;
      newBGGames[ind].isComingSoon = !action.payload.currStatus;
      misc.contestTemplate = [...newBGGames];
    },
    freeGamesReceived: (misc, action) => {
      misc.freeGames = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    dailyTriviaGamesReceived: (misc, action) => {
      misc.dailyTrivia = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    dailyTriviaGameDetailsReceived: (misc, action) => {
      misc.dailyTriviaGameDetails = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    gameDetailsReceived: (misc, action) => {
      misc.gameDetails = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },

    markAsIllegitimateReceived: (misc, action) => {
      const payLoad = action.payload;
      misc.games = misc.games.map((game, index) => {
        const newGame = { ...game };
        const newGames = newGame.games;
        if (index === payLoad.selectedGameIndex) {
          for (let i = 0; i < newGames.length; i++) {
            const newRound = { ...newGames[i] };
            if (payLoad.isIllegitimate) {
              delete newRound.illegitimateDetails;
              newRound.isIllegitimate = false;
            } else {
              newRound.isIllegitimate = true;
              newRound.illegitimateDetails = {
                date: moment.utc().valueOf(),
                reason: payLoad.reason,
              };
            }
            newGames[i] = newRound;
          }
          newGame.games = newGames;
        }
        return newGame;
      });
      if (misc.selectedGame) {
        const newSelectedGame = misc.selectedGame;
        const newGames = misc.selectedGame.games;
        for (let i = 0; i < newGames.length; i++) {
          const newRound = { ...newGames[i] };
          if (payLoad.isIllegitimate) {
            delete newRound.illegitimateDetails;
            newRound.isIllegitimate = false;
          } else {
            newRound.isIllegitimate = true;
            newRound.illegitimateDetails = {
              date: moment.utc().valueOf(),
              reason: payLoad.reason,
            };
          }
          newGames[i] = newRound;
        }
        newSelectedGame.games = newGames;
        misc.selectedGame = newSelectedGame;
      }
      misc.lastFetch = Date.now();
      misc.loading = false;
    },

    markAsRefundedReceived: (misc, action) => {
      const payLoad = action.payload;
      misc.games = misc.games.map((game, index) => {
        const newGame = { ...game };
        const newGames = newGame.games;
        if (index === payLoad.selectedGameIndex) {
          for (let i = 0; i < newGames.length; i++) {
            const newRound = { ...newGames[i] };
            newRound.isRefunded = true;
            newRound.refund = {
              date: moment.utc().valueOf(),
              reason: payLoad.reason,
            };
            newGames[i] = newRound;
          }
          newGame.games = newGames;
        }
        return newGame;
      });
      if (misc.selectedGame) {
        const newSelectedGame = misc.selectedGame;
        const newGames = misc.selectedGame.games;
        for (let i = 0; i < newGames.length; i++) {
          const newRound = { ...newGames[i] };
          newRound.isRefunded = true;
          newRound.refund = {
            date: moment.utc().valueOf(),
            reason: payLoad.reason,
          };
          newGames[i] = newRound;
        }
        newSelectedGame.games = newGames;
        misc.selectedGame = newSelectedGame;
      }
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    selectedGame: (misc, action) => {
      console.log("pay:", action);
      misc.selectedGame = action.payload;
    },
    freeGameDetailsReceived: (misc, action) => {
      misc.freeGameDetails = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    gamesRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    markIndividualGameAsRefundedReceived: (misc, action) => {
      const payLoad = action.payload;
      const newDetailsGames = misc.contestGameDetails?.games;
      for (let i = 0; i < newDetailsGames.length; i++) {
        const newRound = { ...newDetailsGames[i] };
        if (newRound.gameId === payLoad.gameId) {
          newRound.isRefunded = true;
          newRound.refund = {
            date: moment.utc().valueOf(),
            reason: payLoad.reason,
          };
          newDetailsGames[i] = newRound;
        }
      }
      misc.games = misc.games.map((game, index) => {
        const newGame = { ...game };
        const newGames = newGame.games;
        if (index === payLoad.selectedGameIndex) {
          for (let i = 0; i < newGames.length; i++) {
            const newRound = { ...newGames[i] };
            if (newRound.gameId === payLoad.gameId) {
              newRound.isRefunded = true;
              newRound.refund = {
                date: moment.utc().valueOf(),
                reason: payLoad.reason,
              };
              newGames[i] = newRound;
            }
          }
          newGame.games = newGames;
        }
        return newGame;
      });

      misc.lastFetch = Date.now();
      misc.loading = false;
    },

    //  Meta Data
    gameMetaDataRequested: (misc, action) => {
      misc.loadingMetaData = true;
    },
    gameMetaDataReceived: (misc, action) => {
      misc.gameMetaData = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loadingMetaData = false;
    },
    gameMetaDataRequestFailed: (misc, action) => {
      misc.loadingMetaData = false;
    },
  },
});

export const {
  gamesRequested,
  gamesReceived,
  moreGamesReceived,
  gamesRequestFailed,
  gameDetailsReceived,
  freeGamesReceived,
  freeGameDetailsReceived,
  dailyTriviaGamesReceived,
  dailyTriviaGameDetailsReceived,
  markAsIllegitimateReceived,
  markAsRefundedReceived,
  selectedGame,
  bgGamesReceived,
  deleteBGGameReceived,
  changeStatusBGGameReceived,
  contestGameDetailsReceived,
  markIndividualGameAsRefundedReceived,
  changeComingSoonStatusBGGameReceived,
  gameMetaDataRequested,
  gameMetaDataReceived,
  gameMetaDataRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const gameUrl = "game/";
const bgGameUrl = "bgGame/";
const freeGameUrl = "gameFree/";
const dailyGameUrl = "dailyTriviaGame/";

export const loadGames = (params, fresh, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: gameUrl,
      params,
      onStart: gamesRequested.type,
      onSuccess: fresh ? gamesReceived.type : moreGamesReceived.type,
      onError: gamesRequestFailed.type,
      callback,
    }),
  );
};
export const loadFreeGames = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: freeGameUrl,
      params,
      onStart: gamesRequested.type,
      onSuccess: freeGamesReceived.type,
      onError: gamesRequestFailed.type,
      callback,
    }),
  );
};
export const loadFreeGameDetails = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: freeGameUrl + id,
      onStart: gamesRequested.type,
      onSuccess: freeGameDetailsReceived.type,
      onError: gamesRequestFailed.type,
      callback,
    }),
  );
};
export const loadContestGameDetails = (contestId, gameId, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "game/details/" + contestId + "/" + gameId,
      onStart: gamesRequested.type,
      onSuccess: contestGameDetailsReceived.type,
      onError: gamesRequestFailed.type,
      params,
      callback,
    }),
  );
};
export const loadDailyTriviaGames = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: dailyGameUrl,
      params,
      onStart: gamesRequested.type,
      onSuccess: dailyTriviaGamesReceived.type,
      onError: gamesRequestFailed.type,
      callback,
    }),
  );
};
export const loadDailyTriviaGameDetails = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: dailyGameUrl + id,
      onStart: gamesRequested.type,
      onSuccess: dailyTriviaGameDetailsReceived.type,
      onError: gamesRequestFailed.type,
      callback,
    }),
  );
};
export const loadGameDetails = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: gameUrl + id,
      onStart: gamesRequested.type,
      onSuccess: gameDetailsReceived.type,
      onError: gamesRequestFailed.type,
      callback,
    }),
  );
};

//  BG Games
export const loadBGGames = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: bgGameUrl,
      onStart: gamesRequested.type,
      onSuccess: bgGamesReceived.type,
      onError: gamesRequestFailed.type,
      params,
      callback,
    }),
  );
};
export const updateBGGame = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: bgGameUrl + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const addBGGame = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: bgGameUrl,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const deleteBGGame = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: bgGameUrl + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const markAsWinner = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: gameUrl + "markAsWinner/" + id,
      method: "POST",
      callback,
    }),
  );
};
export const removeWinner = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: gameUrl + "removeWinner/" + id,
      method: "POST",
      callback,
    }),
  );
};
export const markAsFeatured = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: gameUrl + "markAsFeatured/" + id,
      data,
      method: "POST",
      callback,
    }),
  );
};
export const rewardTransfer = (gameId, contestId, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: gameUrl + "rewardsTransfer/" + contestId + "/" + gameId,
      data,
      method: "POST",
      callback,
    }),
  );
};
export const markAsIllegitimate = (contestId, userId, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: gameUrl + "markUserAsIllegitimate/" + contestId + "/" + userId,
      data,
      method: "POST",
      callback,
    }),
  );
};
export const refundGame = (contestId, userId, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: gameUrl + "refundUser/" + contestId + "/" + userId,
      data,
      method: "POST",
      callback,
    }),
  );
};
export const refundIndividualGame = (gameId, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: gameUrl + "refundIndividualGame/" + gameId,
      data,
      method: "POST",
      callback,
    }),
  );
};
export const loadGameMetaData = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: gameUrl + "metadata/" + id,
      onStart: gameMetaDataRequested.type,
      onSuccess: gameMetaDataReceived.type,
      onError: gameMetaDataRequestFailed.type,
      callback,
    }),
  );
};
export const getGame = createSelector(
  (state) => state.entities.game,
  (game) => game,
);
