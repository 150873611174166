import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import calendarIcon from "include/images/calendar.svg";
import momentTimeZone from "moment-timezone";
import moment from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

class DateInput extends Component {
  ref = React.createRef(null);
  modalRef = React.createRef(null);
  state = {
    formclass: "form-group custom-input show on",
    open: false,
  };

  handleFocus = () => {
    this.setState({ formclass: "form-group custom-input show on" });
  };

  handleBlur = (e) => {
    const classname = e.target.value ? "form-group custom-input show on" : "form-group custom-input show on";
    this.setState({ formclass: classname });
  };

  componentDidMount() {
    const classname = "form-group custom-input show on";
    this.setState({ formclass: classname });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      const classname = this.props.value ? "form-group custom-input show on" : "form-group custom-input show on";
      this.setState({ formclass: classname });
    }
  }
  theme = createTheme({
    palette: {
      primary: {
        main: "#1c1d23",
        color: "white",
      },
    },
    zIndex: 999999,
    typography: {
      color: "#1b1b1b",
    },
  });
  popperStyle = {
    zIndex: 999999,
  };
  render() {
    const { name, label, error, minDate, disabled, minTime, subText, maxDate, shouldDisableDate, ...rest } = this.props;
    if (this.ref && this.ref.current) {
      this.ref.current.placeholder = "mm/dd/yyyy";
    }
    return (
      <>
        <div className="form-group custom-date-form d-flex flex-column">
          <label className="form-label" htmlFor={label}>
            {label}
          </label>
          <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={this.theme}>
              <DatePicker
                inputRef={this.ref}
                renderInput={(props) => <TextField className={error ? "datepicker error" : "datepicker"} {...props} />}
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
                shouldDisableDate={shouldDisableDate}
                id={name}
                onFocus={this.handleFocus}
                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                onBlur={this.handleBlur}
                className={error ? "form-control datepicker error" : "form-control datepicker"}
                components={{
                  OpenPickerIcon: () => <img src={calendarIcon} />,
                  SwitchViewIcon: KeyboardArrowDownIcon,
                }}
                PopperProps={{
                  style: this.popperStyle,
                }}
                ref={this.modalRef}
                {...rest}
              />
            </ThemeProvider>
          </LocalizationProvider>
        </div>
      </>
    );
  }
}

export default DateInput;
