import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { getSettings, loadSettings, addSettings } from "store/settings";

import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import _ from "lodash";
import moment from "moment";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import historyIcon from "include/images/history-icon.svg";
import plusIcon from "include/images/plus.svg";

//  Components
import Header from "common/header";
import SideBar from "../../../common/sidebar";
import NextButton from "common/form/submitButton";
import Loader from "common/loader";
import AlertSuccess from "common/alert/alertSuccess";
import SelectSearch from "common/form/selectSearch";
import AlertError from "common/alert/alertError";
import DeleteModal from "common/alert/alertModal";
import AddBlockchainNetworks from "./addBlockchainNetworks";
import HistoryFlyer from "common/historyFlyer";

class Listing extends Component {
  ref = createRef(null);
  state = {
    timeIntervals: [
      { title: "Blockchain Networks", value: "blockChainNetworks" },
    ],
    blockChainNetworks: [],
    monthlyLeaderBoardCredits: [],
    dailyLeaderBoardCredits: [],
    weeklyLeaderBoardCredits: [],
    modalTitle: "",
    streakReward: 10,
    freeSignupCredits: 10,
    dailyCreditPurchaseLimit: 10,
    loadingMain: false,
    showAddModal: false,
    deleteId: "",
    editData: null,
    editId: null,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.getSettings.loading &&
      this.props.getSettings.loading !== prevProps.getSettings.loading
    )
      this.ref.current.complete();

    if (
      this.props.getSettings.loading &&
      this.props.getSettings.loading !== prevProps.getSettings.loading
    )
      this.ref.current.continuousStart();
    if (
      this.state.showAddModal !== prevState.showAddModal &&
      !this.state.showAddModal
    ) {
      setTimeout(() => {
        this.setState({ editData: null, editId: null });
      }, 500);
    }
  };
  componentDidMount = () => {
    this.setState({ loadingMain: true });
    this.props.loadSettings((res) => {
      const settings = res.data.data;
      const blockChainNetworks =
        settings?.length > 0 ? settings[0]?.blockChainNetworks : [];

      const dailyLeaderBoardCredits =
        settings?.length > 0 ? settings[0]?.dailyLeaderBoardCredits : [];
      const weeklyLeaderBoardCredits =
        settings?.length > 0 ? settings[0]?.weeklyLeaderBoardCredits : [];
      const monthlyLeaderBoardCredits =
        settings?.length > 0 ? settings[0]?.monthlyLeaderBoardCredits : [];
      const streakReward =
        settings?.length > 0 ? settings[0]?.streakReward : [];
      const freeSignupCredits =
        settings?.length > 0 ? settings[0]?.freeSignupCredits : [];
      const dailyCreditPurchaseLimit =
        settings?.length > 0 ? settings[0]?.dailyCreditPurchaseLimit : [];
      this.setState({
        loadingMain: false,
        dailyLeaderBoardCredits,
        weeklyLeaderBoardCredits,
        monthlyLeaderBoardCredits,
        streakReward,
        freeSignupCredits,
        dailyCreditPurchaseLimit,
        blockChainNetworks,
      });
    });
  };

  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };

  render() {
    const { timeIntervals, modalTitle } = this.state ?? [];
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="customText" />

          {!this.state.loadingMain && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1>Blockchain Networks</h1>
              </div>
              <div className="fwc-wrapper">
                <div className="fwc-body">
                  <div className="table-responsive">
                    <table className="table large-table">
                      <thead>
                        <tr>
                          <th style={{ width: 75, textAlign: "center" }}>
                            Actions
                          </th>
                          <th>Title</th>
                          <th>Networks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {timeIntervals.map((interval, index) => (
                          <tr>
                            <td>
                              <Dropdown className=" custom-dropdown">
                                <Dropdown.Toggle
                                  id="tdDropdownOne"
                                  className="custom-dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img src={carrentIcon} alt="" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  className={`dropdown-menu-start `}
                                  aria-labelledby="tdDropdownOne"
                                >
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState(
                                        {
                                          editData: this.state[interval.value],
                                          modalTitle: interval,
                                          editId: index,
                                        },
                                        () => {
                                          this.toggleAddModal();
                                        }
                                      );
                                    }}
                                  >
                                    Edit
                                  </Dropdown.Item>{" "}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td>
                              {" "}
                              <Link
                                className="two-line-ellipsis"
                                to={"#"}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState(
                                    {
                                      editData: this.state[interval.value],
                                      modalTitle: interval,
                                      editId: index,
                                    },
                                    () => {
                                      this.toggleAddModal();
                                    }
                                  );
                                }}
                              >
                                {interval.title}
                              </Link>
                            </td>
                            <td>
                              {this.state.blockChainNetworks?.map(
                                (network, index) => (
                                  <>
                                    {network}
                                    {index <
                                      this.state.blockChainNetworks.length -
                                        1 && ", "}
                                  </>
                                )
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <AddBlockchainNetworks
          showModal={this.state.showAddModal}
          toggleModal={this.toggleAddModal}
          edit={this.state.editData}
          editId={this.state.editId}
          modalTitle={modalTitle}
          setResponse={(val) => {
            this.setState({ [modalTitle?.value]: val });
          }}
        />
        <HistoryFlyer
          showModal={this.state.showHistory}
          toggleModal={this.toggleHistoryFlyer}
          moduleName={"winnerTemplate"}
          objectId={this.state.objectId}
          title={this.state.object && this.state.object.title}
        />
        {(this.state.showAddModal || this.state.showHistory) && (
          <div
            className="modal-backdrop show fade"
            onClick={
              this.state.showHistory
                ? this.toggleHistoryFlyer
                : this.toggleAddModal
            }
          ></div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadSettings: (callback) => dispatch(loadSettings(callback)),
  addSettings: (data, callback) => dispatch(addSettings(data, callback)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Listing)
);
