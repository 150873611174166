import React, { createRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  getSweepstake,
  loadInstanceInfo,
  requestRandomNumber,
  selectInstanceWinner,
  transferInstancePrize,
  updateInstances,
  updateInstanceInfoReceived,
} from "store/sweepstakes";
import moment from "moment-timezone";
import { Popover, OverlayTrigger } from "react-bootstrap";
import Timer from "react-compound-timer";
import { convertDate, formatDate } from "utils/dateConversion";
import { SWEEP_INSTANCES_STATUS, SWEEP_INSTANCES_TEXTS } from "utils/constants";

//  Images
import backIcon from "include/images/chevron-left.svg";
import clockIcon from "include/images/clock.svg";
import processingIcon from "include/images/w-refresh-icon.svg";
import completeIcon from "include/images/checked-16x16.svg";

//  Components
import Form from "common/form/form";
import Header from "common/header";
import SideBar from "common/sidebar";
import Loader from "common/loader";
import AlertModal from "common/alert/alertModal";
import SelectWinnerButton from "./common/selectWinnerButton";
import { toast } from "react-toastify";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";
import { truncateAddress } from "utils/misc";

class InstanceDetails extends Form {
  videoRef = createRef(null);
  ref = createRef(null);
  state = {
    loading: false,
    data: {
      winnerDocumentsReceived: false,
    },
    showAlertModal: false,
    loadingDocumentsStatus: false,
    showStatusTag: false,
  };
  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAlertModal: !this.state.showAlertModal });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getSweepstake.loading && this.props.getSweepstake.loading !== prevProps.getSweepstake.loading)
      this.ref && this.ref.current && this.ref.current.complete();

    if (this.props.getSweepstake.loading && this.props.getSweepstake.loading !== prevProps.getSweepstake.loading)
      this.ref && this.ref.current && this.ref.current.continuousStart();
  };
  componentDidMount = () => {
    this.setState({ loading: true });
    const slug = this.props.match?.params?.slug;
    this.props.loadInstanceInfo(slug, (res) => {
      if (res?.status === 200) {
        const instance = res?.data?.data;
        const status = res?.data?.data?.status;
        if (status === 4 || status === 5) {
          this.setState({ showStatusTag: true });
        }
      }
      this.setState({ loading: false });
    });
  };
  loadInstanceInfo = (callback) => {
    const slug = this.props.match?.params?.slug;
    this.props.loadInstanceInfo(slug, (res) => {
      callback();
      if (res?.status === 200) {
        const status = res?.data?.data?.status;
        if (status === 4 || status === 5) {
          this.setState({ showStatusTag: true });
        }
      }
      this.setState({ loading: false });
    });
  };

  changeWinnerDocumentStatus = (e) => {
    if (e) e.preventDefault();
    const instance = this.props.getSweepstake.instanceInfo;
    this.setState({ loadingDocumentsStatus: true });
    this.props.updateInstances(instance?.id, { winnerDocumentsReceived: true }, (instanceRes) => {
      this.setState({ loadingDocumentsStatus: false });
      if (instanceRes?.status === 200) {
        toast(<AlertSuccess message="Information Saved" />, {
          containerId: 1,
        });
        this.setState({ showStatusTag: false });
        window.location.reload();
        // this.props.updateInstanceInfoReceived(instanceRes?.data?.data);
        this.alertModalToggle();
      } else {
        toast(<AlertError message={instanceRes?.data?.message} />, {
          containerId: 1,
        });
      }
    });
  };

  render() {
    if (!this.props.match?.params?.slug) {
      this.props.history.push("/sweepstakes/instances");
    }
    const instance = this.props.getSweepstake.instanceInfo;
    const status = instance?.status;
    const showActions = status === 4 || status === 5 || status === 6 || status === 7 || status === 9;
    const winner = instance?.winner ?? null;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="sweepstakes" />
          {!this.state.loading && instance && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1>{instance.title}</h1>
              </div>
              <div className="fwc-wrapper">
                <a
                  href="/"
                  className="back-link-btn d-flex align-items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.goBack();
                  }}
                >
                  <img src={backIcon} alt="" /> Back
                </a>

                <div className="grid-card-details-row d-flex flex-wrap">
                  <div className="gcdr-img-box">
                    <div className="gcdr-img-outer text-white">
                      <div className="gcdr-image">
                        {instance.rewardAnimationURL ? (
                          !instance.rewardAnimationURL.includes(".mp4") &&
                          !instance.rewardAnimationURL.includes(".mov") ? (
                            <>
                              <iframe src={instance.rewardAnimationURL} alt="" />
                            </>
                          ) : (
                            <video
                              className=""
                              src={instance.rewardAnimationURL}
                              autoPlay
                              controls
                              ref={this.videoRef}
                              loop
                              onCanPlay={(e) => {
                                this.videoRef.play();
                              }}
                            ></video>
                          )
                        ) : (
                          <img
                            src={
                              instance.image.medium.includes("https://")
                                ? instance.image.medium
                                : process.env.REACT_APP_CDN + instance.image.medium
                            }
                            alt=""
                          />
                        )}

                        <div
                          className={`badge ${
                            status === 1 || status === 3
                              ? "running"
                              : status >= 8
                              ? "cancelled"
                              : status === 0 || status === 2
                              ? "past"
                              : "upcoming"
                          }`}
                        >
                          {status === 7 && instance?.winnerDocumentsRequired && !instance?.winnerDocumentsReceived
                            ? "Pending Documents"
                            : SWEEP_INSTANCES_STATUS[status]}
                        </div>
                      </div>
                      <div className="gcdr-group d-flex flex-wrap">
                        <div className="gcdr-item text-center flex-grow-1 w-auto">
                          <h6>{instance.maxTicketsPerUser?.toLocaleString() ?? 0}</h6>
                          <p>
                            <strong>Max Tickets Per User</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    {instance.timeExpired && instance?.status === 1 && (
                      <div className="clockdiv d-flex justify-content-center" id="clockdiv">
                        <Timer
                          initialTime={convertDate(instance.timeExpired).diff(moment().tz("America/New_York"))}
                          direction="backward"
                          startImmediately={true}
                        >
                          {(control) => {
                            this.ref = control;

                            return (
                              <>
                                <div className="colckdiv-item">
                                  <span>
                                    <Timer.Days className="days"></Timer.Days>
                                  </span>
                                  <div className="smalltext">Days</div>
                                </div>
                                <div className="colckdiv-item">
                                  <span>
                                    {" "}
                                    <Timer.Hours className="hours"></Timer.Hours>
                                  </span>
                                  <div className="smalltext">Hours</div>
                                </div>
                                <div className="colckdiv-item">
                                  <span>
                                    {" "}
                                    <Timer.Minutes className="minutes"></Timer.Minutes>
                                  </span>
                                  <div className="smalltext">Minutes</div>
                                </div>
                                <div className="colckdiv-item">
                                  <span>
                                    <Timer.Seconds className="seconds"></Timer.Seconds>
                                  </span>
                                  <div className="smalltext">Seconds</div>
                                </div>
                              </>
                            );
                          }}
                        </Timer>
                      </div>
                    )}
                  </div>
                  <div className="gcdr-text-box">
                    <div className="gcdr-heading-filter d-flex align-items-center justify-content-between">
                      <div className="gcdr-heading-date d-flex align-items-center">
                        {instance.timeExpired && (
                          <>
                            <div className="gcdr-heading-text">
                              <img src={clockIcon} alt="" />
                              <span>{instance?.status !== 1 && instance?.status !== 2 ? "Ended on" : "Ends on"} :</span>
                              {formatDate(instance.timeExpired, "MM/DD/YYYY hh:mm A")} EST
                            </div>
                          </>
                        )}
                      </div>
                      {showActions && (
                        <SelectWinnerButton
                          instance={instance}
                          status={status}
                          setStatusTag={(val) => this.setState({ showStatusTag: val })}
                          loadInstanceInfo={this.loadInstanceInfo}
                        />
                      )}
                    </div>{" "}
                    <div className="gcdr-heading mb-20">
                      <h2>{instance.title}</h2>
                    </div>
                    {status === 7 && instance?.winnerDocumentsRequired && !instance?.winnerDocumentsReceived && (
                      <div className={`form-check-item  p-0 ${this.state.showStatusTag && "mb-20"}`}>
                        <div className="form-check has-circle-radio">
                          <input
                            className={`form-check-input `}
                            type="checkbox"
                            name="winnerDocuments"
                            id="radioCheckOneFlyer"
                            checked={this.state.data.winnerDocumentsReceived || instance?.winnerDocumentsReceived}
                            onChange={this.alertModalToggle}
                            disabled={this.state.loadingDocumentsStatus || instance?.winnerDocumentsReceived}
                          />
                          <label className="form-check-label" htmlFor="radioCheckOneFlyer">
                            Tax Documents Received
                          </label>
                        </div>
                      </div>
                    )}
                    {"winningTicketNumber" in instance && status >= 3 && status < 8 && (
                      <div class="gfr-lower">
                        <div class={`gfr-btn-row d-flex flex-wrap has-purple-gradient`}>
                          <div class="gfr-btn-row-left d-flex align-items-center">
                            <div class="gfr-btn-row-icon">
                              <img src={completeIcon} alt="" />
                            </div>
                            <div class="gfr-btn-row-text">
                              <h4>Random Number: {instance?.winningTicketNumber ?? 0}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {instance?.winnerDocumentsRequired && instance?.winnerDocumentsReceived && (
                      <div class="gfr-lower">
                        <div class={`gfr-btn-row d-flex flex-wrap has-yellow-gradient`}>
                          <div class="gfr-btn-row-left d-flex align-items-center">
                            <div class="gfr-btn-row-icon">
                              <img src={completeIcon} alt="" />
                            </div>
                            <div class="gfr-btn-row-text">
                              <h4>
                                <span className="ellipsis">Tax Documents Received</span>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {(this.state.showStatusTag || status === 3) && (
                      <div class="gfr-lower">
                        <div class={`gfr-btn-row d-flex flex-wrap ${status === 3 && "has-green-gradient"}`}>
                          <div class="gfr-btn-row-left d-flex">
                            <div class="gfr-btn-row-icon">
                              <img
                                class={status !== 3 && "fa-spin"}
                                src={status === 3 ? completeIcon : processingIcon}
                                alt=""
                              />
                            </div>
                            <div class="gfr-btn-row-text">
                              <h4>
                                {
                                  SWEEP_INSTANCES_TEXTS[
                                    instance?.winnerDocumentsRequired &&
                                    !instance?.winnerDocumentsReceived &&
                                    status === 7
                                      ? 10
                                      : status
                                  ]?.heading
                                }
                              </h4>
                              <p>
                                {
                                  SWEEP_INSTANCES_TEXTS[
                                    instance?.winnerDocumentsRequired &&
                                    !instance?.winnerDocumentsReceived &&
                                    status === 7
                                      ? 10
                                      : status
                                  ]?.subText
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {winner?.isUserKycRequired && !winner?.isUserKycVerified && (
                      <div class="gfr-lower">
                        <div class={`gfr-btn-row d-flex flex-wrap background-dark`}>
                          <div class="gfr-btn-row-left d-flex">
                            <div class="gfr-btn-row-icon">
                              <img class={"fa-spin"} src={processingIcon} alt="" />
                            </div>
                            <div class="gfr-btn-row-text">
                              <h4>{SWEEP_INSTANCES_TEXTS[11]?.heading}</h4>
                              <p>{SWEEP_INSTANCES_TEXTS[11]?.subText}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {winner && (
                      <div className="gcdr-widget-wrapper mb-20 mt-3">
                        <div className="gcdr-widget-box">
                          <h4>Winner</h4>
                          <div className="gcdr-details-box">
                            <div className="d-flex align-items-start mb-2">
                              <div className="m-right-2  round-img">
                                <img src={process.env.REACT_APP_CDN + winner?.image?.small} alt="" />
                              </div>
                              <div>
                                {winner?.userName}
                                <br />
                                {winner?.email}
                              </div>
                            </div>
                            <p className="mb-2">
                              <strong>Wallet Address: </strong> {winner?.walletAddress}
                            </p>
                            <p className="mb-2">
                              <strong>Sweepstakes Entered: </strong> {winner?.totalEntries?.toLocaleString()}
                            </p>
                            <p className="mb-2">
                              <strong>Sweepstakes Won: </strong> {winner?.totalWinnings?.toLocaleString()}
                            </p>
                            <p className="mb-2">
                              <strong>Total Prize Value in Current Year: </strong>$
                              {winner?.totalPrizeValueInCurrentYear?.toLocaleString()}
                            </p>
                            <p className="mb-2">
                              <strong>Overall Prize Value: </strong> ${winner?.totalPrizeValueOverAll?.toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="gcdr-widget-wrapper">
                      <div className="gcdr-widget-box">
                        <h4>Description</h4>
                        <div className="gcdr-details-box">
                          <p>{instance?.description}</p>
                        </div>
                      </div>
                      <div className="gcdr-widget-box">
                        <h4>Sweeps Details</h4>
                        <div className="gcdr-details-box">
                          <h4>Sweeps Id</h4>
                          <p>{instance?.sweepsId}</p>
                          <h4>Created Date & Time</h4>
                          <p>{formatDate(instance?.createdAt, "MM/DD/YY hh:mm A")}</p>
                          <h4>Start Date & Time</h4>
                          <p>{formatDate(instance?.activatedAt, "MM/DD/YY hh:mm A")}</p>
                          <h4>End Date & Time</h4>
                          <p>{formatDate(instance?.timeExpired, "MM/DD/YY hh:mm A")}</p>
                          <h4>Max Tickets Per User</h4>
                          <p>{instance?.maxTicketsPerUser?.toLocaleString()}</p>
                          <h4>Sweeps Prize</h4>
                          <p>{instance?.prizeTitle}</p>
                        </div>
                      </div>
                      <div className="gcdr-widget-box">
                        <h4>Contract</h4>
                        <div className="gcdr-details-box">
                          <h4>Title</h4>
                          <p>{instance?.contract?.title}</p>
                          <h4>Contract Address</h4>
                          <p>{instance?.contract?.contractAddress}</p>
                          <h4>Description</h4>
                          <p>{instance?.contract?.description}</p>
                          <h4>Blockchain Network</h4>
                          <p>{instance?.contract?.blockChainNetwork}</p>
                        </div>
                      </div>
                      <div className="gcdr-widget-box">
                        <h4>Prize</h4>
                        <div className="gcdr-details-box">
                          <h4>Title</h4>
                          <p>
                            {instance?.prizeTitle}
                            <br />
                            {instance.prizeContractAddress && instance?.prizeType === 1 && (
                              <span>
                                <a
                                  href={`https://opensea.io/assets/ethereum/${instance.prizeContractAddress}/${instance.nftTokenNumber}`}
                                  className="orange-text"
                                  target={"_blank"}
                                  rel="noreferrer"
                                >
                                  View on Open Sea
                                </a>
                              </span>
                            )}
                          </p>
                          <h4>Type</h4>
                          <p>{instance?.prizeType === 1 ? "NFT" : "Coin"}</p>
                          {instance?.coinQuantity && (
                            <>
                              <h4>Coin Quantity</h4>
                              <p>{instance.coinQuantity.toLocaleString()}</p>
                            </>
                          )}
                          <h4>Value in USD</h4>
                          <p>{instance?.prizeValueUSD.toLocaleString()}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <AlertModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showAlertModal}
          alertType="Yes"
          title="Alert"
          description="Are you sure you have received all the necessary documents from the selected winner?"
          onPress={this.changeWinnerDocumentStatus}
          loading={this.state.loadingDocumentsStatus}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadInstanceInfo: (id, callback) => dispatch(loadInstanceInfo(id, callback)),
  updateInstances: (id, data, callback) => dispatch(updateInstances(id, data, callback)),
  updateInstanceInfoReceived: (payLoad) => dispatch(updateInstanceInfoReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getSweepstake: getSweepstake(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InstanceDetails));
