export const SWEEP_INSTANCES_STATUS = [
  "Inactive", // Sweepstake has not been activated yet
  "Active", // Sweepstake is open for users to enter
  "Paused", // Sweepstake is paused
  "Completed", // Sweepstake ended and winner's prize was paid out
  "Pending Chainlink", // Sweepstake ended and random number has been requested,
  "Pending Selecting Winner", // Random number has been fulfilled by ChainLink, ready to pick winner
  "Pending Setting Up Winner", // Winning ticket has been found, ready to set winner
  "Pending Transferring Prize", // Winning ticket has been found, ready to set winner
  "Canceled",
  "Expired",
];
export const SWEEP_INSTANCES_TEXTS = {
  0: { heading: "", subText: "" },
  1: { heading: "", subText: "" },
  2: {
    heading: "Sweepstake Paused",
    subText: "Access the sweepstake when it's active again",
  },
  3: {
    heading: "WINNER SELECTED",
    subText: "PRIZE AWARDED",
  },
  4: {
    heading: "Processing transaction",
    subText: "THIS CAN EXCEED UP TO A MINUTE. PLEASE REFRESH THE PAGE TO CHECK THE LATEST STATUS.",
    tooltipHead: "Pending Chainlink",
    tooltipText: "The status will be updated after process is completed",
    buttonText: "Request Random Number",
  },
  5: {
    heading: "Processing transaction",
    subText: "THIS CAN EXCEED UP TO A MINUTE. PLEASE REFRESH THE PAGE TO CHECK THE LATEST STATUS.",
    buttonText: "Find Winner",
    tooltipHead: "Pending Chainlink",
    tooltipText: "The status will be updated after process is completed",
    buttonText: "Request Random Number",
  },
  6: {
    heading: "WINNER SELECTION IN PROGRESS",
    subText: "THIS CAN EXCEED UP TO A MINUTE",
    tooltipHead: "Winner is being selected",
    tooltipText: "The status will be updated after process is completed",
    buttonText: "Find Winner",
  },
  7: {
    heading: "REWARD TRANSFER IN PROGRESS",
    subText: "REWARD NOT YET TRANSFERRED. PLEASE REFRESH THE PAGE TO CHECK THE LATEST STATUS.",
    buttonText: "Transfer Prize",
    tooltipHead: "Reward Transfer in progress",
    tooltipText: "The status will be updated after Reward is transferred to the winner's wallet",
  },
  8: {
    heading: "Sweeps Instance Cancelled",
    subText: "",
  },
  9: {
    heading: "PROCESSING TRANSACTION",
    subText: "THIS CAN EXCEED UP TO A MINUTE. PLEASE REFRESH THE PAGE TO CHECK THE LATEST STATUS.",
    tooltipHead: "Processing",
    tooltipText: "Status will be updated soon",
    buttonText: "Request Random Number",
  },
  10: {
    heading: "WINNER TAX DOCUMENTATION PENDING",
    subText: "WINNER IS NEEDED TO SUBMIT TAX DOCUMENTS",
    buttonText: "Transfer Prize",
    tooltipHead: "Tax Documentation pending",
    tooltipText: "Prize cannot be transferred to this user. Additional documents are required.",
  },
  11: {
    heading: "WINNER KYC DOCUMENTATION PENDING",
    subText: "WINNER IS NEEDED TO SUBMIT KYC DOCUMENTS",
  },
};
const SweepsInstanceStatus = {
  INACTIVE: 0, // Sweepstake has not been activated yet
  ACTIVE: 1, // Sweepstake is open for users to enter
  PAUSED: 2, // Sweepstake is paused
  COMPLETED: 3, // Sweepstake ended and winner's prize was paid out
  PENDING_CHAINLINK: 4, // Sweepstake ended and random number has been requested,
  PENDING_SELECTING_WINNER: 5, // Random number has been fulfilled by ChainLink, ready to pick winner
  PENDING_SETTING_WINNER: 6, // Winning ticket has been found, ready to set winner
  PENDING_TRANSFERRING_PRIZE: 7, // Winning ticket has been found, ready to set winner
  CANCELED: 8,
  EXPIRED: 9,
};

//  NFT Chain List
export const NFT_CHAIN_LISTS = [
  { name: "Goerli", value: "goerli" },
  { name: "Sepolia", value: "sepolia" },
  { name: "Polygon Mumbai", value: "mumbai" },
  { name: "Klaytn Baobab", value: "baobab" },
  { name: "BNB Testnet", value: "bsctestnet" },
  { name: "Arbitrum Goerli", value: "arbitrum_goerli" },
  { name: "Avalanche Fuji", value: "avalanche_fuji" },
  { name: "Optimism Goerli", value: "optimism_goerli" },
  { name: "Solana Devnet", value: "soldev" },
  { name: "Base Goerli", value: "base_goerli" },
  { name: "Zora Testnet", value: "zora_ testnet" },
];

export const SERVER = () => {
  const url = new URL(process.env.REACT_APP_SITE_URL).hostname;
  const parts = url.split(".");
  return parts[0];
};
