import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "question",
  initialState: {
    questions: [],
    totalCount: null,
    questionInfo: null,
    filteredCount: null,
    options: [],
    questionCounts: null,
    comments: [],
    allHistory: [],
    totalHistoryCount: null,
    historyDetails: null,
  },
  reducers: {
    questionsRequested: (misc, action) => {
      misc.loading = true;
    },
    questionsReceived: (misc, action) => {
      misc.questions = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.filteredCount = action.payload.filteredCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    updateTextOptionsReceived: (misc, action) => {
      misc.options = action.payload;
    },
    allHistoryReceived: (misc, action) => {
      misc.allHistory = action.payload.data;
      misc.totalHistoryCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    moreHistoryReceived: (misc, action) => {
      misc.allHistory = [...misc.allHistory, ...action.payload.data];
      misc.totalHistoryCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    historyDetailsReceived: (misc, action) => {
      misc.historyDetails = action.payload.data;
      misc.totalHistoryCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    deleteQuestionReceived: (misc, action) => {
      const ind = misc.questions.findIndex((quest) => quest.id === action.payload.id);
      const newQuestions = misc.questions;
      newQuestions.splice(ind, 1);
      misc.totalCount = misc.totalCount - 1;
      misc.questions = [...newQuestions];
    },
    questionsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    questionsCountsRequested: (misc, action) => {
      misc.loading = true;
    },
    questionsCountsReceived: (misc, action) => {
      misc.questionCounts = action.payload;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    questionsCountsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    questionInfoRequested: (misc, action) => {
      misc.loading = true;
    },
    questionInfoReceived: (misc, action) => {
      misc.questionInfo = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    questionInfoRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    optionsRequested: (misc, action) => {
      misc.loading = true;
    },
    optionsReceived: (misc, action) => {
      misc.options = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    commentsReceived: (misc, action) => {
      misc.comments = action.payload.data;
      misc.totalCount = action.payload.totalCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    addCommentReceived: (misc, action) => {
      const newComments = misc.comments;
      const payLoad = action.payload;
      misc.comments = [payLoad, ...newComments];
    },
    updateStatusReceived: (misc, action) => {
      const payLoad = action.payload;
      const reviewed = payLoad.reviewed;
      misc.questions = misc.questions.map((question, index) => {
        const newQuestions = { ...question };
        if (payLoad.id === newQuestions.id) {
          newQuestions.reviewed = reviewed;
        }
        return newQuestions;
      });
    },
    optionsUpdate: (misc, action) => {
      misc.options = misc.options.map((o, i) => {
        if (o.id === action.payload.id) {
          o.name = action.payload.name;
        }
        return o;
      });
    },
    questionReviewStatusUpdate: (misc, action) => {
      misc.questions = misc.questions.map((o, i) => {
        if (o.id === action.payload.id) {
          o.reviewed = action.payload.value;
        }
        return o;
      });
    },
    optionsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  questionsRequested,
  questionsReceived,
  questionsRequestFailed,
  optionsRequested,
  optionsReceived,
  optionsRequestFailed,
  questionInfoRequested,
  questionInfoReceived,
  questionInfoRequestFailed,
  questionsCountsRequested,
  questionsCountsReceived,
  questionsCountsRequestFailed,
  optionsUpdate,
  deleteQuestionReceived,
  commentsReceived,
  addCommentReceived,
  updateStatusReceived,
  allHistoryReceived,
  moreHistoryReceived,
  historyDetailsReceived,
  updateTextOptionsReceived,
  questionReviewStatusUpdate,
} = slice.actions;
export default slice.reducer;

// Action Creators
const questionUrl = "question/";
const freeQuestionUrl = "questionFree/";
const addOptionUrl = "question/";
const freeAddOptionUrl = "questionFree/";

export const loadQuestions = (params, type, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: type === "free" ? freeQuestionUrl : questionUrl,
      params,
      onStart: questionsRequested.type,
      onSuccess: questionsReceived.type,
      onError: questionsRequestFailed.type,
      callback,
    }),
  );
};
export const loadAllHistory = (params, moduleName, fresh, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "adminActionLog/history/" + moduleName,
      params,
      onStart: questionsRequested.type,
      onSuccess: fresh ? allHistoryReceived.type : moreHistoryReceived.type,
      onError: questionsRequestFailed.type,
      callback,
    }),
  );
};
export const loadFreeQuestions = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: freeQuestionUrl,
      params,
      onStart: questionsRequested.type,
      onSuccess: questionsReceived.type,
      onError: questionsRequestFailed.type,
      callback,
    }),
  );
};
export const loadComments = (id, type, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: (type === "free" ? freeQuestionUrl : questionUrl) + "reviewComments/" + id,
      onStart: questionsRequested.type,
      onSuccess: commentsReceived.type,
      onError: questionsRequestFailed.type,
      callback,
    }),
  );
};
export const addComment = (data, id, type, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: (type === "free" ? freeQuestionUrl : questionUrl) + "reviewComments/" + id,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateTextOptions = (data, id, type, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: (type === "free" ? freeQuestionUrl : questionUrl) + "option/" + id,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadQuestionCounts = (type, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "questionCount",
      onStart: questionsCountsRequested.type,
      onSuccess: questionsCountsReceived.type,
      onError: questionsCountsRequestFailed.type,
      callback,
    }),
  );
};
export const loadQuestionInfo = (id, type, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: (type === "free" ? freeQuestionUrl : questionUrl) + "detail/" + id,
      onStart: questionInfoRequested.type,
      onSuccess: questionInfoReceived.type,
      onError: questionInfoRequestFailed.type,
      callback,
    }),
  );
};
export const addQuestion = (data, type, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: type === "free" ? freeQuestionUrl : questionUrl,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateQuestion = (data, id, type, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: (type === "free" ? freeQuestionUrl : questionUrl) + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const updateReviewStatus = (id, type, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: (type === "free" ? freeQuestionUrl : questionUrl) + "changeReviewStatus/" + id,
      method: "PUT",
      callback,
    }),
  );
};

export const addOption = (data, type, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: (type === "free" ? freeAddOptionUrl : addOptionUrl) + "addOption",
      method: "POST",
      data,
      callback,
    }),
  );
};

export const uploadImage = (data, params, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: questionUrl + "option/uploadImage/" + id,
      method: "POST",
      data,
      params,
      callback,
    }),
  );
};
export const updateOptions = (data, qId, id, type, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: (type === "free" ? freeAddOptionUrl : addOptionUrl) + "option/" + qId + "/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const updateOptionsBulk = (data, id, type, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: (type === "free" ? freeQuestionUrl : questionUrl) + "option/" + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteOptions = (id, type, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: (type === "free" ? freeQuestionUrl : questionUrl) + "option/delete/" + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const deleteQuestion = (id, type, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: (type === "free" ? freeQuestionUrl : questionUrl) + "delete/" + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const loadOptions = (id, type, callback) => (dispatch) => {
  const params = { sort: "createdAt", order: "desc" };
  return dispatch(
    apiCallBegan({
      url: (type === "free" ? freeQuestionUrl : questionUrl) + "option/" + id,
      onStart: optionsRequested.type,
      onSuccess: optionsReceived.type,
      onError: optionsRequestFailed.type,
      params,
      callback,
    }),
  );
};
export const getQuestion = createSelector(
  (state) => state.entities.question,
  (question) => question,
);
