import React from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import {
  getSettings,
  addSettings,
  loadWinnerTemplates,
  addWinnerTemplate,
  updateWinnerTemplate,
  addWinnerTemplateReceived,
  updateWinnerTemplateReceived,
} from "store/settings";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Joi from "joi-browser";
import { fetchNFT, ethToUSD } from "utils/externalApiCaller";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import Amplify, { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import plusIcon from "include/images/plus-1.svg";
import ethIcon from "include/images/eth-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";

//  Components
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";

class AddFlyer extends Form {
  state = {
    loading: false,
    data: {
      type: "email",
      duplicateIPsNotificationEmails: "",
      sqsErrorNotificationEmails: "",
      lowBalanceNotificationEmails: "",
      contestSweepstakesEndTimeReminderEmails: "",
      lootBoxPrizeEmails: "",
      kycVerificationEmails: "",
      fraudDetectionEmails: "",
      contestSchedularEmails: "",

      chainlinkVRFCoodinatorAddress: "",
      chainlinkSubscriptionId: "",
      chainlinkVRFCoodinatorABI: "",

      minBGWalletBalance: "",
      minChainLinkBalance: "",

      freeSignupArcadeCoinCredits: "",
      freeSignupLootBonusCoinCredits: "",

      dailyArcadeCoinReward: "",
      dailyLootBonusCoinReward: "",

      threeDayStreakArcadeCoinReward: "",
      threeDayStreakLootBonusCoinReward: "",

      weeklyStreakArcadeCoinReward: "",
      weeklyStreakLootBonusCoinReward: "",

      creditPriceBonusPercent: "",
      referralBonusPercent: "",

      dailyGameChallengeArcadeCoinReward: "",
      dailyGameChallengeLootBonusCoinReward: "",
      resumeAutoSuspensionDays: "",
    },
    errors: {},
    loadingCredits: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      const edit = this.props.edit;
      const type = this.props.modalTitle?.value;
      if (this.props.showModal) {
        const data = structuredClone(this.state.data);
        data.type = this.props.modalTitle?.value;
        if (type === "email") {
          data.duplicateIPsNotificationEmails = edit?.duplicateIPsNotificationEmails?.join(",") ?? "";
          data.sqsErrorNotificationEmails = edit?.sqsErrorNotificationEmails?.join(",") ?? "";
          data.lowBalanceNotificationEmails = edit?.lowBalanceNotificationEmails?.join(",") ?? "";
          data.contestSweepstakesEndTimeReminderEmails = edit?.contestSweepstakesEndTimeReminderEmails?.join(",") ?? "";
          data.lootBoxPrizeEmails = edit?.lootBoxPrizeEmails?.join(",") ?? "";
          data.kycVerificationEmails = edit?.kycVerificationEmails?.join(",") ?? "";
          data.fraudDetectionEmails = edit?.fraudDetectionEmails?.join(",") ?? "";
          data.contestSchedularEmails = edit?.contestSchedularEmails?.join(",") ?? "";
        } else if (type === "chainLink") {
          data.chainlinkVRFCoodinatorAddress = edit?.chainlinkVRFCoodinatorAddress;
          data.chainlinkSubscriptionId = edit?.chainlinkSubscriptionId;
          data.chainlinkVRFCoodinatorABI = edit?.chainlinkVRFCoodinatorABI;
        } else if (type === "walletBalance") {
          data.minBGWalletBalance = edit?.minBGWalletBalance;
          data.minChainLinkBalance = edit?.minChainLinkBalance;
        } else if (type === "freeSignUpCredits") {
          data.freeSignupArcadeCoinCredits = edit?.freeSignupArcadeCoinCredits;
          data.freeSignupLootBonusCoinCredits = edit?.freeSignupLootBonusCoinCredits;
        } else if (type === "dailyRewards") {
          data.dailyArcadeCoinReward = edit?.dailyArcadeCoinReward;
          data.dailyLootBonusCoinReward = edit?.dailyLootBonusCoinReward;
        } else if (type === "threeDayStreakRewards") {
          data.threeDayStreakArcadeCoinReward = edit?.threeDayStreakArcadeCoinReward;
          data.threeDayStreakLootBonusCoinReward = edit?.threeDayStreakLootBonusCoinReward;
        } else if (type === "weeklyStreakRewards") {
          data.weeklyStreakArcadeCoinReward = edit?.weeklyStreakArcadeCoinReward;
          data.weeklyStreakLootBonusCoinReward = edit?.weeklyStreakLootBonusCoinReward;
        } else if (type === "creditPriceBonusPercent") {
          data.creditPriceBonusPercent = edit?.creditPriceBonusPercent;
        } else if (type === "referralBonusPercent") {
          data.referralBonusPercent = edit?.referralBonusPercent;
        } else if (type === "dailyGameChallengeRewards") {
          data.dailyGameChallengeArcadeCoinReward = edit?.dailyGameChallengeArcadeCoinReward;
          data.dailyGameChallengeLootBonusCoinReward = edit?.dailyGameChallengeLootBonusCoinReward;
        } else if (type === "resumeAutoSuspensionDays") {
          data.resumeAutoSuspensionDays = edit?.resumeAutoSuspensionDays;
        }
        this.setState({ data });
      }
    }
  };
  doSubmit = (e) => {
    this.setState({ loadingCredits: true });
    const data = structuredClone(this.state.data);
    const payLoad = {};
    if (data.type === "email") {
      payLoad.duplicateIPsNotificationEmails = data.duplicateIPsNotificationEmails
        ?.split(",")
        ?.map((el) => el.trim())
        ?.filter((el) => el !== "");
      payLoad.sqsErrorNotificationEmails = data.sqsErrorNotificationEmails
        ?.split(",")
        ?.map((el) => el.trim())
        ?.filter((el) => el !== "");
      payLoad.lowBalanceNotificationEmails = data.lowBalanceNotificationEmails
        ?.split(",")
        ?.map((el) => el.trim())
        ?.filter((el) => el !== "");
      payLoad.contestSweepstakesEndTimeReminderEmails = data.contestSweepstakesEndTimeReminderEmails
        ?.split(",")
        ?.map((el) => el.trim())
        ?.filter((el) => el !== "");
      payLoad.lootBoxPrizeEmails = data.lootBoxPrizeEmails
        ?.split(",")
        ?.map((el) => el.trim())
        ?.filter((el) => el !== "");
      payLoad.kycVerificationEmails = data.kycVerificationEmails
        ?.split(",")
        ?.map((el) => el.trim())
        ?.filter((el) => el !== "");
      payLoad.fraudDetectionEmails = data.fraudDetectionEmails
        ?.split(",")
        ?.map((el) => el.trim())
        ?.filter((el) => el !== "");
      payLoad.contestSchedularEmails = data.contestSchedularEmails
        ?.split(",")
        ?.map((el) => el.trim())
        ?.filter((el) => el !== "");
    } else if (data.type === "chainLink") {
      payLoad.chainlinkVRFCoodinatorAddress = data.chainlinkVRFCoodinatorAddress;
      payLoad.chainlinkSubscriptionId = parseInt(data.chainlinkSubscriptionId);
      payLoad.chainlinkVRFCoodinatorABI = data.chainlinkVRFCoodinatorABI;
    } else if (data.type === "walletBalance") {
      payLoad.minBGWalletBalance = parseInt(data.minBGWalletBalance);
      payLoad.minChainLinkBalance = parseInt(data.minChainLinkBalance);
    } else if (data.type === "freeSignUpCredits") {
      payLoad.freeSignupArcadeCoinCredits = parseInt(data.freeSignupArcadeCoinCredits);
      payLoad.freeSignupLootBonusCoinCredits = parseFloat(data.freeSignupLootBonusCoinCredits);
    } else if (data.type === "dailyRewards") {
      payLoad.dailyArcadeCoinReward = parseInt(data.dailyArcadeCoinReward);
      payLoad.dailyLootBonusCoinReward = parseFloat(data.dailyLootBonusCoinReward);
    } else if (data.type === "threeDayStreakRewards") {
      payLoad.threeDayStreakArcadeCoinReward = parseInt(data.threeDayStreakArcadeCoinReward);
      payLoad.threeDayStreakLootBonusCoinReward = parseFloat(data.threeDayStreakLootBonusCoinReward);
    } else if (data.type === "weeklyStreakRewards") {
      payLoad.weeklyStreakArcadeCoinReward = parseInt(data.weeklyStreakArcadeCoinReward);
      payLoad.weeklyStreakLootBonusCoinReward = parseFloat(data.weeklyStreakLootBonusCoinReward);
    } else if (data.type === "creditPriceBonusPercent") {
      payLoad.creditPriceBonusPercent = parseInt(data.creditPriceBonusPercent);
    } else if (data.type === "referralBonusPercent") {
      payLoad.referralBonusPercent = parseFloat(data.referralBonusPercent);
    } else if (data.type === "dailyGameChallengeRewards") {
      payLoad.dailyGameChallengeArcadeCoinReward = parseInt(data.dailyGameChallengeArcadeCoinReward);
      payLoad.dailyGameChallengeLootBonusCoinReward = parseFloat(data.dailyGameChallengeLootBonusCoinReward);
    } else if (data.type === "resumeAutoSuspensionDays") {
      payLoad.resumeAutoSuspensionDays = parseInt(data?.resumeAutoSuspensionDays);
    }
    this.props.addSettings(payLoad, (settingsResponse) => {
      if (settingsResponse.status === 200) {
        toast(<AlertSuccess message="Information Saved" />, {
          containerId: 1,
        });
        const settings = settingsResponse.data.data;
        this.props.loadSettings(() => {
          this.props.toggleModal();
          this.clearData();
          this.setState({
            loadingCredits: false,
          });
        });
      } else {
        this.setState({
          loadingCredits: false,
        });
        toast(<AlertError message={settingsResponse.data && settingsResponse.data.message} />, {
          containerId: 1,
        });
      }
    });
  };
  schema = {
    type: Joi.string().allow(""),
    duplicateIPsNotificationEmails: Joi.when("type", {
      is: "email",
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    sqsErrorNotificationEmails: Joi.when("type", {
      is: "email",
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    lowBalanceNotificationEmails: Joi.when("type", {
      is: "email",
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    contestSweepstakesEndTimeReminderEmails: Joi.when("type", {
      is: "email",
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    lootBoxPrizeEmails: Joi.when("type", {
      is: "email",
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    kycVerificationEmails: Joi.when("type", {
      is: "email",
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    fraudDetectionEmails: Joi.when("type", {
      is: "email",
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    contestSchedularEmails: Joi.when("type", {
      is: "email",
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    chainlinkVRFCoodinatorAddress: Joi.when("type", {
      is: "chainLink",
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    chainlinkSubscriptionId: Joi.when("type", {
      is: "chainLink",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    chainlinkVRFCoodinatorABI: Joi.when("type", {
      is: "chainLink",
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),

    minBGWalletBalance: Joi.when("type", {
      is: "walletBalance",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    minChainLinkBalance: Joi.when("type", {
      is: "walletBalance",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),

    freeSignupArcadeCoinCredits: Joi.when("type", {
      is: "freeSignUpCredits",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    freeSignupLootBonusCoinCredits: Joi.when("type", {
      is: "freeSignUpCredits",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),

    dailyArcadeCoinReward: Joi.when("type", {
      is: "dailyRewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    dailyLootBonusCoinReward: Joi.when("type", {
      is: "dailyRewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),

    threeDayStreakArcadeCoinReward: Joi.when("type", {
      is: "threeDayStreakRewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    threeDayStreakLootBonusCoinReward: Joi.when("type", {
      is: "threeDayStreakRewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),

    weeklyStreakArcadeCoinReward: Joi.when("type", {
      is: "weeklyStreakRewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    weeklyStreakLootBonusCoinReward: Joi.when("type", {
      is: "weeklyStreakRewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),

    creditPriceBonusPercent: Joi.when("type", {
      is: "creditPriceBonusPercent",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    referralBonusPercent: Joi.when("type", {
      is: "referralBonusPercent",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    dailyGameChallengeArcadeCoinReward: Joi.when("type", {
      is: "dailyGameChallengeRewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    dailyGameChallengeLootBonusCoinReward: Joi.when("type", {
      is: "dailyGameChallengeRewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    resumeAutoSuspensionDays: Joi.when("type", {
      is: "resumeAutoSuspensionDays",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        type: "email",
        duplicateIPsNotificationEmails: "",
        sqsErrorNotificationEmails: "",
        lowBalanceNotificationEmails: "",
        contestSweepstakesEndTimeReminderEmails: "",
        lootBoxPrizeEmails: "",
        kycVerificationEmails: "",
        fraudDetectionEmails: "",
        contestSchedularEmails: "",

        chainlinkVRFCoodinatorAddress: "",
        chainlinkSubscriptionId: "",
        chainlinkVRFCoodinatorABI: "",

        minBGWalletBalance: "",
        minChainLinkBalance: "",

        freeSignupArcadeCoinCredits: "",
        freeSignupLootBonusCoinCredits: "",

        dailyArcadeCoinReward: "",
        dailyLootBonusCoinReward: "",

        threeDayStreakArcadeCoinReward: "",
        threeDayStreakLootBonusCoinReward: "",

        weeklyStreakArcadeCoinReward: "",
        weeklyStreakLootBonusCoinReward: "",

        creditPriceBonusPercent: "",
        referralBonusPercent: "",

        dailyGameChallengeArcadeCoinReward: "",
        dailyGameChallengeLootBonusCoinReward: "",

        resumeAutoSuspensionDays: "",
      },
      errors: {},
      loadingCredits: false,
    });
  };
  changeArrayText = (e) => {
    const data = structuredClone(this.state.data);
    const errors = structuredClone(this.state.errors);
    data[e.target.name] = e.target.value;
    delete errors[e.target.name];
    this.setState({ data });
  };

  render() {
    const data = structuredClone(this.state.data);
    console.log("errors:", this.state.errors);
    const type = this.state.data.type;
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel half-width-modal"}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>{this.props.modalTitle?.title}</h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <table className="table large-table flyer-table">
                {type === "email" ? (
                  <tbody>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Duplicate IP Notification Emails</td>
                      <td>{this.renderInput("duplicateIPsNotificationEmails", "")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">SQS Error Notification Emails</td>
                      <td>{this.renderInput("sqsErrorNotificationEmails", "")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Low Balance Notification Emails</td>
                      <td>{this.renderInput("lowBalanceNotificationEmails", "")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Contest/Sweepstakes End Time Reminder Emails </td>
                      <td>{this.renderInput("contestSweepstakesEndTimeReminderEmails", "")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Loot Box Prize Emails </td>
                      <td>{this.renderInput("lootBoxPrizeEmails", "")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">KYC Verification Emails </td>
                      <td>{this.renderInput("kycVerificationEmails", "")}</td>
                    </tr>{" "}
                    <tr className="flyer-table">
                      <td className="vertical-middle">Fraud Detection Emails </td>
                      <td>{this.renderInput("fraudDetectionEmails", "")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Contest Schedular Emails </td>
                      <td>{this.renderInput("contestSchedularEmails", "")}</td>
                    </tr>
                  </tbody>
                ) : type === "chainLink" ? (
                  <tbody>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Chain Link VRF Coordinator Address</td>
                      <td>{this.renderInput("chainlinkVRFCoodinatorAddress", "")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Chain Link Subscription ID</td>
                      <td>{this.renderInput("chainlinkSubscriptionId", "", "", "number")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Chain Link VRF Coordinator ABI</td>
                      <td>{this.renderInput("chainlinkVRFCoodinatorABI", "")}</td>
                    </tr>
                  </tbody>
                ) : type === "freeSignUpCredits" ? (
                  <tbody>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Free Sign Up Arcade Coins</td>
                      <td>{this.renderInput("freeSignupArcadeCoinCredits", "", "", "number")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Free Sign Up Loot Bucks</td>
                      <td>{this.renderInput("freeSignupLootBonusCoinCredits", "", "", "number")}</td>
                    </tr>
                  </tbody>
                ) : type === "dailyRewards" ? (
                  <tbody>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Daily Rewards Arcade Coins</td>
                      <td>{this.renderInput("dailyArcadeCoinReward", "", "", "number")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Daily Rewards Loot Bucks</td>
                      <td>{this.renderInput("dailyLootBonusCoinReward", "", "", "number")}</td>
                    </tr>
                  </tbody>
                ) : type === "threeDayStreakRewards" ? (
                  <tbody>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Three Day Streak Rewards Arcade Coins</td>
                      <td>{this.renderInput("threeDayStreakArcadeCoinReward", "", "", "number")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Three Day Streak Rewards Loot Bucks</td>
                      <td>{this.renderInput("threeDayStreakLootBonusCoinReward", "", "", "number")}</td>
                    </tr>
                  </tbody>
                ) : type === "weeklyStreakRewards" ? (
                  <tbody>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Weekly Streak Rewards Arcade Coins</td>
                      <td>{this.renderInput("weeklyStreakArcadeCoinReward", "", "", "number")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Weekly Streak Rewards Loot Bucks</td>
                      <td>{this.renderInput("weeklyStreakLootBonusCoinReward", "", "", "number")}</td>
                    </tr>
                  </tbody>
                ) : type === "creditPriceBonusPercent" ? (
                  <tbody>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Package Bonus %</td>
                      <td>{this.renderInput("creditPriceBonusPercent", "", "", "number")}</td>
                    </tr>
                  </tbody>
                ) : type === "referralBonusPercent" ? (
                  <tbody>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Referral Bonus %</td>
                      <td>{this.renderInput("referralBonusPercent", "", "", "number")}</td>
                    </tr>
                  </tbody>
                ) : type === "dailyGameChallengeRewards" ? (
                  <tbody>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Daily Game Challenge Rewards Arcade Coins</td>
                      <td>{this.renderInput("dailyGameChallengeArcadeCoinReward", "", "", "number")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Daily Game Challenge Rewards Loot Bucks</td>
                      <td>{this.renderInput("dailyGameChallengeLootBonusCoinReward", "", "", "number")}</td>
                    </tr>
                  </tbody>
                ) : type === "resumeAutoSuspensionDays" ? (
                  <tbody>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Resume Auto Suspension Days</td>
                      <td>{this.renderInput("resumeAutoSuspensionDays", "", "", "number")}</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Minimum BG Wallet Balance</td>
                      <td>{this.renderInput("minBGWalletBalance", "", "", "number")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Minimum Chain Link Balance</td>
                      <td>{this.renderInput("minChainLinkBalance", "", "", "number")}</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loadingCredits}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addSettings: (data, callback) => dispatch(addSettings(data, callback)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFlyer));
