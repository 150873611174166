import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "user",
  initialState: { bgBalance: null, balanceLoading: false },
  reducers: {
    balanceRequested: (misc, action) => {
      misc.balanceLoading = true;
    },
    balanceReceived: (misc, action) => {
      misc.bgBalance = action.payload.data;
      misc.balanceLoading = false;
    },
    balanceRequestFailed: (misc, action) => {
      misc.balanceLoading = false;
    },
  },
});

export const { balanceRequested, balanceReceived, balanceRequestFailed } = slice.actions;
export default slice.reducer;

// Action Creators
const authUrl = "auth/admin/";

export const signIn = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: authUrl + "login",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const logout = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: authUrl + "logout",
      method: "POST",
      callback,
    }),
  );
};
export const loadBGBalance = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "checkBalance",
      onStart: balanceRequested.type,
      onSuccess: balanceReceived.type,
      onError: balanceRequestFailed.type,
      callback,
    }),
  );
};
export const getUser = createSelector(
  (state) => state.entities.user,
  (user) => user,
);
