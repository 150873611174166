import React, { Component } from "react";
import { getToken, getProfile, clearAllToken } from "utils/localStorageServices";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUser, logout, loadBGBalance } from "store/user";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";

//  Images
import logo from "include/images/burnghostHorizontal.png";
import arrowIcom from "include/images/chevron-down1.svg";

//  Components
import { getSettings, purgeCache } from "store/settings";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";
class Header extends Component {
  state = { loading: false };
  componentDidMount = () => {
    this.props.loadBGBalance(null);
  };
  render() {
    const user = getProfile();
    if (!user) {
      this.props.history.push("/signin");
    }
    const domain = window.location.host;
    const { bgBalance, balanceLoading } = this.props.getUser;
    console.log("balance:", bgBalance);
    return (
      <header id="header" className="text-white">
        <div
          className={`header-top text-center d-flex align-items-center ${
            process.env.REACT_APP_ADMIN_SITE_URL === "https://www.admin-play.burnghost.com/"
              ? "red-bg"
              : process.env.REACT_APP_ADMIN_SITE_URL === "https://admin-staging.burnghost.com/"
              ? "green-bg"
              : ""
          }`}
        >
          <div className="container-fluid">
            <p>
              THIS IS A{" "}
              {process.env.REACT_APP_ADMIN_SITE_URL === "https://admin-staging.burnghost.com/"
                ? "STAGING "
                : process.env.REACT_APP_ADMIN_SITE_URL === "https://www.admin-play.burnghost.com/"
                ? "LIVE "
                : "DEV "}
              SERVER
            </p>
          </div>
        </div>
        {user && (
          <div className="header-main">
            <nav className="navbar navbar-expand-xl">
              <div className="container-fluid">
                <div className="nav-inside d-flex align-items-center justify-content-between">
                  <a className="navbar-brand" href="/">
                    <img src={logo} alt="" />
                  </a>
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="ust-tag d-flex">
                      {/* Red color button for clearing api cache */}
                      {this.props.getSettings.purgingCache ? (
                        <Skeleton
                          variant="rectangular"
                          width={128.6}
                          height={30}
                          style={{ background: "#44474e" }}
                          className="m-2 me-0"
                        />
                      ) : (
                        <div className="custom-dropdown  m-2">
                          <button
                            className="custom-dropdown-toggle"
                            onClick={() => {
                              this.props.purgeCache((res) => {
                                if (res.status === 200) {
                                  toast(<AlertSuccess message="Cache Cleared Successfully" />, {
                                    containerId: 1,
                                  });
                                } else {
                                  toast(<AlertError message="Error in clearing cache" />, {
                                    containerId: 1,
                                  });
                                }
                              });
                            }}
                          >
                            <span
                              className={`w-100 text-center d-block text-white text-center d-block mx-2 btn-danger`}
                            >
                              Purge Cache
                            </span>
                          </button>
                        </div>
                      )}

                      {balanceLoading ? (
                        <>
                          <Skeleton
                            variant="rectangular"
                            width={180}
                            height={30}
                            style={{ background: "#44474e", marginRight: 10 }}
                            className="m-2 ms-3"
                          />
                          <Skeleton
                            variant="rectangular"
                            width={202}
                            height={30}
                            style={{ background: "#44474e" }}
                            className="m-2 me-0"
                          />
                        </>
                      ) : (
                        <>
                          <Dropdown className=" custom-dropdown  my-2 mx-2">
                            <Dropdown.Toggle
                              id="tdDropdownOne"
                              className="custom-dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span
                                className={`w-100 text-center d-block text-white text-center d-block balance1 mx-2`}
                              >
                                BG Wallet Balance <img src={arrowIcom} />
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className={`dropdown-menu-middle `} aria-labelledby="tdDropdownOne">
                              <>
                                {bgBalance?.nativeTokenBalance?.map((bal) => (
                                  <Dropdown.Item
                                    className="dropdown-item pointer-default"
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    {bal?.title} : {bal?.balance ?? 0}
                                  </Dropdown.Item>
                                ))}
                              </>
                            </Dropdown.Menu>
                          </Dropdown>

                          <Dropdown className=" custom-dropdown  my-2 mx-2">
                            <Dropdown.Toggle
                              id="tdDropdownOne"
                              className="custom-dropdown-toggle pointer-none"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span
                                className={`w-100 text-center d-block text-white text-center d-block balance2 mx-2`}
                              >
                                Chain Link Balance:{" "}
                                {parseFloat(bgBalance?.chainlinkVRFSubscriptionIdBalance)?.toLocaleString(undefined, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 4,
                                })}
                              </span>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </>
                      )}
                    </div>
                    <div className="header-right">
                      <Dropdown className="kt-header position-relative custom-dropdown">
                        <Dropdown.Toggle
                          className="kt-header-togger d-flex flex-wrap align-items-center custom-dropdown-toggle2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="kt-header-topbar-username d-flex flex-column ">
                            {user.firstName} {user.lastName}
                            <span>Super Admin</span>
                          </div>
                          <div className="kt-heade-topbar-wrapper">
                            <div className="profile-initials img-circle d-flex align-items-center justify-content-center">
                              <p>
                                {user.firstName[0]}
                                {user.lastName[0]}
                              </p>
                            </div>
                          </div>
                          <div className="togger-icon">
                            <img src={arrowIcom} alt="" />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                          <Dropdown.Item
                            className="dropdown-item"
                            type="button"
                            onClick={(e) => {
                              this.props.logout(() => {
                                toast.dismiss(2);
                                clearAllToken();
                              });
                              this.props.history.push("/signin");
                            }}
                          >
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        )}
      </header>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  logout: (callback) => dispatch(logout(callback)),
  loadBGBalance: (callback) => dispatch(loadBGBalance(callback)),
  purgeCache: (callback) => dispatch(purgeCache(callback)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
