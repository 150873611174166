import React from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import { getUsers, deductTickets, updateUserCreditsReceived, deductCredits } from "store/users";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Joi from "joi-browser";
import { fetchNFT, ethToUSD } from "utils/externalApiCaller";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import Amplify, { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import userIcon from "include/images/user.png";
import plusIcon from "include/images/plus-1.svg";
import ethIcon from "include/images/eth-icon.svg";
import deleteIcon from "include/images/delete-icon.svg";

//  Components
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";

class AddFlyer extends Form {
  state = {
    loading: false,
    data: {
      rewards: "",
    },
    errors: {},
    loadingCredits: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
    }
  };
  doSubmit = (e) => {
    const errors = structuredClone(this.state.errors);
    const data = structuredClone(this.state.data);
    if (isNaN(data.rewards) || data.rewards == 0) {
      errors.rewards = "Rewards cannot be 0";
      return this.setState({ errors });
    }

    this.setState({ loadingCredits: true });
    const payLoad = {
      userId: this.props.user?.id,
    };
    if (this.props.rewardType === 1) {
      payLoad.xP = parseInt(data.rewards);
      payLoad.arcadeCoins = 0;
      payLoad.lootBonusCoins = 0;
    } else if (this.props.rewardType === 2) {
      payLoad.xP = 0;
      payLoad.arcadeCoins = parseInt(data.rewards);
      payLoad.lootBonusCoins = 0;
    } else {
      payLoad.xP = 0;
      payLoad.lootBonusCoins = parseFloat(data.rewards);
      payLoad.arcadeCoins = 0;
    }
    this.props.deductCredits(payLoad, this.callback);
  };
  callback = (res) => {
    this.setState({
      loadingCredits: false,
    });
    if (res.status === 200) {
      this.props.updateUserCreditsReceived({
        type: "deduct",
        id: this.props.user.id,
        rewardType: this.props.rewardType,
        data: this.props.rewardType === 3 ? parseFloat(this.state.data.rewards) : parseInt(this.state.data.rewards),
      });
      toast(<AlertSuccess message="Information Saved" />, {
        containerId: 1,
      });
      this.props.toggleModal();
      this.clearData();
    } else {
      toast(<AlertError message={res.data && res.data.message} />, {
        containerId: 1,
      });
    }
  };
  schema = {
    rewards: Joi.alternatives()
      .try(Joi.string(), Joi.number())
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Reward is required.";
              break;
          }
        });
        return errors;
      }),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: { rewards: "" },
      errors: {},
      loadingCredits: false,
    });
  };

  render() {
    const { user, rewardType } = this.props;
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel"}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header className="offCanvas-header-2">
          <div>
            <h5>Deduct {rewardType === 1 ? "XP" : rewardType === 2 ? "Arcade Coins" : "Loot Bucks"}</h5>
            <div className="td-img-group d-flex align-items-start m-top-2">
              <div className="round-img m-right-2">
                {user.image ? (
                  <img src={process.env.REACT_APP_CDN + user.image.small} alt="" />
                ) : (
                  <img src={userIcon} alt="" />
                )}
              </div>
              {user.userName}
              <br />
              {user.email}
            </div>
          </div>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <table className="table large-table flyer-table">
                <tbody>
                  <tr className="flyer-table">
                    <td>
                      <input
                        name="credit"
                        type={"number"}
                        value={this.state.data.rewards}
                        className={`form-control ${this.state.errors.rewards && this.state.errors.rewards && "error"}`}
                        onChange={(e) => {
                          this.setState({ data: { rewards: e.target.value } });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loadingCredits}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  deductTickets: (data, callback) => dispatch(deductTickets(data, callback)),
  deductCredits: (data, callback) => dispatch(deductCredits(data, callback)),
  updateUserCreditsReceived: (payLoad) => dispatch(updateUserCreditsReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getUsers: getUsers(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFlyer));
