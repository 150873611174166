import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "sweepstake",
  initialState: {
    contracts: [],
    currencies: [],
    prizes: [],
    instances: [],
    instanceInfo: null,
    loading: false,
    leaderboard: [],
    contractStatus: {},
    contractStatusLoading: false,
  },
  reducers: {
    sweepRequested: (state, action) => {
      state.loading = true;
    },
    sweepRequestFailed: (state, action) => {
      state.loading = false;
    },

    //  Currency
    currenciesReceived: (state, action) => {
      state.currencies = action.payload.data;
      state.loading = false;
    },
    addCurrenciesReceived: (state, action) => {
      state.currencies = [...state.currencies, action.payload];
      state.loading = false;
    },
    updateCurrenciesReceived: (state, action) => {
      const ind = state.currencies.findIndex((currency) => currency.id === action.payload.id);
      const newCurrencies = state.currencies;
      newCurrencies[ind] = { ...newCurrencies[ind], ...action.payload };
      state.currencies = [...newCurrencies];
    },
    deleteCurrencyReceived: (state, action) => {
      const ind = state.currencies.findIndex((currency) => currency.id === action.payload.id);
      const newCurrencies = state.currencies;
      newCurrencies.splice(ind, 1);
      state.currencies = [...newCurrencies];
    },

    //  Contract
    contractReceived: (state, action) => {
      state.contracts = action.payload.data;
      state.loading = false;
    },
    addContractReceived: (state, action) => {
      state.contracts = [...state.contracts, action.payload];
      state.loading = false;
    },
    updateContractReceived: (state, action) => {
      const ind = state.contracts.findIndex((contract) => contract.id === action.payload.id);
      const newContracts = state.contracts;
      newContracts[ind] = { ...newContracts[ind], ...action.payload };
      state.contracts = [...newContracts];
    },
    deleteContractReceived: (state, action) => {
      const ind = state.contracts.findIndex((contract) => contract.id === action.payload.id);
      const newContracts = state.contracts;
      newContracts.splice(ind, 1);
      state.contracts = [...newContracts];
    },
    contractStatusRequested: (state, action) => {
      state.contractStatusLoading = true;
    },
    contractStatusReceived: (state, action) => {
      state.contractStatus = action.payload.data;
      state.contractStatusLoading = false;
    },
    contractStatusRequestFailed: (state, action) => {
      state.contractStatusLoading = false;
    },
    //  Prizes
    prizesReceived: (state, action) => {
      state.prizes = action.payload.data;
      state.loading = false;
    },
    addPrizeReceived: (state, action) => {
      state.prizes = [...state.prizes, action.payload];
      state.loading = false;
    },
    updatePrizeReceived: (state, action) => {
      const ind = state.prizes.findIndex((prize) => prize.id === action.payload.id);
      const newPrizes = state.prizes;
      newPrizes[ind] = { ...newPrizes[ind], ...action.payload };
      state.prizes = [...newPrizes];
    },
    deletePrizeReceived: (state, action) => {
      const ind = state.prizes.findIndex((prize) => prize.id === action.payload.id);
      const newPrizes = state.prizes;
      newPrizes.splice(ind, 1);
      state.prizes = [...newPrizes];
    },

    //  Instances
    instanceReceived: (state, action) => {
      state.instances = action.payload.data;
      state.loading = false;
    },
    addInstanceReceived: (state, action) => {
      state.instances = [...state.instances, action.payload];
      state.loading = false;
    },
    updateInstanceReceived: (state, action) => {
      const ind = state.instances.findIndex((instance) => instance.id === action.payload.id);
      const newInstances = state.instances;
      newInstances[ind] = { ...newInstances[ind], ...action.payload };
      state.instances = [...newInstances];
    },
    updateInstanceInfoReceived: (state, action) => {
      state.instanceInfo = action.payload;
    },
    deleteInstanceReceived: (state, action) => {
      const ind = state.instances.findIndex((instance) => instance.id === action.payload.id);
      const newInstances = state.instances;
      newInstances.splice(ind, 1);
      state.instances = [...newInstances];
    },

    //  Instance leaderboard
    instanceLeaderboardReceived: (state, action) => {
      state.leaderboard = action.payload.data;
      state.loading = false;
    },

    //  Instances info
    instanceInfoReceived: (state, action) => {
      state.instanceInfo = action.payload.data;
      state.loading = false;
    },
  },
});

export const {
  sweepRequested,
  sweepRequestFailed,
  // Currency
  currenciesReceived,
  deleteCurrencyReceived,
  addCurrenciesReceived,
  updateCurrenciesReceived,
  // Contract
  contractReceived,
  addContractReceived,
  updateContractReceived,
  deleteContractReceived,
  contractStatusRequested,
  contractStatusReceived,
  contractStatusRequestFailed,
  //Prizes
  prizesReceived,
  addPrizeReceived,
  updatePrizeReceived,
  deletePrizeReceived,
  //  Instances
  instanceReceived,
  addInstanceReceived,
  updateInstanceReceived,
  deleteInstanceReceived,
  //  Leaderboard
  instanceLeaderboardReceived,
  //  Instances Info
  instanceInfoReceived,
  updateInstanceInfoReceived,
} = slice.actions;
export default slice.reducer;

// Action Creators
const sweepstakeURL = "sweeps/";
const currencyURL = sweepstakeURL + "currency/";
const contractURL = sweepstakeURL + "contract/";
const prizeURL = sweepstakeURL + "prize/";
const instanceURL = sweepstakeURL + "instance/";

//  ===>>> Currencies Start
export const loadCurrencies = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: currencyURL,
      onStart: sweepRequested.type,
      onSuccess: currenciesReceived.type,
      onError: sweepRequestFailed.type,
      callback,
    }),
  );
};
export const addCurrency = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: currencyURL,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateCurrency = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: currencyURL + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteCurrency = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: currencyURL + id,
      method: "DELETE",
      callback,
    }),
  );
};
//  ===>>> Currencies End

//  ===>>> Contract Start
export const loadContract = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contractURL,
      onStart: sweepRequested.type,
      onSuccess: contractReceived.type,
      onError: sweepRequestFailed.type,
      callback,
      params,
    }),
  );
};
export const addContract = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contractURL,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateContract = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contractURL + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteContract = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: prizeURL + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const checkContractStatus = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contractURL + "status/" + id,
      onStart: contractStatusRequested.type,
      onSuccess: contractStatusReceived.type,
      onError: contractStatusRequestFailed.type,
      callback,
    }),
  );
};
export const cancelContract = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contractURL + "cancelInstance/" + id,
      method: "POST",
      callback,
    }),
  );
};
//  ===>>> Contract End

//  ===>>> Prizes Start
export const loadPrizes = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: prizeURL,
      onStart: sweepRequested.type,
      onSuccess: prizesReceived.type,
      onError: sweepRequestFailed.type,
      callback,
    }),
  );
};
export const addPrizes = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: prizeURL,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updatePrizes = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: prizeURL + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deletePrizes = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: prizeURL + id,
      method: "DELETE",
      callback,
    }),
  );
};
//  ===>>> Prizes End

//  ===>>> Instances Start
export const loadInstances = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: instanceURL,
      params,
      onStart: sweepRequested.type,
      onSuccess: instanceReceived.type,
      onError: sweepRequestFailed.type,
      callback,
    }),
  );
};
export const addInstances = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: instanceURL,
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateInstances = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: instanceURL + id,
      method: "PUT",
      data,
      callback,
    }),
  );
};
export const deleteInstances = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: instanceURL + id,
      method: "DELETE",
      callback,
    }),
  );
};
export const cancelInstance = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: instanceURL + "cancel/" + id,
      method: "PUT",
      callback,
    }),
  );
};
export const loadInstanceInfo = (slug, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: instanceURL + slug,
      onStart: sweepRequested.type,
      onSuccess: instanceInfoReceived.type,
      onError: sweepRequestFailed.type,
      callback,
    }),
  );
};
export const loadInstanceLeaderboard = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: instanceURL + "leaderboard/" + id,
      onStart: sweepRequested.type,
      onSuccess: instanceLeaderboardReceived.type,
      onError: sweepRequestFailed.type,
      callback,
    }),
  );
};
export const loadSweepstakeWinners = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: sweepstakeURL + "winners",
      params,
      callback,
    }),
  );
};
//  Winner Selection Workflow
export const requestRandomNumber = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: instanceURL + "randomNumber/" + id,
      method: "POST",
      callback,
    }),
  );
};
export const selectInstanceWinner = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: instanceURL + "selectWinner/" + id,
      method: "POST",
      callback,
    }),
  );
};
export const transferInstancePrize = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: instanceURL + "transferPrize/" + id,
      method: "POST",
      callback,
    }),
  );
};
//  ===>>> Instances End

export const getSweepstake = createSelector(
  (state) => state.entities.sweepstake,
  (sweepstake) => sweepstake,
);
