import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  getSettings,
  loadCreditPrices,
  deleteCreditPrices,
  deleteCreditPricesReceived,
} from "store/settings";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import _ from "lodash";
import moment from "moment";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import historyIcon from "include/images/history-icon.svg";
import plusIcon from "include/images/plus.svg";

//  Components
import Header from "common/header";
import SideBar from "../../../common/sidebar";
import NextButton from "common/form/submitButton";
import Loader from "common/loader";
import AlertSuccess from "common/alert/alertSuccess";
import SelectSearch from "common/form/selectSearch";
import AlertError from "common/alert/alertError";
import DeleteModal from "common/alert/alertModal";
import AddCoinsPackage from "./addCoinsPackage";
import HistoryFlyer from "common/historyFlyer";

class MessageListing extends Component {
  ref = createRef(null);
  state = {
    data: {
      messages: [],
    },
    id: "",
    loadingOptions: false,
    loadingMain: false,
    loadingDelete: false,
    loading: false,
    showDeleteModal: false,
    showAddModal: false,
    deleteId: "",
    editData: null,
    showHistory: false,
    objectId: "",
    object: null,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.getSettings.loading &&
      this.props.getSettings.loading !== prevProps.getSettings.loading
    )
      this.ref.current.complete();

    if (
      this.props.getSettings.loading &&
      this.props.getSettings.loading !== prevProps.getSettings.loading
    )
      this.ref.current.continuousStart();
    if (
      this.state.showAddModal !== prevState.showAddModal &&
      !this.state.showAddModal
    ) {
      setTimeout(() => {
        this.setState({ editData: null });
      }, 500);
    }
  };
  componentDidMount = () => {
    this.setState({ loadingMain: true });
    this.props.loadCreditPrices((res) => {
      this.setState({
        loadingMain: false,
      });
    });
  };

  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };
  handleDelete = (e) => {
    this.setState({ loadingDelete: true });
    this.props.deleteCreditPrices(this.state.deleteId, (templateRes) => {
      if (templateRes.status == 200) {
        toast(<AlertSuccess message="Deleted!" />, {
          containerId: 1,
        });
        this.props.deleteCreditPricesReceived({ id: this.state.deleteId });
        this.alertModalToggle();
        this.setState({ loadingDelete: false });
      } else {
        toast(
          <AlertError message={templateRes.data && templateRes.data.message} />,
          {
            containerId: 1,
          }
        );
        this.alertModalToggle();
        this.setState({ loadingDelete: false });
      }
    });
  };
  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  render() {
    const { creditPrices } = this.props.getSettings;
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="customText" />

          {!this.state.loadingMain && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1>Packages</h1>
                <div className="filter-menu-box d-flex align-items-center ms-auto">
                  <div className="fmb-box">
                    <Link
                      to="#"
                      onClick={this.toggleAddModal}
                      className="btn btn-default btn-sm"
                    >
                      <img src={plusIcon} alt="" /> Add a Package
                    </Link>
                  </div>
                </div>
              </div>
              {creditPrices?.length > 0 && (
                <div className="fwc-wrapper">
                  <div className="fwc-body">
                    <div className="table-responsive">
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th style={{ width: 75, textAlign: "center" }}>
                              Actions
                            </th>
                            <th>Title</th>
                            <th>Price</th>
                            <th>Arcade Coins</th>
                            <th>Loot Bonus Bucks</th>
                            <th>Created On</th>
                          </tr>
                        </thead>
                        <tbody>
                          {creditPrices?.map((packageItem, index) => (
                            <tr>
                              <td>
                                <Dropdown className=" custom-dropdown">
                                  <Dropdown.Toggle
                                    id="tdDropdownOne"
                                    className="custom-dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img src={carrentIcon} alt="" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    className={`dropdown-menu-start `}
                                    aria-labelledby="tdDropdownOne"
                                  >
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState(
                                          { editData: packageItem },
                                          () => {
                                            this.toggleAddModal();
                                          }
                                        );
                                      }}
                                    >
                                      Edit
                                    </Dropdown.Item>{" "}
                                    <Dropdown.Item
                                      className="dropdown-item delete"
                                      onClick={(e) => {
                                        this.setState(
                                          { deleteId: packageItem.id },
                                          () => {
                                            this.alertModalToggle(e);
                                          }
                                        );
                                      }}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                <Link
                                  className="two-line-ellipsis"
                                  to={"#"}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState(
                                      { editData: packageItem },
                                      () => {
                                        this.toggleAddModal();
                                      }
                                    );
                                  }}
                                >
                                  {packageItem.description}
                                </Link>
                              </td>{" "}
                              <td>
                                $
                                {packageItem.price?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td>
                                {packageItem.arcadeCoins?.toLocaleString(
                                  "en-us"
                                )}
                              </td>
                              <td>
                                {packageItem.lootBonusCoins?.toLocaleString(
                                  "en-us"
                                )}
                              </td>
                              <td>
                                {moment(packageItem.createdAt)?.format(
                                  "MM/DD/YYYY hh:mm A"
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <AddCoinsPackage
          showModal={this.state.showAddModal}
          toggleModal={this.toggleAddModal}
          loadData={this.props.loadCreditPrices}
          edit={this.state.editData}
        />
        <DeleteModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showDeleteModal}
          title="Alert"
          description="Are you sure you want to delete this item?"
          onPress={this.handleDelete}
          loading={this.state.loadingDelete}
        />
        {(this.state.showAddModal || this.state.showHistory) && (
          <div
            className="modal-backdrop show fade"
            onClick={
              this.state.showHistory
                ? this.toggleHistoryFlyer
                : this.toggleAddModal
            }
          ></div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadCreditPrices: (callback) => dispatch(loadCreditPrices(callback)),
  deleteCreditPrices: (id, callback) =>
    dispatch(deleteCreditPrices(id, callback)),
  deleteCreditPricesReceived: (payLoad) =>
    dispatch(deleteCreditPricesReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MessageListing)
);
