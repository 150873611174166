import React, { createRef } from "react";
import { toast } from "react-toastify";
import { Offcanvas, OverlayTrigger, Popover } from "react-bootstrap";
import { getGame, loadGames, markAsWinner, selectedGame } from "store/games";
import moment from "moment/moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

//  Images
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";
import closeIcon from "include/images/dark-close-icon.svg";

//  Components
import Loader from "common/loader";
import NextButton from "common/form/submitButton";
import Form from "common/form/form";
import { Link } from "react-router-dom";
import GameRoundsFlyer from "../games/roundsFlyer";

class ListingFlyer extends Form {
  ref = createRef(null);
  state = {
    loading: false,
    loadingWinner: false,
    showRoundsFlyer: false,
    winner: "",
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.setState({ loading: true });
      this.props.loadGames(
        {
          user: this.props.user.id,
          amountType: this.props.amountType,
        },
        true,
        () => {
          this.setState({ loading: false });
        },
      );
    }
    if (!this.props.getGame.loading && this.props.getGame.loading !== prevProps.getGame.loading)
      this.ref.current.complete();

    if (this.props.getGame.loading && this.props.getGame.loading !== prevProps.getGame.loading)
      this.ref.current.continuousStart();
  };
  roundsModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showRoundsFlyer: !this.state.showRoundsFlyer });
  };
  render() {
    const { games, filteredCount } = this.props.getGame;
    const { user } = this.props;
    return (
      <>
        <Loader loaderRef={this.ref} />
        <Offcanvas
          className={"offcanvas offcanvas-end custom-offcanvas-pannel custom-offcanvas-pannel2"}
          show={this.props.showModal}
          onHide={this.props.toggleModal}
          id="rewardPannel"
          placement="right"
          name="rewardPannel"
          backdrop={true}
        >
          <Offcanvas.Header className="offCanvas-header-2">
            <div>
              <h5>User Games {filteredCount && `(${filteredCount})`}</h5>{" "}
              <div className="td-img-group d-flex align-items-start m-top-2">
                {user.userName}
                <br />
                {user.email}
              </div>
            </div>
            <div
              className="btn_close pointer"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={this.props.toggleModal}
            >
              <img src={closeIcon} alt="" />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {" "}
            <div className="">
              <div className="fwc-body">
                {!this.state.loading && (
                  <div className="table-responsive">
                    {games.length === 0 ? (
                      <div className="fwc-body">
                        <div className="no-record-found-container">
                          <div className="nfr-box">
                            <img src={emptyIcon} alt="No Record Found" />
                            <div className="nrf-text">
                              <h5>No Records Found!</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th>Contest</th>
                            <th>Total/Highest Score</th>
                            <th>Played On</th>
                          </tr>
                        </thead>
                        <tbody>
                          {games &&
                            games.length > 0 &&
                            games.map((game, i) => {
                              const allGames = game?.games;

                              return (
                                <tr>
                                  {game.contest ? (
                                    <td>
                                      <Link
                                        className="td-text-box d-flex align-items-center"
                                        to={{
                                          pathname: `/contests/${game.contest && game.contest.slug}`,
                                          state: {
                                            contest: game.contest,
                                            status: "all",
                                          },
                                        }}
                                      >
                                        <div className="td-img margin-right-2 pointer">
                                          <img src={process.env.REACT_APP_CDN + game.contest.image.small} alt="" />
                                        </div>

                                        {game.contest.title}
                                      </Link>
                                    </td>
                                  ) : (
                                    <td>N/A</td>
                                  )}
                                  <td>
                                    <div className={`td-text-box ${!game?.isValid && "opacity-5"}`}>
                                      {game?.isValid ? (
                                        <Link
                                          className="td-text-box d-flex align-items-center"
                                          to={`/game-details/${game.gameId}`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.props.selectedGame(game);
                                            this.roundsModalToggle(e);
                                          }}
                                        >
                                          {game.totalScore?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                        </Link>
                                      ) : (
                                        "NA"
                                      )}
                                    </div>
                                  </td>{" "}
                                  <td>
                                    {" "}
                                    {allGames[0]?.isIllegitimate ? (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Popover
                                            id="popoverPannel"
                                            className="custom-tooltip "
                                            data-bs-title="Illegitimate"
                                          >
                                            <Popover.Body className="tooltip-inner tooltip-inner-reason">
                                              <h4>Reason for marking illegitimate</h4>
                                              <p>{allGames[0]?.illegitimateDetails?.reason}</p>
                                              <h4>Date</h4>
                                              <p>
                                                {moment(allGames[0]?.illegitimateDetails?.date).format(
                                                  "MM/DD/YYYY hh:mm a",
                                                )}
                                              </p>
                                            </Popover.Body>
                                          </Popover>
                                        }
                                      >
                                        <div
                                          class="td-shape"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          data-bs-custom-class="custom-tooltip"
                                          data-bs-title="Illegitimate"
                                        ></div>
                                      </OverlayTrigger>
                                    ) : allGames[0]?.isRefunded ? (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Popover
                                            id="popoverPannel"
                                            className="custom-tooltip "
                                            data-bs-title="Illegitimate"
                                          >
                                            <Popover.Body className="tooltip-inner tooltip-inner-reason ">
                                              <h4>Reason for refund</h4>
                                              <p>{allGames[0]?.refund?.reason}</p>
                                              <h4>Date</h4>
                                              <p>{moment(allGames[0]?.refund?.date).format("MM/DD/YYYY hh:mm a")}</p>
                                            </Popover.Body>
                                          </Popover>
                                        }
                                      >
                                        <div
                                          class="td-shape yellow-bg"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          data-bs-custom-class="custom-tooltip"
                                          data-bs-title="Illegitimate"
                                        ></div>
                                      </OverlayTrigger>
                                    ) : (
                                      <></>
                                    )}
                                    <div class="small-text">
                                      {moment(game.lastPlayedAt).format("MM/DD/YYYY")}
                                      <br /> <small> {moment(game.lastPlayedAt).format("hh:mm a")}</small>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        <GameRoundsFlyer
          showModal={this.state.showRoundsFlyer}
          toggleModal={this.roundsModalToggle}
          contest={this.props.getGame?.selectedGame?.contest}
          player={this.props.getGame?.selectedGame?.user}
          rounds={this.props.getGame?.selectedGame?.games}
          id={this.props.getGame?.selectedGame?.id}
          allGame={this.props.getGame?.selectedGame}
          amountType={this.props.amountType}
        />{" "}
        {this.state.showRoundsFlyer && (
          <div className="modal-backdrop show fade" onClick={this.roundsModalToggle}></div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadGames: (params, fresh, callback) => dispatch(loadGames(params, fresh, callback)),
  markAsWinner: (id, callback) => dispatch(markAsWinner(id, callback)),
  selectedGame: (payLoad) => dispatch(selectedGame(payLoad)),
});
const mapStateToProps = (state) => ({
  getGame: getGame(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListingFlyer));
