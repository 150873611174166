import React from "react";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import {
  getSweepstake,
  addContract,
  updateContract,
  addContractReceived,
  updateContractReceived,
} from "store/sweepstakes";
import { getSettings, loadSettings } from "store/settings";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Joi from "joi-browser";
import _ from "lodash";
import Amplify, { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import plusIcon from "include/images/plus-1.svg";
import deleteIcon from "include/images/delete-icon.svg";

//  Components
import Form from "common/form/form";
import Select from "common/form/selectSearch";
import NextButton from "common/form/submitButton";
import AlertSuccess from "common/alert/alertSuccess";
import AlertError from "common/alert/alertError";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});
class AddFlyer extends Form {
  fileInputRef = React.createRef(null);
  state = {
    loading: false,
    data: {
      contractAddress: "",
      blockChainNetwork: {},
      title: "",
      description: "",
      abiJSON: "",
      status: { name: "Paused", value: 2 },
    },
    errors: {},
    loadingContract: false,
    loadingImages: false,
    loadingMainImages: false,
    imageChange: false,
    imgInd: "",
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      if (this.props.showModal) {
        this.props.loadSettings(() => {});
        if (this.props.edit) {
          const data = { ...this.state.data };
          data.title = this.props.edit.title;
          data.description = this.props.edit.description;
          data.abiJSON = this.props.edit.abiJSON;
          data.contractAddress = this.props.edit.contractAddress;
          data.blockChainNetwork = {
            name: this.props.edit.blockChainNetwork,
            value: this.props.edit.blockChainNetwork,
          };
          data.status = {
            name: this.props.edit.status === 1 ? "Active" : "Paused",
            value: this.props.edit.status,
          };
          this.setState({ data });
        }
      }
    }
  };
  doSubmit = (e) => {
    this.setState({ loadingContract: true });
    const data = { ...this.state.data };

    const payLoad = {
      title: data.title,
      description: data.description,
    };

    if (this.props.edit) {
      this.props.updateContract(this.props.edit.id, payLoad, (contractRes) => {
        this.setState({
          loadingContract: false,
        });
        if (contractRes.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.updateContractReceived(contractRes.data.data);
          this.clearData();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={contractRes.data && contractRes.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      payLoad.abiJSON = data.abiJSON.toString();
      payLoad.contractAddress = data.contractAddress;
      payLoad.blockChainNetwork = data.blockChainNetwork?.value;
      this.props.addContract(payLoad, (contractRes) => {
        this.setState({
          loadingContract: false,
        });
        if (contractRes.status === 200) {
          toast(<AlertSuccess message="Information Saved" />, {
            containerId: 1,
          });
          this.props.addContractReceived(contractRes.data.data);
          this.clearData();
          this.props.toggleModal();
        } else {
          toast(<AlertError message={contractRes.data && contractRes.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };
  schema = {
    contractAddress: Joi.string().required(),
    blockChainNetwork: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.string().required(),
    }),
    title: Joi.string().required(),
    description: Joi.string().required(),
    abiJSON: Joi.string().required(),
    status: Joi.object().keys({
      data: Joi.object().allow(null),
      name: Joi.string().allow(""),
      value: Joi.number().required(),
    }),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        contractAddress: "",
        blockChainNetwork: {},
        title: "",
        description: "",
        abiJSON: "",
        status: { name: "Paused", value: 2 },
      },
      errors: {},
      loadingContract: false,
    });
  };
  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };
  render() {
    const { settings } = this.props.getSettings;
    const blockChainNetworksList = (settings && settings[0]?.blockChainNetworks) ?? [];
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>{this.props.edit ? "Edit" : "Add "} a Sweeps Contract</h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <div className="row">
                <div className="col-12 new-form-group">{this.renderInput("title", "Title", "", "")}</div>
              </div>
            </div>
            <div className="new-form-group">
              <div className="row">
                <div className="col-12 new-form-group">
                  {this.renderInput("contractAddress", "Contract Address", "", "", this.props.edit ?? false)}
                </div>
              </div>
            </div>
            <div className="new-form-group">
              <label className="form-label">Blockchain Network</label>
              <Select
                name="blockChainNetwork"
                options={blockChainNetworksList?.map((network) => ({ name: network, value: network }))}
                label="Select Blockchain Network"
                value={this.state.data.blockChainNetwork}
                error={this.state.errors.blockChainNetwork}
                onChange={this.onChange}
                isLoading={false}
                isDisabled={this.props.edit}
              />
            </div>
            <div className="new-form-group">
              <div className="row">
                <div className="col-12 new-form-group">{this.renderTextarea("description", "Description", "", "")}</div>
              </div>
            </div>
            <div className="new-form-group">
              <div className="row">
                <div className="col-12 new-form-group">
                  {this.renderTextarea("abiJSON", "ABI JSON", "", this.props.edit ?? false)}
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loadingContract || this.state.loadingMainImages || this.state.loadingImages}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addContract: (data, callback) => dispatch(addContract(data, callback)),
  addContractReceived: (payLoad) => dispatch(addContractReceived(payLoad)),
  updateContract: (id, data, callback) => dispatch(updateContract(id, data, callback)),
  updateContractReceived: (payLoad) => dispatch(updateContractReceived(payLoad)),
  loadSettings: (callback) => dispatch(loadSettings(callback)),
});
const mapStateToProps = (state) => ({
  getSweepstake: getSweepstake(state),
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFlyer));
