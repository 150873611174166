import React, { Component } from "react";
class Input extends Component {
  state = {
    formclass: "form-group custom-input",
  };

  render() {
    const { name, label, error, readOnly, disabled, toolData, maxLength, minLength, ...rest } = this.props;
    return (
      <div className={name === "email" && "form-group"}>
        {label && (
          <label htmlFor={name} className="form-label">
            {label}
          </label>
        )}
        <input
          name={name}
          id={name}
          maxLength={maxLength ? maxLength : false}
          minLength={minLength ? minLength : false}
          className={error ? "form-control error" : "form-control"}
          disabled={disabled}
          {...rest}
        />
      </div>
    );
  }
}

export default Input;
