import NextButton from "common/form/submitButton";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import arrowIcon1 from "include/images/arrow-down-icon-1.svg";
import arrowIcon2 from "include/images/arrow-down-icon-2.svg";
import arrowIcon from "include/images/arrow-down-icon.svg";
import filterIcon from "include/images/filter-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import userIcon from "include/images/user.png";
import { getDashboard, loadAllReferrals, loadDeposits, loadReferralStats } from "store/dashboard";
import closeIcon from "include/images/close-icon.svg";
import SelectSearch from "common/form/selectSearch";
import { isBoolean, isEmpty } from "lodash";
import { Dropdown } from "react-bootstrap";
import carrentIcon from "include/images/carrent-icon.svg";
import UsersFlyer from "./usersFlyer";
import DepositsFlyer from "./depositsFlyer";

const ReferralDashboard = (props) => {
  const ref = useRef();

  const [showUsers, setShowUsers] = useState(false);
  const [referrer, setReferrer] = useState({});
  const [referredUsers, setReferredUsers] = useState([]);
  const [loadingMain, setLoadingMain] = useState(true);
  const [page, setPage] = useState(1);
  const [searchData, setSearchData] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const [sorting, setSorting] = useState({});

  const [showFilters, setShowFilters] = useState(false);
  const [showDeposits, setShowDeposits] = useState(false);
  const [referralId, setReferralId] = useState(null);
  const [loadingFilters, setLoadingFilters] = useState(false);
  useEffect(() => {
    searchFilters();
    props.loadReferralStats();
  }, []);
  const {
    allReferrals,
    allReferralsFilteredCount,
    allReferralsTotalCount,
    deposits,
    depositsTotalCount,
    referralStats,
  } = props.getDashboard;

  useEffect(() => {
    if (!loadingMain) ref?.current?.complete();
    else ref?.current?.continuousStart();
  }, [loadingMain]);

  const searchFilters = (e, data = {}, searchPage = 1, sorting) => {
    if (e) e.preventDefault();
    setLoadingFilters(true);

    setSearchData(data);
    if (searchPage !== page) setPage(searchPage);
    props.loadAllReferrals({ ...data, page: searchPage, sort: sorting?.sort, order: sorting?.order }, (res) => {
      if (res.status === 200) {
        const updatedAppliedFilters = {};
        Object.keys(data).forEach((key) => {
          if (!isEmpty(data[key])) {
            updatedAppliedFilters[key] = searchData[key];
          }
          if (isBoolean(data[key])) {
            updatedAppliedFilters[key] = searchData[key] ? "Yes" : "No";
          }
        });
        setAppliedFilters(updatedAppliedFilters);
      }
      setLoadingFilters(false);
      setLoadingMain(false);
    });
  };
  const sort = (e, type) => {
    const data = {};
    if (type === 1) {
      if (sorting?.sort === "numberOfUsersReferred" && sorting?.order === "desc") {
        data.sort = "numberOfUsersReferred";
        data.order = "asc";
      } else {
        data.sort = "numberOfUsersReferred";
        data.order = "desc";
      }
    } else if (type === 2) {
      if (sorting?.sort === "totalDeposit" && sorting?.order === "desc") {
        data.sort = "totalDeposit";
        data.order = "asc";
      } else {
        data.sort = "totalDeposit";
        data.order = "desc";
      }
    } else if (type === 3) {
      if (sorting?.sort === "totalClaimed" && sorting?.order === "desc") {
        data.sort = "totalClaimed";
        data.order = "asc";
      } else {
        data.sort = "totalClaimed";
        data.order = "desc";
      }
    } else if (type === 4) {
      if (sorting?.sort === "totalAvailable" && sorting?.order === "desc") {
        data.sort = "totalAvailable";
        data.order = "asc";
      } else {
        data.sort = "totalAvailable";
        data.order = "desc";
      }
    }
    setSorting(data);

    searchFilters(e, searchData, page, data);
  };
  const onChange = (e) => {
    const data = { ...searchData };
    data[e?.target?.name] = e?.target?.value;
    if (e?.target?.value === "") delete data[e?.target?.name];

    setSearchData(data);
  };
  const toggleUsersModal = (e, referrerUser = {}, referred = []) => {
    if (e) e.preventDefault();
    setShowUsers(!showUsers);
    setReferrer(referrerUser);
    setReferredUsers(referred);
  };
  const toggleDepositModal = (e, referrerUser = {}, id = null) => {
    if (e) e.preventDefault();
    setShowDeposits(!showDeposits);
    setReferrer(referrerUser);
    setReferralId(id);
  };
  return (
    <>
      <Header />
      <Loader loaderRef={ref} />
      <div className="page-wrapper add-dev-server-wrapper">
        <SideBar page="dashboard" />
        <div className="main-content-wrapper position-relative">
          <div className="mcw-header d-flex align-items-center">
            <h1>Referrals {allReferralsTotalCount > 0 && `(${allReferralsTotalCount})`}</h1>
            <div className="filter-menu-box d-flex align-items-center ms-auto">
              <div className="fmb-box">
                <a
                  href="#!"
                  className="filter-link-box"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowFilters(!showFilters);
                  }}
                >
                  <img src={filterIcon} /> <span>Filters</span>
                </a>
              </div>
            </div>
          </div>
          <div className="fwc-wrapper">
            {showFilters && (
              <div className="fwc-head ">
                <div className="fwc-inner">
                  <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                    <li className="flex-fill">
                      <div className="fml-box">
                        <div className={"form-group"}>
                          <label htmlFor={"referrer"} className="form-label">
                            Username
                          </label>

                          <input
                            name={"referrer"}
                            id={"referrer"}
                            type="text"
                            className={"form-control"}
                            placeholder="Search via referrer username"
                            value={searchData?.referrer ?? ""}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </li>{" "}
                    <li className="flex-fill">
                      <div className="fml-box">
                        <div className={"form-group"}>
                          <label htmlFor={"code"} className="form-label">
                            Code
                          </label>

                          <input
                            name={"code"}
                            id={"code"}
                            type="text"
                            className={"form-control"}
                            placeholder="Search via referral code"
                            value={searchData?.code ?? ""}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </li>
                    <li className="flex-fill">
                      <div className="fml-box">
                        <div className={"form-group"}>
                          <label htmlFor={"numberOfUsersReferred"} className="form-label">
                            Number Of Users Referred
                          </label>

                          <input
                            name={"numberOfUsersReferred"}
                            id={"numberOfUsersReferred"}
                            type="number"
                            className={"form-control"}
                            placeholder="Search via number of users referred"
                            value={searchData?.numberOfUsersReferred ?? ""}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </li>
                    <li className="flex-fill">
                      <div className="fml-box">
                        <div className={"form-group"}>
                          <label htmlFor={"totalDeposit"} className="form-label">
                            Total Deposit
                          </label>

                          <input
                            name={"totalDeposit"}
                            id={"totalDeposit"}
                            type="number"
                            className={"form-control"}
                            placeholder="Search via total deposit"
                            value={searchData?.totalDeposit ?? ""}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </li>
                    <li className="flex-fill">
                      <div className="fml-box">
                        <div className={"form-group"}>
                          <label htmlFor={"totalClaimed"} className="form-label">
                            Total Claimed
                          </label>

                          <input
                            name={"totalClaimed"}
                            id={"totalClaimed"}
                            type="number"
                            className={"form-control"}
                            placeholder="Search via Total Claimed"
                            value={searchData?.totalClaimed ?? ""}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </li>
                  </ul>

                  <div className="fwc-btn d-flex align-items-center justify-content-end">
                    <NextButton
                      handleSubmit={(e) => searchFilters(e, {}, 1, sorting)}
                      classData="btn-text pointer"
                      label="Reset"
                    />
                    <NextButton
                      handleSubmit={(e) => searchFilters(e, searchData, 1, sorting)}
                      classData="btn btn-primary btn-sm"
                      label="Search"
                      loading={loadingFilters}
                    />
                  </div>
                </div>{" "}
                {!isEmpty(appliedFilters) && (
                  <div className="filter-tag-container d-flex align-items-center ">
                    <div className="fwc-left w-100">
                      <div className="filter-tag-list">
                        <p>filter applied :</p>
                        {Object.keys(appliedFilters).map((key) => (
                          <div className="filter-tag-item">
                            <span className="camelCase">
                              {key === "referrer"
                                ? "Username"
                                : key === "numberOfUsersReferred"
                                ? "Number Of Users Referred"
                                : key === "totalDeposit"
                                ? "Total Deposit"
                                : key === "totalDeposit"
                                ? "Total Deposit"
                                : key === "totalClaimed"
                                ? "Total Claimed"
                                : key}
                            </span>{" "}
                            : {appliedFilters[key]}
                            <a
                              href="#!"
                              onClick={(e) => {
                                const data = { ...searchData };
                                delete data[key];
                                setSearchData(data);
                                searchFilters(e, data, 1, sorting);
                              }}
                            >
                              <img src={closeIcon} alt="" />
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="fwc-body">
              {!loadingMain && (
                <div className="table-responsive">
                  {allReferralsFilteredCount === 0 ? (
                    <div className="fwc-body">
                      <div className="no-record-found-container">
                        <div className="nfr-box">
                          <img src={emptyIcon} alt="No Record Found" />
                          <div className="nrf-text">
                            <h5>No Records Found!</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="gcdr-widget-box">
                        <ul className="gw-list d-flex flex-wrap">
                          <li className="w-16">
                            <div className="gw-box">
                              <em>Total Referrer Users</em>
                              <h6>{referralStats?.totalReferrers?.toLocaleString("en-us") ?? 0}</h6>
                            </div>
                          </li>
                          <li className="w-16">
                            <div className="gw-box">
                              <em>Total Referral Codes</em>
                              <h6>{referralStats?.totalReferrals?.toLocaleString("en-us") ?? 0}</h6>
                            </div>
                          </li>
                          <li className="w-16">
                            <div className="gw-box">
                              <em>Total Referred Users</em>
                              <h6>{referralStats?.totalReferredUsers?.toLocaleString("en-us") ?? 0}</h6>
                            </div>
                          </li>
                          <li className="w-16">
                            <div className="gw-box">
                              <em>Total Deposited</em>
                              <h6>
                                {referralStats?.totalDeposit?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }) ?? 0}
                              </h6>
                            </div>
                          </li>

                          <li className="w-16">
                            <div className="gw-box">
                              <em>Total Claimed</em>
                              <h6>
                                {referralStats?.totalClaimed?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }) ?? 0}
                              </h6>
                            </div>
                          </li>
                          <li className="w-16">
                            <div className="gw-box">
                              <em>Total Unclaimed</em>
                              <h6>
                                {referralStats?.totalAvailable?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }) ?? 0}
                              </h6>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <table className="table large-table">
                        <thead>
                          <tr>
                            <th style={{ textAlign: "center" }}>Actions</th>
                            <th>Username</th>
                            <th>Code</th>
                            <th>
                              Users Referred{" "}
                              <a href="#!" onClick={(e) => sort(e, 1)}>
                                <img
                                  src={
                                    sorting?.sort === "numberOfUsersReferred" && sorting?.order === "desc"
                                      ? arrowIcon1
                                      : sorting?.sort === "numberOfUsersReferred" && sorting?.order === "asc"
                                      ? arrowIcon2
                                      : arrowIcon
                                  }
                                  alt=""
                                />
                              </a>
                            </th>
                            <th>
                              Total Loot Bucks Deposited{" "}
                              <a href="#!" onClick={(e) => sort(e, 2)}>
                                <img
                                  src={
                                    sorting?.sort === "totalDeposit" && sorting?.order === "desc"
                                      ? arrowIcon1
                                      : sorting?.sort === "totalDeposit" && sorting?.order === "asc"
                                      ? arrowIcon2
                                      : arrowIcon
                                  }
                                  alt=""
                                />
                              </a>
                            </th>
                            <th>
                              Total Loot Bucks Claimed{" "}
                              <a href="#!" onClick={(e) => sort(e, 3)}>
                                <img
                                  src={
                                    sorting?.sort === "totalClaimed" && sorting?.order === "desc"
                                      ? arrowIcon1
                                      : sorting?.sort === "totalClaimed" && sorting?.order === "asc"
                                      ? arrowIcon2
                                      : arrowIcon
                                  }
                                  alt=""
                                />
                              </a>
                            </th>
                            <th>
                              Total Loot Bucks Unclaimed{" "}
                              <a href="#!" onClick={(e) => sort(e, 4)}>
                                <img
                                  src={
                                    sorting?.sort === "totalAvailable" && sorting?.order === "desc"
                                      ? arrowIcon1
                                      : sorting?.sort === "totalAvailable" && sorting?.order === "asc"
                                      ? arrowIcon2
                                      : arrowIcon
                                  }
                                  alt=""
                                />
                              </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {allReferrals?.map((user, index) => (
                            <tr key={index}>
                              <td>
                                <Dropdown className=" custom-dropdown">
                                  <Dropdown.Toggle
                                    id="tdDropdownOne"
                                    className="custom-dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img src={carrentIcon} alt="" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={(e) => toggleUsersModal(e, user?.referrer, user?.usersReferred)}
                                    >
                                      Referred Users
                                    </Dropdown.Item>{" "}
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={(e) => toggleDepositModal(e, user?.referrer, user?._id)}
                                    >
                                      View Deposits
                                    </Dropdown.Item>{" "}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td className="d-flex">
                                <div className="td-img round-img m-right-2">
                                  <img
                                    src={
                                      user?.referrer?.image
                                        ? process.env.REACT_APP_CDN + user?.referrer?.image?.small
                                        : userIcon
                                    }
                                    alt=""
                                  />
                                </div>
                                {user?.referrer?.userName}
                              </td>
                              <td>{user?.code}</td>
                              <td>
                                <a href="#!" onClick={(e) => toggleUsersModal(e, user?.referrer, user?.usersReferred)}>
                                  {user?.numberOfUsersReferred}
                                </a>
                              </td>
                              <td>
                                <a href="#!" onClick={(e) => toggleDepositModal(e, user?.referrer, user?._id)}>
                                  {user?.totalDeposit?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }) ?? 0}
                                </a>
                              </td>
                              <td>
                                {user?.totalClaimed?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }) ?? 0}
                              </td>
                              <td>
                                {user?.totalAvailable?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }) ?? 0}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              )}
              {!(page === 1 && allReferralsFilteredCount === 0) && (
                <div className="page-pagination">
                  <ul className="pagination  justify-content-end">
                    <li className="page-item">
                      <a
                        class={`page-link ${page === 1 && "disabled"}`}
                        href="#!"
                        aria-label="Previous"
                        onClick={(e) => {
                          e.preventDefault();
                          if (page > 1) {
                            searchFilters(e, searchData, page - 1, sorting);
                          }
                        }}
                      >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                    </li>
                    <li className="page-item active">
                      <a
                        className="page-link"
                        href="#!"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        {page}
                      </a>
                    </li>
                    {allReferralsFilteredCount >= 50 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                            searchFilters(e, searchData, page + 1, sorting);
                          }}
                        >
                          {page + 1}
                        </a>
                      </li>
                    )}
                    <li className="page-item">
                      <a
                        class={`page-link ${allReferrals?.length < 50 && "disabled"}`}
                        href="#!"
                        aria-label="Next"
                        onClick={(e) => {
                          e.preventDefault();
                          searchFilters(e, searchData, page + 1, sorting);
                        }}
                      >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <UsersFlyer user={referrer} referredUsers={referredUsers} showModal={showUsers} toggleModal={toggleUsersModal} />
      <DepositsFlyer
        referralId={referralId}
        user={referrer}
        deposits={deposits}
        loadMore={props.loadDeposits}
        depositsTotalCount={depositsTotalCount}
        showModal={showDeposits}
        toggleModal={toggleDepositModal}
      />
      {(showUsers || showDeposits) && (
        <div
          className="modal-backdrop show fade z-index-1000"
          onClick={showUsers ? toggleUsersModal : toggleDepositModal}
        ></div>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  loadAllReferrals: (params, callback) => dispatch(loadAllReferrals(params, callback)),
  loadDeposits: (id, params, fresh, callback) => dispatch(loadDeposits(id, params, fresh, callback)),
  loadReferralStats: () => dispatch(loadReferralStats()),
});
const mapStateToProps = (state) => ({
  getDashboard: getDashboard(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReferralDashboard));
